<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-forum k-elevated" :class="community.type=='lp'?'k-lp-forum':''">
	<h3 class="k-forum-title">
		<v-icon v-if="forum.type=='announcements'" color="primary" class="ml-1 mr-3">fas fa-bullhorn</v-icon>
		<v-icon v-if="forum.type=='resources'" color="primary" class="ml-1 mr-3">fas fa-folder-open</v-icon>
		<v-icon v-if="forum.type=='default'||forum.type=='lp'" color="primary" class="ml-1 mr-3">fas fa-comments</v-icon>
		<div v-html="forum.title"></div>
		<v-spacer/>
		<div v-if="unread_post_count>0" class="k-forum-unread-posts" @click="mark_all_posts_as_read"><v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on"><v-icon color="lime">fas fa-exclamation-circle</v-icon> {{unread_post_count}} Unread</div></template><span>Click to mark all as read</span></v-tooltip></div>
		<div v-if="unread_post_count==0&&hide_unread_posts">
			<v-btn v-if="!show_all_posts_if_hide_unread" small @click="show_all_posts_if_hide_unread=true">Show Posts</v-btn>
			<v-btn v-if="show_all_posts_if_hide_unread" small @click="show_all_posts_if_hide_unread=false">Hide Posts</v-btn>
		</div>
		<v-btn v-if="is_community_admin && forum.type=='default'" class="ml-2" x-small icon color="#999" @click="edit_forum"><v-icon>fas fa-edit</v-icon></v-btn>
	</h3>
	<div class="k-forum-posts">
		<div v-if="no_posts_message!=''" class="k-forum-no-posts" v-html="no_posts_message"></div>
		<ForumPost v-for="(post) in forum_posts" v-show="post.body!=''" :key="post.post_id" :forum="forum" :post="post" :community="community" :is_community_admin="is_community_admin" :hide_post="hide_unread_posts&&unread_post_count==0&&show_all_posts_if_hide_unread!=true"></ForumPost>
	</div>
	<div v-if="!show_post_editor&&new_posts_allowed" class="k-forum-new-post-btn-wrapper"><v-btn @click="add_new_post" small color="secondary"><v-icon small class="mr-1">fas fa-plus</v-icon>{{ new_post_message }}</v-btn></div>
	<ForumPostEdit v-if="show_post_editor" :post="new_post" :forum="forum" :is_community_admin="is_community_admin" @editor_cancel="show_post_editor=false"></ForumPostEdit>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ForumPost from './ForumPost'
import ForumPostEdit from './ForumPostEdit'

export default {
	components: { ForumPost, ForumPostEdit },
	props: {
		forum: { type: Object, required: true },
		community: { type: Object, required: false, default() { return {} } },
		community_lp: { type: Object, required: false, default() { return {} } },
		is_community_admin: { type: Boolean, required: true },
		// if we're told to hide unread posts, we'll do so *unless* there are any unread posts
		hide_unread_posts: { type: Boolean, required: false, default() { return false } },
	},
	data() { return {
		show_post_editor: false,
		new_post: {},
		show_all_posts_if_hide_unread: false,
	}},
	computed: {
		...mapState(['posts', 'user_info', 'all_courses']),
		forum_id() { return this.forum.forum_id },
		forum_posts() {
			// get top-level posts for this forum
			let arr = this.posts.filter((post) => {
				// post_id isn't 0 (so it's not a new post)
				if (post.post_id == 0) return false

				// parent_post_id is 0 (so it's not a reply)
				if (post.parent_post_id != 0) return false

				// for an lp community, match on lp.forum_ids
				if (this.forum.type == 'lp') {
					if (!this.community_lp.forum_ids) {
						console.log('couldn’t find community_lp (Forum.vue)')
						return false	// this shouldn't happen
					}
					return this.community_lp.forum_ids.includes(post.forum_id)

				// else match on forum_id
				} else {
					return post.forum_id == this.forum_id
				}
			})

			arr.sort((a,b) => {
				// pinned items go at the top
				if (a.pinned && !b.pinned) return -1
				if (b.pinned && !a.pinned) return 1
				return b.created_at - a.created_at
			})

			return arr
		},
		forum_posts_and_replies() {
			let arr = []
			for (let post of this.forum_posts) {
				if (post.post_id == 0) continue
				arr.push(post)
				let replies = this.posts.filter((pr) => {
					return (pr.parent_post_id == post.post_id && !empty(pr.body) && this.user_info.post_reads[pr.post_id] != 1)
				})
				arr = arr.concat(replies)
			}
			return arr
		},
		no_posts_message() {
			if (this.forum_posts.length > 0) return ''
			if (this.forum.type == 'announcements') return 'No announcements have been made to this community.'
			if (this.forum.type == 'resources') return 'No resources have been posted to this community.'
			return 'No posts have been made to this forum.'
		},
		new_post_message() {
			if (this.forum.type == 'announcements') return 'New Announcement'
			if (this.forum.type == 'resources') return 'New Community Resource'
			return 'New Post'
		},
		new_posts_allowed() {
			// for lp forums, only admins can make new posts
			if (this.community.type == 'lp') return this.is_community_admin

			// only admins can make new announcements
			return this.forum.type != 'announcements' || this.is_community_admin
		},
		unread_post_count() {
			let ct = 0
			for (let post of this.forum_posts_and_replies) {
				if (!post.post_is_read()) ++ct
			}
			return ct
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		add_new_post() {
			// add new post to the forum, with no post_id
			let np = new window.Post({
				forum_id: this.forum.forum_id,
				community_id: this.forum.community_id,
				parent_post_id: 0,
				author_user_id: this.user_info.user_id,
				body: '',
				first_name: this.user_info.first_name,
				last_name: this.user_info.last_name,
			})

			if (this.forum.type=='resources') {
				np.resource = new Resource({resource_id:'new'})
			}

			this.new_post = np

			this.$store.commit('set', [this.posts, 'PUSH', this.new_post])
			this.show_post_editor = true

			// make sure posts are showing when you add a new one
			this.show_all_posts_if_hide_unread = true
		},

		edit_forum() {
			this.$prompt({
				title: 'Forum Title',
				text: 'Enter a title for this Community Forum:',
				initialValue: this.forum.title,
				acceptText: 'OK',
				cancelText: 'Delete This Forum',
				cancelColor: 'red',
			}).then(title => {
				if (!empty(title)) {
					this.$store.commit('set', [this.forum, 'title', title])
					this.$store.dispatch('save_forum', this.forum)
				}
			}).catch(n=>{
				this.$store.dispatch('delete_forum', this.forum)
			}).finally(f=>{});
		},

		mark_all_posts_as_read() {
			for (let post of this.forum_posts) {
				if (post.post_id == 0) continue

				// mark this post as read
				if (this.user_info.post_reads[post.post_id] != 1) {
					this.$store.commit('set', [this.user_info.post_reads, post.post_id+'', 1])
				}

				// and all replies of this post as read
				let replies = this.posts.filter((pr) => {
					return (pr.parent_post_id == post.post_id && !empty(pr.body))
				})
				for (let pr of replies) {
					if (this.user_info.post_reads[pr.post_id] != 1) {
						this.$store.commit('set', [this.user_info.post_reads, pr.post_id+'', 1])
					}
				}
			}

			this.$store.dispatch('save_post_reads')
		},
	}
}
</script>

<style lang="scss">
.k-forum {
	border:4px solid $secondary-color;
	background-color:#eee;
	border-radius:8px;
	margin: 10px;
	font-size:14px;
	width:calc(33% - 20px);
	min-width:350px;
	max-width:500px;
	flex: 1 1 calc(33% - 20px);

	.k-forum-title {
		display:flex;
		background-color:$secondary-color;
		padding:8px;
		color:$primary-color;
		font-weight:bold;
		font-size:16px;

		.k-forum-unread-posts {
			cursor:pointer;
			background-color:rgba(255,255,255,0.8);
			border-radius:3px;
			padding:2px 5px 2px 3px;
			font-size:14px;
			color:#000;
			font-weight:normal;
			white-space: nowrap;
			align-self:flex-start;
			.v-icon {
				font-size:20px!important;
				margin-right:2px;
			}
		}
	}

	.k-forum-posts {
	}

	.k-forum-no-posts {
		font-style:italic;
		padding:15px;
		background-color:#fff;
	}

	.k-forum-new-post-btn-wrapper {
		text-align:center;
		padding:10px 0;
	}
}

.k-lp-forum {
	width:680px;
	min-width:350px;
	max-width:680px;
}
</style>
