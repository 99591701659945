<template><div class="k-case-item-importer mt-4">
	<div class="pt-3 mt-5 mb-5 text-center" style="border-top:1px solid #999; color:black; font-size:20px;"><b>Import Child Items</b></div>

	<div class="k-case-ie-line">
		<div class="k-case-ie-line-label" style="width:auto;">Human-Readable Code Stem (e.g. “AFNR”):</div>
		<v-text-field outlined dense hide-details v-model="hcs_prefix" placeholder="" autocomplete="new-password" clearable></v-text-field>
	</div>

	<div class="mb-1">Paste item text in below, then click “Process Item Text” to continue.</div>
	<v-textarea outlined dense hide-details v-model="raw_text_for_import" placeholder="" rows="8" auto-grow></v-textarea>
	<div class="text-center mt-2">
		<v-btn small color="secondary" class="mr-2" @click="$emit('cancel_import')">Cancel Import</v-btn>
		<v-btn small color="primary" @click="import_children">Process Imported Item Text</v-btn>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		// original_node: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		raw_text_for_import: '',
		// TODO: gui controls for these
		create_trees: true,
		hcs_prefix: '',
		statement_options: 'single_line',	// or 'multiple_lines'
		order_by_hcs: true,
		grade_low: 9,
		grade_high: 12,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		import_children() {
			let lines = $.trim(this.raw_text_for_import)
			if (empty(lines)) {
				this.$alert('You must enter item text to import.')
				return
			}

			if (empty(this.hcs_prefix)) {
				this.$alert('You must enter a “Human-Readable Code Stem”. For example, if the codes for the standards you’re importing have the format “AFNR-BAS-1”, “AFNR-BAS-1.1”, etc., use “ANFR” or “ANFR-BAS” as the code stem.')
				return
			}

			let cfitems = []
			let hcs = ''
			let full_statement = ''
			let hcs_prefix_re = new RegExp('^(' + this.hcs_prefix + '\\S*)[.:]?(\s|$)\s*(.*)')
			let log = []
			let error_count = 0

			lines = lines.split('\n')
			let line_number = 0
			///////////////////////////////////////
			// go through each line
			for (let line of lines) {
				++line_number

				// trim line; skip blank lines
				line = $.trim(line)
				if (empty(line)) continue

				/////////////////////////////////////////////////////////////////////
				// if we find a valid human coding scheme at the start of the line...
				if (line.search(hcs_prefix_re) == 0) {
					let r1 = RegExp.$1
					let r2 = $.trim(RegExp.$3)

					// for single line mode...
					if (this.statement_options == 'single_line') {
						// if we have an hcs we're already processing, there must be an error
						if (!empty(hcs)) {
							process_error(line_number, sr('Couldn’t find a statement for the previous code ($1)', hcs))
						}

						// if this line included the full_statement, process it
						if (!empty(r2)) {
							process_cfitem('-', r1, r2)

						// else set hcs
						} else {
							hcs = r1
							process_info(line_number, sr('Found standard code: $1', hcs))
						}

						// TODO: needed??
						full_statement = ''

					// else multiple line mode...
					} else {
						// if we have a full_statement to go with the previous hcs, process it; else error
						if (!empty(full_statement)) {
							process_cfitem('-', hcs, full_statement)
						} else {
							process_error('-', sr('Couldn’t find a statement for the previous code ($1)', hcs))
						}

						// set hcs and set full_statement to whatever was after the hcs (if anything)
						hcs = r1
						full_statement = r2
						process_info(line_number, sr('Found standard code: $1', hcs))
					}

				/////////////////////////////////////////////////
				// else it's a line that didn't start with an hcs
				} else {
					// if we're in 'single_line' mode...
					if (this.statement_options == 'single_line') {
						// if we have an hcs we're currently processing
						if (!empty(hcs)) {
							// if full_statement is currently empty, this line is the full_statement for the hcs; process it
							if (empty(full_statement)) {
								process_cfitem(line_number, hcs, line)
								// then clear hcs (full_statement is already clear)
								hcs = ''

							// else we have an hcs and a full statement already; this shouldn't happen but account for it just in case
							} else {
								process_error(line_number, 'Code and statement already exist...')
							}

						// else we don't have an hcs we're currently processing and we're in single line mode, so skip the line (no error)
						} else {
							process_info(line_number, sr('Skipping line <span style="color:#999">[$1]</span>', line))
						}

					// else we're in 'multiple_line' mode
					} else {
						// if we have an hcs we're currently process, add to full_statement
						if (!empty(hcs)) {
							// we could add an option whether or not to separate lines with line breaks; for now we'll collapse them
							if (!empty(full_statement)) full_statement += ' '
							full_statement += line
							// we don't have to log this line, because it'll be logged when the standard finishes processing

						// else we're not currently processing an hcs, so skip the line
						} else {
							process_info(line_number, sr('Skipping line <span style="color:#999">[$1]</span>', line))
						}
					}
				}
			}
			// finished going through each line
			///////////////////////////////////////

			// if we have an un-processed hcs...
			if (!empty(hcs)) {
				// then if we're in single_line mode, it's an error
				if (this.statement_options == 'single_line') {
					process_error('-', sr('Reached end of text with an unprocessed code ($1)', hcs))

				// else we're in multiple_line mode...
				} else {
					// if we have a full_statement to go with the hcs, process it; else it's an error
					if (!empty(full_statement)) {
						process_cfitem('-', hcs, full_statement)
					} else {
						process_error('-', sr('Reached end of text with an unprocessed code ($1)', hcs))
					}
				}
			}

			// sort cfitems
			// cfitems.sort((a,b)=>{
			// 	if (a.humanCodingScheme < b.humanCodingScheme) return -1
			// 	if (b.humanCodingScheme < a.humanCodingScheme) return 1
			// 	return 0
			// })
			//
			// easy natural sort algorithm that actually seems to work!
			// https://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
			cfitems.sort((a, b) => a.humanCodingScheme.localeCompare(b.humanCodingScheme, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true}))

			// if this.create_trees is true, put children under parents -- up to three levels
			if (this.create_trees) {
				let current_parent_1 = ''
				let current_parent_2 = ''
				for (let cfitem of cfitems) {
					let hcs = cfitem.humanCodingScheme
					if (current_parent_2 && hcs.indexOf(current_parent_2) == 0) {
						// e.g. cp2 = AFNR-BAS-1.1 and hcs = AFNR-BAS-1.1a
						cfitem.level = 2

					} else if (current_parent_1 && hcs.indexOf(current_parent_1) == 0) {
						// e.g. cp1 = AFNR-BAS-1 and hcs = AFNR-BAS-1.1
						cfitem.level = 1
						current_parent_2 = hcs

					} else {
						// e.g. hcs = ANFR-BAS-1 or ANFR-BAS-2
						cfitem.level = 0
						current_parent_1 = hcs
						current_parent_2 = ''
					}
					// note that this algorithm would mistakenly put ANFR-BAS-10 a level under ANFR-BAS-1 if they come right after each other, but that shouldn't happen
				}
			}

			// show log and give user options for what to do
			let text = sr('<div style="margin-top:-16px; margin-bottom:8px;">Processed $1 lines; found $2 standards; $3 possible errors.</div>', line_number, cfitems.length, error_count)

			text += '<div class="mb-1">Standards:</div>'
			text += '<div style="margin-left:16px; font-size:14px; line-height:18px;">'
			for (let cfitem of cfitems) {
				let margin = (35 * (cfitem.level+1)) + 'px'
				text += sr('<div style="margin-left:$1; text-indent:-35px; margin-top:4px;"><b>$2:</b> $3</div>', margin, cfitem.humanCodingScheme, cfitem.fullStatement)
			}
			text += '</div>'

			text += '<div class="mt-4 mb-2"><b>Processing log:</b></div>'
			text += '<div class="k-case-item-importer-report"><div class="k-case-item-importer-report-inner">'
			for (let log_line of log) text += sr('<div style="margin-left:50px; text-indent:-50px;">$1</div>', log_line)
			text += '</div></div>'

			this.$confirm({
			    title: 'Processing Results',
			    text: text,
			    acceptText: sr('Add $1 Child Items', cfitems.length),
				dialogMaxWidth: 950,
			}).then(y => {
				this.$emit('save_imported_cfitems', cfitems)
			}).catch(n=>{console.log(n)}).finally(f=>{})


			// internal fn for processing cfitems
			function process_cfitem(line_number, hcs, full_statement) {
				// see if this is a duplicate
				let prev_cfitem = cfitems.find(x=>x.humanCodingScheme == hcs)
				if (!empty(prev_cfitem)) {
					// if we found an already-processed standard with the same hcs, check to see if the full_statements are the same
					if (prev_cfitem.fullStatement == full_statement) {
						log.push(sr('$1: Duplicate standard found; statements match <span style="color:#999">[$2]</span>', line_number, hcs))
					} else {
						process_error(line_number, sr('Found duplicate standard for $1 with different statement. <span style="color:#999"><b>Original:</b> $2 / <b>New:</b> $3</span>', hcs, prev_cfitem.fullStatement, full_statement))
					}
				} else {
					cfitems.push({ humanCodingScheme: hcs, fullStatement: full_statement })
					log.push(sr('$1: Standard <b>$2</b> processed <span style="color:#999">[$3]</span>', line_number, hcs, full_statement))
				}
			}

			// internal fn for processing errors
			function process_error(line_number, error) {
				log.push(sr('<div class="my-3">$1: <b class="red" style="padding:3px 3px; border-radius:3px; color:#fff;">Error:</b> $2</div>', line_number, error))
				++error_count
			}

			// internal fn for processing non-error info
			function process_info(line_number, statement) {
				log.push(sr('$1: $2', line_number, statement))
			}
		},

		process_cfitems(cfitems) {
			console.log(cfitems)
		},
	}
}
</script>

<style lang="scss">
.k-case-item-importer {
	textarea {
		font-size: 12px;
		line-height:16px;
		max-height:500px;
	}
}

.k-case-item-importer-report {
	overflow: auto;
}

.k-case-item-importer-report-inner {
	white-space:nowrap;
	font-size: 12px;
    line-height: 14px;
}
</style>
