class Activity {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'activity_id', 0)
		sdp(this, data, 'activity_template_id', 0)
		sdp(this, data, 'activity_title', '')

		// activity status is empty, 'archived', or 'deleted'
		sdp(this, data, 'activity_status', '')

		// activity_description is, e.g., 'Student activity with 1 star available to be earned', created automatically by the client
		sdp(this, data, 'activity_description', '')
		// activity_instructions are instructions to student, which are entered in the Sparkl editor interface
		sdp(this, data, 'activity_instructions', '')

		if (data.lti_resource_link_id) this.lti_resource_link_id = data.lti_resource_link_id
		else this.lti_resource_link_id = U.new_uuid()

		sdp(this, data, 'lti_context_id_hc', '')	// currently used only for google activities

		sdp(this, data, 'tool_id', 'sparkl')	// other option for now is 'google'
		sdp(this, data, 'tool_activity_id', '')	// for sparkl this will be an integer, but it could be a string for other LTI tools

		sdp(this, data, 'activity_type', 'activity')

		// the following two can be set if the activity is associated with a course/unit
		sdp(this, data, 'course_code', '')
		sdp(this, data, 'lp_unit_id', 0)

		// temporary booleans for whether or not things are showing
		this.editing = false
		this.item_showing = false
		this.resources_showing = false
		this.standards_showing = false
		this.activity_instructions_showing = false

		this.resources = []
		if (!empty(data.resources)) {
			for (let r of data.resources) {
				this.resources.push(new Resource(r))
			}
		}

		// when we first load activities from the server, we will get a resource_ids string that specifies the order of the resources
		if (!empty(data.resource_ids) && typeof(data.resource_ids) == 'string') {
			let order = data.resource_ids.split(',')
			let arr = []
			for (let resource_id of order) {
				let r = this.resources.find(x=>x.resource_id == resource_id)
				if (r) arr.push(r)
			}
			this.resources = arr
			// we don't need the resource_ids field to be saved
		}

		this.standards = []
		if (!empty(data.standards)) {
			for (let standard of data.standards) {
				// for now at least we'll use the learning progression CASE_Item structure for standards; this is a bit simpler than the full CFItem structure
				this.standards.push(new CASE_Item(standard))
			}
		}

		// gradebook settings
		// sdp(this, data, 'gb_category', 'ungraded')	// ungraded, practice, assessment
		// sdp(this, data, 'gb_points', 0)
		sdp(this, data, 'added_to_gradebook', false)
		sdp(this, data, 'lineItemSourcedId', {})
		
		// available_date, due_date, and assigned_to combos are stored in Assignment objects
		// format for dates: 2022-03-24; see date_header() below for how to convert this to a date object
		this.assignments = []
		if (!empty(data.assignments)) for (let assignment of data.assignments) {
			this.assignments.push(new Assignment(assignment))
		}

		// if we receive an available_date and/or due_date right in the activity, the activity was created before we went to the multi-assignment system, so create an assignment for them
		if (typeof(data.available_date) == 'string' || typeof(data.due_date) == 'string') {
			this.assignments.push(new Assignment({available_date: data.available_date, due_date: data.due_date}))
		}

		// add incoming assignees
		this.process_assigned_to(data.assigned_to)

		// sort assignments
		this.sort_assignments()

		// // add activity_results if we got any; indexed by student_sourcedId
		// this.activity_results = {}
		// if (!empty(data.activity_results)) for (let student_sourcedId in data.activity_results) {
		// 	this.activity_results[student_sourcedId] = new window.Activity_Result(data.activity_result[student_sourcedId])
		// }

		sdp(this, data, 'creator_user_id', 0)

		// convert dates from mysql to timestamp if necessary
		let tzo = new Date().getTimezoneOffset()*60*1000	// dates come in GMT; convert to local

		if (!isNaN(data.edited_date*1)) this.edited_date = data.edited_date*1
		else this.edited_date = (empty(data.edited_date)) ? 0 : (date.parse(data.edited_date, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000

		if (!isNaN(data.created_at*1)) this.created_at = data.created_at*1
		else this.created_at = (empty(data.created_at)) ? 0 : (date.parse(data.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000
	}

	process_assigned_to(arr) {
		this.assigned_to = []
		if (!empty(arr)) for (let a of arr) {
			let assignee = new window.Assignee(a)
			// if we can't get section data for the assignee, skip it -- it's probably from a previous academic year
			if (!assignee.get_section_data()) {
				// console.log(`process_assigned_to - UNKNOWN SECTION: activity_id ${this.activity_id}, activity_student_mapping_id ${assignee.activity_student_mapping_id}`)
				continue
			}

			// if the assignee doesn't have an assignment_id listed, it was created before we went to the multi-assignment system...
			if (!a.assignment_id) {
				// if we don't currently have any assignments, add one
				if (this.assignments.length == 0) {
					this.assignments.push(new Assignment())
				}
				// then choose the first-listed assignment (which will probably be the one we just created) for this assignee
				a.assignment_id = this.assignments[0].assignment_id
			}

			this.assigned_to.push(new window.Assignee(a))
		}
	}

	sort_assigned_to() {
		// note that this could be computationally expensive if there are a lot of assignees, so don't do it often
		this.assigned_to.sort((a,b)=>{
			// classes come before students (it's a student if it has a user_sourcedId
			if (a.user_sourcedId && !b.user_sourcedId) return 1
			if (b.user_sourcedId && !a.user_sourcedId) return -1

			// either both are students or both are classes; either way, sort by toString
			return (a.toString() < b.toString()) ? -1 : 1
		})
	}

	sort_assignments() {
		this.assignments.sort((a,b)=>{
			// first sort by available date
			if (a.available_date && !b.available_date) return -1
			if (b.available_date && !a.available_date) return 1
			if (a.available_date < b.available_date) return -1
			if (b.available_date > b.available_date) return -1

			// then by due date
			if (a.due_date && !b.due_date) return -1
			if (b.due_date && !a.due_date) return 1
			if (a.due_date < b.due_date) return -1
			if (b.due_date > b.due_date) return -1

			return 0
		})
	}

	// this should only be called for staff
	get_current_assignee() {
		let my_content_assigned_to_filter = vapp.$store.state.lst.my_content_assigned_to_filter[this.course_code]
		// if no currently-selected assignee, return null
		if (empty(my_content_assigned_to_filter) || empty(my_content_assigned_to_filter.class_sourcedId)) return null
		
		// if a student is selected, look for an assignment for this student
		let assignee
		if (my_content_assigned_to_filter.user_sourcedId) {
			assignee = this.assigned_to.find(x=>x.matches(my_content_assigned_to_filter))
		}

		// if not found above, or if this is a class, match on class only
		if (!assignee) assignee = this.assigned_to.find(x=>x.matches_class(my_content_assigned_to_filter))

		return assignee
	}

	available_date(force_multi_assignment) {
		if (this.assignments.length == 0) return ''

		// for a student or parent...
		if (vapp.$store.getters.studentish_role) {
			// note that we will only have assigned_to records that match this student or the student's class.
			let available_date = '2100-01-01'
			let found_user_assignment = false
			for (let a of this.assignments) {
				// if this assignment is for this particular student
				if (!empty(a.user_sourcedId)) {
					// then if this is the first assignment for the student, or it's subsequent student-specific assignment for the student (unlikely but not impossible) but it's earlier, set available_date
					if (!found_user_assignment || a.available_date < available_date) available_date = a.available_date
					found_user_assignment = true
				
				// else if we haven't encountered a student-specific assignment and this date is earlier, set available_date
				} else if (!found_user_assignment && a.available_date < available_date) {
					available_date = a.available_date
				}
			}

			if (available_date == '2100-01-01') return ''
			return available_date

		// else for staff...
		} else {
			// if force_multi_assignment !== true and we have a value for my_content_assigned_to_filter, use the date for that assignee
			let my_content_assigned_to_filter = vapp.$store.state.lst.my_content_assigned_to_filter[this.course_code]
			if (force_multi_assignment === true || empty(my_content_assigned_to_filter) || empty(my_content_assigned_to_filter.class_sourcedId)) {
				// otherwise return the earliest available_date
				let available_date = '2100-01-01'
				for (let a of this.assignments) if (a.available_date && a.available_date < available_date) available_date = a.available_date
				if (available_date == '2100-01-01') return ''
				return available_date
			} else {
				// if a student is selected, look for an assignment for this student
				let assignee = this.get_current_assignee()

				// if we found an assignee, return the available_date
				if (assignee) {
					let a = this.assignments.find(x=>x.assignment_id == assignee.assignment_id)
					if (a) return a.available_date
				}
				return ''
			}
		}
	}

	due_date(force_multi_assignment) {
		if (this.assignments.length == 0) return ''

		// for a student or parent...
		if (vapp.$store.getters.studentish_role) {
			// note that we will only have assigned_to records that match this student or the student's class.
			let due_date = '2000-01-01'
			let found_user_assignment = false
			for (let a of this.assignments) {
				// if this assignment is for this particular student
				if (!empty(a.user_sourcedId)) {
					// then if this is the first assignment for the student, or it's subsequent student-specific assignment for the student (unlikely but not impossible) but it's later, set due_date
					if (!found_user_assignment || a.due_date > due_date) due_date = a.due_date
					found_user_assignment = true
				
				// else if we haven't encountered a student-specific assignment and this date is later, set due_date
				} else if (!found_user_assignment && a.due_date > due_date) {
					due_date = a.due_date
				}
			}
			
			if (due_date == '2000-01-01') return ''
			return due_date

		// else for staff...
		} else {
			// if force_multi_assignment !== true and we have a value for my_content_assigned_to_filter, use the date for that assignee
			let my_content_assigned_to_filter = vapp.$store.state.lst.my_content_assigned_to_filter[this.course_code]
			if (force_multi_assignment === true || empty(my_content_assigned_to_filter) || empty(my_content_assigned_to_filter.class_sourcedId)) {
				// otherwise return the latest due_date
				let due_date = '2000-01-01'
				for (let a of this.assignments) if (a.due_date && a.due_date > due_date) due_date = a.due_date
				if (due_date == '2000-01-01') return ''
				return due_date
			} else {
				// if a student is selected, look for an assignment for this student
				let assignee = this.get_current_assignee()

				// if we found an assignee, return the due_date
				if (assignee) {
					let a = this.assignments.find(x=>x.assignment_id == assignee.assignment_id)
					if (a) return a.due_date
				}
				return ''
			}
		}
	}

	has_multiple_available_dates() {
		if (this.assignments.length == 0) return false

		// if my_content_assigned_to_filter is set, or this is a student/parent, we're showing that assignee's dates
		let my_content_assigned_to_filter = vapp.$store.state.lst.my_content_assigned_to_filter[this.course_code]
		if (!empty(my_content_assigned_to_filter) && !empty(my_content_assigned_to_filter.class_sourcedId)) return false
		if (vapp.$store.getters.studentish_role) return false

		let av = ''
		for (let a of this.assignments) {
			if (a.available_date) {
				if (!av) av = a.available_date
				// as soon as we find a second available_date, return true
				else if (a.available_date != av) return true
			}
		}
		return false
	}

	has_multiple_due_dates() {
		if (this.assignments.length == 0) return false

		// if my_content_assigned_to_filter is set, or this is a student/parent, we're showing that assignee's dates
		let my_content_assigned_to_filter = vapp.$store.state.lst.my_content_assigned_to_filter[this.course_code]
		if (!empty(my_content_assigned_to_filter) && !empty(my_content_assigned_to_filter.class_sourcedId)) return false
		if (vapp.$store.getters.studentish_role) return false

		let du = ''
		for (let a of this.assignments) {
			if (a.due_date) {
				if (!du) du = a.due_date
				// as soon as we find a second due_date, return true
				else if (a.due_date != du) return true
			}
		}
		return false
	}

	includes_assignee(assignee) {
		for (let a of this.assigned_to) {
			if (a.matches(assignee)) return true
		}
		return false
	}

	copy_for_save(flag) {
		let o = $.extend(true, {}, this)

		delete o.component
		delete o.editing
		delete o.item_showing
		delete o.resources_showing
		delete o.standards_showing
		delete o.activity_instructions_showing

		// remove full resources object; attach resource_ids as a comma-delimited string
		delete o.resources
		if (this.resources.length > 0) {
			o.resource_ids = ''
			for (let r of this.resources) {
				if (o.resource_ids != '') o.resource_ids += ','
				o.resource_ids += r.resource_id
			}
		}

		// remove empty assignments
		o.assignments = []
		for (let a of this.assignments) {
			if (!this.assignment_is_empty(a)) o.assignments.push(extobj(a))
		}

		// assigned_to gets saved via a separate part of the payload
		delete o.assigned_to

		// for legacy purposes, make sure available_date and due_date are cleared
		o.available_date = '*CLEAR*'
		o.due_date = '*CLEAR*'

		// // we don't send activity_results to be saved here
		// delete o.activity_results

		// we don't want to send empty strings or 0s in to the service for certain things
		if (o.edited_date == 0) delete o.edited_date
		if (o.created_at == 0) delete o.created_at
		if (empty(o.resource_ids)) delete o.resource_ids
		return o
	}

	course_unit_header() {
		if (!this.course_code) return ''

		let lp = vapp.$store.state.all_courses.find(x=>x.course_code==this.course_code)
		if (!lp) lp = vapp.$store.state.sis_classes.find(x=>x.course_code == this.course_code)
		if (!lp) return this.course_code

		let s = lp.title

		if (this.lp_unit_id && lp.units) {
			let u = lp.units.find(x=>x.lp_unit_id == this.lp_unit_id)
			if (u) {
				s += ': <nobr>' + u.title + '</nobr>'
			}
		}
		let cls = U.subject_tile_css(lp) + '-border-text'
		s = sr('<div class="k-lesson-course-unit-header-inner $1">$2</div>', cls, s)

		return s
	}

	date_color(now) {
		if (!this.available_date() && !this.due_date()) return 'grey'
		if (empty(now)) now = vapp.$store.state.now_date_string
		/* color:
			- If item has an available_date and a due_date:
				- grey if now < available_date (available_date is in the future)
				- green if available_date <= now <= due_date
				- red if now > due_date (due_date is in the past)
			- If item only has an available_date:
				- grey if now < available_date (available_date is in the future)
				- green if available_date <= now
			- If item only has a due_date:	(note in this case that students will never see it)
				- grey if due_date >= now
				- red if due_date < now (due_date is in the past)
		*/
		if (this.available_date() && this.due_date()) {
			if (now < this.available_date()) return 'grey'
			else if (now <= this.due_date()) return 'teal'
			else return 'red'

		} else if (this.available_date()) {
			if (now < this.available_date()) return 'grey'
			else return 'teal'

		} else {
			if (now < this.due_date()) return 'grey'
			else return 'red'
		}
	}

	date_header(now) {
		if (!this.available_date() && !this.due_date()) {
			return sr('<div class="k-lesson-date-header k-lesson-date-header-$1 darken-2">Not Available</div>', this.date_color(now))
		}

		if (empty(now)) now = vapp.$store.state.now_date_string

		let s, d
		if (this.available_date() && this.due_date()) {
			d = new Date(this.available_date() + 'T00:00:00')
			s = date.format(d, 'ddd M/D').replace(/(\d+\/\d+)/, '<span class="k-lesson-date-header-date">$1</span>')
			if (this.available_date() != this.due_date()) {
				d = new Date(this.due_date() + 'T00:00:00')
				s += ' – ' + date.format(d, 'ddd M/D').replace(/(\d+\/\d+)/, '<span class="k-lesson-date-header-date">$1</span>')
			}

		} else if (this.available_date()) {
			d = new Date(this.available_date() + 'T00:00:00')
			s = date.format(d, 'ddd M/D').replace(/(\d+\/\d+)/, '<span class="k-lesson-date-header-date">$1</span> – No Due Date')

		} else {
			d = new Date(this.due_date() + 'T00:00:00')
			s = date.format(d, 'ddd M/D').replace(/(\d+\/\d+)/, '<span class="k-lesson-date-header-date">$1</span>')
		}

		// console.log('this.has_multiple_available_dates()', this.has_multiple_available_dates())

		// add * to end if we have multiple dates
		let star = ''
		if (this.has_multiple_available_dates() || this.has_multiple_due_dates()) star = '*'

		return sr('<div class="k-lesson-date-header k-lesson-date-header-$1 darken-2">$2$3</div>', this.date_color(now), s, star)
	}

	available_date_header(now) {
		if (!this.available_date()) return ''

		if (empty(now)) now = vapp.$store.state.now_date_string

		/* color:
			- If item has an available_date:
				- grey if now < available_date (available_date is in the future)
				- green if available_date <= now
		*/
		let s, color, d
		if (now < this.available_date()) color = 'grey'
		else color = 'teal'
		d = new Date(this.available_date() + 'T00:00:00')
		s = date.format(d, 'ddd M/D').replace(/(\d+\/\d+)/, '<span class="k-lesson-date-header-date">$1</span>')

		// add * to end if we have multiple dates
		let star = ''
		if (this.has_multiple_available_dates()) star = '*'
		
		return sr('<div class="k-lesson-date-header k-lesson-date-header-$1 darken-2">$2$3</div>', color, s, star)
	}

	due_date_header(now) {
		if (!this.due_date()) return ''

		if (empty(now)) now = vapp.$store.state.now_date_string

		/* color:
			- If item has a due_date:
				- green if due_date >= now
				- red if due_date < now (due_date is in the past)
		*/
		let s, color, d
		if (now < this.due_date()) color = 'teal'
		else color = 'red'
		d = new Date(this.due_date() + 'T00:00:00')
		s = date.format(d, 'ddd M/D').replace(/(\d+\/\d+)/, '<span class="k-lesson-date-header-date">$1</span>')

		// add * to end if we have multiple dates
		let star = ''
		if (this.has_multiple_due_dates()) star = '*'

		return sr('<div class="k-lesson-date-header k-lesson-date-header-$1 darken-2">$2$3</div>', color, s, star)
	}

	standalone_link() {
		return window.location.origin + '/lesson/' + this.activity_id
	}

	icon() { return U.activity_type_icon(this.activity_type) }
	type_label() { return U.activity_type_label(this.activity_type) }
	
	// sort first by the available_date, then by the due_date if we have one
	sort_date() { 
		let s = this.available_date() ? this.available_date() : '9999-99-99'
		s += ' '
		s += this.due_date() ? this.due_date() : '9999-99-99'
		return s
	}

	available_to_students(now) {
		// the activity must have an available_date that has passed for it to be available to students
		if (empty(now)) now = vapp.$store.state.now_date_string
		return this.available_date() && this.available_date() <= now
	}

	due_date_has_passed(now) {
		if (empty(now)) now = vapp.$store.state.now_date_string
		return this.due_date() && now > this.due_date()
	}

	currently_due(now) {
		// the activity is considered due if it is available to students and (doesn't have a due date, or the due date has not yet passed)
		if (empty(now)) now = vapp.$store.state.now_date_string
		return this.available_to_students() && (!this.due_date() || this.due_date() >= now)
	}

	// this is implemented for both activities and lessons: we consider an activity "old" if it has a due_date and its due_date is < old_threshold
	item_is_old() {
		if (this.due_date() != '' && this.due_date() < vapp.$store.state.old_threshold_date_string) return true
		// ... or if we have activity results for this user and it was completed before old_threshold_date_string
		if (vapp.$store.state.my_activity_results && vapp.$store.state.my_activity_results[this.activity_id]) {
			if (vapp.$store.state.my_activity_results[this.activity_id].status == 'complete') {
				if (vapp.$store.state.my_activity_results[this.activity_id].updated_at < vapp.$store.state.old_threshold_date_timestamp) return true
			}
		}
		return false
	}

	// determine if an assignment for this activity is "empty", meaning it has no dates and no students are assigned to do it
	assignment_is_empty(assignment) {
		if (!empty(assignment.available_date)) return false
		if (!empty(assignment.due_date)) return false
		if (this.assigned_to.find(x=>x.assignment_id == assignment.assignment_id)) return false
		return true
	}

	add_gradebook_data_to_payload(payload) {
		// this fn is for students: add the appropriate lineItemSourcedId and student_sourcedId to payload for the student, if the student's grade needs to go into the gradebook
		let user_info = vapp.$store.state.user_info
		// if the activity is added_to_gradebook and we have lineItemSourcedId(s)...
		if (this.added_to_gradebook && this.lineItemSourcedId) {
			// if the student is in one of the class_sourcedIds that we have lineItemSourcedIds for...
			for (let class_sourcedId in this.lineItemSourcedId) {
				if (user_info.sis_class_sourcedIds && user_info.sis_class_sourcedIds.includes(class_sourcedId) && user_info.sis_identities) {
					// get the student's user sourcedId
					let si = user_info.sis_identities.find(x=>x.role == 'student')
					if (si) {
						// if we found all this data, add to the payload; the service will send the score to the gradebook if it has changed
						payload.lineItemSourcedId = this.lineItemSourcedId[class_sourcedId]
						payload.student_sourcedId = si.sourcedId
						break
					}
				}
			}
		}
	}

	// utility fns for converting from/to the XXXX-XX-XX format we use for dates
	static convert_date_from_string(date_string) {
		return date.parse(date_string, 'YYYY-MM-DD')
		// returns JS Date object
	}

	static convert_date_to_string(date_obj) {
		return date.format(date_obj, 'YYYY-MM-DD')
		// returns string
	}

	// utility function to shift a date by delta days, using the XXXX-XX-XX format
	static add_to_date(date_string, delta) {
		let date_obj = Activity.convert_date_from_string(date_string)
		date_obj.setDate(date_obj.getDate() + delta)
		return Activity.convert_date_to_string(date_obj)
	}

	static standard_activity_description(stars_available) {
		return `Student activity with ${stars_available} star${stars_available == 1 ? '' : 's'} available to be earned`
	}
}
window.Activity = Activity

// fns for determining things about activities that we might need to call outside the context of a specific activity instantiation
U.activity_type_icon = function(activity_type) {
	if (activity_type == 'lesson') return 'fas fa-rectangle-list'	// for convenience, we do this for lessons here in the activity_type fn (also see label below)
	if (activity_type == 'discussion') return 'fas fa-comments'
	if (activity_type == 'quiz') return 'fas fa-clipboard-list'
	if (activity_type == 'assessment') return 'fas fa-list-check'
	// if (activity_type == 'google_assignment') return 'fas fa-g'
	if (activity_type == 'google_assignment') return 'fas fa-file-lines'
	return 'fas fa-star'
}

U.activity_type_label = function(activity_type) {
	if (activity_type == 'lesson') return 'Lesson Plan'
	if (activity_type == 'discussion') return 'Discussion'
	if (activity_type == 'quiz') return 'Formative Quiz'
	if (activity_type == 'assessment') return 'Illuminate Assessment'
	if (activity_type == 'google_assignment') return 'Google Assignment'
	return 'Activity'
}

class Assignment {
	constructor(data, asn) {
		if (empty(data)) data = {}

		// assignment_id's are guids, created by the client when a new assignment is generated
		if (!empty(data.assignment_id)) this.assignment_id = data.assignment_id
		else this.assignment_id = U.new_uuid()

		// format for dates: 2022-03-24; see date_header() above for how to convert this to a date object
		sdp(this, data, 'available_date', '')	// if empty, the activity is *not* available to students
		sdp(this, data, 'due_date', '')

		// note: we keep the `assigned_to` list of students in the Activity record; each Assignee will have an assignment_id corresponding to an Assignment object
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)
		return o
	}
}
window.Assignment = Assignment

class Assignee {
	constructor(data, asn) {
		if (empty(data)) data = {}
		// we don't store the activity_id because we don't really need it...
		// sdp(this, data, 'activity_id', '')
		sdp(this, data, 'activity_student_mapping_id', 0)
		sdp(this, data, 'assignment_id', '')	// this will be a guid
		sdp(this, data, 'class_sourcedId', '')
		sdp(this, data, 'user_sourcedId', '')
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)
		// we *don't* send activity_student_mapping_id for assignees we want to save
		delete o.activity_student_mapping_id
		return o
	}

	empty() {
		// consider the object instance "empty" if class_sourcedId isn't set
		return empty(this.class_sourcedId)
	}

	// get a list of the student records of all students included in this Assignee
	get_students() {
		let arr = []
		let all_students = vapp.$store.state.all_students
		if (!all_students) {
			console.log('get_students: no all_students')
		}
		if (this.user_sourcedId) {
			if (all_students[this.user_sourcedId]) arr.push(all_students[this.user_sourcedId])

		} else {
			for (let key in all_students) {
				let student = all_students[key]
				if (student.class_sourcedIds.includes(this.class_sourcedId)) arr.push(student)
			}
		}
		return arr
	}

	// this assignee record is an actual assignment if it has a mapping_id
	assigned() { return this.activity_student_mapping_id != 0 }

	get_section_data(sis_classes) {
		if (empty(sis_classes)) sis_classes = vapp.$store.state.sis_classes

		if (sis_classes) for (let my_class of sis_classes) {
			for (let i = 0; i < my_class.class_sourcedIds.length; ++i) {
				if (my_class.class_sourcedIds[i] == this.class_sourcedId) {
					return {class: my_class, index: i}
				}
			}
		}
		return null
	}

	toString(sis_classes, html_flag = false, student_count_flag = true) {
		// pass true as the second param to include html in the section title
		let section_data = this.get_section_data(sis_classes)
		if (!section_data) {
			// we didn't find a class; this could happen if this was an assignment from a previous school year
			return sr('[unknown class for assignment $1]', this.activity_student_mapping_id)
		}

		// if the assignment is for the whole section, show that
		if (this.user_sourcedId == '') {
			let n_students = 0
			if (!empty(section_data.class.students[section_data.index])) n_students = section_data.class.students[section_data.index].length
			// if (n_students == 0) continue
			return section_data.class.section_title(section_data.index, {student_count:student_count_flag, teacher:false, title: false, term:0, html:html_flag})
		
		// else try to find the student
		} else {
			if (!empty(section_data.class.students[section_data.index])) for (let student of section_data.class.students[section_data.index]) {
				if (student.sourcedId == this.user_sourcedId) {
					// found the student
					let name = student.familyName + ', ' + student.givenName
					if (!empty(student.middleName)) name += ' ' + student.middleName[0] + '.'
					return name
				}
			}
			return sr('[unknown student for assignment $1]', this.activity_student_mapping_id)
		}
	}

	// this returns true IFF class AND user matches
	matches(other_assignee) {
		return (this.class_sourcedId == other_assignee.class_sourcedId && this.user_sourcedId == other_assignee.user_sourcedId)
	}

	// this returns true if this assignee is class-wide (i.e. not student-specific -- user_sourcedId is empty) and other_assignee's class matches this assignee's class
	// so other_assignee's user_sourcedId is ignored in this comparison.
	// be very careful when using this, as it's tricky
	matches_class(other_assignee) {
		return (this.class_sourcedId == other_assignee.class_sourcedId && empty(this.user_sourcedId))
	}
}
window.Assignee = Assignee

class Activity_Result {
	constructor(data, activity, user_info) {
		if (empty(data)) data = {}

		sdp(this, data, 'activity_result_id', 0)

		sdp(this, data, 'activity_id', '')
		if (empty(this.activity_id) && !empty(activity)) this.activity_id = activity.activity_id

		sdp(this, data, 'student_user_id', '')
		if (empty(this.student_user_id) && !empty(user_info)) this.student_user_id = user_info.user_id

		sdp(this, data, 'student_sourcedId', '')
		if (empty(this.student_sourcedId) && !empty(user_info)) this.student_sourcedId = user_info.sis_user_sourcedId

		sdp(this, data, 'time_spent', 0)
		sdp(this, data, 'status', '', ['', 'viewed', 'started', 'complete'])
		sdp(this, data, 'score', 0)			// 0-1 (decimal)

		sdp(this, data, 'teacher_grading_required', false)

		sdp(this, data, 'history', [])

		// convert dates from mysql to timestamp if necessary
		let tzo = new Date().getTimezoneOffset()*60*1000	// dates come in GMT; convert to local

		if (!isNaN(data.updated_at*1)) this.updated_at = data.updated_at*1
		else this.updated_at = (empty(data.updated_at)) ? 0 : (date.parse(data.updated_at, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000

		if (!isNaN(data.created_at*1)) this.created_at = data.created_at*1
		else this.created_at = (empty(data.created_at)) ? 0 : (date.parse(data.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)
		delete o.history
		delete o.updated_at
		delete o.created_at
		return o
	}

	viewed() {
		return (this.status != '')
	}

	started() {
		return (this.status == 'started' || this.status == 'complete')
	}

	complete() {
		return (this.status == 'complete')
	}
}
window.Activity_Result = Activity_Result
