<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-collection-unit">
	<div style="display:flex" class="justify-center" v-show="!show_unit_editor">
		<div style="flex:1 1 100%;max-width:1000px">
			<div class="k-collections-unit-description fr-view elevation-3" v-if="unit.text" v-html="unit.text"></div>
			<div class="k-collection-unit-wrapper-outer">
				<div>
					<div class="k-collection-unit-wrapper">
						<div>
							<div class="mb-6">
								<div class="k-collection-unit-resources">
									<CollectionResourceFolder :collection="collection" :unit="unit" :folder_id="'top'" :enable_editing="false" />
								</div>
							</div>

							<div class="text-center k-edit-btn" v-if="!show_todo_report">
								<v-btn small v-if="is_collection_admin" class="ma-2" color="secondary" @click="edit_collection_unit"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit Unit</v-btn>
								<v-btn small v-if="can_view_report" class="ma-2 k-tight-btn" color="secondary" @click="show_todo_report=true"><v-icon small class="mr-2">fas fa-table</v-icon>Show Resource Completion Report</v-btn>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
	<CollectionUnitEdit ref="unit_editor_component" v-if="show_unit_editor" :collection="collection" :original_unit="unit" @editor_cancel="collection_unit_edit_requested=false" />
	<TodoReportCollectionUnit v-if="show_todo_report" :collection="collection" :unit="unit" @dialog_cancel="show_todo_report=false" />
</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import CollectionResourceFolder from './CollectionResourceFolder'
import CollectionUnitEdit from './CollectionUnitEdit'
import TodoReportCollectionUnit from './../reports/TodoReportCollectionUnit'

export default {
	components: { CollectionResourceFolder, CollectionUnitEdit, TodoReportCollectionUnit, },
	props: {
		collection: { type: Object, required: true },
		unit: { type: Object, required: true },
		is_collection_admin: { type: Boolean, required: true },
	},
	data() { return {
		show_todo_report: false,
		collection_unit_edit_requested: false,
	}},
	watch: {
	},
	computed: {
		...mapState(['user_info', 'lp_edit_locked']),
		can_view_report() {
			return vapp.has_admin_right('pd_rep.any')
		},
		show_unit_editor() {
			// show the editor if the user has requested to edit and the lp isn't locked for editing (lp_edit_locked[this.course_code] must have been explicitly set to false)
			if (this.collection_unit_edit_requested != true) return false
			if (empty(this.lp_edit_locked[this.collection.course_code]) || this.lp_edit_locked[this.collection.course_code] === true) return false
			return true
			// return this.collection_unit_edit_requested == true && this.lp_edit_locked[this.collection.course_code] != true
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		narrow_window() {
			return $(window).width() < 1000
		},
		edit_collection_unit() {
			// if the lp is already saved (i.e. it isn't brand new), we have to request to edit it, to make sure no one else already has it opened for editing
			if (!empty(this.collection.course_code) && this.collection.lp_id != 0) {
				this.$store.dispatch('lp_edit_socketmsg', this.collection.course_code)
			}

			// flag that we've requested to edit the LP; once the socket message comes back saying we're OK to edit, the show_lp_editor computed will open it for editing
			this.collection_unit_edit_requested = true
		},
	}
}
</script>

<style lang="scss">

.k-collections-unit-description {
	font-size:14px;
	line-height:19px;
	padding:4px 8px;
	margin:16px 16px 0px 16px;
	border-radius:8px;
	border:1px solid #ccc;
}

.k-collection-unit-wrapper {
	font-size:16px;
	padding:8px;
}

.k-collection-unit-wrapper-outer {
	max-width:1000px;
	min-height:300px;

	.k-collection-unit-wrapper {
		width:auto;
		margin:8px;
		background-color:#fff;
		border-radius:10px;
		overflow:auto;

		.k-collection-unit-section-header {
			font-weight:bold;
			background-color:$v-blue-darken-2;
			border-radius:5px;
			padding:2px 8px;
			color:#fff;
			font-size:20px;
		}

		.k-collection-unit-description {
			max-width:700px;
			font-size:16px;
			// background-color:$v-light-blue-lighten-5;
			// border-radius:5px;
			// padding:4px 12px;
			margin:20px auto;
		}

		.k-collection-unit-resources {
			max-width:700px;
			margin:0 auto;
		}
	}
}

</style>
