<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div v-if="show_search_result" class="k-search-result-wrapper k-communities-list-search-result">
	<div>
		<v-icon color="primary" class="mr-2">fas fa-users</v-icon>
		<b>{{search_result.title}}</b>
		<v-btn v-if="!show_description" small text color="secondary" @click="show_description=true">Show Description</v-btn>
		<v-btn v-if="show_description" small text color="secondary" @click="show_description=false">Hide Description</v-btn>
	</div>
	<div v-if="show_description" class="k-search-result-details" v-html="search_result.description"></div>
	<div class="k-search-result-action">
		<b class="ml-4 mr-2 green--text d-inline-block" style="min-width:160px" v-if="search_result.type=='public'">Public Community</b>
		<b class="ml-4 mr-2 red--text d-inline-block" style="min-width:160px" v-if="search_result.type=='restricted'">Restricted Community</b>

		<span v-if="community_store_record">
			<i v-if="community_store_record.role!='pending'">You are a member of this community</i>
			<i v-if="community_store_record.role=='pending'">You have requested to join this community</i>
		</span>
		<v-btn v-if="!community_store_record&&search_result.type=='public'" x-small style="width:130px" color="primary" @click="join_community">Join</v-btn>
		<v-btn v-if="!community_store_record&&search_result.type=='restricted'" x-small style="width:130px" color="primary" @click="join_community">Request To Join</v-btn>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
		search_result: { type: Object, required: true },
	},
	data() { return {
		show_description:false,
	}},
	computed: {
		...mapState(['communities', 'user_info']),
		community_store_record() {
			return this.communities.find(o=>o.community_id == this.search_result.community_id)
		},
		show_search_result() {
			// don't show the lp community -- it's used to house a forum for each LP, so it can't be joined
			if (this.community_store_record && this.community_store_record.type == 'lp') return false
			return true
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		join_community() {
			this.$store.dispatch('join_community', this.search_result.community_id).then(()=>{
				if (this.search_result.type == 'public') {
					this.$alert({title:'Success', text:sr('You’ve been successfully added to community “$1”.', this.search_result.title)})
				} else {
					this.$alert({title:'Success', text:sr('Your request to join community “$1” has been submitted.', this.search_result.title)})
				}
			})
		}
	}
}
</script>

<style lang="scss">
.k-communities-list-search-result {
	.k-search-result-action {
		display:flex;
	}
}
</style>
