<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-main-collection">
	<h2 class="k-main-collection-header k-page-title d-flex mb-4"><v-icon large color="primary" class="mr-4" style="margin-top:4px">fas fa-home-user</v-icon><b>Welcome to HenryConnects!</b></h2>

	<div>
		<div class="k-parent-resources-announcements">
			<div class="k-parent-resources-student-list" :style="(user_info.role=='parent')?'':'opacity:0.5'">
				<div><b style="font-size:18px"><v-icon color="primary" class="mr-2">fas fa-child</v-icon> Students In My Family:</b></div>
				<ul v-if="child_count>0&&user_info.role=='parent'" class="mt-2">
					<li v-for="(child_data, child_email) in user_info.child_data"><a @click="child_selected(child_email)">{{child_data.first_name}} {{child_data.last_name}}</a></li>
				</ul>
				<!-- <div class="text-center"><v-btn small color="primary" class="mt-3 k-tight-btn" @click="show_child_list" :disabled="user_info.role!='parent'"><v-icon small class="mr-2">fas fa-cog</v-icon> Manage designated students</v-btn></div> -->
			</div>

			<img src="./../../images/hcs_announcements.png" class="k-parent-resources-announcements-img">
			<div>
				<div class="fr-view" v-html="parent_announcements"></div>
				<div v-if="is_pg_admin" class="mt-2 text-right"><v-btn small text color="secondary" @click="edit_file('parent_announcements')"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit Parent Announcements</v-btn></div>
			</div>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	data() { return {
	}},
	computed: {
		...mapState(['user_info', 'all_courses_loaded', 'all_courses', 'course_update_trigger']),
		...mapGetters(['child_count', 'child_data_showing', 'system_role']),
		parent_announcements() { return this.$store.state.content_files.parent_announcements },
		is_pg_admin() {
			return vapp.has_admin_right('pg')
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		show_child_list() {
			// the ChildList component is in UserMenu, which is always visible; invoke it here
			vapp.user_menu.show_child_list()
		},

		child_selected(child_email) {
			// when a child's name is clicked, set child_email_showing and go to the courses page
			this.$store.commit('lst_set', ['child_email_showing', child_email])
			this.$store.commit('set', ['my_classes_view', 'assignments'])
			vapp.go_to_home('classes')
		},

		edit_file(filename) { vapp.edit_file(filename) },
	}
}
</script>

<style lang="scss">
.k-parent-resources-announcements {
	// display:flex;
	background-color:#fff;
	border:6px solid $secondary-color;
	border-radius:10px;
	padding:12px;
	// width: 60%;
	max-width:1000px;
	margin:0 auto;
	text-align:left;
	// align-items:flex-start;
	// flex:0 1 auto;
	.fr-view {
		font-size:16px;
	}
}

.k-parent-resources-announcements-img {
	width:320px;
	// flex:0 1 280px;
	margin-right:16px;
	display:block;
	margin:0 auto 12px auto;
}

.k-parent-resources-student-list {
	// flex: 1 1 auto;
	float:right;
	width:400px;
	margin:-12px -12px 18px 18px;
	background-color:rgba(255, 240, 200, 0.5);
	// background-color:#fff;
	border-left:6px solid $secondary-color;
	border-bottom:6px solid $secondary-color;
	border-radius:0 0 0 10px;
	padding:12px;
	white-space:nowrap;
	text-align:left;

	ul {
		margin-left:-2px;
	}

	li {
		font-size:16px;
	}

	a:hover {
		text-decoration:underline;
	}
}
</style>
