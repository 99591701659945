<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-page-wrapper">
	<h2 class="k-page-title">
		<div class="float-right"><v-btn @click="load_membership_requests">Membership Requests</v-btn></div>
		<div class="float-right mr-2"><v-btn @click="load_creation_requests">Creation Requests</v-btn></div>
		<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-cog</v-icon>
		Communities Administration
	</h2>

	<div class="mt-12 my-3"><i>This admin area is still to be developed. Note that you can manage <b>communitity moderation rights</b> in the “Admin User Privileges” area.</i></div>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
	},
	mounted() {
	},
	methods: {
		load_membership_requests() {
			return
			let payload = {}
			U.loading_start()
			U.ajax('update_search_data', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}


			});
		},

		load_creation_requests() {
		},

		return_to_admin_main() {
			this.$router.push({ path: '/welcome' })
		}
	}
}
</script>

<style lang="scss">
</style>
