<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<div :class="top_css_class"><div class="k-collections-view-wrapper-inner">

		<h2 v-if="!show_collection_only" class="k-page-title d-flex align-center">
			<v-icon color="primary" class="mr-3">fas {{(collection.subject=='PD')?'fa-user-graduate':'fa-circle-nodes'}}</v-icon>
			<span v-if="initialized==0">LOADING…</span>
			<span v-else v-html="page_title"></span>
			<b v-if="initialized&&collection.active=='no'" class="ml-8 red--text">[Inactive]</b>
			<v-spacer/>

			<v-menu v-if="is_collection_admin||can_view_report" bottom right><template v-slot:activator="{on}"><v-btn v-on="on" class="k-edit-btn ml-2" small fab color="#999" dark><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template>
				<v-list>
					<v-list-item v-if="is_collection_admin" @click="edit_collection"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit Resource Collection</v-list-item-title></v-list-item>
					<v-list-item v-if="can_view_report" @click="show_todo_report=true"><v-list-item-icon><v-icon small>fas fa-table</v-icon></v-list-item-icon><v-list-item-title>Show Resource Completion Report</v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
		</h2>

		<div v-if="initialized" v-show="!show_collection_editor">
			<div v-if="!show_collection_only&&collection.description" class="fr-view k-collections-collection-description elevation-3" :class="collection_description_css_class" v-html="collection.description"></div>

			<div class="k-collections-units-outer-wrapper elevation-3" v-if="collection.units.length>0" :class="unit_showing?'k-collections-units-outer-wrapper--unit-showing '+collection_css_class:collection_css_class">
				<div class="k-collections-units-list">
					<div v-for="(unit, i) in collection.units" class="k-collections-unit-list-item" :class="unit_showing==unit?'k-collections-unit-list-item--showing'+unit_css_class[i]:unit_css_class[i]">
						<div class="k-collections-unit-title" @click="toggle_unit(unit)">
							<v-btn v-if="unit_showing!=unit" color="primary" icon small class="mx-1" style="align-self:flex-start"><v-icon :class="folder_icon_css" small>fas fa-folder</v-icon></v-btn>
							<v-btn v-if="unit_showing==unit" color="primary" icon small class="mx-1" style="align-self:flex-start"><v-icon :class="folder_icon_css" small>fas fa-folder-open</v-icon></v-btn>
							<div class="k-collections-unit-list-item-title">{{unit.title}}</div>
							<v-spacer />
							<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" v-show="unit_todo_count[unit.lp_unit_id]>0" class="k-collections-unit-list-item-assignment-total">{{unit_todo_count[unit.lp_unit_id]}}</div></template>{{unit_todo_count[unit.lp_unit_id]}} {{unit_todo_count[unit.lp_unit_id]==1?'Resource':'Resources'}} To Do</v-tooltip>
						</div>
					</div>
					<div class="k-collections-unit-list-item k-collections-unit--last"></div>
				</div>

				<div v-show="unit_showing" class="k-collections-unit-content-wrapper">
					<CollectionUnitResources v-if="unit_showing"
						:collection="collection" :unit="unit_showing"
						:is_collection_admin="is_collection_admin"
						@hide_unit="hide_unit"
					></CollectionUnitResources>
				</div>
			</div>

		</div>
		<CollectionEdit v-if="show_collection_editor" :learning_progression="collection" @editor_cancel="collection_edit_requested=false"></CollectionEdit>
		<TodoReportCollection v-if="show_todo_report" :collection="collection" @dialog_cancel="show_todo_report=false" />
	</div></div>
</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import CollectionUnitResources from './CollectionUnitResources'
import CollectionEdit from './CollectionEdit'
import TodoReportCollection from './../reports/TodoReportCollection'

export default {
	components: { CollectionUnitResources, CollectionEdit, TodoReportCollection },
	props: {
		// note that these two props come from vuerouter
		// collection_id: { type: String, required: true },
		course_code: { type: String, required: true },
		unit_id: { type: String, required: true },
		show_collection_only: { type: Boolean, required: false, default() { return false }},
		custom_class: { type: String, required: false, default() { return '' }},
	},
	data() { return {
		initialized: 0,
		unit_showing_val: null,
		show_todo_report: false,
		// this is set to true when the user requests to edit; then we show the editor if the lp isn't locked for editing
		collection_edit_requested: false,
	}},
	computed: {
		...mapState(['user_info', 'all_courses', 'lp_edit_locked']),
		...mapGetters(['role']),
		collection_id() {
			// for HenryConnects, the course_code is used as the collection_id
			return this.course_code
		},
		user_is_teacher() {
			return this.user_info.role == 'staff'
		},
		is_collection_admin() {
			if (this.initialized <= 0) return

			return this.collection.user_is_lp_admin()
		},
		show_collection_editor() {
			// show the editor if the user has requested to edit and the lp isn't locked for editing (lp_edit_locked[this.course_code] must have been explicitly set to false)
			if (this.collection_edit_requested != true) return false
			if (empty(this.lp_edit_locked[this.collection_id]) || this.lp_edit_locked[this.collection_id] === true) return false
			return true
		},
		can_view_report() {
			// if this is a PG/SC collection, don't enable the view report controls
			if (this.collection.subject == 'PG' || this.collection.subject == 'SC') return false
			return vapp.has_admin_right('pd_rep.any')
		},
		collection() {
			if (this.initialized <= 0) return {}

			// we always pull from all_courses here, not the user's individual courses
			let l = this.all_courses.find(o=>o.course_code == this.course_code)
			if (empty(l)) return {}
			return l
		},
		page_title() {
			return sr('<b>$1</b>', this.collection.title)
		},
		top_css_class() {
			let s = ''
			if (!this.show_collection_only) {
				s = 'k-collections-view-wrapper k-page-wrapper '
				s += vapp.color_from_number(this.collection.course_code) + '-border'
			}
			if (this.unit_showing) s += ' k-collections-view-wrapper--unit-showing'

			// add custom_class (blank by default)
			s += ' ' + this.custom_class

			return s
		},
		folder_icon_css() {
			return U.subject_tile_css(this.collection) + '-text'
		},
		collection_css_class() {
			let s = vapp.color_from_number(this.collection.course_code) + '-light'
			s += ' ' + vapp.color_from_number(this.collection.course_code) + '-border'
			return s
		},
		collection_description_css_class() {
			let s = vapp.color_from_number(this.collection.course_code) + '-border'
			return s
		},
		unit_css_class() {
			if (empty(this.collection.units)) return []
			let arr = []
			for (let i = 0; i < this.collection.units.length; ++i) {
				let s = ''
				if (i == 0) s += ' k-collections-unit--first'
				// if (i == this.collection.units.length-1) s += ' k-collections-unit--last'

				arr.push(s)
			}
			return arr
		},
		unit_showing() {
			if (empty(this.collection.units)) return

			// unit_id either comes from the url via vuerouter, in which case the router will update it when necessary,
			// or it's sent in by the including component, in which case it'll be sent in as 0 and we'll manipulate unit_showing_val directly below

			// if unit_id is something other than 0, get the unit to show;
			// otherwise use the last unit showing (which could be null)
			if (this.unit_id != 0) {
				let u = this.collection.units.find(x=>x.lp_unit_id == this.unit_id)
				if (empty(u)) return null
				this.unit_showing_val = u
			}
			return this.unit_showing_val
		},
		unit_todo_count() {
			let o = {}

			// for each unit
			for (let unit of this.collection.units) {
				o[unit.lp_unit_id] = 0

				// for each resource in the unit
				for (let resource of unit.resources) {
					// if the resource is marked todo
					if (resource.todo) {
						// then if the user hasn't completed it, add to the count
						// note that the todo_status for a video may be 5-95, indicating partial completion
						if (!(this.user_info.todo_status[resource.resource_id] > 100)) {
							++o[unit.lp_unit_id]
						}
					}
				}
			}

			return o
		},
	},
	created() {
		console.log('collection created; subject = ' + this.collection.subject + ' / role = ' + this.role)

		// don't show collections to people who don't have the right role. start by assuming it *is* available
		let available = true
		// staff and admins can see anything; parents can't see PD collections; students cant see any collections
		if (this.role == 'parent' && this.collection.subject == 'PD') {
			available = false
		} else if (this.role == 'student') {
			available = false
		}
		if (!available) {
			console.log('not available!', this.role, this.collection.subject)

			vapp.go_to_home('classes')
		}
	},
	mounted() {
		this.initialize()
	},
	methods: {
		initialize() {
			// if we don't already have the full collection data for this course...
			if (empty(this.collection.lp_id)) {
				// if title is empty and the user is an admin, we must be creating it now, so go right to the editor
				if (this.collection.title == '' && this.is_collection_admin) {
					++this.initialized
					this.edit_collection()
					return
				}

				// try to load it now
				this.$store.dispatch('get_learning_progression', this.course_code).then((found)=>{
					if (!found) {
						this.$alert('This collection id was not found.')
						return
					}

					this.$nextTick(()=>{
						++this.initialized
					})

				}).catch((e)=>{
					console.log(e)
					this.$alert('error in get_collection?')
				})

			} else {
				++this.initialized
			}
		},

		hide_unit() {
			if (!empty(this.unit_showing)) {
				// if we're only showing the collection, we're not using the router, so just set unit_showing_val to null
				if (this.show_collection_only) {
					this.unit_showing_val = null

				} else {
					// otherwise use the router; when we hide the unit, we have to set unit_showing_val to null, but after a tick to let unit_id get to 0
					this.$nextTick(()=>this.unit_showing_val = null)
					this.$router.push({ path: sr('/$1/$2/$3', this.collection.subject.toLowerCase(), this.collection_id, 0) })
				}
			}
		},

		show_unit(unit) {
			if (empty(this.unit_showing) || unit != this.unit_showing) {
				// if we're only showing the collection, we're not using the router, so just set unit_showing_val to the unit
				if (this.show_collection_only) {
					this.unit_showing_val = unit
				} else {
					this.$router.push({ path: sr('/$1/$2/$3', this.collection.subject.toLowerCase(), this.collection_id, unit.lp_unit_id) })
				}
			}
		},

		toggle_unit(unit) {
			if (!empty(this.unit_showing) && unit == this.unit_showing) this.hide_unit()
			else this.show_unit(unit)
		},

		edit_collection() {
			// if the lp is already saved (i.e. it isn't brand new), we have to request to edit it, to make sure no one else already has it opened for editing
			if (!empty(this.collection_id) && this.collection.lp_id != 0) {
				this.$store.dispatch('lp_edit_socketmsg', this.collection_id)
			}

			// flag that we've requested to edit the LP; once the socket message comes back saying we're OK to edit, the show_lp_editor computed will open it for editing
			this.collection_edit_requested = true

			//this.show_collection_editor = true
			// this.hide_unit()
		},

		safari_search() {
			let o = {
				tool: 'safari',
				endpoint: this.$store.state.safari_lti_endpoint,
				search_params: {}
			}
			this.$store.dispatch('lti_launch', o)
		},
	}
}
</script>

<style lang="scss">
.k-collections-view-wrapper {
	margin-top:20px!important;
	border-top:5px solid transparent;
	border-bottom:5px solid transparent;

	.k-page-title {
		margin-bottom:0;
	}

	.k-safari_btn {
		height:36px;
		display:inline-block;
	    cursor: pointer;
	    background-color: #fff;
	    border-radius: 10px;
	    padding: 3px 16px;
	    vertical-align: middle;
		img {
			height: 30px;
		}
	}
}

.k-collections-view-wrapper-inner {
	max-width: 1000px;
	margin:0 auto;
}

.k-collections-collection-description {
	background-color:#fff;
	color:#000;
	max-width:680px;
	margin:20px auto 0 auto;
	padding:4px 16px;
	border-radius:8px;
	font-size:16px;
	border:3px solid transparent;	// color will be overwritten
}

.k-collections-units-outer-wrapper {
	display:flex;
	background-color:#fff;
	border-radius:10px;
	// padding:8px;
	max-width:680px;
	margin:20px auto 16px auto;
	border:3px solid transparent;	// color will be overwritten
}

.k-collections-units-outer-wrapper--unit-showing {
	max-width:none;
}

.k-collections-units-list {
	flex:1 1 35%;
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
}

.k-collections-unit-content-wrapper {
	flex:1 1 65%;
	border-radius:0 12px 12px 0;
	background-color:#fff;
}

.k-collections-unit-list-item {
	padding:2px 0;
}

.k-collections-unit-list-item--showing {
	background-color:#fff;
}

.k-collections-unit-title {
	display:flex;
	align-items: center;
	cursor:pointer;
	font-weight:bold;
	padding-left:4px;
}

.k-collections-unit--first {
	// border-radius:10px 0 0 0;
	margin-top:8px;
	// padding-top:4px;
}

.k-collections-unit--last {
	flex:1 1 auto;
	border-radius:0 0 0 10px;
	min-height:8px;
	// padding-bottom:8px;
}

.k-collections-unit-list-item--showing.k-collections-unit--last {
}

.k-collections-unit-list-item-assignment-total {
	margin-right:8px;
	margin-left:4px;
	font-size:12px;
	font-weight:bold;
	background-color:$v-pink-accent-3;
	color:#fff;
	flex:0 0 20px;
	width:20px;
	height:20px;
	border-radius:20px;
	line-height:20px;
	text-align:center;
}

</style>
