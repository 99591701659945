<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<div class="k-mycontent-item-title" style="cursor:pointer" @click="toggle_item_showing">
		<v-btn x-small fab color="orange lighten-1" class="mr-3 elevation-0"><v-icon>fas {{item.o.item_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>

		<v-icon v-if="!($vuetify.breakpoint.xs||tight_width)" class="mr-2" color="primary">{{item.icon}}</v-icon>
		<div v-if="!($vuetify.breakpoint.xs||tight_width)" style="cursor:pointer" class="k-lesson-title" v-html="item.title"></div>
		<v-spacer v-if="!($vuetify.breakpoint.xs||tight_width)" />
		<div v-if="!($vuetify.breakpoint.xs||tight_width)" class="k-lesson-course-unit-header" v-html="course_unit_header"></div>
		<div v-if="!($vuetify.breakpoint.xs||tight_width)" class="mr-2" v-html="date_header"></div>

		<div v-if="($vuetify.breakpoint.xs||tight_width)" style="flex:1 0 auto">
			<div class="mx-1 float-right" v-html="date_header"></div>
			<div class="k-lesson-title" v-html="item.title"></div>
			<div class="k-lesson-course-unit-header text-left ml-0 mt-1" v-html="course_unit_header"></div>
		</div>
		<!-- <v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="is_lesson_editor&&manage_assignments" v-show="$vuetify.breakpoint.smAndUp&&!tight_width&&item.o.item_showing" class="ml-1 mr-1" fab x-small dark color="pink accent-4" @click="share_lesson"><v-icon small>fas fa-share-nodes</v-icon></v-btn></template>Share {{activity_type_label}}</v-tooltip> -->
		<!-- <v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="is_lesson_editor" v-show="$vuetify.breakpoint.smAndUp&&!tight_width&&item.o.item_showing&&!lesson_being_edited" class="ml-1 mr-1" fab x-small dark color="#333" @click="print_lesson"><v-icon small>fas fa-print</v-icon></v-btn></template>Print Lesson Plan</v-tooltip> -->
		
		<!-- <v-btn small icon class="mr-1" @click.stop="lesson_expanded=!lesson_expanded"><v-icon small>fas {{lesson_expanded?'fa-compress':'fa-expand'}}</v-icon></v-btn> -->

		<v-menu offset-y bottom right><template v-slot:activator="{on}"><v-btn v-on="on" v-if="is_lesson_editor" v-show="$vuetify.breakpoint.smAndUp&&item.o.item_showing&&!lesson_being_edited" fab x-small color="primary" @click.stop=""><v-icon small>fas fa-bolt</v-icon></v-btn></template>
			<v-list dense>
				<!-- <v-list-item v-if="true" click.stop="add_to_gc"><v-list-item-icon><img src="./../../images/google_classroom_logo.png" style="height:18px;margin-top:3px;margin-left:-4px;border-radius:3px;"></v-list-item-icon><v-list-item-title>Add to Google Classroom</v-list-item-title></v-list-item> -->
				<v-list-item @click="share_lesson"><v-list-item-icon><v-icon small color="purple">fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Share {{activity_type_label}}</v-list-item-title></v-list-item>
				<v-list-item @click="print_lesson"><v-list-item-icon><v-icon small color="#666">fas fa-print</v-icon></v-list-item-icon><v-list-item-title>Print {{activity_type_label}}</v-list-item-title></v-list-item>
				<v-list-item @click="lesson_being_edited=true"><v-list-item-icon><v-icon small color="light-blue">fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit {{activity_type_label}}</v-list-item-title></v-list-item>
				<v-list-item @click="archive_lesson"><v-list-item-icon><v-icon small color="orange">fas fa-box-archive</v-icon></v-list-item-icon><v-list-item-title>Archive {{activity_type_label}}</v-list-item-title></v-list-item>
				<v-list-item @click="delete_lesson"><v-list-item-icon><v-icon small color="red">fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete {{activity_type_label}}</v-list-item-title></v-list-item>
				<!-- <v-list-item @click.stop="copy_link_to_clipboard"><v-list-item-icon><v-icon small>fas fa-link</v-icon></v-list-item-icon><v-list-item-title>Copy link to clipboard</v-list-item-title></v-list-item> -->
			</v-list>
		</v-menu>
	</div>

	<v-expand-transition><div v-if="item.o.item_showing">
		<div v-if="lesson.activity_type=='lesson'">
			<LessonView v-if="!lesson_being_edited" :lesson="item.o" :print_lesson_dom_id="print_lesson_dom_id" :title_showing="false" />
			<div v-if="lesson_being_edited" class="k-lesson-edit-outer">
				<LessonEditor :original_lesson="lesson" :course_code="course_code" :lp_unit_id="lp_unit_id" lesson_class="teacher" @edit_lesson_cancel="edit_lesson_cancel" @edit_lesson_saved="edit_lesson_saved"  />
			</div>
		</div>
		<div v-else>
			<div>
				<ActivityView v-if="!lesson_being_edited" :lesson="item.o" :print_lesson_dom_id="print_lesson_dom_id" :title_showing="false" />
				<div v-if="lesson_being_edited">
					<ActivityEditor :original_lesson="lesson" :course_code="course_code" :lp_unit_id="lp_unit_id" lesson_class="teacher" @edit_lesson_cancel="edit_lesson_cancel" @edit_lesson_saved="edit_lesson_saved" />
				</div>
			</div>
		</div>
	</div></v-expand-transition>

	<LessonPrint v-if="show_print_dialog" :lesson="lesson" :print_lesson_dom_id="print_lesson_dom_id" @dialog_cancel="show_print_dialog=false" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import LessonView from '../lessons/LessonView'
import LessonEditor from '../lessons/LessonEditor'
import ActivityView from '../lessons/ActivityView'
import ActivityEditor from '../lessons/ActivityEditor'
import LessonPrint from '../lessons/LessonPrint'

export default {
	components: { LessonView, LessonEditor, ActivityView, ActivityEditor, LessonPrint },
	props: {
		item: { type: Object, required: true },
		course_code: { type: String, required: false, default() { return '' } },
		lp_unit_id: { type: Number, required: false, default() { return 0 } },
		tight_width: { type: Boolean, required: false, default() { return false } },
	},
	data() { return {
		lesson_being_edited: false,
		lesson_expanded: false,
		show_print_dialog: false,
		print_lesson_dom_id: U.new_uuid(),
	}},
	computed: {
		...mapState(['user_info', 'my_lessons']),
		...mapGetters(['role', 'system_role', 'manage_assignments']),
		lesson() { return this.item.o },
		is_lesson_editor() { return this.role=='staff' || this.role=='admin' },
		course_unit_header() { return this.lesson.course_unit_header() },
		date_header() { return this.lesson.date_header() },
		// TEMP: determine if the lesson is actually an activity
		lesson_is_activity() { return this.lesson.lesson_master_id != this.$store.state.default_lesson_master.lesson_id },
		activity_type_icon() { return U.activity_type_icon(this.lesson.activity_type) },
		activity_type_label() { return U.activity_type_label(this.lesson.activity_type) },
	},
	watch: {
		'item.o.item_showing'(val) {
			// console.log('item.o.item_showing: ' + val, $(this.$el).find('.k-mycontent-item-title').length)
			// if showing, scroll to show the lesson
			if (val) {
				// this component might be rendered multiple places in the dom; only scroll if this one is actually visible
				if ($(this.$el).find('.k-mycontent-item-title').is(':visible')) {
					setTimeout(x=>this.$vuetify.goTo($(this.$el).find('.k-mycontent-item-title')[0], {offset:96}), 300)
				}
			}
		}
	},
	created() {
		// get lesson masters if we haven't already
		this.$store.dispatch('get_lesson_masters')
	},
	mounted() {
	},
	methods: {
		toggle_item_showing(val) {
			if (typeof(val) != 'boolean') val = !this.item.o.item_showing
			this.$store.commit('set', [this.item.o, 'item_showing', val])
		},

		edit_lesson_cancel() {
			this.lesson_being_edited = false
		},

		edit_lesson_saved(edited_lesson) {
			// the lesson editor will have saved the lesson to the db; splice to my_lessons
			let index = this.my_lessons.findIndex(x=>x.lesson_id == edited_lesson.lesson_id)
			if (index == -1) {
				// shouldn't ever happen

			} else {
				let new_lesson = new Lesson(edited_lesson)

				// preserve booleans for components that are showing
				new_lesson.item_showing = this.lesson.item_showing
				new_lesson.resources_showing = this.lesson.resources_showing
				new_lesson.standards_showing = this.lesson.standards_showing
				new_lesson.student_description_showing = this.lesson.student_description_showing

				this.my_lessons.splice(index, 1, new_lesson)
			}
		},

		delete_lesson() {
			this.$confirm({
			    title: 'Are you sure?',
			    text: 'Are you sure you want to delete this ' + this.activity_type_label + '?',
			    acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				U.loading_start()
				U.ajax('delete_lesson', {
					user_id: this.user_info.user_id,
					lesson_id: this.lesson.lesson_id,
				}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in ajax call'); vapp.ping(); return;
					}
					let index = this.my_lessons.findIndex(x=>x.lesson_id == this.lesson.lesson_id)
					this.my_lessons.splice(index, 1)
				});
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		archive_lesson() {

		},

		print_lesson() {
			this.show_print_dialog = true
		},

		share_lesson() {
			let msg = sr('<div class="mb-2">To share this $1, give the following $1 ID to your colleague:</div>', this.activity_type_label)
			msg += sr('<div class="mb-2 text-center" style="font-size:22px"><b>$1</b></div>', ('L' + this.lesson.lesson_id))
			msg += sr('<div style="font-size:14px; line-height:18px;">Your colleague can import this $1 by clicking the “+CREATE” button from the My Lessons area of a course or unit, choosing “Import shared lesson”, and entering this $1 ID.</div>', this.activity_type_label)

			this.$confirm({
				title: 'Share ' + this.activity_type_label,
				text: msg,
				cancelText: 'Copy Lesson ID',
				dialogMaxWidth: 600,
			}).catch(e=>{
				U.copy_to_clipboard('L' + this.lesson.lesson_id)
				this.$inform(sr('$1 ID copied to clipboard', this.activity_type_label))
			})
		},
	}
}
</script>

<style lang="scss">
</style>
