// vuex store for portal application
// Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// this imports the version number from package.json, so we can show the version number in the ui with $state.store.app_version; also need something in vue.config.js (PACKAGE_VERSION)
		// run the following to update the third number; use 'minor' to update the second number and 'major' to update the first number
		// npm --no-git-tag-version version patch; npm run build; npm run serve
		app_version: process.env.PACKAGE_VERSION || '0.0.0',

		now_date_obj: new Date(),
		now_date_string: '',
		old_threshold_date_timestamp: 0,
		old_threshold_date_string: '',

		use_google_signin: false,
		login_error: null,
		user_info: {},
		simulated_user_info: {},
		actual_user_info: {},

		allow_msft_signin: false,
		msft_initialize_signin_domain: '',

		single_item: false,		// this will be get set to true if we're in a view where only a single item is showing -- /activity or /lesson
		single_item_mode: '',	// set in MyContentView2

		searchable_links: {
			top_hits: [],
			school_links: [],
			schoolwire_nav_links: [],
			employee_links: [],
			student_links: [],
			parent_links: [],
			community_links: [],
		},

		content_files: {
			parent_announcements: '<p>This is the text that will show up at the start of the parent home view.</p>',
		},
		content_file_editor_title: {
			parent_announcements: 'Family Announcements',
		},

		available_academic_years: [],
		allow_academic_year_change_for_all_staff: false,
		grades: [],
		subjects: {},
		todo_user_group_divisions: {},
		todo_user_group_schools: {},
		todo_user_group_warning_issued: false,
		todo_report_group_showing: '',
		todo_report_group_data: {},

		// for SparklEmbed
		sparkl_origin: '',	// set from config in initialize
		pm_event_listener_created: false,
		sparkl_embed_components: {},
		activity_embed_maximized: false,

		safari_schools: [],
		safari_lti_endpoint: '',

		loaded_community_memberships: false,
		communities: [],	// communities this user is a member of
		forums: [],
		messages: [],
		message_load_timestamp: 0,	// used to determine which messages to retrieve
		posts: [],
		resources: [],	// TODO: not used??
		all_resources: [],
		all_communities: [],	// all communities -- used in the admin tool; possibly other places
		all_communities_loaded: false,
		last_viewed_resource_id: '',

		loaded_classes: false,
		sis_classes: [],
		added_my_courses: [],
		removed_my_courses: [],
		all_students: {},

		my_lessons: [],
		// my_lessons_by_course: {},	// not dealing with this for now...
		my_activities: [],
		my_coteachers: [],
		my_coteaching_courses: [],
		my_activities_by_course: {},
		my_activity_results: {},
		now: '',
		old_threshold: '',
		last_viewed_activity_or_lesson_id: '',

		beta_options_available: {},
		beta_options_on: {},

		editor_components: {},	// used for implementing the "add resource" button in the froala editor

		// For satchel...
		framework_records: [],
		frameworks_loading: false,
		frameworks_loaded: false,
		satchel_origin: 'https://hcs.satchelcommons.com',

		// remove the next two
		case_frameworks: {},	// used by the CASETree component to "cache" all loaded frameworks
		case_tree_showing: false,

		all_courses: [],
		all_courses_loaded: false,
		last_lp_list: null,	// used to know whether to go back to (the learning progression index page or the my classes page) from a lp page
		course_update_trigger: 0,

		open_assignment_editor: null,

		my_classes_view: 'assignments',		// which "classes" view is showing on the welcome page -- 'assignments' (which really means "my classes") or 'lpindex'
		welcome_section_showing: '',	// which "tab" is showing on the home page

		lesson_masters: [],
		default_lesson_master: null,

		todo_item: '',

		// TEMP
		lessons: [],

		google_client_id: '',

		froala_key: '',

		socket_status: 'not_started',	// not_started, started, running
		socket: null,
		socket_sent_messages: [],
		socket_timeout: null,
		henry_chatter_url: '',

		// this keeps track of whether or not lp's the user has requested to edit are currently locked
		lp_edit_locked: {},

		lp_showing: 0,

		// set this to true using the console to force resource launches to pause while the user can inspect the launch parameters:
		// vapp.$store.commit('set', ['debug_lti_resource_launches', true])
		debug_lti_resource_launches: false,

		color_select: [
			{ value: '', text: 'AUTO-GENERATED' },
			{ value: '1', text: 'Red' },
			{ value: '2', text: 'Magenta' },
			{ value: '3', text: 'Purple' },
			{ value: '4', text: 'Deep Purple' },
			{ value: '5', text: 'Indigo' },
			{ value: '6', text: 'Blue' },
			{ value: '7', text: 'Light Blue' },
			{ value: '8', text: 'Cyan' },
			{ value: '9', text: 'Teal' },
			{ value: '10', text: 'Green' },
			{ value: '11', text: 'Light Green' },
			{ value: '12', text: 'Lime' },
			{ value: '13', text: 'Yellow' },
			{ value: '14', text: 'Amber' },
			{ value: '15', text: 'Orange' },
			{ value: '16', text: 'Deep Orange' },
		],

		// Term settings, loaded from config
		// and writeable by admins back to config via admin Term Management UI
		term_settings: {},

		default_beta_options: {
			manage_assignments: false,
			show_safari: false,
		},

		// "local_storage settings": set defaults here; lst_initialize is called on initialization; call lst_set to set new values, possibly in computed:
		// lp_format_showing: {
		// 	get() { return this.$store.state.lst.lp_format_showing },
		// 	set(val) { this.$store.commit('lst_set', ['lp_format_showing', val]) }
		// },
		// @update:foo="(val)=>foo=val"
		lst: {
			simulated_date: '',
			simulated_role: '',
			simulated_user: '',
			
			child_email_showing: '',
			connections_showing: 'parent',
			// cv_unit_lessons_showing: true,

			lp_my_content_or_messages_mode: 'content',	// content, messages
			lp_showing_for_student: false,
			lp_unit_mode: 'lp',		// lp, standards, assignments
			lp_format_showing: {},	// one value for each course code

			froala_image_size: '500',
			froala_paste_mode: 'normal',
			froala_image_display: 'block',
			froala_image_align: 'center',
			// froala_image_style: '',
			froala_image_scaled_size: '',

			courseindex_opened_category: null,
			courseindex_opened_subcats: {},

			my_content_items_mode: 'list',	// gantt
			my_content_assigned_to_filter: {},
			show_older_items: false,
			term_for_assignments: 0,
			assignment_chooser_sections: {},	// see SectionChooser.vue
			message_chooser_sections: {},		// see SectionChooser.vue
			library_show_lessons: false,
			library_show_activities: false,

			todo_report_show_empty_users: false,

			// whenever we need to update the default beta options, change the date here and use the new lst value in the getter, lst_initialize, and beta_option_set below
			beta_options_2022_11_28: {},
		},
		lst_prefix: 'henryconnects_local_storage_setting_',
	},
	getters: {
		small_screen:(state) => {
			let val = vapp.$vuetify.breakpoint.height < 400 || vapp.$vuetify.breakpoint.width < 600
			return val
		},

		academic_year:(state) => { return state.user_info.academic_year },
		academic_year_display:(state) => { return state.user_info.academic_year + '–' + (state.user_info.academic_year*1+1) },

		// the user_info.system_role value indicates the highest role the user can adopt
		system_role:(state) => { return state.user_info.system_role },
		// but staff and admin users can adopt different roles; so usually when we want to do something that's role-based, we look at the user_info.role value, which reflects the role the user has currently chosen to adopt
		role:(state) => { return state.user_info.role },
		studentish_role:(state) => { return (state.user_info.role == 'student' || state.user_info.role == 'parent') },
		simulating_user:(state) => { return !empty(state.lst.simulated_user) },
		user_is_principal_or_ap:(state) => {
			return state.user_info.district_role.some(x=>(x == 'principal' || x == 'assistant principal' || x == 'principle' || x == 'assistant principle'))
		},
		child_count:(state) => {
			let i = 0
			for (let child_email in state.user_info.child_data) ++i
			return i
		},
		child_email_showing:(state) => {
			return state.lst.child_email_showing
		},
		child_data_showing:(state) => {
			if (state.lst.child_email_showing) return state.user_info.child_data[state.lst.child_email_showing]
			return {}
		},
		// it's crucial that beta_options be specified everywhere in mapGetters, so we can update default beta_options whenever we need to
		beta_options:(state) => { return state.lst.beta_options_2022_11_28 },
		manage_assignments:(state) => { 
			// put all students in manage_assignments mode
			if (state.user_info.role == 'student') return true

			// if beta_options_on.manage_assignments is on for this user or their department, return true
			if (!state.beta_options_on?.manage_assignments) {
				// console.log('no beta_options_on.manage_assignments settings')
			} else {
				// console.log(state.beta_options_on.manage_assignments)
				for (let val of state.beta_options_on.manage_assignments) {
					// if val is an email address, check for that email
					if (val.includes('@') && val.toLowerCase() == state.user_info.email.toLowerCase()) return true

					// else it should be a "district_department" -- e.g. a school
					if (state.user_info.district_department.includes(val)) return true
				}
			}

			// else return beta option; note that whether or not a beta option is showing may be determined by state.beta_options_available
			return state.lst.beta_options_2022_11_28.manage_assignments 
		},
		show_safari:(state, getters) => {
			// Safari is a beta feature, turn-onable only by system admins
			if (getters.beta_options.show_safari) return true

			// also turn it on for teachers in safari_schools
			for (let school of state.safari_schools) {
				if (state.user_info.district_department.includes(school)) return true

				// as per Brittany's instructions on 8/5/2022, turn on for all staff members -- if we get a safari_schools value of 'all'
				if (school == 'all' && (getters.role == 'staff' || getters.role == 'admin')) return true
			}

			return false
		},

		threaded_messages:(state) => {
			const threads = state.messages?.filter(message => message.message_level*1 === 0)
				.map(message => ({ ...message, replies: [] })) ?? []
			const level_one_messages = state.messages?.filter(message => message.message_level*1 === 1)
				.map(message => ({ ...message, replies: [] })) ?? []
			const level_two_messages = state.messages?.filter(message => message.message_level*1 === 2) ?? []

			level_two_messages.forEach(message => {
				const match = level_one_messages.find(a => a.message_id === message.parent_message_id)
				if (match) {
					match.replies.push(message)
				}
			})
			level_one_messages.forEach(message => {
				const match = threads.find(a => a.message_id === message.parent_message_id)
				if (match) {
					match.replies.push(message)
				}
			})

			return threads.map(thread => new window.Message(thread))
		},

		messages_for_student:(state, getters) => {
			// if role is parent, we only want to show messages for the selected student...
			if (state.user_info.role == 'parent') {
				const student_sourcedId = getters.child_data_showing.sourcedId
				if (!student_sourcedId) return []
				let arr = []
				for (let message of getters.threaded_messages) {
					if (message.recipients?.map(recipient => recipient.user_sourcedId)?.includes(student_sourcedId)) {
						arr.push(message)
					}
				}
				return arr
			}
		},

		unread_message_count:(state) => (course_code = "") => {
			// In main course view, show all unread messages
			if (course_code == "") return state.messages.filter(x=>!x.is_read && x.message_id !== 0).length

			// In course-specific view, show only unread messages for that course
			let count = 0
			for (let message of state.messages) {
				if (message.course_code == course_code) {
					if (!message.is_read && message.message_id !== 0) count++
				}
			}
			return count
		},

		// determine the current_term based on the term_settings and today's date
		current_term:(state) => {
			// return the first term for which today is within the start_date and end_date
			// note that we index terms starting at 1 (1, 2, 3, or 4)
			let today = date.format(state.now_date_obj, 'YYYY-MM-DD')
			for (let i = 0; i < state.term_settings.term_dates.length; ++i) {
				let td = state.term_settings.term_dates[i]
				if (today >= td.start_date && today <= td.end_date) {
					return (i+1)
				}
			}
		},

		activities_for_student:(state, getters) => {
			// If  role is parent, we only want to show activities for the current student...
			if (state.user_info.role == 'parent') {
				let arr = []
				for (let activity of state.my_activities) {
					let sc = getters.child_data_showing.sis_student_classes
					if (!sc || sc.findIndex(x=>x.course_code == activity.course_code) == -1) {
						continue
					}
					arr.push(activity)
				}
				return arr
			}
		},

		// list of the courses the user is teaching/taking only
		my_sis_courses:(state, getters) => {
			let arr = []
			for (let course of state.sis_classes) {
				// filter out courses with code 'xxx'
				if (course.course_code == 'xxx') continue
				// filter out duplicates
				if (arr.find(x=>x.course_code == course.course_code)) continue
				// filter out gifted courses, which are "X.2.X" in the "title_code"
				if (course.is_gifted_course()) continue
					
				let lp = state.all_courses.find(x=>x.course_code == course.course_code)

				// if we don't have an lp for this course_code...
				if (empty(lp)) {
					// skip it for staff/admin, but show for parent/student?
					if (state.role == 'staff' || state.role == 'admin') continue

					// no, just skip it altogether
					// continue

				} else if (lp.active == 'no') {
					// for inactive lp's, only show to admins

					if (!lp.user_is_lp_admin()) {
						continue
					}
				}

				// for student/parent, only show courses for current term (teachers always see all the courses they're teaching throughout the year)
				// PW: This is what I was working on last the evening of 9/18
				if (state.user_info.role == 'student' || state.user_info.role == 'parent') {
					// let current = true
					// let today = '2024-05-01'
					let today = date.format(state.now_date_obj, 'YYYY-MM-DD')
					let current = false
					for (let i = 0; i < state.term_settings.term_dates.length; ++i) {
						// if today is within the start_date and end_date of any term...
						let td = state.term_settings.term_dates[i]
						if (today >= td.start_date && today <= td.end_date) {
							// ...then if this course matches the term...
							if (course.class_matches_term(i+1)) {
								// this is a current course, so show it
								current = true
								break
							}
						}
					}
					if (!current) {
						// console.log('course doesn’t match term', extobj(course))
						continue
					}

					// for parent role, also only show classes for the currently-selected student
					if (state.user_info.role == 'parent') {
						let sc = getters.child_data_showing.sis_student_classes
						if (!sc || sc.findIndex(x=>x.course_code == course.course_code) == -1) {
							continue
						}
					}
				}

				arr.push(course)
			}
			return arr
		},

		// list of "My Courses": courses the user is teaching/taking (if any), minus "removed_my_courses", plus "added_my_courses"
		my_courses:(state, getters) => {
			// console.log('-------- LOADING COURSES --------')
			let arr = []
			for (let lp of getters.my_sis_courses) {
				// filter out courses in removed_my_courses
				if (state.removed_my_courses.find(x=>x == lp.course_code)) continue
				arr.push(lp)
			}

			// add courses whose course_codes are in added_my_courses (and which we haven't yet processed above)
			for (let course_code of state.added_my_courses) {
				// skip if we already processed the class above -- the class might, e.g., have been added to the teacher's sis_classes after they explicitly added it
				if (arr.find(x=>x.course_code==course_code)) continue

				let c = state.all_courses.find(x=>x.course_code == course_code)
				if (!empty(c)) {
					arr.push(new User_Course({course_code: course_code, titles: [c.title]}))
				}
			}
			return arr

			// NOTE: sis_classes will appear first, in alpha order, followed by added classes
		},
		
	},
	mutations: {
		set(state, payload) {
			// this.$store.commit('set', ['key', val])
			// update state property 'key' to value 'val'
			if (payload.length == 2) {
				state[payload[0]] = payload[1]
				return
			}

			var o = payload[0]
			var key = payload[1]
			var val = payload[2]

			// this.$store.commit('set', ['obj', 'key', val])
			// update property 'key' of 'o' to value 'val'
			if (typeof(o) == 'string') {
				if (state[o][key] == undefined) Vue.set(state[o], key, val)
				else state[o][key] = val
				return
			}

			// this.$store.commit('set', [obj, 'key', true])
			// this.$store.commit('set', [obj, ['level_1_key', 'level_2_key'], true])
			// this.$store.commit('set', [obj, 'PUSH', 1])	// push 1 onto obj, which must be an array in this case
			// update property of obj, **WHICH MUST BE PART OF STATE!**
			if (typeof(key) == 'string') {
				if (key == 'PUSH') {
					o.push(val)
				} else if (key == 'UNSHIFT') {
					o.unshift(val)
				} else if (key == 'SPLICE') {
					// if we got a fourth value in payload, add that value into the array; otherwise just take the val-th item out
					if (!empty(payload[3])) {
						o.splice(val, 1, payload[3])
					} else {
						o.splice(val, 1)
					}
				} else if (val == '*DELETE_FROM_STORE*') {
					// delete the val if it existed (if it didn't exist, we don't have to do anything)
					if (o[key] != undefined) Vue.delete(o, key)
				} else if (o[key] == undefined) {
					Vue.set(o, key, val)
				} else {
					o[key] = val
				}
			} else {
				for (var i = 0; i < key.length-1; ++i) {
					o = o[key[i]]
					if (empty(o)) {
						console.log('ERROR IN STORE.SET', key, val)
						return
					}
				}
				if (o[key[i]] == undefined) Vue.set(o, key[i], val)
				else o[key[i]] = val
			}

			// samples:
			// this.$store.commit('set', [this.exercise, ['temp', 'editing'], true])
			// this.$store.commit('set', [this.qstatus, 'started', true])
		},

		replace_in_array(state, payload) {
			// this.$store.commit('replace_in_array', [array, old_val, new_val])
			let arr = payload[0]

			// try to find the index of the old_val; caller can send either a value to look for directly, or a property and a value
			let i, new_val
			if (payload.length == 3) {
				let old_val = payload[1]
				new_val = payload[2]
				i = arr.findIndex(x=>x==old_val)
			} else {
				let prop = payload[1]
				let old_val = payload[2]
				new_val = payload[3]
				i = arr.findIndex(x=>x[prop]==old_val)
			}

			if (i > -1) {
				// if found, replace with new_val; have to use splice for reactive arrays (see vue documentation)
				arr.splice(i, 1, new_val)
			} else {
				// else push
				arr.push(new_val)
			}
		},

		splice_from_array(state, payload) {
			// this.$store.commit('splice_from_array', [array, old_val])
			let arr = payload[0]
			let old_val = payload[1]

			// try to find the index of the old_val
			let i = arr.findIndex(x=>x==old_val)
			if (i > -1) {
				// if found, replace with new_val; have to use splice for reactive arrays (see vue documentation)
				arr.splice(i, 1)
			}
		},

		splice_from_array_by_index(state, payload) {
			// this.$store.commit('splice_from_array', [array, old_val])
			let arr = payload[0]
			let i = payload[1]

			arr.splice(i, 1)
		},

		// consolidate students from all sis_classes into a single associative array
		process_students_from_sis_classes(state) {
			let o = {}
			for (let cl of state.sis_classes) {
				if (empty(cl.students)) continue
				for (let i = 0; i < cl.students.length; ++i) {
					let student_list = cl.students[i]
					if (empty(student_list)) continue
					for (let student of student_list) {
						if (empty(o[student.sourcedId])) {
							o[student.sourcedId] = student
							o[student.sourcedId].class_sourcedIds = [cl.class_sourcedIds[i]]
						} else {
							o[student.sourcedId].class_sourcedIds.push(cl.class_sourcedIds[i])
						}
					}
				}
			}
			state.all_students = o
		},

		trigger_course_update(state) {
			state.course_update_trigger += 1
		},

		// fns to initialize and set local_storage settings
		lst_initialize(state) {
			for (let key in state.lst) {
				let val = U.local_storage_get(state.lst_prefix + key)
				if (!empty(val)) {
					state.lst[key] = val
				}
			}

			// initialize beta_options specially, given that the options here will change periodically
			if (state.lst.beta_options_2022_11_28) {
				// console.log(JSON.stringify(state.lst.beta_options))
				for (let key in state.default_beta_options) {
					if (state.lst.beta_options_2022_11_28[key] === undefined) {
						Vue.set(state.lst.beta_options_2022_11_28, key, state.default_beta_options[key])
					}
				}
			}
		},

		// special setter for beta_options
		// this.$store.commit('beta_option_set', ['property', 'value'])
		beta_option_set(state, payload) {
			state.lst.beta_options_2022_11_28[payload[0]] = payload[1]
			U.local_storage_set(state.lst_prefix + 'beta_options_2022_11_28', state.lst.beta_options_2022_11_28)
		},

		// this.$store.commit('lst_set', ['mc_mode', 'bubbles'])
		lst_set(state, payload) {
			let key, val
			if (typeof(payload) == 'string') {
				// if a single string value is sent in, we just save in local_storage; presumably the changed value will have been already saved via set
				U.local_storage_set(state.lst_prefix + payload, state.lst[payload])
				return
			}

			if (Array.isArray(payload)) {
				key = payload[0]
				val = payload[1]
			} else {
				key = payload.key
				val = payload.val
			}

			// save in state
			state.lst[key] = val

			// now save in local_storage
			U.local_storage_set(state.lst_prefix + key, val)
		},

		lst_clear(state, key) {
			U.local_storage_clear(state.lst_prefix + key)
		},

		// this fn is set to run once a minute by initialize_app
		set_now_date(state) {
			// use now_date_obj everywhere instead of Date(), so that we can simulate a certain date for demo purposes
			// try to get simulated_date from lst
			state.simulated_date = state.lst.simulated_date		// '2023-02-04'
			if (!state.simulated_date) state.now_date_obj = new Date()
			else state.now_date_obj = date.parse(state.simulated_date + ' ' + date.format(new Date(), 'HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')
			
			// set now_date_string and old_threshold_date_string to the dates format needed to compare to lesson/activity dates
			state.now_date_string = date.format(state.now_date_obj, 'YYYY-MM-DD')
			state.old_threshold_date_timestamp = Math.round((state.now_date_obj - 7*24*60*60*1000) / 1000)
			state.old_threshold_date_string = date.format(new Date(state.now_date_obj - 7*24*60*60*1000), 'YYYY-MM-DD')	// one week prior to now
		},

		add_to_my_activities(state, activity) {
			if (activity.course_code) {
				if (!state.my_activities_by_course[activity.course_code]) {
					Vue.set(state.my_activities_by_course, activity.course_code, [])
				}
				state.my_activities_by_course[activity.course_code].push(activity)
			}
			state.my_activities.push(activity)
		},

		replace_in_my_activities(state, activity) {
			let index = state.my_activities.findIndex(x => x.activity_id == activity.activity_id)
			state.my_activities.splice(index, 1, activity)

			if (activity.course_code && state.my_activities_by_course[activity.course_code]) {
				let index = state.my_activities_by_course[activity.course_code].findIndex(x => x.activity_id == activity.activity_id)
				state.my_activities_by_course[activity.course_code].splice(index, 1, activity)
			}
		},

		remove_from_my_activities(state, activity) {
			let index = state.my_activities.findIndex(x => x.activity_id == activity.activity_id)
			state.my_activities.splice(index, 1)

			if (activity.course_code && state.my_activities_by_course[activity.course_code]) {
				let index = state.my_activities_by_course[activity.course_code].findIndex(x => x.activity_id == activity.activity_id)
				state.my_activities_by_course[activity.course_code].splice(index, 1)
			}
		},

	},
	actions: {
		initialize_app({state, commit, dispatch}, payload) {
			commit('lst_initialize')

			commit('set_now_date')
			setInterval(() => { commit('set_now_date') }, 60*1000)

			// if we're simulating another user, send in the simulated user's email here
			if (state.lst.simulated_user) payload.simulated_user = state.lst.simulated_user

			return new Promise((resolve, reject)=>{
				// BYPASS INITIALIZATION
				if (false) {
					commit('set', ['user_info', new User_Info({
						user_id: 1,
						first_name: 'Pepper',
						last_name: 'Williams',
						email: 'pw@pw.com',
						system_role: 'admin',
						role: 'admin',
					})])
					resolve('main')
					return
				}

				U.ajax('initialize_app', payload, result=>{
					if (result.status != 'ok') {
						console.log('Error in initialization!')
						reject()
						return
					}
					console.log('Initialized!', result)

					if (state.lst.simulated_user) {
						state.simulated_user_info = result.simulated_user_info
						if (!state.simulated_user_info) {
							vapp.$inform(`We were not able to simulated the chosen user ${state.lst.simulated_user}.`)
							commit('lst_set', ['simulated_user', ''])
						} else {
							state.actual_user_info = result.user_info
							result.user_info = result.simulated_user_info
						}
					}

					// backup use_google_signin option
					if (result.use_google_signin) commit('set', ['use_google_signin', true])
					// result should always include the google_client_id
					commit('set', ['google_client_id', result.google_client_id])

					// microsoft saml signin
					if (result.allow_msft_signin) commit('set', ['allow_msft_signin', true])
					// usually empty, except for docker where server port different than vue dev port
					if (!empty(result.msft_initialize_signin_domain)) {
						commit('set', ['msft_initialize_signin_domain', result.msft_initialize_signin_domain])
					}

					// store available_academic_years, grades, subjects, and todo_user_groups
					commit('set', ['available_academic_years', result.available_academic_years])
					commit('set', ['allow_academic_year_change_for_all_staff', result.allow_academic_year_change_for_all_staff])
					commit('set', ['safari_schools', result.safari_schools])
					commit('set', ['safari_lti_endpoint', result.safari_lti_endpoint])
					commit('set', ['grades', result.grades])
					commit('set', ['subjects', result.subjects])
					commit('set', ['todo_user_group_divisions', result.todo_user_group_divisions])
					commit('set', ['todo_user_group_schools', result.todo_user_group_schools])

					// if we didn't receive user_info, the user is not already logged in...
					if (empty(result.user_info)) {
						let msg = ''
						if (result.down_until) msg = sr('HenryConnects is temporarily down for maintenance. We should be back online by <b>$1</b>. ', result.down_until)
						if (result.login_error) msg += result.login_error
						commit('set', ['login_error', msg])
						resolve('login')
						return
					}

					// else we received user_info.  if we have a simulated_role in lst, add it to user_info; if this is '' (the default value), role will be set to system_role
					result.user_info.role = state.lst.simulated_role
					commit('set', ['user_info', new User_Info(result.user_info)])

					// if user is a parent, set child_email_showing...
					if (state.user_info.role == 'parent') {
						// if we already have a value that's valid, leave it alone; otherwise...
						if (!state.lst.child_email_showing || !state.user_info.child_data[state.lst.child_email_showing]) {
							// if we don't have any child_data entries, clear
							commit('lst_set', ['child_email_showing', ''])

							// otherwise set to the first-available value
							for (let child_email in state.user_info.child_data) {
								commit('lst_set', ['child_email_showing', child_email])
								break
							}
						}
					}

					// store searchable_links
					// make sure we have a top_hits array, even if it's empty
					if (empty(result.searchable_links.top_hits)) {
						result.searchable_links.top_hits = []
						// result.searchable_links.top_hits = [new Link({
						// 	text: '2019-20 School Calendar',
						// 	href: 'https://schoolwires.henry.k12.ga.us/cms/lib/GA01000549/Centricity/Domain/36/2019-2020_HCS_Calendar%202.pdf',
						// })]
					}
					commit('set', ['searchable_links', result.searchable_links])

					// store other incoming content files; could be different sets of files depending on user role
					if (result.content_files) {
						for (let key in result.content_files) {
							commit('set', [state.content_files, key, result.content_files[key]])
						}
					}

					// restore todo_item from local_storage
					commit('set', ['todo_item', U.local_storage_get('district_portal_todo_item', '')])

					// include the term_settings from config file
					commit('set', ['term_settings', result.term_settings])

					// transfer other things we receive from the server to the state
					state.froala_key = result.froala_key
					state.beta_options_available = result.beta_options_available
					state.beta_options_on = result.beta_options_on

					// sparkl origin domain (e.g. 'https://sparkl-ed.com')
					state.sparkl_origin = result.sparkl_origin

					// for local testing... but this is dangerous, because some services that manipulate data will still be going to the server defined by the sparkl_origin value in the config file
					// if (state.user_info.role == 'student' || state.user_info.role == 'parent') state.sparkl_origin = 'http://localhost:8071'
					// else state.sparkl_origin = 'http://localhost:8070'
	
					commit('set', ['henry_chatter_url', result.henry_chatter_url])

					resolve('main')
				});
			})
		},

		get_classes({state, commit, dispatch}) {
			let payload = {
				user_id: state.user_info.user_id,
				single_item: state.single_item,		// send this so that initialize knows it only needs to send back info about the single item
				system_role: state.user_info.system_role,	// we might be simulating another user; if so, for get_classes, we want to simulate that other user's system_role
				role: state.user_info.role,
			}

			// for students, send in sis_user_sourcedId and sis_class_sourcedIds
			if (state.user_info.system_role == 'student') {
				payload.sis_user_sourcedId = state.user_info.sis_user_sourcedId
				payload.sis_class_sourcedIds = state.user_info.sis_class_sourcedIds
			}

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('get_classes', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error retrieving class data')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					console.log('get_classes', result)

					// add added_my_courses and removed_my_courses
					if (!empty(result.added_my_courses)) commit('set', ['added_my_courses', result.added_my_courses])
					if (!empty(result.removed_my_courses)) commit('set', ['removed_my_courses', result.removed_my_courses])

					// if we didn't get any sis_classes OR added_my_courses, set my_classes_view to 'lpindex', since we don't have any classes to show
					if ((empty(result.classes) || result.classes.length == 0) && state.added_my_courses.length == 0) {
						state.my_classes_view = 'lpindex'

					} else {
						// sort sis_classes by title (added_my_courses will appear after these in the my courses list)
						result.classes.sort((a,b) => {
							if (a.titles[0] > b.titles[0]) return 1
							if (a.titles[0] < b.titles[0]) return -1
							return 0
						})

						for (let class_data of result.classes) {
							commit('set', [state.sis_classes, 'PUSH', new window.User_Course(class_data)])
						}
						// for teachers, create object with all students here
						if (state.user_info.role == 'staff') {
							commit('process_students_from_sis_classes')
						}
					}

					// process incoming lp's
					for (let lp_data of result.learning_progressions) {
						let lp = state.all_courses.find(x=>x.course_code==lp_data.course_code)
						if (empty(lp)) {
							lp = new Learning_Progression(lp_data)
							commit('set', [state.all_courses, 'PUSH', lp])
						}
					}

					// if teacher, process incoming coteacher info
					if (state.user_info.role == 'staff') {
						for (let coteacher of result.my_coteachers) {
							commit('set', [state.my_coteachers, 'PUSH', new Coteacher(coteacher)])
						}
						for (let course of result.my_coteaching_courses) {
							commit('set', [state.my_coteaching_courses, 'PUSH', course])
						}
					}

					// process incoming my_lessons, my_activities, and my_activity_results
					let arr = []
					// we may need to do the my_lessons_by_course thing later...
					if (result.my_lessons) for (let o of result.my_lessons) arr.push(new Lesson(o))
					state.my_lessons = arr

					state.my_activities_by_course = {}
					state.my_activities = []
					if (result.my_activities) {
						for (let o of result.my_activities) {
							o = new Activity(o)
							commit('add_to_my_activities', o)
						}
					}

					let o = {}
					if (result.my_activity_results) for (let activity_id in result.my_activity_results) o[activity_id+''] = new Activity_Result(result.my_activity_results[activity_id])
					state.my_activity_results = o

					// TEMP?
					// state.lessons = result.lessons

					commit('set', ['loaded_classes', true])

					// return result so the caller can look for communities_joined
					resolve(result)
				});
			})
		},

		change_academic_year({state, commit, dispatch}, academic_year) {
			let payload = {
				user_id: state.user_info.user_id,
				academic_year: academic_year,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('change_academic_year', payload, result=>{
					state.user_info.academic_year = academic_year
					document.location.reload()

					U.loading_stop()

					resolve()
				});
			})
		},

		update_my_courses({state, commit, dispatch}, payload) {
			// payload should include `course_code` and `action`, which should be `add` or `remove`
			// add user_id to payload
			payload.user_id = state.user_info.user_id
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('update_my_courses', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error updating My Courses.')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// add or remove course to/from added_my_courses and removed_my_courses
					if (payload.action == 'add') {
						// but don't add to added_my_courses if the course is in sis_classes
						if (!state.sis_classes.find(x=>x.course_code==payload.course_code)) {
							commit('set', [state.added_my_courses, 'PUSH', payload.course_code])
						}
						commit('splice_from_array', [state.removed_my_courses, payload.course_code])
					} else {
						commit('set', [state.removed_my_courses, 'PUSH', payload.course_code])
						commit('splice_from_array', [state.added_my_courses, payload.course_code])
					}

					resolve()
				});
			})
		},

		get_learning_progression({state, commit, dispatch}, course_code) {
			let payload = {
				user_id: state.user_info.user_id,
				course_code: course_code,
				// get resources and resource_collections for all users, including parents/students, since they might have access to these resources if they're enrolled in the course
				// (LpUnitResourceCollectionTree will determine exactly what they have access to)
				retrieve_resources: 'yes',
				retrieve_resource_collections: 'yes',
			}
			// for teachers (even if they're viewing as a student or parent), retrieve additional info that students don't need
			if (state.user_info.system_role == 'staff' || state.user_info.system_role == 'admin') {
				payload.retrieve_case = 'yes'
				payload.retrieve_professional_development_resources = 'yes'
			}

			return new Promise((resolve, reject)=>{
				// if we already have the LP fully loaded, don't replace it
				let c = state.all_courses.find(o=>o.course_code == course_code)
				if (c && c.fully_loaded) {
					resolve(true)
					return
				}

				U.loading_start()
				U.ajax('get_learning_progression', payload, result=>{
					U.loading_stop()

					// if not found, create a "stub" lp for the course
					if (result.status == 'not_found') {
						resolve(false)
						return
					}

					if (result.status != 'ok') {
						console.log('Error retrieving learning progression')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					// console.log('lp data for course_code ' + course_code, result)

					// if the lp already exists, don't do anything (it may have been loading via another service prior to this fn running)
					let i = state.all_courses.findIndex(o=>o.course_code == course_code)
					if (i == -1 || state.all_courses[i].units.length == 0 || state.all_courses[i].fully_loaded != true) {
						// set fully_loaded to true
						result.learning_progression.fully_loaded = true
						
						let lp = new Learning_Progression(result.learning_progression)

						if (i == -1) {
							// push onto all_courses
							commit('set', [state.all_courses, 'PUSH', lp])
						} else {
							state.all_courses.splice(i, 1, lp)
						}
					}

					resolve(true)
				});
			})
		},

		save_learning_progression({state, commit, dispatch}, lp) {
			let payload = {
				user_id: state.user_info.user_id,
				lp_data: JSON.stringify(lp.copy_for_save())
			}

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_learning_progression', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error updating learning progression')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					// trigger updates
					commit('trigger_course_update')
					resolve()
				});
			})
		},

		delete_learning_progression({state, commit, dispatch}, lp) {
			let payload = {
				user_id: state.user_info.user_id,
				course_code: lp.course_code,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('delete_learning_progression', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error deleting learning progression')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// splice out of all_courses array
					let index = state.all_courses.findIndex(o=>o == lp)
					state.all_courses.splice(index, 1)

					resolve()
				});
			})
		},

		duplicate_learning_progressions({state, commit, dispatch}, course_codes) {
			let payload = {
				user_id: state.user_info.user_id,
				course_codes: course_codes,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('duplicate_learning_progression', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error duplicating learning progression')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// reload all courses to get duplicates
					dispatch('get_all_courses')

					resolve()
				});
			})
		},

		save_collection({state, commit, dispatch}, lp) {
			let payload = {
				user_id: state.user_info.user_id,
				lp_data: JSON.stringify(lp.copy_for_save())
			}

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_collection', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error updating collection')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					// trigger updates
					commit('trigger_course_update')
					resolve(result)
				});
			})
		},

		save_file({state, commit, dispatch}, payload) {
			// payload should include `filename` and `html`
			payload.user_id = state.user_info.user_id

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_file', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error updating file')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// put new html in content_files
					state.content_files[payload.filename] = payload.html

					resolve()
				});
			})
		},

		save_resource({state, commit, dispatch}, args) {
			let payload = args.resource.copy_for_save()

			payload.user_id = state.user_info.user_id

			// if (!empty(args.uploaded_file_data)) {
			// 	payload.uploaded_file_data = args.uploaded_file_data
			// }
			let override_options = null
			if (!empty(args.uploaded_file)) {
				// file upload
				if (payload.type == 'upload') {
					let fd = new FormData()
					fd.append('file', args.uploaded_file)
					for (let key in payload) {
						fd.append(key, payload[key])
					}
					payload = fd
					override_options = {contentType: false, processData: false}
				} else {
					// html, entered in the resource editor directly
					payload.html = args.uploaded_file
				}
			}

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_resource', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error updating resource')
						vapp.ping()		// call ping to check if the session is expired
						reject(result.status)
						return
					}

					// resolve with the resource data
					resolve(result.resource)
				}, override_options);
			})
		},

		delete_resource({state, commit, dispatch}, resource) {
			let payload = {
				user_id: state.user_info.user_id,
				resource_id: resource.resource_id,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('delete_resource', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error deleting resource')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// caller is responsible for keeping track of the resource...
					resolve(resource.resource_id)
				});
			})
		},

		save_resource_collection({state, commit, dispatch}, payload) {
			// payload should contain 'resource_collection_title', 'resource_collection_json' and 'resources' (array)
			payload.user_id = state.user_info.user_id

			// stringify json and array
			payload.resource_collection_json = JSON.stringify(payload.resource_collection_json)
			payload.resources = JSON.stringify(payload.resources)

			console.log('upload size: ' + (payload.resource_collection_json.length + payload.resources.length))

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_resource_collection', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error saving resource collection')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// resolve with new_resource_collection_id
					resolve(result.new_resource_collection_id)
				})
			})
		},

		save_resource_completion({state, commit, dispatch}, payload) {
			// payload should contain 'resource_id' and 'todo_status', which can be 0 (not complete), 100 (complete), or 5-95 (partially complete video); add user_id
			payload.user_id = state.user_info.user_id

			return new Promise((resolve, reject)=>{
				U.ajax('save_resource_completion', payload, result=>{
					if (result.status != 'ok') {
						console.log('Error saving resource completion')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// completion timestamp should come back from service if set; otherwise we'll get back the todo_status we sent in; either way, save in store
					commit('set', [state.user_info.todo_status, payload.resource_id, result.todo_status])

					// note that this may be called without a then()
					resolve()
				})
			})
		},

		save_todo_user_group({state, commit, dispatch}, payload) {
			// payload should contain 'todo_user_id' and 'todo_user_group' (a uuid); add the signed-in user's user_id
			payload.user_id = state.user_info.user_id

			// if todo_user_group is empty, send '*CLEAR*' to clear it out
			if (payload.todo_user_group == '') payload.todo_user_group = '*CLEAR*'

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_todo_user_group', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in save_todo_user_group')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// if todo_user_id is the signed-in user, set todo_user_group for state.user_info
					if (payload.todo_user_id == state.user_info.user_id) {
						if (payload.todo_user_group == '*CLEAR*') {
							commit('set', [state.user_info, 'todo_user_group', ''])
						} else {
							commit('set', [state.user_info, 'todo_user_group', payload.todo_user_group])
						}
					}

					// note that this may be called without a then()
					resolve()
				})
			})
		},

		get_todo_report_data({state, commit, dispatch}, payload) {
			// payload should contain 'todo_user_group' (a uuid, or 'all' for all groups); add user_id
			payload.user_id = state.user_info.user_id

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('get_todo_report_data', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in get_todo_report_data')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					commit('set', [state.todo_report_group_data, payload.todo_user_group, result.todo_report_data])

					// note that this may be called without a then()
					resolve()
				})
			})
		},

		get_all_courses({state, commit, dispatch}) {
			let payload = {
				user_id: state.user_info.user_id,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('get_all_courses', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error retrieving all courses')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					for (let course of result.all_courses) {
						let lp = new Learning_Progression(course)
						// if we already have the course in all_courses and it has an lp_id...
						let index = state.all_courses.findIndex(o=>o.course_code == course.course_code)
						if (index > -1) {
							// then only overwrite what we already have if lp_id is 0
							if (state.all_courses[index].lp_id == 0) {
								state.all_courses[index] = lp
							}
						} else {
							// else push onto all_courses
							commit('set', [state.all_courses, 'PUSH', lp])
						}
					}
					commit('set', ['all_courses_loaded', true])

					resolve()
				});
			})
		},

		save_activity({state, commit, dispatch}, payload) {
			// payload must include:
			// activity_data, which should have been run through Activity.copy_for_save and will be stringified here
			// optional: assignees, which should have been run through Assignee.copy_for_save and will be stringified here if provided
			// optional: suppress_loader (boolean)
			// TODO: deal with activity_class ('teacher' or 'template')
			payload.activity_data = JSON.stringify(payload.activity_data)
			if (payload.assignees) payload.assignees = JSON.stringify(payload.assignees)
			payload.user_id = state.user_info.user_id
			return new Promise((resolve, reject)=>{
				let suppress_loader = payload.suppress_loader
				if (suppress_loader) delete payload.suppress_loader
				else U.loading_start(payload.loader_msg)	// loader_msg will be set if we're updating IC gradebook settings
				delete payload.loader_msg

				U.ajax('save_activity', payload, result=>{
					if (!suppress_loader) U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in save_activity call'); vapp.ping(); reject(result); return;
					}

					// note that it's up to the caller to save changes to store
					resolve(result)
				});
			})
		},

		save_lesson({state, commit, dispatch}, payload) {
			// payload must include:
			// lesson_class ('master', 'teacher' or 'template')
			// lesson_data, which should have been run through Activity.copy_for_save and will be stringified here
			// optional: suppress_loader (boolean)
			payload.lesson_data = JSON.stringify(payload.lesson_data)
			payload.user_id = state.user_info.user_id
			return new Promise((resolve, reject)=>{
				let suppress_loader = payload.suppress_loader
				if (suppress_loader) delete payload.suppress_loader
				else U.loading_start()

				U.ajax('save_lesson', payload, result=>{
					if (!suppress_loader) U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in save_lesson call'); vapp.ping(); reject(); return;
					}

					// note that it's up to the caller to save changes to store
					resolve(result)
				});
			})
		},

		save_my_activity_result({state, commit, dispatch}, activity_id) {
			return new Promise((resolve, reject)=>{
				let ar = state.my_activity_results[activity_id]
				if (empty(ar)) {	// shouldn't happen
					console.log('no_my_activity_result')
					reject()
					return
				}

				let payload = {
					user_id: state.user_info.user_id,
					activity_result_data: JSON.stringify(ar.copy_for_save())
				}

				// if this student's results need to go into the IC gradebook, add relevant params
				let activity = state.my_activities.find(x=>x.activity_id == activity_id)
				if (activity) activity.add_gradebook_data_to_payload(payload)
		
				// U.loading_start()
				U.ajax('save_activity_result_by_student', payload, result=>{
					// U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in save_activity_result_by_student call'); vapp.ping(); reject(); return;
					}

					// replace result record in my_activity_results
					commit('set', [state.my_activity_results, activity_id+'', new Activity_Result(result.activity_result)])
					resolve()
				});
			})
		},
	
		get_all_communities({state, commit, dispatch}) {
			let payload = {
				user_id: state.user_info.user_id,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('get_all_communities', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error retrieving all communities')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					commit('set', ['all_communities', []])
					for (let community of result.all_communities) {
						// skip the lp community
						if (community.type == 'lp') continue
						let co = new window.Community(community)

						// replace in the communities list if there
						commit('replace_in_array', [state.communities, 'community_id', community.community_id, co])

						// then add to all_communities
						commit('set', [state.all_communities, 'PUSH', co])
					}

					commit('set', ['all_communities_loaded', true])

					resolve()
				});
			})
		},

		get_community_memberships({state, getters, commit, dispatch}) {
			let payload = {
				user_id: state.user_info.user_id,
				course_forum_id_arrs: {},
			}

			// add forum_ids arrays for my_courses
			for (let course of getters.my_courses) {
				let lp = state.all_courses.find(x=>x.course_code==course.course_code)
				if (!lp) {
					console.log('get_community_memberships: no lp found', course.course_code)
					continue
				}
				payload.course_forum_id_arrs[lp.course_code] = lp.forum_ids
			}

			console.log('get_community_memberships payload', payload)

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('get_community_memberships', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error retrieving community memberships')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					console.log('community memberships rv', result)

					for (let community_data of result.communities) {
						commit('replace_in_array', [state.communities, 'community_id', community_data.community_id, new window.Community(community_data)])
					}

					for (let course of getters.my_courses) {
						let lp = state.all_courses.find(x=>x.course_code==course.course_code)
						if (!lp) continue
						// create a Community object for each course
						let c = new window.Community({
							community_id: 100000 + lp.lp_id,	// set community_id to a unique value for the lp
							title: lp.title,
							color: lp.color,
							type: 'lp',
						})


						// extract post_ids_authored_by_others for this course from the result
						c.post_ids_authored_by_others = result.course_post_ids_authored_by_others[lp.course_code]

						commit('replace_in_array', [state.communities, 'community_id', c.community_id, c])
					}

					commit('set', ['loaded_community_memberships', true])

					resolve()
				});
			})
		},

		get_messages({state, commit, dispatch}, payload) {
			// don't try to get messages if we don't have a sis_user_sourcedId (unless we happen to be viewing as parent)
			if (state.user_info.sis_user_sourcedId == '' && state.user_info.role !== 'parent') return

			payload.user_id = state.user_info.user_id
			payload.sis_user_sourcedId = state.user_info.sis_user_sourcedId
			payload.role = state.user_info.role

			// add a timestamp of when the last message load happened so we can only load new messages; if this value is 0 (the initial value in state), all messages will be retrieved
			payload.message_load_timestamp = state.message_load_timestamp
			return new Promise((resolve, reject)=>{
				// only show loading indicator for the first time we're doing this
				if (payload.message_load_timestamp == 0) U.loading_start()
				U.ajax('get_messages', payload, result=>{
					if (payload.message_load_timestamp == 0) U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error retrieving messages for course')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					let filtered_messages = result?.messages ?? []
					if (state.user_info.role === 'student') {
						filtered_messages = filtered_messages?.filter(message => message.send_to !== 'Guardian')
					}
					if (state.user_info.role === 'parent') {
						filtered_messages = filtered_messages?.filter(message => message.send_to !== 'Student')
					}

					// store incoming data...
					for (let message_data of filtered_messages) {
						// console.log(`message_data ${JSON.stringify(message_data)}`)
						let message = new window.Message(message_data)
						commit('replace_in_array', [state.messages, 'message_id', message.message_id, message])
					}

					// store incoming message_load_timestamp
					state.message_load_timestamp = result.message_load_timestamp
				})
			})
		},

		get_data_for_community({state, commit, dispatch}, payload) {
			// payload must include a community_id; can also include a forum_id if you just want the data from one forum
			payload.user_id = state.user_info.user_id

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('get_data_for_community', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error retrieving data for community')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					console.log('community data for community_id ' + payload.community_id, result)

					// store incoming data...
					// note that we won't get any forums for LP communities
					for (let forum_data of result.forums) {
						// replace forum if we already had it in the forums array
						commit('replace_in_array', [state.forums, 'forum_id', forum_data.forum_id, new window.Forum(forum_data)])
					}

					for (let post_data of result.posts) {
						let post = new window.Post(post_data)

						// add to posts array
						commit('replace_in_array', [state.posts, 'post_id', post.post_id, post])

						// if a child, add to parent's replies array
						if (post.parent_post_id != 0) {
							let parent = state.posts.find(o=>o.post_id == post.parent_post_id)
							if (!empty(parent)) {
								commit('replace_in_array', [parent.replies, 'post_id', post.post_id, post])
							}
						}
					}

					resolve()
				});
			})
		},

		create_community({state, commit, dispatch}, title) {
			let payload = {
				user_id: state.user_info.user_id,
				title: title,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('create_community', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						if (result.status.indexOf('A community with this title already exists') > -1) {
							vapp.$alert('A community with this title already exists.')
						} else {
							vapp.ping()		// call ping to check if the session is expired
						}
						console.log('Error creating community')
						reject()
						return
					}
					console.log('new community created', result)

					commit('set', [state.communities, 'PUSH', new window.Community(result.community_data)])

					resolve()
				});
			})
		},

		save_community_data({state, commit, dispatch}, community) {
			let payload = {
				user_id: state.user_info.user_id,
				community_id: community.community_id,
				title: community.title,
				description: community.description,
				image: community.image,
				type: community.type,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_community_data', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error updating community')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					resolve()
				});
			})
		},

		delete_community({state, commit, dispatch}, community) {
			let payload = {
				user_id: state.user_info.user_id,
				community_id: community.community_id,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('delete_community', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error deleting community')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// splice out of communities array
					let index = state.communities.findIndex(o=>o == community)
					state.communities.splice(index, 1)

					resolve()
				});
			})
		},

		join_community({state, commit, dispatch}, community_id) {
			let payload = {
				user_id: state.user_info.user_id,
				community_id: community_id,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('join_community', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error joining or requesting to join community.')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// if the user was added to a public community, add to communities array
					if (!empty(result.community_data)) {
						commit('set', [state.communities, 'PUSH', new window.Community(result.community_data)])
					}

					resolve()
				});
			})
		},

		remove_from_community({state, commit, dispatch}, payload) {
			// payload should come in including community_id and user_id_to_remove
			payload.user_id = state.user_info.user_id
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('remove_from_community', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error removing user from community.')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// if the user removed themself, remove from communities list
					if (payload.user_id_to_remove == payload.user_id) {
						vapp.$alert({title:'Success', text:'You have been removed from this community.'}).then(y=>{
							let index = state.communities.findIndex(o=>o.community_id == payload.community_id)
							state.communities.splice(index, 1)

							// then go to welcome page
							vapp.$router.push({ path: '/welcome' })
						})
					}

					resolve()
				});
			})
		},

		save_forum({state, commit, dispatch}, forum) {
			let payload = {
				user_id: state.user_info.user_id,
				forum_id: forum.forum_id,
				community_id: forum.community_id,
				type: forum.type,
				title: forum.title,
				// we're not currently dealing with forum descriptions...
			}

			// send course_code if we have one
			if (!empty(forum.course_code)) payload.course_code = forum.course_code

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_forum', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error updating forum')
						reject()
						return
					}

					// update forum_id and community_id, in case this was a new forum being saved
					// (note that if this is a LP forum, the forum object we update here is attached to the LP, so we don't have to update the LP separately)
					forum.forum_id = result.forum_id
					forum.community_id = result.community_id

					resolve()
				});
			})
		},

		delete_forum({state, commit, dispatch}, forum) {
			let payload = {
				user_id: state.user_info.user_id,
				forum_id: forum.forum_id,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('delete_forum', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error deleting forum')
						reject()
						return
					}

					// splice out of forums array
					let index = state.forums.findIndex(o=>o == forum)
					state.forums.splice(index, 1)

					resolve()
				});
			})
		},

		save_message({ state, commit, dispatch }, message) {
			console.log('saving message', message)
			let payload = {
				user_id: state.user_info.user_id,
				message_id: message.message_id,
				author_user_id: state.user_info.user_id,
				course_code: message.course_code,
				parent_message_id: message.parent_message_id,
				message_level: message.message_level,
				recipients: JSON.stringify(message.recipients),
				subject: message.subject,
				send_to: message.send_to,
				send_at: message.send_at,
				first_name: state.user_info.first_name,
				last_name: state.user_info.last_name,
				author_sourcedId: state.user_info.sis_user_sourcedId,
				activity_id: message.activity_id,
			}
			if (!empty(message.body)) payload.body = message.body
			return new Promise((resolve, reject) => {
				U.loading_start()
				console.log('promising')
				U.ajax('save_message', payload, result => {
					console.log('promising done')
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping() // call ping to check if the session is expired
						console.log('Error saving message')
						reject()
						return
					}
					// update message_id, in case this was a new message being saved
					message.message_id = result.message_id
					message.created_at = date.parse(result.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() / 1000
					message.is_read = 1 // author has read the message by default
					resolve()
				})
			})
		},

		delete_message({state, commit, dispatch}, message) {
			return new Promise((resolve, reject)=>{
				let index = state.messages.findIndex(o=>o == message)
				state.messages.splice(index, 1)
				resolve()
				return
			})
		},

		archive_message({state, commit, dispatch}, message) {
			return new Promise((resolve, reject)=> {
				U.ajax('archive_message', {user_id: state.user_info.user_id, message_id: message.message_id}, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error archiving message')
						reject()
						return
					}
					let index = state.messages.findIndex(o=>o.message_id == message.message_id)
					state.messages.splice(index, 1)
					resolve()
				});
			})
		},

		mark_message_as_read({state, commit, dispatch}, message) {
			const payload = {
				user_id: state.user_info.user_id,
				user_sourcedId: state.user_info.sis_user_sourcedId,
				message_id: message.message_id,
			}
			let index = state.messages.findIndex(o=>o.message_id == message.message_id)
			state.messages[index].is_read = 1
			return new Promise((resolve, reject)=>{
				U.ajax('mark_message_as_read', payload, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error marking message as read')
						reject()
						return
					}
					resolve()
				})
			})
		},

		save_message_preferences({state, commit, dispatch}, prefs) {
			console.log('saving message prefs', JSON.stringify(prefs))
			return new Promise((resolve, reject)=>{
				U.loading_start()
				console.log('promising')
				U.ajax('save_message_preferences', prefs, result=>{
					console.log('promising done')
					console.log('result', JSON.stringify(result))
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error saving message')
						reject()
						return
					}
					commit('set', [state.user_info, 'message_preferences', result.updated_prefs])
					resolve()
				});
			})
		},

		save_post({state, commit, dispatch}, post) {
			let payload = {
				user_id: state.user_info.user_id,
				post_id: post.post_id,
				forum_id: post.forum_id,
				community_id: post.community_id,
				parent_post_id: post.parent_post_id,
				author_user_id: post.author_user_id,
				pinned: post.pinned,
			}

			// if this is a resource forum post, stringify resource data to go in body
			if (!empty(post.resource.type)) {
				// note that we don't currently save posted resources in the resources table, so for now we don't need to save any resource ID.
				payload.body = JSON.stringify({
					type: post.resource.type,
					url: post.resource.url,
					description: post.resource.description,
					teacher_facing: post.resource.teacher_facing,
					target_students: post.resource.target_students,
				})
			} else {
				// else send body as body
				payload.body = post.body
			}

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_post', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error updating post')
						reject()
						return
					}

					// update with returned data, in case this was a new post being saved
					post.post_id = result.post_id
					post.created_at = date.parse(result.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() / 1000

					resolve()
				});
			})
		},

		delete_post({state, commit, dispatch}, post) {
			let payload = {
				user_id: state.user_info.user_id,
				post_id: post.post_id,
			}
			return new Promise((resolve, reject)=>{
				// if post_id is 0, it's a newly-created post that was aborted, so we just have to remove it from state
				if (post.post_id == 0) {
					let index = state.posts.findIndex(o=>o == post)
					state.posts.splice(index, 1)
					resolve()
					return
				}

				U.loading_start()
				U.ajax('delete_post', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error deleting post')
						reject()
						return
					}

					// splice out of posts array
					let index = state.posts.findIndex(o=>o == post)
					state.posts.splice(index, 1)

					resolve()
				});
			})
		},

		save_post_reads({state, commit, dispatch}, post) {
			let payload = {
				user_id: state.user_info.user_id,
				post_reads: state.user_info.post_reads_for_save(),
			}
			return new Promise((resolve, reject)=>{
				U.ajax('save_post_reads', payload, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error saving post reads')
						reject()
						return
					}

					resolve()
				});
			})
		},

		// debugging fn to clear all post reads for a user
		// vapp.$store.dispatch('clear_post_reads')
		clear_post_reads({state, commit, dispatch}, post) {
			let payload = {
				user_id: state.user_info.user_id,
				post_reads: '0',
			}
			return new Promise((resolve, reject)=>{
				U.ajax('save_post_reads', payload, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error saving post reads')
						reject()
						return
					}
					state.user_info.post_reads = []

					resolve()
				});
			})
		},

		save_coteacher({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_coteacher', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error saving coteacher')
						reject()
						return
					}
					let ct = new window.Coteacher(result.coteacher)
					if (state.my_coteachers.findIndex(o=>o.coteacher_id == ct.coteacher_id) == -1) {
						// we are adding a new coteacher
						commit('set', [state.my_coteachers, 'PUSH', ct])
					} else {
						// we are edting an existing coteacher
						commit('replace_in_array', [state.my_coteachers, 'coteacher_id', ct.coteacher_id, ct])
					}
					resolve()
				})
			})
		},

		delete_coteacher({state, commit, dispatch}, coteacher) {
			let payload = {
				user_id: state.user_info.user_id,
				coteacher_id: coteacher.coteacher_id,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('delete_coteacher', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('An error occurred when attempting to delete the coteacher.')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					// splice out of coteachers array
					let index = state.my_coteachers.findIndex(o=>o == coteacher)
					state.my_coteachers.splice(index, 1)
					resolve()
				})
			})
		},

		lti_launch({state, commit, dispatch}, payload) {
			// To do an LTI launch, we call a service to get the LTI form html along with the javascript to auto-submit the form,
			// then we open a new window and write the html/js
			payload.user_id = state.user_info.user_id
			U.loading_start()
			U.ajax('get_lti_1_launch_form', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					vapp.ping()		// call ping to check if the session is expired
					vapp.$alert('An error occurred when attempting to launch the resource.')
					return
				}

				// see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
				let w = window.open()
				w.document.write(result.lti_form)
			});
		},

		get_resource_record({state, commit, dispatch}, payload) {
			// payload should include the resource_id being queried; this fn is mainly (or possibly exclusively) used for lti launches
			// if payload includes "get_lti_form:'yes'", we will retrieve the form to do the launch

			// we have to send the user's *email address* for this service
			payload.email = state.user_info.email

			// Done my MZ
			// logging resource usage, if we call get_resource_record service from here or bridge_vue_app we don't need a separate ajax call for logging
			payload.lp_id = state.lp_showing

			// don't show loading indicator here, as we call the service on hover for resources
			// U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('get_resource_record', payload, result=>{
					// U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve data about the resource.')
						reject()
						return
					}

					resolve(result)
				});
			})
		},

		//Done by MZ
		log_resource_usage({state, commit, dispatch}, payload) {
			payload.lp_id = state.lp_showing // keep until backfill script executed and 2/2022 updates deployed
			payload.course_code = state.lp_showing
			return new Promise((resolve, reject)=>{
				U.ajax('log_resource_usage', payload, result=>{
					// don't block other activity for logging errors
				});
			})
		},

		get_lsdoc_list({state, commit, dispatch}) {
			let payload = { user_id: state.user_info.user_id }

			// add subject-area framework identifiers to payload, since we only need to retrieve data for them
			let arr = []
			for (let s in state.subjects) {
				arr.push(state.subjects[s])
			}
			payload.framework_identifier_filter = JSON.stringify(arr)

			// set frameworks_loading to true BEFORE framework_records have been loaded, so that other courses' pages won't also load the framework list
			state.frameworks_loading = true

			U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('get_framework_list', payload, result=>{
					U.loading_stop()

					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve the standards frameworks list.')
						reject()
						return
					}

					let arr = []
					for (let record of result.records) {
						// console.log(record.framework_identifier)
						let json = JSON.parse(record.json)
						let doc = new CFDocument(json.case_document_json)

						// for now, skip records that don't match frameworks in our subjects array
						let found = false
						for (let s in state.subjects) {
							if (state.subjects[s] == doc.identifier) {
								found = true
								break
							}
						}

						// TEMP: also skip the full CTAE and Fine Arts frameworks; we can take these checks out once we've removed those full frameworks from subjects
						if (doc.title.indexOf('no longer public') > -1) continue
						if (!found) continue

						delete(json.case_document_json)
						let framework_record = U.create_framework_record(doc.identifier, {CFDocument: doc}, json, false)
						arr.push(framework_record)
					}

					// sort by title, keeping fine arts and CTAE at the end
					arr = arr.sort((a,b) => {
						if (a.json.CFDocument.title.indexOf('CTAE') > -1 && b.json.CFDocument.title.indexOf('CTAE') == -1) return 1
						if (b.json.CFDocument.title.indexOf('CTAE') > -1 && a.json.CFDocument.title.indexOf('CTAE') == -1) return -1

						if (a.json.CFDocument.title.indexOf('Fine Arts') > -1 && b.json.CFDocument.title.indexOf('Fine Arts') == -1) return 1
						if (b.json.CFDocument.title.indexOf('Fine Arts') > -1 && a.json.CFDocument.title.indexOf('Fine Arts') == -1) return -1

						if (a.json.CFDocument.title.toLowerCase() < b.json.CFDocument.title.toLowerCase()) return -1
						if (b.json.CFDocument.title.toLowerCase() < a.json.CFDocument.title.toLowerCase()) return 1
						return 0
					})

					state.framework_records = arr

					// now set frameworks_loading to false and frameworks_loaded to true
					state.frameworks_loading = false
					state.frameworks_loaded = true

					resolve()
				})
			})
		},

		get_lsdoc({state, commit, dispatch}, lsdoc_identifier) {
			return new Promise((resolve, reject)=>{
				let filepath = sr('frameworks/$1.json', lsdoc_identifier)
				// TODO: get_json_file will throw an error if the json is malformed; need to test what happens if that happens.
				U.get_json_file(filepath, result=>{
					if (typeof(result) == 'string') {
						console.log('Error in get_lsdoc', result)
						reject(result)
						return
					}

					// the result should hold the CASE JSON for the framework, already JSON.parse'd
					// console.log('CASE JSON:', result)

					// the framework_record should already exist when this is called,
					let fr = state.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)
					if (!fr) {
						vapp.$alert('Framework record not found: the value entered for “CASE framework identifier” in the course editor may not be valid.')
						reject()
					} else {
						// so all we have to do is store the json and set framework_json_loaded to true, which framework_record_load_json does
						U.framework_record_load_json(fr, result)
					}

					resolve()
					// note that we don't need to load the exemplar frameworks in Inspire
				})
			})
		},

		// remove the next one...
		case_save_framework_data({state, commit, dispatch}, payload) {
			payload.user_id = state.user_info.user_id

			// stringify cfitems and cfassociations so that we don't have too many params; but we put them back as-is when we resolve
			let original_cfitems = payload.cfitems
			let original_cfassociations = payload.cfassociations
			payload.cfitems = JSON.stringify(payload.cfitems)
			payload.cfassociations = JSON.stringify(payload.cfassociations)

			U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('case_save_framework_data', payload, result=>{
					// restore cfitems/cfassociations
					payload.cfitems = original_cfitems
					payload.cfassociations = original_cfassociations

					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to save the standards data.')
						reject()
						return
					}

					// update the cfitems and associations in the framework
					let framework = state.case_frameworks[payload.framework_identifier]

					for (let cfitem_updates of payload.cfitems) {
						let cfitem = framework.cfitems[cfitem_updates.identifier]
						if (!empty(cfitem)) {
							for (let key in cfitem_updates) {
								if (cfitem_updates[key] == '*DELETE*') {
									delete cfitem[key]
								} else if (!empty(cfitem_updates[key])) {
									cfitem[key] = cfitem_updates[key]
									// lowercase versions of fullStatement and humanCodingScheme for filtering purposes (see CASETree.vue)
									if (key == 'fullStatement') cfitem.fullStatement_lc = cfitem.fullStatement.toLowerCase()
									if (key == 'humanCodingScheme') cfitem.humanCodingScheme_lc = cfitem.humanCodingScheme.toLowerCase()
								}
							}
						}
					}

					for (let cfassociation_updates of payload.cfassociations) {
						let cfassociation = framework.cfassociations[cfassociation_updates.identifier]
						if (!empty(cfassociation)) {
							for (let key in cfassociation_updates) {
								if (cfassociation_updates[key] == '*DELETE*') delete cfassociation[key]
								else cfassociation[key] = cfassociation_updates[key]
							}
						}
					}

					resolve(result)
				});
			})
		},

		get_lesson_masters({state, commit, dispatch}, force) {
			// unless force is true, only load masters if we haven't already done so
			if (state.loading_lesson_masters || (force !== true && state.lesson_masters.length > 0)) return

			state.loading_lesson_masters = true

			U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('get_lesson_masters', {user_id: state.user_info.user_id}, result=>{
					U.loading_stop()
					state.loading_lesson_masters = false
					state.lesson_masters = []

					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve the lesson masters (' + result.status + ').')
						reject()
						return
					}

					for (let lm of result.masters) {
						state.lesson_masters.push(new Lesson(lm))
					}

					// sort so that newer masters are earlier in the list
					state.lesson_masters.sort((a,b)=>b.lesson_id-a.lesson_id)

					if (result.default_lesson_master_id != 0) {
						state.default_lesson_master = state.lesson_masters.find(x=>x.lesson_id == result.default_lesson_master_id)
						if (empty(state.default_lesson_master)) {
							// this shouldn't happen
							vapp.$alert('Couldn’t identify default lesson master (' + result.default_lesson_master_id + ')')
						}
					}

					resolve()
				});
			})
		},

		// server side pdf writer if adding resource pdfs to lesson
		save_to_pdf({state, commit, dispatch}, payload) {
			payload.user_id = state.user_info.user_id

			U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('save_to_pdf', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to save this page as pdf.')
						reject()
						return
					}
					resolve(result)
				});
			})
		},

		// this.$store.dispatch('update_sis_data')
		update_sis_data({state, commit, dispatch}) {
			let payload = {
				user_id: state.user_info.user_id,
			}

			U.ajax('clear_sis_data', payload, result=>{
				// this will clear the user's sis data and prepare things so that when we reload, initialize_app will refresh the sis data
				document.location.reload()
			});
		},

		// this.$store.dispatch('sign_out')
		sign_out({state, commit, dispatch}) {
			let payload = {
				user_id: state.user_info.user_id,
			}
			U.ajax('sign_out', payload, result=>{
				// regardless of result, reload the page, which should show the login screen
				document.location.reload()
			});
		},

		/////////////////////////////////////
		// Websocket stuff

		socket_open({state, commit, dispatch}) {
			if (state.socket_status == 'started') {
				return
			}

			return new Promise((resolve, reject)=>{
				// set socket_status to started so we know we're trying to start
				state.socket_status = 'started'

				// try to instantiate the socket
				state.socket = new WebSocket(state.henry_chatter_url)

				// when the socket receives a message, dispatch socket_receive
				state.socket.onmessage = msg => { dispatch('socket_receive', msg)}

				// when the socket opens...
				state.socket.onopen = e => {
					// send a 'sign_on' message...
					dispatch('socket_send', {type:'sign_on'}).then(x=>{
						state.socket_status = 'running'
						console.log('HenryChatter websocket open (socket_status set to “running”)')
						resolve()

					}).catch(x=>{
						state.socket_status = 'not_started'
						console.log('ERROR in socket.onopen/socket_send ...')
						reject()
					})
				}

				// if the socket fails at any point...
				state.socket.onerror = e => {
					// set socket_status to not_started; try to reconnect again in 5 seconds
					state.socket_status = 'not_started'
					console.log('ERROR received by socket.onerror (socket_status set to not_started)', e)
					clearTimeout(state.socket_timeout)
					state.socket_timeout = setTimeout(x=>dispatch('socket_open'), 5000)
				}
			})
		},

		socket_send({state, commit, dispatch}, msg) {
			// clear state.socket_timeout
			clearTimeout(state.socket_timeout)

			return new Promise((resolve, reject)=>{
				// if socket hasn't even been started, OR it's closing or closed (readyState > 1)
				if (!state.socket || state.socket_status == 'not_started' || state.socket.readyState > 1) {
					if (state.socket && state.socket.readyState > 1) {
						console.log('Reopening dead socket...')
					}

					// set socket_status to 'starting'
					state.socket_status = 'starting'

					// open, then call socket_send when done
					dispatch('socket_open').then(x=>{
						dispatch('socket_send', msg)
						.then(x=>{ resolve() })
						.catch(x=>{ reject() })
					}).catch(x=>{
						reject()
					})
					return
				}

				// if socket has been initiated but isn't yet open
				if (state.socket.readyState == 0) {
					// TODO: we should really have a queue here; for now we'll just try again in a second
					setTimeout(x=>{
						dispatch('socket_send', msg)
						.then(x=>{ resolve() })
						.catch(x=>{ reject() })
					}, 1000)
					return
				}

				////////////////////
				// if we get to here, send the msg
				// incomming msg should already be an object; `type` is a required field (e.g. `sign_on`)
				// add token, lp_id, user_id, and role to msg
				msg.token = U.new_uuid()
				// for edit open we may be trying to reaquire lock(s) after sleeping, it/they it may not be what's currently showing
				let msg_course = state.lp_showing
				if (msg.type == 'lp_edit_open') msg_course = msg.data.lp_course_code
				if (state.lp_showing < 90000) {
					msg.course_code = msg_course + '_' + this.getters.academic_year
				} else {
					msg.course_code = msg_course
				}
				msg.user_id = state.user_info.user_id
				msg.role = 1000

				// store messages to socket_sent_messages queue; only hold a max of 20 messages there
				state.socket_sent_messages.unshift(msg)
				if (state.socket_sent_messages.length > 20) state.socket_sent_messages.pop()

				// queue socket ping in 5 seconds if nothing else is sent in that time
				state.socket_timeout = setTimeout(x=>dispatch('socket_send', {type:'ping'}), 30000)

				try {
					state.socket.send(JSON.stringify(msg))
					resolve()
				} catch(e) {
					console.log('error encountered for socket.send', e)
					reject()
				}
			})
		},

		socket_receive({state, commit, dispatch}, msg) {
			// this is called by the state.socket.onmessage handler, so there's no promise involved here (it could be a commit, but easier to keep the socket stuff together here)

			if (empty(msg.data)) {
				console.log('socket_receive did not receive data in the incoming message', msg)
			}
			msg = msg.data

			// if data starts with a GUID followed by a colon and a space, it's a "reply" to a message we sent
			if (msg.search(/(........-....-....-....-............): (.*)/) > -1) {
				let token = RegExp.$1
				let reply = RegExp.$2
				let ssm = state.socket_sent_messages.find(x=>x.token == token)
				if (empty(ssm)) {
					console.log('socket_receive got a message that couldn’t be found in socket_sent_messages:\n' + msg)
					return
				}

				if (ssm.type == 'ping') {
					console.log(sr('socket_ping $1', reply))
				} else {
					console.log(sr('socket_receive: $1: $2', ssm.type, reply))
					// console.log(sr('socket_receive: $1: $2 ($3)', ssm.type, reply, ssm.token))
				}

				// for sign_on message, we will receive any updates that have occurred in the brief amount of time since we fetched all the data from the server
				if (ssm.type == 'sign_on') {
					console.log('PROCESS SIGN_ON...')
					// re-establish edit locks if connection may have restarted
					dispatch('lp_edit_confirm')
				}

				// user requested to open an LP for editing; record here if it's OK to do so
				let course_code = ssm.course_code.split('_')[0]
				if (ssm.type == 'lp_edit_open') {
					if (reply == "OK") {
						commit('set', [state.lp_edit_locked, course_code, false])

					} else if (reply == 'EDIT_LOCKED') {
						let rtype = (course_code >= 90000) ? 'Resource Collection' : 'Learning Progression'
						commit('set', [state.lp_edit_locked, course_code, true])
						let lp = state.all_courses.find(o=>o.course_code == course_code)
						vapp.$alert(sr("The $1 for “$2” is currently being edited by another user. Please try again later.", rtype, lp.title))

						// PW: for now, we immediatelly close the socket if this happens
						dispatch('socket_close')
					}
				} else if (ssm.type == 'lp_edit_close') {
					// setting to true so that we don't reaquire this lp lock if dead socket reconnects
					// if user want's to edit again a new edit request will be evaluated at server socket
					commit('set', [state.lp_edit_locked, course_code, true])
				}
				return
			}

			// if we're here, the received message isn't a reply to our message, so process it...
			try {
				msg = JSON.parse(msg)
			} catch(e) {
				console.log('data from socket_receive could not be parsed:', e)
			}

			let course_code = msg.data.course_code.split('_')[0]
			let resource_type = (course_code >= 90000) ? 'Resource Collection' : 'Learning Progression'
			if (msg.type == 'lp_edit_unlocked') {
				// an LP the user requested to edit has been unlocked; tell them that it's OK to edit it now
				let lp = state.all_courses.find(o=>o.course_code == course_code)
				vapp.$inform({text:sr("The $1 for “$2” is now open for editing.", resource_type, lp.title), snackbarTimeout:10000, snackbarX: "left"})

			} else if (msg.type == 'lp_updated') {
				// an LP the user has previously viewed was updated; refresh the LP data and tell them it's been updated
				// Update the course data, by setting fully_loaded to false and dispatching get_learning_progression...
				let lp = state.all_courses.find(o=>o.course_code == course_code)
				lp.fully_loaded = false

				dispatch('get_learning_progression', course_code).then(()=>{
					vapp.$inform({text:sr("The $1 for “$2” has been updated.", resource_type, lp.title), snackbarTimeout:10000, snackbarX: "right"})
				})

			} else {
				console.log('unprocessed socket_receive message', msg)
			}
		},

		socket_close({state, commit, dispatch}) {
			return new Promise((resolve, reject)=>{
				if (!state.socket) return

				// close in a try/catch just in case there's an issue
				try {
					state.socket.close()
					state.socket_status = 'not_started'
					clearTimeout(state.socket_timeout)
				} catch(e) {
					console.log('state.socket.close failed')
				}

				resolve()
			})
		},

		////////////////////////////
		// SOCKET REQUESTS USED ACROSS MULTIPLE COMPONENTS
		lp_edit_socketmsg({state, commit, dispatch}, course_code) {
			// request ability to edit LP via socket
			let lp = state.all_courses.find(o=>o.course_code == course_code)
			dispatch('socket_send', {
				type: 'lp_edit_open',
				data: {
					lp_course_code: lp.course_code,
					user_email: state.user_info.email,
				}
			})
		},

		// on sign in, verify that edits previously requested for this state are still open to edit
		// request and re-establish locks, e.g. if user's brower is waking up in edit view
		lp_edit_confirm({state, commit, dispatch}) {
			if (empty(state.lp_edit_locked)) return

			// lp_edit_locked[course_code] === FALSE indicates an edit request was previously granted
			let edits = Object.keys(state.lp_edit_locked)
			for (let i = 0; i < edits.length; ++i) {
				if (state.lp_edit_locked[edits[i]] === false) {
					dispatch('lp_edit_socketmsg', edits[i])
				}
			}
		},

		lp_edit_close_socketmsg({state, commit, dispatch}, payload) {
			// tell socket that an LP that was being edited is no longer being edited; also say if the LP has been changed
			let course_code = payload.course_code
			let edit_action = payload.edit_action	// should be 'cancelled' or 'updated'

			// send update to other signed-in users via socket
			let lp = state.all_courses.find(o=>o.course_code == course_code)
			dispatch('socket_send', {
				type: 'lp_edit_close',
				data: {
					edit_action: edit_action,
					lp_course_code: course_code,
					user_email: state.user_info.email,
				}
			}).finally(x=>{
				// once we've sent the message, close the socket
				console.log('CLOSING SOCKET')
				dispatch('socket_close')
			})
		},

		// allow admin to unlock edit, close connection, etc.
		admin_socketmsg({state, commit, dispatch}, payload) {
			let action = payload.action // unlock_course, close_by_conn_id, close_by_user_id
			let course_code = payload.course_code
			let conn_id = payload.conn_id
			let user_id = payload.user_id

			if (!empty(course_code) && course_code < 90000) course_code += '_' + this.getters.academic_year

			dispatch('socket_send', {
				type: 'socket_admin',
				data: {
					action: action,
					course_code: course_code,
					conn_id: conn_id,
					user_id: user_id
				}
			})
		},

		admin_get_term_settings({state, commit, dispatch}) {
			const payload = {
				user_id: state.user_info.user_id
			}
			return new Promise((resolve, reject)=>{
				U.ajax('admin_get_term_settings', payload, result=>{
					if (result.status != 'ok') {
						vapp.$alert('An error occurred when attempting to get the term settings.')
						reject()
						return
					}
					if (state.user_info.role == 'admin') {
						commit('set', ['term_settings', result.term_settings])
					}
				})
				resolve()
			})
		},

		admin_update_term_settings({state, commit, dispatch}, payload) {
			payload.user_id = state.user_info.user_id
			return new Promise((resolve, reject)=>{
				U.ajax('admin_update_term_settings', payload, result=>{
					if (result.status != 'ok') {
						vapp.$alert('An error occurred when attempting to update the term settings.')
						reject()
						return
					}
				})
				resolve()
			})
		},
	}
})
