<template>
	<v-dialog v-model="dialog_open" persistent max-width="800px">
		<v-card>
			<v-card-title><b>Edit Co-teacher Permissions</b></v-card-title>

			<v-card-text>
				<div style="font-size:1.2em"><b>{{ coteacher.last_name }}, {{ coteacher.first_name }} ({{ coteacher.email
				}})</b>
				</div>

				<v-checkbox :label="'Can view student results?'" v-model="can_view_results" :disabled="true" :value="true"
					hide-details></v-checkbox>
				<v-checkbox :label="'Can view and edit student results?'" v-model="can_edit_results"
					@change="update_can_edit_results" :disabled="true||can_edit_activity" hide-details></v-checkbox>
				<v-checkbox :label="'Can edit activity content?'" v-model="can_edit_activity"
					@change="update_can_edit_activity" :disabled="true" hide-details></v-checkbox>
				<div class="mt-4"><i>Note: In the future, you will be able to restrict co-teachers to being able to view student results only, or allow co-teachers to edit activity content.</i></div>
			</v-card-text>


			<v-card-actions class="pl-4 pr-4 pb-4">
				<v-btn color="red" dark @click="delete_coteacher"><v-icon small class="mr-2">fas fa-trash</v-icon> Remove
					From Course</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="secondary" @click="$emit('editor_cancel')" class="mr-1"><v-icon small class="mr-2">fas
						fa-times</v-icon> Cancel</v-btn>
				<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
			</v-card-actions>

		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
	components: {},
	props: {
		coteacher: { type: Object, required: true, default() { return {} } },
	},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
	},
	data() {
		return {
			dialog_open: true,
			can_view_results: true,
			can_edit_results: false,
			can_edit_activity: false,
		};
	},
	methods: {
		save_edits() {
			const payload = {
				coteacher_id: this.coteacher.coteacher_id,
				course_code: this.coteacher.course_code,
				coteacher_email: this.coteacher.email,
				user_id: this.user_info.user_id,
				origin_teacher_user_id: this.user_info.user_id,
				permission_level: this.can_edit_activity ? 2 : (this.can_edit_results ? 1 : 0)
			}
			this.$store.dispatch('save_coteacher', payload).then(x => {
				this.$emit("editor_cancel")
			})
		},
		update_can_edit_results(e) {
			this.can_edit_results = e
		},
		update_can_edit_activity(e) {
			if (e) {
				this.can_edit_activity = this.can_edit_results = true
			} else {
				this.can_edit_activity = false
			}
		},
		delete_coteacher() {
			this.$confirm({
				title: 'Are you sure?',
				text: 'Are you sure you want to remove this co-teacher from this course?',
				acceptText: 'Remove Co-teacher',
				acceptColor: 'red',
				dialogMaxWidth: 600
			}).then(y => {
				this.$store.dispatch('delete_coteacher', this.coteacher).then(x => {
					this.$emit('editor_cancel')
				})
			}).catch(n => { console.log(n) }).finally(f => { });
		},
	},
	created() {
		this.can_view_results = this.coteacher.permission_level >= 0
		this.can_edit_results = this.coteacher.permission_level >= 1
		this.can_edit_activity = this.coteacher.permission_level >= 2
	},
	mounted() { },
}

</script>

<style lang="scss">
</style>
