<template>
  <v-dialog light v-if="shown" content-class="simple-dialog" eager v-model="shown" :max-width="message.dialogMaxWidth || 500" scrollable persistent overlay-opacity="0.9">
    <v-card>
      <!-- <v-responsive> -->
        <v-card-title v-if="message.title" class="pa-3">
          <h3 v-html="message.title"></h3>
        </v-card-title>
        <v-card-text class="px-4 py-4">
          <div v-html="message.text" class="mb-2"></div>
		  <v-text-field ref="passwordInput" outlined hide-details class="simple-dialog-text-field" v-if="type === 'prompt' && message.promptType === 'password'" v-model="text_input" @keyup="checkSubmit" autocomplete="new-password"  :type="show_password ? 'text' : 'password'" :append-icon="show_password ? 'fas fa-eye' : 'fas fa-eye-slash'" @click:append="show_password = !show_password"></v-text-field>
          <v-text-field ref="textInput" outlined hide-details class="simple-dialog-text-field" v-if="type === 'prompt' && message.promptType === 'text'" :type="message.type || 'text'" v-model="text_input" @keyup="checkSubmit"></v-text-field>
		  <v-textarea ref="textareaInput" outlined hide-details auto-grow class="simple-dialog-textarea" v-if="type === 'prompt' && message.promptType === 'textarea'" v-model="text_input"></v-textarea>
		  <v-file-input ref="fileInput" outlined hide-details placeholder="Select a file…" class="simple-dialog-file-input" v-if="type === 'prompt' && message.promptType === 'file'" v-model="file_input"></v-file-input>
		  <v-autocomplete ref="autocompleteInput" dense outlined hide-details auto-grow class="simple-dialog-autocomplete" v-if="type === 'prompt' && message.promptType === 'autocomplete'" v-model="text_input" :items="autocomplete_items" :loading="autocomplete_loading" :search-input.sync="autocomplete_search" hide-no-data hide-selected></v-autocomplete>
		  <v-select ref="selectInput" outlined hide-details dense class="simple-dialog-select-input" v-if="type === 'prompt' && message.promptType === 'select'" v-model="text_input" :items="message.selectOptions" label=""></v-select>

		  <div v-if="type === 'prompt' && message.promptType === 'radio'" style="border:1px solid #777; border-radius:8px; padding:4px 8px 8px 8px; margin-top:12px; "><v-radio-group ref="radioInput" hide-details class="mt-0" v-model="text_input">
			<v-radio v-for="(o, index) in message.radioOptions" :value="o.value" :key="index"><template v-slot:label><div class="black--text" v-html="o.label"></div></template></v-radio>
		  </v-radio-group></div>

		</v-card-text>
        <v-card-actions class="pa-3">
			<v-btn v-on:click="cancel" class="simple-dialog-dismiss-btn" dark :color="message.cancelColor" v-if="type !== 'alert'" :style="message.hideCancel==true?'visibility:hidden;height:0px;':''">
				<v-icon v-if="message.cancelIcon" small class="mr-2">{{message.cancelIcon}}</v-icon>
				<span v-html="message.cancelText"></span>
				<v-icon v-if="message.cancelIconAfter" small class="ml-2">{{message.cancelIconAfter}}</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
			<v-btn v-on:click="accept" :class="type=='alert'?'simple-dialog-dismiss-btn':''" dark :color="message.acceptColor" :style="message.hideAccept==true?'visibility:hidden;height:0px;':''" :disabled="accept_disabled" ref="acceptBtn">
				<v-icon v-if="message.acceptIcon" small class="mr-2">{{message.acceptIcon}}</v-icon>
				<span v-html="message.acceptText"></span>
				<v-icon v-if="message.acceptIconAfter" small class="ml-2">{{message.acceptIconAfter}}</v-icon>
			</v-btn>

			<!-- <v-btn v-on:click="cancel" class="simple-dialog-dismiss-btn" dark :color="message.cancelColor" v-if="type !== 'alert'" :style="message.hideCancel==true?'visibility:hidden;height:0px;':''" v-html="message.cancelText"></v-btn>
			<v-spacer></v-spacer>
			<v-btn v-on:click="accept" :class="type=='alert'?'simple-dialog-dismiss-btn':''" dark :color="message.acceptColor" :style="message.hideAccept==true?'visibility:hidden;height:0px;':''" v-html="message.acceptText" ref="acceptBtn"></v-btn> -->
        </v-card-actions>
      <!-- </v-responsive> -->
    </v-card>
  </v-dialog>
</template>

<script>
    export default {
        name : "SimpleDialog",
        props : {
            type : String,
            message : Object,
            resolve : Function
        },
        data() { return {
            shown: false,
            text_input: '',
			file_input: [],
			autocomplete_loading: false,
			autocomplete_search: '',
			autocomplete_items: [],
			show_password: false,
        }},
		created() {
			if (this.message.initialValue != null) {
				this.text_input = this.message.initialValue;
			}
		},
		computed: {
			accept_disabled() {
				if (this.message.disableForEmptyValue) {
					return (this.text_input == '' && this.file_input.length == 0)
				}
				return false
			},
		},
		watch: {
			autocomplete_search(val) {
				/*
					To use autocomplete, either specify `autocompleteItems` in the $prompt call (they should be an array of strings),
					or specify a `serviceName` value, in which case:
						- this fn will call serviceName to retrieve the items based on the first 3 characters the user types
						- the service must return an array of `entries` in the result
						- caller can optionally specify `entryField` to use a field of the returned objects;
						  if entryField isn't specified, the service will receive `return_string:yes`, which should signal it to return an array of strings
				*/

				if (this.message.autocompleteItems) {
					this.autocomplete_items = this.message.autocompleteItems
					return
				}

				if (!this.message.serviceName) return

				// items have already been requested
				if (this.autocomplete_loading) return

				// don't search until we have three characters; then if the value drops back below three characters, clear autocomplete_items so we'll search again when we get back to 3
				if (!val || val.length < 3) {
					this.autocomplete_items = []
					return
				} else if (this.autocomplete_items.length > 0 && val.length > 3) {
					// if we don't have any items, the search service may have overloaded, so keep trying if the user keeps typing
					return
				}

				// if we get to here, search for items
				this.autocomplete_loading = true
				let payload = {
					user_id: vapp.user_info.user_id,
					// if an entryField is specified, we want the service to return an object; otherwise tell it to return a string
					return_string: (this.message.entryField) ? 'no' : 'yes',
					stem: val,
				}
				U.ajax(this.message.serviceName, payload, result=>{
					// parse results, which should be in result.entries
					let arr = []
					for (let entry of result.entries) {
						// if entryField is specified, entries should be objects; get the right field
						if (this.message.entryField) arr.push(entry[this.message.entryField])
						// else entries should be strings
						arr.push(entry)
					}
					this.autocomplete_items = arr
					this.autocomplete_loading = false
				})
			},
		},
        methods : {
            cancel()
            {
                this.shown = false;
                this.resolve( false );
            },
            accept()
            {
                this.shown = false;
				if (this.type == 'prompt') {
					// note that although file_input is defined as an array, since we only currently allow for one file to be selected, only a single file object is returned.
					if (this.message.promptType == 'file') {
						this.resolve(this.file_input)
						return
					}

					// if type is 'autocomplete' and we didn't get a text_input, return the autocomplete_search value
					if (!this.text_input) this.text_input = this.autocomplete_search

					this.resolve(this.text_input)
				} else {
					this.resolve(true)
				}
            },
            checkSubmit( evt )
            {
                if( evt.key === "Enter" )
                {
                    this.accept();
                }

				// if allCaps is true, transform input to upper case
				if (this.message.allCaps) {
					this.text_input = this.text_input.toUpperCase()
				}
            }
        },
        mounted()
        {
            this.shown = true;
            if (this.type === "prompt"){
                setTimeout(function() {
					if (this.message.promptType == 'text') {
						$(this.$refs.textInput.$el).find('input').focus()
						$(this.$refs.textInput.$el).find('input').select()
					} else if (this.message.promptType == 'password') {
						$(this.$refs.passwordInput.$el).find('input').focus()
						$(this.$refs.passwordInput.$el).find('input').select()
					} else if (this.message.promptType == 'textarea') {
						$(this.$refs.textareaInput.$el).find('textarea').focus()
						$(this.$refs.textareaInput.$el).find('textarea').select()
					}
				}.bind(this), 300 );
            }

			if (this.message.focusBtn) {
				setTimeout(function() {
					$(this.$refs.acceptBtn.$el).focus()
				}.bind(this), 300 );
			}
        }
    }
</script>

<style>
.simple-dialog-textarea textarea {
	margin-top:0!important;
}

.simple-dialog-text-field {
	margin-top:10px!important;
}

.simple-dialog-file-input {
	margin-top:15px!important;
}

.simple-dialog .v-card__text {
	font-size:16px;
	line-height:24px;
	color:#111!important;
}

.simple-dialog .v-card__actions {
	background-color:#f8f8f8;
	border-top:1px solid #ddd;
}

.simple-dialog .v-card__title {
	background-color:#f8f8f8;
	border-bottom:1px solid #ddd;
}
.simple-dialog .v-card__title h3 {
	font-size:24px;
	margin:12px;
	margin:0;
	padding:0;
	color:#111!important;
}

</style>
