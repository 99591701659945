// Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams
import { mapState, mapGetters } from 'vuex'

/*
To use, currently, run from the console:
vapp.count_course_resources([1815, 1325, 5754, 3202])
vapp.count_course_resources_report([1815, 1325, 5754, 3202])
*/

export default {
	data() { return {
		resource_count_ids: [],
		resource_count_deferred_courses: 0,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	methods: {
		count_course_resources_report(course_codes) {
			this.count_course_resources(course_codes, true)
		},

		count_course_resources(course_codes, download_report) {
			this.resource_count_deferred_courses = 0

			let grand_totals = {
				courses: [],
				count_unit: 0,
				count_ese: 0,
				count_adv: 0,
				count_pd: 0,
				count_collections: 0,
				total: 0,
				zids_units: [],
				zids_ese: [],
				zids_adv: [],
				zids_pd: [],
				zids_col: [],
			}
			for (let course_code of course_codes) {
				let lp = this.$store.state.all_courses.find(o=>o.course_code == course_code)

				// if we don't already have the full lp data for this course...
				if (empty(lp) || empty(lp.lp_id) || lp.lp_id == 0) {
					console.log('loading course ' + course_code)
					++this.resource_count_deferred_courses

					// try to load it now
					this.$store.dispatch('get_learning_progression', course_code).then((found)=>{
						if (!found) {
							console.log('course not found: ' + course_code)
							return
						}
						console.log('deferred: ' + course_code)
						let lp = this.$store.state.all_courses.find(o=>o.course_code == course_code)
						this.add_to_count(lp, grand_totals)

						--this.resource_count_deferred_courses
						if (this.resource_count_deferred_courses == 0) {
							this.finish_count(grand_totals, download_report)
						}

					}).catch(()=>{
						console.log('error in get_learning_progression? ' + course_code)
					})
				} else {
					this.add_to_count(lp, grand_totals)
				}
			}

			// if we didn't defer any courses, finish_count now
			if (this.resource_count_deferred_courses == 0) {
				this.finish_count(grand_totals, download_report)
			}
		},

		finish_count(grand_totals, download_report) {
			// sum up total
			grand_totals.total = grand_totals.count_unit + grand_totals.count_collections + grand_totals.count_pd

			let arr = []

			arr.push([
				'Course',
				'Code',
				'Total',
				'Units',
				'Units-ESE',
				'Units-ADV',
				'HMH',
				'PD',
			])

			arr.push([
				'Grand Total',
				'-',
				grand_totals.total,
				grand_totals.count_unit,
				grand_totals.count_ese,
				grand_totals.count_adv,
				grand_totals.count_collections,
				grand_totals.count_pd,
			])

			for (let course of grand_totals.courses) {
				arr.push([
					course.atitle,
					course.code,
					course.total,
					course.count_unit,
					course.count_ese,
					course.count_adv,
					course.count_collections,
					course.count_pd,
				])
			}

			console.log(arr)

			if (download_report === true) {
				let filename = sr('Resource-Counts.csv')
				U.download_file(CSV.stringify(arr), filename)
			}
		},

		add_to_count(lp, grand_totals) {
			let cats = this.count_resources(lp)

			// add to totals
			for (let id of cats.zids_units) {
				if (grand_totals.zids_units.findIndex(x=>x==id) == -1) {
					++grand_totals.count_unit
					grand_totals.zids_units.push(id)
				}
			}

			for (let id of cats.zids_ese) {
				if (grand_totals.zids_ese.findIndex(x=>x==id) == -1) {
					++grand_totals.count_ese
					grand_totals.zids_ese.push(id)
				}
			}

			for (let id of cats.zids_adv) {
				if (grand_totals.zids_adv.findIndex(x=>x==id) == -1) {
					++grand_totals.count_adv
					grand_totals.zids_adv.push(id)
				}
			}

			for (let id of cats.zids_pd) {
				if (grand_totals.zids_pd.findIndex(x=>x==id) == -1) {
					++grand_totals.count_pd
					grand_totals.zids_pd.push(id)
				}
			}

			for (let id of cats.zids_col) {
				if (grand_totals.zids_col.findIndex(x=>x==id) == -1) {
					++grand_totals.count_collections
					grand_totals.zids_col.push(id)
				}
			}

			grand_totals.courses.push(cats)
		},

		count_resources(lp) {
			let count_collection_resources = (children, zids) => {
				let count = 0
				for (let child of children) {
					if (child.c) count += count_collection_resources(child.c, zids)
					else {
						if (zids.findIndex(x=>x==child.resource_id) == -1) {
							count += 1
							zids.push(child.r)
						}
					}
				}
				return count
			}

			let cats = {
				atitle: lp.title,
				code: lp.course_code,
				count_unit: 0,
				count_ese: 0,
				count_adv: 0,
				count_pd: 0,
				count_collections: 0,
				total: 0,
				zids_units: [],
				zids_ese: [],
				zids_adv: [],
				zids_pd: [],
				zids_col: [],
			}

			// unit resources
			for (let unit of lp.units) {
				for (let r of unit.resources) {
					if (cats.zids_units.findIndex(x=>x==r.resource_id) == -1) {
						++cats.count_unit
						cats.zids_units.push(r.resource_id)
					}

					if (r.target_students == 'ese' && cats.zids_ese.findIndex(x=>x==r.resource_id) == -1) {
						++cats.count_ese
						cats.zids_ese.push(r.resource_id)
					}

					if (r.target_students == 'adv' && cats.zids_adv.findIndex(x=>x==r.resource_id) == -1) {
						++cats.count_adv
						cats.zids_adv.push(r.resource_id)
					}
				}
			}

			// PD resources
			for (let r of lp.pd_resources) {
				if (cats.zids_pd.findIndex(x=>x==r.resource_id) == -1) {
					++cats.count_pd
					cats.zids_pd.push(r.resource_id)
				}
			}

			// resource collections (e.g. HMH)
			for (let col of lp.resource_collections) {
				cats.count_collections += count_collection_resources(col.collection_json.c, cats.zids_col)
			}

			for (let key in cats) if (key.indexOf('count') == 0) cats.total += cats[key]

			return cats
		},
	}
}
