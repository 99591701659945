<!-- Part of the SPARKL educational activity system, Copyright 2020 by Pepper Williams -->
<template><div class="k-pdlp-editor-wrapper">
	<h3>Resource Collection title and description:</h3>
	<div class="k-pdlp-editor-top-data elevation-3">
		<div class="d-flex mb-4">
			<v-text-field background-color="#fff" style="font-weight:bold" dense hide-details outlined label="Title" v-model="title"></v-text-field>
			<v-select v-model="color" :items="color_select" label="Color" dense outlined background-color="#fff" hide-details class="k-plp-color-menu"></v-select>
			<div class="k-plp-color-swatch" :class="color_swatch_class"></div>
			<div style="width:80px" class="ml-4"><v-text-field background-color="#fff" dense hide-details outlined label="Sequence" v-model="sequence"></v-text-field></div>
			<v-checkbox class="ml-4 pt-0 mt-2" :label="'Active'" v-model="active" hide-details></v-checkbox>
			<v-checkbox class="ml-4 pt-0 mt-2" :label="'Featured'" v-model="featured_on" hide-details></v-checkbox>
		</div>

		<froala-wrapper :config="editor_config('Description (optional)')" v-model="description" />
	</div>

	<div class="k-pdlp-editor-units">
		<h3 class="ml-3">Resource Collection Units:</h3>
		<div class="d-flex flex-wrap justify-left">
			<div v-for="(unit, i) in units" class="k-pdlp-editor-unit elevation-2">
				<div class="d-flex align-center">
					<v-text-field background-color="#fff" class="mr-1" hide-details outlined label="Title" v-model="unit.title"></v-text-field>
					<div class="ml-2" style="margin-right:-3px">
						<v-btn icon x-small color="#666" @click="move_unit(unit,'up')"><v-icon>fas fa-arrow-circle-up</v-icon></v-btn>
						<v-btn icon x-small color="#666" @click="move_unit(unit,'down')"><v-icon>fas fa-arrow-circle-down</v-icon></v-btn><br>
						<v-btn icon x-small color="red lighten-2" @click="delete_unit(unit)"><v-icon>fas fa-times-circle</v-icon></v-btn>
					</div>
				</div>
			</div>
			<v-spacer/>

			<div class="mt-4 text-center" style="width:100%">
				<v-btn color="#666" dark width="300" @click="create_new_unit()">Create New Unit</v-btn>
			</div>
		</div>
	</div>

	<v-card-actions class="mt-4">
		<v-spacer></v-spacer>
		<v-btn color="secondary" @click="cancel_editor" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
	</v-card-actions>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
		learning_progression: { type: Object, required: true },
	},
	data() { return {
		title: this.learning_progression.title,
		active: (this.learning_progression.active == 'yes'),
		course_code: this.learning_progression.course_code,
		description: this.learning_progression.description,
		featured: this.learning_progression.featured,
		color: this.learning_progression.color,
		sequence: this.learning_progression.sequence,
		units: [],	// copied below

		unit_deletion_confirmed: false,

		initial_values: null,
	}},
	computed: {
		...mapState(['user_info']),
		featured_on: {
			get() { return this.featured > 0 },
			set(val) {
				if (!val) {
					// off: set featured to 0
					this.featured = 0
				} else {
					// on: set featured to current timestamp
					this.featured = Math.round(Date.now() / 1000)
				}

				console.log('set this.featured to ' + this.featured)
			}
		},
		color_select() { return this.$store.state.color_select },
		color_swatch_class() {
			if (!this.color) return ''
			else return 'k-list-color-' + this.color
		},
	},
	created() {
	},
	mounted() {
		console.log('CollectionEdit', this.learning_progression)
		this.initial_values = JSON.stringify(this.learning_progression.copy_for_save())

		// create a copy of the lp to get a copy of units
		let lp_copy = new Learning_Progression(this.learning_progression)
		this.units = lp_copy.units

		vapp.collection_editor = this
	},
	methods: {
		editor_config(text, inline) {
			return U.get_froala_config({
				placeholderText: text,
				toolbarInline: (inline === true),
			})
		},

		create_new_unit() {
			let u = new LP_Unit()
			// remove duration value so label shows in input
			u.duration = ''
			this.units.push(u)
		},

		delete_unit(unit) {
			if (this.unit_deletion_confirmed) {
				this.units.splice(this.units.findIndex(x=>x==unit), 1)

			} else {
				this.$confirm({
					text: 'Are you sure you want to delete this unit? (Note: you will not be asked to confirm subsequent unit deletions.)',
					acceptText: 'Delete Unit',
				}).then(y => {
					this.units.splice(this.units.findIndex(x=>x==unit), 1)
					// don't ask again
					this.unit_deletion_confirmed = true
				}).catch(n=>{}).finally(f=>{});
			}
		},

		move_unit(unit, direction) {
			let i = this.units.findIndex(x=>x==unit)

			if (direction == 'up') {
				if (i > 0) {
					this.units.splice(i, 1)
					this.units.splice(i-1, 0, unit)
				}
			} else {
				if (i < this.units.length - 1) {
					this.units.splice(i, 1)
					this.units.splice(i+1, 0, unit)
				}
			}
		},

		save_edits() {
			if (empty(this.title)) {
				this.$alert('The Resource Collection must have a title.')
				return
			}

			this.$store.commit('set', [this.learning_progression, 'title', this.title])
			this.$store.commit('set', [this.learning_progression, 'active', this.active ? 'yes' : 'no'])
			this.$store.commit('set', [this.learning_progression, 'description', this.description])
			this.$store.commit('set', [this.learning_progression, 'units', this.units])
			this.$store.commit('set', [this.learning_progression, 'featured', this.featured])
			this.$store.commit('set', [this.learning_progression, 'color', this.color])
			this.$store.commit('set', [this.learning_progression, 'sequence', this.sequence])

			if (JSON.stringify(this.learning_progression.copy_for_save()) != this.initial_values) {
				this.$store.dispatch('save_collection', this.learning_progression).then((result)=>{
					console.log(result)
					// set returned lp_id and course_code
					this.$store.commit('set', [this.learning_progression, 'lp_id', result.lp_id])
					this.$store.commit('set', [this.learning_progression, 'course_code', result.course_code])

					// set returned lp_unit_ids, in case one or more units is new
					for (let i = 0; i < this.learning_progression.units.length; ++i) {
						this.$store.commit('set', [this.learning_progression.units[i], 'lp_unit_id', result.lp_unit_ids[i] * 1])
					}

					// if course code was originally empty, have to refresh to the new course
					if (empty(this.course_code)) {
						let path = sr('/$1/$2/0', this.learning_progression.subject.toLowerCase(), this.learning_progression.course_code)
						document.location.pathname = path
					} else {
						this.cancel_editor('updated')
					}
				})
			} else {
				console.log('nothing changed, so not saving')
				this.cancel_editor('cancelled')
			}
		},

		cancel_editor(edit_action) {
			if (typeof(edit_action) !== 'string') edit_action = 'cancelled'
			this.$store.dispatch('lp_edit_close_socketmsg', {edit_action: edit_action, course_code: this.learning_progression.course_code})

			this.$nextTick(()=>this.$emit('editor_cancel'))
		},

	}
}
</script>

<style lang="scss">
.k-pdlp-editor-wrapper {
	clear:both;
	margin:10px;
	padding-top:15px;
	font-size:16px;
	h3 { padding-left:0!important; }

	.k-pdlp-editor-top-data {
		background-color:$v-amber-lighten-5;	// :#f08c78;
		padding:15px;
		margin:15px 0;
		border-radius:4px;
	}

	.k-pdlp-editor-units {
		margin-top:20px;
		background-color:#f8cdad;
		padding:10px 0px 15px 0;
		border-radius:8px;
	}

	.k-pdlp-editor-unit {
		padding:10px;
		border:4px solid transparent;
		width:calc(33% - 20px);
		margin:10px;
		border-radius:4px;
		background-color:$v-amber-lighten-5; 	// :#f8cdad;
	}

	.k-plp-color-menu {
		max-width:150px;
		margin-left:12px;
	}

	.k-plp-color-swatch {
		width:30px;
		height:30px;
		background-color:#999;
		align-self: center;
		margin-left:5px;
		border-radius:8px;
	}
}

</style>
