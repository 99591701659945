<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<div v-if="resource_viewable_by_user" class="k-resource-link" :class="top_css_class" :style="(resource.quick_look_showing||resource.editing)?'flex:1 1 100%':''" @mouseover="mouseover">
		<v-hover v-slot:default="{hover}"><div class="k-resource-link-inner" :class="hover?'k-resource-link-hovered':''">
			<div class="k-resource-link-description">
				<v-tooltip top transition="none" color="#666"><template v-slot:activator="{on}">
					<a v-on="on" v-if="has_openable_url" :href="url" @click="resource_link_clicked" target="_blank">
						<v-icon class="k-resource-type-icon" @mouseover="show_long_description=true" @mouseout="show_long_description=false" small>fas {{icon}}</v-icon>
						<span v-html="resource.description" class="k-resource-description-text ml-1">x</span>
					</a>
					<a v-on="on" v-else @click="resource_link_clicked" target="_blank">
						<v-icon class="k-resource-type-icon" @mouseover="show_long_description=true" @mouseout="show_long_description=false" small>fas {{icon}}</v-icon>
						<span v-html="resource.description" class="ml-1"></span>
					</a>
				</template>{{resource.description}}</v-tooltip>
				<div v-show="false && show_long_description && resource.long_description" class="k-resource-link-long-description" v-html="resource.long_description"></div>
			</div>
			<v-spacer/>
			<v-menu bottom left nudge-top="-16" v-model="assignment_menu_showing">
				<template v-slot:activator="{on}"><v-btn v-on="on" v-visible="hover" style="z-index:3" class="k-resource-link-options-menu-btn" icon small color="primary"><v-tooltip bottom><template v-slot:activator="{on}"><v-icon small v-on="on">fas fa-bolt</v-icon></template>Resource options</v-tooltip></v-btn></template>
				<v-list dense min-width="250">
					<v-list-item v-if="resource.teacher_facing"><v-list-item-title>This Teacher Resource should not be made available to students</v-list-item-title></v-list-item>
					<v-list-item v-if="!resource.teacher_facing&&resource.target_students=='adv'"><v-list-item-title>Tagged as an Advanced Learning Resource</v-list-item-title></v-list-item>
					<v-list-item v-if="!resource.teacher_facing&&resource.target_students=='ese'"><v-list-item-title>Tagged as an ESE Resource</v-list-item-title></v-list-item>
					<v-list-item><v-list-item-title class="text-center">RESOURCE OPTIONS</v-list-item-title></v-list-item>

					<v-list-item v-if="enable_edit_link" @click="$emit('edit_resource', resource)"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit resource</v-list-item-title></v-list-item>
					<v-list-item v-if="enable_remove_link" @click="$emit('remove_resource', resource)"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Remove resource</v-list-item-title></v-list-item>

					<v-list-item v-if="enable_gc_link" @click="add_to_gc"><v-list-item-icon><img src="./../../images/google_classroom_logo.png" style="height:18px;margin-top:3px;margin-left:-4px;border-radius:3px;"></v-list-item-icon><v-list-item-title>Add to Google Classroom</v-list-item-title></v-list-item>
					<v-list-item v-if="enable_add_to_current_hc_assignment" @click="add_to_current_assignment"><v-list-item-icon><v-icon small>fas fa-plus-square</v-icon></v-list-item-icon><v-list-item-title>Add to the Lesson Plan or Activity you’re currently editing</v-list-item-title></v-list-item>

					<v-list-item v-if="enable_hc_assignment&&!resource.teacher_facing" @click="$emit('assign_in_hc', {activity_type:'activity', resource:resource})"><v-list-item-icon><v-icon small>{{activity_type_icon('activity')}}</v-icon></v-list-item-icon><v-list-item-title>Create a new Student Activity with this resource</v-list-item-title></v-list-item>

					<!-- <v-menu v-if="enable_hc_assignment" :transition="false" offset-x nudge-top="8" open-on-hover style="display: block;">
						<template v-slot:activator="{on}"><v-list-item v-on="on" style="cursor:pointer">
							<v-list-item-icon><v-icon small>fas fa-bolt</v-icon></v-list-item-icon><v-list-item-title>Create a new Assignment with this resource</v-list-item-title>
							<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
						</v-list-item></template>

						<v-list dense>
							<v-list-item @click="$emit('assign_in_hc', {activity_type:'activity', resource:resource});assignment_menu_showing=false"><v-list-item-icon><v-icon small>{{activity_type_icon('activity')}}</v-icon></v-list-item-icon><v-list-item-title>{{activity_type_label('activity')}}</v-list-item-title></v-list-item>
							<v-list-item @click="$emit('assign_in_hc', {activity_type:'discussion', resource:resource})"><v-list-item-icon><v-icon small style="margin-left:3px;">{{activity_type_icon('discussion')}}</v-icon></v-list-item-icon><v-list-item-title>{{activity_type_label('discussion')}}</v-list-item-title></v-list-item>
							<v-list-item @click="$emit('assign_in_hc', {activity_type:'quiz', resource:resource})"><v-list-item-icon><v-icon small style="margin-left:3px;">{{activity_type_icon('quiz')}}</v-icon></v-list-item-icon><v-list-item-title>{{activity_type_label('quiz')}}</v-list-item-title></v-list-item>
						</v-list>
					</v-menu> -->
					<!-- assign_in_hc travels up to CVUnit.vue->assign_resource -->

					<v-list-item @click="copy_link_to_clipboard"><v-list-item-icon><v-icon small>fas fa-link</v-icon></v-list-item-icon><v-list-item-title>Copy link to clipboard</v-list-item-title></v-list-item>
					<v-list-item v-show="enable_report_issue" @click="$emit('report_issue',{resource:resource})"><v-list-item-icon><v-icon small class="ml-1">fas fa-exclamation</v-icon></v-list-item-icon><v-list-item-title>Report an issue with this resource</v-list-item-title></v-list-item>
					<!-- <v-list-item @click="copy_resource_title_to_clipboard"><v-list-item-icon><v-icon small>fas fa-clipboard</v-icon></v-list-item-icon><v-list-item-title>Copy resource title to clipboard</v-list-item-title></v-list-item> -->

				</v-list>
			</v-menu>
			<div v-if="resource.todo" class="ml-1 mr-1" style="cursor:pointer">
				<div v-if="!todo_complete" @click="mark_todo_assignment_complete"><v-icon class="k-resource-link-check-box">far fa-square</v-icon></div>
				<div v-else @click="complete_todo_assignment_clicked"><v-icon class="k-resource-link-check-box">fas fa-check-square</v-icon></div>
			</div>
			<div class="k-resource-link-hovered" v-show="hover">
				<v-btn v-if="quick_look_available" x-small @click="toggle_quick_look">Quick Look</v-btn>
				<!-- <span v-if="resource.teacher_facing" class="k-resource-link-teacher-facing-text mr-2">Teacher Resource</span> -->
			</div>
		</div></v-hover>

		<div v-show="show_inline_document" class="my-2 k-aspect-ratio-box--10-8 k-resource-link-iframe-outer"><div class="k-aspect-ratio-box-inside">
			<iframe :src="inline_document_src" class="k-resource-link-iframe"></iframe>
		</div></div>

		<div v-show="show_inline_html" class="my-2 k-resource-link-inline-html fr-view">
			<div class="d-flex" v-if="inline_html_toggleable">
				<v-spacer/>
				<v-btn class="mr-2" x-small color="secondary" @click="toggle_all_blocks(true)">Expand All</v-btn>
				<v-btn x-small color="secondary" @click="toggle_all_blocks(false)">Collapse All</v-btn>
			</div>
			<div v-html="inline_html"></div>
		</div>

		<input style="display:none" class="k-copy-link-hidden-input" type="text" :value="reslink">
		<input style="display:none" class="k-copy-title-hidden-input" type="text" :value="copyable_title">

		<TodoUserGroupChooser v-if="show_tugc_dialog" @dialog_cancel="todo_user_group_chooser_closed" />
		<AssessmentOptions v-if="show_assessment_options" :resource="resource" @dialog_cancel="show_assessment_options=false" />
		<VideoPlayer v-if="show_video_player" :resource="resource" @dialog_cancel="show_video_player=false" @video_complete="video_complete" @save_video_progress="save_video_progress" />
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TodoUserGroupChooser from './TodoUserGroupChooser'
import AssessmentOptions from './AssessmentOptions'
import VideoPlayer from './VideoPlayer'

export default {
	components: { TodoUserGroupChooser, AssessmentOptions, VideoPlayer },
	props: {
		resource: { type: Object, required: true },
		enable_hc_assignment: { type: Boolean, required: false, default() { return false }},
		enable_edit_link: { type: Boolean, required: false, default() { return false }},
		enable_remove_link: { type: Boolean, required: false, default() { return false }},
		search_match: { type: String, required: false, default() { return '-' }},
		mark_district_unsanctioned: { type: Boolean, required: false, default() { return false }},
		small: { type: Boolean, required: false, default() { return false }},
		enable_report_issue: { type: Boolean, required: false, default() { return false }},
	},
	data() { return {
		show_inline_document: false,
		show_inline_html: false,
		show_long_description: false,
		inline_html: 'Loading...',
		retrieved_inline_html: false,
		inline_html_toggleable: false,
		link_clicked: false,
		show_tugc_dialog:false,
		show_assessment_options: false,
		show_video_player: false,
		assignment_menu_showing: false,
	}},
	computed: {
		...mapState(['open_assignment_editor', 'user_info', 'todo_user_group_warning_issued']),
		...mapGetters(['role', 'beta_options', 'manage_assignments']),
		lp_category() {
			// as of 8/2021 we can explicitly designate an "lp_category" for resource links; but prior to this we designated unit planning documents via their descriptions
			// possible values: unit_planning, course_guidance, course_ebook (see EditResource.vue)
			if (this.resource.lp_category) return this.resource.lp_category
			if (this.resource.description.search(/unit planning guide/i) > -1) return 'unit_planning'
			if (this.resource.description.search(/learning targets/i) > -1) return 'course_guidance'
			return ''
		},
		top_css_class() {
			let s = ''

			if (this.resource.type == 'folder') return ' k-resource-link--folder'

			if (this.small) s += ' k-resource-link-small'

			if (this.mark_district_unsanctioned && !this.resource.district_sanctioned) s += ' k-resource-link--not-district-sanctioned'

			if (this.resource.teacher_facing) s += ' k-resource-link--teacher-facing'
			else {
				if (this.resource.target_students == 'ese') s += ' k-resource-link--ese'
				if (this.resource.target_students == 'adv') s += ' k-resource-link--adv'
			}
			if (this.lp_category == 'unit_planning') s += ' k-resource-link--unit-planning-guide'
			if (this.lp_category == 'leader_resource') s += ' k-resource-link--leader-resource'
			if (this.lp_category == 'course_guidance') s += ' k-resource-link--course-guidance'
			if (this.lp_category == 'stem_resource') s += ' k-resource-link--stem'
			// if (this.lp_category == 'course_ebook') s += ' k-resource-link--course-ebook'

			// assessment needs to go after teacher-facing
			if (this.resource.type == 'assessment') s += ' k-resource-link--assessment'

			if (this.search_match == 'yes') s += ' k-resource-link--search_match'

			if (this.$store.state.last_viewed_resource_id == this.resource.resource_id) s += ' k-resource-link--last-viewed'

			s += ' elevation-1'		// originally we only added this with the beta 'new_collections' feature

			return s
		},
		reslink() {
			// link/url that we want to be used from the outside
			return 'https://henryconnects.henry.k12.ga.us/reslink/' + this.resource.resource_id
		},
		url() {
			if ((this.resource.url.indexOf('https:') == -1) && (this.resource.type == 'upload' || this.resource.type == 'html')) {
				let base = '/user-files/'
				return base + this.resource.url

			} else {
				return this.resource.url
			}
		},
		has_openable_url() {
			// if we don't have a url obviously we can't open
			if (empty(this.resource.url)) return false

			// lti and assessment resources don't open directly as links
			if (this.resource.type == 'lti' || this.resource.type == 'assessment') return false

			// for assigned videos that have youtube urls, we open in an inline video player
			if (this.resource.type == 'video' && this.resource.todo && U.youtube_id_from_url(this.resource.url)) return false

			// if we get to here, assume the url is openable
			return true
			// return (!empty(this.resource.url) && ['upload', 'html', 'website', 'document', 'video', 'interactive'].includes(this.resource.type))
		},
		icon() {
			if (this.resource.type == 'website') return 'fa-link'
			if (this.resource.type == 'assessment') return 'fa-clipboard-list'	// list-alt / list-ol
			if (this.resource.type == 'video' || this.resource.type == 'interactive') return 'fa-video'
			if (this.resource.type == 'upload') {
				let ext = this.resource.url.replace(/.*?\.(\w+)$/, '$1')
				if (ext == 'pdf') return 'fa-file-pdf'
				if (ext == 'doc') return 'fa-file-word'
				if (ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'pdf') return 'fa-file-image'
			}
			if (this.resource.type == 'html') return 'fa-file-alt'
			if (this.resource.type == 'folder') return 'fa-folder'
			return 'fa-file'
		},
		inline_document_src() {
			if (this.show_inline_document) return this.url
			else return ''
		},
		quick_look_available() {
			// quick look always available for certain types...
			if (this.resource.type == 'html') return true
			if (this.resource.type == 'document') return true

			// youtube videos might work? but not always unfortunately
			// if (this.resource.url.search(/youtu.*?be/i) > -1) return true

			// uploaded pdfs and images should work
			if (this.resource.type == 'upload') {
				if (this.resource.url.search(/\.pdf/i) > -1) return true
				if (this.icon == 'fa-file-image') return true
			}

			// for other things be safe and don't allow it
			return false
		},
		enable_add_to_current_hc_assignment() {
			// you can add the resource to the currently-edited hc assignment if enable_hc_assignment is true and we have an active assignment editor
			if (this.enable_hc_assignment) {
				if (this.open_assignment_editor && this.open_assignment_editor.editor_active) {
					return true
				}
			}
			return false
		},
		enable_gc_link() {
			// don't show gc link if we're in manage_assignments mode
			// NO: for now at least, show it because some teachers might still be using GC
			// if (this.manage_assignments) return false
			
			// currently we show gc links for resources as long as the user isn't a parent or student
			if (this.role == 'student' || this.role == 'parent') return false
			return true
		},
		copyable_title() {
			return $(sr('<div>$1</div>', this.resource.description)).text()
		},
		todo_complete: {
			get() {
				// todo_status value is a timestamp, so if it's > 100, it's complete
				// note that the todo_status for a video may be 5-95, indicating partial completion
				return this.user_info.todo_status[this.resource.resource_id] > 100
			},
			set(val) {
				// record new value in database; the dispatch will update the store
				// if complete, we send a value of 100, which will get saved in the db as the timestamp
				let payload = {resource_id: this.resource.resource_id, todo_status: (val ? 100 : 0)}
				U.loading_start()
				this.$store.dispatch('save_resource_completion', payload).then(x=>U.loading_stop()).catch(x=>U.loading_stop())
			}
		},
		is_required_video() {
			return this.resource.type == 'video' && this.resource.todo && U.youtube_id_from_url(this.resource.url)
		},
		resource_viewable_by_user() {
			// teacher resources are not viewable by students or parents, unless family_avail is set to true
			if (this.resource.teacher_facing && !this.resource.family_avail) {
				if (this.role == 'student' || this.role == 'parent') {
					return false
				}
			}
			return true
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		activity_type_icon(activity_type) { return U.activity_type_icon(activity_type) },
		activity_type_label(activity_type) { return U.activity_type_label(activity_type) },

		mouseover() {
			// for now don't worry about the long_description
			if (!this.resource.full_resource_data_loaded) {
				// this.get_resource_record(false)
			}
		},

		add_to_current_assignment() {
			// this won't be called unless we have an active open_assignment_editor
			this.open_assignment_editor.add_existing_resource(this.resource)
		},

		add_to_gc() {
			// Done by MZ
			this.$store.dispatch('log_resource_usage', {
				'user_id': this.$store.state.user_info.user_id,
				'user_email': this.$store.state.user_info.email,
				'school': this.$store.state.user_info.district_department.join(','),
				'system_role': this.$store.state.user_info.system_role,
				'resource_id': this.resource.resource_id,
				'resource_title': this.resource.description,
				'action': 'add_to_gc'
			})

			// for assessments, can't add to gc directly
			if (this.resource.type == 'assessment') {
				this.$alert(sr('To add an Illuminate assessment to Google Classroom, click the <a href="$1" target="_illuminate">resource link</a> to open the assessment in Illuminate, then generate a “Quick Code link” for your class in the Illuminate system, and add this Quick Code link to Google Classroom.', this.url))
				return
			}

			// let url = 'https://classroom.google.com/share?url=' + this.resource.url
			let url = sr('https://classroom.google.com/share?url=$1', this.reslink)
			// add title to url
			url += sr('&title=$1', this.copyable_title)
			window.open(url)

			// TODO for MZ: call a service to note that the teacher has initiated adding the resource to GC
		},

		copy_link_to_clipboard() {
			// temporarily show the input (if we don't do this first, the select/execCommand doesn't work)
			let jq = $(this.$el).find('.k-copy-link-hidden-input')
			jq.show()

			// select the input value and copy to clipboard
			jq[0].select()
			document.execCommand("copy")

			// re-hide the input
			jq.hide()

			this.$inform('Resource link copied to clipboard')
		},

		copy_resource_title_to_clipboard() {
			// temporarily show the input (if we don't do this first, the select/execCommand doesn't work)
			let jq = $(this.$el).find('.k-copy-title-hidden-input')
			jq.show()

			// select the input value and copy to clipboard
			jq[0].select()
			document.execCommand("copy")

			// re-hide the input
			jq.hide()

			this.$inform('Resource title copied to clipboard')
		},

		toggle_quick_look() {
			let quick_look_showing
			if (this.resource.type == 'html') {
				this.show_inline_html = !this.show_inline_html
				quick_look_showing = this.show_inline_html
				if (this.show_inline_html) {
					// get html fresh every time, in case of an edit
					$.ajax({
						url: this.url,
						success: (html)=>{
							this.inline_html = html
							this.retrieved_inline_html = true
							this.$nextTick(()=>this.initialize_lp_blocks())
						},
						cache: false,
					})
				}

			} else {
				this.show_inline_document = !this.show_inline_document
				quick_look_showing = this.show_inline_document
			}
			this.$store.commit('set', [this.resource, 'quick_look_showing', quick_look_showing])

			// mark link as clicked if they use quick_look
			this.link_clicked = true
		},

		initialize_lp_blocks() {
			$(this.$el).find('a').attr('target', '_blank')
			$(this.$el).find('.klp-header').on('click', function(){
				$(this).next().slideToggle(50)
			})
			if ($(this.$el).find('.klp-header').length > 0) {
				this.inline_html_toggleable = true
			}
		},

		toggle_all_blocks(on) {
			if (on) $(this.$el).find('.klp-block').slideDown()
			else $(this.$el).find('.klp-block').slideUp()
		},

		get_resource_record(and_launch) {
			this.$store.commit('set', [this.resource, 'full_resource_data_loaded', true])

			let payload = {resource_id: this.resource.resource_id}
			if (and_launch == true) {
				payload.get_lti_form = 'yes'
				// add debug param
				if (this.$store.state.debug_lti_resource_launches) payload.debug_lti_resource_launches = 'yes'
			}

			this.$store.dispatch('get_resource_record', payload).then(result=>{
				// fill in parts of the resource that we need but might not have been fully filled in previously, including overwriting the type
				this.$store.commit('set', [this.resource, 'type', result.resource_record.type])
				this.$store.commit('set', [this.resource, 'url', result.resource_record.url])
				this.$store.commit('set', [this.resource, 'long_description', result.resource_record.long_description])

				console.log(this.resource)

				// if and_launch is true, launch the link once we get the record data back
				if (and_launch == true) {
					// if we got back an lti_form, launch
					if (!empty(result.lti_form)) {
						// for the lti_form we open a new window and write out the form, which submits itself
						// see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
						let w = window.open()
						w.document.write(result.lti_form)
					} else {
						// else we just open the new window to the resource's url
						window.open(this.resource.url, '_blank')
					}
				}
			})
		},

		resource_link_clicked() {
			// TODO for MZ: when the resource is clicked, call a service to record that the user has clicked the link

			// if type is 'collection_item', or type is 'lti', call get_resource_record to get the full resource data and retrieve the lti_form if necessary
			if (this.resource.type == 'collection_item' || this.resource.type == 'lti') {
				// (note that lti forms are timestamped, so we need to get a new form every time the link is launched)
				this.get_resource_record(true)
			} else {
				// Done by MZ
				console.log("click resource link recourse"); console.log(this.resource)
				// explicitly call logging service if not logging via get_resource_record service
				this.$store.dispatch('log_resource_usage', {
					'user_id': this.$store.state.user_info.user_id,
					'user_email': this.$store.state.user_info.email,
					'school': this.$store.state.user_info.district_department.join(','),
					'system_role': this.$store.state.user_info.system_role,
					'resource_id': this.resource.resource_id,
					'resource_title': this.resource.description,
					'action': 'view'
				})

			}

			// for assessments, we set show_assessment_options to true to show links + instructions
			if (this.resource.type == 'assessment') {
				this.show_assessment_options = true
			}

			// for required video, show the video player
			if (this.is_required_video) {
				this.show_video_player = true
			}

			this.$emit('resource_opened')

			// mark link as clicked
			this.link_clicked = true

			// mark as the last-viewed resource
			this.$store.commit('set', ['last_viewed_resource_id', this.resource.resource_id])
		},

		todo_user_group_chooser_closed() {
			this.show_tugc_dialog = false
			// once the user has gone through the group chooser (whether or not they selected a group), go back to the mark dialog
			this.mark_todo_assignment_complete()
		},

		mark_todo_assignment_complete() {
			if (this.is_required_video) {
				this.$alert('This resource is a video you must watch. Click the link and play the video; when the video completes playing you will be automatically marked as having completed the assignment.')
				return
			}

			if (!this.link_clicked) {
				this.$alert('You must click the link to open and interact with the resource before you can mark that you’ve completed it.')
				return
			}

			if (empty(this.user_info.todo_user_group) && !this.todo_user_group_warning_issued) {
				this.show_tugc_dialog = true
				this.$store.commit('set', ['todo_user_group_warning_issued', true])
				return
			}

			this.$confirm({
			    title: 'Confirm Resource Completion',
			    text: sr('<div class="mb-2"><b>$1</b></div>By clicking the button below, you are confirming that you have completed this resource.', this.resource.description),
			    acceptText: 'Confirm',
				acceptColor: 'pink accent-4',
				// dialogMaxWidth: 800
			}).then(y => {
				this.todo_complete = true
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		complete_todo_assignment_clicked() {
			let msg = 'Are you sure you want to mark this resource as not complete?'
			if (this.is_required_video) {
				msg += ' You will have to re-watch the video in order to re-complete the resource assignment.'
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: msg,
			    acceptText: 'Mark as Not Complete',
			}).then(y => {
				this.todo_complete = false
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		save_video_progress(pct_complete) {
			// save the percent complete as the todo_status
			let payload = {resource_id: this.resource.resource_id, todo_status: pct_complete}
			this.$store.dispatch('save_resource_completion', payload)
		},

		video_complete() {
			// this will trigger a save_resource_completion call in the todo_complete computed setter
			this.todo_complete = true
		},
	}
}
</script>

<style lang="scss">
.k-resource-link {
	background-color:$v-amber-lighten-3;		// #fbe4d4
	border-radius:8px;
	padding:4px 8px 4px 6px;
	position:relative;
	min-height:38px;
	white-space: normal;
	width:calc(100% - 8px);

	.k-resource-link-inner {
		display:flex;
		align-items: center;
		min-height:30px;
		position:relative;

		.k-resource-link-hovered {
			width:100%;
			position:absolute;
			top:20px;
			left:0;
			z-index:2;
			text-align:center;
		}

		.k-resource-link-teacher-facing-text {
			white-space:nowrap;
			color:#fff;
			font-weight:900;
			letter-spacing:1px;
			text-transform: uppercase;
			font-size:10px;
			background-color:rgba(2,136,209,0.7);
			padding:3px 5px;
			border-radius:3px;
		}
	}

	a {
		font-family:$roboto-font;
		text-decoration:none;
		color:$v-brown-darken-2!important;	// #b48a6b
		// font-weight:bold;
		font-size:13px;
	}
	a span:hover {
		text-decoration:underline;
	}
	.v-icon.k-resource-type-icon { color:$v-brown!important; }

	.k-resource-link-description {
		flex: 0 1 auto;
		margin-left:5px;
		margin-top:-6px;
		min-width: 0;
		overflow:hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.k-resource-link-gc-btn {
		img {
			height:18px;
		}
	}

	.k-resource-link-iframe {
		width:100%;
		height:100%;
		border:0;
		background-color:#fff;
	}

	.k-resource-link-inline-html {
		background-color:#fff;
		border-radius:8px;
		padding:5px 15px 15px 15px;
		font-size:14px;
	}
}

.k-resource-link-small {
	padding: 2px 4px 2px 3px;
	border-radius:6px;
	min-height:20px;

	.k-resource-link-inner {
		min-height:18px;
	}

	a {
		font-size:11px;
		line-height:13px;
	}

	.fas {
		font-size:12px!important;
	}

	.v-btn--icon.v-size--small {
		height:20px;
		width:20px;
	}
}

.k-resource-link-hovered {
	z-index:200;
}

.k-resource-link--teacher-facing {
	background-color:$v-light-blue-lighten-4;
	a, .v-icon.k-resource-type-icon {
		color:$v-light-blue-darken-4!important;
	}
}

.k-resource-link--adv {
	background-color:$v-purple-lighten-4;
	a, .v-icon.k-resource-type-icon {
		color:$v-purple-darken-4!important;
	}
}

.k-resource-link--ese {
	background-color:$v-teal-lighten-4;
	a, .v-icon.k-resource-type-icon {
		color:$v-teal-darken-4!important;
	}
}

.k-resource-link--not-district-sanctioned {
	background-color:$v-red-lighten-4;
	a, .v-icon.k-resource-type-icon {
		color:$v-red-darken-3!important;
	}
}

.k-resource-link--unit-planning-guide {
	background-color:$v-green-lighten-4;
	a, .v-icon.k-resource-type-icon {
		color:$v-green-darken-3!important;
		// font-weight:900;
	}
}

.k-resource-link--course-guidance {
	background-color:$v-cyan-lighten-3;
	a, .v-icon.k-resource-type-icon {
		color:$v-cyan-darken-4!important;
		// font-weight:900;
	}
}

.k-resource-link--stem {
	background-color:$v-lime-lighten-3;
	a, .v-icon.k-resource-type-icon {
		color:$v-lime-darken-4!important;
		// font-weight:900;
	}
}

.k-resource-link--leader-resource {
	background-color:$v-brown-lighten-4;
	a, .v-icon.k-resource-type-icon {
		color:$v-brown-darken-4!important;
		// font-weight:900;
	}
}

// this needs to come after "teacher-facing"
.k-resource-link--assessment {
	background-color:$v-pink-accent-4;
	a, .v-icon.k-resource-type-icon {
		color:#fff!important;
	}
}

.k-resource-link--last-viewed {
	box-shadow: 0px 0px 8px $v-pink-darken-3;
}

.k-aspect-ratio-box--10-8 {
	height: 0;
	overflow: hidden;
	padding-top: 10px / 8px * 100%;
	background: white;
	position: relative;
}
.k-aspect-ratio-box-inside {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.k-resource-link-iframe-outer.k-aspect-ratio-box--10-8 {
	// use this so the iframe won't be too tall when it's wide
	padding-top: min(70vh, 125%);
}

// wrap a list of resources in this div to make them appear across the page
.k-resources-list {
	display:flex;
	justify-content: space-between;
	flex-wrap:wrap;
}
// this makes it so items in the final "row" of resources don't stretch across the page if they shouldn't do so
.k-resources-list::after {
	content: '';
	flex-grow: 1000000000;
}

.k-resource-link-long-description {
	position:fixed;
	width:400px;
	background-color:rgba(0,0,0,0.9);
	color:#fff;
	padding:8px;
	border-radius:6px;
}

// .k-resource-link--search_match {
// 	background-color:$v-yellow-lighten-3!important;
// 	border:2px solid $v-yellow-darken-1!important;
// 	padding:2px 6px 2px 4px;
// }

.k-resource-link--folder {
	background-color:$v-grey-lighten-3!important;
}

.k-resource-link-check-box {
	color:$v-pink-accent-3!important;
}
</style>
