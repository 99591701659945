<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-page-wrapper">
	<h2 class="k-page-title d-flex align-end">
		<v-icon @click="return_to_admin_main" large color="primary" class="mr-3">fas fa-cog</v-icon>
		<div>Site Usage Statistics</div>
		<v-spacer />
		<v-menu bottom left light>
			<template v-slot:activator="{on}"><v-btn dark v-on="on" color="primary" style="text-transform:none;letter-spacing:0;">{{charts[chart_showing].name}}</v-btn></template>
			<v-list dense>
				<v-list-item v-for="(chart, key, index) in charts" :key="key" @click="chart_showing=key"><v-list-item-title>{{chart.name}}</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</h2>

	<v-card style="background:#222; padding-top:12px; margin:16px 0;">
		<highcharts ref="graph" class="chart" :options="chartOptions"></highcharts>
	</v-card>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
	},
	data() { return {
		counts_loaded: false,
		zoomed: false,
		charts: {
			logins_24: { name: 'User Sign-Ins Per Day', y_label: 'User Count', data: {sf:[], su:[], pr:[]} },
			users: { name: 'Cumulative Users (staff since 9/28/2020, students since 8/31/2021)', y_label: 'User Count', data: {sf:[], su:[], pr:[]} },
			resource_usage_24: { name: 'Resource Usage Per Day', y_label: 'Resource Count', data: {tv:[], sv:[]} },
			resource_usage: { name: 'Cumulative Resource Usage (since 8/31/2021)', y_label: 'Resource Count', data: {tv:[], sv:[]} },
			gc_adds_24: { name: 'Resources Added to Google Classroom Per Day', y_label: 'Resources Added to GC', data: {agc:[]} },
			gc_adds: { name: 'Cumulative Resources Added to Google Classroom (since 8/31/2021)', y_label: 'Resources Added to GC', data: {agc:[]} },

			// skip cumulative logins for now
			// logins: { name: 'Cumulative Logins', y_label: 'User Count', data: {sf:[], su:[], pr:[]} },
		},
		chart_showing: 'logins_24',
	}},
	computed: {
		...mapState(['user_info']),
		chartOptions() {
			let self = this

			let o = {
				chart: {
					type: 'line',
					// height: 220,
					zoomType: 'x',
					resetZoomButton: {
						position: {
							align: 'left',
							x: 50,
						},
					},
					fontFamily: 'Arial, sans-serif',
					events: {
				        render: function() {
							if (vapp.admin_usage.zoomed) return

							let chart = this
							let series = this.series[0]
							let xAxis = chart.xAxis[0]
							let newStart = series.xData[series.xData.length - 10]
							let newEnd = series.xData[series.xData.length - 1]

							if (!empty(newStart)) {
								vapp.admin_usage.zoomed = true
								xAxis.setExtremes(newStart, newEnd)
								chart.showResetZoom()
							}
						}
			        },
				},
				title: { text: null },
				xAxis: {
					type: 'datetime',
					title: null,
					visible: true,
					labels: {
						formatter: function() {
							return date.format(new Date(this.value), 'dd M/D')
						}
					},
				},
				yAxis: {
					min: this.chart_min,
					max: this.chart_max,
					title: { text: vapp.admin_usage.charts[vapp.admin_usage.chart_showing].y_label },
					allowDecimals: false,
				},
				tooltip: {
					formatter: function() {
						return date.format(new Date(this.x), 'dd M/D/YY') + ': ' + this.y
					}
				},
				plotOptions: {
				},
				legend: {
					enabled: true,
					layout: 'horizontal',
				},
				credits: { enabled: false },
			}

			if (vapp.admin_usage.chart_showing == 'logins_24' || vapp.admin_usage.chart_showing == 'users') {
				o.series = [
					{ name: 'Staff', marker: { symbol: 'circle', fillColor: '#7986cb'}, color:'#7986cb', data: vapp.admin_usage.charts[vapp.admin_usage.chart_showing].data.sf },
					{ name: 'Students', marker: { symbol: 'circle', fillColor: '#ff9800'}, color:'#ff9800', data: vapp.admin_usage.charts[vapp.admin_usage.chart_showing].data.su },
					{ name: 'Parents', marker: { symbol: 'circle', fillColor: '#4DB6AC'}, color:'#4DB6AC', data: vapp.admin_usage.charts[vapp.admin_usage.chart_showing].data.pr },
				]
			} else if (vapp.admin_usage.chart_showing == 'resource_usage_24' || vapp.admin_usage.chart_showing == 'resource_usage') {
				o.series = [
					{ name: 'Staff', marker: { symbol: 'circle', fillColor: '#7986cb'}, color:'#7986cb', data: vapp.admin_usage.charts[vapp.admin_usage.chart_showing].data.tv },
					{ name: 'Students', marker: { symbol: 'circle', fillColor: '#ff9800'}, color:'#ff9800', data: vapp.admin_usage.charts[vapp.admin_usage.chart_showing].data.sv },
				]
			} else if (vapp.admin_usage.chart_showing == 'gc_adds_24' || vapp.admin_usage.chart_showing == 'gc_adds') {
				o.series = [
					{ name: 'Count', marker: { symbol: 'circle', fillColor: '#ffd54f'}, color:'#ffd54f', data: vapp.admin_usage.charts[vapp.admin_usage.chart_showing].data.agc },
				]
			}

			return o
		},
	},
	created() {
		// needed to allow self-reference in chart options
		vapp.admin_usage = this
		this.get_usage_data()
	},
	mounted() {
	},
	methods: {
		get_usage_data() {
			U.loading_start()
			U.ajax('admin_get_usage_data', {user_id: this.user_info.user_id}, result=>{
				// result returns 3 files (as of 9/17/2021):
				//    users: user_id's that have signed in each day (not currently displayed here)
				//    counts: counts of users that have signed in each day, and cumulative user counts
				//    resource_counts: counts of resources used by students and teachers

				function parse_file(file) {
					let o = {}
					let lines = file.split("\n")
					for (let line of lines) {
						line = $.trim(line)
						if (empty(line)) continue
						if (line.search(/^(\d+):(.*)/) > -1) {
							let ts = RegExp.$1
							let json = RegExp.$2
							o[ts] = JSON.parse(json)
						}
					}
					return o
				}

				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				/////////////////////////
				// USER AND LOGIN COUNTS
				let counts = parse_file(result.counts)
				// 1601366461:{"usf":6,"usu":1,"upr":2,"lsf":6,"lsu":1,"lpr":2,"l24sf":3,"l24su":1,"l24pr":0}
				// console.log('counts', counts)
				for (let ts in counts) {
					// manipulate date to make x-axis ticks line up properly
					let dts = (ts - 8 * 3600) * 1000
					// let dts = ts * 1000
					// total users
					this.charts.users.data.sf.push([dts, counts[ts].usf])	// sf = staff
					this.charts.users.data.su.push([dts, counts[ts].usu])	// su = students
					this.charts.users.data.pr.push([dts, counts[ts].upr])	// pr = parent

					// Skip cumulative logins for now
					// this.charts.logins.data.sf.push([dts, counts[ts].lsf])
					// this.charts.logins.data.su.push([dts, counts[ts].lsu])
					// this.charts.logins.data.pr.push([dts, counts[ts].lpr])

					// logins in the last 24 hours
					this.charts.logins_24.data.sf.push([dts, counts[ts].l24sf])
					this.charts.logins_24.data.su.push([dts, counts[ts].l24su])
					this.charts.logins_24.data.pr.push([dts, counts[ts].l24pr])
				}

				/////////////////////////
				// RESOURCE USAGE COUNTS
				counts = parse_file(result.usage_counts)
				// 1630396861:{"tv":146,"agc":5,"sv":53,"tv24":146,"agc24":5,"sv24":53}
				// console.log('usage_24', counts)
				for (let ts in counts) {
					// manipulate date to make x-axis ticks line up properly
					let dts = (ts - 8 * 3600) * 1000
					// cumulative
					this.charts.resource_usage.data.tv.push([dts, counts[ts].tv])	// teacher resource views
					this.charts.resource_usage.data.sv.push([dts, counts[ts].sv])	// student resource views
					this.charts.gc_adds.data.agc.push([dts, counts[ts].agc])	// resources added to google classroom

					// last 24 hours
					this.charts.resource_usage_24.data.tv.push([dts, counts[ts].tv24])
					this.charts.resource_usage_24.data.sv.push([dts, counts[ts].sv24])
					this.charts.gc_adds_24.data.agc.push([dts, counts[ts].agc24])
				}

				this.counts_loaded = true
			});
		},

		return_to_admin_main() {
			this.$router.push({ path: '/welcome' })
		}
	}
}
</script>

<style lang="scss">
</style>
