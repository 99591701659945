<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<div v-if="maximized" class="k-sparkl-overlay-scrim"></div>
	<div :class="maximized?'k-sparkl-maximized':''">
		<div v-if="sparkl_loading_status" v-show="show_sparkl" class="k-sparkl-inline-outer" :data-sparkl_embed_id="sparkl_embed_id">
			<iframe :name="'sparkl_iframe-'+sparkl_embed_id" class="k-sparkl-inline-iframe" allow="camera *;microphone *;clipboard-read *;clipboard-write *"></iframe>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		activity: { required: true },
		initial_content: { required: false, default() { return '' } },	// this can be sent in to create an activity with the given initial content
		coteacher_role: { required: false, default() { return '' } },	// this can be sent in to designate that the user has a given coteacher_role -- 
		context: { required: false, default() { return 'view' } },		// the other current option is 'edit'; this affects which UI elements Sparkl shows
		force_maximize: { required: false, default() { return false } },
		role_override: { required: false, default() { return '' } },	// send in 'student' to force the student view
	},
	data() { return {
		sparkl_embed_id: U.new_uuid(),
		sparkl_loading_status: false,
		show_sparkl: false,
		establish_connection_tries: 0,
		connection_established: false,
		initialize_params: {},

		maximized: this.force_maximize,

		sparkl_new_window_url: '',

		pm_verbose: true,
	}},
	computed: {
		...mapState(['user_info', 'pm_event_listener_created', 'sparkl_embed_components', 'single_item', 'sparkl_origin']),
		...mapGetters(['role', 'studentish_role']),
		sparkl_activity_id() { return this.activity.tool_activity_id },
		lti_resource_link_id() { return this.activity.lti_resource_link_id },
		activity_title() { return this.activity.activity_title },
		lti_context_id() {
			// for now at least, use the creator_user_id to generate the lti_context_id
			return 'henryconnects_' + this.activity.creator_user_id
		},
		lti_context_title() {
			// for now return this as the class title
			return `HenryConnects LTI Context for user ${this.activity.creator_user_id}`;
		},
		openable_in_new_window() {
			// if we're already open in single_item mode, don't show the button again
			if (this.single_item) return false

			// in edit context, we need to communicate with the HC frame, so don't allow this
			if (this.context == 'edit') return false

			// keep students focused on a single activity; also it's crucial that Sparkl communicate with HC here
			if (this.studentish_role) return false

			// if we get to here allow to be opened in a new tab
			return true
		},
	},
	watch: {
	},
	created() {
		// for now we'll just always show right away
		this.execute('show')
		this.show_sparkl = true

		// save this component in state.sparkl_embed_components (see below)
		this.$store.commit('set', [this.sparkl_embed_components, this.sparkl_embed_id, this])
		console.log(sr('sparkl_activity_id $1; sparkl_embed_id: $2; sparkl_origin: $3', this.sparkl_activity_id, this.sparkl_embed_id, this.sparkl_origin))

		if (this.force_maximize) {
			this.$store.commit('set', ['activity_embed_maximized', true])
			$('html').css('overflow', 'hidden')
			vapp.toggle_google_translate_btn('hide')

			// control bar is in App.vue so that it can appear above everything else
			vapp.$refs.sparkl_embed_control_bar.show(this, this.context)
		}
	},
	mounted() {
	},
	methods: {
		execute(cmd, data) {
			if (this.pm_verbose) console.log(sr('PMX HOST ==> execute cmd $1: $2', cmd, JSON.stringify(data)))
			return new Promise((resolve, reject)=>{
				// if the sparkl iframe hasn't finished loading...
				if (this.sparkl_loading_status != 'loaded') {
					// if we haven't even started loading...
					if (!this.sparkl_loading_status) {
						// setting sparkl_loading_status to a non-falsey value renders the iframe
						this.sparkl_loading_status = 'loading'

						// execute an LTI launch to sparkl
						this.lti_launch()

						// initialize postMessage; once loading is done, sparkl_loading_status will be set to 'loaded', 
						// then pm_execute_from_host will eventually call execute_cmd, sending through the initialize_params we store here (including resolve and reject)
						this.initialize_params = {cmd:cmd, data:data, resolve:resolve, reject:reject}
						this.pm_initialize()
					}

				} else {
					// else we're already loaded, so send the command
					this.execute_cmd(cmd, data, resolve, reject)
				}
			})
		}, 

		execute_cmd(cmd, data, resolve, reject) {
			if (cmd == 'show') {
				this.show_sparkl = true
				if (resolve) resolve('ok')

			} else if (cmd == 'hide') {
				// TODO: send a msg to sparkl telling it to suspend activity (i.e. stop checking the server for updates); and/or close the window altogether after a few minutes of inactivity
				this.show_sparkl = false
				if (resolve) resolve('ok')

			} else if (cmd == 'host_activity_saved') {
				// send a message TO sparkl telling it that the activity has been saved
				this.pm_send('host_activity_saved', data, resolve, reject)
			
			// one example of a non-show/hide cmd from Satchel; see SatchelEmbed for more examples
			// } else if (cmd == 'search') {
			// 	this.pm_send('search', {search_terms: data.search_terms, limit_to: data.limit_to}, resolve, reject)
			// 	if (resolve) resolve('ok')
			}
		},

		lti_launch() {
			let embedded_flag = this.context
			if (this.force_maximize) embedded_flag += '-maxonly'

			// exception for sparkl_origin: if we're using localhost and role_override is student, use the student origin
			let sparkl_origin = this.sparkl_origin
			if (sparkl_origin.indexOf('localhost') > -1 && this.role_override == 'student') sparkl_origin = 'http://localhost:8071'

			let payload = { 
				email: this.user_info.email, 
				context_id: this.lti_context_id,
				context_title: this.lti_context_title,
				lti_resource_link_id: this.lti_resource_link_id,
				sparkl_activity_id: this.sparkl_activity_id,
				activity_title: this.activity_title,
				embedded_flag: embedded_flag,
				role_override: this.role_override,
				demo_mode: this.role_override ? 'on' : '',
				sparkl_origin: sparkl_origin,
			}
			if (this.initial_content) payload.initial_content = this.initial_content
			if (this.coteacher_role) payload.coteacher_role = this.coteacher_role
			if (sparkl_origin.indexOf('localhost') > -1) payload.localhost = 'true'

			// add lti_section_collections if we have a course_code for the activity...
			if (this.activity.course_code) {
				// for staff, don't send if role_override is student
				if (this.role == 'staff') {
					if (this.role_override != 'student') {
						// and for staff, we send all assigned sections, and all students in all assigned sections
						let lti_section_collections
						// note that we might have multiple sis_classes records if teaching block and non-block courses
						let sis_classes = this.$store.state.sis_classes.filter(x=>x.course_code==this.activity.course_code)
						if (sis_classes.length > 0) for (let assignee of this.activity.assigned_to) {
							if (empty(assignee.user_sourcedId)) {
								for (let c of sis_classes) {
									let index = c.class_sourcedIds.findIndex(x=>x==assignee.class_sourcedId)
									// don't send section data for sections with no students
									if (index > -1 && $.isArray(c.students[index])) {
										console.log('send section data for', assignee.class_sourcedId, index)
										if (!lti_section_collections) lti_section_collections = {}
										lti_section_collections[assignee.class_sourcedId] = {
											title: c.section_title(index, {term:0, title:true}),
											students: {},
										}
										for (let student of c.students[index]) {
											lti_section_collections[assignee.class_sourcedId].students[student.email] = {g: student.givenName, f: student.familyName}
										}
									}
								}
							}
						}

						// we got any lti_section_collections, send them, stringified, with the payload
						// if (lti_section_collections) console.log('lti_section_collections', JSON.stringify(lti_section_collections).length, lti_section_collections)
						if (lti_section_collections) payload.lti_section_collections = JSON.stringify(lti_section_collections)
					}

				// for studentish, send only sections the student is a member of, and only send their email
				} else if (this.studentish_role) {
					let lti_section_collections
					let sis_classes = this.$store.state.sis_classes.filter(x=>x.course_code==this.activity.course_code)
					if (sis_classes.length > 0) for (let assignee of this.activity.assigned_to) {
						if (empty(assignee.user_sourcedId)) {
							for (let c of sis_classes) {
								let index = c.class_sourcedIds.findIndex(x=>x==assignee.class_sourcedId)
								if (index > -1) {
									// if we find the class_sourcedId in the student's sis_classes records, the student is definitionally in that class
									console.log('send section data for', assignee.class_sourcedId)
									if (!lti_section_collections) lti_section_collections = {}
									lti_section_collections[assignee.class_sourcedId] = {
										title: c.section_title(index, {term:0, title:true}),
										students: {},
									}
									lti_section_collections[assignee.class_sourcedId].students[this.user_info.email] = {g: this.user_info.first_name, f: this.user_info.last_name}
								}
							}
						}
					}

					// we got any lti_section_collections, send them, stringified, with the payload
					// if (lti_section_collections) console.log('lti_section_collections', JSON.stringify(lti_section_collections).length, lti_section_collections)
					if (lti_section_collections) payload.lti_section_collections = JSON.stringify(lti_section_collections)
				}
			}

			// debug
			// payload.debug_sparkl_resource_launches = 'yes'

			U.ajax('get_sparkl_lti_launch', payload, result=>{
				if (result.status != 'ok') {
					vapp.ping()		// call ping to check if the session is expired
					vapp.$alert('An error occurred when attempting to open the Sparkl activity.')
					return
				}

				// console.log('got lti_form:', result.lti_form)
				// we should return back the lti launch form; write it out to the iframe
				window['sparkl_iframe-'+this.sparkl_embed_id].document.write(result.lti_form)
			});
		},

		// this is the initialize fn for the host window that embeds/opens sparkl in an iframe window
		// here, we establish the eventListener for postMessages (if it hasn't already been established), then send a message to sparkl establishing the connection.
		pm_initialize() {
			// set up eventListener for postMessages -- BUT we only set up one listener for the window, no matter how many Sparkl iframes we embed
			if (!this.pm_event_listener_created) window.addEventListener('message', (event) => {
				// event.source is the window that sent the message (the window that included the iframe)
				// event.origin is the origin that sent it; make sure we trust it
				// event.data is what was sent

				// Do we trust the sender of this message?
				if (event.origin !== this.sparkl_origin) {
					if (this.pm_verbose == 'show_untrusted') {
						if (event.origin == window.location.origin) console.log('   PMX HOST    !-- message in host from self')
						else console.log('   PMX HOST    !-- message in host from untrusted origin: ' + event.origin, event.data)
					}
					return
				}

				// messages should all have the form {msg: 'xxx', data: ...}
				if (typeof(event.data) != 'object' || empty(event.data.msg)) {
					console.log('   PMX HOST    !-- bad message received', event.data)
					return
				}

				// Remember: we only set this event listener once, for the first-loaded SparklEmbed component; that listener then handles all events from any SparklEmbed components we open later
				// Get sparkl_embed_id from event.data. That indicates the SparklEmbed component this event is directed at. Direct the event to that component
				let sparkl_embed_id = event.data.sparkl_embed_id
				if (this.pm_verbose) console.log(sr('   PMX HOST    <-- Message from sparkl_embed_id $1', this.sparkl_embed_id))
				this.sparkl_embed_components[sparkl_embed_id].pm_execute_from_host(event)
			}, false);
			this.$store.commit('set', ['pm_event_listener_created', true])

			// show loader, then start trying to establish the connection
			// U.loading_start()
			setTimeout(x=>{ this.pm_establish_connection() }, 100)
		},

		pm_establish_connection() {
			// sparkl may not be ready to receive messages right away, so keep sending this message until it succeeds
			if (!this.connection_established) {
				if (this.establish_connection_tries > 600) {
					console.log('   PMX HOST    !-- giving up on establishing connection')
					// U.loading_stop()
					// vapp.$alert('The connection to Sparkl could not be opened.')
					return
				}
				++this.establish_connection_tries
				if (this.pm_verbose) console.log('   PMX HOST    --> queuing establish_connection message (' + this.establish_connection_tries + ')')
				this.pm_send('establish_connection')

				setTimeout(x=>{ this.pm_establish_connection() }, 50)
			}
		},

		pm_send(msg, data, resolve, reject) {
			if (this.pm_verbose) console.log('   PMX HOST    --> pm_send: ' + msg)

			// queue a message to be sent
			// second param specifies what the origin of the target window must be for the event to be dispatched
			try {
				// note that we send in sparkl_embed_id; sparkl sends it back with its messages so the host's event listener will knows which embedded iframe should deal with the message
				window['sparkl_iframe-'+this.sparkl_embed_id].postMessage({msg: msg, data: data, sparkl_embed_id:this.sparkl_embed_id}, this.sparkl_origin)
				if (resolve) resolve('ok')

			} catch(e) {
				console.log('   PMX HOST    !-- pm_send error caught: ', e)
				if (reject) reject(e)
			}
		},

		// this fn acts on the messages sent back from Sparkl
		pm_execute_from_host(event) {
			let msg = event.data.msg
			let data = event.data.data

			if (msg == 'received_establish_connection') {
				// if we already finished loading, return
				if (this.sparkl_loading_status == 'loaded') {
					if (this.pm_verbose) console.log('   PMX HOST    <-- received_establish_connection after connection already established')
					return
				}

				if (this.pm_verbose) console.log('   PMX HOST    <-- CONNECTION ESTABLISHED WITH SPARKL')
				this.sparkl_loading_status = 'loaded'
				this.connection_established = true
				// U.loading_stop()

				// if the original execute command wasn't 'show', assume that the user wants the iframe to appear first
				// (if the original command *was* show, go ahead and execute it; then we'll go on to execute whatever was in resolve)
				if (this.initialize_params.cmd != 'show') this.show_sparkl = true

				// execute the command that sent to the original execute call (wait a tick in case we just showed)
				this.$nextTick(()=>this.execute_cmd(this.initialize_params.cmd, this.initialize_params.cmd_data, this.initialize_params.resolve, this.initialize_params.reject))
				
			///////////////////////////////////////////
			// HERE ARE THE MESSAGES THAT SPARKL MAY SEND BACK TO US
			} else if (msg == 'window_size_inform') {
				if (this.pm_verbose) console.log('   PMX HOST    <-- window_size_inform: ' + JSON.stringify(data))

				// we may get sparkl_new_window_url here; save it
				if (data.sparkl_new_window_url) this.sparkl_new_window_url = data.sparkl_new_window_url

				// if the screen is maximized, we don't change the iframe height
				if (this.maximized) return

				// currently we only look at height
				let new_height = data.height

				// ??don't let the window get too small -- we need space for menus and such
				// if (this.context == 'edit' && new_height < 260) new_height = 260
				// currently we don't do this, because Sparkl keeps track of the minimum size it needs and calls window_size_inform accordingly

				// don't let the iframe get taller than the host window, minus space for the header
				let max_height = $(window).height() - 72
				if (new_height > max_height) new_height = max_height

				// we have to record and reset the window scroll position before/after we resize; otherwise the window seems to "jump down"
				let st = $(window).scrollTop()
				$(this.$el).find(sr('.k-sparkl-inline-outer[data-sparkl_embed_id=$1]', this.sparkl_embed_id)).css('height', new_height + 'px')
				$(window).scrollTop(st)

			} else if (msg == 'maximize') {
				if (this.pm_verbose) console.log('   PMX HOST    <-- maximize')

				// if force_maximize is on, don't do anything
				if (this.force_maximize) return
				
				this.maximized = true
				// when we maximize Sparkl, we have to do some things to make sure it shows up properly above everything else in the app
				this.$store.commit('set', ['activity_embed_maximized', true])
				$('html').css('overflow', 'hidden')

			} else if (msg == 'minimize') {
				if (this.pm_verbose) console.log('   PMX HOST    <-- minimize')

				// if force_maximize is on, don't do anything
				if (this.force_maximize) return

				this.maximized = false
				this.$store.commit('set', ['activity_embed_maximized', false])
				$('html').css('overflow', '')

			} else if (msg == 'sparkl_activity_saved') {
				if (this.pm_verbose) console.log('   PMX HOST    <-- sparkl_activity_saved: ' + JSON.stringify(data))
				// when the sparkl activity is saved, pass the data up to the parent component
				this.$emit('activity_saved', data)

			} else if (msg == 'sparkl_student_results_update') {
				if (this.pm_verbose) console.log('   PMX HOST    <-- student_results_update: ' + JSON.stringify(data))
				// when the sparkl activity is saved, pass the data up to the parent component
				this.$emit('student_results_update', data)

			} else if (msg == 'open_resource_link') {
				vapp.open_resource_link(data.resource_id)

			// more messages here...
			} else {
				if (this.pm_verbose) console.log('   PMX HOST    !-- received unprocessed message: ', msg, data)
			}
		},

		reload_iframe() {
			// redo some of what we do in execute when the frame hasn't yet been loaded
			this.sparkl_loading_status = 'loading'
			this.connection_established = false
			this.lti_launch()
			this.pm_initialize()
		},

		close_btn_clicked() {
			vapp.$refs.sparkl_embed_control_bar.hide()
			this.$store.commit('set', ['activity_embed_maximized', false])
			$('html').css('overflow', '')
			vapp.toggle_google_translate_btn('show')
			this.$emit('close_sparkl')
		},

		open_in_new_window() {
			// currently the only situation where we allow opening in a new window is when you've clicked to admin the activity, so always send the flag to show the sparkl activity right away
			// (see ActivityView for where the search string gets parsed)
			let url = window.location.origin + '/activity/' + this.activity.activity_id + '?admin'
			window.open(url, 'sparkl_tab-'+this.activity.activity_id)
		},
	},
}
</script>

<style lang="scss">
.k-sparkl-inline-outer {
	width:100%;
	// width:600px;
	height:220px;
	// background-color:#999;
}

.k-sparkl-inline-iframe {
	width:100%;
	height:100%;
	border:0px;
	background-color:transparent;
}

.k-sparkl-maximized {
	position:fixed;
	// z-index:10000000;
	// left:16px;
	// top:16px;
	// width:calc(100vw - 32px);
	// height:calc(100vh - 32px);
	// background-color:#fff;
	// border-radius:12px;
	// border:3px solid $v-amber-accent-4;

	// .k-sparkl-inline-iframe {
	// 	height:calc(100vh - 78px);	// this includes 40px to account for the btns below the iframe
	// 	border-radius:9px;
	// }
	z-index:4;
	left:0;
	top:56px;	// below HenryConnects banner
	width:100vw;
	height:calc(100vh - 56px);
	background-color:#fff;

	.k-sparkl-inline-iframe {
		height:calc(100vh - 56px);
	}
}

.k-sparkl-overlay-scrim {
	// display:none;
	position:fixed;
	z-index: 3;	// we previously had this at 0; but then the welcome "tabs" would show through...
	left:0;
	top:0;
	width:100vw;
	height:100vh;
	background-color:#fff;
}

.k-sparkl-close-btn {
	position:fixed;
	z-index:10000001;
	right:2px;
	top:2px;
	background-color:#fff;
	border-radius:50px;
}
</style>