<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<div class="k-lpurc-tree-top">
		<v-text-field light rounded :background-color="(search_results.length==0)?'#eee':'yellow lighten-4'" solo hide-details clearable dense
			placeholder="Search"
			v-model="search_terms"
			prepend-inner-icon="fas fa-search" @click:prepend-inner="execute_search_start"
			@click:clear="execute_search_clear"
			@keyup="search_field_keyup"
			autocomplete="new-password"
		></v-text-field>
		<div v-show="open_items.length>1" class="text-right mx-2 mb-1" style="margin-bottom:-5px"><v-btn @click="collapse_all" x-small dark color="#999"><b>Collapse all</b></v-btn></div>
		<v-tooltip bottom><template v-slot:activator="{on}"><img v-if="show_safari" v-on="on" src="./../../images/safari-search-logo.png" class="ml-2 px-2" style="height:38px;cursor:pointer" @click="safari_search"></template>Search SAFARI Montage<br>for additional resources</v-tooltip>
	</div>
	<div class="k-lpurc-tree-main">
		<v-treeview open-on-click dense
			:open="open_items"
			:items="resource_tree"
			item-key="resource_id"
			item-children="children"
			@update:open="open_updated"
		>
			<template v-slot:label="{ item }">
				<div v-if="item.type=='collection_node'" class="k-lpurc-tree_case_item" :class="search_css(item.resource_id)">
					<span @click.stop=""><v-checkbox v-if="enable_collection_editing && item.is_collection_resource!='never'" class="shrink mt-0 pt-0 d-inline-block" hide-details :indeterminate="item.node_included_implicitly" v-model="item.is_collection_resource" @change="collection_folder_checkbox_clicked(item)" hide-details></v-checkbox></span>
					<span style="cursor:pointer" v-html="highlight_search_terms(item.description)"></span>
				</div>
				<div v-if="item.type=='collection_node_resources'" class="k-resources-list">
					<div v-if="item.resource_id=='OTHER_ASSESSMENTS-resources'" style="width:100%" class="text-left ml-2 mb-2 mt-1"><i><b>Note:</b> Sign in to Illuminate prior to clicking these links.</i></div>

					<div v-for="(resource) in item.resources" v-show="node_search_match(resource.resource_id)!='no'" :class="(resource.quick_look_showing||resource.editing)?'k-lpurc-resource-wrapper-full':'k-lpurc-resource-wrapper-normal'">
						<ResourceCollectionItem v-if="false" v-show="!resource.editing" class="ma-1" :key="resource.resource_id"
							:item="resource"
							:enable_hc_assignment="enable_hc_assignment"
							:enable_edit_link="enable_edit_link"
							:enable_remove_link="enable_remove_link"
							:search_match="node_search_match(resource.resource_id)"
							:mark_district_unsanctioned="false"
							:enable_report_issue="true"
							@report_issue="report_issue"
							@assign_in_hc="$emit('assign_resource', $event)"
							@edit_resource="edit_resource_start(resource)"
							@remove_resource="remove_resource"
						/>

						<ResourceLink v-if="true" v-show="!resource.editing" class="ma-1" :key="resource.resource_id"
							:resource="resource"
							:enable_hc_assignment="enable_hc_assignment"
							:enable_edit_link="enable_edit_link"
							:enable_remove_link="enable_remove_link"
							:search_match="node_search_match(resource.resource_id)"
							:mark_district_unsanctioned="false"
							:enable_report_issue="true"
							@report_issue="report_issue"
							@assign_in_hc="$emit('assign_resource', $event)"
							@edit_resource="edit_resource_start(resource)"
							@remove_resource="remove_resource"
						/>
						<EditResource v-if="resource.editing" :show_shareable_control="false" :resource="resource" :district_sanctioned="true" :show_lp_category_chooser="true" :show_family_control="true" @edit_resource_cancel="edit_resource_cancel(resource)" @edit_resource_save="edit_resource_save(resource, ...arguments)" />
					</div>
				</div>
				<div v-if="item.type!='collection_node'&&item.type!='collection_node_resources'">
					<ResourceLink v-show="!resource.editing" class="ma-1" :key="item.resource_id"
						:resource="item"
						:enable_hc_assignment="enable_hc_assignment"
						:enable_edit_link="enable_edit_link"
						:enable_remove_link="enable_remove_link"
						:search_match="node_search_match(item.resource_id)"
						:mark_district_unsanctioned="false"
						:enable_report_issue="true"
						@report_issue="report_issue"
						@assign_in_hc="$emit('assign_resource', $event)"
						@edit_resource="edit_resource_start(item)"
						@remove_resource="remove_resource"
					/>
					<EditResource v-if="item.editing" :show_shareable_control="false" :resource="item" :district_sanctioned="true" :show_lp_category_chooser="true" :show_family_control="true" @edit_resource_cancel="edit_resource_cancel(item)" @edit_resource_save="edit_resource_save(item, ...arguments)" />
				</div>
			</template>
		</v-treeview>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CASETreeSearchHelp from './CASETreeSearchHelp'
import ResourceLink from './ResourceLink'
import EditResource from '../resources/EditResource'
import ResourceCollectionItem from '../resources/ResourceCollectionItem'
import '../../js/search_fns.js'

export default {
	components: { CASETreeSearchHelp, EditResource, ResourceLink, ResourceCollectionItem },
	props: {
		lp: { type: Object, required: true },
		unit: { type: Object, required: true },
		term_mode: { type: String, required: true },
		enable_hc_assignment: { type: Boolean, required: false, default() { return false }},
		enable_edit_link: { type: Boolean, required: false, default() { return false }},
		enable_remove_link: { type: Boolean, required: false, default() { return false }},
		enable_collection_editing: { type: Boolean, required: false, default() { return false }},
	},
	data() { return {
		active_items: [],
		open_items: [],
		search_terms: '',
		search_term_res: [],
		stop_words: [],
		search_results: [],
		collection_tree_keys: [],
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters(['role', 'system_role', 'show_safari', 'beta_options']),
		user_can_view_teacher_resources() {
			return this.role == 'staff' || this.role == 'admin'
		},
		user_can_view_leader_resources() {
			if (this.role == 'admin') return true
			if (this.$store.getters.user_is_principal_or_ap) return true
			return false
		},
		course_in_my_courses() {
			// some things should only be shown to family members/students if the course is included in my_courses
			return (this.$store.getters.my_courses.findIndex(x=>x.course_code == this.lp.course_code) > -1)
		},
		user_can_view_student_resources() {
			// publisher-supplied resources are only available to non-teachers (i.e. family members or students) if this course is included in my_courses (i.e. if it's one of the courses a family member's kid is enrolled in); but also show if it's a staff/admin viewing as a student/parent

			// if the user is staff or higher, you can view the ebook resources
			if (this.user_can_view_teacher_resources) return true
			// for parents or students, if this is one of their courses, you can view ebook resources
			if (this.course_in_my_courses) return true
			// if user's system role is staff or admin, you're probably viewing as a teacher/student; allow to view ebook resources
			if (this.system_role == 'staff' || this.system_role == 'admin') return true

			return false
		},
		resource_tree() {
			let enable_collection_editing = this.enable_collection_editing

			// this fn is for traversing a resource collection generated from a common cartridge (i.e. HMH resources in HenryConnects)
			function traverse(collection_id, node, collection_inclusions, parent_included, role) {
				// we assume here that each node is *either* a resource (where the resource_id is specified by 'r') or a "folder" (with children in 'c')
				if (!empty(node.r)) {
					// NOTE: the code below is also mostly reproduced in ResourceCollectionTree.vue
					// teacher_facing is normally based on the "node.i" value...
					let teacher_facing = (node.i == 1)

					// ...but to fix some HMH bugs, make sure that anything that has certain strings in the title is teacher facing
					if (node.t.search(/(assessment)|(teacher ebook)|(teacher edition)|(answer key)/i) > -1) teacher_facing = true
					// ...and that resources with "student ebook" in the title are student facing
					if (node.t.search(/student ebook/i) > -1) teacher_facing = false

					// start by setting include_resource to parent_included
					let include_resource = parent_included

					// if enable_collection_editing is on (this would only be true for someone with edit rights, who can see anything), we always include the resource
					if (enable_collection_editing) include_resource = true

					// hide teacher resources from parents and students
					if (role == 'parent' || role == 'student') {
						include_resource = !teacher_facing
					}

					if (include_resource) {
						return new Resource({
							resource_id: node.r,
							type: 'collection_item',
							teacher_facing: teacher_facing,
							district_sanctioned: true,
							description: node.t,
						})
					}

				} else if (!empty(node.c)) {
					let node_included_explicitly = empty(collection_inclusions) || !empty(collection_inclusions.find(x=>x==node.f))	// node.f = identifier, from CC file
					let node_included_implicitly = !node_included_explicitly && parent_included
					let nn = {
						resource_id: node.f,	// call this resource_id because we user resource_id's as tree "key"s
						type: 'collection_node',
						collection_id: collection_id,
						is_collection_resource: node_included_explicitly,
						node_included_implicitly: node_included_implicitly,
						description: node.t,
						searchable_description: node.t,
						included_resource_count: 0,	// only counts items included via collection_inclusions
						total_resource_count: 0,	// includes items that are (or will be) excluded via collection_inclusions
						children: [],
						resources: [],
					}

					for (let child of node.c) {
						// traverse child; parent_included is true if nn is either explicitly or implicitly included
						child = traverse(collection_id, child, collection_inclusions, (node_included_explicitly || nn.node_included_implicitly), role)
						if (!empty(child)) {
							if (child.type == 'collection_item') {
								nn.resources.push(child)
								nn.total_resource_count += 1
								if (parent_included || node_included_explicitly || nn.node_included_implicitly) {
									nn.included_resource_count += 1
								}
							} else {
								// child is collection_node: only push if it has children, or if enable_collection_editing is on
								if (child.included_resource_count > 0 || enable_collection_editing) {
									nn.children.push(child)
									if (child.is_collection_resource || child.node_included_implicitly) {
										nn.included_resource_count += child.included_resource_count
									}
									nn.total_resource_count += child.total_resource_count
								}
							}
						}
					}

					// if this node's included resource count is > 0, it is at least implicitly included for the purposes of its parents
					// (note, however, that when traversing children above -- where included_resource_count was calculated -- it may not have been counted as implicitly included)
					if (nn.included_resource_count > 0) {
						if (!node_included_explicitly && !nn.node_included_implicitly) {
							nn.node_included_implicitly = true
						}
					}

					if (nn.resources.length > 0) {
						nn.children.push({
							resource_id: node.i + '-resources',
							description: 'RESOURCES',
							type: 'collection_node_resources',
							children: [],
							resources: nn.resources
						})
					}

					// return the node if collection_inclusions is empty or if it has children
					// (if the node itself is supposed to be included, it should have children, which will all be included
					// via the recursive traverse call above; if it doesn't have children, don't include it (this shouldn't happen))
					if (enable_collection_editing || empty(collection_inclusions) || nn.included_resource_count > 0) {
						// consolidate nodes that have only one child node...
						if (nn.resources.length == 0 && nn.children.length == 1 && nn.children[0].consolidated != true) {
							let child_title = nn.children[0].description.replace(/(.*) (\(.+\))$/, '<span class="k-cv-unit-rc-subhead">$1</span> $2')
							nn.description += ' ' + child_title
							nn.children = nn.children[0].children
							nn.consolidated = true
						} else {
							// else add resource count(s) to description
							if (enable_collection_editing) {
								nn.description += sr(' ($1 / $2)', nn.included_resource_count, nn.total_resource_count)
							} else {
								nn.description += sr(' ($1)', nn.included_resource_count)
							}
						}

						return nn
					}
				}

				// if we get to here return null
				return null
			}

			let all_resources_for_unit = this.unit.resources.concat([])

			// divide explicitly-called-out resources (i.e. those not from collections) into buckets...
			// start with course_guidance and course_ebook resources, which can come from any unit
			// also pull out here resources from other units that aren't in the course_guidance or course_ebook category, but that belong in all units
			let course_guidance_resources = []
			let course_ebooks = []
			for (let u of this.lp.units) {
				// if we're editing this unit, we need to search/pull from the copy of the unit that's being edited
				if (u.lp_unit_id == this.unit.lp_unit_id) u = this.unit
				for (let r of u.resources) {
					// hide/show items marked as block or traditional; note that 'traditional' resource == 'normal' term_mode; but don't hide when editing
					if (!this.enable_collection_editing) {
						if (r.block_or_traditional == 'block' && this.term_mode != 'block') continue
						if (r.block_or_traditional == 'traditional' && this.term_mode != 'normal') continue
					}

					if (r.lp_category == 'course_guidance') {
						// if user isn't allowed to see teacher resources, only include if the family_avail flag is true
						if (this.user_can_view_teacher_resources || r.family_avail) {
							course_guidance_resources.push(r)
						}

					} else if (r.lp_category == 'course_ebook') {
						// only let teachers see teacher-facing ebooks
						if (this.user_can_view_teacher_resources || !r.teacher_facing) {
							// only show student-facing ebooks to non-teachers if user_can_view_student_resources
							if (this.user_can_view_teacher_resources || this.user_can_view_student_resources) {
								course_ebooks.push(r)
							}
						}

					} else if (r.cross_unit && u.lp_unit_id != this.unit.lp_unit_id) {
						// now add to all_resources_for_unit resources from other units that are marked cross_unit.
						// we don't include resources from this unit here because we're already added them to all_resources_for_unit in the concat() above
						all_resources_for_unit.push(r)
					}
				}
			}

			let unit_planning_resources = []
			let leader_resources = []
			let other_teacher_resources = []
			let other_student_resources = []
			let other_student_resources_ese = []
			let other_student_resources_adv = []
			let other_resources_stem = []
			let other_assessments = []
			for (let r of all_resources_for_unit) {
				// skip items explicitly marked as course_guidance and course_ebook resources; we dealt with them above
				if (r.lp_category == 'course_guidance' || r.lp_category == 'course_ebook') continue

				// if user isn't allowed to see teacher resources, only include if the family_avail flag is true
				if (!this.user_can_view_teacher_resources && !r.family_avail) {
					continue
				}

				// hide/show items marked as block or traditional; note that 'traditional' resource == 'normal' term_mode; but don't hide when editing
				if (!this.enable_collection_editing) {
					if (r.block_or_traditional == 'block' && this.term_mode != 'block') continue
					if (r.block_or_traditional == 'traditional' && this.term_mode != 'normal') continue
				}

				// learning targets in the description => put in course_guidance, at least for now
				if (r.description.search(/learning targets/i) > -1) course_guidance_resources.push(r)
				else if (r.lp_category == 'unit_planning' || r.description.search(/unit planning/i) > -1) unit_planning_resources.push(r)
				else if (r.lp_category == 'leader_resource') leader_resources.push(r)
				else if (r.type == 'assessment') other_assessments.push(r)
				else if (r.teacher_facing) other_teacher_resources.push(r)
				else if (r.lp_category == 'stem_resource') other_resources_stem.push(r)
				else if (r.target_students == 'ese') other_student_resources_ese.push(r)
				else if (r.target_students == 'adv') other_student_resources_adv.push(r)
				else other_student_resources.push(r)
			}

			// start the tree with the leader_resources (if visible to this user), course_guidance and unit_planning_resources
			let tree = []
			if (leader_resources.length > 0 && this.user_can_view_leader_resources) tree.push({
				type: 'collection_node',
				is_collection_resource: 'never',
				resource_id: 'LEADER_RESOURCES',
				description: sr('<i class="fas fa-th-list mr-1"></i> Leader Resources ($1)', leader_resources.length),
				children: [{resource_id: 'LEADER-RESOURCES-resources', description: 'RESOURCES', type: 'collection_node_resources', resources: leader_resources.sort(U.resources_sort)}]
			})
			if (course_guidance_resources.length > 0) tree.push({
				type: 'collection_node',
				is_collection_resource: 'never',
				resource_id: 'COURSE_GUIDANCE_RESOURCES',
				description: sr('<i class="fas fa-th-list mr-1"></i> Resources for Course Guidance ($1)', course_guidance_resources.length),
				children: [{resource_id: 'COURSE_GUIDANCE_RESOURCES-resources', description: 'RESOURCES', type: 'collection_node_resources', resources: course_guidance_resources.sort(U.resources_sort)}]
			})
			if (unit_planning_resources.length > 0) tree.push({
				type: 'collection_node',
				is_collection_resource: 'never',
				resource_id: 'UNIT_PLANNING_DOCS',
				description: sr('<i class="fas fa-th-list mr-1"></i> Unit Planning Documents ($1)', unit_planning_resources.length),
				children: [{resource_id: 'UNIT_PLANNING_DOCS-resources', description: 'RESOURCES', type: 'collection_node_resources', resources: unit_planning_resources.sort(U.resources_sort)}]
			})

			// now for each collection in the lp,
			for (let rc of this.lp.resource_collections) {
				// if the user can't view student or teacher resources, skip
				if (!this.user_can_view_teacher_resources && !this.user_can_view_student_resources) {
					continue
				}

				// traverse to get a filtered version of these resources, pulling in items for this unit from unit.resource_collection_inclusions

				// get resource_collection_inclusions; if we're editing, then send an empty array if it doesn't exist (this makes it so that it starts with no "folders" selected)
				let collection_inclusions = this.unit.resource_collection_inclusions[rc.resource_id]
				if (this.enable_collection_editing && empty(collection_inclusions)) collection_inclusions = []

				let rc_tree = traverse(rc.resource_id, rc.collection_json, collection_inclusions, false, this.role)
				if (!empty(rc_tree) && rc_tree.total_resource_count > 0) {
					// the collection's tree is a node in our overall tree, with title == the collection title and resource_id (tree key) == the collection's resource_id
					rc_tree.description = '<i class="fas fa-th-list mr-1"></i> ' + rc.description
					rc_tree.resource_id = rc.resource_id
					if (this.enable_collection_editing) {
						rc_tree.description += sr(' ($1 / $2)', rc_tree.included_resource_count, rc_tree.total_resource_count)
					} else {
						rc_tree.description += sr(' ($1)', rc_tree.included_resource_count)
					}
					tree.push(rc_tree)

					// force the resource collection tree(s) start open
					this.$nextTick(()=>this.open_items.push(rc_tree.resource_id))
					this.collection_tree_keys.push(rc_tree.resource_id)
				}
			}

			// now course_ebook's
			if (course_ebooks.length > 0) {
				tree.push({
					type: 'collection_node',
					is_collection_resource: 'never',
					resource_id: 'COURSE_EBOOK_RESOURCES',
					description: sr('<i class="fas fa-th-list mr-1"></i> Course Ebooks ($1)', course_ebooks.length),
					children: [{resource_id: 'COURSE_EBOOK_RESOURCES-resources', description: 'RESOURCES', type: 'collection_node_resources', resources: course_ebooks.sort(U.resources_sort)}]
				})
				// force the resource collection tree(s) start open
				this.$nextTick(()=>this.open_items.push('COURSE_EBOOK_RESOURCES'))
			}

			// now other resources
			if (other_teacher_resources.length > 0) tree.push({
				type: 'collection_node',
				is_collection_resource: 'never',
				resource_id: 'OTHER_TEACHER_RESOURCES',
				description: sr('<i class="fas fa-th-list mr-1"></i> Additional Teacher Resources from Unit Planning Guides ($1)', other_teacher_resources.length),
				children: [{resource_id: 'OTHER_TEACHER_RESOURCES-resources', description: 'RESOURCES', type: 'collection_node_resources', resources: other_teacher_resources.sort(U.resources_sort)}]
			})
			if (other_assessments.length > 0 && this.user_can_view_teacher_resources) tree.push({
				type: 'collection_node',
				is_collection_resource: 'never',
				resource_id: 'OTHER_ASSESSMENTS',
				description: sr('<i class="fas fa-th-list mr-1"></i> Assessments ($1)', other_assessments.length),
				children: [{resource_id: 'OTHER_ASSESSMENTS-resources', description: 'RESOURCES', type: 'collection_node_resources', resources: other_assessments.sort(U.resources_sort)}]
			})
			if (other_student_resources.length > 0) tree.push({
				type: 'collection_node',
				is_collection_resource: 'never',
				resource_id: 'OTHER_STUDENT_RESOURCES',
				description: sr('<i class="fas fa-th-list mr-1"></i> Additional Student Resources from Unit Planning Guides ($1)', other_student_resources.length),
				children: [{resource_id: 'OTHER_STUDENT_RESOURCES-resources', description: 'RESOURCES', type: 'collection_node_resources', resources: other_student_resources.sort(U.resources_sort)}]
			})
			if (other_student_resources_ese.length > 0) tree.push({
				type: 'collection_node',
				is_collection_resource: 'never',
				resource_id: 'OTHER_STUDENT_RESOURCES_ESE',
				description: sr('<i class="fas fa-th-list mr-1"></i> Additional Student ESE Resources ($1)', other_student_resources_ese.length),
				children: [{resource_id: 'OTHER_STUDENT_RESOURCES_ESE-resources', description: 'RESOURCES', type: 'collection_node_resources', resources: other_student_resources_ese.sort(U.resources_sort)}]
			})
			if (other_resources_stem.length > 0) tree.push({
				type: 'collection_node',
				is_collection_resource: 'never',
				resource_id: 'OTHER_RESOURCES_STEM',
				description: sr('<i class="fas fa-th-list mr-1"></i> STEM Resources ($1)', other_resources_stem.length),
				children: [{resource_id: 'OTHER_RESOURCES_STEM-resources', description: 'RESOURCES', type: 'collection_node_resources', resources: other_resources_stem.sort(U.resources_sort)}]
			})
			if (other_student_resources_adv.length > 0) tree.push({
				type: 'collection_node',
				is_collection_resource: 'never',
				resource_id: 'OTHER_STUDENT_RESOURCES_ADV',
				description: sr('<i class="fas fa-th-list mr-1"></i> Additional Student Advanced Learning Resources ($1)', other_student_resources_adv.length),
				children: [{resource_id: 'OTHER_STUDENT_RESOURCES_ADV-resources', description: 'RESOURCES', type: 'collection_node_resources', resources: other_student_resources_adv.sort(U.resources_sort)}]
			})

			// console.log('resource_tree: ', tree)

			// if we have at least one item in tree, declare to the parent that we have resources to show
			if (tree.length > 0) {
				this.$emit('declare_resource_presence')
			}

			return tree
		},
	},
	created() {
	},
	mounted() {
	},
	watch: {
	},
	methods: {
		// send issue report requests to vapp
		report_issue(issue_params) {
			// issue_params comes in with resource specified; add lp and unit
			issue_params.learning_progression = this.lp
			issue_params.lp_unit = this.unit
			vapp.report_issue(issue_params)
		},

		collection_folder_checkbox_clicked(node) {
			if (node.is_collection_resource) {
				if (empty(this.unit.resource_collection_inclusions[node.collection_id])) {
					this.$store.commit('set', [this.unit.resource_collection_inclusions, node.collection_id, []])
				}
				this.$store.commit('set', [this.unit.resource_collection_inclusions[node.collection_id], 'PUSH', node.resource_id])

			} else {
				this.$store.commit('splice_from_array', [this.unit.resource_collection_inclusions[node.collection_id], node.resource_id])
			}
		},

		open_updated(arr) {
			this.open_items = arr
		},

		execute_search(node) {
			if (empty(node)) return false

			// by default return false (item doesn't meet criteria)
			let rv = false

			// if the node has children, search the children
			if (!empty(node.children) && node.children.length > 0) {
				for (let child of node.children) {
					if (this.execute_search(child)) {
						if (!this.open_items.find(x=>x==node.resource_id)) this.open_items.push(node.resource_id)
						rv = true
					}
				}
			}

			// if the node has resources, search the resources
			if (!empty(node.resources) && node.resources.length > 0) {
				for (let child of node.resources) {
					if (this.execute_search(child)) {
						if (!this.open_items.find(x=>x==node.resource_id)) this.open_items.push(node.resource_id)
						rv = true
					}
				}
			}

			// assuming the node has a description (title), determine if it should be highlighted as a search result
			let description = node.description
			// use searchable_description if we have one
			if (!empty(node.searchable_description)) description = node.searchable_description
			if (!empty(description)) {
				// if the description includes a stop word, no
				if (!U.string_includes_stop_word(this.stop_words, description)) {
					// check description and url (if there); could also check long_description at some point
					let arr = [description]
					if (!empty(node.url)) {
						arr.push(node.url)
					}

					if (U.strings_match_search_term_res(this.search_term_res, arr)) {
						this.search_results.push(node.resource_id)
						if (!this.open_items.find(x=>x==node.resource_id)) this.open_items.push(node.resource_id)

						rv = true
					}
				}
			}

			return rv
		},

		node_search_match(resource_id) {
			if (this.search_results.length == 0) return '-'
			if (this.search_results.findIndex(x=>x==resource_id) > -1) return 'yes'
			return 'no'
		},

		execute_search_start() {
			// establish the debounce fn if necessary
			if (empty(this.fn_debounced)) {
				this.fn_debounced = U.debounce(() => {
					this.collapse_all()
					this.execute_search_clear()

					let arr = U.create_search_re(this.search_terms)
					this.search_term_res = arr[0]
					this.stop_words = arr[1]

					for (let node of this.resource_tree) {
						this.execute_search(node)
					}
					if (this.search_results.length == 0) {
						this.$inform({text:'No items matched your search terms.', color:'pink darken-4'})
					}
				}, 1000)
			}

			// call the debounce fn
			this.fn_debounced()
		},

		clear_search_matches(node) {
			// if the node has children, search the children
			if (!empty(node.children) && node.children.length > 0) {
				for (let child of node.children) {
					this.clear_search_matches(child)
				}
			}
			if (!empty(node.resources) && node.resources.length > 0) {
				for (let res of node.resources) {
					if (res.search_match == 'yes') {
						console.log('clear resource...')
						this.$store.commit('set', [res, 'search_match', false])
					}
				}
			}
		},

		execute_search_clear() {
			// clear search_match from all resources
			for (let node of this.resource_tree) {
				this.clear_search_matches(node)
			}

			// this.collapse_all()
			this.search_results = []
		},

		search_field_keyup(evt) {
			this.execute_search_start()
		},

		search_css(tree_key) {
			if (tree_key == 'LEADER_RESOURCES') return 'k-lpurc-special-node k-lpurc-leader-resources-doc-node'
			if (tree_key == 'COURSE_GUIDANCE_RESOURCES') return 'k-lpurc-special-node k-lpurc-course-guidance-doc-node'
			if (tree_key == 'UNIT_PLANNING_DOCS') return 'k-lpurc-special-node k-lpurc-unit-planning-doc-node'
			if (tree_key == 'COURSE_EBOOK_RESOURCES') return 'k-lpurc-special-node k-lpurc-rc-collection-node'	// same as resource collection
			if (tree_key == 'OTHER_TEACHER_RESOURCES') return 'k-lpurc-special-node k-lpurc-other-teacher-node'
			if (tree_key == 'OTHER_ASSESSMENTS') return 'k-lpurc-special-node k-lpurc-other-assessments-node'
			if (tree_key == 'OTHER_STUDENT_RESOURCES') return 'k-lpurc-special-node k-lpurc-other-student-node'
			if (tree_key == 'OTHER_STUDENT_RESOURCES_ESE') return 'k-lpurc-special-node k-lpurc-other-student-node-ese'
			if (tree_key == 'OTHER_STUDENT_RESOURCES_ADV') return 'k-lpurc-special-node k-lpurc-other-student-node-adv'
			if (tree_key == 'OTHER_RESOURCES_STEM') return 'k-lpurc-special-node k-lpurc-stem-resource-node'
			for (let rc of this.lp.resource_collections) {
				if (tree_key == rc.resource_id) return 'k-lpurc-special-node k-lpurc-rc-collection-node'
			}
			if (this.node_search_match(tree_key) == 'yes') return 'k-rc-tree-search-match'
			return ''
		},

		highlight_search_terms(s) {
			return s

			// highlight search terms if we have them
			if (!empty(this.search_term_res)) {
				for (let res of this.search_term_res) {
					for (let re of res) {
						s = s.replace(re, '<span class="k-rc-tree-searched-term">$1</span>')
					}
				}
			}
			return s
		},

		collapse_all() {
			this.open_items = []
			this.active_items = []
		},

		edit_resource_start(resource) {
			let original_resource = this.unit.resources.find(o=>o.resource_id == resource.resource_id)
			// if the resource isn't in this unit, it's probably a cross-unit resource; try to find where it is and alert the user
			if (!original_resource) {
				let original_unit
				for (let u of this.lp.units) {
					for (let r of u.resources) {
						if (r.resource_id == resource.resource_id) {
							original_unit = u
							break
						}
					}
					if (original_unit) break
				}
				if (original_unit) {
					this.$alert(sr('You must edit this cross-unit resource from the unit where it was originally added: <b>$1</b>', original_unit.title))
				} else {
					this.$alert('This resource cannot be edited.')
				}
				return
			}

			this.$set(resource, 'editing', true)
		},

		edit_resource_cancel(resource) {
			resource.editing = false
		},

		edit_resource_save(resource, edited_resource) {
			// we have to do this in the parent (LearningProgressionUnitEdit), where it has access to the original unit; here we're working with a copy
			this.$emit('edit_resource_save', resource, edited_resource)
			resource.editing = false
		},

		// "pass through emitters"
		remove_resource(resource) {
			// if the resource isn't in this unit, it's probably a cross-unit resource; try to find where it is and alert the user
			let original_resource = this.unit.resources.find(o=>o.resource_id == resource.resource_id)
			if (!original_resource) {
				let original_unit
				for (let u of this.lp.units) {
					for (let r of u.resources) {
						if (r.resource_id == resource.resource_id) {
							original_unit = u
							break
						}
					}
					if (original_unit) break
				}
				if (original_unit) {
					this.$alert(sr('You must remove this cross-unit resource from the unit where it was originally added: <b>$1</b>', original_unit.title))
				} else {
					this.$alert('This resource cannot be removed.')
				}
				return
			}

			this.$emit('remove_resource', resource)
		},

		assign_resource(standard, resource) {
			// console.log('LPResourceCollection: assign_resource', resource)
			this.$emit('assign_resource', standard, resource)
		},

		safari_search() {
			// for safari send grade levels, as well as search string
			let o = {
				tool: 'safari',
				endpoint: this.$store.state.safari_lti_endpoint,
				search_params: {}
			}
			if (!empty(this.lp.grade_low)) o.search_params.fromgrade = this.lp.grade_low
			if (!empty(this.lp.grade_high)) o.search_params.tograde = this.lp.grade_high
			if (!empty(this.search_terms)) o.search_params.keywords = this.search_terms

			// TODO: send top-level standard for the course/framework?
			// o.search_params.standardsparentguid = '4fc73034-416e-11e7-8d5e-3765d5cb8bb6'

			this.$store.dispatch('lti_launch', o)
		},
	}
}
</script>

<style lang="scss">
.k-lpurc-tree-top {
	margin:16px 16px 16px 16px;
	display:flex;
	align-items:center;
}

.k-lpurc-tree-main {
	.v-treeview-node__label {
		margin-left:-28px;

		.k-lpurc-tree_case_item {
			margin-left:28px;
		}
	}

	// this makes it so nodes don't take up space when all of their items are hidden because they don't match search terms
	.v-treeview-node__root {
		min-height:0!important;
		padding-top:2px;
		padding-bottom:2px;
	}

	.k-resources-list {
		margin-bottom:7px;
	}

	.v-treeview-node__root:hover::before {
		// display:none!important;
		opacity:0!important;
		// this kills the light grey background when you hover over tree items
	}

	.k-lpurc-special-node {
		font-weight:900;
		padding:4px 8px;
		border-radius:8px;
	}

	.k-lpurc-course-guidance-doc-node {
		background-color: $v-cyan-lighten-3;
		color:$v-cyan-darken-4;
	}

	.k-lpurc-leader-resources-doc-node {
		background-color: $v-brown-lighten-4;
		color:$v-brown-darken-4;
	}

	.k-lpurc-unit-planning-doc-node {
		background-color: $v-green-lighten-4;
		color:$v-green-darken-3;
	}

	.k-lpurc-other-teacher-node {
		background-color: $v-light-blue-lighten-4;
		color:$v-light-blue-darken-3;
	}

	.k-lpurc-other-assessments-node {
		background-color: $v-pink-accent-4;
		color:#fff
	}

	.k-lpurc-other-student-node {
		background-color: $v-amber-lighten-3;
		color:$v-brown;
	}

	.k-lpurc-other-student-node-adv {
		background-color: $v-purple-lighten-3;
		color:$v-purple-darken-3;
	}

	.k-lpurc-other-student-node-ese {
		background-color: $v-teal-lighten-3;
		color:$v-teal-darken-3;
	}

	.k-lpurc-stem-resource-node {
		background-color: $v-lime-lighten-4;
		color:$v-lime-darken-3;
	}
}

.k-lpurc-resource-wrapper-full {
	flex:1 1 100%;
}

.k-lpurc-resource-wrapper-normal {
	// flex-basis:33.333%;
	flex-basis:50%;
	flex-shrink: 0;
	flex-grow: 1;
	min-width:0;
	overflow:hidden;
	// flex:0 0 30%;
	// max-width:250px;
}

.k-rc-tree-search-match {
	// background-color: #afa;
	background-color: $v-yellow-lighten-3;
	padding-left:4px;
	border-radius:4px;
	font-weight:bold;
	text-decoration:underline;
}

.k-rc-tree-searched-term {
	// font-weight:bold;
	// text-decoration:underline;
	// color:#000;
	// background-color: $v-yellow-lighten-3;
}

</style>
