<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="d-flex justify-center align-stretch k-beta-2022-04">
	<MiniNav v-show="collection_showing" current_section="launchers" />
	<div class="k-main-collection" :class="collection_showing?'k-main-collection--item-showing k-main-collection--course-showing':''">
		<div v-if="!collection_showing" class="d-flex align-center mb-4">
			<h2 class="k-main-collection-header k-page-title d-flex"><v-icon large color="primary" class="mr-4">fas fa-circle-nodes</v-icon><b>{{connections_showing=='staff'?'Staff':'Family'}} Connections</b></h2>
			<v-spacer/>
			<div v-if="role=='staff'||role=='admin'" class="d-flex flex-wrap justify-center">
				<div style="font-size:14px;" class="ma-1">Show connections for:</div>
				<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-1"><v-icon small class="mr-2">fas fa-user</v-icon>{{(connections_showing=='staff')?'Staff':'Families'}}</v-btn></template>
					<v-list>
						<v-list-item @click="connections_showing='staff'"><v-list-item-title><v-icon v-visible="connections_showing=='staff'" small class="mr-2">fas fa-circle</v-icon>Staff</v-list-item-title></v-list-item>
						<v-list-item @click="connections_showing='parent'"><v-list-item-title><v-icon v-visible="connections_showing=='parent'" small class="mr-2">fas fa-circle</v-icon>Families</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</div>
		</div>

		<div v-show="initialized">
			<!-- STAFF CONNECTIONS -->
			<div v-if="connections_showing=='staff'">
				<!-- We only use a single collection for staff, so show the collection here -->
				<div v-show="!show_sc_collection_editor">
					<div v-if="sc_collections.length>0" class="k-staff-collections-wrapper elevation-3">
						<v-text-field light rounded :background-color="(search_results.length==0)?'#fff':'yellow lighten-4'" class="mb-4 mt-2 mx-auto" style="max-width:480px" solo hide-details clearable dense
							placeholder="Search"
							v-model="search_terms"
							prepend-inner-icon="fas fa-search" @click:prepend-inner="execute_search_start"
							@click:clear="execute_search_clear"
							@keyup="search_field_keyup"
							autocomplete="new-password"
						></v-text-field>

						<div v-for="(resource_collection, i) in sc_collections" :key="resource_collection.uuid" :class="'k-staff-sub-collection-'+i" v-show="search_results.length==0||resource_collection.items.length>0">
							<ResourceCollectionView
								:collection="resource_collection"
								:enable_gc_link="false"
								:enable_hc_assignment="false"
								:enable_resource_edit_link="false"
								:enable_resource_remove_link="false"
								:enable_collection_edit_link="is_pg_admin"
								@edit_resource_collection="edit_sc_collection(resource_collection)"
							/>
							<CollectionUnitEdit v-if="sc_unit_being_edited" :collection="sclp" :original_unit="sc_unit_being_edited" @editor_cancel="sc_unit_being_edited=null" />
						</div>
					</div>
					<div class="text-center mt-4" v-if="!small_screen"><v-btn color="secondary" @click="show_sc_collection_editor=true"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit Collection</v-btn></div>
				</div>

				<CollectionEdit v-if="show_sc_collection_editor" :learning_progression="sclp" @editor_cancel="show_sc_collection_editor=false"></CollectionEdit>
			</div>

			<!-- FAMILY CONNECTIONS -->
			<div v-if="connections_showing=='parent'">
				<div v-if="collection_showing" class="k-mini-nav-site-section-heading">Family Connections</div>
				<div v-if="!new_lp">
					<div v-if="pglps.length==0">There are currently no family connections available.</div>

					<!-- Featured collections -->
					<!-- <div v-if="has_featured_collection"><div class="k-main-collection-sub-collection k-main-collection-sub-collection-featured elevation-3">
						<div class="k-main-collection-body">
							<div v-for="(lp) in pglps" v-if="lp.featured>0" @click="go_to_lp(lp)" class="k-elevated k-main-collection-item k-lp-tile" :class="lp_css(lp)" v-html="lp_title(lp)"></div>
						</div>
					</div></div> -->

					<div class="k-main-collection-sub-collection elevation-3">
						<div class="k-main-collection-body">
							<div v-for="(lp) in pglps" v-if="!collection_showing||lp.featured>0" @click="go_to_lp(lp)" class="k-elevated k-main-collection-item k-lp-tile" :class="lp_css(lp)" v-html="lp_title(lp)"></div>
						</div>
					</div>

				</div>

				<div v-if="!new_lp&&is_pg_admin&&!collection_showing&&!small_screen" class="mt-4">
					<v-btn @click="create_lp">Create a New Family Connections Collection</v-btn>
				</div>

				<CollectionEdit v-if="new_lp" :learning_progression="new_lp" @editor_cancel="new_lp=null"></CollectionEdit>
			</div>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ResourceCollectionView from '../resources/ResourceCollectionView'
import CollectionEdit from './CollectionEdit'
import CollectionUnitEdit from './CollectionUnitEdit'
import MiniNav from '../main/MiniNav'
import '../../js/search_fns.js'

export default {
	components: { CollectionEdit, CollectionUnitEdit, MiniNav, ResourceCollectionView },
	props: {
	},
	data() { return {
		initialized: false,
		new_lp: null,
		show_sc_collection_editor: false,
		sc_unit_being_edited: null,

		search_terms: '',
		search_term_res: [],
		stop_words: [],
		search_results: [],
	}},
	computed: {
		...mapState(['user_info', 'all_courses_loaded', 'all_courses', 'course_update_trigger']),
		...mapGetters(['child_count', 'child_data_showing', 'system_role', 'role', 'beta_options', 'small_screen']),
		connections_showing: {
			get() {
				// if you're viewing with the parent role, we always show the parent connections
				if (this.role == 'parent') return 'parent'
				else return this.$store.state.lst.connections_showing
			},
			set(val) { this.$store.commit('lst_set', ['connections_showing', val]) }
		},
		collection_showing() {
			if (!empty(this.$router.history.current.params, 'course_code')) {
				return this.$router.history.current.params.course_code
			} else {
				return ''
			}
		},
		sclp() {
			// we use a single LP with subject 'SC' (staff connections) for the staff connections links
			let lp = this.all_courses.find(x=>x.subject == 'SC')
			if (empty(lp)) return null	// shouldn't happen

			// if we don't already have the full collection data...
			if (empty(lp.lp_id) || lp.lp_id == 0) {
				// try to load it now
				this.$store.dispatch('get_learning_progression', lp.course_code).then((found)=>{
					if (!found) {
						this.$alert('This collection id was not found.')	// shouldn't happen
						return
					}

				}).catch((e)=>{
					console.log(e)
					this.$alert('error in get_collection?')
				})
			}
			return lp
		},
		sc_collections() {
			if (empty(this.sclp) || empty(this.sclp.units)) return []

			let sc_arr = []
			for (let unit of this.sclp.units) {
				let arr = []
				// for each resource...
				for (let resource of unit.resources) {
					if (this.search_results.length > 0 && !this.search_results.find(x=>x==resource.resource_id)) continue

					// console.log(resource.description)
					// look for a folder_assignment for the resource
					let folder_assignment = unit.resource_tree.folder_assignments.find(x=>x.resource_id==resource.resource_id)

					// if not found and we're doing the 'top' folder, put the resource at the end of the folder
					if (empty(folder_assignment)) {
						folder_assignment = {
							resource_id: resource.resource_id,
							parent_folder_id: 'top',
							seq: this.get_max_folder_sequence('top') + 1
						}
						// this.$store.commit('set', [unit.resource_tree.folder_assignments, 'PUSH', folder_assignment])
					}

					// if this resource goes in this folder, add it in seq order
					if (oprop(folder_assignment, 'parent_folder_id') == 'top') {
						arr[folder_assignment.seq] = resource
					}
				}

				// now deal with sub-folders
				// NO SUB-FOLDERS IN STAFF RESOURCES
				// for (let folder of unit.resource_tree.folders) {
				// 	if (folder.parent_folder_id == this.folder_id) {
				// 		// create a "fake resource" for the folder
				// 		arr[folder.seq] = {
				// 			type: 'folder',
				// 			resource_id: folder.folder_id
				// 		}
				// 	}
				// }

				// remove empty vals, which can happen if/when items are deleted
				for (let i = arr.length-1; i >= 0; --i) {
					if (empty(arr[i])) {
						arr.splice(i, 1)
					}
				}

				let o = {
					uuid: U.new_uuid(),
					title: unit.title,
					// items: unit.resources,
					items: arr,
					initially_expanded: false,
					unit: unit,
				}
				sc_arr.push(o)
			}
			return sc_arr
		},
		pglps() {
			let arr = this.all_courses.filter(lp => {
				// when we first create a new LP to be entered, its title will be false
				if (lp.title == '') return false

				// for inactive lp's, only show to admins
				if (lp.active == 'no' && !this.is_pg_admin) return false

				return (lp.subject == 'PG')
			})

			arr.sort((a,b)=>{
				// featured collections first
				if (a.featured && !b.featured) return -1
				if (b.featured && !a.featured) return 1

				// now sequence if there
				if (a.sequence != 0 && b.sequence == 0) return -1
				else if (b.sequence != 0 && a.sequence == 0) return 1
				else if (a.sequence != b.sequence) return a.sequence - b.sequence

				// if both are featured, most-recently-featured first
				if (a.featured && b.featured) return b.featured - a.featured

				// default sort by title
				if (a.title < b.title) return -1
				if (b.title < a.title) return 1

				return 0
			})

			return arr
		},
		has_featured_collection() {
			for (let lp of this.pglps) {
				if (lp.featured > 0) return true
			}
			return false
		},
		has_nonfeatured_collection() {
			for (let lp of this.pglps) {
				if (lp.featured == 0) return true
			}
			return false
		},
		is_pg_admin() {
			return vapp.has_admin_right('pg')
		},
	},
	created() {
		if (!this.all_courses_loaded) {
			this.$store.dispatch('get_all_courses').then(()=>{
				this.$nextTick(()=>{
					this.initialized = true
					// console.log('all_courses', this.all_courses)
				})
			}).catch(()=>{
				this.$alert('error in get_all_courses')
			})
		} else {
			this.initialized = true
		}
	},
	mounted() {
	},
	methods: {
		lp_css(lp) {
			// let s = vapp.color_from_number(lp.course_code)
			let s = U.subject_tile_css(lp)
			// let s = ''
			if (lp.title.length > 50) {
				s += ' k-lp-tile-extra-long-title'
			} else if (lp.title.length > 30) {
				s += ' k-lp-tile-long-title'
			}
			if (lp.active == 'no') {
				s += ' k-lp-tile-inactive'
			}

			if (this.collection_showing == lp.course_code) {
				s += ' k-lp-tile--pd-showing'
			}

			return s
		},

		lp_title(lp) {
			// add <wbr> tags after slashes
			let s = lp.title.replace(/\//g, '/<wbr>')
				if (lp.active == 'no') {
					s += ' <b class="red--text">[Inactive]</b>'
				}

			return s
		},

		go_to_lp(lp) {
			this.$store.commit('set', ['last_lp_list', 'index'])
			this.$router.push({ path: sr('/pg/$1/0', lp.course_code) })
		},

		create_lp() {
			this.new_lp = new Learning_Progression({subject:'PG'})
			return
		},

		edit_sc_collection(resource_collection) {
			this.sc_unit_being_edited = resource_collection.unit
		},

		execute_search(node) {
			if (empty(node)) return false

			// by default return false (item doesn't meet criteria)
			let rv = false

			// if the node has items, search the items
			if (!empty(node.items) && node.items.length > 0) {
				for (let child of node.items) {
					if (this.execute_search(child)) {
						rv = true
					}
				}
			}

			// if the node has a description, determine if it should be highlighted as a search result
			let description = node.description
			if (!empty(description)) {
				description = description.toLowerCase()
				// if the description includes a stop word, no
				if (!U.string_includes_stop_word(this.stop_words, description)) {
					if (U.strings_match_search_term_res(this.search_term_res, [description])) {
						this.search_results.push(node.resource_id)
						rv = true
					}
				}
			}

			return rv
		},

		execute_search_start() {
			// establish the debounce fn if necessary
			if (empty(this.fn_debounced)) {
				this.fn_debounced = U.debounce(() => {
					// this.collapse_all()
					this.execute_search_clear()

					let arr = U.create_search_re(this.search_terms)
					this.search_term_res = arr[0]
					this.stop_words = arr[1]

					for (let collection of this.sc_collections) {
						this.execute_search(collection)
					}
					if (this.search_results.length == 0 && this.search_terms) {
						this.$inform({text:'No items matched your search terms.', color:'pink darken-4'})
					}
				}, 1000)
			}

			// call the debounce fn
			this.fn_debounced()
		},

		execute_search_clear() {
			this.search_results = []
		},

		search_field_keyup(evt) {
			this.execute_search_start()
		},
	}
}
</script>

<style lang="scss">

.k-staff-collections-wrapper {
	background-color:rgba(255, 240, 200, 0.5);
	margin:0 auto;
	border-radius:30px;
	padding:8px;
	max-width:1000px;
}
// .k-parent-resources-collections-view-wrapper {
// 	.k-collections-units-outer-wrapper {
// 		border-color:$secondary-color !important;
// 		border-width:6px;
// 		background-color:$v-yellow-lighten-4 !important;
// 	}
// }

// rainbow background colors for collection sub-categories
.k-staff-sub-collection-0 { .k-resource-collection { background-color:$v-green-lighten-4; } .k-resource-collection-item { background-color:$v-green-darken-4!important } }
.k-staff-sub-collection-1 { .k-resource-collection { background-color:$v-blue-lighten-4; } .k-resource-collection-item { background-color:$v-blue-darken-4!important } }
.k-staff-sub-collection-2 { .k-resource-collection { background-color:$v-indigo-lighten-4; } .k-resource-collection-item { background-color:$v-indigo-darken-4!important } }
.k-staff-sub-collection-3 { .k-resource-collection { background-color:$v-purple-lighten-4; } .k-resource-collection-item { background-color:$v-purple-darken-4!important } }
.k-staff-sub-collection-4 { .k-resource-collection { background-color:$v-pink-lighten-4; } .k-resource-collection-item { background-color:$v-pink-darken-4!important } }
.k-staff-sub-collection-5 { .k-resource-collection { background-color:$v-orange-lighten-4; } .k-resource-collection-item { background-color:$v-orange-darken-4!important } }
.k-staff-sub-collection-6 { .k-resource-collection { background-color:$v-yellow-lighten-4; } .k-resource-collection-item { background-color:$v-yellow-darken-4!important } }
.k-staff-sub-collection-7 { .k-resource-collection { background-color:$v-blue-grey-lighten-4; } .k-resource-collection-item { background-color:$v-blue-grey-darken-4!important } }
.k-staff-sub-collection-8 { .k-resource-collection { background-color:$v-blue-grey-lighten-4; } .k-resource-collection-item { background-color:$v-blue-grey-darken-4!important } }

.xk-collections-view-wrapper-inner {
	.k-resource-collection-item--student-resource {
		.k-resource-collection-item--title {
			.fas, .k-resource-collection-item-title-link {color:#fff!important; }
		}
	}
}


// Note: see collection styles in PDIndex
</style>
