<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template><div class="k-my-content-view-list-outer" v-show="lessons.length>0||activities.length>0" @click="last_viewed_activity_or_lesson_id=''">
	<div v-if="show_header" class="k-library-view-header">
		<div v-html="library_title"></div>
		<v-spacer/>
		<v-btn v-show="lessons.length>0" small :outlined="!library_show_lessons" :color="library_show_lessons?'#fff':'#333'" class="k-tight-btn" @click="library_show_lessons=!library_show_lessons">Lessons ({{lessons.length}})<v-icon small class="ml-2">fas fa-caret-{{library_show_lessons?'up':'down'}}</v-icon></v-btn>
		<v-btn v-show="activities.length>0" small :outlined="!library_show_activities" :color="library_show_activities?'#fff':'#333'" class="k-tight-btn ml-3" @click="library_show_activities=!library_show_activities">Student Activities ({{activities.length}})<v-icon small class="ml-2">fas fa-caret-{{library_show_activities?'up':'down'}}</v-icon></v-btn>
	</div>

	<v-expand-transition><div v-show="!show_header||(library_show_lessons&&lessons.length>0)">
		<div class="k-library-view-secondary-header">Lessons</div>
		<div v-for="(item, index) in lessons" :key="item.id" :class="outer_content_item_class(item.id)">
			<div class="k-mycontent-view2-outer-scrim"></div>
			<v-hover v-slot:default="{hover}"><div class="k-mycontent-item-outer" :class="outer_content_item_class2(item,hover)">
				<MyContentItemView :ref="'item'+item.id" :course_code="course_code" :lp_unit_id="lp_unit_id" :item="item" :hover="hover" :flags="{in_library:true, is_editable:library_editable}" @edits_saved="$emit('edits_saved', $event)" @duplicate_item="$emit('duplicate_item', $event)" />
			</div></v-hover>
		</div>
	</div></v-expand-transition>

	<v-expand-transition><div v-show="!show_header||(library_show_activities&&activities.length>0)">
		<div class="k-library-view-secondary-header">Student Activities</div>
		<div v-for="(item, index) in activities" :key="item.id" :class="outer_content_item_class(item.id)">
			<div class="k-mycontent-view2-outer-scrim"></div>
			<v-hover v-slot:default="{hover}"><div class="k-mycontent-item-outer" :class="outer_content_item_class2(item,hover)">
				<MyContentItemView :ref="'item'+item.id" :course_code="course_code" :lp_unit_id="lp_unit_id" :item="item" :hover="hover" :flags="{in_library:true, is_editable:library_editable}" @edits_saved="$emit('edits_saved', $event)" @duplicate_item="$emit('duplicate_item', $event)" />
			</div></v-hover>
		</div>
	</div></v-expand-transition>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import MyContentItemView from './MyContentItemView'

export default {
	components: { MyContentItemView },
	props: {
		course_code: { type: String, required: false, default() { return '' } },
		lp_unit_id: { type: Number, required: false, default() { return 0 } },
		show_header: { type: Boolean, required: false, default() { return true } },
		library_editable: { type: Boolean, required: false, default() { return false } },
		search_terms: { type: String, required: false, default() { return '' } },
	},
	data() { return {
		show_library: true,
		items_rendered: false,
	}},
	computed: {
		...mapState(['all_courses']),
		...mapGetters([]),
		library_title() {
			return 'Unit Library'
		},
		library_show_lessons: {
			get() { return this.$store.state.lst.library_show_lessons },
			set(val) { this.$store.commit('lst_set', ['library_show_lessons', val]) }
		},
		library_show_activities: {
			get() { return this.$store.state.lst.library_show_activities },
			set(val) { this.$store.commit('lst_set', ['library_show_activities', val]) }
		},
		// we must have at least an LP course_code; we may also get an lp_unit_id
		lp() {
			// we always pull from all_courses here, not the user's individual courses
			let l = this.all_courses.find(o=>o.course_code == this.course_code)
			if (empty(l)) return {}
			return l
		},
		units() {
			if (!this.lp.units || this.lp.units.length == 0) return []
			if (this.lp_unit_id > 0) return [this.lp.units?.find(x=>x.lp_unit_id == this.lp_unit_id)]
			else return this.lp.units
		},
		lessons() {
			let search_terms = this.search_terms?.toLowerCase()
			let arr = []
			for (let unit of this.units) {
				for (let lesson of unit.lessons) {
					// if we have search_terms, filter based on lesson title
					if (search_terms && !lesson.lesson_title.toLowerCase().includes(search_terms)) continue

					let item = {
						type: 'lesson',
						o: lesson,
						title: lesson.lesson_title,
						id: 'L' + lesson.lesson_id,
						icon: lesson.icon(),
					}
					arr.push(item)
				}
			}
			return arr
		},
		activities() {
			let search_terms = this.search_terms?.toLowerCase()
			let arr = []
			for (let unit of this.units) {
				for (let activity of unit.activities) {
					// if we have search_terms, filter based on activity title
					if (search_terms && !activity.activity_title.toLowerCase().includes(search_terms)) continue
					let item = {
						type: 'activity',
						o: activity,
						title: activity.activity_title,
						id: 'A' + activity.activity_id,
						icon: activity.icon(),
					}
					arr.push(item)
				}
			}
			return arr
		},
		last_viewed_activity_or_lesson_id: {
			get() { return this.$store.state.last_viewed_activity_or_lesson_id },
			set(val) { this.$store.commit('set', ['last_viewed_activity_or_lesson_id', val]) }
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
		// from MyContentView2; not sure if it's needed
		this.$nextTick(()=>this.items_rendered = true)
	},
	methods: {
		outer_content_item_class(item) {
			let s = ''
			if (!this.items_rendered) return s

			let ref = this.$refs['item'+item.id]
			if (ref && ref[0] && ref[0].lesson_expanded) s += ' k-mycontent-view2-outer-expanded'
			return s
		},

		outer_content_item_class2(item, hover) {
			let s = ''
			if (!this.items_rendered) return s

			let ref = this.$refs['item'+item.id]
			if (ref && ref[0] && ref[0].item_being_edited) s += ' k-mycontent-item-outer-edited elevation-5'
			else if (item.o.item_showing) s += ' k-mycontent-item-outer-showing elevation-5'
			else s += ' elevation-0'

			if (hover) s += ' k-mycontent-item-outer-hovered'

			// highlight the last-clicked item
			if (this.$store.state.last_viewed_activity_or_lesson_id == item.id) s += ' k-mycontent-item-outer-last-viewed'

			return s
		},
	}
}
</script>

<style lang="scss">
.k-library-view-header {
	max-width:860px;
	margin-left:auto;
	margin-right:auto;
	text-align:left;
	font-size:20px;
	font-weight:bold;
	// margin-bottom:8px;
	// margin-left:8px;
	display:flex;
	align-items:center;
	// color:#333;
	padding-top:8px;
	border-top:1px solid #999;
}

.k-library-view-secondary-header {
	font-size:16px;
	font-weight:bold;
	margin-left:8px;
	margin-bottom:4px;
	padding-top:4px;
}
</style>