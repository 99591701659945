<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-page-wrapper">
	<h2 class="k-page-title">
		<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-cog</v-icon>
		Learning Progressions Administration
	</h2>

	<p v-show="initialized&&!new_lp">
		<v-btn @click="create_lp" class="k-tight-btn" color="primary"><v-icon class="mr-2" small>fas fa-plus</v-icon> Create a New Learning Progression</v-btn>
		<v-btn class="ml-2 k-tight-btn" color="primary" @click="duplicate_lp"><v-icon small class="mr-2">fas fa-copy</v-icon>Duplicate an Existing LP</v-btn>
		<v-btn class="ml-2 k-tight-btn" color="red" dark @click="delete_lp_clicked"><v-icon small class="mr-2">fas fa-trash-alt</v-icon>Delete an Existing LP</v-btn>
	</p>

	<LearningProgressionEdit v-if="new_lp" :learning_progression="new_lp" @editor_cancel="new_lp=null"></LearningProgressionEdit>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import LearningProgressionEdit from '../courses/LearningProgressionEdit'

export default {
	components: { LearningProgressionEdit },
	props: {
	},
	data() { return {
		initialized: false,
		new_lp: null,
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
		if (!this.all_courses_loaded) {
			this.$store.dispatch('get_all_courses').then(()=>{
				this.$nextTick(()=>{
					this.initialized = true
				})
			}).catch(()=>{
				this.$alert('error in get_all_courses')
				this.return_to_admin_main()
			})
		} else {
			this.initialized = true
		}
	},
	mounted() {
	},
	methods: {
		create_lp() {
			this.new_lp = new Learning_Progression()
			return
		},

		duplicate_lp() {
			this.$prompt({
				title: 'Duplicate Learning Progression',
				text: 'Enter the course code(s) for the Learning Progression you’d like to duplicate, along with the new course codes for the duplicates. Separate each original and duplicate course code with a :, and separate multiple course code pairs with a line break, like this:<br><br>1111: 1112<br>3333: 3334<br>5555: 5556<br><br>',
				promptType: 'textarea',
				acceptText: 'Duplicate',
			}).then(course_code_lines => {
				if (empty(course_code_lines)) return
				course_code_lines = $.trim(course_code_lines).split(/\n+/)

				// parse lines and check for errors
				let course_codes = []
				let bad_origin_course_codes = []
				let bad_destination_course_codes = []
				for (let line of course_code_lines) {
					let arr = $.trim(line).split(/[\s\:]+/)
					if (arr.length != 2) {
						this.$alert('Bad line in input:<br><br>' + line)
						return
					}

					// if the first code doesn't exist, error
					if (empty(this.$store.state.all_courses.find((o)=>o.course_code==arr[0]))) {
						bad_origin_course_codes.push(arr[0])
					}

					// if the second code does exist, error
					if (!empty(this.$store.state.all_courses.find((o)=>o.course_code==arr[1]))) {
						bad_destination_course_codes.push(arr[1])
					}

					course_codes.push(arr)
				}

				if (bad_origin_course_codes.length > 0) {
					this.$alert(sr('We don’t have Learning Progression(s) for course code(s) $1.', bad_origin_course_codes.join(', ')))
					return
				}
				if (bad_destination_course_codes.length > 0) {
					this.$alert(sr('We already have Learning Progression(s) for course code(s) $1.', bad_destination_course_codes.join(', ')))
					return
				}

				// if we get to here, process the duplicates
				this.$store.dispatch('duplicate_learning_progressions', course_codes).then(()=>{
					this.$alert('Learning Progression(s) duplicated.')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		delete_lp_clicked() {
			this.$prompt({
				title: 'Delete Learning Progression',
				text: 'Enter the course code for the Learning Progression you’d like to delete.',
				acceptText: 'Delete',
				acceptColor: 'red',
			}).then(course_code => {
				if (!empty(course_code)) {
					this.delete_lp(course_code)
				}
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		delete_lp(course_code) {
			let lp = this.$store.state.all_courses.find((o)=>o.course_code==course_code)
			if (empty(lp)) {
				this.$alert('We don’t have a Learning Progression for that course code in the system.')
				return
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: sr('Are you sure you want to delete the Learning Progression for course code $1 (“$2”)?', lp.course_code, lp.title),
			    acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				this.$store.dispatch('delete_learning_progression', lp).then(()=>{
					this.$alert('Learning Progression deleted.')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		return_to_admin_main() {
			this.$router.push({ path: '/welcome' })
		}
	}
}
</script>

<style lang="scss">
</style>
