<!-- Part of the SPARKL educational activity system, Copyright 2020 by Pepper Williams -->
<template><div class="k-lp-unit-editor-wrapper">
	<div class="d-flex align-center">
		<v-text-field background-color="#fff" class="mr-2" hide-details outlined label="Number (e.g. “Unit 1”)" style="flex-basis:25%" v-model="unit.title" @change="unit_number_changed(unit)"></v-text-field>
		<v-text-field background-color="#fff" class="mr-2" hide-details outlined label="Weeks" style="flex-basis:15%" v-model="unit.duration"></v-text-field>
		<v-text-field background-color="#fff" class="" style="font-weight:bold; flex-basis:60%;" hide-details outlined label="Unit Short Description (e.g. “Matter”)" v-model="unit.description"></v-text-field>
	</div>

	<div class="mt-3"><froala-wrapper :config="editor_config('Unit Long Description')" v-model="unit.text" /></div>

	<div class="mt-3">
		<div class="d-flex align-center lp_unit_editor_standard_div">
			<div><b>Standards and Elements ({{unit.standards.length}})</b></div>
			<v-btn x-small class="ml-2" color="#ccc" @click="standards_showing=!standards_showing">{{standards_showing?'Hide':'Show'}}</v-btn>
			<v-spacer/>
			<v-btn v-show="!aligning_to_standards" class="mt-2" small color="brown" dark @click="align_to_standard(null)"><v-icon class="mr-1" small>fas fa-bullseye</v-icon>Edit Aligned Standards</v-btn>
			<v-btn v-show="aligning_to_standards" class="mt-2" small color="brown" dark @click="finish_aligning_to_standards"><v-icon class="mr-1" small>fas fa-check</v-icon>Done Aligning</v-btn>
		</div>

		<v-expand-transition><div v-show="standards_showing&&unit.standards.length>0" style="background-color:#fff; padding:2px 8px; border-radius:6px; margin-top:8px;">
			<draggable v-bind="drag_options" v-model="unit.standards" @end="drag_complete">
				<div v-for="(standard) in unit.standards" class="k-lp-editor-standard-resource">
					<div class="d-flex align-center">
						<div class="k-lp-editor-standard-description" @click="align_to_standard(standard)"><b v-html="standard.case_item.humanCodingScheme"></b> &nbsp; <span v-html="standard.case_item.fullStatement"></span></div>
						<v-btn icon small color="red lighten-2" class="ml-2" @click="remove_standard(standard)"><v-icon>fas fa-times-circle</v-icon></v-btn>
						<v-icon small class="k-move-handle ml-2 mr-2" @click.stop="">fas fa-up-down-left-right</v-icon>
					</div>
				</div>
			</draggable>
		</div></v-expand-transition>
	</div>

	<div class="mt-6">
		<div>
			<b>Unit Planning & Resources</b>
			<v-btn small v-show="!unit.show_resource_creator" class="ml-2" color="primary" @click="create_resource_start"><v-icon small class="mr-1">fas fa-plus-circle</v-icon>Add Resource</v-btn>
			<v-btn class="ml-3" x-small @click="remove_all_resources">Remove All</v-btn>
		</div>

		<div v-if="unit.show_resource_creator" class="k-lp-editor-resource-creator">
			<EditResource :show_shareable_control="false" :district_sanctioned="true" :show_lp_category_chooser="true" :show_family_control="true" @edit_resource_cancel="create_resource_cancel" @edit_resource_save="create_resource_save" />
		</div>

		<LPUnitResourceCollectionTree
			:lp="lp" :unit="unit" term_mode=""
			:enable_hc_assignment="true"
			:enable_edit_link="true"
			:enable_remove_link="true"
			:enable_collection_editing="true"
			@edit_resource_save="edit_resource_save"
			@remove_resource="remove_resource"
			@save_unit_edits="save_edits(false)"
		/>

	</div>

	<div class="mt-6" style="background-color:#eee; padding:8px 12px; border-radius:12px; margin-top:8px;">
		<div class="d-flex align-center">
			<b style="font-size:18px">Unit Lesson Plan/Activity Library</b>
			<v-btn small class="ml-3 k-tight-btn" color="primary" @click="import_shared_item"><v-icon small class="mr-2">fas fa-plus-circle</v-icon>Import a Lesson Plan or Activity</v-btn>
			<!-- <v-menu nudge-bottom="30"><template v-slot:activator="{on}"><v-btn v-on="on" small class="ml-3" color="primary"><v-icon small class="mr-2">fas fa-plus-circle</v-icon>Add ...</v-btn></template>
				<v-list min-width="250">
					<v-list-item @click="create_lesson_start"><v-list-item-title>Create a New Lesson Plan</v-list-item-title></v-list-item>
					<v-list-item @click="create_sparkl({type:'activity'})"><v-list-item-title>Create a New Student Activity</v-list-item-title></v-list-item>
					<v-list-item @click="import_shared_item"><v-list-item-title>Import a Lesson Plan or Activity</v-list-item-title></v-list-item>
				</v-list>
			</v-menu> -->
			<v-menu top><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-3" x-small><v-icon x-small class="mr-1">fas fa-trash-alt</v-icon>Remove All...</v-btn></template>
				<v-list min-width="250">
					<v-list-item @click="remove_all_lessons_activities('lessons')"><v-list-item-title>Remove all lessons</v-list-item-title></v-list-item>
					<v-list-item @click="remove_all_lessons_activities('activities')"><v-list-item-title>Remove all activities</v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
			<v-spacer/>
			<div style="font-size:11px; line-height:15px;" class="text-center">Note: this library appears in the<br>“My Lessons & Activities” area of the unit.</div>
		</div>
		<div class="mt-2">
			<ContentLibrariesView :course_code="lp.course_code" :lp_unit_id="unit.lp_unit_id" :show_header="false" :library_editable="true" @edits_saved="library_edits_saved" @duplicate_item="duplicate_item" />
		</div>
	</div>

	<v-card-actions class="mt-3">
		<v-btn color="secondary" @click="process_upg_btn_clicked" class="mr-1"><v-icon small class="mr-2">fas fa-file-import</v-icon> Process Unit Planning Guide</v-btn>
		<v-btn color="secondary" @click="edit_resource_import_rules" class="mr-1"><v-icon small class="mr-2">fas fa-cog</v-icon> Resource Import Rules</v-btn>
		<v-spacer></v-spacer>
		<v-btn color="secondary" @click="cancel_editor" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
	</v-card-actions>
	<DuplicateActivity
		v-if="duplicate_activity_data"
		:duplicate_is_template_resource="true"
		:course_code="lp.course_code"
		:lp_unit_id="unit.lp_unit_id"
		:activity_data="duplicate_activity_data"
		@duplicate_activities_created="duplicate_activities_created"
		@dialog_cancel="duplicate_activity_data=null" />
	<DuplicateLesson
		v-if="duplicate_lesson_data"
		:lesson_data="duplicate_lesson_data"
		@duplicate_lesson_created="duplicate_lesson_created"
		@dialog_cancel="duplicate_lesson_data=null" />
	</div>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import EditResource from '../resources/EditResource'
import LPUnitResourceCollectionTree from '../resources/LPUnitResourceCollectionTree'
import ContentLibrariesView from '../mycontent/ContentLibrariesView'
import DuplicateActivity from '../lessons/DuplicateActivity'
import DuplicateLesson from '../lessons/DuplicateLesson'
import draggable from 'vuedraggable'

export default {
	components: { EditResource, LPUnitResourceCollectionTree, ContentLibrariesView, DuplicateActivity, DuplicateLesson, draggable, DuplicateLesson },
	props: {
		lp: { type: Object, required: true },
		original_unit: { type: Object, required: true },
	},
	data() { return {
		grade_low: this.lp.grade_low,
		grade_high: this.lp.grade_high,
		subject: this.lp.subject,

		// create a new unit to edit here; then we copy it into the original_unit when user saves
		unit: new LP_Unit(this.original_unit),

		initial_values: JSON.stringify(this.original_unit.copy_for_save()),

		case_tree_showing: false,
		case_tree_loaded: false,
		aligning_to_unit: false,
		case_tree_active_item: '',
		case_tree_open_item: '',
		case_tree_item_to_open: '',

		standards_showing: false,
		aligning_to_standards: false,

		resource_import_rules: U.local_storage_get('district_portal_resource_import_rules', []),

		duplicate_activity_data: null,
		duplicate_lesson_data: null,

		drag_options: {
			animation: 200,
			handle: ".k-move-handle",
		}
	}},
	computed: {
		...mapState(['user_info', 'case_frameworks']),
		...mapGetters([]),
		case_framework_identifier() {
			return this.$store.state.subjects[this.subject]	// may return null
		},
		sorted_resources() {
			let arr = this.unit.resources.concat([])
			arr.sort(U.resources_sort)
			return arr
		},
	},
	created() {
	},
	mounted() {
		// course_unit_editor is used by the standards chooser hide_fn
		vapp.course_unit_editor = this
	},
	methods: {
		editor_config(text, inline) {
			return U.get_froala_config({
				placeholderText: text,
				// initOnClick: true,
				toolbarInline: (inline === true),
			})
		},
		unit_number_changed(unit) {
			// if user enters just a number, change to "Unit #"
			if (unit.title && !isNaN(unit.title*1)) {
				unit.title = 'Unit ' + unit.title
			}
		},

		// resource fns work for both units and standards
		create_resource_start() {
			this.$set(this.unit, 'show_resource_creator', true)
		},

		create_resource_cancel() {
			this.unit.show_resource_creator = false
		},

		create_resource_save(resource) {
			this.unit.resources.push(resource)
			this.unit.show_resource_creator = false

			// save unit edits right away whenever a new resource is added
			this.save_edits(false)
		},

 		remove_resource(resource) {
			// the LPUnitResourceCollectionTree component deals with cross-unit resources
			let i = this.unit.resources.findIndex(x=>x.resource_id==resource.resource_id)
			if (i > -1) {
				this.unit.resources.splice(i, 1)
			} else {
				console.log('RESOURCE COULD NOT BE FOUND!')
			}
		},

		remove_all_resources() {
			this.$confirm({
			    title: 'Are you sure?',
			    text: 'Are you sure you want to remove all resources from the unit?',
			    acceptText: 'Remove Resources',
				acceptColor: 'red',
			}).then(y => {
				this.unit.resources = []
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		edit_resource_save(resource, edited_resource) {
			// transfer all properties from the edited_resource to the local resource and the one in the original unit
			let original_resource = this.original_unit.resources.find(o=>o.resource_id == resource.resource_id)
			for (let prop in edited_resource) {
				resource[prop] = edited_resource[prop]
				this.$store.commit('set', [original_resource, prop, edited_resource[prop]])
			}
		},

		////////////////////////////////////////
		// Lessons and activities
		edit_lesson_cancel() {
			this.lesson_being_edited = null
		},

		edit_lesson_saved(edited_lesson) {
			// the lesson editor will have saved the lesson to the db; splice or push it to unit lessons
			let index = this.unit.lessons.findIndex(x=>x.lesson_id == edited_lesson.lesson_id)
			if (index == -1) {
				let new_lesson = new Lesson(edited_lesson)
				console.log(new_lesson)
				this.unit.lessons.push(new_lesson)
				this.lesson_being_edited = null
				// save unit edits right away whenever a new lesson is added
				this.save_edits(false).then(x=>{
					// then close and re-open the editor
					setTimeout(x=>this.lesson_being_edited = new_lesson, 100)
				})

			} else {
				this.unit.lessons.splice(index, 1, new Lesson(edited_lesson))
				// in the case of an update the unit data won't change, so we have to update the original_unit too
				this.original_unit.lessons.splice(index, 1, new Lesson(edited_lesson))
			}
		},

		show_sparkl(item, embed_mode) {
			this.sparkl_activity_record = {
				tool_activity_id: item.tool_activity_id ?? item.url,
				lti_resource_link_id: item.lti_resource_link_id ?? item.resource_id,
				activity_title: item.activity_title ?? item.description,
			}

			this.sparkl_embed_mode = typeof(embed_mode) == 'string' ? embed_mode : 'view'
			console.log('this.sparkl_embed_mode: ' + this.sparkl_embed_mode)

			this.sparkl_loaded = true
			this.sparkl_showing = true
		},

		hide_sparkl() {
			this.sparkl_loaded = false
			this.sparkl_showing = false
			this.sparkl_activity_record = null
			this.initial_sparkl_content = ''
		},
		
		rename_activity() {
			// note that this fn is used for renaming already-existing activities
			// get the resource item that's showing
			let item_index = this.unit.resources.findIndex(x=>x.resource_id == this.sparkl_activity_record.lti_resource_link_id)
			if (item_index == -1) {	// shouldn't happen
				this.$alert('An error occurred when renaming the activity.')
				return
			}
			let item = this.unit.resources[item_index]

			this.$prompt({
				title: 'Rename Activity',
				text: 'Enter new activity title',
				initialValue: item.description,
				disableForEmptyValue: true,
				acceptText: 'Save',
				acceptIconAfter: 'fas fa-circle-arrow-right',
				hideCancel: false,	// set to true to hide the cancel button
			}).then(title => {
				title = $.trim(title)
				if (empty(title)) return

				this.$refs.sparkl_embed.execute('host_activity_saved', {activity_title: title})
				this.$store.commit('set', [item, 'description', title])
				this.$store.dispatch('save_resource', {resource: item}).then((resource_data)=>{
					this.unit.resources.splice(item_index, 1, new Resource(resource_data))
					this.original_unit.resources.splice(item_index, 1, new Resource(resource_data))
				})

			}).catch(n=>{console.log(n)}).finally(f=>{})
			setTimeout(x=>{ 
				$('.v-overlay--active').css('z-index', '200000001') 
				$('.v-dialog__content--active').css('z-index', '200000002') 
			})
		},

		// note that a sibling fn is also in CourseUnitEditor
		import_shared_item() {
			let params = {acceptText:'Import'}
			params.title = 'Import Shared Lesson or Activity'
			params.text = 'Enter the shared Lesson ID or Activity ID:<div class="mt-2" style="font-size:14px;font-style:italic">This ID should start with an “L” or “A”.</div>'

			this.$prompt(params).then(entered_item_id => {
				// special hack: import items from "my activities"
				if (entered_item_id == 'MY') {
					this.import_my_activities()
					return
				}

				entered_item_id = $.trim(entered_item_id).toUpperCase()
				if (entered_item_id.search(/^([ALS])(\d+)$/) == -1) {
					this.$alert('The ID you entered is not valid.').then(()=>this.import_shared_item())
					return
				}
				let item_id_prefix = RegExp.$1
				let item_id = RegExp.$2
				
				// L119 / A115
				let payload = {
					user_id: this.user_info.user_id, 
					item_id: item_id,
					creator_user_id: this.user_info.user_id,
					course_code: this.lp.course_code,
					lp_unit_id: this.unit.lp_unit_id,
					template_id: 'none',	// this ensures the shared item won't be added to the user's "my" area
				}
				let service = (item_id_prefix == 'L') ? 'add_shared_lesson' : 'add_shared_activity'
				U.loading_start()
				U.ajax(service, payload, result=>{
					U.loading_stop()
					if (result.status == 'item_not_found') {
						this.$alert(sr('We could not find an item with the ID you entered ($1).', entered_item_id)).then(()=>this.import_shared_item())
					} else if (result.status != 'ok') {
						this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
					}

					if (service == 'add_shared_lesson') {
						this.unit.lessons.push(new Lesson(result.lesson))
						// save unit edits right away whenever a new lesson is added
						this.save_edits(false)
						this.$inform('Shared lesson added to unit')

					} else {
						this.unit.activities.push(new Activity(result.activity))
						// save unit edits right away whenever a new lesson is added
						this.save_edits(false)
						this.$inform('Shared activity added to unit')
					}
				});
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		import_my_activities() {
			// go through all activities in "my activities" for this unit
			let activity_ids = []
			let s = ''

			let activity_pool = this.$store.state.my_activities_by_course[this.course_code] ?? this.$store.state.my_activities
			if (activity_pool) for (let i = 0; i < activity_pool.length; ++i) {
				let activity = activity_pool[i]
				if (activity.lp_unit_id == this.unit.lp_unit_id) {
					activity_ids.push(activity.activity_id)
					s += `<li>${activity.activity_title}</li>`	
				}
			}

			if (activity_ids.length == 0) {
				this.$alert('You do not have any “My Activities” tied to this unit.')
				return
			}

			s = `Are you sure you want to import the ${activity_ids.length} “My Activities” tied to this unit to the Unit Library? Here are the activities that will be imported:<ul>${s}</ul>`
			this.$confirm({
				title: 'Import from “My Activities” to Unit Library',
				text: s,
				acceptText: 'Import Activities',
				dialogMaxWidth: 800,
			}).then(y => {
				let payload = {
					user_id: this.user_info.user_id, 
					activity_ids: activity_ids,
					creator_user_id: this.user_info.user_id,
					course_code: this.lp.course_code,
					lp_unit_id: this.unit.lp_unit_id,
					template_id: 'none',	// this ensures the shared item won't be added to the user's "my" area
				}
				U.loading_start()
				U.ajax('add_shared_activity', payload, result=>{
					U.loading_stop()
					if (result.status == 'item_not_found') {
						this.$alert(sr('We could not find an item with the ID you entered ($1).', entered_item_id)).then(()=>this.import_shared_item())
					} else if (result.status != 'ok') {
						this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
					}

					for (let activity of result.activities) {
						this.unit.activities.push(new Activity(activity))
					}
					// save unit edits right away whenever a new lesson is added
					this.save_edits(false)
					this.$inform('Shared activity added to unit')
				});
		
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		library_edits_saved(o) {
			if (o.item_deleted === true) {
				// o will include a type and item_id field; remove from unit lessons or activities
				if (o.type == 'lesson') {
					let index = this.unit.lessons.findIndex(x=>x.lesson_id == o.item_id)
					this.unit.lessons.splice(index, 1)
				} else {
					let index = this.unit.activities.findIndex(x=>x.activity_id == o.item_id)
					this.unit.activities.splice(index, 1)
				}

			} else {
				// o is a new Lesson or Activity object; update in the unit lessons or activities
				if (o.lesson_id) {
					let index = this.unit.lessons.findIndex(x=>x.lesson_id == o.lesson_id)
					this.unit.lessons.splice(index, 1, o)
				} else {
					let index = this.unit.activities.findIndex(x=>x.activity_id == o.activity_id)
					this.unit.activities.splice(index, 1, o)
				}
			}

			// immediately save. we have to use the second parameter to force an actual save, because the unit data itself may not have changed (since all we store is the item id)
			this.save_edits(false, true)
		},

		duplicate_item(item) {
			// note that this is almost identical to the fn in MyContentView2
			// for a lesson, we can just use share_lesson
			if (item.type == 'lesson') {
				this.duplicate_lesson_data = item.o
			} else {
				// Call a service to get a list of the exercises from the activity
				U.loading_start()
				U.ajax('get_activity_exercise_list', {user_id: this.user_info.user_id, tool_activity_id: item.o.tool_activity_id}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
					}

					// set duplicate_activity_data to show the DuplicateActivity interface
					result.activity_data.exercise_records = result.exercises
					this.duplicate_activity_data = result.activity_data
					this.duplicate_activity_data.original_activity_id = item.o.activity_id
					console.log('get_activity_exercise_list', result)
				});
			}
		},

		duplicate_activities_created(activities, archive_original_activity_id = 0) {
			// push newly-created activity(s) to the unit
			for (let activity of activities) {
				this.unit.activities.push(new Activity(activity))
			}
			// save unit edits right away
			this.save_edits(false)

			if (archive_original_activity_id !== 0) {
				let payload = {
					user_id: this.user_info.user_id,
					activity_id: archive_original_activity_id,
				}
				U.loading_start()
				U.ajax('archive_activity', payload, result => {
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert('Error in ajax call: ' + result.status);
						vapp.ping();
						return;
					}
					let new_activity = new Activity({activity_id: archive_original_activity_id, course_code: this.lp.course_code})
					this.$store.commit('remove_from_my_activities', new_activity)
					this.$inform('Duplicated activity/activities added to unit library and original archived')
				})
			} else {
				this.$inform('Duplicated activity/activities added to unit library')
			}
			// close the dialog
			this.duplicate_activity_data = null
		},

		duplicate_lesson_created(duplicate_lesson, archive_original = false) {
			let payload = {
				user_id: this.user_info.user_id,
				creator_user_id: this.user_info.user_id,
				course_code: this.lp.course_code,
				lp_unit_id: this.unit.lp_unit_id,
				template_id: 'none',	// this ensures the shared item won't be added to the user's "my" area
				item_id: duplicate_lesson.lesson_id,
				new_lesson_title: duplicate_lesson.new_lesson_title,
			}
			U.loading_start()
			U.ajax('add_shared_lesson', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					this.$alert('Error in ajax call: ' + result.status);
					vapp.ping();
					return;
				}

				this.unit.lessons.push(new Lesson(result.lesson))
				this.save_edits(false)
				if (archive_original) {
					U.loading_start()
					U.ajax('archive_lesson', {user_id: this.user_info.user_id, lesson_id: duplicate_lesson.lesson_id}, result=>{
						U.loading_stop()
						if (result.status != 'ok') {
							this.$alert('Error in ajax call: ' + result.status);
							vapp.ping();
							return;
						}
						let index = this.my_lessons.findIndex(x => x.lesson_id == duplicate_lesson.item_id)
						this.my_lessons.splice(index, 1)
						this.$inform('Duplicate lesson added to unit library and original lesson archived')
					})
				} else {
					this.$inform('Duplicated lesson added to unit library')
				}
			})
			// close the dialog
			this.duplicate_lesson_data = null
		},

		remove_all_lessons_activities(which) {
			this.$confirm({
			    title: 'Are you sure?',
			    text: `Are you sure you want to remove all ${which} from the unit library?`,
			    acceptText: 'Remove ' + which,
				acceptColor: 'red',
			}).then(y => {
				this.unit[which] = []
				this.save_edits(false)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		// Standards
		align_to_standard(start_item) {
			// standards_showing should always be true when we're aligning
			this.standards_showing = true

			// we currently save unit standards with the LP_Unit_Standard class, though the only thing we use from that class is the case_item
			// also note that the case_item includes the framework_identifier, for standards chosen starting 1/2023
			// there are a number of places below where use, e.g., "standard.case_item" for this reason
			if (start_item?.case_item) start_item = start_item.case_item

			let data = { framework_identifier: '', item_identifier: '' }

			if (start_item?.framework_identifier) {
				data.framework_identifier = start_item.framework_identifier
			} else {
				// trace up through parents looking for a case_framework_identifier value; we should find it in in CourseView
				let parent = this.$parent
				while (parent) {
					if (parent.case_framework_identifier) { data.framework_identifier = parent.case_framework_identifier; break; }
					parent = parent.$parent
				}
			}

			if (start_item?.identifier) {
				data.item_identifier = start_item.identifier
			} else {
				// trace up through parents looking for a course_case_identifier value; we should find it in in CourseView
				let parent = this.$parent
				while (parent) {
					if (parent.course_case_identifier) { data.item_identifier = parent.course_case_identifier; break; }
					parent = parent.$parent
				}
			}

			// add current unit standards as selected items
			if (this.unit.standards.length > 0) {
				data.selected_items = []
				for (let standard of this.unit.standards) data.selected_items.push(standard.case_item.identifier)
			}

			let show_data = { 
				// set embed_hide_callback_fn to toggle aligning_to_standards off when the user closes the chooser
				embed_hide_callback_fn: ()=>{ this.aligning_to_standards = false },
				// set hide_fn to hide the standards chooser if/when the editor is no longer visible
				hide_fn: ()=>{ return ($(vapp.course_unit_editor?.$el).is(':visible') == false) },
			}

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// if we already have this item aligned, remove the standard
						let i = this.unit.standards.findIndex(o=>o.case_item.identifier==aligned_item.cfitem.identifier)
						if (i > -1) {
							this.unit.standards.splice(i, 1)
							// re-initialize the chooser, showing the framework for the item we removed
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})

						} else {
							// Add the standard and the framework_identifier, currently using the LP_Unit_Standard construct
							aligned_item.cfitem.framework_identifier = aligned_item.framework_identifier
							let o = new LP_Unit_Standard({identifier: aligned_item.cfitem.identifier, case_item: aligned_item.cfitem})
							this.unit.standards.push(o)
							// re-initialize the chooser, showing the framework for the item we added
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})
						}
						
						// save immediately, unless this is a new lesson -- currently can't do this, as this editor always closes on save
						// if (!this.is_new_lesson) this.save_lesson('no_spinner')
					})
				})
			}).catch(()=>this.finish_aligning_to_standards())	// this will execute when the standards are hidden

			this.aligning_to_standards = true
		},

		remove_standard(standard) {
			let i = this.unit.standards.findIndex(o=>o==standard)
			if (i > -1) this.unit.standards.splice(i, 1)
		},

		finish_aligning_to_standards() {
			console.log('finish_aligning_to_standards!!!')
			vapp.$refs.satchel.execute('hide')
			this.aligning_to_standards = false
		},

		drag_complete(evt) {
			// standards will have been reordered; just need to save
			// currently can't save, as this editor always closes on save
			// if (!this.is_new_lesson) this.save_lesson('no_spinner')
		},

		hide_case_tree() {
			// might need this...
		},

		unit_has_changed() {
			return JSON.stringify(this.unit.copy_for_save()) != this.initial_values
		},

		save_edits(and_close, force_save) {
			// by default, we close after saving, but let caller specify not to
			if (and_close !== false) and_close = true

			return new Promise((resolve, reject)=>{
				if (!force_save && !this.unit_has_changed()) {
					console.log('nothing changed (unit editor), so not saving')
					this.cancel_editor()
					resolve()
					return
				}

				// we no longer require a title or duration
				// if (empty(this.unit.title)) {
				// 	this.$alert('The unit must have a number (e.g. “Unit 1”).')
				// 	reject()
				// 	return
				// }

				if (empty(this.unit.description)) {
					this.$alert('The unit must have a description (e.g. “Matter”).')
					reject()
					return
				}

				// if (isNaN(this.unit.duration)) {
				// 	this.$alert('Please specify a numeric number of weeks for the unit (e.g. “3” or “4.5”).')
				// 	reject()
				// 	return
				// }

				this.$store.commit('set', [this.original_unit, 'title', this.unit.title])
				this.$store.commit('set', [this.original_unit, 'duration', this.unit.duration])
				this.$store.commit('set', [this.original_unit, 'description', this.unit.description])
				this.$store.commit('set', [this.original_unit, 'text', this.unit.text])
				this.$store.commit('set', [this.original_unit, 'standards', this.unit.standards])
				this.$store.commit('set', [this.original_unit, 'resource_collection_inclusions', this.unit.resource_collection_inclusions])
				this.$store.commit('set', [this.original_unit, 'resources', this.unit.resources])
				this.$store.commit('set', [this.original_unit, 'lessons', this.unit.lessons])
				this.$store.commit('set', [this.original_unit, 'activities', this.unit.activities])

				this.$store.dispatch('save_learning_progression', this.lp).then(()=>{
					if (and_close) {
						this.cancel_editor('updated')
					}
					resolve()
				})
			})
		},

		cancel_editor(edit_action) {
			if (typeof(edit_action) !== 'string') edit_action = 'cancelled'
			this.$store.dispatch('lp_edit_close_socketmsg', {edit_action: edit_action, course_code: this.lp.course_code})

			// make sure case tree is hidden before we close
			this.hide_case_tree()
			this.$nextTick(()=>this.$emit('editor_cancel'))
		},

		process_upg_btn_clicked() {
			// prompt for file to import
			this.$prompt({
				text: '<p>Open the Unit Planning Guide document in Microsoft Word, choose “Save As” from the File menu, then save the document in “Web Page, filtered (.htm)” format. Then use the file upload interface below to find and process the .htm file you just saved:</p>',
				promptType: 'file',
				acceptText: 'Process File',
				cancelText: 'Cancel'
			}).then(file => {
				if (file.type != 'text/html') {
					this.$alert('You must save the file in “Web Page, filtered (.htm)” format, which will result in a file that ends in “.htm” or “.html” The file you attempted to process doesn’t have the right extension.').then(()=>this.show_import_btn_clicked())
					return
				}

				let reader = new FileReader()
				reader.onload = e => {
					this.process_upg_html(e.target.result)
				}
				reader.readAsText(file)

			}).catch(n=>{console.log(n)}).finally(f=>{})

		},

		process_upg_resource(saved_resource_urls, url, description) {
			// deal with issues where links are http sometimes and https other times
			let url_comp = url.replace(/^https/, 'http')

			// don't repeat resources we've already tried to save
			if (saved_resource_urls.find(x=>x==url_comp)) {
				console.log('skipping duplicate(1): ' + url)
				return
			}

			// don't repeat resources we already have in unit.resources...
			for (let i = 0; i < this.unit.resources.length; ++i) {
				let r = this.unit.resources[i]
				if (r.url == url) {
					let url_comp_i = r.url.replace(/^https/, 'http')
					let desc_comp_i = r.description.replace(/^https/, 'http')
					// ... unless the url is the same as the description (allowing for http: / https: differences)...
					if (url_comp_i == desc_comp_i) {
						// in this case, we will go ahead and try to save, in case the description (and/or other properties) have been updated for the resource
						// (this was needed as of 7/24 to deal with some legacy issues)
						// if we're going to resave, splice the resource from unit.resources, because we'll add it back in below, after it's saved
						this.unit.resources.splice(i, 1)
						--i

					} else {
						console.log('skipping duplicate(2): ' + url)
						return
					}
				}
			}

			saved_resource_urls.push(url_comp)

			let rtype = 'website'
			// mark google docs as documents
			if (url.indexOf('drive.google.com') > -1 || url.indexOf('docs.google.com') > -1) rtype = 'document'

			// youtube = video
			if (url.indexOf('youtube\.com/watch') > -1 || url.indexOf('youtu.be/') > -1) rtype = 'video'

			let teacher_facing = true	// set this to true by default -- this is the safer option

			// look for additional operator-set resource import rules
			if (this.resource_import_rules.length > 0) {
				for (let rule of this.resource_import_rules) {
					if (url.indexOf(rule.pattern) > -1) {
						if (rule.teacher_facing == 'false') teacher_facing = false
						if (!empty(rule.type)) rtype = rule.type
					}
				}
			}

			// create and save the resource
			let res = new Resource({
				resource_id: 'import',
				type: rtype,
				url: url,
				description: description,
				district_sanctioned: true,
				teacher_facing: teacher_facing,
				shareable: true,
			})

			console.log('found link: ' + url)

			// DEBUG
			// this.unit.resources.push(new Resource(res)); return;

			this.$store.dispatch('save_resource', {resource: res}).then((resource_data)=>{
				// if this resource was loaded earlier, it might have been updated, so use the data we got back to add it to unit.resources
				this.unit.resources.push(new Resource(resource_data))
			})
		},

		process_upg_html(html) {
			// get the html and find the "WordSection1" node
			let nodes = $.parseHTML(html)
			let jq
			let found_word_section = false
			for (let node of nodes) {
				jq = $(node)
				if (jq.hasClass('WordSection1')) {
					console.log("FOUND WORDSECTION1")
					found_word_section = true

					// pull out all links to use as resources
					let saved_resource_urls = []
					jq.find('a').each((i,el)=>{
						let description = $.trim($(el).text().replace(/\s+/g, ' '))
						let url = $(el).attr('href')

						if (!empty(url) && url.indexOf('6230') > -1) {
							debugger
						}

						if (empty(description) || empty(url)) {
							console.log('NO DESCRIPTION OR URL', url, description)
							return
						}

						this.process_upg_resource(saved_resource_urls, url, description)
					})

					// look for additional urls that aren't in anchor tags
					let url_re = /\b(http(s)?:\/\/.+?)([\s<'"])/g
					let url_arr
					while ((url_arr = url_re.exec(html)) !== null) {
						let url = url_arr[1]
						this.process_upg_resource(saved_resource_urls, url, url)
					}
				}
			}

			if (!found_word_section) { this.$alert('Error parsing file (1)'); return; }
		},

		edit_resource_import_rules() {
			let s = ''
			for (let rule of this.resource_import_rules) {
				s += sr('$1: ', rule.pattern)
				if (rule.teacher_facing == 'true') s += 'teacher '
				if (rule.teacher_facing == 'false') s += 'student '
				if (!empty(rule.type)) s += rule.type
				s += '\n'
			}
			this.$prompt({
				title: 'Resource Import Rules',
				text: 'Format:<pre class="mt-1 ml-3 mb-3">pattern: [teacher|student] [document|video|interactive]</div>',
				promptType: 'textarea',
				initialValue: s,
				acceptText: 'OK',
				dialogMaxWidth: 800,
			}).then(resource_import_rules => {
				// process rules; make sure they're all valid
				let lines = resource_import_rules.split('\n')
				let rules = []
				for (let line of lines) {
					line = $.trim(line)
					if (empty(line)) continue
					if (line.search(/(.*?): *(teacher|student|document|video|interactive)( *(document|video|interactive))?/) == -1) {
						this.$alert('Malformed rule line:<br><br>' + line)
						return
					}
					let p1 = RegExp.$2
					let p2 = RegExp.$4
					let rule = {pattern: RegExp.$1}
					if (p1 == 'teacher' || p1 == 'student') {
						rule.teacher_facing = (p1 == 'teacher') + ''	// convert to 'true' or 'false'
					}
					if (p1 == 'document' || p1 == 'video' || p1 == 'interactive') {
						rule.type = p1
					} else if (p2 == 'document' || p2 == 'video' || p2 == 'interactive') {
						rule.type = p2
					}
					rules.push(rule)
				}
				console.log(rules)

				this.resource_import_rules = rules
				U.local_storage_set('district_portal_resource_import_rules', rules)
			}).catch(n=>{console.log(n)}).finally(f=>{});

		},
	}
}
</script>

<style lang="scss">
.k-lp-unit-editor-wrapper {
	padding:15px;
	font-size:16px;

	.k-lp-editor-resources-header {
		font-size:14px;
		font-weight:bold;
		color:#666;
	}

	.k-lp-editor-standard-resource {
		padding:5px;
		margin:10px 0;
		border-radius:4px;
		background-color:#eee;
	}

	.k-lp-editor-standard-description {
		flex:1 1 auto;
		cursor:pointer;
		font-size:14px;
		line-height:18px;
		// margin-bottom:8px;
		// overflow:hidden;
		// white-space:nowrap;
	}

	.k-lp-editor-standard-text {
		margin-top:8px;
		padding-top:8px;
		border-top:1px solid #ccc;
		font-size:14px;
		line-height:18px;
	}

	.k-lp-editor-resource-creator {
		background-color:#eee;
		margin-top:10px;
		padding:10px;
		border-radius:4px;
	}

	.k-lp-standard-editor-froala-wrapper {
		background-color:#fff;
		border:1px solid #666;
		padding:0px 8px;
		margin-top:5px;
		border-radius:5px;
	}
}

</style>
