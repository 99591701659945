<template><div>
	<v-dialog v-model="dialog_open" max-width="800" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Search Help</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<p><b>General notes:</b></p>
				<ul>
					<li>All searches are case-insensitive (e.g. “reading” matches “reading” or “Reading”; “RL” matches “RL” or “rl”.</li>
					<li>Unless you use quotes (see below), partial words will be matched (e.g. “read” will match both “read” and “reading”).</li>
					<li>Each standard statement and human-readable code (e.g. “RL.5.1”) will be searched.</li>
					<li>Standards marked as matched or parked will always be shown, regardless of whether or not they meet the search criteria</li>
					<li>Characters other than letters, numbers, and a small number of punctuation marks will be ignored in search terms. Punctuation marks that <i>can</i> be used in search terms are: “&nbsp;<b>,</b>&nbsp;” (comma), “&nbsp;<b>;</b>&nbsp;” (semicolon), and “&nbsp;<b>'</b>&nbsp;” (single-quote). (As noted below, some symbols serve as search directives: “&nbsp;-&nbsp;” and “&nbsp;*&nbsp;”.)</li>
				</ul>

				<p class="mt-5"><b>Sample searches illustrating search directives:</b></p>

				<table class="k-search-help-table">
					<thead>
						<tr>
							<th class="k-search-help--search-terms">Enter this in the search box…</th>
							<th>…to limit results to these standards</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="k-search-help--search-terms">read</td>
							<td>standards that include “read” (or, e.g. “reading”; see note above)</td>
						</tr>
						<tr>
							<td class="k-search-help--search-terms">read writ RL</td>
							<td>standards that include “read” AND “writ” AND “RL”</td>
						</tr>
						<tr>
							<td class="k-search-help--search-terms">"read" OR "RL"</td>
							<td>standards that include “read” (but not, e.g., “reading”) OR include “RL”</td>
						</tr>
						<tr>
							<td class="k-search-help--search-terms">reading RL OR writing</td>
							<td>standards that include (“reading” AND “RL”) OR include “writing”</td>
						</tr>
						<tr>
							<td class="k-search-help--search-terms">"fluid reading"</td>
							<td>standards that include the phrase “fluid reading” (that is, the words “fluid” and “reading” in that order; e.g. “fluid writing and reading” would not be matched)</td>
						</tr>
						<tr>
							<td class="k-search-help--search-terms">-writing</td>
							<td>standards that DO NOT include “writing”</td>
						</tr>
						<tr>
							<td class="k-search-help--search-terms">topic -"writing projects"</td>
							<td>standards that DO include “topic” but do NOT include “writing projects”</td>
						</tr>
						<tr>
							<td class="k-search-help--search-terms">"read*aloud"</td>
							<td>standards that include a phrase that stars with “read”, followed by any number of characters, followed by “aloud” (e.g. “read aloud”, “reading aloud”, “reading dialog aloud”)</td>
						</tr>
						<tr>
							<td class="k-search-help--search-terms">RL*5</td>
							<td>standards that include “RL”, followed by any number of characters, followed by “5” (e.g. “RL.1.5”, “RL.2.5”, “RL.K.5”)</td>
						</tr>
					</tbody>
				</table>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('dialog_cancel')">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
	},
	data() { return {
		dialog_open: true,
	}},
	computed: {
	},
	created() {

	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
.k-search-help-table {
	border-collapse:collapse;
	td, th {
		border:1px solid #ccc;
		padding:6px 10px;
		vertical-align:top;
		text-align:left;
		// color:#fff;
	}
	th {
		background-color:#777;
		color:#fff;
	}
	.k-search-help--search-terms {
		white-space:nowrap;
		font-weight:bold;
		// text-align:center;
	}
}
</style>
