<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="xk-collection-unit">
	<!-- <div class="xk-collections-unit-description fr-view elevation-3" v-if="unit.text" v-html="unit.text"></div> -->
	<div class="xk-collection-unit-wrapper" :class="collection_unit_css">
		<ResourceCollectionView
			:collection="resource_collection"
			:search_results="search_results"
			:enable_gc_link="false"
			:enable_hc_assignment="false"
			:enable_resource_edit_link="false"
			:enable_resource_remove_link="false"
			:enable_report_issue="true"
			:enable_collection_edit_link="is_collection_admin"
			@report_issue="report_issue"
			@edit_resource_collection="edit_collection_unit"
		/>
	</div>
	<CollectionUnitEdit ref="unit_editor_component" v-if="show_unit_editor" :collection="collection" :original_unit="unit" @editor_cancel="collection_unit_edit_requested=false" />
	<TodoReportCollectionUnit v-if="show_todo_report" :collection="collection" :unit="unit" @dialog_cancel="show_todo_report=false" />
</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import ResourceCollectionView from '../resources/ResourceCollectionView'
import CollectionUnitEdit from './CollectionUnitEdit'
import TodoReportCollectionUnit from './../reports/TodoReportCollectionUnit'

export default {
	components: { ResourceCollectionView, CollectionUnitEdit, TodoReportCollectionUnit, },
	props: {
		collection: { type: Object, required: true },
		unit: { type: Object, required: true },
		search_results: { type: Array, required: true },
		is_collection_admin: { type: Boolean, required: true },
	},
	data() { return {
		show_todo_report: false,
		collection_unit_edit_requested: false,
		// TODO: allow for nesting?
		folder_id: 'top',
	}},
	watch: {
	},
	computed: {
		...mapState(['user_info', 'lp_edit_locked']),
		can_view_report() {
			return vapp.has_admin_right('pd_rep.any')
		},
		resource_collection() {
			// TODO: I think we have to use a special algorithm to sort the resources here...
			let arr = []
			// for each resource...
			for (let resource of this.unit.resources) {
				// console.log(resource.description)
				// look for a folder_assignment for the resource
				let folder_assignment = this.unit.resource_tree.folder_assignments.find(x=>x.resource_id==resource.resource_id)

				// if not found and we're doing the 'top' folder, put the resource at the end of the folder
				if (empty(folder_assignment) && this.folder_id == 'top') {
					folder_assignment = {
						resource_id: resource.resource_id,
						parent_folder_id: 'top',
						seq: this.get_max_folder_sequence('top') + 1
					}
					this.$store.commit('set', [this.unit.resource_tree.folder_assignments, 'PUSH', folder_assignment])
				}

				// if this resource goes in this folder, add it in seq order
				if (oprop(folder_assignment, 'parent_folder_id') == this.folder_id) {
					arr[folder_assignment.seq] = resource
				}
			}

			// now deal with sub-folders
			for (let folder of this.unit.resource_tree.folders) {
				if (folder.parent_folder_id == this.folder_id) {
					// create a "fake resource" for the folder
					arr[folder.seq] = {
						type: 'folder',
						resource_id: folder.folder_id
					}
				}
			}

			// remove empty vals, which can happen if/when items are deleted
			for (let i = arr.length-1; i >= 0; --i) {
				if (empty(arr[i])) {
					arr.splice(i, 1)
				}
			}

			let o = {
				uuid: U.new_uuid(),
				title: this.unit.title,
				items: arr,
				// items: this.unit.resources,
				initially_expanded: false,
			}
			return o
		},
		show_unit_editor() {
			// show the editor if the user has requested to edit and the lp isn't locked for editing (lp_edit_locked[this.course_code] must have been explicitly set to false)
			if (this.collection_unit_edit_requested != true) return false
			if (empty(this.lp_edit_locked[this.collection.course_code]) || this.lp_edit_locked[this.collection.course_code] === true) return false
			return true
			// return this.collection_unit_edit_requested == true && this.lp_edit_locked[this.collection.course_code] != true
		},
		collection_unit_css() {
			return U.subject_tile_css(this.collection) + '-resource-collection'
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		narrow_window() {
			return $(window).width() < 1000
		},
		edit_collection_unit() {
			// if the lp is already saved (i.e. it isn't brand new), we have to request to edit it, to make sure no one else already has it opened for editing
			if (!empty(this.collection.course_code) && this.collection.lp_id != 0) {
				this.$store.dispatch('lp_edit_socketmsg', this.collection.course_code)
			}

			// flag that we've requested to edit the LP; once the socket message comes back saying we're OK to edit, the show_lp_editor computed will open it for editing
			this.collection_unit_edit_requested = true
		},
		// send issue report requests to vapp
		report_issue(issue_params) {
			// issue_params comes in with resource specified; add lp and unit
			issue_params.learning_progression = this.collection
			issue_params.lp_unit = this.unit
			vapp.report_issue(issue_params)
		},
	}
}
</script>

<style lang="scss">

.xk-collections-unit-description {
	font-size:14px;
	line-height:19px;
	padding:4px 8px;
	margin:16px 16px 0px 16px;
	border-radius:8px;
	border:1px solid #ccc;
}

.xk-collection-unit-wrapper {
}

.xk-collection-unit-wrapper {

}

</style>
