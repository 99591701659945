<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-cv-unit-wrapper-outer mx-auto" style="max-width:800px">
	<div>
		<div class="k-lp-unit-wrapper">
			<div v-if="unit.standards.length==0" class="pa-2 text-center"><i>This unit does not have any standards specified.</i></div>
			<div class="mx-1 my-2" v-if="unit.standards.length>0">
				<div class="k-lp-unit-section-header">Unit Standards</div>

				<div v-for="(o) in unit_standards_tree">
					<div class="k-lp-unit-standard" :class="o.standard.case_item.humanCodingScheme?'':'k-lp-unit-standard-top-category'">
						<div class="k-lp-unit-standard-statement-wrapper" :style="o.children.length>0?'border-top:1px solid #ccc; padding-top:8px;':''">
							<v-btn v-visible="o.children.length>0" icon x-small style="margin-right:6px" @click.stop="toggle_standard_children(o.standard.identifier)"><v-icon style="font-size:18px" color="deep-orange darken-2">{{standard_children_showing[o.standard.identifier] ? 'fas fa-circle-chevron-down' : 'fas fa-circle-chevron-right'}}</v-icon></v-btn>
							<div class="k-lp-unit-standard-statement" @click.stop="standard_clicked(o.standard.case_item)">
								<b v-if="o.standard.case_item.humanCodingScheme" v-html="o.standard.case_item.humanCodingScheme"></b> &nbsp;
								<span class="k-lp-unit-standard-statement_inner" v-html="o.standard.case_item.fullStatement"></span>
								<v-btn v-if="o.standard.text" x-small color="brown" style="letter-spacing:0" text @click.stop="toggle_standard_text(o.standard.identifier)"><b>Learning Targets / Notes</b></v-btn>
								<v-icon v-if="show_standard_search" class="ml-2" color="brown" small @click.stop="standard_search(o.standard.case_item)">fas fa-search</v-icon>
							</div>
						</div>
						<v-expand-transition><div v-show="o.standard.text && standard_text_showing[o.standard.identifier]" class="k-lp-unit-standard-text fr-view">
							<div v-html="o.standard.text"></div>
						</div></v-expand-transition>

						<div v-if="o.children.length>0" class="k-lp-unit-standard-children">
							<v-expand-transition><div v-show="standard_children_showing[o.standard.identifier]">
								<div v-for="(standard) in o.children" class="k-lp-unit-standard">
									<div class="k-lp-unit-standard-statement-wrapper">
										<div class="k-lp-unit-standard-statement" @click.stop="standard_clicked(standard.case_item)">
											<b v-if="standard.case_item.humanCodingScheme"><span v-html="standard.case_item.humanCodingScheme"></span> &nbsp;</b> <span class="k-lp-unit-standard-statement_inner" v-html="standard.case_item.fullStatement"></span>
											<v-btn v-if="standard.text" x-small color="brown" style="letter-spacing:0" text @click.stop="toggle_standard_text(standard.identifier)"><b>Learning Targets / Notes</b></v-btn>
											<v-icon v-if="show_standard_search" class="ml-2" color="brown" small @click.stop="standard_search(standard.case_item)">fas fa-search</v-icon>
										</div>
									</div>
									<v-expand-transition><div v-show="standard.text && standard_text_showing[standard.case_item.identifier]" class="k-lp-unit-standard-text fr-view">
										<div v-html="standard.text"></div>
									</div></v-expand-transition>
								</div>
							</div></v-expand-transition>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
		lp: { type: Object, required: true },
		unit: { type: Object, required: true },
		// is_sis_course: { type: Boolean, required: true },
		// is_lp_admin: { type: Boolean, required: true },
	},
	data() { return {
		standard_text_showing: {},
		standard_children_showing: {},
	}},
	computed: {
		...mapState(['user_info']),
		case_framework_identifier() {
			if (this.lp.subject_case_identifier) return this.lp.subject_case_identifier
			return this.$store.state.subjects[this.lp.subject]	// may return null
		},
		framework_record() {
			let o = this.$store.state.framework_records.find(x=>x.lsdoc_identifier == this.case_framework_identifier)
			if (empty(o)) return {}
			else return o
		},
		unit_standards_tree() {
			let arr = []
			let current_parent = null
			let current_parent_hcs = ''
			let standard_children_showing = {}

			for (let standard of this.unit.standards) {
				let hcs = standard.case_item.humanCodingScheme
				standard_children_showing[standard.identifier] = true

				if (!empty(current_parent_hcs) && hcs.indexOf(current_parent_hcs) > -1) {
					current_parent.children.push(standard)
					continue
				}

				let o = {
					standard: standard,
					children: [],
				}
				arr.push(o)
				current_parent = o
				current_parent_hcs = hcs
			}

			// all children should be showing by default
			this.standard_children_showing = standard_children_showing

			return arr
		},
		show_standard_search() {
			// for now only show standard search button to test users, and if show_safari is on
			if (vapp.user_info.email == 'teacher@henry.k12.ga.us') return true
			if (vapp.user_info.email == 'admin@henry.k12.ga.us') return true
			if (this.$store.getters.show_safari) return true
			return false
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		standard_clicked(case_item) {
			// else show the framework, highlight this item, and send in selected_items and limit_to_selected_items if there
			let data = { 
				framework_identifier: case_item.framework_identifier, 
				item_identifier: case_item.identifier, 
				no_framework_reset: true,
			}

			if (!data.framework_identifier) {
				// if case_item doesn't have a framework_identifier, it was probably selected before we started recording this.
				// so trace up through parents looking for a case_framework_identifier value; we should find it in in CourseView
				let parent = this.$parent
				while (parent) {
					if (parent.case_framework_identifier) { data.framework_identifier = parent.case_framework_identifier; break; }
					parent = parent.$parent
				}
			}

			// add all unit standards as selected_items
			if (this.unit.standards.length > 0) {
				data.selected_items = []
				for (let standard of this.unit.standards) data.selected_items.push(standard.case_item.identifier)
				data.limit_to_selected_items = 'only'
			}

			vapp.$refs.satchel.execute('show').then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
				})
			})
		},

		toggle_standard_children(identifier) {
			if (!this.standard_children_showing[identifier]) {
				this.$set(this.standard_children_showing, identifier, true)
			} else {
				this.standard_children_showing[identifier] = false
			}
		},

		toggle_standard_text(identifier) {
			if (!this.standard_text_showing[identifier]) {
				this.$set(this.standard_text_showing, identifier, true)
			} else {
				this.standard_text_showing[identifier] = false
			}
		},

		standard_search(case_item) {
			let o = {
				tool: 'safari',
				// endpoint: 'https://montage.henry.k12.ga.us/SAFARI/api/imslti.php',
				endpoint: this.$store.state.safari_lti_endpoint,
				search_params: {}
			}
			// send grade low/high
			if (!empty(this.lp.grade_low)) o.search_params.fromgrade = this.lp.grade_low
			if (!empty(this.lp.grade_high)) o.search_params.tograde = this.lp.grade_high

			// send CASE identifier
			o.search_params.standardsparentguid = case_item.identifier
			console.log(o)
			this.$store.dispatch('lti_launch', o)
		},
	}
}
</script>

<style lang="scss">
.k-lp-unit-standard {
	// border-top:1px solid #ccc;
	// border-radius:5px;
	padding-top:8px;
	margin:0 12px;
	font-size:14px;
	line-height:18px;
}

.k-lp-unit-standard-top-category {
	font-weight:bold;
	color:$v-deep-orange-darken-4;
	margin-top:8px;
}

.k-lp-unit-standard-statement-wrapper {
	display:flex;
	align-items: flex-start;
}

.k-lp-unit-standard-statement {
	cursor:pointer;
}
.k-lp-unit-standard-statement_inner:hover {
	text-decoration:underline;
}

.k-lp-unit-standard-children {
	margin-left:26px;
}

.k-lp-unit-standard-text {
	font-style:italic;
	color:#666;
	font-weight:bold;
	font-size:0.9em;
	line-height:1.3em;
	padding-left:32px;
}
</style>
