// https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Inheritance

class User_Course {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'course_code', '')
		sdp(this, data, 'course_sourcedIds', [])
		sdp(this, data, 'class_sourcedIds', [])
		sdp(this, data, 'titles', [])
		sdp(this, data, 'periods', [])
		sdp(this, data, 'format', 'T', ['T', 'B'])
		sdp(this, data, 'title_codes', [])
		sdp(this, data, 'classCodes', [])
		sdp(this, data, 'school_sourcedIds', [])
		sdp(this, data, 'term_sourcedIds', [])
		sdp(this, data, 'students', [])
		sdp(this, data, 'teachers', [])

		// use the first titles entry as the title (they should all be the same)
		this.title = this.titles[0]
	}

	period_matches_term(index, term) {
		for (let p of this.periods[index]) {
			if (p.search(/^T(\d+)-.*?-(\d+)$/) > -1) {
				// record period if/when we find a period value that matches term (unless term is 0)
				if (!term || term == 0 || RegExp.$1*1 == term) {
					return true
				}
			}
		}
		return false
	}

	class_matches_term(term) {
		for (let index = 0; index < this.periods.length; ++index) {
			for (let p of this.periods[index]) {
				if (p.search(/^T(\d+)-.*?-(\d+)$/) > -1) {
					// record period if/when we find a period value that matches term (unless term is 0)
					if (!term || term == 0 || RegExp.$1*1 == term) {
						return true
					}
				}
			}
		}
		return false
	}

	section_title(index, params) {
		// construct section title, matching coding from IC
		let periods = []
		let terms = []
		for (let ps of this.periods[index]) {
			if (ps.search(/^T(\d+)-.*?-(\d+)$/) > -1) {
				let t = RegExp.$1*1
				let p = RegExp.$2
				// record period if/when we find a period value that matches term (unless term is 0)
				if (!params.term || params.term == 0 || t == params.term) {
					if (!periods.includes(p)) periods.push(p)
				}
				if (!terms.includes(t)) terms.push(t)
			} else {
				console.log('unknown format for Period: ' + ps)
			}
		}

		let start = ''
		if (params.html) start += '<b>'
		if (periods.length > 0) start += sr('P$1 ', periods.join(','))
		if (terms.length > 0 && params.term == 0) start += sr('T$1 ', terms.join(','))
		if (params.html) start += '</b>'

		let section_title = ''
		if (params.html) section_title += '<span style="font-size:80%">'
		let title_code = this.title_codes[index]
		section_title += sr('$2.$3 - $4', start, title_code[0], title_code[1], title_code[2])
		if (params.title) section_title += sr(' $1', this.titles[index])
		if (params.teacher) section_title += sr(' ($1)', this.classCodes[index])	// classCode == teacher

		if (params.student_count === true && this.students[index]) {
			if (params.html) section_title += '<i>'
			section_title += sr(' ($1 $2)', this.students[index].length, U.ps('student', this.students[index].length))
			if (params.html) section_title += '</i>'
		}

		if (params.html) section_title += '</span>'

		return start + section_title

		// title_code has three vals: course_code, "instruction type" (see below), and section number
		// instruction type 0 = general
		// let instruction_type
		// if (title_code[1]) {
		// 	if (title_code[1][0] == 1) instruction_type = 'Remedial'
		// 	else if (title_code[1][0] == 2) instruction_type = 'Gifted'
		// 	else if (title_code[1][0] == 3) instruction_type = 'Online'
		// 	else if (title_code[1][0] >= 4 && title_code[1][0] <= 6) instruction_type = sr('Lab [$1]', title_code[1][0])
		// 	else if (title_code[1][0] == 7) instruction_type = 'Work-Based Learning'
		// 	else if (title_code[1][0] >= 8 && title_code[1][0] <= 9) instruction_type = 'Special Ed'

		// 	instruction_type = sr(' [$1$2] ', (instruction_type ? instruction_type+'-' : ''), title_code[1])
		// }

		/* from Ben's cheat sheet
			A - First Semester
			B - Second Semester
			T - 9 wk Course
			S - Semester Long Course
			Y - Year Long
			BL - Block
			YBL - Semester Long Course
			SBL - 9 wk Course
		*/
	}

	// return true if all the title_codes indicate instruction_type 2 (gifted)
	is_gifted_course() {
		// if there are no title_codes, return false (not gifted) -- I don't think this should happen
		if (this.title_codes.length == 0) return false

		for (let title_code of this.title_codes) {
			// if any title_code *isn't* 2, it's *not* a gifted only course
			// note that sometimes Social Studies (e.g.) will have a gifted title_code and a general (0) title_code
			if (title_code[1] != 2) return false
		}

		// if we get to here, there was at least one title_code, and all title_codes were 2, so return true
		console.log('found gifted course', this)
		return true
	}
}
window.User_Course = User_Course

class Google_Classroom_Class {
	constructor(data) {
		this.type = 'google_classroom'
		if (empty(data)) data = {}
		sdp(this, data, 'title', '')
		sdp(this, data, 'url', '')
		sdp(this, data, 'id', '')
		sdp(this, data, 'created_at', '')
		sdp(this, data, 'hidden', false)
	}
}
window.Google_Classroom_Class = Google_Classroom_Class

class Schoology_Class {
	constructor(data) {
		this.type = 'schoology'
		if (empty(data)) data = {}
		sdp(this, data, 'title', '')
		sdp(this, data, 'url', '')
		sdp(this, data, 'id', '')
		sdp(this, data, 'created_at', '')
		sdp(this, data, 'hidden', false)
	}
}
window.Schoology_Class = Schoology_Class
