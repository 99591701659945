<template><div v-show="!course_showing||course_showing_is_in_my_courses" class="d-flex justify-center align-stretch" :class="(course_showing&&$vuetify.breakpoint.width>768)?'mx-2':''">
	<MiniNav v-show="course_showing" current_section="classes" />
	<div class="k-main-collection k-cv-main-collection" :class="course_showing?'k-main-collection--item-showing k-cv-main-collection--course-showing':''">
		<div v-if="!course_showing">
			<div class="k-main-collection-header k-page-title text-left d-flex align-center">
				<div class="mr-2"><v-icon large color="primary" class="mr-4">fas fa-chalkboard</v-icon>{{header_text}}</div>
				<v-spacer/>
				<v-btn v-if="manage_assignments" class="mr-2" color="secondary" @click="show_all_lps"><v-icon class="mr-2">fas fa-chalkboard</v-icon>More Courses…</v-btn>
				<v-menu eager bottom right><template v-slot:activator="{on}"><v-btn v-show="can_view_resource_report" x-show="user_is_admin||user_is_teacher" v-on="on" small fab color="#999" dark><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template>
					<v-list dense>
						<!-- <v-list-item @click="show_all_lps"><v-list-item-icon><v-icon small>fas fa-search</v-icon></v-list-item-icon><v-list-item-title>Search Henry County Learning Progression Index</v-list-item-title></v-list-item> -->
						<v-list-item v-if="can_view_resource_report" @click="resource_report_showing=true"><v-list-item-icon><v-icon small>fas fa-table</v-icon></v-list-item-icon><v-list-item-title>View Resource Usage Reports</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</div>

			<div v-if="false" class="k-lps-unavailable-note"><i>Note that as of 7/15/2021, HenryConnects is serving Learning Progressions for the 2021-2022 school year. Some courses’ Learning Progressions have not yet been finalized for the new school year and therefore are not yet appearing here or in the Learning Progression Index. All courses should be ready and available here by 7/23/2021.</i></div>

			<div v-if="role=='parent'">
				<div v-if="child_count==0">You have not yet designated any students. Choose “Designated Students” from the “PARENT/GUARDIAN” menu above to view information for your students’ classes.</div>
				<div v-else>
					<span style="font-size:1.2em">Viewing courses for student <b class="ml-1">{{child_data_showing.first_name}} {{child_data_showing.last_name}}</b></span>
					<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-if="child_count>1" small v-on="on" class="ml-2" color="secondary"><v-icon small class="mr-2">fas fa-arrow-right-arrow-left</v-icon>Switch To Student…</v-btn></template>
						<v-list>
							<v-list-item v-for="(child_data, child_email) in user_info.child_data" :key="child_email" @click="switch_child_data_showing(child_email)"><v-list-item-title>{{child_data.first_name}} {{child_data.last_name}} ({{child_email}})</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>

				</div>
			</div>
		</div>

		<div v-if="course_showing" class="k-mini-nav-site-section-heading">My Courses</div>

		<div class="k-main-collection-body" :class="course_showing?'':'mt-4'" :style="collection_body_style">
			<div v-if="(role=='staff'||role=='admin')&&my_courses.length==0&&!student_view">
				<i>You do you not have any courses in your “My Courses” view.</i>
				<div v-if="!course_showing"><i>You can browse all courses in the Henry County course catalog by clicking “MORE COURSES” below;<br>you can then add courses to your “My Courses” view from there if you like.</i></div>
			</div>

			<div v-if="my_courses.length==0&&student_view"><i>You are not currently enrolled in any classes.</i></div>

			<div v-if="role=='parent'&&course_showing&&child_data_showing.first_name" class="k-course-list-child-switcher">
				<v-menu bottom left>
					<template v-slot:activator="{on}">
						<div v-on="on" class="mx-2 d-flex align-center text-center elevation-2" style="cursor:pointer; font-size:14px; background-color:rgba(255,255,255,0.5); padding:4px 12px 4px 4px; border-radius:6px;" :style="child_count==0?'padding-left:12px':''">
							<v-btn v-if="child_count>1" small icon color="#999"><v-icon small>fas fa-arrow-right-arrow-left</v-icon></v-btn>
							<div style="font-weight:900">{{child_data_showing.first_name}}<br>{{child_data_showing.last_name}}</div>
						</div>
					</template>
					<v-list dense>
						<v-list-item v-for="(child_data, child_email) in user_info.child_data" :key="child_email" @click="switch_child_data_showing(child_email)"><v-list-item-title>{{child_data.first_name}} {{child_data.last_name}} ({{child_email}})</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</div>


			<!-- <div v-if="role=='parent'&&course_showing&&child_data_showing.first_name" class="mx-2 text-center elevation-2" style="font-size:14px; background-color:rgba(255,255,255,0.5); padding:4px 12px 4px 4px; border-radius:6px;display:flex;" :style="child_count==0?'padding-left:12px':''">
				<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-if="child_count>1" small icon v-on="on" color="#999"><v-icon small>fas fa-arrow-right-arrow-left</v-icon></v-btn></template>
					<v-list>
						<v-list-item v-for="(child_data, child_email) in user_info.child_data" :key="child_email" @click="switch_child_data_showing(child_email)"><v-list-item-title>{{child_data.first_name}} {{child_data.last_name}} ({{child_email}})</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
				<div><span style="font-weight:900">{{child_data_showing.first_name}}<br>{{child_data_showing.last_name}}</span></div>
			</div> -->

			<div v-if="role=='parent'&&my_courses.length==0&&!student_view" class="my-3"><i>This student is not registered for any classes that are viewable in HenryConnects.</i></div>

			<CourseListTile v-for="(course) in my_courses" :key="course.class_sourcedIds[0]"
				:course="course"
				:course_showing="course_showing"
				@show_lp="show_lp"
			></CourseListTile>

			<div v-if="!manage_assignments" class="mt-5 mb-3" :style="course_showing?'':'flex:1 1 100%'">
				<v-btn v-if="!course_showing&&$vuetify.breakpoint.mdAndUp&&(role=='staff'||role=='admin')" class="mt-3" x-large color="primary" href="https://classroom.google.com/?emr=0" target="_blank"><img src="./../../images/google_classroom_logo.png" style="height:30px;margin-left:-4px;margin-right:12px;border-radius:3px;">Open Google Classroom</v-btn>
				<a v-if="course_showing&&$vuetify.breakpoint.mdAndUp&&(role=='staff'||role=='admin')" href="https://classroom.google.com/?emr=0" target="_blank" class="k-course-list-gc-icon ml-3 mr-3"><img src="./../../images/google_classroom_logo.png" style="height:60px;border-radius:5px;"></a>
				<v-btn v-if="!course_showing" class="mt-3 mx-5" x-large color="secondary" @click="show_all_lps"><v-icon class="mr-2">fas fa-chalkboard</v-icon>More Courses…</v-btn>
			</div>

			<div v-if="!course_showing&&manage_assignments" class="my-4 pt-3" style="flex:1 1 100%; border-top:1px solid #999;">
				<div class="d-flex mb-5"><v-spacer/><v-btn-toggle v-if="$vuetify.breakpoint.width>800" class="k-course-view-toggle-btn elevation-3" active-class="k-course-view-toggle-btn-active-class" v-model="lp_my_content_or_messages_mode">
					<v-btn small :large="$vuetify.breakpoint.mdAndUp" class="k-tight-btn k-nocaps-btn" @click="" :value="'course_materials'">&nbsp;&nbsp;<v-icon class="mr-2" :small="$vuetify.breakpoint.smAndDown" :color="(lp_my_content_or_messages_mode=='course_materials')?'#fff':'secondary'">fas fa-shapes</v-icon>Course-Wide Materials</v-btn>
					<v-btn small :large="$vuetify.breakpoint.mdAndUp" class="k-tight-btn k-nocaps-btn" @click="" :value="'content'">&nbsp;&nbsp;<v-icon class="mr-2" :small="$vuetify.breakpoint.smAndDown" :color="(lp_my_content_or_messages_mode=='content')?'#fff':'secondary'">fas fa-rectangle-list</v-icon>Unit Lessons & Activities</v-btn>
					<v-btn small :large="$vuetify.breakpoint.mdAndUp" class="k-tight-btn k-nocaps-btn" @click="" :value="'messages'">&nbsp;&nbsp;&nbsp;<v-icon class="mr-2" :small="$vuetify.breakpoint.smAndDown" :color="(lp_my_content_or_messages_mode=='messages')?'#fff':'secondary'">fas fa-comments</v-icon>{{ messages_button_text }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-btn>
				</v-btn-toggle><v-spacer/></div>

				<div v-if="lp_my_content_or_messages_mode=='content'||lp_my_content_or_messages_mode=='course_materials'">
					<MyContentView2 v-if="manage_assignments" :filtered_origin_teacher_id="filtered_origin_teacher_id" />
					<MyContentView v-if="!manage_assignments" />
				</div>
				<div v-if="lp_my_content_or_messages_mode=='messages'">
					<MessagesView :notification_message_id="notification_message_id" />
				</div>

			</div>

			<AdminResourceUsageDialog v-if="resource_report_showing" @dialog_cancel="resource_report_showing=false" />
		</div>
	</div>


</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CourseListTile from './CourseListTile'
import CourseView from './CourseView'
import MiniNav from '../main/MiniNav'
import MessagesView from '../messages/MessagesView'
import MyContentView from '../mycontent/MyContentView'
import MyContentView2 from '../mycontent/MyContentView2'
import AdminResourceUsageDialog from '../admin/AdminResourceUsageDialog'

export default {
	components: { CourseView, CourseListTile, MiniNav, MessagesView, MyContentView, MyContentView2, AdminResourceUsageDialog, },
	data() { return {
		num_hidden_classes: 0,
		show_hidden_classes: false,
		show_safari_search: false,
		// hackish way of getting access to CourseView components
		lp_components: {},
		classes_initialized: false,
		resource_report_showing: false,
		collection_body_style: '',
		// If we arrived here via a message notification link, pass
		// the message ID to the MessagesView component so it can autoexpand
		notification_message_id: null
	}},
	props: {
		filtered_origin_teacher_id: { required: false, default() { return null } },
	},
	computed: {
		...mapState(['user_info', 'all_courses', 'course_update_trigger', 'sis_classes', 'added_my_courses', 'removed_my_courses', 'child_email_showing', 'messages']),
		...mapGetters(['child_count', 'child_data_showing', 'beta_options', 'my_courses', 'role', 'manage_assignments', 'unread_message_count']),
		messages_button_text() {
			let n = this.unread_message_count('')
			if (n == 0) return 'Message Center'
			else return `Message Center (${n})`
		},
		messages_exist() {
			return this.messages.length > 0
		},
		lp_my_content_or_messages_mode: {
			get() { return this.$store.state.lst.lp_my_content_or_messages_mode },
			set(val) { 
				console.log('lp_my_content_or_messages_mode (CourseList) ', val)
				this.$store.commit('lst_set', ['lp_my_content_or_messages_mode', val]) 
			}
		},
		header_text() {
			if (this.user_info.role == 'parent') return 'My Child’s Courses'
			if (this.user_info.role == 'student') return 'My Courses'
			return 'My Courses'
			// return 'My Learning Progressions'
		},
		course_showing() {
			if (!empty(this.$router.history.current.params, 'course_code')) {
				return this.$router.history.current.params.course_code
			} else {
				return ''
			}
		},
		course_showing_is_in_my_courses() {
			return (this.my_courses.findIndex(x=>x.course_code==this.course_showing) > -1)
		},
		role() {
			return this.user_info.role
		},
		user_is_admin() {
			return this.user_info.role == 'admin'
		},
		user_is_teacher() {
			return this.user_info.role == 'staff'
		},
		student_view() {
			return this.user_info.role == 'student'
		},
		can_view_resource_report() {
			// system admins and users that are principals/vice-principals can view resource usage reports from here
			return this.user_info.role == 'admin' || this.$store.getters.user_is_principal_or_ap
		},
		child_email_showing: {
			get() { return this.$store.state.lst.child_email_showing },
			set(val) { this.$store.commit('lst_set', ['child_email_showing', val]) }
		},
	},
	watch: {
		messages_exist() {
			if (this.messages_exist) {
				this.$nextTick(()=>{
					this.check_for_message_redirect()
				})
			}
		},
	},
	created() {
		if (this.$store.state.loaded_classes) {
			this.classes_initialized = true
		} else if (!this.classes_initialized) {
			this.get_classes()
		}
	},
	mounted() {
		this.resize_course_tiles()
	},
	methods: {
		get_grandparent_message_id(parent_message_id) {
			const parent_message = this.messages?.find(x=>x.message_id==parent_message_id) ?? 0
			const grandparent_message =  this.messages?.find(x=>x.message_id==parent_message.parent_message_id) ?? 0
			if (!grandparent_message) return 0
			return grandparent_message.message_id
		},
		check_for_message_redirect() {
			const params = (new URL(document.location)).searchParams
			// console.log('check_for_message_redirect (2)')
			if (params.get('message_id') != undefined) {
				const message_id = parseInt(params.get('message_id'))
				// make sure the Message Center tab is chosen
				this.lp_my_content_or_messages_mode = 'messages'

				// Find message, add inform 
				const message = this.messages.find(x=>x.message_id==message_id)
				if (!message) return
				const message_date = date.format(new Date(message.send_at*1000), 'MMMM D, YYYY h:mm A')
				vapp.$inform({text: `Message from ${message.first_name} ${message.last_name}, sent ${message_date}`, color: 'pink darken-3' })

				// open the thread that includes the received message_id &
				// scroll to make sure that thread is showing by passing
				// message ID prop to MessagesView->MessageList->Message
				this.notification_message_id = message.message_level == 0 ? message.message_id
					: message.message_level == 1 ? message.parent_message_id
					: this.get_grandparent_message_id(message.parent_message_id)

				U.clear_location_search()
			}
		},

		resize_course_tiles() {
			// for some reason we need to explicitly specify the width of this div to make overflow scrolling work, and we have to use this hackish mechanism to get the width right
			clearTimeout(this.resize_course_tiles_timeout)

			let google_btn_width = $('.k-course-list-gc-icon').outerWidth(true)
			if (!google_btn_width) google_btn_width = 0

			let child_switcher_width = $('.k-course-list-child-switcher').outerWidth(true)
			if (!child_switcher_width) child_switcher_width = 0

			if (this.course_showing) this.collection_body_style = 'width:' + (this.my_courses.length * $(this.$el).find('.k-main-collection-item').first().outerWidth(true) + google_btn_width + child_switcher_width) + 'px'
			else this.collection_body_style = ''

			this.resize_course_tiles_timeout = setTimeout(x=>this.resize_course_tiles(), 200)
		},

		get_classes() {
			this.$store.dispatch('get_classes').then(()=>{
				this.$nextTick(()=>{
					this.classes_initialized = true
				})
			})
		},
		show_all_lps() {
			this.$store.commit('set', ['my_classes_view', 'lpindex'])
		},

		show_lp(course, event, unit_id, assignment_id) {
			if (empty(assignment_id)) assignment_id = 0
			if (empty(unit_id)) unit_id = 0

			// DEBUG: if you hold down the (mac) command and option key while clicking, just show the course data in the console
			if (event.metaKey && event.altKey) {
				this.log_object(course)
				return
			}
			let path = sr('/courses/lp/$1/$2/$3', course.course_code, unit_id, assignment_id)
			if (path != this.$router.currentRoute.path) {
				this.$router.push({ path: path })
			}
		},

		log_object(o) {
			let s = ''
			for (let key in o) {
				s += sr('$1: $2\n', key, JSON.stringify(o[key]))
			}
			console.log(s)
		},

		switch_child_data_showing(child_email) {
			this.child_email_showing = child_email
		},
	}
}
</script>

<style lang="scss">
.k-cv-main-collection {
	// padding-right:20px!important;
	// padding-left:20px!important;
	.k-main-collection-body {
		justify-content:center!important;
	}
}

// .k-cv-main-collection--course-showing, .k-main-collection--item-showing {
.k-main-collection--item-showing {
	display: inline-block;
	margin-top:0!important;
	padding:0px!important;
	overflow: auto;
	width:100vw;
	max-width:100vw;
	margin:0;
	.k-main-collection-body {
		display:flex;
		flex-wrap: nowrap;
		// margin:0px;
		padding-right:0;
		// margin-right:32px;
		// margin-right:24px;
	}
}

// .k-cv-main-collection--course-showing {
// 	display: inline-block;
// 	margin-top:0!important;
// 	padding:0px 10px!important;
// }
</style>
