<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div :class="folder_id!='top' ? 'k-resource-folder' : ''">

	<!-- note that this won't be displayed for the top folder -->
	<div v-if="folder_title" class="k-resource-folder-header mb-2" @click="toggle_folder_open">
		<v-icon class="k-resource-folder-icon mr-2" :class="folder_icon_css" small>fas {{folder_icon}}</v-icon>
		<div class="k-resource-folder-title" v-html="folder_title"></div>
		<div class="k-resource-folder-count" v-if="!folder_open">({{n_resources}} {{n_resources_word}})</div>
		<v-spacer/>
		<v-menu bottom left>
			<template v-slot:activator="{on}"><v-btn v-show="enable_editing" v-on="on" class="ml-1" style="z-index:3" icon small color="primary"><v-tooltip bottom><template v-slot:activator="{on}"><v-icon small v-on="on">fas fa-bolt</v-icon></template>Folder options</v-tooltip></v-btn></template>
			<v-list dense min-width="250">
				<v-list-item @click="edit_folder_title"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit folder title</v-list-item-title></v-list-item>
				<v-list-item @click="remove_folder"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Remove folder</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
		<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" v-show="!enable_editing&&folder_todo_count>0&&!folder_open" class="k-collections-unit-list-item-assignment-total mt-1">{{folder_todo_count}}</div></template>{{folder_todo_count}} {{folder_todo_count==1?'Resource':'Resources'}} To Do</v-tooltip>
	</div>

	<v-expand-transition><div v-if="folder_open" class="k-resource-folder-resources">
		<div v-if="enable_editing && !allow_resource_reordering" class="mb-2">
			<v-btn small class="k-tight-btn" color="primary" text @click="create_resource_start"><v-icon small class="mr-1">fas fa-plus-circle</v-icon>Add Resource</v-btn>
			<!-- allow folders in PD collections only -->
			<v-btn v-if="resource_editor_collection_type=='PD'" small class="ml-2 k-tight-btn" color="primary" text @click="create_resource_folder_start"><v-icon small class="mr-1">fas fa-folder-plus</v-icon>Add Resource Folder</v-btn>
		</div>

		<div v-if="show_resource_creator" class="mb-2">
			<EditResource
				:show_shareable_control="false"
				:show_todo_control="resource_editor_show_todo_control"
				:district_sanctioned="true"
				:collection_type="resource_editor_collection_type"
				:show_tags_control="(resource_editor_collection_type=='PD')"
				:professional_development="resource_editor_professional_development"
				:show_teacher_facing_control="resource_editor_show_teacher_facing_control"
				@edit_resource_cancel="create_resource_cancel"
				@edit_resource_save="create_resource_save"
			/>
		</div>

		<div v-if="folder_resource_list.length>0">
			<div v-if="!tree_view" class="d-flex flex-wrap align-center justify-center">
				<div v-for="(resource, index) in folder_resource_list" class="k-resource-collection-activity-wrapper">
					<ResourceCollectionItem :key="resource.resource_id"
						:item="resource"
						:enable_hc_assignment="false"
						:enable_edit_link="enable_editing"
						:enable_remove_link="enable_editing"
						:enable_report_issue="true"
						@report_issue="report_issue"
						@edit_resource="edit_resource_start(resource)"
						@remove_resource="remove_resource"
					/>
				</div>
			</div>

			<div v-if="tree_view" class="mb-2">
				<draggable v-bind="drag_options" v-model="folder_resource_list" @end="drag_complete" class="k-resource-folder-resources-inner">
					<div v-for="(resource, index) in folder_resource_list" :data-resource_id="resource.resource_id" :class="'k-resource-folder-resource-container-width-' + ((resource.type=='folder' || enable_editing) ? 'full' : 'half')">
						<div v-if="resource.type=='folder'" class="d-flex align-top" :class="index==0?'k-resource-folder-resources-first-child':''">

							<div class="mt-4 mr-2" v-if="enable_editing&&allow_resource_reordering"><v-icon small color="#999" class="moveHandle">fas fa-arrows-alt</v-icon></div>
							<div style="flex:1 1 auto">
								<CollectionResourceFolder
									:key="resource.resource_id"
									:collection="collection"
									:folder_id="resource.resource_id"
									:unit="unit"
									:enable_editing="enable_editing"
									:allow_resource_reordering="allow_resource_reordering"
									:folder_being_edited="folder_being_edited"
									@editing_resource="editing_resource"
									@edit_resource_save="edit_resource_save"
									@save_unit_edits="save_unit_edits"
								/>
							</div>
						</div>
						<div v-else>
							<div v-show="!resource.editing"><div class="d-flex align-center">
								<v-icon small color="#999" v-if="enable_editing&&allow_resource_reordering" class="moveHandle mr-2">fas fa-arrows-alt</v-icon>
								<ResourceLink class="mb-1" :key="resource.resource_id"
									:resource="resource"
									:enable_hc_assignment="false"
									:enable_edit_link="enable_editing"
									:enable_remove_link="enable_editing"
									:enable_report_issue="true"
									@report_issue="report_issue"
									@edit_resource="edit_resource_start(resource)"
									@remove_resource="remove_resource"
								/>
							</div></div>
							<div v-if="resource.editing" class="mb-1"><EditResource v-if="resource.editing"
								:show_shareable_control="false"
								:show_todo_control="resource_editor_show_todo_control"
								:resource="resource"
								:district_sanctioned="true"
								:collection_type="resource_editor_collection_type"
								:show_tags_control="(resource_editor_collection_type=='PD')"
								:professional_development="resource_editor_professional_development"
								:show_teacher_facing_control="resource_editor_show_teacher_facing_control"
								@edit_resource_cancel="edit_resource_cancel(resource)"
								@edit_resource_save="edit_resource_save(resource, ...arguments)"
							/></div>
						</div>
					</div>
					<div slot="footer" v-if="folder_resource_list.length==0 && allow_resource_reordering" class="k-resource-folder-empty-footer">This folder is currently empty</div>
				</draggable>
			</div>
		</div>

	</div></v-expand-transition>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import CollectionResourceFolder from './CollectionResourceFolder'
import ResourceLink from '../resources/ResourceLink'
import EditResource from '../resources/EditResource'
import ResourceCollectionItem from '../resources/ResourceCollectionItem'

export default {
	name: 'CollectionResourceFolder',
	components: { draggable, CollectionResourceFolder, EditResource, ResourceLink, ResourceCollectionItem },
	props: {
		collection: { type: Object, required: true },
		folder_id: { type: String, required: true },
		unit: { type: Object, required: true },
		// note that if this component is being deployed to edit a unit, the value of the `unit` prop will be a *copy* of the original unit in the store;
		// that's why we set values directly below, rather than commit.set'ing them
		enable_editing: { type: Boolean, required: false, default() { return false }},
		allow_resource_reordering: { type: Boolean, required: false, default() { return false }},
		// this component is currently used only for professional_development resources, so we set this to true by default
		professional_development: { type: Boolean, required: false, default() { return true }},
		folder_being_edited: { type: Boolean, required: false, default() { return false }},
	},
	data() { return {
		// TODO: I think we can replace this with !enable_editing if CollectionUnitEdit sends !editing_resource in as enable_editing
		show_resource_creator: false,
		folder_open: false,
		drag_options: {
		    animation: 200,
		    handle: '.moveHandle',
			group: 'collection_resources',
			componentData: { attrs: { 'data-resource_id': this.folder_id }}
		},
	}},
	computed: {
		...mapState(['user_info', ]),
		...mapGetters(['beta_options']),
		tree_view() {
			// for now at least always use the straight tree view here...
			return true

			// always use tree_view if the folder is being edited
			if (this.folder_being_edited) return true

			// else return false
			return false
		},
		folder_title() {
			let folder = this.unit.resource_tree.folders.find(o=>o.folder_id==this.folder_id)
			return folder.title
		},
		folder_resource_list: {
			get() {
				let arr = []
				// for each resource...
				for (let resource of this.unit.resources) {
					// console.log(resource.description)
					// look for a folder_assignment for the resource
					let folder_assignment = this.unit.resource_tree.folder_assignments.find(x=>x.resource_id==resource.resource_id)

					// if not found and we're doing the 'top' folder, put the resource at the end of the folder
					if (empty(folder_assignment) && this.folder_id == 'top') {
						folder_assignment = {
							resource_id: resource.resource_id,
							parent_folder_id: 'top',
							seq: this.get_max_folder_sequence('top') + 1
						}
						this.$store.commit('set', [this.unit.resource_tree.folder_assignments, 'PUSH', folder_assignment])
					}

					// if this resource goes in this folder, add it in seq order
					if (oprop(folder_assignment, 'parent_folder_id') == this.folder_id) {
						arr[folder_assignment.seq] = resource
					}
				}

				// now deal with sub-folders
				for (let folder of this.unit.resource_tree.folders) {
					if (folder.parent_folder_id == this.folder_id) {
						// create a "fake resource" for the folder
						arr[folder.seq] = {
							type: 'folder',
							resource_id: folder.folder_id
						}
					}
				}

				// remove empty vals, which can happen if/when items are deleted
				for (let i = arr.length-1; i >= 0; --i) {
					if (empty(arr[i])) {
						arr.splice(i, 1)
					}
				}

				return arr
			},
			set(val) {
				// console.log(val)
			},
		},
		folder_icon() {
			if (this.folder_open) return 'fa-folder-open'
			else return 'fa-folder'
		},
		folder_icon_css() {
			return U.subject_tile_css(this.collection) + '-text'
		},
		n_resources() {
			let n_in_folder = (folder_id) => {
				let n = 0
				for (let fa of this.unit.resource_tree.folder_assignments) {
					if (fa.parent_folder_id == folder_id) {
						++n
					}
				}
				for (let folder of this.unit.resource_tree.folders) {
					if (folder.parent_folder_id == folder_id) {
						n += n_in_folder(folder.folder_id)
					}
				}
				return n
			}
			return n_in_folder(this.folder_id)
		},
		n_resources_word() { return U.ps('resource', this.n_resources) },
		folder_todo_count() {
			let n_in_folder = (folder_id) => {
				let n = 0
				for (let fa of this.unit.resource_tree.folder_assignments) {
					if (fa.parent_folder_id == folder_id) {
						let resource = this.unit.resources.find(x=>x.resource_id == fa.resource_id)

						// if the resource is marked todo
						if (resource.todo) {
							// then if the user hasn't completed it, add to the count
							// note that the todo_status for a video may be 5-95, indicating partial completion
							if (!(this.user_info.todo_status[resource.resource_id] > 100)) {
								++n
							}
						}
					}
				}
				for (let folder of this.unit.resource_tree.folders) {
					if (folder.parent_folder_id == folder_id) {
						n += n_in_folder(folder.folder_id)
					}
				}
				return n
			}
			return n_in_folder(this.folder_id)
		},
		resource_editor_show_todo_control() {
			// show the todo control iff this is a PD collection
			return this.collection.subject == 'PD'
		},
		resource_editor_professional_development() {
			// mark the resource as professional_development iff this is a PD collection
			return this.collection.subject == 'PD'
		},
		resource_editor_show_teacher_facing_control() {
			// show the teacher/student resource control iff this is a PD collection
			return this.collection.subject == 'PD'
		},
		resource_editor_collection_type() {
			if (this.collection.subject == 'PD') return 'PD'
			if (this.collection.subject == 'PG') return 'PG'
			if (this.collection.subject == 'SC') return 'SC'
			return 'LP'	// default
		},
	},
	created() {
		if (this.folder_id == 'top' || U.local_storage_get('odlss_portal_folder_open_' + this.folder_id) == 'open') {
			this.folder_open = true
		}
	},
	mounted() {
	},
	methods: {
		// send issue report requests to vapp
		report_issue(issue_params) {
			// issue_params comes in with resource specified; add unit
			issue_params.lp_unit = this.unit
			vapp.report_issue(issue_params)
		},

		get_max_folder_sequence(folder_id) {
			// look through folder_assignments and folder, and get the max seq value for items connected to the given folder_id
			let last_seq = -1
			for (let fa of this.unit.resource_tree.folder_assignments) {
				if (fa.parent_folder_id == folder_id && fa.seq > last_seq) {
					last_seq = fa.seq
				}
			}
			for (let folder of this.unit.resource_tree.folders) {
				if (folder.parent_folder_id == folder_id && folder.seq > last_seq) {
					last_seq = folder.seq
				}
			}
			return last_seq
		},

		toggle_folder_open() {
			this.folder_open = !this.folder_open
			if (this.folder_id != 'top') {
				if (this.folder_open) U.local_storage_set('odlss_portal_folder_open_' + this.folder_id, 'open')
				else U.local_storage_clear('odlss_portal_folder_open_' + this.folder_id)
			}
		},

		editing_resource(status) {
			// "pass-through" emitter for recursive folders
			this.$emit('editing_resource', status)
		},

		create_resource_start() {
			this.show_resource_creator = true
			this.$emit('editing_resource', true)
		},

		create_resource_cancel() {
			this.show_resource_creator = false
			this.$emit('editing_resource', false)
		},

		create_resource_save(resource) {
			// add to unit.resources and unit.resource_tree
			this.unit.resources.push(resource)
			this.unit.resource_tree.folder_assignments.push({
				resource_id: resource.resource_id,
				parent_folder_id: this.folder_id,
				seq: this.get_max_folder_sequence(this.folder_id) + 1
			})
			this.show_resource_creator = false
			this.$emit('editing_resource', false)
			// call save_unit_edits immediately; otherwise if the user immediately refreshes (e.g.), the newly-added resource is lost
			this.save_unit_edits()
		},

		edit_resource_start(resource) {
			this.$set(resource, 'editing', true)
			this.$emit('editing_resource', true)
		},

		edit_resource_save(resource, edited_resource) {
			// we have to do this in the parent (CollectionEdit), where it has access to the original unit; here we're working with a copy
			this.$emit('edit_resource_save', resource, edited_resource)
			this.edit_resource_cancel(resource)
			// in this case the edit will be automatically reflected in the unit as a whole, so no need to save_unit_edits
		},

		edit_resource_cancel(resource) {
			resource.editing = false
			this.$emit('editing_resource', false)
		},

 		remove_resource(resource) {
			let i = this.unit.resources.findIndex(x=>x==resource)
			if (i > -1) this.unit.resources.splice(i, 1)

			i = this.unit.resource_tree.folder_assignments.findIndex(x=>x.resource_id==resource.resource_id)
			if (i > -1) this.unit.resource_tree.folder_assignments.splice(i, 1)

			// call save_unit_edits immediately
			this.save_unit_edits()
		},

		drag_complete(evt) {
			// get resource_id moved
			let item_moved_resource_id = oprop(evt, 'item', 'attributes', 'data-resource_id', 'nodeValue')

			// get resource_id of the folder we moved to
			let moved_to_folder_id = oprop(evt, 'to', 'attributes', 'data-resource_id', 'nodeValue')
			// let moved_from_resource_id = oprop(evt, 'from', 'attributes', 'data-resource_id', 'nodeValue')

			// construct a list of updates we need to make
			let updates = []

			// start with items other than the moved item in the moved_to folder
			let to_children = $(evt.to).children()
			for (let i = 0; i < to_children.length; ++i) {
				// skip the moved item if we encounter it; also skip the "footer" if there is one
				let child_resource_id = $(to_children[i]).attr('data-resource_id')
				if (child_resource_id == item_moved_resource_id || empty(child_resource_id)) continue

				updates.push({
					resource_id: child_resource_id,
					parent_folder_id: moved_to_folder_id,
				})
			}
			// add the moved item at position newIndex
			updates.splice(evt.newIndex, 0, {
				resource_id: item_moved_resource_id,
				parent_folder_id: moved_to_folder_id,
			})
			console.log(updates)

			// update folder sequences
			for (let i = 0; i < updates.length; ++i) {
				let update = updates[i]
				let folder_assignment = this.unit.resource_tree.folder_assignments.find(o=>o.resource_id==update.resource_id)
				if (empty(folder_assignment)) {
					// if empty the item should be a folder
					folder_assignment = this.unit.resource_tree.folders.find(o=>o.folder_id==update.resource_id)
				}
				folder_assignment.parent_folder_id = update.parent_folder_id
				folder_assignment.seq = i
			}

			// call save_unit_edits immediately
			this.save_unit_edits()
		},

		create_resource_folder_start() {
			this.$prompt({
				title: 'New Resource Folder',
				text: 'Enter a title for your new resource folder:',
				acceptText: 'Create Folder',
			}).then(folder_title => {
				if (!empty(folder_title)) {
					// add a new folder to resource_tree
					this.unit.resource_tree.folders.push({
						folder_id: U.new_uuid(),
						title: folder_title,
						parent_folder_id: this.folder_id,
						seq: this.get_max_folder_sequence(this.folder_id) + 1,
					})

					// call save_unit_edits immediately
					this.save_unit_edits()
				}
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		edit_folder_title() {
			this.$prompt({
				title: 'Edit Folder Title',
				text: 'Enter a new title for the folder:',
				acceptText: 'Save',
				initialValue: this.folder_title,
			}).then(folder_title => {
				if (!empty(folder_title)) {
					let folder = this.unit.resource_tree.folders.find(x=>x.folder_id==this.folder_id)
					folder.title = folder_title
					// call save_unit_edits immediately
					this.save_unit_edits()
				}
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		remove_folder() {
			let text = sr('Are you sure you want to delete folder “<b>$1</b>”?', this.folder_title)
			if (this.n_resources > 0) {
				text += sr(' This will also remove the $1 $2 currently in the folder from the unit resource collection.', this.n_resources, this.n_resources_word)
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: text,
			    acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				let remove_folder_recurse = (folder_id) => {
					// go through all folder_assignments
					for (let i = this.unit.resource_tree.folder_assignments.length-1; i >= 0; --i) {
						let fa = this.unit.resource_tree.folder_assignments[i]
						// if we found something in this folder
						if (fa.parent_folder_id == folder_id) {
							// delete the resource
							let index = this.unit.resources.findIndex(x=>x.resource_id==fa.resource_id)
							if (index > -1) this.unit.resources.splice(index, 1)

							// and delete the folder_assignment
							this.unit.resource_tree.folder_assignments.splice(i, 1)
						}
					}
					// recurse to remove units and folders that are children of this folder
					for (let folder of this.unit.resource_tree.folders) {
						if (folder.parent_folder_id == folder_id) {
							remove_folder_recurse(folder.folder_id)
						}
					}

					// remove this folder itself
					let index = this.unit.resource_tree.folders.findIndex(x=>x.folder_id == folder_id)
					if (index > -1) this.unit.resource_tree.folders.splice(index, 1)
				}
				remove_folder_recurse(this.folder_id)
				// call save_unit_edits immediately
				this.save_unit_edits()
			}).catch(n=>{console.log(n)}).finally(f=>{})

		},

		save_unit_edits() {
			this.$emit('save_unit_edits')
		},
	}
}
</script>

<style lang="scss">
.k-resource-folder {
	border-top:1px solid #ddd;
	padding:4px 0 4px 4px;

	.k-resource-folder-resources {
		margin-left:20px;
	}
}

.k-resource-folder-resources-inner {
	// display:flex;
	// flex-wrap:wrap;
	//
	// .k-resource-link {
	// 	margin: 4px;
	// }

	.k-resource-folder-resources-first-child {
		.k-resource-folder {
			border-top:0;
			padding-top:0;
			margin-top:0;
		}
	}
}

.k-resource-folder-header {
	display:flex;
	cursor:pointer;
	align-items: flex-start;
}

.k-resource-folder-icon {
	margin-top:4px;
}

.k-resource-folder-title {
	font-weight:bold;
}

.k-resource-folder-count {
	margin-left:8px;
	margin-top:3px;
	color:#999;
	font-size:0.8em;
	font-style:italic;
	white-space:nowrap;
}

.k-resource-folder-empty-footer {
	color:#999;
	font-style:italic;
}

.k-resource-collection-activity-wrapper {
	flex:0 1 auto;
	min-width:180px;
	max-width:180px;
}
</style>
