// https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Inheritance

class User_Info {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'user_id', 0)
		sdp(this, data, 'first_name', '')
		sdp(this, data, 'last_name', '')
		sdp(this, data, 'email', '')
		sdp(this, data, 'system_role', 'student', ['student', 'staff', 'parent', 'admin', 'unknown'])
		sdp(this, data, 'role', this.system_role)
		sdp(this, data, 'district_role', [])
		sdp(this, data, 'district_department', [])
		sdp(this, data, 'sis_identities', [])
		sdp(this, data, 'sis_user_sourcedId', '')
		sdp(this, data, 'sis_class_sourcedIds', [])
		sdp(this, data, 'admin_rights', [])
		sdp(this, data, 'todo_user_group', '')
		sdp(this, data, 'academic_year', '')
		sdp(this, data, 'opt_in_to_emails', true)
		sdp(this, data, 'child_data', {})
		sdp(this, data, 'guardian_data', {})
		sdp(this, data, 'message_preferences', {})

		// for recording which posts the user has read: translate from format "5,6,10,23" to {5:1, 6:1, 10:1, 23:1}
		this.post_reads = {}
		if (!empty(data.post_reads)) {
			let arr = data.post_reads.split(',')
			for (let post_id of arr) this.post_reads[post_id] = 1
		}

		// for resources marked as todos; format is resource_id: timestamp of completion
		this.todo_status = {}
		if (!empty(data.todo_status)) {
			for (let resource_id in data.todo_status) {
				// note that the todo_status for a video may be 5-95, indicating partial completion
				if (data.todo_status[resource_id] * 1 > 0) {
					this.todo_status[resource_id] = data.todo_status[resource_id]*1
				}
			}
		}
	}

	post_reads_for_save() {
		// when we save post_reads, convert back to string format
		let s = ''
		for (let post_id in this.post_reads) {
			if (s != '') s += ','
			s += post_id
		}
		return s
	}
}
window.User_Info = User_Info

/* Admin rights values
su: “Superuser” admin rights
qs: Editing Quick Search links
pd: Creating/editing PD resource collections
pg: Creating/editing PG resource collections
Editing Learning Progressions
	lp.level.all: All courses
	lp.level.Elementary: All Elementary
	lp.level.Middle School: All Middle
	lp.level.High School: All High School
	lp.subject.Science: All Science
	lp.subject.Mathematics: All Math
	lp.subject.English: All English
	lp.subject.Social Studies: All Social Studies
	lp.course.1345: Specific course
Administering Communities
	com.new: Approve new community requests
	com.mod.all: Moderate all communities
	com.mod.123: Moderate Specific community
*/
