<template><div>
	<v-dialog v-model="dialog_open" max-width="900" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b class="teal--text">Edit Standard</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<div class="k-case-ie-line">
					<div class="k-case-ie-line-label">Identifier:</div>
					<div v-html="cfitem.identifier"></div>
				</div>
				<div class="k-case-ie-line">
					<div class="k-case-ie-line-label">Human-Readable Code:</div>
					<v-text-field outlined dense hide-details :disabled="show_import_interface" v-model="cfitem.humanCodingScheme" placeholder="" autocomplete="new-password" clearable></v-text-field>
				</div>
				<div class="k-case-ie-line">
					<div class="k-case-ie-line-label">Full Statement:</div>
					<v-textarea outlined dense hide-details :disabled="show_import_interface" v-model="cfitem.fullStatement" placeholder="" rows="2" auto-grow clearable></v-textarea>
				</div>

				<div class="text-center" v-show="!is_new_item&&!show_import_interface">
					<v-btn small color="secondary" class="mr-2" @click="add_sibling">Add Sibling Item</v-btn>
					<v-btn small color="secondary" class="mr-2" @click="add_child">Add Child Item</v-btn>
					<v-btn small color="secondary" @click="show_import_interface=true">Import Child Items</v-btn>
				</div>

				<CASEItemImporter v-show="show_import_interface" class="mt-4" @cancel_import="show_import_interface=false" @save_imported_cfitems="save_imported_cfitems" />

			</v-card-text>
			<v-card-actions v-show="!show_import_interface" class="pa-3" style="border-top:1px solid #999">
				<v-btn color="secondary" @click="cancel_edit">Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="red accent-4" dark @click="delete_item">Delete Item</v-btn>
				<v-btn color="primary" @click="save_changes">
					<span v-if="changes_pending">Save Changes</span>
					<span v-else>Done</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CASEItemImporter from '@/components/resources/CASEItemImporter'

export default {
	components: { CASEItemImporter },
	props: {
		original_node: { type: Object, required: true },
		framework_identifier: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		cfitem: {},
		show_import_interface: false,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		is_new_item() { return !empty(this.original_node.new_item_parent_node)},
		changes_pending() {
			for (let key in this.cfitem) {
				if (this.cfitem[key] != this.original_node.cfitem[key]) return true
			}
			return false
		},
		framework() { return this.$store.state.case_frameworks[this.framework_identifier] },
	},
	watch: {
	},
	created() {
		console.log(this.original_node)
		this.cfitem = {
			identifier: this.original_node.cfitem.identifier,
			humanCodingScheme: this.original_node.cfitem.humanCodingScheme,
			fullStatement: this.original_node.cfitem.fullStatement,
			// TODO: add grade_low and grade_high
		}
		this.$emit('')
		this.$emit('make_item_active', this.cfitem.identifier, true)
		this.$emit('make_item_parents_open', this.cfitem.identifier)
	},
	mounted() {
	},
	methods: {
		cancel_edit() {
			console.log('cancel_edit')
			// if this is a new item, delete it from the framework
			if (this.is_new_item) {
				this.delete_item_from_framework(this.cfitem.identifier)
			}
			this.$emit('dialog_cancel')
		},

		save_changes() {
			if (!this.changes_pending) {
				this.cancel_edit()
				return
			}

			if (empty(this.cfitem.fullStatement)) {
				this.$alert('All standards must specify a full statement.')
				return
			}

			// calculate formatted date_string and add as lastChangeDateTime
			let date_string = new Date().toISOString().replace(/\.\d+Z/, '+00:00')	// 2020-12-17T01:49:46+00:00
			this.cfitem.lastChangeDateTime = date_string

			let data = {
				framework_identifier: this.framework_identifier,
				cfitems: [
					this.cfitem
				],
				cfassociations: [],
			}

			// if this is a newly-added item...
			if (this.is_new_item) {
				// add a uri field
				this.cfitem.uri = this.framework.uri_base + this.cfitem.identifier

				// create an 'isChildOf' association for the item, and add to data
				let child_association = this.create_ischildof_association(this.framework, this.original_node.new_item_parent_node.cfitem, this.cfitem, this.original_node.sequence)
				data.cfassociations.push(child_association)
			}

			this.$store.dispatch('case_save_framework_data', data).then(()=>{
				// the store fn will update original_node.cfitem to match this.cfitem (including identifier if this was a new item)

				// if this was a new item...
				if (this.is_new_item) {
					// add the cfassociation to framework.cfassociations
					this.$store.commit('set', [this.framework.cfassociations, 'PUSH', data.cfassociations[0]])

					// remove the new_item_parent_node attribute from original_node
					this.$store.commit('set', [this.original_node, 'new_item_parent_node', '*DELETE_FROM_STORE*'])
				}
				console.log('done saving')
			})

		},

		create_ischildof_association(framework, parent_cfitem, child_cfitem, sequence) {
			// note that:
			//     for xNodeURI titles, we use the humanCodingScheme (if there) or the first X chars of the fullStatement
			//     the origin (of "isChildOf") is the child (the item we're saving)
			//     the destination is the child's parent
			let association_identifier = U.new_uuid()
			let date_string = new Date().toISOString().replace(/\.\d+Z/, '+00:00')	// 2020-12-17T01:49:46+00:00

			let dest_title
			if (!empty(parent_cfitem.humanCodingScheme)) dest_title = parent_cfitem.humanCodingScheme
			else if (!empty(parent_cfitem.fullStatement)) dest_title = parent_cfitem.fullStatement.substr(0,50)
			else if (!empty(parent_cfitem.title)) dest_title = parent_cfitem.title	// this would be a top-level item, whose parent is the document
			else dest_title = '???'	// shouldn't happen

			return {
				uri: framework.uri_base + association_identifier,
				identifier: association_identifier,
				lastChangeDateTime: date_string,

				originNodeURI: {
					title: ((!empty(child_cfitem.humanCodingScheme)) ? child_cfitem.humanCodingScheme : child_cfitem.fullStatement.substr(0,50)),
					identifier: child_cfitem.identifier,
					uri: framework.uri_base + child_cfitem.identifier
				},

				associationType: "isChildOf",

				destinationNodeURI: {
					title: dest_title,
					identifier: parent_cfitem.identifier,
					uri: framework.uri_base + parent_cfitem.identifier
				},
				sequenceNumber: sequence
			}
		},

		delete_item() {
			// TODO: allow for deleting a parent; for now we'll just disallow it
			// if (this.original_node.children.length > 0) {
			// 	this.$alert('You cannot currently delete nodes that have children.')
			// 	return
			// }

			// if this is a new item, delete it without saving or confirming
			if (this.is_new_item) {
				this.delete_item_from_framework(this.cfitem.identifier)
				this.$emit('dialog_cancel')
			} else {
				let confirm_text = 'Are you sure you want to delete this item?'
				if (this.original_node.children.length > 0) {
					confirm_text = sr('Are you sure you want to delete this item <b>and its $1 $2 (and possible grandchildren)</b>?', this.original_node.children.length, U.ps('child', this.original_node.children.length, 'children'))
				}
				this.$confirm({
				    title: 'Are you Sure?',
				    text: confirm_text,
				    acceptText: 'Delete',
					acceptColor: 'red',
					dialogMaxWidth: 500
				}).then(y => {
					// delete from the DB

					// start data to send to service with the cfitem identifier
					let data = {
						framework_identifier: this.framework_identifier,
						cfitems: [],
						cfassociations: []
					}

					// recursively add cfitems
					let add_cfitem_to_delete = (node) => {
						data.cfitems.push({ identifier: node.cfitem.identifier, delete: 'yes' })
						// add associations to this cfitem identifier so we delete those too
						for (let i = this.framework.cfassociations.length-1; i >= 0; --i) {
							let cfa = this.framework.cfassociations[i]
							// remove any association whose origin OR destination is this identifier
							if (cfa.originNodeURI.identifier == node.cfitem.identifier || cfa.destinationNodeURI.identifier == node.cfitem.identifier) {
								if (!data.cfassociations.find(x=>x.identifier == cfa.identifier)) {
									data.cfassociations.push({ identifier: cfa.identifier, delete: 'yes' })
								}
							}
						}
						// recurse on children
						for (let child_node of node.children) {
							add_cfitem_to_delete(child_node)
						}
					}
					add_cfitem_to_delete(this.original_node)

					console.log(data)

					this.$store.dispatch('case_save_framework_data', data).then(()=>{
						// purge items and associations from the tree, in reverse order so we do children first
						for (let i = data.cfitems.length-1; i >= 0; --i) {
							this.delete_item_from_framework(data.cfitems[i].identifier)
						}
						this.$emit('dialog_cancel')
					})

				}).catch(n=>{console.log(n)}).finally(f=>{})
			}
		},

		delete_item_from_framework(identifier) {
			if (!empty(this.framework.cfitems[identifier])) {
				let cfitem = this.framework.cfitems[identifier]
				// remove from the tree (the item's node's parent_node's children)
				// it's possible, though unlikely, for an item to be listed in multiple places in the tree
				for (let tree_node of cfitem.tree_nodes) {
					if (!empty(tree_node.parent_node)) {
						let index = tree_node.parent_node.children.findIndex(x=>x.cfitem.identifier == this.cfitem.identifier)
						if (index > -1) {
							this.$store.commit('set', [tree_node.parent_node.children, 'SPLICE', index])
						}
					}
				}

				// remove from cfitems
				this.$store.commit('set', [this.framework.cfitems, identifier, '*DELETE_FROM_STORE*'])
				// decrement item_count
				this.$store.commit('set', [this.framework, 'item_count', this.framework.item_count-1])
			}

			// remove from cfassociations if there; go backwards to make splice work right
			for (let i = this.framework.cfassociations.length-1; i >= 0; --i) {
				let cfa = this.framework.cfassociations[i]
				// remove any association whose origin OR destination is this identifier
				if (cfa.originNodeURI.identifier == identifier || cfa.destinationNodeURI.identifier == identifier) {
					this.$store.commit('set', [this.framework.cfassociations, 'SPLICE', i])
				}
			}
		},

		add_child_to_state_framework(cfitem, parent_node) {
			let child_cfitem = {
				// randomly generate a new uuid
				identifier: U.new_uuid(),
				tree_keys: [],
				tree_nodes: [],
			}
			if (!empty(cfitem.humanCodingScheme)) child_cfitem.humanCodingScheme = cfitem.humanCodingScheme
			if (!empty(cfitem.fullStatement)) child_cfitem.fullStatement = cfitem.fullStatement
			if (!empty(cfitem.grade_low)) child_cfitem.grade_low = cfitem.grade_low
			if (!empty(cfitem.grade_high)) child_cfitem.grade_high = cfitem.grade_high

			// add the child_cfitem to the framework's cfitems hash, then get a reference to the now-reactive child_cfitem
			this.$store.commit('set', [this.framework, 'item_count', this.framework.item_count+1])
			this.$store.commit('set', [this.framework.cfitems, child_cfitem.identifier, child_cfitem])
			child_cfitem = this.framework.cfitems[child_cfitem.identifier]

			// add to the end of any children already in the original_node
			let sequence = 0
			for (let cn of parent_node.children) {
				if (cn.sequence > sequence) sequence = cn.sequence
			}
			++sequence

			// create a node and add it to the tree, marking it as a new item
			let child_node = {
				tree_key: this.framework.next_tree_key,
				cfitem: child_cfitem,
				parent_node: parent_node,
				children: [],
				sequence: sequence,
			}

			// store tree_key and tree_node in child_cfitem, then increment framework.next_tree_key
			this.$store.commit('set', [child_cfitem.tree_nodes, 'PUSH', child_node])
			this.$store.commit('set', [child_cfitem.tree_keys, 'PUSH', child_node.tree_key])
			this.$store.commit('set', [this.framework, 'next_tree_key', this.framework.next_tree_key+1])

			// push the node onto parent_node's children
			this.$store.commit('set', [parent_node.children, 'PUSH', child_node])

			// return the child_node
			return child_node
		},

		add_child(confirmed) {
			if (this.changes_pending && confirmed !== true) {
				this.$confirm({
				    title: 'Are you sure?',
				    text: 'You’ve made changes to this item. If you create a new child item now, those changes will be lost. Click “Discard Changes” to proceed. (You can also click “Cancel” here, save your changes, then click again to add a child item.)',
				    acceptText: 'Discard Changes and Add Child Item',
					acceptColor: 'red',
					dialogMaxWidth: 600
				}).then(y => {
					this.add_child(true)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			// try to guess at a reasonable value for the hcs
			let child_cfitem = {}
			if (!empty(this.cfitem.humanCodingScheme)) {
				child_cfitem.humanCodingScheme = this.cfitem.humanCodingScheme + '.x'
			} else {
				// else add placeholder text for the statement, so that something appears in the tree
				child_cfitem.fullStatement = 'Statement'
			}

			// temporarily add a new item
			let child_node = this.add_child_to_state_framework(child_cfitem, this.original_node)

			// add the original_node as the new_item_parent_node
			this.$store.commit('set', [child_node, 'new_item_parent_node', this.original_node])

			// open the child for editing
			this.$emit('edit_new_child', child_node.cfitem.tree_nodes[0])

			// make sure the parent is open in the tree
			this.$emit('make_item_open', this.cfitem.identifier)
		},

		add_sibling(confirmed) {
			if (this.changes_pending && confirmed !== true) {
				this.$confirm({
				    title: 'Are you sure?',
				    text: 'You’ve made changes to this item. If you create a new sibling item now, those changes will be lost. Click “Discard Changes” to proceed. (You can also click “Cancel” here, save your changes, then click again to add a sibling item.)',
				    acceptText: 'Discard Changes and Add Sibling Item',
					acceptColor: 'red',
					dialogMaxWidth: 600
				}).then(y => {
					this.add_child(true)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			// try to guess at a reasonable value for the hcs
			let child_cfitem = {}
			if (!empty(this.cfitem.humanCodingScheme) && this.cfitem.humanCodingScheme.search(/(.*)[0-9a-zA-Z]+$/) > -1) {
				child_cfitem.humanCodingScheme = RegExp.$1 + 'x'
			} else {
				// else add placeholder text for the statement, so that something appears in the tree
				child_cfitem.fullStatement = 'Statement'
			}

			// temporarily add a new item
			let child_node = this.add_child_to_state_framework(child_cfitem, this.original_node.parent_node)

			// add the original_node's parent_node as the new_item_parent_node
			this.$store.commit('set', [child_node, 'new_item_parent_node', this.original_node.parent_node])

			// open the sibling for editing
			this.$emit('edit_new_child', child_node.cfitem.tree_nodes[0])

			// make sure the parent is open in the tree
			this.$emit('make_item_open', this.original_node.parent_node.cfitem.identifier)
		},

		save_imported_cfitems(cfitems) {
			// calculate formatted date_string for lastChangeDateTime values
			let date_string = new Date().toISOString().replace(/\.\d+Z/, '+00:00')	// 2020-12-17T01:49:46+00:00

			let data = {
				framework_identifier: this.framework_identifier,
				cfitems: [],
				cfassociations: [],
			}

			let parent_node = [this.original_node, null, null]
			// for each to-be-processed cfitem...
			for (let cfitem of cfitems) {
				// add to framework (this creates an identifier), and mark it as newly-imported (we'll use this flag below)
				let child_node = this.add_child_to_state_framework(cfitem, parent_node[cfitem.level])
				this.$store.commit('set', [child_node, 'imported', true])

				// set parent_node if this item is on level 0 (meaning directly under original_node) or level 1
				if (cfitem.level == 0) parent_node[1] = child_node
				if (cfitem.level == 1) parent_node[2] = child_node

				// construct and push json for CFItems
				let cfi = {
					identifier: child_node.cfitem.identifier,
					uri: this.framework.uri_base + child_node.cfitem.identifier,
					fullStatement: child_node.cfitem.fullStatement,
					lastChangeDateTime: date_string,
				}
				if (!empty(child_node.cfitem.humanCodingScheme)) cfi.humanCodingScheme = child_node.cfitem.humanCodingScheme
				if (!empty(child_node.cfitem.grade_low)) cfi.grade_low = child_node.cfitem.grade_low
				if (!empty(child_node.cfitem.grade_high)) cfi.grade_high = child_node.cfitem.grade_high

				data.cfitems.push(cfi)

				// create an 'isChildOf' association for the item, and add to data
				let child_association = this.create_ischildof_association(this.framework, parent_node[cfitem.level].cfitem, child_node.cfitem, child_node.sequence)
				data.cfassociations.push(child_association)
			}

			console.log(data)

			///////////////////////////////////
			this.$store.dispatch('case_save_framework_data', data).then(()=>{
				// the store fn will finish setting up the cfitem nodes in the framework tree

				// add cfassociations to framework.cfassociations
				for (let cfa of data.cfassociations) {
					this.$store.commit('set', [this.framework.cfassociations, 'PUSH', cfa])
				}

				// remove the "imported" attribute from the nodes
				for (let cfitem of data.cfitems) {
					console.log('imported: ' + this.framework.cfitems[cfitem.identifier].tree_nodes[0])
					this.$store.commit('set', [this.framework.cfitems[cfitem.identifier].tree_nodes[0], 'imported', '*DELETE_FROM_STORE*'])
				}

				this.show_import_interface = false
				console.log('done saving')

			}).catch(()=>{
				// in case of failure...
				this.$alert('Error saving imported items')
				// remove cfitems from the framework
				for (let cfitem of data.cfitems) {
					// find the item's node in the framework tree and delete it
					let tree_node = this.framework.cfitems[cfitem.identifier].tree_nodes[0]
					let index = tree_node.parent_node.children.findIndex(x=>x==tree_node)
					if (index == -1) {
						console.log('error deleting child node from parent')
					} else {
						this.$store.commit('set', [tree_node.parent_node.children, 'SPLICE', index])
					}

					// then delete it from cfitems
					this.$store.commit('set', [this.framework.cfitems, cfitem.identifier, '*DELETE_FROM_STORE*'])
				}
			})


		},
	}
}
</script>

<style lang="scss">
.k-case-ie-line {
	display:flex;
	align-items:center;
	margin-bottom:12px;

	textarea {
		line-height:22px;
	}
}

.k-case-ie-line-label {
	font-weight:bold;
	margin-right:8px;
	width:190px;
}
</style>
