<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-page-wrapper">
	<h2 class="k-page-title">
		<div class="float-right"><v-btn @click="load_admin_user_list">Load / Reload Admin User List</v-btn></div>
		<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-cog</v-icon>
		Admin User Privileges
	</h2>

	<div v-if="admin_users" style="clear:both">
		<div class="py-4 d-flex">
			<v-text-field
				v-model="search"
				prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
				label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 450px"
			></v-text-field>
			<v-btn class="ml-8" color="primary" @click="new_user_clicked"><v-icon small class="mr-2">fas fa-plus</v-icon> Add New Admin User</v-btn>
		</div>

		<v-data-table light
			:headers="headers"
			:items="rows"
			:sort-by="['su','user']"
			:sort-desc="[true,false]"
			:must-sort="true"
			:custom-filter="table_search_filter"
			:search="search"
			hide-default-footer
			:footer-props="footer_options"
			:items-per-page="-1"
			class="k-admin-users-table"
		>
			<template v-slot:header.user="{header}">User&nbsp;</template>
			<template v-slot:header.su="{header}"><v-tooltip bottom><template v-slot:activator="{on}"><span v-on="on">SU&nbsp;</span></template>Superuser Access</v-tooltip></template>
			<template v-slot:header.pd="{header}"><v-tooltip bottom><template v-slot:activator="{on}"><span v-on="on">PD&nbsp;</span></template>Edit Professional Development resource collections</v-tooltip></template>
			<template v-slot:header.pg="{header}"><v-tooltip bottom><template v-slot:activator="{on}"><span v-on="on">P/G&nbsp;</span></template>Edit Parent/Guardian announcements and resource collections</v-tooltip></template>
			<template v-slot:header.qs="{header}"><v-tooltip bottom><template v-slot:activator="{on}"><span v-on="on">QS&nbsp;</span></template>Update “QuickStart” search results</v-tooltip></template>
			<template v-slot:header.mimic_all="{header}"><v-tooltip bottom><template v-slot:activator="{on}"><span v-on="on">Mimic All&nbsp;</span></template>Mimic All Users</v-tooltip></template>
				<!-- <th>User</th>
				<th>SU!!</th>
				<th>PD</th>
				<th>QS</th>
				<th>Learning Progressions</th>
				<th>Communities</th>
				<th>PD Reports</th>
			</template> -->
			<template v-slot:item="{ item }"><tr>
				<td><v-btn icon x-small class="mr-2" @click="edit_user(item.identifier)"><v-icon>fas fa-edit</v-icon></v-btn><span v-html="item.user"></span></td>
				<td><v-icon small v-if="item.su">fas fa-check</v-icon></td>
				<td><v-icon small v-if="item.pd">fas fa-check</v-icon></td>
				<td><v-icon small v-if="item.pg">fas fa-check</v-icon></td>
				<td><v-icon small v-if="item.qs">fas fa-check</v-icon></td>
				<td><v-icon small v-if="item.mimic_all">fas fa-check</v-icon></td>
				<td><v-chip v-for="lp in item.lps" :key="lp.code" x-small class="mr-1">{{lp.text}}</v-chip></td>
				<td><v-chip v-for="com in item.coms" :key="com.code" x-small class="mr-1">{{com.text}}</v-chip></td>
				<td><v-chip v-for="com in item.pd_reps" :key="com.code" x-small class="mr-1">{{com.text}}</v-chip></td>
			</tr></template>
		</v-data-table>
	</div>
	<div v-else class="mt-12 mb-3"><i>This area allows you to manage which HenryConnects users have administrative access to do such things as moderating community forums and author/edit Learning Progressions and Professional Development resources. To get started, click the “LOAD / RELOAD ADMIN USER LIST” button above.</i></div>
	<AdminUserEdit v-if="user_being_edited" :admin_user="user_being_edited" @editor_cancel="user_being_edited=null" @rights_edited="rights_edited"></AdminUserEdit>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminUserEdit from './AdminUserEdit'

export default {
	components: { AdminUserEdit },
	props: {
	},
	data() { return {
		admin_users: null,
		headers: [
			{ text: 'User', align: 'left', sortable: true, value:'user' },
			{ text: 'SU', align: 'left', sortable: true, value:'su' },
			{ text: 'PD', align: 'left', sortable: true, value:'pd' },
			{ text: 'P/G', align: 'left', sortable: true, value:'pg' },
			{ text: 'QS', align: 'left', sortable: true, value:'qs' },
			{ text: 'Mimic All', align: 'left', sortable: true, value:'mimic_all' },
			{ text: 'Learning Progressions', align: 'left', sortable: false, value:'lp' },
			{ text: 'Communities', align: 'left', sortable: false, value:'com' },
			{ text: 'PD Reports', align: 'left', sortable: false, value:'pd_rep' },
		],
		footer_options: {
			itemsPerPageOptions: [10,50,100,-1],
		},
		search: '',
		user_being_edited: null,
	}},
	computed: {
		...mapState(['user_info', 'all_courses_loaded', 'all_courses', 'all_communities_loaded', 'all_communities', 'todo_user_group_divisions', 'todo_user_group_schools']),
		rows() {
			let arr = []

			for (let identifier in this.admin_users) {
				let u = this.admin_users[identifier]
				let o = {
					identifier: identifier,
					user: sr('$1, $2 ($3)', u.last_name, u.first_name, u.email),
				}
				o.user_lc = o.user.toLowerCase()

				// NOTE THAT CODE BELOW IS LARGELY MIRRORED IN AdminUserEdit.vue
				// if user has su rights, they can do everything else too
				if (u.rights.findIndex(s=>s=='su') > -1) {
					o.su = true
					o.qs = true
					o.pd = true
					o.pg = true
					o.mimic_all = true
					o.lps = [
						{ code: 'lp.level.all', text: 'ALL COURSES', text_lc: 'all courses' },
					]
					o.coms = [
						{ code: 'com.new', text: 'APPROVE NEW', text_lc: 'approve new' },
						{ code: 'com.mod.all', text: 'MODERATE ALL', text_lc: 'moderate all' },
					]
					o.pd_reps = [
						{ code: 'all', text: 'ALL SCHOOLS/DIVISIONS', text_lc: 'all schools/divisions' },
					]

				} else {
					// else build up list from rights
					o.su = false
					o.qs = false
					o.pd = false
					o.pg = false
					o.mimic_all = false
					o.lps = []
					o.coms = []
					o.pd_reps = []
					for (var r of u.rights) {
						// Quick Search
						if (r == 'qs') {
							o.qs = true

						// Professional Development
						} else if (r == 'pd') {
							o.pd = true

						// Parent/Guardian
						} else if (r == 'pg') {
							o.pg = true

						// Mimic All Users
						} else if (r == 'mimic_all') {
							o.mimic_all = true

						// Learning Progressions
						} else if (r.search(/^lp\.(.*)\.(.*)/) > -1) {
							let l1 = RegExp.$1
							let l2 = RegExp.$2
							let text
							if (l2 == 'all') {
								text = 'ALL COURSES'
							} else if (l1 == 'level') {
								text = 'ALL ' + l2
							} else if (l1 == 'subject') {
								text = 'ALL ' + l2
							} else {
								// lp.course.1355
								let course = this.all_courses.find(x=>x.course_code == l2)
								if (!empty(course)) {
									text = course.title
								} else {
									text = 'Course ID ' + l2
								}
							}
							o.lps.push({ code: r, text: text, text_lc: text.toLowerCase() })

						// Communities
						} else if (r.search(/^com\.(.*)/) > -1) {
							let c1 = RegExp.$1
							let text
							if (c1 == 'new') {
								text = 'APPROVE NEW'
							} else if (c1 == 'mod.all') {
								text = 'MODERATE ALL'
							} else {
								if (c1.search(/(.*)\.(.*)/) == -1) {
									text = c1	// shouldn't happen
								} else {
									// com.mod.23
									let community_id = RegExp.$2
									let com = this.all_communities.find(x=>x.community_id == community_id)
									if (!empty(com)) {
										text = 'Moderate “' + com.title + '”'
									} else {
										text = 'Moderate “' + community_id + '”'
									}
								}
							}
							o.coms.push({ code: r, text: text, text_lc: text.toLowerCase() })

						// PD reports
						} else if (r.search(/^pd_rep\.(.*)/) > -1) {
							let code = RegExp.$1
							let text
							if (code == 'all') {
								text = 'ALL SCHOOLS/DIVISIONS'
							} else {
								if (this.todo_user_group_divisions[code]) {
									text = this.todo_user_group_divisions[code]
								} else if (this.todo_user_group_schools[code]) {
									text = this.todo_user_group_schools[code]
								} else {
									text = code	// hopefully this won't happen
								}
							}
							o.pd_reps.push({ code: r, text: text, text_lc: text.toLowerCase() })
						}
					}
				}

				arr.push(o)
			}

			return arr
		},
	},
	created() {
		// DEBUG
		vapp.admin_users_component = this
	},
	mounted() {
	},
	methods: {
		load_admin_user_list() {
			// we must have all_courses and all_communities loaded first; then we get the list of admins
			if (!this.all_courses_loaded) {
				this.$store.dispatch('get_all_courses').then(()=>{
					this.load_admin_user_list_2()
				}).catch(()=>{
					this.$alert('error in get_all_courses')
				})
			} else {
				this.load_admin_user_list_2()
			}
		},
		load_admin_user_list_2() {
			if (!this.all_communities_loaded) {
				this.$store.dispatch('get_all_communities').then(()=>{
					this.load_admin_user_list_3()
				}).catch(()=>{
					this.$alert('error in get_all_communities')
				})
			} else {
				this.load_admin_user_list_3()
			}
		},
		load_admin_user_list_3() {
			let payload = {
				user_id: this.user_info.user_id,
				user_identifier: 'all',
			}
			U.loading_start()
			U.ajax('admin_get_user_admin_rights', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				this.admin_users = result.admin_users
				// format for each admin_user: { user_id, email, first_name, last_name, rights["su", "qs", "pd", "pg", "lp.course.1345", etc.]}
			});
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check user name
			if (item.user_lc.search(re) > -1) return true

			// check lps
			for (let lp of item.lps) {
				if (lp.text_lc.search(re) > -1) return true
			}

			// check coms
			for (let com of item.coms) {
				if (com.text_lc.search(re) > -1) return true
			}

			// check su, qs, etc.
			if (search == 'su' && item.su) return true
			if (search == 'qs' && item.qs) return true
			if (search == 'pd' && item.pd) return true
			if (search == 'pg' && item.pg) return true

			// if we get to here return false
			return false
		},

		edit_user(identifier) {
			this.user_being_edited = this.admin_users[identifier]
		},

		rights_edited(new_rights) {
			let payload = {
				user_id: this.user_info.user_id,
				user_identifier: this.user_being_edited.user_id,
				rights_arr: new_rights,
			}
			console.log(payload)

			U.loading_start()
			U.ajax('admin_update_admin_rights', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				this.user_being_edited.rights = new_rights
				this.user_being_edited = null
			});
		},

		new_user_clicked() {
			this.$prompt({
				title: 'Add New Admin User',
				text: 'Enter user email:',
				initialValue: '@henry.k12.ga.us',
				acceptText: 'Find User',
			}).then(email => {
				email = $.trim(email).toLowerCase()

				if (empty(email) || email.indexOf('@') == -1) {
					this.$alert('Please enter a valid user email.').then(x=>{this.new_user_clicked()})
					return
				}
				for (let identifier in this.admin_users) {
					if (this.admin_users[identifier].email == email) {
						this.search = email
						this.$alert('This user already has some admin rights. Click the edit button next to the user’s name to edit their rights.')
						return
					}
				}
				this.new_user_validate(email)
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		new_user_validate(email) {
			let payload = {
				user_id: this.user_info.user_id,
				email: email,
			}
			U.loading_start()
			U.ajax('admin_validate_email', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				if (empty(result.user_id)) {
					this.$alert('We couldn’t find that email address in the system.').then(x=>{this.new_user_clicked()})
				} else {
					this.$set(this.admin_users, result.user_id+'', {
						user_id: result.user_id,
						email: result.email,
						first_name: result.first_name,
						last_name: result.last_name,
						rights: [],
					})
					this.user_being_edited = this.admin_users[result.user_id]
				}
			});
		},

		return_to_admin_main() {
			this.$router.push({ path: '/welcome' })
		},
	}
}
</script>

<style lang="scss">
.k-admin-users-table {
	th { white-space:nowrap; }
}
</style>
