import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 1. Define route components. These can be imported from other files
import BadRoute from './components/main/BadRoute'
import WelcomeView from './components/main/WelcomeView'
import CoursesMain from './components/courses/CoursesMain'
import CourseView from './components/courses/CourseView'
// import PDMain from './components/pd/PDMain'
// import PDCourseView from './components/pd/PDCourseView'
import PDBase from './components/collections/PDBase'
import PGBase from './components/collections/PGBase'
import Collection from './components/collections/Collection'
import XCollection from './components/collections/XCollection'
import CommunitiesView from './components/communities/CommunitiesView'
import CommunitiesList from './components/communities/CommunitiesList'
import Community from './components/communities/Community'
import AdminView from './components/admin/AdminView'
import AdminUsers from './components/admin/AdminUsers'
import AdminLearningProgressions from './components/admin/AdminLearningProgressions'
import AdminCommunities from './components/admin/AdminCommunities'
import AdminJumpTo from './components/admin/AdminJumpTo'
import AdminUsage from './components/admin/AdminUsage'
import AdminSearch from './components/admin/AdminSearch'
import AdminResourceUsage from './components/admin/AdminResourceUsage'
import AdminSiteIssues from './components/admin/AdminSiteIssues'
import AdminLessonMasters from './components/admin/AdminLessonMasters'
import AdminTerms from './components/admin/AdminTerms'
import MyContentView2 from './components/mycontent/MyContentView2'

// 2. Define some routes
const routes = [
	{ path: '*', component: BadRoute },
	{ path: '', redirect: '/welcome' },
	{ path: '/welcome', name: 'welcome', component: WelcomeView },
	// note that we never actually go to courses_base or communities_base
	{ path: '/courses', name: 'courses_main', component: CoursesMain, children: [
		{ path: '/courses/lp/:course_code/:unit_id/:assignment_id', name: 'course_view', component: CourseView, props: true },
	] },
	{ path: '/pd', name: 'pd_main', component: PDBase, children: [
		{ path: '/pd/:course_code/:unit_id', name: 'pd_course_view', component: Collection, props: true },
	] },
	{ path: '/pg', name: 'pg_main', component: PGBase, children: [
		{ path: '/pg/:course_code/:unit_id', name: 'pg_course_view', component: XCollection, props: true },
	] },
	{ path: '/communities_base', name: 'communities', component: CommunitiesView, children: [
		{ path: '/communities/:community_id', name: 'community', component: Community, props: true },
	] },
	{ path: '/admin_base', name: 'admin', component: AdminView, children: [
		{ path: '/admin/users', name: 'admin_users', component: AdminUsers },
		{ path: '/admin/lp', name: 'admin_lp', component: AdminLearningProgressions },
		{ path: '/admin/communities', name: 'admin_communities', component: AdminCommunities },
		{ path: '/admin/jumpto', name: 'admin_jumpto', component: AdminJumpTo },
		{ path: '/admin/usage', name: 'admin_usage', component: AdminUsage },
		{ path: '/admin/search', name: 'admin_search', component: AdminSearch },
		{ path: '/admin/resource_usage', name: 'admin_resource_usage', component: AdminResourceUsage },
		{ path: '/admin/site_issues', name: 'admin_site_issues', component: AdminSiteIssues },
		{ path: '/admin/lessonmasters', name: 'admin_lessonmasters', component: AdminLessonMasters },
		{ path: '/admin/terms', name: 'admin_terms', component: AdminTerms },
	] },
	{ path: '/activity/:single_activity_id', name: 'single_activity', component: MyContentView2, props: true },
	{ path: '/lesson/:single_lesson_id', name: 'single_lesson', component: MyContentView2, props: true },
]

// 3. Create the router instance and pass the `routes` option
const router = new VueRouter({
	// for local development, base is '/'; on the server, base is /
	base: (document.location.href.indexOf('localhost') > -1) ? '' : '/',
	mode: 'history',
	routes // short for `routes: routes`
})

// override push and replace functions to avoid useless "NavigationDuplicated" errors
// https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
const _push = router.__proto__.push
router.__proto__.push = function push (...args) {
	return _push.call(this, ...args).catch(error => {
		if (error.name !== 'NavigationDuplicated') throw error
	})
}

const _replace = router.__proto__.replace
router.__proto__.replace = function replace (...args) {
	return _replace.call(this, ...args).catch(error => {
		if (error.name !== 'NavigationDuplicated') throw error
	})
}

// 4. export router object for inclusion in main.js
export default router
