<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<div class="d-flex">
		<v-checkbox class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="is_top_hit" hide-details></v-checkbox>
		<v-tooltip top><template v-slot:activator="{on}"><a v-on="on" :href="link.href" target="_hc_preview" v-html="link.text"></a></template>{{link.href}}</v-tooltip>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
		link: { type: Object, required: true },
		top_hits: { type: Array, required: true },
	},
	data() { return {
	}},
	computed: {
		...mapState(['searchable_links']),
		is_top_hit:{
			get() {
				return (this.top_hits.findIndex(x=>(x.href==this.link.href && x.text==this.link.text)) > -1)
			},
			set(val) {
				this.$emit('set_top_hit')
			}
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
