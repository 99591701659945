<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-page-wrapper">
	<h2 class="k-page-title">
		<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-cog</v-icon>
		Jump To Options Administration
	</h2>

	<p><v-btn @click="action">Action</v-btn></p>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
	},
	mounted() {
	},
	methods: {
		action() {
			return
			let payload = {}
			U.loading_start()
			U.ajax('update_search_data', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}


			});
		},

		return_to_admin_main() {
			this.$router.push({ path: '/welcome' })
		}
	}
}
</script>

<style lang="scss">
</style>
