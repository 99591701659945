<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="d-flex justify-center align-stretch">
	<MiniNav v-show="community_showing" current_section="communities" />
	<div class="k-main-collection" :class="community_showing?'k-main-collection--item-showing k-main-collection--community-showing':''">
		<div class="k-main-collection-header k-page-title d-flex">
			<div><v-icon large color="primary" class="mr-4">fas fa-users</v-icon>Communities</div>
			<v-spacer/>
			<v-btn color="secondary" @click="find_community" v-if="!small_screen"><v-icon class="mr-2" small>fas fa-search</v-icon>Join A New Community</v-btn>
			<v-menu bottom right><template v-slot:activator="{on}"><v-btn v-on="on" v-if="!small_screen" class="ml-2" small fab color="#999" dark><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template>
				<v-list>
					<!-- <v-list-item @click="find_community"><v-list-item-title>Find A Community To Join</v-list-item-title></v-list-item> -->
					<v-list-item v-if="!is_communities_admin" @click="request_new_community"><v-list-item-icon><v-icon small>fas fa-plus</v-icon></v-list-item-icon><v-list-item-title>Request A New Community</v-list-item-title></v-list-item>
					<v-list-item v-if="is_communities_admin" @click="create_community"><v-list-item-icon><v-icon>fas fa-tools</v-icon></v-list-item-icon><v-list-item-title>Create a New Community</v-list-item-title></v-list-item>
					<v-list-item v-if="is_communities_admin" @click="review_community_requests"><v-list-item-icon><v-icon>fas fa-tools</v-icon></v-list-item-icon><v-list-item-title>Review New Community Requests</v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
		</div>
		<div v-if="community_showing" class="k-mini-nav-site-section-heading">Communities</div>
		<div class="k-main-collection-body">
			<div v-if="communities.length==0" class="mt-2"><i>Click the button above to find HenryConnects communities you might want to join!</i></div>
			<div v-for="(community) in communities" :key="community.community_id" @click="open_community(community)" class="k-main-collection-item k-community-item" :class="community_tile_class(community)">
				<div class="k-community-tile-text" v-html="community.title"></div>
				<div v-if="unread_post_count[community.community_id]>0" class="k-community-tile-new-post"><v-icon small style="margin-top:-3px;margin-right:2px">fas fa-exclamation-circle</v-icon> <b>UNREAD POST</b></div>
				<div v-if="unread_post_count[community.community_id]==0&&community.role!='pending'" class="k-community-tile-new-post">ALL CAUGHT UP!</div>
			</div>
		</div>
		<SearchResults v-if="show_search_results" @dialog_done="show_search_results=false"/>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import SearchResults from '../resources/SearchResults'
import MiniNav from '../main/MiniNav'

export default {
	components: { SearchResults, MiniNav },
	props: {
		// community_showing: { type: Boolean, required: true },
	},
	data() { return {
		initialized: false,
		show_search_results: false,
	}},
	computed: {
		...mapState(['user_info', 'communities']),
		...mapGetters(['beta_options', 'small_screen']),
		is_communities_admin() {
			return this.user_info.system_role == 'admin'
		},
		unread_post_count() {
			let o = {}
			for (let c of this.communities) {
				o[c.community_id] = 0
				for (let pid of c.post_ids_authored_by_others) {
					if (this.user_info.post_reads[pid] != 1) ++o[c.community_id]
				}
			}
			return o
		},
		community_showing() {
			if (this.$route.fullPath.search(/communities\/(\d+)\b/) > -1) {
				return RegExp.$1
			} else {
				return null
			}
		},
	},
	created() {
		console.log('CommunitiesList created')
		if (this.$store.state.loaded_community_memberships) {
			this.initialized = true
		} else if (!this.initialized) {
			this.get_community_memberships()
		}
	},
	mounted() {
	},
	// beforeRouteEnter (to, from, next) {
	// 	// this fn gets called before the component is rendered by vue-router,
	// 	// and allows for the construction below to let us run code every time the route is accessed.
	// 	next(vm => {
	// 		// console.log('communitiesList beforeRouteEnter fn')
	// 		if (vm.$store.state.loaded_community_memberships) {
	// 			vm.initialized = true
	// 		} else if (!vm.initialized) {
	// 			vm.get_community_memberships()
	// 		}
	// 	})
	// },
	methods: {
		get_community_memberships() {
			this.$store.dispatch('get_community_memberships').then(()=>{
				this.$nextTick(()=>{
					this.initialized = true
				})
			})
		},

		community_tile_class(community) {
			let s = ''
			if (community.role=='pending') {
				s += 'k-community-item-pending '
			}
			// use designated community color if there
			if (community.color != '') s += 'k-list-color-' + community.color
			// otherwise if lp...
			else if (community.type == 'lp') {
				// if we can get the lp record, base on its subject
				let lp = this.$store.state.all_courses.find(x=>x.title==community.title)
				if (lp) s += U.subject_tile_css(lp)
				else s += vapp.color_from_string(community.title)

			// else base on title
			} else s += vapp.color_from_string(community.title)

			if (this.community_showing == community.community_id) {
				s += ' k-community-item-showing'
			} else if (this.community_showing) {
				s += ' k-community-item-not-showing'
			}
			return s
		},

		find_community() {
			this.show_search_results = true
		},

		request_new_community() {
			this.$alert('This will show an interface allowing the user to request that a new community be created.')
		},

		review_community_requests() {
			this.$alert('This will show an interface allowing the admin user to review new community requests.')
		},

		create_community() {
			this.$prompt({
				title: 'Create A New Community',
				text: 'Enter a title, then click “Create Community”. You will be temporarily added as a member of the community, so you can open the community, edit its description, and/or designate other users as administrators for the community. (You can then remove yourself from the community if you wish.)',
				acceptText: 'Create Community',
			}).then(title => {
				if (!empty(title)) {
					this.$store.dispatch('create_community', title)
				}
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		open_community(community) {
			this.$router.push({ path: sr('/communities/$1', community.community_id) })
		}
	}
}
</script>

<style lang="scss">
.k-main-wrapper .k-community-item {
	justify-content:space-between;
	width:165px;
	height:165px;
	padding-top:25px;
	.k-community-tile-text {
		// font-weight:bold;
	}
	.k-community-tile-new-post {
		margin-bottom:-2px;
		font-size:12px;
		white-space:nowrap;
	}
	.fa-exclamation-circle {
		color:#000;
	}
}

.k-main-collection--community-showing {
	display: inline-block;
	padding:0px 10px!important;

	.k-main-collection-header {
		display:none!important;
	}

	.k-main-collection-item {
		width:100px;
		height:100px;
		min-width:100px;
		padding-top:15px;
		border-radius:16px;
		opacity:0.6;
		margin:10px;
		background-color:#f8f8f8;

		.k-community-tile-text {
			font-size:14px;
			line-height:18px;
		}
		.k-community-tile-new-post {
			font-size:9px;
		}
	}
}

.k-community-item-pending {
	border:6px dashed #ccc;
}

.k-main-wrapper .k-main-collection-item.k-community-item-not-showing {
	-webkit-box-shadow: none!important;
	box-shadow: none!important;
}

.k-community-item-showing {
	opacity:1.0!important;
	.k-community-tile-text {
		font-weight:900;
	}
}

</style>
