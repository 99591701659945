<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<div class="k-resource-editor-outer">
		<div class="d-flex mb-2">
			<v-select v-model="resource_type" :items="resource_types" label="Resource Type" tabindex="1" outlined hide-details dense background-color="#fff" style="width:150px" @change="resource_type_selected"></v-select>
			<v-text-field v-show="resource_type!='none'&&resource_type!='html'" hide-details outlined clearable :label="url_field_label" v-model="url" background-color="#fff" dense tabindex="2" class="ml-2" @change="$emit('resource_changed')"></v-text-field>
			<v-btn v-show="resource_type=='safari'" @click="safari_search" class="ml-2" fab small color="light-blue" dark><v-icon>fas fa-search</v-icon></v-btn>
			<v-btn v-show="resource_type=='upload'" @click="upload_file" class="ml-2" fab small color="light-blue" dark><v-icon>fas fa-file-upload</v-icon></v-btn>
		</div>

		<v-textarea v-show="resource_type!='none'" class="my-4" hide-details outlined label="Resource title" v-model="description" background-color="#fff" auto-grow dense rows="1" tabindex="3" @change="$emit('resource_changed')"></v-textarea>

		<v-textarea v-if="collect_long_description" class="my-4" hide-details outlined label="Resource long description (optional)" v-model="long_description" background-color="#fff" auto-grow dense rows="2" tabindex="3" @change="$emit('resource_changed')"></v-textarea>

		<div v-if="resource_type=='assessment'" class="d-flex mb-2">
			<v-text-field hide-details outlined clearable label="Assessment Blueprint URL (optional)" class="mr-1" v-model="blueprint_url" background-color="#fff" dense @change="$emit('resource_changed')"></v-text-field>
			<v-text-field hide-details outlined clearable label="Teacher Test Booklet URL (optional)" class="ml-1" v-model="paper_url" background-color="#fff" dense @change="$emit('resource_changed')"></v-text-field>
		</div>

		<div v-if="show_tags_control" class="d-flex">
			<v-autocomplete chips deletable-chips multiple small-chips dense outlined background-color="#fff" hide-details class="mb-0 pb-0 mr-1" label="Tag to subject(s)" placeholder="(type to search tags)" :items="subject_tag_options" v-model="subject_mappings"></v-autocomplete>
			<v-autocomplete chips deletable-chips multiple small-chips dense outlined background-color="#fff" hide-details class="mb-0 pb-0 mx-1" label="Tag to grade(s)" placeholder="(type to search tags)" :items="grade_tag_options" v-model="grade_mappings"></v-autocomplete>
			<v-autocomplete chips deletable-chips multiple small-chips dense outlined background-color="#fff" hide-details class="mb-0 pb-0 ml-1" label="Tag to courses(s)" placeholder="(type to search tags)" :items="course_tag_options" v-model="course_mappings"></v-autocomplete>
		</div>

		<div class="my-4"><froala-wrapper v-show="resource_type=='html'" :config="editor_config()" v-model="resource_html" /></div>

		<div class="mt-2 mb-4 d-flex justify-center align-center flex-wrap" v-if="show_teacher_facing_control||show_shareable_control">
			<div class="mt-2 mx-4"><v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="teacher_facing" mandatory>
				<v-btn small light :value="false" style="letter-spacing:0">Student-Facing</v-btn>
				<v-btn small light :value="true" style="letter-spacing:0">Teacher-Facing</v-btn>
			</v-btn-toggle></div>

			<div v-if="!teacher_facing" class="mt-2">
				Target Students:
				<v-menu bottom left>
					<template v-slot:activator="{on}"><span v-on="on" class="ml-1">
						<v-btn small v-if="target_students=='all'" color="amber lighten-2">All Students</v-btn>
						<v-btn small v-if="target_students=='ese'" dark color="teal">ESE Students</v-btn>
						<v-btn small v-if="target_students=='adv'" dark color="purple">Advanced Learners</v-btn>
					</span></template>
					<v-list dense>
						<v-list-item @click="target_students='all'"><v-list-item-title>All Students</v-list-item-title></v-list-item>
						<v-list-item @click="target_students='ese'"><v-list-item-title>ESE Students</v-list-item-title></v-list-item>
						<v-list-item @click="target_students='adv'"><v-list-item-title>Advanced Learners</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</div>

			<div v-if="show_lp_category_chooser" class="ml-4 mt-2">
				Special Category:
				<v-menu bottom left>
					<template v-slot:activator="{on}"><span v-on="on" class="ml-1">
						<v-btn small v-if="lp_category==''">Choose…</v-btn>
						<v-btn small v-if="lp_category=='unit_planning'" dark color="green">Unit Planning Document</v-btn>
						<v-btn small v-if="lp_category=='course_guidance'" dark color="cyan">Resources for Course Guidance</v-btn>
						<v-btn small v-if="lp_category=='stem_resource'" dark color="lime">STEM Resource</v-btn>
						<v-btn small v-if="lp_category=='leader_resource'" dark color="brown">Leader Resource</v-btn>
						<v-btn small v-if="lp_category=='course_ebook'" dark color="indigo">Course Ebook</v-btn>
					</span></template>
					<v-list dense>
						<v-list-item @click="lp_category=''"><v-list-item-title>-- NONE --</v-list-item-title></v-list-item>
						<v-list-item @click="lp_category='unit_planning'"><v-list-item-title>Unit Planning Documents</v-list-item-title></v-list-item>
						<v-list-item @click="lp_category='leader_resource'"><v-list-item-title>Leader Resource</v-list-item-title></v-list-item>
						<v-list-item @click="lp_category='course_guidance'"><v-list-item-title>Resources for Course Guidance (shown in all units)</v-list-item-title></v-list-item>
						<v-list-item @click="lp_category='stem_resource'"><v-list-item-title>STEM Resource</v-list-item-title></v-list-item>
						<v-list-item @click="lp_category='course_ebook'"><v-list-item-title>Course Ebooks (shown in all units)</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</div>

			<div v-if="collection_type=='LP'" class="k-inline-checkbox-holder mx-2 mt-3"><v-checkbox class="ma-0 pa-0" :label="'Show in all units'" v-model="cross_unit" hide-details></v-checkbox></div>
			<div v-if="collection_type=='LP'" class="k-inline-checkbox-holder mx-2 mt-3">
				<v-checkbox class="ma-0 pa-0" :label="'Show in both block and traditional formats'" v-model="both_block_and_traditional" hide-details></v-checkbox>
				<div v-show="!both_block_and_traditional"><div class="d-flex align-center" style="font-size:14px"><v-spacer/>
					<div :style="block_or_traditional_switch?'':'font-weight:bold'">Block</div><v-switch label="" v-model="block_or_traditional_switch" hide-details class="mt-0 ml-3 mr-1"></v-switch><div :style="!block_or_traditional_switch?'':'font-weight:bold'">Traditional</div>
				<v-spacer/></div></div>
			</div>
			<div v-if="show_family_control" class="k-inline-checkbox-holder mx-2 mt-3"><v-checkbox class="ma-0 pa-0" :label="'Show to family members'" v-model="family_avail" hide-details></v-checkbox></div>
			<div v-if="show_shareable_control" class="k-inline-checkbox-holder mx-2"><v-checkbox class="ma-0 pa-0" :label="'Share Resource'" v-model="shareable" hide-details tabindex="4"></v-checkbox></div>
			<div v-if="show_todo_control" class="k-inline-checkbox-holder mx-2"><v-checkbox class="ma-0 pa-0" :label="'Require Completion'" v-model="todo" hide-details tabindex="5"></v-checkbox></div>
		</div>

		<div v-if="show_save_buttons" class="d-flex">
			<v-spacer/>
			<v-btn small class="mr-2" color="secondary" @click="$emit('edit_resource_cancel')" tabindex="6"><v-icon small class="mr-2">fas fa-times</v-icon>Cancel</v-btn>
			<v-btn v-if="resource_type!='none'" small color="primary" @click="save_resource" tabindex="5"><v-icon small class="mr-2">fas fa-check</v-icon>Save</v-btn>
		</div>

		<SafariSearch v-if="show_safari_search" @dialog_done="show_safari_search=false"></SafariSearch>
		<!-- <SafariUpload v-if="show_safari_upload" :upload_type="safari_upload_type" @dialog_done="show_safari_upload=false"></SafariUpload> -->
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SafariSearch from './SafariSearch'
// import SafariUpload from './SafariUpload'

export default {
	components: { SafariSearch },	// , SafariUpload
	props: {
		resource: { type: Object, required: false, default() {
			let r = new Resource({resource_id:'new'})
			// mark the resource as new so we can check it for some default values in the created handler below
			r.is_new = true
			return r
		} },
		show_save_buttons: { type: Boolean, required: false, default() { return true } },
		show_shareable_control: { type: Boolean, required: false, default() { return true } },
		show_teacher_facing_control: { type: Boolean, required: false, default() { return true } },
		show_tags_control: { type: Boolean, required: false, default() { return false } },
		show_todo_control: { type: Boolean, required: false, default() { return false } },
		collection_type: { type: String, required: false, default() { return 'LP' }},	// or PD (professional development), PG (parent/guardian [family]), or SC (staff connection)
		professional_development: { type: Boolean, required: false, default() { return false } },
		// note: caller says whether the edited resource is/should be a professional_development resource; we don't look at what the resource itself says.
		district_sanctioned: { type: Boolean, required: false, default() { return false } },
		// note: caller says whether the edited resource is/should be district_sanctioned; we don't look at what the resource itself says.
		show_lp_category_chooser: { type: Boolean, required: false, default() { return false } },
		show_family_control: { type: Boolean, required: false, default() { return false } },
	},
	data() { return {
		resource_types: [
			{ value: 'upload', text: 'Uploaded File' },
			{ value: 'html', text: 'Text (entered in Henry Connects)' },
			{ value: 'video', text: 'Video' },
			{ value: 'assessment', text: 'Illuminate Assessment' },
			{ value: 'interactive', text: 'Interactive/Simulation' },
			{ value: 'website', text: 'Website' },
			// { value: 'google_assignment', text: 'Google Classroom Assignment' },
			{ value: 'document', text: 'Google Drive/MS OneDrive Document' },
			// { value: 'onedrive', text: 'Microsoft OneDrive Document' },
			{ value: 'safari', text: 'SAFARI Resource' },
			{ value: 'lti', text: 'LTI Link' },
		],
		resource_type: this.resource.type,
		description: this.resource.description,
		long_description: this.resource.long_description,
		url: this.resource.url,
		creator: this.resource.creator,
		shareable: this.resource.shareable,
		family_avail: this.resource.family_avail,
		cross_unit: this.resource.cross_unit,
		block_or_traditional: this.resource.block_or_traditional,
		todo: this.resource.todo,
		teacher_facing: this.resource.teacher_facing,
		target_students: this.resource.target_students,
		lp_category: this.resource.lp_category,
		mappings: this.resource.mappings,
		subject_mappings: [],
		grade_mappings: [],
		course_mappings: [],
		resource_html: '',

		// the following 2 are used for assessments
		blueprint_url: '',
		paper_url: '',

		show_safari_search: false,
		show_safari_upload: false,
		safari_upload_type: 'link',	// 'link', 'file'
		uploaded_file: null,
		uploaded_file_name: '',
	}},
	computed: {
		...mapState(['all_courses']),
		subject_tag_options() {
			let arr = [{value: 'subject-all', text: 'All subjects'}]
			for (let s in this.$store.state.subjects) {
				arr.push({value: ('subject-' + s).toLowerCase(), text: s})
			}
			return arr
		},
		grade_tag_options() {
			let arr = [
				{value: 'grade-all', text: 'All grades'},
				{value: 'grade-elem', text: 'Grades K-5'},
				{value: 'grade-mid', text: 'Grades 6-8'},
				{value: 'grade-high', text: 'Grades 9-12'},
			]
			for (let g of this.$store.state.grades) {
				arr.push({value: ('grade-' + g).toLowerCase(), text: 'Grade ' + g})
			}
			return arr
		},
		course_tag_options() {
			let arr = []
			for (let lp of this.all_courses) {
				arr.push({value: 'course-' + lp.course_code, text: U.html_to_text(lp.title)})
			}
			arr.sort((a,b)=>{
				if (a.text < b.text) return -1
				if (a.text > b.text) return 1
				return 0
			})

			return arr
		},
		url_field_label() {
			if (this.resource_type == 'upload') return 'Resource File Identifier'
			if (this.resource_type == 'assessment') return 'Illuminate Assessment ID'
			return 'Resource URL'
		},
		collect_long_description() {
			return this.collection_type == 'PG' || this.collection_type == 'SC'
		},
		both_block_and_traditional: {
			get() { return this.block_or_traditional == 'both' },
			set(val) { this.block_or_traditional = (val) ? 'both' : 'traditional' }
		},
		block_or_traditional_switch: {
			get() { return this.block_or_traditional == 'traditional' },
			set(val) { this.block_or_traditional = (val) ? 'traditional' : 'block' }
		},
	},
	created() {
		// retrieve resource_html if we should already have it
		if (this.resource_type == 'html' && !empty(this.url)) {
			let html_url = '/user-files/' + this.url
			$.ajax({
				url: html_url,
				success: (html)=>{
					this.resource_html = html
				},
				cache: false,
			})
		}

		// for professional_development and SC links, default value for teacher_facing should be true; otherwise false
		if (this.resource.is_new) {
			if (this.professional_development || this.collection_type == 'SC') {
				this.teacher_facing = this.resource.teacher_facing = true
			}
			delete this.resource.is_new
		}

		// split mappings into groups for editing
		for (let m of this.mappings) {
			if (m.indexOf('subject-') == 0) this.subject_mappings.push(m)
			else if (m.indexOf('grade-') == 0) this.grade_mappings.push(m)
			else if (m.indexOf('course-') == 0) this.course_mappings.push(m)
		}

		// for assessment, get blueprint_url and paper_url if there
		if (this.resource_type == 'assessment') {
			let bp = this.resource.supp_links.find(x=>x.type=='blueprint')
			if (!empty(bp)) this.blueprint_url = bp.url

			let p = this.resource.supp_links.find(x=>x.type=='paper')
			if (!empty(p)) this.paper_url = p.url
		}
	},
	mounted() {
	},
	methods: {
		editor_config(text) {
			return U.get_froala_config({
				placeholderText: 'Enter text here',
			})
		},

		safari_search() {
			this.$alert('The ability to search SAFARI Montage for resources is coming in the future.')
			return

			U.loading_start()
			setTimeout(()=>{
				U.loading_stop()
				this.show_safari_search = true
			}, 250)
		},

		safari_upload() {
			U.loading_start()
			setTimeout(()=>{
				U.loading_stop()
				this.safari_upload_type = (this.resource_type == 'upload') ? 'file' : 'link'
				this.show_safari_upload = true
			}, 250)
		},

		upload_file() {
			// prompt for file to import
			this.$prompt({
				text: '<p>Select the file you’d like to upload:</p>',
				promptType: 'file',
				acceptText: 'Choose File',
				cancelText: 'Cancel'
			}).then(file => {
				// console.log(file)
				// TODO: check for appropriate file types -- file.type
				// TODO: check for file size -- file.size
				// make sure file has an extension
				if (file.name.search(/.*(\.\w+)$/) == -1) {
					this.$alert('Uploaded files must have a valid file name extension, such as “.pdf” or “.jpg”')
					return
				}

				this.uploaded_file = file
				this.uploaded_file_name = file.name.replace(/.*(\.\w+)$/, U.new_uuid() + '$1')

				// set url to this.uploaded_file_name
				this.url = this.uploaded_file_name

				// if description is empty, set to the actual file name
				if (empty(this.description)) {
					this.description = file.name
				}

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		get_edited_resource() {
			// bundle data back up into a resource object and return it
			// combine mappings
			this.mappings = this.subject_mappings.concat(this.grade_mappings, this.course_mappings)
			console.log(this.mappings)

			// convert from resource_type to type list from resources.js
			let type = this.resource_type
			if (type == 'google_drive' || type == 'google_assignment' || type == 'onedrive') type = 'document'
			let o = {
				saved: false,
				resource_id: this.resource.resource_id,
				type: type,
				description: this.description,
				long_description: this.long_description,
				url: this.url,
				creator: this.creator,
				shareable: this.shareable,
				family_avail: this.family_avail,
				cross_unit: this.cross_unit,
				block_or_traditional: this.block_or_traditional,
				todo: this.todo,
				teacher_facing: this.teacher_facing,
				target_students: this.target_students,
				lp_category: this.lp_category,
				district_sanctioned: this.district_sanctioned,
				professional_development: this.professional_development,
				mappings: this.mappings,
				supp_links: [],
			}

			// for an assessment save supplemental links
			if (type == 'assessment') {
				if (!empty(this.blueprint_url)) {
					o.supp_links.push({type: 'blueprint', url: this.blueprint_url})
				}
				if (!empty(this.paper_url)) {
					o.supp_links.push({type: 'paper', url: this.paper_url})
				}
			}

			return new Resource(o)
		},

		resource_type_selected() {
			if (this.resource_type == 'upload') {
				this.upload_file()
			}
			this.$emit('resource_changed')
		},

		save_resource() {
			let r = this.get_edited_resource()
			let uploaded_file = null

			// if type is upload, we must have a resource guid in the url field
			if (r.type == 'upload') {
				if (!U.is_uuid(r.url.replace(/\.\w+$/, ''))) {
					this.$alert('For an Uploaded File resource, you must either choose a file to upload, or enter a previously-uploaded Resource File Identifier.')
					return
				}
				// if the user specified a file to be uploaded, include it in the resource save operation
				// (the user can alternatively specify an already-existing filename -- though this is risky and will probably be undocumented)
				if (r.url == this.uploaded_file_name) {
					uploaded_file = this.uploaded_file
				}

			} else if (r.type == 'html') {
				if (empty(this.resource_html)) {
					this.$alert('Please enter the text you’d like to save for this resource.')
					return
				}
				uploaded_file = this.resource_html
				// generate a filename for the html, if not already specified
				if (!U.is_uuid(r.url.replace(/\.\w+$/, ''))) {
					r.url = U.new_uuid() + '.html'
				}

			} else {
				if (empty(r.url)) {
					this.$alert('You must specify the URL for the resource.')
					return
				}
			}

			if (empty(r.description)) {
				this.$alert('Please enter a brief title for the resource.')
				return
			}

			if (this.subject_mappings.length > 0 && this.grade_mappings.length == 0) {
				this.$alert('When tagging resources to one or more subjects, please also choose one or more grade tags. (Choose “All grades” if the resource is relevant for all grades.)')
				return
			}
			if (this.grade_mappings.length > 0 && this.subject_mappings.length == 0) {
				this.$alert('When tagging resources to one or more grades, please also choose one or more subject tags. (Choose “All subjects if the resource is relevant for all subjects.)')
				return
			}

			this.$store.dispatch('save_resource', {resource: r, uploaded_file: uploaded_file}).then(()=>{
				// once resource is saved, emit edit_resource_save
				this.$emit('edit_resource_save', r)
			}).catch(error=>{
				console.log(error)
				this.$alert(sr('A problem occurred when attempting to save the resource. Message from server:<div class="mt-2">$1</div>', error))
			})
		},
	}
}
</script>

<style lang="scss">
.k-resource-editor-outer {
	background-color:#fbe4d4;
	padding:8px;
	border-radius:4px;

	.v-input--checkbox .v-label {
		color:#222;
	}
}

.k-inline-checkbox-holder {
	background-color:#fff;
	padding:4px 6px;
	border:1px solid #9E9E9E;
	border-radius:4px;
	label {
		font-size:14px;
	}
}
</style>
