<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<v-btn v-if="single_item" color="#666" dark fab small class="mt-1 @click=""><v-icon>fas fa-right-from-bracket</v-icon></v-btn>
	<v-menu v-else bottom left><template v-slot:activator="{on}">
		<v-btn class="k-user-menu-show-large" v-on="on" color="#666" dark>
			<span class="k-user-menu-role-display">{{role_display}}</span>
			<v-icon small class="ml-2">fas fa-user</v-icon>
			<v-icon small class="ml-2">fas fa-caret-down</v-icon>
		</v-btn>
		<v-btn class="k-user-menu-show-small" v-on="on" color="#666" icon dark><v-icon small>fas fa-user</v-icon></v-btn>
		</template>
		<v-list dense min-width="180">
			<v-list-item class="k-user-menu-show-small text-center"><v-list-item-title><b>{{user_info.first_name}} {{user_info.last_name}}</b></v-list-item-title></v-list-item>
			<v-divider class="k-user-menu-show-small"/>
			<v-list-item @click="change_role('admin')" v-if="show_role('admin')"><v-list-item-icon><v-icon small v-show="role=='admin'">fas fa-check</v-icon><v-icon small v-show="role!='admin'">fas fa-user</v-icon></v-list-item-icon><v-list-item-title>View site as <b>Admin</b> role</v-list-item-title></v-list-item>
			<v-list-item @click="change_role('staff')" v-if="show_role('staff')"><v-list-item-icon><v-icon small v-show="role=='staff'">fas fa-check</v-icon><v-icon small v-show="role!='staff'">fas fa-user</v-icon></v-list-item-icon><v-list-item-title>View site as <b>Staff</b> role</v-list-item-title></v-list-item>
			<v-list-item @click="change_role('student')" v-if="show_role('student')"><v-list-item-icon><v-icon small v-show="role=='student'">fas fa-check</v-icon><v-icon small v-show="role!='student'">fas fa-user</v-icon></v-list-item-icon><v-list-item-title>View site as <b>Student</b> role</v-list-item-title></v-list-item>
			<v-list-item @click="change_role('parent')" v-if="show_role('parent')"><v-list-item-icon><v-icon small v-show="role=='parent'">fas fa-check</v-icon><v-icon small v-show="role!='parent'">fas fa-user</v-icon></v-list-item-icon><v-list-item-title>View site as <b>Parent/Guardian</b> role</v-list-item-title></v-list-item>
			<v-list-item @click="mimic_another_user" v-if="enable_mimic_another_user"><v-list-item-icon><v-icon small>fas fa-user-secret</v-icon></v-list-item-icon><v-list-item-title>{{mimic_user_noun}}</v-list-item-title></v-list-item>
			<v-divider/>
			<v-list-item @click="update_sis_data"><v-list-item-icon><v-icon small>fas fa-rotate</v-icon></v-list-item-icon><v-list-item-title>Update your class enrollments from Infinite Campus</v-list-item-title></v-list-item>
			<v-list-item v-show="allow_beta_options_choice" @click="choose_beta_modes=true"><v-list-item-icon><v-icon small>fas fa-toggle-on</v-icon></v-list-item-icon><v-list-item-title>Choose <b style="font-weight:900"><span class="red--text text--darken-2">BETA FUNCTIONALITY</span></b></v-list-item-title></v-list-item>
			<!-- <v-divider v-if="user_info.role=='parent'"/> -->
			<!-- <v-list-item @click="show_child_list" v-if="user_info.role=='parent'"><v-list-item-icon><v-icon small>fas fa-child</v-icon></v-list-item-icon><v-list-item-title>Manage Designated Students ({{child_count}})</v-list-item-title></v-list-item> -->
			<!-- <v-list-item @click="edit_parent_account" v-if="user_info.system_role=='parent'"><v-list-item-icon><v-icon small>fas fa-cog</v-icon></v-list-item-icon><v-list-item-title>Edit HenryConnects Parent/Guardian Account Settings</v-list-item-title></v-list-item> -->
			<v-divider/>
			<v-list-item><v-list-item-icon><v-icon small>fas fa-calendar-alt</v-icon></v-list-item-icon><v-list-item-title>Academic Year {{academic_year_display}} <v-btn v-if="allow_academic_year_change" x-small color="secondary" style="margin-bottom:3px; margin-left:4px" @click="change_academic_year">Change</v-btn></v-list-item-title></v-list-item>
			<v-divider/>
			<v-list-item @click="sign_out"><v-list-item-icon><v-icon small>fas fa-sign-out-alt</v-icon></v-list-item-icon><v-list-item-title><b>Sign Out</b></v-list-item-title></v-list-item>
		</v-list>
	</v-menu>

	<v-dialog v-model="choose_beta_modes" max-width="800" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Beta Modes</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<div class="mb-2">Enable or disable the following HenryConnects <b style="font-weight:900"><span class="red--text text--darken-2">BETA FUNCTIONALITY</span></b>:</div>

				<!-- <div><v-checkbox :label="'Enable HenryConnects “Family View”'" @change="update_beta_options" v-model="beta_options.parent_view" hide-details></v-checkbox></div> -->
				<!-- <div><v-checkbox :label="'Create Lesson Plans in HenryConnects'" @change="update_beta_options" v-model="beta_options.lesson_mode" hide-details></v-checkbox></div>
				<div><v-checkbox :label="'Show content collections using new look and feel'" @change="update_beta_options" v-model="beta_options.new_collections" hide-details></v-checkbox></div> -->
				<div v-if="beta_available('manage_assignments')"><v-checkbox :label="'Manage assignments in HenryConnects (reload after turning on)'" @change="update_beta_options" v-model="beta_options.manage_assignments" hide-details></v-checkbox></div>
				<div v-if="!beta_available('manage_assignments')&&manage_assignments"><i><b>Note:</b> the beta <b>assignment management</b> functionality in HenryConnects is turned on for you by default.</i></div>
				<!-- <div v-if="$store.getters.system_role=='admin'"><v-checkbox :label="'ADMIN ONLY: Enable SAFARI Montage search (reload after turning on)'" @change="update_beta_options" v-model="beta_options.show_safari" hide-details></v-checkbox></div> -->

				<!-- <p class="text-center mt-4"><v-btn small color="secondary" @click="choose_beta_modes_more_info=!choose_beta_modes_more_info"><span v-if="!choose_beta_modes_more_info">More Information</span><span v-else>Less Information</span></v-btn></p> -->

				<div v-show="choose_beta_modes_more_info" class="mt-4" style="font-size:16px">
					<ul>
						<li><b>“Family View”</b> allows family members of Henry County students to track learning progressions and standards, and get access to appropriate course materials (e.g. HMH resources) through HenryConnects. The BETA version of Family View is available to Henry County Schools staff members in Spring 2022, and will be rolled out to all district families in Fall 2022. If you enable the BETA version, you can then choose to view the site as a Parent/Guardian by clicking the <nobr>“<v-icon small class="mr-2">fas fa-user</v-icon><v-icon small>fas fa-caret-down</v-icon>”</nobr> button above.</li>
						<li class="mt-2">We are working on functionality allowing teachers to create <b>Lesson Plans</b> in HenryConnects. You can try the BETA version of the Lesson Planning tool in Spring 2022. In Fall 2022, the tool will be available to all teachers, and will include the ability to work collaboratively with other teachers on shared plans.</li>
						<li class="mt-2">We are also working on a new look and feel for <b>content collections</b>, including learning progression and PD resources. You can try the BETA version of this new look and feel now; the final version will be in place for Fall 2022.</li>
					</ul>
				</div>
			</v-card-text>

			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="choose_beta_modes=false">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<ChildList v-if="child_list_showing" @cancel_editor="hide_child_list" />
	<AdminParentEdit v-if="editing_parent_account" :parent_user="user_info" @editor_cancel="editing_parent_account=false" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ChildList from './ChildList'
import AdminParentEdit from '../admin/AdminParentEdit'

export default {
	components: { ChildList, AdminParentEdit },
	data() { return {
		child_list_showing: false,
		editing_parent_account: false,
		choose_beta_modes: false,
		choose_beta_modes_more_info: false,
		beta_options: Object.assign({}, this.$store.getters.beta_options),
	}},
	computed: {
		...mapState(['user_info', 'single_item']),
		...mapGetters(['academic_year_display', 'child_count', 'system_role', 'role', 'manage_assignments', 'user_is_principal_or_ap', 'manage_assignments']),
		simulated_role: {
			get() { return this.$store.state.lst.simulated_role },
			set(val) { this.$store.commit('lst_set', ['simulated_role', val]) }
		},
		simulated_user: {
			get() { return this.$store.state.lst.simulated_user },
			set(val) { this.$store.commit('lst_set', ['simulated_user', val]) }
		},
		role_display() {
			if (this.role == 'parent') return 'Parent / Guardian'
			return U.capitalize_word(this.role)
		},
		allow_academic_year_change() {
			// show button to change AY if the user has rights to edit all LPs
			if (this.initialized < 0) return
			if (vapp.has_admin_right('lp.course.all') || (this.$store.state.allow_academic_year_change_for_all_staff && this.user_info.system_role == 'staff')) return true
			return false
		},
		allow_beta_options_choice() {
			return true
			// return this.user_info.email == 'teacher@henry.k12.ga.us'
		},
		can_mimic_all_users() {
			if (vapp.has_admin_right('su')) return true
			if (vapp.has_admin_right('mimic_all')) return true
			return false
		},
		enable_mimic_another_user() {
			if (this.can_mimic_all_users) return true
			if (this.system_role == 'staff') return true
			return false
		},
		mimic_user_noun() {
			if (this.can_mimic_all_users || this.user_is_principal_or_ap) return 'Mimic another user'
			else return 'Mimic one of your students'
		},
	},
	created() {
	},
	mounted() {
		// add a reference to this component in vapp so that FamilyHome can show the ChildList
		vapp.user_menu = this
	},
	watch: {
	},
	methods: {
		beta_available(key) {
			// admins can always see all beta options
			if (this.system_role=='admin') return true

			// check the beta_options_available structure imported from config.php via initialize_app
			let beta_options_available = this.$store.state.beta_options_available

			// if the given key doesn't exist, the beta option is available for everyone...
			if (empty(beta_options_available[key])) {
				// but (this is confusing) if the option is *always on* for this user, we *don't* show the option
				if (!empty(this.$store.state.beta_options_on[key])) {
					for (let val of this.$store.state.beta_options_on[key]) {
						// if val is an email address, check for that email
						if (val.includes('@') && val.toLowerCase() == this.user_info.email.toLowerCase()) return false

						// else it should be a "district_department" -- e.g. a school
						if (this.user_info.district_department.includes(val)) return false
					}
				}

				return true
			}

			// if we're here, we need to check the entries for beta_options_available...
			for (let val of beta_options_available[key]) {
				// if val is an email address, check for that email
				if (val.includes('@') && val.toLowerCase() == this.user_info.email.toLowerCase()) return true

				// else it should be a "district_department" -- e.g. a school
				if (val == this.user_info.district_department) return true
			}

			// if we get here, not available
			return false
		},

		update_beta_options() {
			console.log('setting beta options', this.beta_options)
			for (let prop in this.beta_options) {
				this.$store.commit('beta_option_set', [prop, this.beta_options[prop]])
			}
		},

		show_role(to_show) {
			// you can only adopt the admin role if your system_role is admin
			if (to_show == 'admin') {
				return this.system_role == 'admin'
			}

			if (to_show == 'staff') {
				return this.system_role == 'staff' || this.system_role == 'admin'
			}

			if (to_show == 'parent') {
				if (this.system_role == 'parent') return true
				if (this.system_role == 'staff' || this.system_role == 'admin') return true
				return false
			}

			if (to_show == 'student') {
				// if manage_assignments mode is on, allow staff/admin to switch to student role
				if (this.manage_assignments && (this.system_role == 'staff' || this.system_role == 'admin')) return true

				// otherwise we don't allow other roles to switch to the student role
				return this.system_role == 'student'
			}

			return false
		},

		change_role(new_role) {
			// if we're changing back to the user's system_role, cancel simulation
			if (new_role == this.system_role) {
				this.simulated_role = ''
				this.simulated_user = ''
			} else {
				// else set the new role in local_storage
				this.simulated_role = new_role
			}
			// then reload
			this.reload()
		},

		reload() {
			// TODO: be a little more careful about what page we're sending the person to depending on the role?
			// for now, just reload if we're viewing a course (since you may be viewing what the page looks like in the other role); otherwise send to /welcome
			if (window.location.toString().indexOf('/courses/') > -1) {
				window.location.reload()
			} else {
				window.location = '/welcome'
			}
		},

		mimic_another_user() {
			// superusers and users with mimic_all rights can mimic anyone
			if (this.can_mimic_all_users) {
				this.$prompt({
					text: 'Find the email address of the user you wish to mimic:',
					promptType: 'autocomplete',
					serviceName: 'email_search',
					initialValue: '',
					acceptText: 'Mimic User',
				}).then(email => {
					// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
					email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

					if (empty(email) || email.indexOf('@') == -1) {
						this.$alert('Please enter a valid user email.').then(x=>{this.mimic_another_user()})
						return
					}
					
					this.simulated_user = email
					this.reload()
					
				}).catch(n=>{console.log(n)}).finally(f=>{});

			} else if (this.user_is_principal_or_ap) {
				// principals/aps can mimic others from their schools; first get a list of them
				U.loading_start()
				// U.ajax('get_all_users_for_department', {user_id:this.user_info.user_id, department:['Impact Academy']}, result=>{
				U.ajax('get_all_users_for_department', {user_id:this.user_info.user_id, department:this.user_info.district_department}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert('Error loading search results.')
						return
					}
					let arr = []
					for (let row of result.users) arr.push({value: row.email, text: `${row.first_name} ${row.last_name} (${row.email})`})
					this.$prompt({
						text: 'Choose a user from your school or department that you wish to mimic:',
						promptType: 'autocomplete',
						autocompleteItems: arr,
						initialValue: '',
						acceptText: 'Mimic User',
					}).then(email => {
						// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
						email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

						if (empty(email) || email.indexOf('@') == -1) {
							this.$alert('Please choose a user.').then(x=>{this.mimic_another_user()})
							return
						}
						
						this.simulated_user = email
						this.reload()
						
					}).catch(n=>{console.log(n)}).finally(f=>{});
				});

			} else if (this.system_role == 'staff') {
				// teachers can mimic their students; get a list of them
				let arr = []
				for (let cl of this.$store.state.sis_classes) {
					if (!empty(cl.students)) for (let sarr of cl.students) {
						if (!empty(sarr)) for (let s of sarr) {
							if (!empty(s) && !arr.find(x=>x.value == s.email)) {
								arr.push({value: s.email, text: `${s.givenName} ${s.familyName} (${s.email})`})
							}
						}
					}
				}
				console.log(arr.length)
				arr.sort((a,b) => U.natural_sort(a.text, b.text))
				this.$prompt({
					text: 'Choose a student that you wish to mimic:',
					promptType: 'autocomplete',
					autocompleteItems: arr,
					initialValue: '',
					acceptText: 'Mimic Student',
				}).then(email => {
					// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
					email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

					if (empty(email) || email.indexOf('@') == -1) {
						this.$alert('Please choose a student.').then(x=>{this.mimic_another_user()})
						return
					}
					
					this.simulated_user = email
					this.reload()
					
				}).catch(n=>{console.log(n)}).finally(f=>{});
			}
		},

		change_academic_year() {
			vapp.change_academic_year()
		},

		show_child_list() {
			this.child_list_showing = true
		},

		hide_child_list() {
			this.child_list_showing = false
		},

		edit_parent_account() {
			this.editing_parent_account = true
		},

		update_sis_data() {
			this.$confirm({
				title: 'Update Class Enrollments',
				text: 'To update your class enrollments from Infinite Campus, click PROCEED below, then sign back in to HenryConnects.<div class="mt-2"><b>Note:</b> your class enrollments are automatically checked, and updated if necessary, the first time you sign in to HenryConnects each day, so the only reason you should need to run this “manual” update process is if you think your enrollments may have changed in the last 24 hours.</div>',
				acceptText: 'Proceed',
				dialogMaxWidth: 540,
				focusBtn: true,		// focus on the accept btn when dialog is rendered
			}).then(y => {
				this.$store.dispatch('update_sis_data')
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		sign_out() {
			this.$store.dispatch('sign_out')
		},
	}
}
</script>

<style lang="scss">
.k-user-menu-show-large { display:flex; }
.k-user-menu-show-small { display:none; }

.v-btn.k-user-menu-show-large {
	margin-top:5px;
}
</style>
