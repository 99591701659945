<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-main-wrapper">
	<div class="k-main-wrapper-bgd"></div>
	<v-app-bar app dense :hide-on-scroll="small_screen" class="k-app-toolbar">
		<!-- <v-btn v-show="main_mode!='welcome'" class="mt-2 mr-2" @click="go_to_route('welcome',$event)" fab small color="primary"><v-icon>fas fa-home</v-icon></v-btn> -->
		<img @click="logo_clicked" class="k-toolbar__logo-img" src="./../../images/HenryConnects_Main_LOGO.svg">

		<v-spacer/>
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="alt_role_label" color="pink darken-3" class="mt-1 k-tight-btn k-nocaps-btn" dark @click="return_to_default_role"><v-icon small class="mr-2">fas fa-glasses</v-icon>{{alt_role_label}} <v-icon small class="ml-2">fas fa-arrow-rotate-left</v-icon></v-btn></template>Click to return to default role</v-tooltip>
		<v-spacer/>

		<div v-if="!small_screen&&manage_assignments" class="k-toolbar-date" :class="simulated_date?'red--text text--darken-3':''" @click="simulate_date">{{date_string+(simulated_date?' (SIMULATED)':'')}}</div>
		<div v-if="!small_screen" class="k-toolbar__username">{{user_info.first_name}} {{user_info.last_name}}</div>
		<UserMenu/>
	</v-app-bar>

	<!-- <transition name="k-fade"><keep-alive><router-view :key="router_key"></router-view></keep-alive></transition> -->
	<keep-alive><router-view :key="router_key"></router-view></keep-alive>

	<div class="k-copyright" @click="henry_logo_clicked">© 2023 Henry County Schools &nbsp; (v{{$store.state.app_version}})</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import UserMenu from './UserMenu'

export default {
	components: { UserMenu },
	data() { return {
		todo_mousedown: false,
	}},
	computed: {
		...mapState(['user_info', 'todo_item', 'single_item']),
		...mapGetters(['small_screen', 'role', 'system_role', 'beta_options', 'manage_assignments']),
		simulated_role: {
			get() { return this.$store.state.lst.simulated_role },
			set(val) { this.$store.commit('lst_set', ['simulated_role', val]) }
		},
		simulated_user: {
			get() { return this.$store.state.lst.simulated_user },
			set(val) { this.$store.commit('lst_set', ['simulated_user', val]) }
		},
		alt_role_label() {
			if (this.simulated_role) {
				if (this.role == 'student') return 'STUDENT View'
				if (this.role == 'parent') return 'GUARDIAN View'
				if (this.role == 'staff') return 'STAFF View'
			} else if (this.simulated_user) {
				return 'MIMICKING ' + this.simulated_user
			}
		},
		main_mode() {
			let path = this.$route.fullPath
			return path.replace(/^\/(\w+).*/, '$1')
		},
		router_key() {
			let path = this.$route.fullPath
			// by using this as the key, we preserve the right amount of "history" in our components...
			if (vapp.$route.name == 'course_view') {
				return path.replace(/(\/courses\/lp\/\d+).*/, '$1')
			} else {
				return path
			}
			// we use to always use fullPath, but that made it so that every time you viewed a new assignment, a new copy of CoursesMain was rendered
		},
		simulated_date() { return this.$store.state.lst.simulated_date },
		date_string() {
			return date.format(this.$store.state.now_date_obj, 'ddd M/D h:mm')	// Thu 1/16 9:01
		},
	},
	created() {
		window.main_view = this
	},
	mounted() {
	},
	watch: {
	},
	methods: {
		logo_clicked() {
			// if we're viewing a single item, don't do anything when the logo is clicked
			if (this.single_item) return
			// else go to the welcome route
			this.go_to_route('welcome')
		},

		go_to_route(new_route, event) {
			if (!empty(event) && !empty(event.target)) $(event.target).closest('button').blur()

			new_route = '/' + new_route
			if (this.$route.path == new_route) return

			this.$router.push({ path: new_route })
		},

		sign_out() {
			// for demo purposes we sign out by clearing password from local_storage and reloading
			U.local_storage_set('district_portal_login_password', '')
			window.location.reload()

			// this.$store.dispatch('sign_out')
		},

		show_change_log() {
			// changelog not up todate...
			// this.go_to_route('changelog')
		},
		henry_logo_clicked() {
			window.open('https://schoolwires.henry.k12.ga.us/')
		},

		cycle_bgd_image() {
			vapp.show_bgd_image(-1)
		},

		todo_complete() {
			this.$store.commit('set', ['todo_item', ''])
			U.local_storage_clear('district_portal_todo_item')
		},

		return_to_default_role() {
			this.simulated_role = ''
			this.simulated_user = ''
			window.location.reload()
		},

		simulate_date() {
			// only system admins/staff can simulate dates
			if (!(this.system_role == 'admin' || this.system_role == 'staff')) return

			let msg = 'Enter a date, using the format “YYYY-MM-DD”, to simulate what assignments in the HenryConnects interface will look like for this date.'
			if (this.$store.state.simulated_date) msg += '<br><br>Click “DON’T SIMULATE DATE” to cancel the current date simulation.'
			this.$prompt({
				title: 'Simulate Date',
				text: msg,
				initialValue: this.$store.state.simulated_date,
				disableForEmptyValue: true,
				acceptText: 'SIMULATE DATE',
				cancelText: 'DON’T SIMULATE DATE'
			}).then(date_string => {
				// debugger
				date_string = $.trim(date_string)
				if (empty(date_string)) {
					this.$store.commit('lst_set', ['simulated_date', ''])
					return
				}
				if (date_string.search(/^\d\d\d\d-\d\d-\d\d$/) == -1) {
					this.$alert('Please use the format “YYYY-MM-DD” when entering a simulated date.').then(x=>this.simulate_date())
					return
				}
				this.$store.commit('lst_set', ['simulated_date', date_string])
				this.$store.commit('set_now_date')
			}).catch(n=>{
				this.$store.commit('lst_set', ['simulated_date', ''])
				this.$store.commit('set_now_date')
			}).finally(f=>{})
		},
	}
}
</script>

<style lang="scss">
.k-main-wrapper {
	.k-main-wrapper-bgd {
		position:fixed;
		z-index:-1;
		left:0;
		top:0;
		width:100vw;
		height:100vh;
		background:rgba(0, 0, 0, 0.65)!important;
	}

	margin-bottom:40px;
	// margin-right:15px;

	.k-elevated {
		-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
		box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
	}

	.k-app-toolbar {
		min-height:56px;
		background-color:rgba(255, 255, 255, $toolbar-white-opacity)!important;

		.k-toolbar__logo-img {
			height:40px;
			cursor:pointer;
		}

		.k-toolbar__todo {
			flex: 1 1 auto;
			margin:0 15px;
			justify-content:center;
			line-height:18px;
			display:flex;
			align-items:center;
			text-align:right;
		}

		.k-toolbar__todo-wrapper {
			position:relative;
			line-height:38px;
		}

		.k-toolbar__todo-wrapper:before {
			content:'Done?';
			font-size:10px;
			position:absolute;
			top:-12px;
			left:1px;
			width:100%;
			text-align:center;
		}

		.k-toolbar__username {
			margin-right:10px;
			margin-top:5px;
			font-family:$display-font;
			font-weight:900;
			font-size:1.4em;
			line-height:1.0em;
			text-align:center;
		}

		.k-toolbar-date {
			font-size:14px;
			font-family:$display-font;
			margin-right:16px;
			margin-top:8px;
			font-weight:bold;
			color:#333;
		}
	}

	.k-main-division-wrapper {
		// margin-left:140px;
		margin-top:50px;
	}

	.k-page-wrapper {
		background-color:$page-background;
		padding:15px 80px;
		// max-width:1200px;
		margin:30px auto 0 auto;
		// border-radius: 8px;
	}

	.k-page-title {
		font-size:27px;
		margin-bottom:20px;
	}
	.k-page-title-btn {
		width:70px;
		height:70px;
		background-color:$secondary-color;
		color:$primary-color;
		border-radius:50%;
		margin-right:10px;
		font-size:40px;
		float:left;
	}

	.k-main-collection {
		// border-radius:8px;
		padding:15px 50px;
		// max-width:1000px;
		// margin:0 auto;
		margin:0;
		width:100vw;
		background-color:$page-background;
		// border-top:5px solid #fff;
	}

	.k-main-collection--item-showing {
		border-radius:0 16px 16px 0;
		width:auto;
		min-height:140px;
	}

	.k-main-collection-header {
		font-size:30px;
		font-weight:900;
		margin:5px 15px;
		text-align:left;
	}

	.k-main-collection-body {
		display:flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items:center;
		justify-content: center;
		margin:4px 8px;
		clear:both;
	}

	.k-main-collection-item {
		margin:12px 16px;
		// border:3px solid $primary-color;
		padding:8px;
		font-size:16px;
		line-height:22px;
		text-align:center;
		vertical-align:middle;
		// background-color:$secondary-color;

		width:165px;
		height:165px;
		border-radius:25px;

		display:flex;
		justify-content: center;
		flex-direction: column;

		position:relative;
		cursor:pointer;

		-webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	}

	.k-lp-tile {
		width:120px!important;
		height:120px!important;
		border-radius:30px!important;
		font-size:14px!important;
		line-height:18px!important;
		// hyphens: auto;	// this doesn't seem to really help; e.g. it doesn't break "microeconomics"
		.k-class-tile-course-code {
			position:absolute;
			left:0px;
			bottom:10px;
			font-size:9px;
			width:100%;
			text-align:center;
		}
	}

	.k-lp-tile-long-title {
		font-size:12px!important;
		line-height:16px!important;
	}

	.k-lp-tile-extra-long-title {
		font-size:10px!important;
		line-height:13px!important;
	}

	.k-lp-tile-inactive {
		border:4px dashed #ccc;
	}

}

.k-copyright {
	position:absolute;
	bottom:0;
	right:0;
	padding:10px;
	text-align:right;
	white-space:nowrap;
	font-size:.85em;
	color:#999;
	font-weight:bold;

	// cursor pointer for easter egg
	cursor:pointer;
}


.k-fade-enter-active {
	transition: opacity .25s;
}
.k-fade-enter, .k-fade-leave-active {
	opacity: 0;
}

</style>
