<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-container class="k-login-container" fill-height>
		<v-spacer/>
		<div class="k-login-wrapper">
			<img class="k-login__logo-img" src="./../../images/HenryConnects_Main_LOGO.svg" @click="force_magic_signin=!force_magic_signin">
			<!-- sign in with Google alternative -->
			<div v-show="use_google_signin&&!force_magic_signin">
				<div v-show="use_google_signin&&!force_magic_signin" class="k-google_signin_btn" id="g_signin_admin"></div>
				<div v-if="friendly_login_error!=null" class="k-login-error" v-html="friendly_login_error"></div>
				<div v-else class="k-signin-help"><b>Staff and students</b>, please Sign In with Google at this time. <b>Parent/guardian</b> access is temporarily unavailable.</div>
			</div>
			<div v-show="!use_google_signin||force_magic_signin">
				<v-text-field @keyup="keyup" ref="email_field" class="mt-3" background-color="#eee" outlined hide-details prepend-inner-icon="fa fa-envelope" label="HCS Email or Infinite Campus Username*" v-model="email"></v-text-field>
				<v-text-field @keyup="keyup" class="mt-4" background-color="#eee" outlined password hide-details prepend-inner-icon="fa fa-key" :type="show_password ? 'text' : 'password'" :append-icon="show_password ? 'fas fa-eye' : 'fas fa-eye-slash'" @click:append="show_password = !show_password" label="Password" v-model="password"></v-text-field>
				<div class="d-flex mt-4 align-center">
					<v-btn small text color="primary" class="k-tight-btn" @click="create_guardian" style="margin-top:-4px"><v-icon small class="mr-1">fas fa-home-user</v-icon>Create Family View Account</v-btn>
					<v-spacer/>
					<v-btn @click="sign_in" color="primary">Sign In</v-btn>
				</div>
				<div v-if="friendly_login_error!=null" class="k-login-error" v-html="friendly_login_error"></div>
				<div v-else class="k-signin-help">*<b>Staff and students</b>, please sign in with your HCS email address, or use the “Sign in with Microsoft” option below. <b>Parents and guardians</b>, please sign in with your Infinite Campus username.</div>
			</div>

			<!-- <div v-if="allow_msft_signin||true" class="d-flex mt-4 pt-4 pb-4 center justify-center align-center" style="border-top:1px solid #aaa; height:64px;">
				<v-btn class="mt-2 k-nocaps-btn k-tight-btn" large color="#555" dark @click="msft_sign_in" style="width:340px; height:50px; border-radius: 4px; position:relative;">
					<img src="../../images/msftlogo.png" alt="Microsoft Logo" style="width:42px; height:42px; border-radius:3px; left:-16px; top:-11px; position:absolute;"/>
					<div style="padding-left:46px; font-size:18px;">Sign in with Microsoft</div>
				</v-btn>
			</div> -->

		</div>


		<v-spacer/>
		<div class="k-copyright" @click="cycle_bgd_image">© 2022 Henry County Schools &nbsp; (v{{$store.state.app_version}})</div>
		<AdminParentEdit v-if="new_parent_user" :parent_user="new_parent_user" @editor_cancel="new_parent_user=null" />
	</v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminParentEdit from '../admin/AdminParentEdit'
export default {
	components: { AdminParentEdit },
	data() { return {
		email: '',
		password: '',
		show_password: false,
		new_parent_user: null,
		force_magic_signin: false,
	}},
	computed: {
		...mapState(['login_error', 'use_google_signin', 'google_client_id', 'allow_msft_signin', 'msft_initialize_signin_domain']),
		friendly_login_error() {
			if (this.login_error == 'ad_fail') {
				return 'Your email address and/or password could not be validated via Active Directory.'
			} else if (this.login_error == 'ic_fail_password_error') {
				return 'Your username and/or password could not be validated via Infinite Campus. If you’ve forgotten your password, <a href="https://campus.henry.k12.ga.us/campus/portal/parents/henry.jsp" target="_blank">click here</a>, then click the “Forgot Password?” link.'
			} else if (this.login_error == 'ic_fail_logins_exceeded') {
				return 'Your username and/or password could not be validated via Infinite Campus. To sign into HenryConnects, you will need to first <a href="https://campus.henry.k12.ga.us/campus/henry.jsp?status=captcha&captcha" target="_blank">click here</a> and complete a CAPTCHA challenge. (You can also reset your password from there.)'
			} else if (this.login_error && this.login_error.indexOf('ic_fail') > -1) {
				return 'Your username and/or password could not be validated via Infinite Campus (2).'
			} else if (this.login_error == 'password_fail') {
				return 'Your email address and/or password could not be validated.'
			} else if (this.login_error == 'ic_not_allowed') {
				return 'HenryConnects Family View will be available once the Infinite Campus Parent Portal goes live.'
			} else if (this.login_error == 'student_not_allowed') {
				return 'HenryConnects is not currently open to students.'
			} else if (this.login_error == 'bad_sign_in_method') {
				return '<b>Staff and students</b>, please sign in using your Henry County Schools email address (ending in “@henry.k12.ga.us”).'
			} else if (this.login_error == 'signin_with_google_no_email_for_user') {
				return 'Your Google email was not recognized as an existing HenryConnects user.'
			} else {
				return this.login_error
			}
		},
	},
	created() {
	},
	mounted() {
		// if we're using google signin, initialize it
		if (this.use_google_signin) {
			this.google_signin_initialize()

		} else {
			// otherwise fill in with the last-entered email
			this.$nextTick(()=>{
				this.email = U.local_storage_get('district_portal_login_email', '')
				$(this.$refs.email_field.$el).find('input').focus()
			})
		}
	},
	methods: {
		google_signin_initialize() {
			U.loading_start()
			// google sign in should be loaded via https://accounts.google.com/gsi/client in index.html
			// if it isn't loaded yet, try again in 100 ms
			if (typeof(google) == 'undefined' || typeof(google.accounts) == 'undefined') {
				setTimeout(()=>this.google_signin_initialize(), 100);
				console.log('re-try google.accounts.id.initialize')
				return;
			}
			U.loading_stop()

			console.log('id', this.google_client_id)

			// api docs https://developers.google.com/identity/gsi/web/reference/js-reference
			google.accounts.id.initialize({
				client_id: sr("$1.apps.googleusercontent.com", this.google_client_id),
				callback: this.handleCredentialResponse,
			});
			
			google.accounts.id.renderButton(
				document.getElementById("g_signin_admin"),
				{ 
					theme: "filled_blue", 
					size: "large",
				} 
			);
			google.accounts.id.prompt(); // optionally, display the One Tap dialog
		},

		handleCredentialResponse(response) {
			//console.log("Encoded JWT ID token: " + response.credential);
			let id_token = response.credential
			if (id_token) {
				// pass id_token back to initialize_portal service, which will sign the user in
				vapp.initialize_app({google_id_token: id_token})
			} else {
				// TODO: what do we do here?
				alert('Google sign in handleCredentialResponse failed')				
			}
        },

		cycle_bgd_image() {
			vapp.show_bgd_image(-1)
		},

		keyup(event) {
			if (event.code == 'Enter' || event.keyCode == 13) this.sign_in()
		},

		sign_in($evt) {
			this.email = $.trim(this.email)
			this.password = $.trim(this.password)

			if (empty(this.email)) {
				this.$alert('Please enter your email address.')
				return
			}

			if (empty(this.password)) {
				this.$alert('Please enter your password.')
				return
			}

			// shortcut: if email has no @, append @henry.k12.ga.us
			// if (this.email.indexOf('@') == -1) {
			// 	this.email += '@henry.k12.ga.us'
			// }

			// READY TO SIGN IN...

			// store email in local storage
			U.local_storage_set('district_portal_login_email', this.email)
			vapp.initialize_app({
				email: this.email,
				password: this.password,
				// if user holds shift key down while clicking sign in btn, force a check of ic data (otherwise we only check once every 24 hours)
				force_ic_check: ($evt && $evt.shiftKey) ? 'yes' : 'no',
			})
		},

		// Microsoft SAML sign in
		msft_sign_in() {
			// READY TO SIGN IN...

			// saml/login.php redirects to the microsoft login page
			window.location.href = sr('$1/src/saml/login.php', this.msft_initialize_signin_domain)
			//window.location.href = 'http://localhost:8003/src/saml/login.php'
		},

		create_guardian() {
			this.$confirm({
			    title: 'Create a Family View Account',
			    text: 'If you already have an Infinite Campus Parent Portal account, you can use that username and password to sign in to HenryConnects.<div class="my-2">If you do not have an Infinite Campus Parent Portal account, click the button below to create one.</div>If you need further assistance with your account, please contact the registrar at your child’s school.',
			    acceptText: 'Create Infinite Campus Account',
				dialogMaxWidth: 540,
			}).then(y => {
				window.open('https://campus.henry.k12.ga.us/campus/portal/parents/henry.jsp')
			}).catch(n=>{console.log(n)}).finally(f=>{})

			// set z-index of dialog to a really high value to cover the google translate btn
			this.$nextTick(x=>$('.v-dialog__content--active').css('z-index', '20200000'))
		},
	},
}
</script>

<style lang="scss">
.k-login-container {
	// background-color:$primary-color;
	max-width:100vw;
	width:100vw;
	padding:0;
}

.k-login-wrapper {
	border-radius:8px;
	background:rgba(255, 255, 255, 0.9);
	padding:10px 10px 52px 10px;
	margin-top:-64px;
	margin-top:4px;
	padding-bottom:10px;
	// height:308px;
	// min-width:480px;

	.k-login__logo-img {
		max-width:380px;
		width:calc(100vw - 72px);
		display:block;
		margin:0 auto 20px auto;
	}
}

.k-login-error {
	background-color:$v-red-lighten-4;
	color:$v-red-darken-4;
	border-radius:5px;
	padding:8px;
	margin: 16px 0 0px 0;
	max-width: 500px;
	font-size: 14px;
	line-height:18px;
	// height:70px;	// keep this constant no matter how much text is there
}

.k-signin-help {
	background-color:$v-light-blue-lighten-4;
	color:$v-blue-darken-4;
	border-radius:5px;
	padding:8px 8px;
	margin: 16px 0 0px 0;
	max-width: 500px;
	font-size: 14px;
	line-height:18px;
	// height:70px;	// keep this constant no matter how much text is there
}

.k-google_signin_btn {
	cursor:pointer;
	width:240px;
	margin: 80px 0px 130px 124px;
	transform: scale(1.9);
}

</style>
