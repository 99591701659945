<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-cvlp-unit" :class="wrapper_css_class">
	<div class="k-cvlp-unit__title" @click="$emit('toggle_unit')" :class="vertical_layout?'ml-3':''">
		<div>
			<span class="k-cvlp-unit__title--unit-title" v-html="unit.title"></span> <span class="k-cvlp-unit__title--unit-description" v-html="unit.description"></span>
		</div>

		<v-spacer/>

	</div>
	<div v-if="unit_loaded"><v-expand-transition><div v-show="unit_showing">

		<div v-if="show_my_content" class="d-flex align-center mt-4 mb-4"><v-spacer/>
			<v-btn-toggle v-if="$vuetify.breakpoint.width>800" class="k-course-view-toggle-btn elevation-3" active-class="k-course-view-toggle-btn-active-class" v-model="unit_mode" mandatory>
				<!-- Note: this first btn has to be v-show, not v-if, because if it's v-if, it doesn't work if you clicked on an another unit first that doesn't have unit.text -->
				<v-btn small :large="$vuetify.breakpoint.mdAndUp" class="k-tight-btn k-nocaps-btn" @click="scroll_to_unit" :value="'lp'"><v-icon class="mr-1" :small="$vuetify.breakpoint.smAndDown" :color="(unit_mode=='lp')?'#fff':'secondary'">fas fa-location-dot</v-icon>Unit Planning & Resources</v-btn>
				<v-btn small :large="$vuetify.breakpoint.mdAndUp" class="k-tight-btn k-nocaps-btn" @click="scroll_to_unit" :value="'standards'"><v-icon class="mr-1" :small="$vuetify.breakpoint.smAndDown" :color="(unit_mode=='standards')?'#fff':'secondary'">fas fa-bullseye</v-icon>Unit Standards & Targets&nbsp;</v-btn>
				<v-btn small :large="$vuetify.breakpoint.mdAndUp" class="k-tight-btn k-nocaps-btn" @click="scroll_to_unit" :value="'assignments'"><v-icon class="mr-1" :small="$vuetify.breakpoint.smAndDown" :color="(unit_mode=='assignments')?'#fff':'secondary'">fas fa-rectangle-list</v-icon>Unit Lessons{{manage_assignments?' & Activities':''}}</v-btn>
				<v-btn small :large="$vuetify.breakpoint.mdAndUp" class="k-tight-btn k-nocaps-btn" @click="" :value="'messages'">&nbsp;<v-icon class="mr-2" :small="$vuetify.breakpoint.smAndDown" :color="(unit_mode=='messages')?'#fff':'secondary'">fas fa-comments</v-icon>{{ messages_button_text }}&nbsp;&nbsp;</v-btn>
			</v-btn-toggle>
			<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="is_lp_admin" class="ml-4" small fab color="secondary" @click="edit_unit"><v-icon small>fas fa-edit</v-icon></v-btn></template>Edit Learning Progression Unit</v-tooltip>
		<v-spacer/></div>

		<div v-show="unit_mode=='lp'" class="k-cvlp-unit-lp-wrapper">
			<CVUnitLP ref="unit_lp"
				:lp="lp" :unit="unit" :term_mode="term_mode"
				:is_sis_course="is_sis_course"
				:is_lp_admin="is_lp_admin"
				@show_assignments="show_assignments"
				@assign_resource="assign_resource"
			/>
		</div>
		<div v-show="unit_mode=='standards'" class="k-cvlp-unit-lp-wrapper">
			<CourseUnitStandards :lp="lp" :unit="unit" />
		</div>
		<div v-show="unit_mode=='assignments'">
			<MyContentView2 v-if="manage_assignments" ref="my_assignments" :course_code="lp.course_code" :lp_unit_id="unit.lp_unit_id" :filtered_origin_teacher_id="filtered_origin_teacher_id" />
			<MyContentView v-if="!manage_assignments" :course_code="lp.course_code" :lp_unit_id="unit.lp_unit_id" />
		</div>
		<div v-if="unit_mode=='messages' && manage_assignments">
			<MessagesView :course_code="lp.course_code" />
		</div>
	</div></v-expand-transition></div>
</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import CVUnitLP from './CVUnitLP'
import MyContentView from '../mycontent/MyContentView'
import MyContentView2 from '../mycontent/MyContentView2'
import CourseUnitStandards from './CourseUnitStandards'
import MessagesView from '../messages/MessagesView'

export default {
	components: { CVUnitLP, MyContentView, MyContentView2, CourseUnitStandards, MessagesView },
	props: {
		lp: { type: Object, required: true },
		unit: { type: Object, required: true },
		term_mode: { type: String, required: true },
		unit_showing: { type: Boolean, required: true },
		assignment_id_expanded: { type: String, required: true },
		is_lp_admin: { type: Boolean, required: true },
		is_sis_course: { type: Boolean, required: true },
		filtered_origin_teacher_id: { required: false, default() { return null } },
	},
	data() { return {
		unit_loaded: false,
	}},
	watch: {
		unit_showing: { immediate: true, handler() {
			if (this.unit_showing) {
				// this ensures that the "meat" of the unit -- the resources and the user's activities -- are "lazy loaded"
				// that is, we won't try to calculate/render this stuff until the user actually chooses to view the unit
				this.unit_loaded = true
				this.scroll_to_unit()
			}
		}},
		assignment_id_expanded: { immediate: false, handler() {
			if (this.unit_showing) this.scroll_to_unit()
		}},
	},
	computed: {
		...mapState(['user_info', 'course_update_trigger']),
		...mapGetters(['role', 'beta_options', 'manage_assignments', 'unread_message_count']),
		vertical_layout() { return this.$vuetify.breakpoint.width < 1280 || this.$vuetify.breakpoint.height < 600 },
		// cv_unit_lessons_showing: {
		// 	get() { return this.$store.state.lst.cv_unit_lessons_showing },
		// 	set(val) { this.$store.commit('lst_set', ['cv_unit_lessons_showing', val]) }
		// },
		wrapper_css_class() {
			let s = U.subject_tile_css(this.lp) + '-light'
			if (this.unit_showing) {
				s += ' k-cvlp-unit--showing'
			}
			return s
		},
		messages_button_text() {
			let n = this.unread_message_count(this.lp.course_code)
			if (n == 0) return 'Message Center'
			else return `Message Center (${n})`
		},
		lesson_color_count() {
			if (this.course_update_trigger < 0) return
			let o = {amber:0, green:0, lime:0, blue:0, grey:0, red:0}
			if (this.unit.assignments.length == 0 || empty(this.unit.assignments[0].component)) {
				return o
			}

			o.amber = this.unit.assignments.length
			return o
		},
		lessons_total() {
			return 0
			return this.lesson_color_count['amber'] + this.lesson_color_count['green'] + this.lesson_color_count['lime'] + this.lesson_color_count['blue'] + this.lesson_color_count['grey'] + this.lesson_color_count['red']
		},
		lesson_tooltip() {
			let o = {amber:0, green:0, lime:0, blue:0, grey:0, red:0}
			for (let color in o) {
				let ct = this.lesson_color_count[color]
				// not started/not assigned: grey
				if (color == 'grey') {
					o[color] = sr('$1 Not available to students', ct)

					// past-due: blue
				} else if (color == 'blue') {
					o[color] = sr('<b>$1 Past Due</b>', ct)

				} else if (color == 'amber') {
					o[color] = sr('<div class="text-center"><b>$1 $2</b></div>', ct, U.ps('Lesson', ct))
				}
			}
			return o
		},
		show_my_content() {
			// if role is staff or admin, show
			if (this.role=='staff' || this.role=='admin') return true
			// or if manage_assignments is on, show
			if (this.manage_assignments) return true
			// if we get to here, don't show
			return false
		},
		unit_mode: {
			get() { return this.$store.state.lst.lp_unit_mode },
			set(val) { this.$store.commit('lst_set', ['lp_unit_mode', val]) }
		},
		// unit_mode: {
		// 	get() {
		// 		if (!this.show_my_content) return 'lp'
		// 		return this.cv_unit_lessons_showing ? 'assignments' : 'lp'
		// 	},
		// 	set(val) {
		// 		if (val == 'assignments') this.cv_unit_lessons_showing = true
		// 		else this.cv_unit_lessons_showing = false
		// 	}
		// }
	},
	created() {
	},
	mounted() {
	},
	methods: {
		scroll_to_unit() {
			setTimeout(()=>{ goTo($(this.$el)[0], {offset:100, duration:200}) }, 50)
		},

		show_assignments() {
			this.unit_mode = 'assignments'
			this.scroll_to_unit()
		},

		assign_resource(o) {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			this.$prompt({
				title: 'New Assignment',
				text: 'Enter a student-facing description for the linked resource:',
				initialValue: o.resource.description,
				disableForEmptyValue: true,
				acceptText: 'Configure Assignment',
				acceptIconAfter: 'fas fa-circle-arrow-right',
			}).then(link_description => {
				link_description = $.trim(link_description)
				if (empty(link_description)) return

				// make sure assignments are showing, then create a new assignment
				this.unit_mode = 'assignments'
				this.$refs.my_assignments.create_new_activity(o.activity_type, o.resource, link_description)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		edit_unit() {
			this.unit_mode = 'lp'
			this.scroll_to_unit()
			this.$nextTick(x=>this.$refs.unit_lp.edit_lp_unit())
		},
	}
}
</script>

<style lang="scss">
.k-cvlp-unit {
	background-color:#eee;	// this will be overwritten
	font-size:16px;
	margin:0 -80px -16px -80px;
	padding:20px 60px;
	border-radius:0;

	-webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;

	.k-cvlp-unit__title {
		display:flex;
		align-items:center;
		justify-content:flex-start;
		cursor:pointer;

		.k-cvlp-unit__title--unit-title {
			font-weight:900;
			font-size:24px;
			padding-right:8px;
		}

		.k-cvlp-unit__title--unit-description {
			padding-left:5px;
			font-size:24px;
		}
	}

	.k-cvlp-unit__status {
		display:inline-block;
		width:28px;
		height:28px;
		line-height:28px;
		border-radius:28px;
		text-align:center;
		font-weight:bold;
		margin-right:4px;
	}
}

.k-cvlp-unit-lp-wrapper {
	max-width:1024px;
	width:1024px;
	margin:0 auto;
}

.k-course-view-toggle-btn {
	border-radius: 10px !important;
	.v-btn { color: #fff !important; }

	.v-btn.v-btn:first-child {
		border-top-left-radius: 10px !important;
		border-bottom-left-radius: 10px !important;
	}

	.v-btn.v-btn:last-child {
		border-top-right-radius: 10px !important;
		border-bottom-right-radius: 10px !important;
	}
	.v-btn__content { color:#000 !important; }
}

.v-btn.k-course-view-toggle-btn-active-class {
	background-color: #f1ca5e !important;
	border: 0px solid #f1ca5e !important;
	
}
</style>
