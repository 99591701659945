<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="d-flex justify-center align-stretch">
	<MiniNav v-show="collection_showing" current_section="pd" />
	<div class="k-main-collection" :class="collection_showing?'k-main-collection--item-showing k-main-collection--course-showing':''">
		<h2 v-if="!collection_showing" class="k-main-collection-header k-page-title d-flex mb-4"><v-icon large color="primary" class="mr-4" style="margin-top:4px">fas fa-user-graduate</v-icon><b>Professional Development Resources</b></h2>
		<div v-show="initialized">
			<div v-if="collection_showing" class="k-mini-nav-site-section-heading">PD Resources</div>
			<div v-if="!new_lp">
				<div v-if="pdlps.length==0">There are currently no professional development resources available.</div>

				<!-- Featured collections -->
				<div v-if="has_featured_collection"><div class="k-main-collection-sub-collection k-main-collection-sub-collection-featured elevation-3">
					<div v-if="!collection_showing" class="mb-1" style="font-size:20px"><b>Featured PD Resource Collections:</b></div>
					<div class="k-main-collection-body">
						<div v-for="(lp) in pdlps" v-if="lp.featured>0" @click="go_to_lp(lp)" class="k-elevated k-main-collection-item k-lp-tile" :class="lp_css(lp)" v-html="lp_title(lp)"></div>
					</div>
				</div></div>

				<div v-if="!collection_showing&&has_nonfeatured_collection" class="k-main-collection-sub-collection elevation-3">
					<div class="mb-1" v-if="has_featured_collection" style="font-size:20px"><b>Other PD Resource Collections:</b></div>
					<div class="k-main-collection-body">
						<div v-for="(lp) in pdlps" v-if="lp.featured==0" @click="go_to_lp(lp)" class="k-elevated k-main-collection-item k-lp-tile" :class="lp_css(lp)" v-html="lp_title(lp)"></div>
					</div>
				</div>

			</div>

			<div v-if="!new_lp&&is_pd_admin&&!collection_showing&&!small_screen" class="mt-4">
				<v-btn @click="create_lp">Create a New PD Resource Collection</v-btn>
			</div>

			<CollectionEdit v-if="new_lp" :learning_progression="new_lp" @editor_cancel="new_lp=null"></CollectionEdit>

		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CollectionEdit from './CollectionEdit'
import MiniNav from '../main/MiniNav'

export default {
	components: { CollectionEdit, MiniNav },
	data() { return {
		initialized: false,
		search_string:'',
		search_terms: [],
		new_lp: null,
	}},
	computed: {
		...mapState(['user_info', 'all_courses_loaded', 'all_courses', 'course_update_trigger']),
		...mapGetters(['small_screen']),
		collection_showing() {
			if (!empty(this.$router.history.current.params, 'course_code')) {
				return this.$router.history.current.params.course_code
			} else {
				return ''
			}
		},
		pdlps() {
			let arr = this.all_courses.filter(lp => {
				// when we first create a new LP to be entered, its title will be false
				if (lp.title == '') return false

				// for inactive lp's, only show to admins
				if (lp.active == 'no' && !this.is_pd_admin) return false

				return (lp.subject == 'PD')
			})

			arr.sort((a,b)=>{
				// featured collections first
				if (a.featured && !b.featured) return -1
				if (b.featured && !a.featured) return 1

				// now sequence if there
				if (a.sequence != 0 && b.sequence == 0) return -1
				else if (b.sequence != 0 && a.sequence == 0) return 1
				else if (a.sequence != b.sequence) return a.sequence - b.sequence

				// if both are featured, most-recently-featured first
				if (a.featured && b.featured) return b.featured - a.featured

				// default sort by title
				if (a.title < b.title) return -1
				if (b.title < a.title) return 1

				return 0
			})

			return arr
		},
		has_featured_collection() {
			for (let lp of this.pdlps) {
				if (lp.featured > 0) return true
			}
			return false
		},
		has_nonfeatured_collection() {
			for (let lp of this.pdlps) {
				if (lp.featured == 0) return true
			}
			return false
		},
		search_results() {
			// trigger for updating search_results
			if (this.course_update_trigger < 0) return

			if (this.search_terms.length == 0) return null

			let arr = this.all_courses.filter(lp => {
				// when we first create a new LP to be entered, its title will be false
				if (lp.title == '') return false

				if (lp.subject != 'PD') return false

				// for inactive lp's, only show to admins
				if (lp.active == 'no') {
					return this.is_pd_admin
				}

				// if all search_terms appear in the title, return true
				let tlc = lp.title.toLowerCase()
				for (let term of this.search_terms) {
					if (tlc.indexOf(term) == -1) return false
				}

				return true
			})

			arr.sort((a,b)=>{
				if (a.title < b.title) return -1
				if (b.title < a.title) return 1

				return 0
			})

			return arr
		},
		is_pd_admin() {
			return vapp.has_admin_right('pd')
		},
	},
	watch: {
		search_string() {
			if (empty(this.search_string)) {
				this.search_terms = []
			} else {
				// convert string to lower case and split on spaces
				this.search_terms = $.trim(this.search_string).toLowerCase().split(/\s+/)
			}
		}
	},
	created() {
		// don't show PD collections to students or parents
		if (this.user_info.role == 'student' || this.user_info.role == 'parent') {
			console.log('going to home (PDIndex)')
			vapp.go_to_home('classes')
			return
		}

		if (!this.all_courses_loaded) {
			this.$store.dispatch('get_all_courses').then(()=>{
				this.$nextTick(()=>{
					this.initialized = true
					// console.log('all_courses', this.all_courses)
				})
			}).catch(()=>{
				this.$alert('error in get_all_courses')
			})
		} else {
			this.initialized = true
		}
	},
	mounted() {
	},
	methods: {
		lp_css(lp) {
			// let s = vapp.color_from_number(lp.course_code)
			let s = U.subject_tile_css(lp)

			if (lp.title.length > 50) {
				s += ' k-lp-tile-extra-long-title'
			} else if (lp.title.length > 30) {
				s += ' k-lp-tile-long-title'
			}
			if (lp.active == 'no') {
				s += ' k-lp-tile-inactive'
			}

			if (this.collection_showing == lp.course_code) {
				s += ' k-lp-tile--pd-showing'
			}

			return s
		},

		lp_title(lp) {
			// add <wbr> tags after slashes
			let s = lp.title.replace(/\//g, '/<wbr>')
				if (lp.active == 'no') {
					s += ' <b class="red--text">[Inactive]</b>'
				}

			return s
		},

		go_to_lp(lp) {
			this.$store.commit('set', ['last_lp_list', 'index'])
			this.$router.push({ path: sr('/pd/$1/0', lp.course_code) })
		},

		create_lp() {
			this.new_lp = new Learning_Progression({subject:'PD'})
			return
		},

		create_lp_complicated_XXX() {
			this.$prompt({
				title: 'Create A New PD Resource Collection',
				text: 'Enter a title for the new PD Resource Collection, then click “Create”.',
				acceptText: 'Create',
			}).then(title => {
				if (!empty(title)) {
					// make sure we don't already have a PD LP with this title
					let lp = this.all_courses.find(o=>o.subject == 'PD' && o.title == title)
					if (!empty(lp) && !empty(lp.title)) {
						this.$alert(sr('A Learning Progression for course code “$1” already exists!', course_code))
						return
					}

					// if we don't already have an lp with an empty title, create a new one
					if (empty(lp)) {
						lp = new Learning_Progression({course_code: course_code})
						this.$store.commit('set', [this.all_courses, 'PUSH', lp])
					}

					// open the lp to edit it
					this.go_to_lp(lp)
				}
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},
	}
}
</script>

<style lang="scss">

.k-main-collection-sub-collection {
	border-radius: 30px;
	// border:1px solid #666;
	background-color:rgba(255, 255, 255, 0.5);
	padding:8px 8px 1px 8px;
	margin-bottom:16px;
	display:inline-block;	// make it shrink to fit whatever is inside of it
	min-width:460px;
}

.k-main-collection-sub-collection-featured {
	background-color:rgba(255, 240, 200, 0.5);
	// border:6px solid $secondary-color;
}

.k-main-collection--course-showing {
	margin:0!important;
	padding:0px 10px!important;

	.k-lp-tile {
		width:100px!important;
		height:100px!important;
		opacity:0.6;
		margin:10px;
		background-color:#f8f8f8;
		-webkit-box-shadow: 0 0 0 0 !important;
	    box-shadow: 0 0 0 0 !important;
		font-size:12px!important;
		line-height:14px!important;
		font-weight:normal!important;
	}

	.k-lp-tile--pd-showing {
		font-weight:bold!important;
		background-color:#eee;
		opacity:1.0;
		-webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
	    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
	}

	.k-main-collection-sub-collection {
		-webkit-box-shadow: none!important;
		box-shadow:none!important;
		background-color:transparent;
		padding:0;
		margin-bottom:0;
		border:0px;
	}
}
</style>
