<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="d-flex justify-center align-stretch">
	<MiniNav v-show="admin_tool_showing" current_section="admin" />
	<div class="k-main-collection k-main-collection-admin-list" :class="admin_tool_showing?'k-main-collection--item-showing k-main-collection--admin-showing':''">
		<div v-if="admin_tool_showing" class="k-mini-nav-site-section-heading">Site Admin Tools</div>
		<h2 class="k-main-collection-header"><v-icon large color="primary" class="mr-4">fas fa-gear</v-icon><b>Site Admin Tools</b></h2>
		<div class="k-main-collection-body">
			<div @click="go_to_admin_route('users')" class="k-elevated k-main-collection-item pb-5 pt-5 justify-space-between k-list-color-1" :class="admin_tool_showing=='users'?'k-admin-item-showing':''"><div>Admin User Privileges</div><div><v-icon large>fas fa-user-cog</v-icon></div></div>
			<div @click="go_to_admin_route('communities')" class="k-elevated k-main-collection-item pb-5 pt-5 justify-space-between k-list-color-3" :class="admin_tool_showing=='communities'?'k-admin-item-showing':''"><div>Communities</div><div><v-icon large>fas fa-users</v-icon></div></div>
			<div @click="go_to_admin_route('lp')" class="k-elevated k-main-collection-item pb-5 pt-5 justify-space-between k-list-color-4" :class="admin_tool_showing=='lp'?'k-admin-item-showing':''"><div>Learning Progressions</div><div><v-icon large>fas fa-chalkboard</v-icon></div></div>
			<div @click="go_to_admin_route('lessonmasters')" class="k-elevated k-main-collection-item pb-5 pt-5 justify-space-between k-list-color-5" :class="admin_tool_showing=='lessonmasters'?'k-admin-item-showing':''"><div>Lesson Masters</div><div><v-icon large>fas fa-file-code</v-icon></div></div>
			<!-- <div @click="go_to_admin_route('jumpto')" class="k-elevated k-main-collection-item pb-5 pt-5 justify-space-between k-list-color-5" :class="admin_tool_showing=='jumpto'?'k-admin-item-showing':''"><div>Jump To Options</div><div><v-icon large>fas fa-bookmark</v-icon></div></div> -->
			<div @click="go_to_admin_route('usage')" class="k-elevated k-main-collection-item pb-5 pt-5 justify-space-between k-list-color-6" :class="admin_tool_showing=='usage'?'k-admin-item-showing':''"><div>Site Usage Stats</div><div><v-icon large>fas fa-chart-line</v-icon></div></div>
			<div @click="go_to_admin_route('resource_usage')" class="k-elevated k-main-collection-item pb-5 pt-5 justify-space-between k-list-color-7" :class="admin_tool_showing=='resource_usage'?'k-admin-item-showing':''"><div>Resource Usage Report</div><div><v-icon large>fas fa-table</v-icon></div></div>
			<div @click="go_to_admin_route('site_issues')" class="k-elevated k-main-collection-item pb-5 pt-5 justify-space-between k-list-color-8" :class="admin_tool_showing=='site_issues'?'k-admin-item-showing':''"><div>Site Issue Reports</div><div><v-icon large>fas fa-bug</v-icon></div></div>
			<div @click="go_to_admin_route('search')" class="k-elevated k-main-collection-item pb-5 pt-5 justify-space-between k-list-color-9" :class="admin_tool_showing=='search'?'k-admin-item-showing':''"><div>Site Search</div><div><v-icon large>fas fa-search</v-icon></div></div>
			<div @click="go_to_admin_route('terms')" class="k-elevated k-main-collection-item pb-5 pt-5 justify-space-between k-list-color-10" :class="admin_tool_showing=='terms'?'k-admin-item-showing':''"><div>Term Management</div><div><v-icon large>far fa-calendar</v-icon></div></div>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import MiniNav from '../main/MiniNav'

export default {
	components: { MiniNav },
	props: {
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info']),
		admin_tool_showing() {
			if (this.$route.fullPath.search(/admin\/(\w+)\b/) > -1) {
				return RegExp.$1
			} else {
				return null
			}
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		go_to_admin_route(which) {
			this.$router.push({ path: '/admin/' + which })
		},
	}
}
</script>

<style lang="scss">
.k-main-collection-admin-list {
	.k-main-collection-item {
		.v-icon {
			color:#000!important;
		}
	}
}

.k-main-collection--admin-showing {
	display: inline-block;
	padding:0px 10px!important;

	.k-main-collection-header {
		display:none!important;
	}

	.k-main-collection-item {
		width:110px;
		height:100px;
		border-radius:16px;
		opacity:0.6;
		margin:10px;
		background-color:#f8f8f8;
		font-size:13px!important;
		line-height:16px;

		.v-icon {
			font-size:20px!important;
		}
	}

	.k-admin-item-showing {
		opacity:1.0!important;
		font-weight:900;
	}
}
</style>
