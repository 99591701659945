<template>
	<v-dialog v-model="dialog_open" max-width="780px" persistent scrollable overlay-opacity="0.9">
		<v-card>
			<v-card-title>
				<span class="text-h5">Confirm Messaging Preferences</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="message_preferences_form">
					<v-select v-model="preferred_delivery" :items="preferred_delivery_options" label="Preferred Delivery"
						required></v-select>

					<v-select v-if="is_text_message" v-model="mobile_provider" :items="mobile_provider_options"
						label="Mobile Provider"></v-select>

					<v-text-field v-if="is_text_message" v-model="phone_number" :rules="phone_number_rules"
						@input="format_phone_number" label="Mobile Number"></v-text-field>

					<v-select v-if="!is_no_notifications" v-model="receipt_preference" :items="receipt_preference_options"
						label="Message Notification Frequency"></v-select>

					<v-select v-if="!is_no_notifications" v-model="language_preference" :items="language_preference_options"
						label="Notification Language Preference"></v-select>

					<v-btn color="primary" class="ml-3 k-tight-btn" @click="save_preferences"
						:disabled="!save_enabled"><v-icon small class="mr-2">fas fa-save</v-icon>Save&nbsp;</v-btn>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
	components: {},
	props: {
		message_preferences: { type: Object, required: true },
	},
	data() {
		return {
			dialog_open: true,
			phone_number: this.message_preferences?.phone_number ?? null,
			phone_number_rules: [
				(v) => !!v || "Phone number is required",
				(v) =>
					/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(v) ||
					"Invalid phone number",
			],
			preferred_delivery:
				this.message_preferences?.preferred_delivery ?? "none",
			preferred_delivery_options: [
				{
					text: "No Notifications",
					value: "none"
				},
				{
					text: "Email",
					value: "email",
				},
				{
					text: "Text Message",
					value: "text",
				},
			],
			mobile_provider: this.message_preferences?.mobile_provider ?? null,
			mobile_provider_options: [
				{
					text: "AT&T",
					value: "txt.att.net",
				},
				{
					text: "T-Mobile",
					value: "tmomail.net",
				},
				{
					text: "Sprint",
					value: "messaging.sprintpcs.com",
				},
				{
					text: "Verizon",
					value: "vtext.com",
				},
				{
					text: "Virgin Mobile",
					value: "vmobl.com",
				},
			],
			receipt_preference:
				this.message_preferences?.receipt_preference ?? "immediate",
			receipt_preference_options: [
				{
					text: "Immediate Delivery",
					value: "immediate",
				},
				{
					text: "Daily Digest",
					value: "daily",
				},
				{
					text: "Weekly Digest",
					value: "weekly",
				},
			],
			language_preference:
				this.message_preferences?.language_preference ?? "English",
			language_preference_options: [
				{
					text: "English",
					value: "English",
				},
				{
					text: "Chinese (Simplified)",
					value: "Chinese (Simplified)",
				},
				{
					text: "French",
					value: "French",
				},
				{
					text: "Hindi",
					value: "Hindi",
				},
				{
					text: "Spanish",
					value: "Spanish",
				},
				{
					text: "Vietnamese",
					value: "Vietnamese",
				},
			],
		}
	},
	computed: {
		...mapState(["user_info"]),
		...mapGetters([]),
		is_no_notifications() {
			return this.preferred_delivery === "none"
		},
		is_email() {
			return this.preferred_delivery === "email"
		},
		is_text_message() {
			return this.preferred_delivery === "text"
		},
		save_enabled() {
			if (!this.preferred_delivery || !this.receipt_preference) return false
			if (
				this.preferred_delivery === "text" &&
				(!this.mobile_provider || !this.is_phone_number_valid)
			)
				return false
			return true
		},
		is_phone_number_valid() {
			return this.phone_number_rules.every(
				(rule) => rule(this.phone_number) === true
			)
		},
	},
	watch: {
	},
	created() { },
	mounted() { },
	methods: {
		save_preferences() {
			const p = {
				user_id: this.user_info.user_id,
				preferred_delivery: this.preferred_delivery,
				mobile_provider: this.mobile_provider,
				phone_number: this.phone_number,
				receipt_preference: this.receipt_preference,
				language_preference: this.language_preference,
			}
			this.$emit("close_preferences_dialog")
			this.$store.dispatch("save_message_preferences", p)
		},
		format_phone_number() {
			// Remove all non-digit characters from the phone number
			let formatted_number = this.phone_number.replace(/\D/g, "")

			// Apply the desired formatting
			if (formatted_number.length >= 3) {
				formatted_number = `(${formatted_number.substring(
					0,
					3
				)}) ${formatted_number.substring(3)}`
			}
			if (formatted_number.length >= 9) {
				formatted_number = `${formatted_number.substring(
					0,
					9
				)}-${formatted_number.substring(9)}`
			}

			// Update the input value
			this.phone_number = formatted_number
		},
	},
}
</script>

<style lang="scss">
</style>
