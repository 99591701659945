<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div><div v-if="!hide_post||!post_and_replies_are_read" class="k-forum-post-wrapper" :class="post.pinned?'k-forum-post-wrapper-pinned':''">
	<div class="k-forum-post">
		<ForumPostEdit v-if="show_post_editor" :post="post" :forum="forum" :is_community_admin="is_community_admin" @editor_cancel="show_post_editor=false"></ForumPostEdit>
		<div v-if="!show_post_editor" class="k-forum-post-body">
			<ResourceLink v-if="post.resource.type!=null" class="mb-1" :resource="post.resource" :mark_district_unsanctioned="true" />
			<div v-else class="k-forum-post-body-text fr-view" v-html="post.body"></div>
			<v-icon v-if="post.pinned" small class="mt-1 ml-2" color="primary">fas fa-thumbtack</v-icon>
			<div v-if="!post_is_read" class="k-forum-post__status" @click.stop="mark_post_as_read"><v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on"><v-icon color="lime">fas fa-exclamation-circle</v-icon></div></template><span>Unread; click to mark as read</span></v-tooltip></div>
		</div>
		<div v-if="!show_post_editor" class="k-forum-post-author-line">
			<div v-if="!show_post_editor && !show_post_reply_editor && post.parent_post_id==0 && replies.length==0 && user_can_reply"><a @click="add_post_reply">REPLY</a></div>
			<v-spacer/>
			<div><span class="k-forum-post-author">{{ post.first_name}} {{ post.last_name}}</span> &nbsp; {{ post_date }}</div>
			<div v-if="user_can_edit"><a @click="edit_post">EDIT</a></div>
		</div>
		<div v-if="replies.length>0 || show_post_reply_editor" class="k-forum-post-replies">
			<ForumPost v-for="(reply) in replies" v-show="reply.body!=''" :key="reply.post_id" :forum="forum" :post="reply" :community="community" :is_community_admin="is_community_admin"></ForumPost>
			<ForumPostEdit v-if="show_post_reply_editor" :post="post_reply" :forum="forum" :is_community_admin="is_community_admin" @editor_cancel="show_post_reply_editor=false"></ForumPostEdit>
		</div>
	</div>
	<div v-if="!show_post_editor && !show_post_reply_editor && post.parent_post_id==0 && replies.length>0 && user_can_reply" class="k-forum-post-author-line pb-2 pl-5">
		<div><a @click="add_post_reply">REPLY</a></div>
	</div>
</div></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ForumPostEdit from './ForumPostEdit'
import ResourceLink from '../resources/ResourceLink'

export default {
	components: { ResourceLink, ForumPostEdit },
	name: 'ForumPost',
	props: {
		forum: { type: Object, required: true },
		post: { type: Object, required: true },
		community: { type: Object, required: false, default() { return {} } },
		is_community_admin: { type: Boolean, required: true },
		hide_post: { type: Boolean, required: false, default() { return false } },
	},
	data() { return {
		show_post_reply_editor: false,
		show_post_editor: false,
		post_reply: {},
	}},
	computed: {
		...mapState(['user_info', 'posts']),
		post_date() {
			return date.format(new Date(this.post.created_at*1000), 'MMM D, YYYY h:mm A')	// Jan 1, 2019 3:12 PM
		},
		user_can_reply() {
			// for lp forums, only admins can make new posts
			if (this.community.type == 'lp') return this.is_community_admin
			return true
		},
		user_can_edit() {
			return this.is_community_admin || this.post.author_user_id == this.user_info.user_id
		},
		replies() {
			let arr = this.posts.filter((post) => {
				return (this.post.post_id!= 0 && post.parent_post_id == this.post.post_id && !empty(post.body))
			})

			arr.sort((a,b) => {
				// sort replies so that older ones come first
				return a.created_at - b.created_at
			})

			return arr
		},
		post_is_read() {
			return this.post.post_is_read()
		},
		post_and_replies_are_read() {
			if (!this.post_is_read) return false
			for (let pr of this.replies) {
				if (!pr.post_is_read()) return false
			}
			return true
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		add_post_reply() {
			this.post_reply = new window.Post({
				forum_id: this.post.forum_id,
				community_id: this.forum.community_id,
				parent_post_id: this.post.post_id,
				author_user_id: this.user_info.user_id,
				body: '',
				first_name: this.user_info.first_name,
				last_name: this.user_info.last_name,
			})
			this.$store.commit('set', [this.posts, 'PUSH', this.post_reply])
			this.show_post_reply_editor = true
		},

		edit_post() {
			this.show_post_editor = true
		},

		mark_post_as_read() {
			this.$store.commit('set', [this.user_info.post_reads, this.post.post_id+'', 1])
			this.$store.dispatch('save_post_reads')
		},
	}
}
</script>

<style lang="scss">
.k-forum-post-wrapper {
	background-color:#fff;
	border-top:1px solid $secondary-color;
}

.k-forum-post-wrapper-pinned {
	background-color:#FCF1CA!important;
}

.k-forum-post {
	// margin-top:10px;
	padding:4px 10px 10px 10px;

	.k-forum-post-body {
		display:flex;
		align-items:flex-start;

		.k-forum-post-resource-link {
			text-decoration:none;
		}

		.k-forum-post-body-text {
			flex:1 1 auto;
		}

		.fa-thumbtack {
			transform:rotate(30deg);
		}
	}

	.k-forum-post-replies {
		border-left:5px solid $secondary-color;
		margin:20px 10px 0 10px;
		border-bottom:1px solid $secondary-color;
		border-right:1px solid $secondary-color;
		border-top:1px solid $secondary-color;
		border-radius:5px;
		background-color:#FDF7E4;

		.k-forum-post-wrapper {
			background-color:transparent!important;
		}

		.k-forum-post-wrapper, .k-forum-post-editor {
			border-top:1px solid $secondary-color!important;
		}
		.k-forum-post-wrapper:first-of-type, .k-forum-post-editor:first-of-type {
			border-top:0!important;
		}
	}

	.k-forum-post__status {
		cursor:pointer;
		margin-top:2px;
		margin-left:6px;
		margin-right:-2px;
		.v-icon {font-size:20px!important;}
	}
}

.k-forum-post-author-line {
	display:flex;
	font-size:0.85em;
	padding:2px 10px 0 10px;
	color:#777;

	.k-forum-post-author {
		font-weight:bold;
	}

	a {
		font-weight:bold;
		color:$primary-color;
		opacity:0.85;
		margin-left:10px;
	}
}

.k-forum-post-wrapper:first-of-type {
	.k-forum-post, .k-forum-post-editor {
		border-top:0;
	}
}
</style>
