<template>
	<div :class="{ 'k-message-wrapper': true, 'k-message-wrapper--highlight': highlight_notification }"
		:id="message_id_string">
		<div class="k-message">
			<v-hover v-slot:default="{ hover }">
				<div :class="{
						'k-message-header': !has_unread_messages,
						'k-message-header--hover': !has_unread_messages && hover,
						'k-message-header--expanded': !has_unread_messages && expanded,
						'k-unread-message-header': has_unread_messages,
						'k-unread-message-header--hover': has_unread_messages && hover,
						'k-unread-message-header--expanded': has_unread_messages && expanded,
					}
					">
					<div @click="toggle_message">
						<div class="d-flex">
							<div class="flex-grow-1 k-message-subject">
								<v-icon class="mr-2" small>{{ expansion_caret }}</v-icon>
								<div class="k-message-subject-text">
									<v-icon v-if="has_unread_messages && !expanded" class="k-unread-message-icon">
										fa-light fa-comment
									</v-icon>
									{{ message.subject }}
								</div>
							</div>
							<div class="k-lesson-course-header mr-3" v-html="course_header"></div>
						</div>
						<div class="k-message-author">
							{{ message.first_name }} {{ message.last_name }}
							<span :class="{
									'k-message-date': true,
									'k-message-date--scheduled': is_scheduled_message,
								}
								">{{ message_date }}</span>
						</div>
					</div>
				</div>
			</v-hover>

			<v-expand-transition>
				<div v-if="expanded" class="k-message-expanded">
					<span v-if="show_recipients_list" class="k-message-recipients">
						<span class="k-message-recipients-sent-to-prompt">{{ sent_to_prompt }}&nbsp;</span>{{
							recipients_list }}
					</span>
					<v-divider></v-divider>
					<div class="k-message-body">
						<div class="k-message-body-text" v-html="message.body"></div>
					</div>
					<div v-if="replies.length > 0" class="k-message-replies">
						<MessageReplyList v-for=" reply_thread  in  replies " :reply_thread="reply_thread"
							class="k-message-reply" :key="reply_thread.message_id" @reply_thread_expanded="reply_thread_expanded" />
					</div>
					<MessageEditor v-if="show_message_reply_editor && message_reply" :message="message_reply"
						@editor_cancel="show_message_reply_editor = false" :course_code="this.message_reply.course_code"
						:is_reply="true" :reply_to_string="reply_to_string" />

					<v-fade-transition hide-on-leave>
						<v-alert v-if="archive_prompt" class="ml-3 mr-3 mt-3" prominent type="warning" dense elevation="5"
							outlined text>
							<v-row align="center">
								<v-col class="grow">
									Confirm that you want to archive this message. Messsage thread will no longer be visible
									to you or the recipient(s).
								</v-col>
								<v-col class="shrink" align="right">
									<v-btn small color="secondary" class="k-tight-btn mb-3"
										@click="confirm_archive_message">
										<v-icon small class="mr-2">fas fa-archive</v-icon>
										Archive
									</v-btn>
									<v-spacer></v-spacer>
									<v-btn small color="primary" class="k-tight-btn" @click="cancel_archive_message">
										<v-icon small class="mr-2">fas fa-cancel</v-icon>
										Cancel
									</v-btn>
								</v-col>
							</v-row>
						</v-alert>
					</v-fade-transition>

					<div v-if="!show_message_reply_editor" class="d-flex mt-3">
						<v-spacer></v-spacer>
						<v-btn v-if="!is_message_author" small color="primary" class="k-tight-btn elevation-2"
							@click="add_message_reply">
							<v-icon small class="mr-2">fas fa-reply</v-icon>
							Reply
						</v-btn>
						<v-btn v-if="!archive_prompt && is_message_author" small color="secondary"
							class="ml-3 k-tight-btn elevation-2" @click="show_archive_prompt">
							<v-icon small class="mr-2">fas fa-archive</v-icon>
							Archive
						</v-btn>
					</div>
				</div>
			</v-expand-transition>
		</div>
	</div>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from "vuex"
import MessageEditor from "./MessageEditor"
import MessageReplyList from "./MessageReplyList"

export default {
	components: { MessageEditor, MessageReplyList },
	name: "Message",
	props: {
		message: { type: Object, required: true },
		is_reply: {
			type: Boolean,
			required: false,
			default() {
				return false
			},
		},
		autoexpand: {
			type: Boolean,
			required: false,
			default() {
				return false
			},
		},
	},
	data() {
		return {
			show_message_reply_editor: false,
			message_reply: null,
			expanded: this.is_reply,
			archive_prompt: false,
			// If coming from a notification, add colored highlight border
			highlight_notification: false,
		}
	},
	computed: {
		...mapState(["user_info", "messages", "all_students"]),
		...mapGetters(["role"]),
		reply_to_string() {
			return `${this.message.first_name} ${this.message.last_name}`
		},
		message_date() {
			if (!this.message.send_at) return ""
			return date.format(
				new Date(this.message.send_at * 1000),
				"MMM D, YYYY h:mm A"
			)
		},
		message_id_string() {
			return `message-${this.message.message_id}`
		},
		is_scheduled_message() {
			// Only staff or admin can see scheduled messages.
			// This is a catch for a scenario that should never happen in the first place,
			// as student/parent messages set for later delivery will be filtered out by the get_messages service
			if (!(this.role === "staff" || this.role === "admin")) return false
			const now = new Date()
			return this.message.send_at > now.getTime() / 1000
		},
		sent_to_prompt() {
			const prefix = this.is_scheduled_message ? "Send to" : "Sent to"
			if (this.message.send_to == "Guardian") return `${prefix} Guardians of:`
			if (this.message.send_to == "Student & Guardian")
				return `${prefix} Students and Guardians:`
			return `${prefix}:`
		},
		show_recipients_list() {
			// Only show recipient list if user is staff or admin
			if (!(this.role === "staff" || this.role === "admin")) return false
			// Don't show recipient list for student generated (activity) messages, as those are one-on-one to teacher
			if (this.message.activity_id !== 0 && this.message.author_sourcedId !== this.user_info.sis_user_sourcedId) return false
			// otherwise...
			return true
		},
		recipients_count() {
			return this.message.recipients.length
		},
		recipients_list() {
			const student_names = this.message.recipients
				.filter((r) => {
					return this.all_students[r.user_sourcedId]
				})
				.map((r) => {
					const student = this.all_students[r.user_sourcedId]
					if (!student) return
					return `${student.givenName} ${student.familyName}`
				})
			return student_names.join(", ")
		},
		course_header() {
			return this.message.course_header()
		},
		replies() {
			let arr = this.message.replies.filter(m => m.message_id !== 0)
			arr.sort((a, b) => {
				return a.created_at - b.created_at
			})
			return arr
		},
		has_unread_messages() {
			return this.unread_message_count > 0
		},
		unread_message_count() {
			let um = 0
			if (!this.message.is_read) um++
			um += this.replies.map((r) => {
				return [r.is_read, ...r.replies.map(rr => rr.is_read)]
			}).flat().filter(r => !r).length
			return um
		},
		unread_message_string() {
			if (this.has_unread_messages) {
				if (this.replies.length > 0) {
					return this.unread_message_count == 1 ? "New reply" : `${this.unread_message_count} new replies`
				}
				return this.unread_message_count == 1 ? "New message" : `${this.unread_message_count} new messages`
				// return `${this.unread_message_count} new message${this.unread_message_count > 1 ? "s" : ""}`
			}
			return ""
		},
		unread_reply_length() {
			return this.replies.map((r) => {
				return [r.is_read, ...r.replies.map(rr => rr.is_read)]
			}).flat().filter(r => !r).length
		},

		has_unread_replies() {
			return this.unread_reply_length > 0
		},
		unread_reply_string() {
			if (this.has_unread_replies) {
				return this.unread_reply_length == 1 ? "New reply" : `${this.unread_reply_length} new replies`
			}
			return ""
		},
		is_message_author() {
			return this.message.author_user_id == this.user_info.user_id
		},
		expansion_caret() {
			return `fas ${this.expanded ? "fa-caret-down" : "fa-caret-right"}`
		},
	},
	watch: {
		autoexpand() {
			// If we are arriving from a notification...
			if (this.autoexpand) {
				this.expanded = true
				this.highlight_notification = true
				setTimeout(() => {
					goTo($(this.$el)[0], { offset: 100, duration: 200 })
					if (this.message.is_read === false) this.$store.dispatch("mark_message_as_read", this.message)
				}, 200)
			}
		},
	},
	created() { },
	mounted() { },
	methods: {
		reply_thread_expanded() {
			if (this.highlight_notification) {
				this.highlight_notification = false
			}
		},
		toggle_message() {
			if (!this.show_message_reply_editor) {
				this.expanded = !this.expanded
				if (this.expanded && this.message.is_read === false) {
					this.$store.dispatch("mark_message_as_read", this.message)
				}
				if (!this.expanded) {
					this.highlight_notification = false
				}
			}
		},
		add_message_reply() {
			// Triggers for initial (level 1) reply on thread
			// Subsequent (level 2) replies are managed in MessageReplyList.vue
			if (this.highlight_notification) {
				this.highlight_notification = false
			}
			const response_recipient = new Recipient({
				user_sourcedId: this.message.author_sourcedId,
			})
			let m = {
				course_code: this.message.course_code,
				subject: this.message.subject,
				send_to: this.message.send_to,
				body: "",
				parent_message_id: this.message.message_id,
				message_level: 1,
				author_user_id: this.user_info.user_id,
				author_sourcedId: this.user_info.sis_user_sourcedId,
				recipients: [response_recipient],
				first_name: this.user_info.first_name,
				last_name: this.user_info.last_name,
			}
			console.log("m", m)
			this.message_reply = new window.Message(m)
			this.$store.commit("set", [this.messages, "PUSH", this.message_reply])
			this.show_message_reply_editor = true
		},
		show_archive_prompt() {
			this.archive_prompt = true
		},
		cancel_archive_message() {
			this.archive_prompt = false
		},
		confirm_archive_message() {
			this.archive_prompt = false
			this.$store.dispatch("archive_message", this.message)
		},
	},
}
</script>

<style scoped lang="scss">
.k-message-wrapper {
	background-color: #fff;
	border-top: 1px solid $secondary-color;

	&--highlight {
		border: 4px solid #ad1457;
		transform: translateX(-4px);
		width: calc(100% + 8px);
	}
}

.k-message {
	/* padding: 10px; */

	.k-message-header {
		background-color: #f5f5f5;
		padding: 10px;

		&--hover {
			background-color: #fff;
			cursor: pointer;
		}

		&--expanded {
			background-color: #fff;
		}
	}

	.k-unread-message-header {
		// background-color: $v-red-lighten-4;
		padding: 10px;

		&--hover {
			// background-color: $v-red-lighten-5;
			cursor: pointer;
		}

		&--expanded {
			background-color: #fff;
		}
	}

	.k-message-expanded {
		padding: 0 10px 10px 10px;

		.k-message-recipients {
			font-style: italic;
			font-size: 12px;
			color: #777;

			.k-message-recipients-sent-to-prompt {
				font-weight: 600;
			}
		}
	}

	.k-message-subject {
		display: flex;
		align-items: center;

		.k-message-subject-text {
			flex: 1 1 auto;
			font-weight: bold;

			.k-unread-message-icon {
				font-weight: normal;
				font-size: 15px;
				padding-bottom: 2px;
				// transform:scaleX(0.85);

				.k-unread-message-icon::after {
					content: "\00a0";
				}
			}
		}
	}

	.k-lesson-course-header {
		text-align: center;
		padding-top: 1px;

		.k-lesson-course-header-inner {
			font-size: 9px;
			line-height: 12px;
			font-weight: bold;
			border-radius: 6px;
			padding: 2px 3px;
			background-color: #fff;
			color: #000;
			border: 1px solid #999;
		}
	}

	.k-message-body {
		display: flex;
		align-items: flex-start;

		.k-message-body-text {
			flex: 1 1 auto;
		}
	}

	.k-message-replies {
		margin: 20px 10px 0 10px;
		border-radius: 5px;

		.k-message-reply {
			border-left: 5px solid $secondary-color;
			border-bottom: 1px solid $secondary-color;
			border-right: 1px solid $secondary-color;
			border-top: 1px solid $secondary-color;
			border-radius: 5px;

			&:not(:last-of-type) {
				margin-bottom: 10px;
			}
		}
	}

	.k-message__status {
		cursor: pointer;
		margin-top: 2px;
	}
}

.k-message-author-line {
	padding: 10px 10px 0 14px;
	color: #777;
}

.k-message-author {
	color: #777;
}

.k-message-date {
	&::before {
		content: "\00a0\00a0";
	}

	&--scheduled {
		font-weight: 700;
		color: #777;

		&::before {
			content: "\00a0\00a0Scheduled to send:\00a0\00a0";
		}
	}
}
</style>
