import '@/js/utilities.js'	// do this first so router et al have access to U functions
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import highcharts from '@/plugins/highcharts'
import JSZip from 'jszip'
import DialogPromise from '@/components/vuetify-dialog-promise-pwet'
Vue.use(DialogPromise)

import date from 'date-and-time'
window.date = date

// jquery
window.$ = require('jquery')
window.jQuery = window.$

import marked from 'marked'
window.marked = marked

// katex
import 'katex/dist/katex.css'
import katex from 'katex'
window.katex = katex

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
Vue.component('vue-draggable-resizable', VueDraggableResizable)

// import drag from 'v-drag'
// Vue.use(drag)

import VueVisible from 'vue-visible'
Vue.use(VueVisible)

// import froala component, custom froala fns, and FroalaWrapper component; to use: <froala-wrapper />
import froala from '@/components/FroalaWrapper/froala-plugin'
import FroalaWrapper from '@/components/FroalaWrapper/FroalaWrapper'
Vue.component('froala-wrapper', FroalaWrapper)

import '@/js/google_translate.js'
import '@/js/textfill.js'
import '@/scss/henry-base.scss'
import '@/scss/beta-styles.scss'
import '@/scss/responsive.scss'

// data structures
import '@/js/user_info.js'
import '@/js/resources.js'
import '@/js/lessons.js'
import '@/js/activities.js'
import '@/js/messages.js'
import '@/js/courses.js'
import '@/js/learning_progressions.js'
import '@/js/case_data_structures.js'
import '@/js/case_utilities.js'
import '@/js/communities.js'
import '@/js/links.js'
import '@/js/coteachers.js'

import '@/js/sample_data.js'

Vue.config.productionTip = false

new Vue({
	vuetify,
	store,
	router,
	render: h => h(App)
}).$mount('#app')
