<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="800" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Duplicate Student Activity</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<div>Original activity title: <b>{{activity_data.title}}</b></div>
				<div class="mt-4"><v-text-field outlined dense hide-details label="Duplicate activity title" v-model="title_start"></v-text-field></div>
				<div v-show="activity_data.exercise_records.length>1">
					<div class="mt-3">This activity includes {{activity_data.exercise_records.length}} “exercises”. Choose the exercises you would like to use in the new activity you’re creating, then choose an option below:</div>
					<div class="mt-2 text-center">
						<v-btn x-small outlined color="primary" class="mx-1" @click="select_all">Select All</v-btn>
						<v-btn x-small outlined color="primary" class="mx-1" @click="select_none">Select None</v-btn>
					</div>
					<div v-for="(exercise, index) in activity_data.exercise_records" class="mt-1">
						<v-checkbox class="mt-0 pt-0" :label="exercise_label(exercise, index)" v-model="included_exercises[exercise.exercise_id]" hide-details></v-checkbox>
					</div>
				</div>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-btn color="secondary" @click="$emit('dialog_cancel')">Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-menu top><template v-slot:activator="{on}"><v-btn v-show="n_included_exercises>0" v-on="on" class="ml-3" color="primary"><v-icon small class="mr-2">fas fa-plus-circle</v-icon>Create New Activity...</v-btn></template>
					<v-list min-width="250">
						<v-list-item v-show="n_included_exercises==1" @click="create_new('single', false)"><v-list-item-title>Create new activity, <b>leaving the original activity unchanged</b></v-list-item-title></v-list-item>
						<v-list-item v-show="n_included_exercises==1" @click="create_new('single', true)"><v-list-item-title>Create new activity, and <b class="red--text text--darken-2">archive the original activity</b></v-list-item-title></v-list-item>
						<v-list-item v-show="n_included_exercises>1" @click="create_new('single', false)"><v-list-item-title>Create <b>a single new activity</b> with the {{n_included_exercises}} selected exercises, <b>leaving the original activity unchanged</b></v-list-item-title></v-list-item>
						<v-list-item v-show="n_included_exercises>1" @click="create_new('single', true)"><v-list-item-title>Create <b>a single new activity</b> with the {{n_included_exercises}} selected exercises, and <b class="red--text text--darken-2">archive the original activity</b></v-list-item-title></v-list-item>
						<v-divider v-show="n_included_exercises>1" />
						<v-list-item v-show="n_included_exercises>1" @click="create_new('multiple', false)"><v-list-item-title>Create <b>{{n_included_exercises}} new activities</b>, one for each selected exercise, <b>leaving the original activity unchanged</b></v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		activity_data: { type: Object, required: true },
		course_code: { type: String, required: false, default() { return '' } },
		lp_unit_id: { type: Number, required: false, default() { return 0 } },
		duplicate_is_template_resource: { type: Boolean, required: false, default() { return false } },
	},
	data() { return {
		dialog_open: true,
		included_exercises: {},
		title_start: '',
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
		n_included_exercises() {
			let n = 0
			for (let exercise_id in this.included_exercises) if (this.included_exercises[exercise_id]) ++n
			return n
		},
	},
	watch: {
	},
	created() {
		vapp.duplicate_activity_component = this
		this.select_all()
		this.title_start = this.activity_data.title
	},
	mounted() {
	},
	methods: {
		exercise_label(exercise, index) {
			let s = `${index+1}. `
			if (exercise.exercise_title) s += exercise.exercise_title
			else s += `${U.capitalize_word(exercise.exercise_type)} Exercise`
			s += ` (${exercise.stars_available} ${U.ps('star', exercise.stars_available)})`
			return s
		},
		select_all() {
			let o = {}
			for (let exercise of this.activity_data.exercise_records) {
				o[exercise.exercise_id] = true
			}
			this.included_exercises = o
		},
		select_none() {
			let o = {}
			for (let exercise of this.activity_data.exercise_records) {
				o[exercise.exercise_id] = false
			}
			this.included_exercises = o
		},

		create_new(which, archive_original) {
			let new_activity_shell = ()=>{
				let o = extobj(this.activity_data)
				
				// mark the new activity as a duplicate of the original activity
				o.duplicate_of = o.activity_id
				// set editor to the current user
				o.editors = `,${this.user_info.email},`
				// create new exercises array to receive duplicated exercises
				o.exercises = []
				// we'll add up stars_available below
				o.stars_available = 0
				// clear title; we'll set it below
				o.title = ''

				// delete the exercise_records we added when get_activity_exercise_list was called
				delete o.exercise_records

				// delete other properties that we'll want to be re-established in the new activity
				delete o.activity_id
				delete o.created_at
				delete o.created_for_lti_link_id
				delete o.last_save_ts
				delete o.login_at
				delete o.login_count
				delete o.updated_at
				
				return o
			}

			// start the payload
			let payload = { 
				user_id: this.user_info.user_id, 
				sparkl_activities: [],
				cureum_activities: [],
			}

			// for a single activity, start its new shell
			if (which == 'single') {
				payload.sparkl_activities.push(new_activity_shell())
				// if title_start is empty, use the original title
				if (empty(this.title_start)) payload.sparkl_activities[0].title = this.activity_data.title
				else payload.sparkl_activities[0].title = this.title_start
			}

			// go through each included exercise
			let ex_n = 0
			for (let exercise_id in this.included_exercises) {
				++ex_n
				if (this.included_exercises[exercise_id]) {
					let exercise_record = this.activity_data.exercise_records.find(x=>x.exercise_id==exercise_id)
					let activity
					// if we're creating a single activity, we're adding to the activity shell we created above
					if (which == 'single') {
						activity = payload.sparkl_activities[0]

					// otherwise we're making an activity for each exercise, so create a new activity shell here
					} else {
						activity = new_activity_shell()
						payload.sparkl_activities.push(activity)

						// set activity title to include info about the exercise
						// if the exercise has a title...
						if (!empty(exercise_record.exercise_title)) {
							// if user didn't supply an title_start, use the exercise_title as-is
							if (empty(this.title_start)) activity.title = exercise_record.exercise_title
							// else append the exercise_title to the end of the title_start
							else activity.title = `${this.title_start}: ${exercise_record.exercise_title}`
						} else {
							// we don't have an exercise title. If user didn't supply a title_start, use the original activity title; otherwise start with title_start
							if (empty(this.title_start)) activity.title = this.activity_data.exercise_title
							else activity.title = this.title_start
							// then add ': 1', ': 2', etc.
							activity.title += ': ' + ex_n
						}
					}

					// now add the exercise to the activity.exercises array
					activity.exercises.push({
						exercise_id: exercise_id,
						// add original_activity_id field; that lets Sparkl know that if the exercise gets edited in the duplicate, we have to first make a copy of the exercise
						original_activity_id: this.activity_data.activity_id,
					})
					// and update stars_available
					activity.stars_available += exercise_record.stars_available
				}
			}

			// now go back through and create cureum_activities shells
			for (let sparkl_activity of payload.sparkl_activities) {
				let a = new Activity({
					activity_id: 0,
					activity_title: sparkl_activity.title,
					activity_description: Activity.standard_activity_description(sparkl_activity.stars_available),
					lti_resource_link_id: U.new_uuid(),
					tool_id: 'sparkl',
					activity_type: 'activity',
					course_code: this.course_code,
					lp_unit_id: this.lp_unit_id,
					creator_user_id: this.user_info.user_id,
					// note that the server will fill in tool_activity_id (and activity_id of course)
				})
				payload.cureum_activities.push(a)
			}

			// stringify the sparkl_activities and cureum_activities arrays, since they might be big
			payload.sparkl_activities = JSON.stringify(payload.sparkl_activities)
			payload.cureum_activities = JSON.stringify(payload.cureum_activities)
			// if duplicate_is_template_resource, we need to send in -1 for the activity_template_id, so that the duplicate will get saved as a template (and therefore won't show up in the user's "my activities")
			if (this.duplicate_is_template_resource) payload.activity_template_id = -1
			else payload.activity_template_id = 0

			// Call a service to create the activities -- both in Sparkl and in HC
			U.loading_start()
			U.ajax('create_duplicate_activities', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
				}
				console.log('create_sparkl_activities', result, 'A' + result.cureum_activities[0].activity_id)
				// on success, emit event to parent to do something with our new activity(s), the data for which will be in result.cureum_activities
				// if archiving, we need to pass the original activity_id so that the parent can archive it. Otherwise, just pass 0.
				const archive_original_activity_id = archive_original ? this.activity_data.original_activity_id : 0
				this.$emit('duplicate_activities_created',
					result.cureum_activities,
					archive_original_activity_id
				)
				// set last_viewed_activity_or_lesson_id to the first-created duplicate activity
				this.$store.commit('set', ['last_viewed_activity_or_lesson_id', 'A' + result.cureum_activities[0].activity_id])
			})

		},
	}
}
</script>

<style lang="scss">
</style>
