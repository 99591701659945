<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="98vw" persistent scrollable>
		<v-card>
			<!-- <v-card-title style="border-bottom:1px solid #999"><b>Title</b></v-card-title> -->
			<v-card-text class="mt-3" style="font-size:16px">
				<AdminResourceUsage :course_code="course_code" />
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('dialog_cancel')">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminResourceUsage from '../admin/AdminResourceUsage'

export default {
	components: { AdminResourceUsage },
	props: {
		// req: { type: String, required: true },
		course_code: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
