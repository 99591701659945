<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-forum-post-editor">
	<div class="mb-4 d-flex align-center">
		<div><span v-html="header_string"></span> by <b>{{ post.first_name }} {{ post.last_name }}</b></div>
		<v-spacer/>
		<div v-if="post_to_options.length>0" class="mr-2">Post to:</div>
		<div v-if="post_to_options.length>0" style="width:300px">
			<v-select v-model="post_to" :items="post_to_options" label="" dense background-color="#fff" outlined hide-details></v-select>
		</div>
	</div>

	<!-- show resource editor or post body editor -->
	<div v-if="forum.type=='resources'&&post.parent_post_id==0" class="mb-2">
		<EditResource ref="resource_editor" :resource="post.resource" :show_save_buttons="false" :show_shareable_control="false" @resource_changed="resource_changed" />
	</div>
	<div v-else class="mb-4">
		<froala-wrapper :config="editor_config()" v-model="body" />
	</div>

	<div class="d-flex">
		<v-checkbox v-if="post.parent_post_id==0&&is_community_admin" class="mt-0 pt-0 d-inline-block" style="vertical-align:bottom" v-model="pinned" hide-details><div slot="label">Pinned <v-icon small class="ml-1">fas fa-thumbtack</v-icon></div></v-checkbox>
		<v-spacer></v-spacer>
		<v-btn small v-if="post.body==''" color="secondary" @click="delete_post"><v-icon small class="mr-1">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn small v-if="post.body!=''" color="red" dark @click="delete_post"><v-icon small class="mr-1">fas fa-trash</v-icon> Delete Post<span v-if="post.parent_post_id!=0"> Reply</span></v-btn>
		<v-btn small v-if="post.body!=''||values_changed" color="primary" class="ml-2" @click="save_edits"><v-icon small class="mr-1">fas fa-check</v-icon> {{save_btn_string}}</v-btn>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import EditResource from '../resources/EditResource'

export default {
	components: { EditResource },
	props: {
		forum: { type: Object, required: true },
		post: { type: Object, required: true },
		is_community_admin: { type: Boolean, required: true },
	},
	data() { return {
		body: this.post.body,
		pinned: this.post.pinned,
		resource: new Resource(this.post.resource),
		show_safari_search: false,
		show_safari_upload: false,
		post_to: '',
	}},
	computed: {
		...mapState(['all_courses']),
		header_string() {
			let s = ''
			if (this.post.body == '') s = 'new '
			if (this.post.parent_post_id != 0) {
				s += 'post reply '
			} else if (this.forum.type == 'announcements') {
				s += 'announcement '
			} else if (this.forum.type == 'resources') {
				s += 'resource posted '
			} else {
				s += 'post '
			}

			return U.capitalize_word(s)
		},
		body_label() {
			if (this.forum.type == 'announcements') {
				return 'Announcement text'
			} else {
				return 'Post text'
			}
		},
		values_changed() {
			if (this.body != this.post.body) return true
			if (this.pinned != this.post.pinned) return true
			if (this.forum.type == 'resources') {
				if (this.resource.type != this.post.resource.type) return true
				if (this.resource.description != this.post.resource.description) return true
				if (this.resource.url != this.post.resource.url) return true
				if (this.resource.teacher_facing != this.post.resource.teacher_facing) return true
				if (this.resource.target_students != this.post.resource.target_students) return true
			}

			return false
		},
		save_btn_string() {
			if (!this.values_changed) return 'Done'

			let s
			if (this.post.parent_post_id == 0) {
				if (this.forum.type == 'resources') return 'Save Resource'
				return 'Save Post'
			} else {
				return 'Save Post Reply'
			}
		},
		post_to_options() {
			let arr = []

			// if this is an lp forum and this is a new post...
			if (this.forum.type == 'lp' && this.post.parent_post_id == 0 && this.post.post_id == 0) {
				let lp = this.all_courses.find(x=>x.course_code==this.forum.course_code)
				if (!lp) {
					console.log('ForumPost.post_to_options: lp not found')
				} else {
					// forum_ids is created in the lp class constructor
					arr.push({ value:lp.forum_ids[0], text:sr('This course community only ($1)', lp.title) })

					// if you have access to the entire subject area...
					if (vapp.has_admin_right('lp.subject.' + lp.subject)) {
						// you can also post to all courses for this course's grade band for this subject
						arr.push({ value:lp.forum_ids[1], text:sr('All $1 $2 courses', lp.grade_band(), lp.subject) })

						// and you can post to all courses for this course's subject
						arr.push({ value:lp.forum_ids[2], text:sr('All $1 courses (all grades)', lp.subject) })
					}

					// side effect: set post_to; by default we post to the class
					this.post_to = lp.forum_ids[0]
				}
			}

			return arr
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		editor_config() {
			return U.get_froala_config({
				placeholderText: this.body_label
			})
		},
		resource_changed() {
			// when anything in the resource editor changes, reload this.resource so we update the save_btn_string
			this.resource = this.$refs.resource_editor.get_edited_resource()
		},

		save_edits() {
			if (!empty(this.$refs.resource_editor)) {
				this.resource = this.$refs.resource_editor.get_edited_resource()
				// posted resources are by definition shareable
				this.resource.shareable = true
			}

			if (this.values_changed) {
				// if this is a top-level post post_to is set, that's the forum_id for the post
				if (this.post.parent_post_id == 0 && this.post_to) {
					this.$store.commit('set', [this.post, 'forum_id', this.post_to])
				}

				if (this.forum.type == 'resources' && this.post.parent_post_id == 0) {
					// if no resource_type specified, return? for now at least we'll let this go unspecified ('none')
					// if (this.resource.type == 'none') {
					// 	this.$alert('Please specify a Resource Type.')
					// 	return
					// }

					// TODO: once we're integrated with SAFARI, make sure the url is a safari URL
					if (this.resource.url == '') {
						this.$alert('Please specify a resource URL.')
						return
					}
					if (this.resource.description == '') {
						this.$alert('Please specify a resource description.')
						return
					}

					this.$store.commit('set', [this.post.resource, 'type', this.resource.type])
					this.$store.commit('set', [this.post.resource, 'url', this.resource.url])
					this.$store.commit('set', [this.post.resource, 'description', this.resource.description])
					this.$store.commit('set', [this.post.resource, 'teacher_facing', this.resource.teacher_facing])
					this.$store.commit('set', [this.post.resource, 'target_students', this.resource.target_students])

					// copy description into post.body
					this.$store.commit('set', [this.post, 'body', this.resource.description])

				} else {
					// body must be specified
					if (empty(this.body)) {
						this.$alert(sr('Please enter the $1.', this.body_label))
						return
					}
					this.$store.commit('set', [this.post, 'body', this.body])
				}

				this.$store.commit('set', [this.post, 'pinned', this.pinned])

				this.$store.dispatch('save_post', this.post).then(()=>{
					this.$emit('editor_cancel')
				})
			} else {
				this.$emit('editor_cancel')
			}
		},

		delete_post() {
			// if the post wasn't created, no confirm
			if (empty(this.post.body)) {
				this.$store.dispatch('delete_post', this.post).then(()=>{
					this.$emit('editor_cancel')
				})
				return
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: 'Are you sure you want to delete this post?',
			    acceptText: 'Delete Post',
				acceptColor: 'red',
				dialogMaxWidth: 600
			}).then(y => {
				this.$store.dispatch('delete_post', this.post).then(()=>{
					this.$emit('editor_cancel')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{});
		}
	}
}
</script>

<style lang="scss">
.k-forum-post-editor {
	border-top:1px solid $secondary-color;
	padding:10px;
	background-color:#CAC9D7;

	textarea {
		font-size:14px;
		line-height:22px;
	}

	.fa-thumbtack {
		transform:rotate(30deg);
	}

	.v-label, .v-select__selection, input[type=text] {
		font-size:14px;
	}
}

</style>
