<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<froala :class="css_class" :config="config_x" :data-froala_wrapper_id="froala_wrapper_id" ref="froala_component" v-model="model_value"></froala>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	name : "FroalaWrapper",
	// components: { TemplateComponent },
	props: {
		value: { required: false, default() { return null } },
		css_class: { required: false, default() { return '' } },
		parameter: { required: false, default() { return '' } },
		parameter_object: { required: false, default() { return null } },
		config: { required: false, default() { return null } },
		z_index: { required: false, default() { return null } },
		parent_component: { required: false, default() { return null } },
	},
	data() { return {
		config_x: {},
		froala_wrapper_id: U.new_uuid(),
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		// we provide multiple options for mapping the model_value returned by the froala editor onto the parent's parameter...
		model_value: {
			get() {
				// if a parameter_object is passed in, we directly get/set parameter_object[this.parameter]
				if (this.parameter_object) {
					return this.parameter_object[this.parameter]

				} else if (this.parameter) {
					// if we get a parameter (but not a parameter_object), set this.$parent.parameter
					// note that this requires the froala-wrapper component to be a *direct child* of the component it's in
					return this.$parent[this.parameter]
					// (we might want to use this if we need access to the parameter name for some other reason...)

				} else {
					// if parameter/parameter_object not supplied, use the standard vue v-model method
					// https://v2.vuejs.org/v2/guide/components-custom-events.html
					// https://www.digitalocean.com/community/tutorials/how-to-add-v-model-support-to-custom-vue-js-components
					return this.value
				}
			},
			set(val) {
				if (this.parameter_object) {
					this.parameter_object[this.parameter] = val

				} else if (this.parameter) {
					this.$parent[this.parameter] = val

				} else {
					this.$emit('input', val)
					// this.$emit('update:value', val)	// for vue 3, we will need to switch to this, and use "modelValue" instead of "value"
					// https://vuejs.org/guide/components/events.html#usage-with-v-model
				}
			}
		},
	},
	watch: {
	},
	created() {
		// if we're passed an explicit froala config object, use it; otherwise use the default supplied by U.get_froala_config
		let config
		// if this.config is empty, use the default U.get_froala_config
		if (!this.config) config = U.get_froala_config({})
		// if this.config has a "key" value specified, it's an already "fully-formed" config, so use it as is
		else if (this.config.key) config = this.config
		// else pass the supplied config values through to get_froala_config
		else config = U.get_froala_config(this.config)

		// if we got a z_index value, add it to config
		if (this.z_index !== null) config.zIndex = this.z_index

		// add standard events, unless they already exist
		if (!config.events) config.events = {}

		// a froala command btn was clicked
		if (!config.events['commands.after']) config.events['commands.after'] = function(cmd, param1, param2) {
			// note: if we return false from this fn, the command will be canceled

			// remember the last-chosen imageDisplay value, so we can use the same thing next time
			if (cmd == 'imageDisplay') {
				vapp.$store.commit('lst_set', ['froala_image_display', param1])
			
			// remember the last-chosen imageAlign value, so we can use the same thing next time
			} else if (cmd == 'imageAlign') {
				vapp.$store.commit('lst_set', ['froala_image_align', param1])

			// I would like to also remember the last-chosen imageStyle value, so we can use the same thing next time...
			} else if (cmd == 'imageStyle') {
				// but all froala tells me is the style chosen; it doesn't say whether it was applied or unapplied
				// console.log('imageStyle: ' + param1 + ' / ' + param2)
				// vapp.$store.commit('lst_set', ['froala_image_style', param1])

			} else if (cmd == 'html') {
				// when the html toolbar button is toggled on...
				if (this.codeView.isActive()) {
					// extract image data sources from the html, stashing them in an array
					// note that we *don't* extract srcs for images housed at their own urls
					this.img_srcs = []
					let html = this.codeView.get()
					html = html.replace(/(<img [^>]*?src=")(data.*?)("[^>]*?>)/g, ($0, $1, $2, $3) => {
						this.img_srcs.push($2)
						return $1 + 'XX-DATA-SRC-' + this.img_srcs.length + '-XX' + $3
					})
					// set the html to the simplified html, allowing the data to be easily edited
					this.html.set(html)

					// if we're not already in fullscreen mode, set to fullscreen mode
					if (!this.fullscreen.isActive()) {
						this.fullscreen.toggle()
						this.toggle_fullscreen_when_done = true
					} else {
						this.toggle_fullscreen_when_done = false
					}
				
				// then when the html toolbar button is toggled off...
				} else {
					// put the image sources back in
					let html = this.codeView.get()
					html = html.replace(/(<img [^>]*?src=")XX-DATA-SRC-(.*?)-XX("[^>]*?>)/g, ($0, $1, $2, $3) => {
						return $1 + this.img_srcs[$2 - 1] + $3
					})
					this.html.set(html)
					// and exit fullscreen mode, unless we were already in fullscreen mode
					if (this.toggle_fullscreen_when_done) {
						if (this.fullscreen.isActive()) this.fullscreen.toggle()
					}
				}
			}
		}

		// process images
		if (!config.events['image.beforeUpload']) config.events['image.beforeUpload'] = function (images) {
			return window.froala_image_before_upload_fn(this, images)
		}

		// process paste events
		if (!config.events['paste.afterCleanup']) config.events['paste.afterCleanup'] = function (clipboard_html) {
			return window.froala_paste_after_cleanup_fn(this, clipboard_html)
		}

		// remember the last-chosen scaled image size, so we can use the same thing next time
		if (!config.events['image.resizeEnd']) config.events['image.resizeEnd'] = function ($img) {
			// if the style includes `width:(\d+)$`, save $1 as the scale
			let style = $img.attr('style')
			if (style.search(/width:\s+(\d+)%/) > -1) {
				vapp.$store.commit('lst_set', ['froala_image_scaled_size', RegExp.$1])
			}
		}
		
		this.config_x = config

		// create the froala_wrapper_components object in the store if not already there
		if (!this.$store.state.froala_wrapper_components) {
			this.$store.commit('set', ['froala_wrapper_components', {}])
		}

		// then "register" this wrapper component
		this.$store.commit('set', ['froala_wrapper_components', this.froala_wrapper_id, this])
	},
	mounted() {
	},
	methods: {
		get_parent() {
			if (this.parent_component) return this.parent_component
			else return this.$parent
		},
	}
}
</script>

<style lang="scss">
</style>
