// https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Inheritance

class Community {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'community_id', 0)
		sdp(this, data, 'title', '')
		sdp(this, data, 'color', '')
		sdp(this, data, 'description', '')
		sdp(this, data, 'image', '')
		sdp(this, data, 'type', 'restricted', ['restricted', 'public', 'lp'])
		// a list of post_ids for posts *not* authored by this user
		sdp(this, data, 'post_ids_authored_by_others', [])

		sdp(this, data, 'role', 'default', ['default', 'pending'])
	}
}
window.Community = Community

class Forum {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'forum_id', 0)
		sdp(this, data, 'community_id', 0)
		sdp(this, data, 'type', 'default', ['default', 'announcements', 'resources', 'lp'])
		sdp(this, data, 'title', '')
		// we're not currently dealing with forum descriptions
		// sdp(this, data, 'description', '')

		// for lp forums we save the course code of the lp
		if (this.type == 'lp') {
			sdp(this, data, 'course_code', '')
		}

		if (this.type == 'announcements') this.title = 'Announcements'
		if (this.type == 'resources') this.title = 'Community Resources'
		// rename "General Discussion" to "Community Discussion", at least for now
		if (this.title == 'General Discussion') this.title = 'Community Discussion'
	}
}
window.Forum = Forum

class Post {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'post_id', 0)
		sdp(this, data, 'forum_id', '0')	// note that this could be an integer (for a "normal" community) or a string (for an LP community)
		sdp(this, data, 'community_id', 0)
		sdp(this, data, 'parent_post_id', 0)

		// if body is a stringified resource, convert to resource
		if (data.body.search(/\{"type"/) == 0) {
			let o = {}
			try {
				o = JSON.parse(data.body)
			} catch(e) {}
			this.resource = new Resource(o)
			// copy resource.description into post.body
			this.body = this.resource.description

		} else {
			// otherwise load in body, and resource is empty
			sdp(this, data, 'body', '')
			this.resource = {}
		}
		sdp(this, data, 'author_user_id', 0)
		sdp(this, data, 'pinned', false)

		// convert created at from mysql to timestamp
		this.created_at = (empty(data.created_at)) ? 0 : date.parse(data.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() / 1000

		sdp(this, data, 'first_name', '')
		sdp(this, data, 'last_name', '')
		sdp(this, data, 'replies', [])
	}

	post_is_read() {
		// we consider post read if its post_id is in post_reads OR if this user wrote the post
		return vapp.$store.state.user_info.post_reads[this.post_id] == 1 || this.author_user_id == vapp.$store.state.user_info.user_id
	}
}
window.Post = Post

// class Resource {
// 	constructor(data) {
// 		if (empty(data)) data = {}
// 		sdp(this, data, 'resource_id', 0)
// 		sdp(this, data, 'post_id', 0)
// 		sdp(this, data, 'community_id', 0)
// 		sdp(this, data, 'type', 'undefined', ['none', 'website', 'google_drive', 'youtube_video', 'safari', 'undefined'])
// 		sdp(this, data, 'author_user_id', 0)
// 		sdp(this, data, 'body', '')
// 		// sdp(this, data, 'case_uris', [])
// 		// sdp(this, data, 'tags', [])
// 	}
// }
// window.Resource = Resource

/*
Option 1:
	- Resources are posts with extra metadata:
		- type
		- url
		- case_uris
		- tags

Option 2:
	- Resources have their own tables and are loaded separately, but they're treated as posts in the forum components
		- descripton = body
		- resource_id = post_id (add something for the key)
		- pinned is added

Option 3:
	- Posts and resources are separate tables, linked by a post_id
		- store the post body as the resource description
*/
