<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><v-dialog v-model="dialog_open" persistent max-width="800px"><v-card>
	<v-card-title><b v-html="interface_title"></b></v-card-title>
	<v-card-text style="color:#000;">
		<div v-if="!user_saved" style="background-color:#ddd; border-radius:8px; padding:12px;" class="elevation-3 mb-6">
			Parents and guardians of Henry County Schools students can create a HenryConnects account to access course materials and other resources for their designated students. After creating an account, you can designate students by having each student enter their Henry County Schools email address and password.
		</div>
		<div class="d-flex align-center mt-3">
			<v-text-field class="mr-1" :disabled="user_saved" dense outlined hide-details label="Email" v-model="email"></v-text-field>
			<v-checkbox class="mt-0 pt-0 ml-3 d-inline-block" style="vertical-align:bottom" v-model="opt_in_to_emails" hide-details><template v-slot:label><span style="font-size:14px">Send emails to parents/guardians<br>about my students to this address</span></template></v-checkbox>
		</div>
		<div class="d-flex align-center mt-3">
			<v-text-field class="mr-1" dense outlined hide-details label="First Name" v-model="first_name"></v-text-field>
			<v-text-field class="ml-1" dense outlined hide-details label="Last Name" v-model="last_name"></v-text-field>
		</div>
		<div class="d-flex align-center mt-3">
			<v-text-field class="mr-1" dense outlined password hide-details :type="show_password ? 'text' : 'password'" :append-icon="show_password ? 'fas fa-eye' : 'fas fa-eye-slash'" @click:append="show_password = !show_password" label="Password" v-model="password"></v-text-field>
			<v-text-field class="ml-1" dense outlined password hide-details :type="show_password ? 'text' : 'password'" :append-icon="show_password ? 'fas fa-eye' : 'fas fa-eye-slash'" @click:append="show_password = !show_password" label="Confirm Password" v-model="password_confirm"></v-text-field>
		</div>

	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-btn v-if="user_saved&&role=='admin'" color="red" dark @click="delete_user"><v-icon small class="mr-2">fas fa-trash</v-icon> Delete User</v-btn>
		<v-spacer></v-spacer>
		<v-btn color="secondary" @click="$emit('editor_cancel')" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> {{save_btn_text}}</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		parent_user: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		email: this.parent_user.email,
		first_name: this.parent_user.first_name,
		last_name: this.parent_user.last_name,
		opt_in_to_emails: this.parent_user.opt_in_to_emails,
		password: '',
		password_confirm: '',
		show_password: false,
		user_saved: false,
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters(['role']),
		interface_title() {
			if (!this.user_saved) return 'Create New Parent/Guardian User for HenryConnects'
			return sr('Edit Parent/Guardian User <b class="ml-2">$1</b>', this.parent_user.email)
		},
		save_btn_text() {
			if (!this.user_saved) return 'Create User'
			return 'Save Changes'
		}
	},
	created() {
	},
	mounted() {
		// if the user_id isn't 0 when we mount, it means that the user record is already saved
		if (this.parent_user.user_id != 0) this.user_saved = true
	},
	methods: {
		delete_user() {

		},

		save_edits() {
			this.email = $.trim(this.email)
			this.first_name = $.trim(this.first_name)
			this.last_name = $.trim(this.last_name)
			this.password = $.trim(this.password)
			this.password_confirm = $.trim(this.password_confirm)

			if (empty(this.email) || this.email.search(/.+@.+\..+/) == -1) {
				this.$alert('You must specify a valid email address.')
				return
			}

			if (this.email.indexOf('@henry.k12.ga.us') > -1) {
				this.$alert('Henry County Schools staff members should sign in using their district email password. Once you’re signed in to HenryConnects, you will find a “Manage Designated Students” item in the “STAFF” menu in the upper-right corner of the site, where you can add children to monitor using HenryConnects.')
				return
			}

			if (empty(this.first_name) || empty(this.last_name)) {
				this.$alert('You must specify a first and last name.')
				return
			}

			// if this is a new user,
			if (!this.user_saved) {
				// a password must be specified
				if (empty(this.password)) {
					this.$alert('You must enter and confirm a password.')
					return
				}
			}

			// if password isn't empty, it has to match password_confirm
			if (this.password && this.password != this.password_confirm) {
				this.$alert('The string entered in the “Confirm Password” field does not match the Password you entered.')
				return
			}

			let payload = {
				user_id: this.user_info.user_id,
				user_id_being_edited: this.parent_user.user_id,
				email: this.email,
				first_name: this.first_name,
				last_name: this.last_name,
				password: this.password,
				opt_in_to_emails: this.opt_in_to_emails ? 'yes' : 'no',	// service will translate this to true/false
			}

			U.loading_start()
			U.ajax('save_parent_user', payload, result=>{
				U.loading_stop()

				if (result.status == 'email_exists') {
					this.$alert('The email address you entered is already in use for a HenryConnects parent/guardian account.')
					return
				}
				if (result.status != 'ok') {
					this.$alert('Error in ajax call: ' + result.status); return;
				}

				let msg
				if (!this.user_saved) {
					msg = 'Parent/guardian account created. You can now sign in using your email and password.'
				} else {
					msg = 'Account updated.'
					// if the user was updating their own account, update first/last name if needed
					if (this.parent_user.user_id == this.user_info.user_id) {
						this.$store.commit('set', [this.user_info, 'first_name', this.first_name])
						this.$store.commit('set', [this.user_info, 'last_name', this.last_name])
						this.$store.commit('set', [this.user_info, 'opt_in_to_emails', this.opt_in_to_emails])
					}
				}
				this.$alert(msg).then(x=>this.$emit('editor_cancel'))
			});
		},
	}
}
</script>

<style lang="scss">
</style>
