<template>
	<div class="k-message-list k-elevated">
		<h3 class="k-message-list-title">
			<v-icon color="primary" class="ml-1 mr-3">fas fa-comments</v-icon>
			<div>{{ message_list_title }}</div>
		</h3>
		<div class="k-message-list-messages">
			<Message v-for="message in paginated_messages" :key="message.message_id" :message="message"
				:autoexpand="message.message_id == notification_message_id" />
			<v-pagination v-if="total_pages - 1" v-model="current_page" :length="total_pages" />

		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import Message from "./Message"

export default {
	components: { Message },
	props: {
		course_code: {
			type: String,
			required: false,
			default() {
				return ""
			},
		},
		notification_message_id: {
			type: Number,
			required: false,
			default() {
				return null
			},
		},
		// If this is a subset of messages for a specific activity...
		activity_id: {
			type: Number,
			required: false,
			default() {
				return 0
			},
		},
	},
	data() {
		return {
			new_message: {},
			current_page: 1,
			items_per_page: 10,
		}
	},
	computed: {
		...mapState(["messages"]),
		...mapGetters(["role", "system_role", "messages_for_student", "threaded_messages", "unread_message_count"]),
		unread_messages() {
			let n = 0
			for (let message of this.messages) {
				if ((this.course_code == "") || (message.course_code == this.course_code)) {
					if (!message.is_read && message.message_id !== 0) ++n
				}
			}
			return n
		},
		message_list_title() {
			const count = this.unread_message_count(this.course_code)
			if (count > 0) {
				return `${count} New Message${count == 1 ? "" : "s"}`
			} else {
				return "Messages"
			}
		},
		root_messages() {
			let ms = this.role === "parent" ? this.messages_for_student : this.threaded_messages
			let root_messages = ms.filter((message) => {
				// message_id isn't 0 so it isn't new
				if (message.message_id === 0) return false
				if (message.message_level !== 0) return false
				if (this.course_code !== "") {
					return message.course_code === this.course_code
				}
				return true
			}).filter((message) => {
				if (this.activity_id === 0) return true
				return message.activity_id === this.activity_id
			})
			root_messages.sort((a, b) => {
				return b.created_at - a.created_at
			})
			return root_messages
		},
		total_pages() {
			return Math.ceil(this.root_messages.length / this.items_per_page)
		},
		paginated_messages() {
			const start = (this.current_page - 1) * this.items_per_page;
			const end = start + this.items_per_page;
			let paginated = this.root_messages.slice(start, end)
			if (paginated.length === 0) {
				paginated = this.root_messages.slice(start - this.items_per_page, end - this.items_per_page)
				this.current_page--
			}
			return paginated
		}
	},
	created() { },
	mounted() { },
	methods: {},
}
</script>

<style lang="scss">
.k-message-list {
	border: 4px solid $secondary-color;
	background-color: #eee;
	border-radius: 8px;
	margin: 10px;
	font-size: 14px;

	.k-message-list-title {
		display: flex;
		background-color: $secondary-color;
		padding: 8px;
		color: $primary-color;
		font-weight: bold;
		font-size: 16px;
	}
}
</style>
