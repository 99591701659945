<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-mycontent-view-outer">
	<div class="k-mycontent-view-header pr-2">
		<div>{{header_text}}</div>
		<v-spacer/>

		<v-menu left><template v-slot:activator="{on}"><v-btn v-if="max_context>0" v-on="on" small color="#fff" class="mr-4" @click=""><v-icon small class="mr-2">fas fa-eye</v-icon>{{contexts[context_showing]}}</v-btn></template>
			<v-list dense v-if="max_context>0">
				<v-list-item v-for="(mitem, index) in contexts" v-if="index<=max_context" :key="mitem" @click="context_showing=index"><v-list-item-title><v-icon x-small style="margin-top:-3px" color="#333" class="mr-2" v-visible="index==context_showing">fas fa-circle</v-icon>{{mitem}}</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>

		<v-menu left offset-y>
			<template v-slot:activator="{on}">
				<v-btn v-if="$vuetify.breakpoint.smAndUp" v-on="on" small class="k-tight-btn ml-3" color="primary" @click=""><v-icon class="mr-1" small>fas fa-plus</v-icon>Create</v-btn>
			</template>
			<v-list dense>
				<v-list-item @click="create_new_lesson"><v-list-item-icon><v-icon small>fas fa-rectangle-list</v-icon></v-list-item-icon><v-list-item-title>Create a new lesson</v-list-item-title></v-list-item>
				<v-list-item @click="import_shared_item"><v-list-item-icon><v-icon small color="purple">fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Import a shared lesson</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>

		<!-- <v-menu bottom left offset-y>
			<template v-slot:activator="{on}">
				<v-btn v-if="max_context>0" v-on="on" x-small fab color="primary" @click=""><v-icon small>fas fa-plus</v-icon></v-btn>
				<v-btn v-else v-on="on" small color="primary" @click=""><v-icon class="mr-2" small>fas fa-plus</v-icon>Create a Lesson Plan</v-btn>
			</template>
			<v-list dense min-width="250">
				<v-list-item @click="create_new_lesson"><v-list-item-icon><v-icon small>fas fa-rectangle-list</v-icon></v-list-item-icon><v-list-item-title>Create New Lesson Plan</v-list-item-title></v-list-item>
				<v-list-item @click=""><v-list-item-icon><v-icon small>fas fa-star</v-icon></v-list-item-icon><v-list-item-title>Create New Student Activity</v-list-item-title></v-list-item>
				<v-list-item @click=""><v-list-item-icon><v-icon small style="margin-left:3px;">fas fa-file</v-icon></v-list-item-icon><v-list-item-title>Create New Resource</v-list-item-title></v-list-item>
			</v-list>
		</v-menu> -->
	</div>

	<div v-if="new_lesson" class="mb-4 k-mycontent-new-lesson-component">
		<div class="k-mycontent-item-outer elevation-2">
			<div class="k-mycontent-item-title">
				<v-icon class="mr-2">fas fa-rectangle-list</v-icon>
				<div class="k-lesson-title"><b style="font-weight:900">New Lesson Plan</b></div>
				<v-spacer/>
			</div>
			<div class="k-lesson-edit-outer">
				<LessonEditor :original_lesson="new_lesson" :course_code="course_code" :lp_unit_id="lp_unit_id" lesson_class="teacher" @edit_lesson_cancel="new_lesson=null" @edit_lesson_saved="new_lesson_saved" />
			</div>
		</div>
	</div>

	<div v-for="(item, index) in items" :key="item.id" class="k-mycontent-item-outer elevation-2">
		<MyLesson ref="items" :course_code="course_code" :lp_unit_id="lp_unit_id" v-if="item.type=='lesson'" :item="item" />
	</div>

	<div v-if="items.length==0&&!new_lesson" class="k-mycontent-no-items elevation-2" v-html="no_items_msg"></div>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import MyLesson from './MyLesson'
import LessonEditor from '../lessons/LessonEditor'

export default {
	components: { MyLesson, LessonEditor },
	props: {
		// req: { type: String, required: true },
		course_code: { type: String, required: false, default() { return '' } },
		lp_unit_id: { type: Number, required: false, default() { return 0 } },
	},
	data() { return {
		new_lesson: null,
		context_showing: 0,
		contexts: ['All Courses', 'This Course', 'This Unit'],
	}},
	computed: {
		...mapState(['user_info', 'my_lessons']),
		...mapGetters([]),
		max_context() {
			// if we receive a unit id, we can go down to the unit level; if we receive a course_code we can go to that level
			if (this.lp_unit_id != 0) return 2
			if (this.course_code) return 1
			return 0
		},
		no_items_msg() {
			if (this.context_showing == 2) return 'You have not yet created any lessons for this Unit. Click the <i class="fas fa-eye"></i> button above to view lessons for other units in this course or from across the whole site.'
			if (this.context_showing == 1) return 'You have not yet created any lessons for this Course. Click the <i class="fas fa-eye"></i> button above to view lessons from across the whole site.'
			return 'You have not yet created any lessons. Click the <i class="fas fa-plus"></i> button above to create one!'
		},
		header_text() {
			if (this.user_info.role == 'parent') return 'My Child’s Activities'
			if (this.user_info.role == 'student') return 'My Activities'
			return 'My Lessons'
		},
		items() {
			let arr = []
			for (let lesson of this.my_lessons) {
				// skip "non-lesson lessons" in MyContentView -- we show them in MyContentView2
				if (lesson.activity_type != 'lesson') continue
				
				// limit by course_code / lp_unit_id if we get them
				if (this.context_showing == 1 && this.course_code && lesson.course_code != this.course_code) continue
				if (this.context_showing == 2 && this.lp_unit_id && lesson.lp_unit_id != this.lp_unit_id) continue

				let item = {
					type: 'lesson',
					o: lesson,
					title: lesson.lesson_title,
					id: 'L' + lesson.lesson_id,
					icon: 'fas fa-rectangle-list',
				}
				arr.push(item)
			}

			// sort by...

			return arr
		},
	},
	watch: {
	},
	created() {
		vapp.my_content_view = this

		// set initial context value to max_content
		this.context_showing = this.max_context

		// get lesson_masters if we haven't already gotten done so
		this.$store.dispatch('get_lesson_masters')
	},
	mounted() {
	},
	methods: {
		create_new_lesson() {
			this.new_lesson = 'new'
			setTimeout(x=>{
				this.$vuetify.goTo($(this.$el).find('.k-mycontent-new-lesson-component')[0], {offset:96})
			}, 300)
		},

		import_shared_item() {
			this.$prompt({
				title: 'Import Shared Lesson Plan',
				text: 'Enter the shared lesson ID:<div class="mt-2" style="font-size:14px;font-style:italic">This ID should start with an “L”.</div>',
				acceptText: 'Import',
			}).then(entered_item_id => {
				entered_item_id = $.trim(entered_item_id).toUpperCase()
				if (entered_item_id.search(/^([ALS])(\d+)$/) == -1) {
					this.$alert('The ID you entered is not valid.').then(()=>this.import_shared_item())
					return
				}
				let item_id_prefix = RegExp.$1
				let item_id = RegExp.$2

				let payload = {
					user_id: this.user_info.user_id, 
					item_id: item_id,
					creator_user_id: this.user_info.user_id,
					course_code: this.course_code,
					lp_unit_id: this.lp_unit_id,
				}
				let service = (item_id_prefix == 'L') ? 'add_shared_lesson' : 'add_shared_activity'
				U.loading_start()
				U.ajax(service, payload, result=>{
					U.loading_stop()
					if (result.status == 'item_not_found') {
						this.$alert(sr('We could not find an item with the ID you entered ($1).', entered_item_id)).then(()=>this.import_shared_item())
					} else if (result.status != 'ok') {
						this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
					}

					let new_item_id
					if (service == 'add_shared_lesson') {
						let new_lesson = new Lesson(result.lesson)
						this.$store.commit('set', [this.my_lessons, 'PUSH', new_lesson])
						this.$inform('Shared lesson added')
						new_item_id = 'L' + new_lesson.lesson_id
					} else {
						let new_activity = new Activity(result.activity)
						this.$store.commit('add_to_my_activities', new_activity)
						this.$inform('Shared activity added')
						new_item_id = 'A' + new_activity.activity_id
					}
				});
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		new_lesson_saved(edited_lesson) {
			// the lesson editor will have saved the lesson to the db; push it to my_lessons
			let new_lesson = new Lesson(edited_lesson)
			this.$store.commit('set', [this.my_lessons, 'PUSH', new_lesson])

			// close the new lesson editor open the editor for the now-created lesson
			this.new_lesson = null
			setTimeout(x=>{
				let index = this.items.findIndex(x=>x.id == 'L' + new_lesson.lesson_id)
				if (index > -1) {	// we should always find the item
					// first we have to show the lesson, then set lesson_being_edited
					this.$store.commit('set', [this.items[index].o, 'item_showing', true])
					setTimeout(x=>this.$refs.items[index].lesson_being_edited = true, 300)
				}
			}, 300)
		},
	}
}
</script>

<style lang="scss">
.k-mycontent-view-outer {
	// width:800px;
	max-width:800px;
	margin:0 auto;
	text-align:left;

	// do some things here for "legacy" lessons (prior to moving to HCS assignments)
	.k-lesson-course-unit-header {
		margin-right:6px;
		margin-top:3px;
	}
}

.k-mycontent-view-header {
	text-align:left;
	font-size:24px;
	font-weight:bold;
	margin-bottom:8px;
	margin-left:8px;
	display:flex;
	align-items:center;
}

.k-mycontent-item-outer {
	background-color:#fff;
	border-radius:8px;
	padding:8px;
	margin-bottom:12px;
}

.k-mycontent-item-title {
	display:flex;
	align-items: center;
}

.k-mycontent-no-items {
	background-color:#fff;
	border-radius:8px;
	margin-top:12px;
	padding:12px;
	font-size:16px;
	font-style: italic;
}
</style>
