<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><v-card class="ma-2">
	<v-card-title class="d-flex justify-space-between">
		<div>{{title}} <b>({{links.length}})</b></div>
		<v-btn v-show="links.length>0" class="ml-2" small color="secondary" @click="show_links=!show_links">Show</v-btn>
	</v-card-title>
	<v-card-text v-show="show_links">
		<div v-for="(link) in links">
			<div v-if="link.date!=null"><span v-if="link.date!=''">{{link.date}}:</span> {{link.text}}</div>
			<AdminSearchLink v-if="link.date==null" :link="link" :top_hits="top_hits" @set_top_hit="$emit('set_top_hit',link)"/>
		</div>
	</v-card-text>
</v-card></template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminSearchLink from './AdminSearchLink'

export default {
	components: { AdminSearchLink },
	props: {
		title: { type: String, required: true },
		links: { type: Array, required: true },
		top_hits: { type: Array, required: true },
	},
	data() { return {
		show_links: false,
	}},
	computed: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
