<template>
	<v-dialog v-model="coteacher_dialog_open" persistent scrollable max-width="800px">
		<v-card>
			<v-card-title>
				<v-icon large color="primary" class="mr-2">fas fa-cog</v-icon>
				<span>Manage My Co-teachers for This Course</span>
			</v-card-title>
			<v-card-text style="border-top:1px solid #999">
				<CoteacherList :course_code="course_code" />
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-btn color="secondary" @click="add_new_coteacher"><v-icon small class="mr-2">fas fa-plus</v-icon> Add new
					co-teacher</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('dialog_cancel')">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CoteacherList from './CoteacherList'

export default {
	components: { CoteacherList },
	props: {
		course_code: { type: String, required: false, default() { return '' } },
	},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
	},
	data() {
		return {
			coteacher_dialog_open: true,
		};
	},
	methods: {
		add_new_coteacher() {
			this.$prompt({
				title: 'Add New Co-teacher',
				text: 'Enter co-teacher email:',
				promptType: 'autocomplete',
				serviceName: 'email_search',
				acceptText: 'Add Co-teacher',
			}).then(coteacher_email => {
				// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
				coteacher_email = $.trim(coteacher_email).toLowerCase().replace(/^(\S+).*/, '$1')
				if (empty(coteacher_email) || coteacher_email.indexOf('@') == -1) {
					this.$alert('Please enter a valid user email.').then(x => { this.add_new_coteacher() })
					return
				}
				const payload = {
					coteacher_id: 0,
					course_code: this.course_code,
					coteacher_email: coteacher_email,
					user_id: this.user_info.user_id,
					origin_teacher_user_id: this.user_info.user_id,
					permission_level: 1,
				}

				this.$store.dispatch("save_coteacher", payload).then(() => {
					this.$emit("editor_cancel")
				})

			}).catch(n => { console.log(n) }).finally(f => { })
		},

	},
	created() { },
	mounted() { },
}
</script>

<style lang="scss">
</style>
