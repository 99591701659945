<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
<div class="k-resource-collection elevation-1" :class="ac_show_all?'k-resource-collection-maximized':'k-resource-collection-minimized'">
	<v-hover v-slot:default="{hover}"><div>
		<div class="k-resource-collection-outer-wrapper">
			<div class="k-resource-collection-dropdown">
				<v-btn fab x-small color="amber accent-4" light @click="toggle_all_activities()" class="mr-2"><v-icon>fas {{ac_show_all?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>
				<div class="k-resource-collection-title">
					<a @click="toggle_all_activities()">{{collection.title}}</a>
					<v-btn v-if="enable_collection_edit_link&&!small_screen" color="#666" icon small class="ml-3" @click="$emit('edit_resource_collection')"><v-icon small>fas fa-edit</v-icon></v-btn>
				</div>
			</div>
			<div class="k-resource-collection-activities-wrapper-outer">
				<div class="k-resource-collection-activities-wrapper" :id="'activities_wrapper_'+collection.uuid" @scroll="++scroll_updater" :class="ac_show_all?'k-resource-collection-activities-wrapper--show-all':''">
					<div v-if="collection.items.length==0" class="k-resource-collection-activities-no-items">This collection currently has no items.</div>
					<div v-for="(item, index) in collection.items" :key="index" class="k-resource-collection-activity-wrapper" v-show="search_results.length==0||search_results.find(x=>x==item.resource_id)">
						<ResourceCollectionItem
							:item="item"
							:enable_gc_link="enable_gc_link"
							:enable_hc_assignment="enable_hc_assignment"
							:enable_edit_link="enable_resource_edit_link"
							:enable_remove_link="enable_remove_link"
							:enable_report_issue="enable_report_issue"
							@report_issue="$emit('report_issue',$event)"
							@edit_resource="edit_resource"
							@remove_resource="remove_resource"
							@copy_to_my_content="$emit('copy_to_my_content',$event)"
						/>
					</div>
					<v-spacer/>
				</div>
				<div class="k-resource-collection-scrollers" v-show="!ac_show_all&&(show_scroll_activities.right||show_scroll_activities.left)">
					<div class="mb-2"><v-btn v-visible="show_scroll_activities.right" @click="scroll_activities('right',collection.uuid)" fab x-small color="#999" dark><v-icon>fas fa-angle-right</v-icon></v-btn></div>
					<div class="mt-2"><v-btn v-visible="show_scroll_activities.left" @click="scroll_activities('left',collection.uuid)" fab x-small color="#999" dark><v-icon>fas fa-angle-left</v-icon></v-btn></div>
				</div>
			</div>
		</div>
	</div></v-hover>

</div>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import ResourceCollectionItem from './ResourceCollectionItem'

export default {
	components: { ResourceCollectionItem },
	props: {
		collection: { type: Object, required: true },
		search_results: { type: Array, required: false, default() { return [] }},
		enable_gc_link: { type: Boolean, required: false, default() { return false }},
		enable_hc_assignment: { type: Boolean, required: false, default() { return false }},
		enable_resource_edit_link: { type: Boolean, required: false, default() { return false }},
		enable_remove_link: { type: Boolean, required: false, default() { return false }},
		enable_report_issue: { type: Boolean, required: false, default() { return false }},
		enable_collection_edit_link: { type: Boolean, required: false, default() { return false }},
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		scroll_updater: 0,
		ac_show_all: false,
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters(['small_screen']),
		show_scroll_activities() {
			if (this.scroll_updater < 0) return {}

			let jq = $('#activities_wrapper_' + this.collection.uuid)
			let val = jq.scrollLeft()
			let w = jq.width()
			let length = jq.find('.k-resource-collection-activity-wrapper').length * jq.find('.k-resource-collection-activity-wrapper').outerWidth()
			// console.log('show_scroll_activities (' + this.collection.uuid + '): ' + (val + w) + ' / ' + length)
			return {
				left: val > 0,
				right: (val + w + 4) < length
			}
		},
	},
	watch: {
		// I think this was here as a way to allow the parent component to expand/contract the list
		// 'collection.expand_trigger': {
		// 	immediate: true, handler(val) {
		// 		console.log('collection.expand_trigger handler: ' + val)
		// 		this.toggle_all_activities(this.collection.initially_expanded)
		// 	}
		// },
	},
	created() {
	},
	mounted() {
		++this.scroll_updater
		// update the scroller every 2 seconds, just in case
		setInterval(x=>this.scroll_updater++, 2000)
	},
	methods: {
		toggle_all_activities(new_val) {
			if (new_val !== true && new_val !== false) new_val = !this.ac_show_all
			this.ac_show_all = new_val
		},

		scroll_activities(direction) {
			let jq = $('#activities_wrapper_' + this.collection.uuid)
			let val = jq.scrollLeft()
			let w = jq.width() - 0

			if (direction == 'left') val -= w
			else val += w

			jq.animate({scrollLeft: val}, 400)
		},

		// pass-through emmiters
		edit_resource(item) { this.$emit('edit_resource', item) },
		remove_resource(item) { this.$emit('remove_resource', item) },
	}
}
</script>

<style lang="scss">
.k-resource-collection {
	margin:12px;
	background-color:#fff;
	border-radius:16px;
}

.k-resource-collection.k-resource-collection-maximized + .k-resource-collection.k-resource-collection-minimized {
	border-color:transparent;
	padding-top:0;
}


.k-resource-collection-outer-wrapper {
	display:flex;
	align-items: flex-start;
	flex-direction:column;
	overflow:hidden;
	// align-items: center;
	// flex-direction:row;
	// width:500px;
	position:relative;
}

.k-resource-collection-dropdown {
	// flex:0 0 auto;
	padding-left:8px;
	padding-top:8px;
	font-size: 13px;
	line-height:17px;
	text-align:center;
	display:flex;
	align-items: center;

	flex:0 0 auto;
	padding-right:0px;
	// padding-top:0;
}

.k-resource-collection-title {
	text-align:left;
	position:absolute;
	margin-left:44px;
	margin-top:0px;
	font-size:16px;
	line-height:19px;
	display:flex;
	align-items: center;
	// background-color:#eee;
	// padding:2px 6px;
	border-radius:4px;
	a {
		color:#333!important;
		font-weight:900;
		flex:1 0 0%;
	}
}

.k-resource-collection-activities-wrapper-outer {
	flex:1 0 auto;
	padding-top:0;
	padding-bottom:8px;
	display:flex;
	align-items: center;
	overflow:auto;
}

.k-resource-collection-activities-wrapper {
	display:flex;
	flex-wrap:wrap;
	overflow-x: auto;
	align-items:stretch;
	padding-left:4px;
	padding-top:4px;
	// flex: 0 0 940px;
	// flex: 0 0 calc(100% - 40px);
	flex:0 0 100%;
}
.k-resource-collection-activities-wrapper::-webkit-scrollbar {
	display: none;
}

.k-resource-collection-activity-wrapper {
	flex:0 1 auto;
	min-width:178px;
	max-width:178px;
}

.k-resource-collection-activities-no-items {
	flex: 1 0 100%;
	font-size:16px;
	font-style: italic;
	padding-left:4px;
	padding-top:4px;
}

// updated css when the collection is minimized
.k-resource-collection.k-resource-collection-minimized {
	.k-resource-collection-outer-wrapper {
		// align-items: center;
		flex-direction:row;
		// width:500px;
		// position:relative;
	}

	.k-resource-collection-title {
		margin-top:-16px;
	}

	.k-resource-collection-activities-wrapper-outer {
		flex: 0 0 calc(100% - 86px);
		padding-top:28px;
	}

	.k-resource-collection-activities-wrapper {
		flex-wrap: nowrap;
		// align-items:center;
		padding-left:0;
		padding-top:0;
	}

	.k-resource-collection-activity-wrapper {
		min-width:132px;
	}

	.k-resource-collection-scrollers {
		position:absolute;
		right:8px;
		.v-btn {
			width:24px;
			height:24px;
			opacity:0.9;
			.fas { font-size:18px!important; }
		}
	}
}

</style>
