<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><v-dialog v-model="dialog_open" persistent max-width="1000px"><v-card>
	<v-card-text>
		<img src="./../../images/safari-screenshot.jpg" style="width:100%; margin-top:15px;">
	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-spacer></v-spacer>
		<v-btn color="primary" @click="$emit('dialog_done')">Done</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		// community: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
