<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><v-dialog v-model="dialog_open" persistent max-width="800px"><v-card>
	<v-card-title><b>Edit Community</b></v-card-title>
	<v-card-text class="mt-4">
		<v-text-field class="mb-4" hide-details outlined label="Community Title" v-model="title"></v-text-field>
		<div class="mb-4"><froala-wrapper :config="editor_config()" v-model="description" /></div>
		<v-text-field class="mb-4" hide-details outlined label="Image URL" v-model="image"></v-text-field>
		<v-radio-group v-model="type" hide-details>
			<v-radio label="Restricted (users must get approval from a community administrator to join)" value="restricted"></v-radio>
			<v-radio label="Public (anyone can join without requiring approval from a community administrator)" value="public"></v-radio>
		</v-radio-group>
	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-btn color="red" dark @click="delete_community"><v-icon small class="mr-2">fas fa-trash</v-icon> Delete Community</v-btn>
		<v-spacer></v-spacer>
		<v-btn color="secondary" @click="$emit('editor_cancel')" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		community: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		title: this.community.title,
		description: this.community.description,
		image: this.community.image,
		type: this.community.type,
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
	},
	mounted() {
	},
	methods: {
		editor_config() {
			return U.get_froala_config({
				placeholderText: 'Community Description'
			})
		},
		save_edits() {
			if (empty(this.title)) {
				this.$alert('The community must have a title.')
				return
			}

			if (this.title != this.community.title || this.description != this.community.description || this.image != this.community.image || this.type != this.community.type) {
				this.$store.commit('set', [this.community, 'title', this.title])
				this.$store.commit('set', [this.community, 'description', this.description])
				this.$store.commit('set', [this.community, 'image', this.image])
				this.$store.commit('set', [this.community, 'type', this.type])

				this.$store.dispatch('save_community_data', this.community).then(()=>{
					this.$emit('editor_cancel')
				})
			} else {
				this.$emit('editor_cancel')
			}
		},

		delete_community() {
			this.$confirm({
			    title: 'Are you sure?',
			    text: 'Are you sure you want to delete this community?',
			    acceptText: 'Delete Community',
				acceptColor: 'red',
				dialogMaxWidth: 600
			}).then(y => {
				this.$store.dispatch('delete_community', this.community).then(()=>{
					this.$router.push({ path: '/communities' })
					this.$emit('editor_cancel')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{});
		}
	}
}
</script>

<style lang="scss">
</style>
