<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div style="visibility:hidden">
	<div v-html="lti_form"></div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
	},
	data() { return {
		lti_form: '',
	}},
	computed: {
	},
	created() {
	},
	mounted() {
		// http://localhost:7088/reslink/RESOURCE_6_SCI_GA19ED_ESE_G03U00L00_001_EN
		// https://henryconnects.henry.k12.ga.us/reslink/b717fb5f-e758-496c-b0a7-5dab757c18fc
		let payload = {
			resource_id: vapp.reslink_resource_id,
			get_lti_form: 'yes',
		}

		this.$store.dispatch('get_resource_record', payload).then(result=>{
			// if we got back an lti_form, launch
			if (!empty(result.lti_form)) {
				this.lti_form = result.lti_form
				this.$nextTick(()=>$('[name=ext_submit]').click())
			} else {
				document.location = result.resource_record.url
			}
		})

	},
	methods: {
	}
}
</script>

<style lang="scss">
.k-changelog {
	font-size:16px;
	max-width:850px!important;
	border-radius:12px;

	.k-markup {
		margin:0 0 30px 20px;
	}
}
</style>
