<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
<v-dialog v-model="dialog_open" max-width="700" persistent scrollable>
	<v-card>
		<v-card-title style="border-bottom:1px solid #999">
			<b>Designated Students</b>
		</v-card-title>
		<v-card-text class="mt-3" style="font-size:16px; color:#000;">
			<div v-if="child_count>0" style="clear:both">
				<v-data-table light dense
					:headers="headers"
					:items="children"
					:sort-by="['email']"
					:sort-desc="[false]"
					:must-sort="true"
					hide-default-footer
					:footer-props="footer_options"
					:items-per-page="100"
					no-data="None..."
					class="k-admin-users-table"
				>
					<template v-slot:item="{ item }"><tr>
						<td><v-btn icon x-small class="mr-2" @click="remove_child(item.email)"><v-icon>fas fa-times-circle</v-icon></v-btn><span v-html="item.email"></span></td>
						<td>{{item.last_name}}, {{item.first_name}}</td>
					</tr></template>
				</v-data-table>
			</div>
			<div v-else class="text-center"><i>You currently have no designated students. To add one, click the button below.<br><br>You will need to supply the student’s Henry County Schools email address (e.g. 123456@henry.k12.ga.us) and enter the password for this email.</i></div>
		</v-card-text>
		<v-card-actions class="mt-3">
			<v-btn color="secondary" @click="new_child_clicked"><v-icon small class="mr-2">fas fa-plus</v-icon> Add a New Student</v-btn>
			<v-spacer></v-spacer>
			<v-btn color="primary" @click="$emit('cancel_editor')" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Done</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
	},
	data() { return {
		dialog_open: true,
		headers: [
			{ text: 'Email', align: 'left', sortable: true, value:'email' },
			{ text: 'Name', align: 'left', sortable: true, value:'sortable_name' },
		],
		footer_options: {
		},
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters(['role', 'child_count', 'child_email_showing']),
		children() {
			let arr = []
			for (let child_email in this.user_info.child_data) {
				let child_data = this.user_info.child_data[child_email]
				arr.push({
					email: child_email,
					first_name: child_data.first_name,
					last_name: child_data.last_name,
				})
			}
			return arr
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		remove_child(email_to_remove) {
			let child_data = this.user_info.child_data[email_to_remove]

			this.$confirm({
			    title: 'Are you sure?',
			    text: sr('Are you sure you want to remove <b>$1 $2</b> from your list of designated students?', child_data.first_name, child_data.last_name),
			    acceptText: 'Remove',
				acceptColor: 'red',
				dialogMaxWidth: 600
			}).then(y => {
				let payload = {
					user_id: this.user_info.user_id,
					email_to_remove: email_to_remove,
				}
				U.loading_start()
				U.ajax('remove_child_from_parent', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
					}

					this.$store.commit('set', [this.user_info.child_data, email_to_remove, '*DELETE_FROM_STORE*'])

					// if this student was child_email_showing, reset child_email_showing
					if (email_to_remove == this.child_email_showing) {
						// if we don't have any child_data entries, clear
						this.$store.commit('lst_set', ['child_email_showing', ''])

						// otherwise set to the first-available value
						for (let child_email in this.user_info.child_data) {
							this.$store.commit('lst_set', ['child_email_showing', child_email])
							break
						}
					}
				});
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		new_child_clicked() {
			this.$prompt({
				title: 'Designate New Student',
				text: 'Step 1: Enter the Henry County Schools email address (e.g. “123456@henry.k12.ga.us”) for the student you wish to designate as your child:',
				initialValue: '',
				acceptText: 'Next Step',
			}).then(email_to_add => {
				email_to_add = $.trim(email_to_add).toLowerCase()

				if (empty(email_to_add) || email_to_add.search(/\d\d\d\d\d\d@henry.k12.ga.us/) == -1) {
					this.$alert('Please enter a valid Henry County Schools student email address, e.g. “123456@henry.k12.ga.us”.').then(x=>{this.new_child_clicked()})
					return
				}
				if (this.user_info.child_data[email_to_add]) {
					this.$alert('This student is already designated as your child.')
					return
				}
				this.new_child_check_password(email_to_add)
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		new_child_check_password(email_to_add) {
			this.$prompt({
				title: 'Designate New Student',
				text: sr('Step 2: Enter the Henry County Schools password for the student with email address <b>$1</b>. If you don’t have the password at this time, you can cancel and try again later.', email_to_add),
				initialValue: '',
				promptType: 'password',
				acceptText: 'Check Password',
			}).then(password => {
				let payload = {
					user_id: this.user_info.user_id,
					email_to_add: email_to_add,
					password: password,
				}
				U.loading_start()
				U.ajax('add_child_to_parent', payload, result=>{
					U.loading_stop()
					if (result.status == 'ad_fail') {
						this.$alert('The email address and/or password you entered could not be verified.').then(x=>this.new_child_check_password(email_to_add))
						return
					}

					if (result.status != 'ok') {
						this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
					}

					if (this.role == 'parent') {
						// if the current role is parent, show an alert then reload
						this.$alert('Student added. HenryConnects will now reload to retrieve the student’s course information.').then(x=>window.location.reload())
					} else {
						// else add the child to the user's list and show an alert
						this.$store.commit('set', [this.user_info.child_data, email_to_add, result.child_data])
						this.$alert('Student added. Choose “View site as Parent/Guardian role” from the menu in the upper-right corner of the site to view your student’s course information.')
					}
				});
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},
	}
}
</script>

<style lang="scss">
</style>
