<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div v-if="initialized">
	<vue-draggable-resizable :drag-handle="'.k-case-tree-title'" :resizable="!small_screen" :draggable="!small_screen" class-name="k-case-tree-outer-wrapper elevation-8" :h="560" :w="560" :minWidth="350" :minHeight="250" :active="true" :preventDeactivation="true" :handles="['br']" class-name-handle="k-resizable-handle">
		<div class="k-case-tree-top">
			<div class="k-case-tree-title">
				<v-icon v-if="!small_screen" color="#fff" class="mr-2" style="margin-left:-5px">fas fa-arrows-alt</v-icon>
				<div v-html="case_tree_title"></div>
				<v-spacer/>
				<v-btn icon color="#fff" @click="hide_tree"><v-icon>fas fa-times-circle</v-icon></v-btn>
			</div>
			<div class="d-flex align-center">
				<v-text-field light background-color="#fff" class="ma-1" solo hide-details clearable dense
					placeholder="Search"
					v-model="search_terms"
					prepend-inner-icon="fas fa-search" @click:prepend-inner="execute_search_start"
					append-outer-icon="fa fa-info-circle" @click:append-outer="show_search_help=true"
					@click:clear="execute_search_clear"
					@keyup="search_field_keyup"
					autocomplete="new-password"
				></v-text-field>
				<v-btn v-if="user_is_admin&&!show_chooser&&!small_screen" class="ml-2" color="primary" small @click="toggle_edit_mode"><v-icon small class="mr-1">fas fa-edit</v-icon>Edit</v-btn>
			</div>
		</div>
		<div class="k-case-tree-main">
			<div class="k-case-tree-inner-wrapper">
				<div :style="open_items.length>0?'visibility:visible':'visibility:hidden'" class="text-right" style="margin-bottom:-5px"><v-btn @click="collapse_all" x-small text color="orange lighten-1"><b>Collapse all</b></v-btn></div>
				<v-treeview activatable multiple-active hoverable active-class="k-case-tree_case-item--active" light
					:active="active_items"
					:open="open_items"
					:items="cftree.children"
					item-key="tree_key"
					item-children="children"
					@update:active="active_updated"
					@update:open="open_updated"
				>
					<template v-slot:label="{ item }">
						<v-hover v-slot:default="{hover}"><div class="k-case-tree_case_item" :class="item_css(item.tree_key)">
							<span v-if="show_chooser" v-show="hover" class="k-case-tree__choose_item"><v-icon color="green" @click.stop="$emit('choose_item',item.cfitem.identifier,$event)">fas fa-check-circle</v-icon></span>

							<span v-if="edit_mode" class="k-case-tree__edit_item px-1"><v-icon small color="primary" @click.stop="edit_item(item)">fas fa-edit</v-icon></span>

							<span style="cursor:pointer"><b v-if="item.cfitem.humanCodingScheme!=null" v-html="highlight_search_terms(item.cfitem.humanCodingScheme)"></b> <span v-html="highlight_search_terms(item.cfitem.fullStatement)"></span></span>
						</div></v-hover>
					</template>
				</v-treeview>
			</div>
		</div>
	</vue-draggable-resizable>
	<CASETreeSearchHelp v-if="show_search_help" @dialog_cancel="show_search_help=false"></CASETreeSearchHelp>
	<CASEItemEditor v-if="edited_node" :framework_identifier="framework_identifier" :original_node="edited_node"
		@dialog_cancel="cancel_item_edit"
		@edit_new_child="edit_new_child"
		@make_item_open="make_item_open"
		@make_item_active="make_item_active"
		@make_item_parents_open="make_item_parents_open"
	/>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CASETreeSearchHelp from './CASETreeSearchHelp'
import CASEItemEditor from './CASEItemEditor'
export default {
	components: { CASETreeSearchHelp, CASEItemEditor },
	props: {
		framework_identifier: { type: String, required: true },
		showing: { type: Boolean, required: true },
		active_item: { type: String, required: false, default() { '' }},
		open_item: { type: String, required: false, default() { '' }},
		show_chooser: { type: Boolean, required: false, default() { return false }},
		imported_search_terms: { type: String, required: false, default() { return '' }},
	},
	data() { return {
		initialized: false,
		active_items: [],
		open_items: [],
		search_terms: '',
		search_term_res: [],
		stop_words: [],
		search_results: [],
		show_search_help: false,
		edit_mode: false,
		edited_node: null,
	}},
	computed: {
		...mapState(['user_info', 'case_frameworks']),
		...mapGetters(['small_screen']),
		cfo() {
			let o = this.case_frameworks[this.framework_identifier]
			if (empty(o)) {
				this.load_case_framework(this.framework_identifier)
				return {}
			}

			// set flag on vapp that will add css class that gives us room for the case tree on the left side of the window
			this.$store.commit('set', ['case_tree_showing', true])
			this.initialized = true
			this.$emit('tree_becomes_visible')

			this.$nextTick(()=>{ $(this.$el).find('.k-case-tree-outer-wrapper').css({'z-index': 10, height:'60vh'}) })

			return o
		},
		case_tree_title() {
			let title = 'Henry Teaching & Learning Standards'
			for (let key in this.$store.state.subjects) {
				if (this.$store.state.subjects[key] == this.framework_identifier) {
					return title + ': ' + key
				}
			}
			return title
		},
		cfdocument() { return empty(this.cfo.cfdocument) ? {} : this.cfo.cfdocument },
		cfitems() { return empty(this.cfo.cfitems) ? {} : this.cfo.cfitems },
		cftree() { return empty(this.cfo.cftree) ? {} : this.cfo.cftree },
		user_is_admin() {
			return this.user_info.role == 'admin'
		},
	},
	created() {
	},
	mounted() {
		// this line is needed to force the cfo computed to load the framework
		if (!empty(this.cfo.cfdocument)) console.log(this.cfo.cfdocument.title)
	},
	watch: {
		// use this hack to make the vue-draggable-resizable component shift more-or-less-properly to full screen when the window is narrow
		'$vuetify.breakpoint.width': { immediate: true, handler(val) {
			this.accommodate_narrow_window()
		}},

		showing() {
			if (this.showing) {
				if (this.initialized) {
					// set flag on vapp that will add css class that gives us room for the case tree on the left side of the window
					// (not sure if this does anything in HC...)
					this.$store.commit('set', ['case_tree_showing', true])
					this.$emit('tree_becomes_visible')
				}
			} else {
				this.$store.commit('set', ['case_tree_showing', false])
			}
		},

		active_item() {
			let cfitem = this.cfitems[this.active_item]
			if (!empty(cfitem) && !empty(cfitem.tree_keys)) {
				// this.active_items = cfitem.tree_keys
				for (let item of cfitem.tree_keys) {
					this.active_items.push(item)
				}
			}
		},

		open_item() {
			this.make_item_active(this.open_item)
			this.make_item_parents_open(this.open_item)
		},

		imported_search_terms: { immediate: true, handler() {
			// if (!empty(this.imported_search_terms)) {
				// when we receive imported search terms, start a search
				this.search_terms = this.imported_search_terms
				this.execute_search_start()
			// }
		}},
	},
	methods: {
		accommodate_narrow_window() {
			setTimeout(x=>{
				if (this.small_screen) {
					$('.k-case-tree-outer-wrapper').css({width:'100vw', height:'100vh', transform:'none'})
				} else {
					$('.k-case-tree-outer-wrapper').css({width:'560px', height:'560px'})
				}
			}, 10)
		},
		load_case_framework(case_framework_identifier) {
			U.loading_start('Loading standards…')
			let o = {
				user_id: this.user_info.user_id,
				framework_identifier: case_framework_identifier
			}
			U.ajax('retrieve_case_framework', o, result=>{
				if (result.status != 'ok') {
					U.loading_stop()
					this.$alert('An error occurred when loading the standards framework.').then(x=>this.hide_tree())
					return
				}
				try {
					// PROCESS THE CASE DATA
					let cfo = {}	// case framework object
					let data = JSON.parse(result.file_content)

					// stash the CFDocument, after adding tree_keys
					data.CFDocument.tree_keys = []
					data.CFDocument.tree_nodes = []
					cfo.cfdocument = data.CFDocument

					// leave this uncommented if we need access to cfassociations outside of this fn
					cfo.cfassociations = data.CFAssociations

					// store uri_base for use when creating/editing items
					cfo.uri_base = data.CFAssociations[0].uri.replace(/^(.*\/).*/, '$1')

					// put the CFItems into a hash, to make it easy to pull the data out
					cfo.item_count = 0
					cfo.cfitems = {}
					for (let item of data.CFItems) {
						++cfo.item_count
						// lowercase versions for filtering purposes
						if (!empty(item.fullStatement)) item.fullStatement_lc = item.fullStatement.toLowerCase()
						if (!empty(item.humanCodingScheme)) item.humanCodingScheme_lc = item.humanCodingScheme.toLowerCase()

						// add tree_keys and tree_nodes array for use below
						item.tree_keys = []
						item.tree_nodes = []

						cfo.cfitems[item.identifier] = item
					}

					// construct tree structure
					cfo.next_tree_key = 0
					let build_node = function(cfitem, parent_node, sequence) {
						let node = {
							tree_key: cfo.next_tree_key,
							cfitem: cfitem,
							parent_node: parent_node,
							children: [],
							sequence: sequence,
						}

						// store tree_key and tree_node in cfitem, then increment cfo.next_tree_key for the next one
						cfitem.tree_nodes.push(node)
						cfitem.tree_keys.push(node.tree_key)
						++cfo.next_tree_key

						// recursively process children
						for (let a of data.CFAssociations) {
							if (a.associationType == 'isChildOf' && a.destinationNodeURI.identifier == node.cfitem.identifier) {
								node.children.push(build_node(cfo.cfitems[a.originNodeURI.identifier], node, a.sequenceNumber))
							}
						}

						// order children by sequence. Note that the sequenceNumbers are in the *associations*, and that not all nodes have sequenceNumbers
						node.children.sort((a,b)=>{
							a = a.sequence
							b = b.sequence
							if (!empty(a) && !empty(b)) return a - b
							if (!empty(a) && empty(b)) return -1
							if (!empty(b) && empty(a)) return 1
							return 0
						})

						return node
					}
					cfo.cftree = build_node(data.CFDocument)

					// store cfo in state
					this.$store.commit('set', [this.case_frameworks, case_framework_identifier, cfo])

					U.loading_stop()
					this.initialized = true
					this.accommodate_narrow_window()

				} catch(e) {
					U.loading_stop()
					console.log(e)
					this.$alert('An error occurred when loading the standards framework.').then(x=>this.hide_tree())
				}
			})
		},

		find_cfitem_from_key(node, key) {
			if (empty(node)) return null
			if (node.tree_key == key) return node.cfitem
			if (empty(node.children) || node.children.length == 0) return null
			for (let child of node.children) {
				let r = this.find_cfitem_from_key(child, key)
				if (!empty(r)) return r
			}
			return null
		},

		// this is called by the treeview component when the user clicks to show or hide an item
		active_updated(arr) {
			// this is called by an event in the v-treeview component when the user clicks to activate an item in the tree
			if (arr.length == 0) {
				// ??? do we need/want to do this?
				this.$emit('case_item_clicked', null)
			} else {
				let item = this.find_cfitem_from_key(this.cftree, arr[0])
				if (!empty(item)) this.$emit('case_item_clicked', item.identifier)
			}

			// console.log(this.active_items, arr)
			// if the user clicked on an item that wasn't already active...
			if (this.active_items.length < arr.length) {
				let newly_active_tree_key = arr[arr.length-1]

				// then if the item isn't open, open it
				if (!this.open_items.find(x=>x==newly_active_tree_key)) {
					this.open_items.push(newly_active_tree_key)
				}
			}

			this.active_items = arr
		},

		make_item_open(identifier) {
			let cfitem = this.cfitems[identifier]
			if (empty(cfitem)) return

			// open this item anywhere it appears in the case_tree
			for (let tree_key of cfitem.tree_keys) {
				this.open_items.push(tree_key)
			}
		},

		make_item_active(identifier, only_this_item) {
			let cfitem = this.cfitems[identifier]
			if (empty(cfitem)) return

			if (only_this_item === true) {
				this.active_items = []
			}

			// activate this item anywhere it appears in the case_tree
			for (let tree_key of cfitem.tree_keys) {
				this.active_items.push(tree_key)
			}
		},

		make_item_parents_open(identifier) {
			let cfitem = this.cfitems[identifier]
			if (empty(cfitem)) return

			// open the item's ancestors
			for (let node of cfitem.tree_nodes) {
				let temp_node = node
				while (!empty(temp_node.parent_node)) {
					this.open_items.push(temp_node.parent_node.tree_key)
					temp_node = temp_node.parent_node
				}
			}
		},

		open_updated(arr) {
			this.open_items = arr
		},

		open_case_network_item(case_guid) {
			let case_item = this.cfitems[case_guid]

			// the uris sometimes go to a non-opensalt URL; since we're limited at this point to case network, always go to the casenetwork "mirror" of the item
			let uri = 'https://casenetwork.imsglobal.org/uri/' + case_guid

			window.open(uri, 'case_network_window', 'menubar=false,toolbar=false,status=false,width=800,height=800')
		},

		// sample searches:
		//    energy ask questions: string contains "energy" AND "ask" AND "questions"
		//    energy "ask questions": string contains "energy" AND "ask questions"
		//    energy OR "ask questions": string contains "energy" OR "ask questions"
		//    energy OR ask questions: string contains "energy" OR ("ask" AND "questions")
		//    energ*: string contains "energy" or "energize" or "energetic", etc.
		create_search_re() {
			this.search_term_res = []
			this.stop_words = []

			if (empty(this.search_terms)) {
				this.search_terms = ''
				return
			}

			// remove all punctuation except a few things, to make sure regexps work
			this.search_terms = this.search_terms.replace(/[^0-9a-zA-Z+\-,;'" .*]/g, '')

			// replace . with \.
			let st = this.search_terms.replace(/\./g, '\\.')

			// wildcards: replace * with .*? (eliminating any surrounding spaces)
			st = st.replace(/\s*\*\s*/g, '\.*?')

			// pull out stop words
			st = st.replace(/-\s*"([^"]+)"/g, ($0, $1) => {
				this.stop_words.push(new RegExp('\\b' + $1 + '\\b', 'gi'))
				return ''
			})
			st = st.replace(/-\s*(\w+)/g, ($0, $1) => {
				this.stop_words.push(new RegExp('\\b' + $1 + '\\b', 'gi'))
				return ''
			})
			st = $.trim(st)

			if (!empty(st)) {
				// first split by ' OR 's into or_re_strings: “energy OR ask questions” => ['energy', 'ask questions']
				let or_arr = st.split(/\s+OR\s+/)
				for (let or_re_string of or_arr) {
					// trim each or_re_string
					or_re_string = $.trim(or_re_string)
					if (empty(or_re_string)) continue

					// "hard-code" spaces within double-quotes: "foo bar" > foo\sbar
					or_re_string = or_re_string.replace(/"([^"]+)"/g, function($0, $1) {
						return "\\b" + $1.replace(/ +/g, "\\s") + "\\b";
					})
					or_re_string = $.trim(or_re_string)
					if (empty(or_re_string)) continue

					// then split the or_re_string into individual and_re_strings on spaces: “ask questions” => ['ask', 'questions']
					let or_res = []
					let and_re_strings = or_re_string.split(/\s+/)
					for (let and_re_string of and_re_strings) {
						and_re_string = $.trim(and_re_string)
						if (!empty(and_re_string)) {
							// create a regexp for this and_re_string
							or_res.push(new RegExp('(' + and_re_string + ')', 'gi'))
						}
					}

					// if we actually created an regexps, push onto search_term_re
					if (or_res.length > 0) {
						this.search_term_res.push(or_res)
					}
				}
			}
		},

		strings_match_search_term_res(string_arr) {
			// at least one of the top-level search_term_res arrays must match at least one of the strings of string_arr
			// (note that usually, the user won't use the 'OR' keyword, so there will be only one sub-array of search_term_res)

			// go through each set of "and_res" in the search_term_res array
			for (let and_res of this.search_term_res) {
				// now go through each string
				for (let string of string_arr) {
					string = $.trim(string)
					if (empty(string)) continue

					// now go through each "re" in the "and_res" array; each re must be in the string for it to match
					let match = true
					for (let re of and_res) {
						// if this re isn't in the string, it's not a match!
						if (string.search(re) == -1) {
							match = false
							break
						}
					}

					// if match is true, this string includes all the re's from this set of and_res, so we can return true overall
					if (match == true) return true

					// otherwise we keep looking for matches in other strings and/or with other sets of and_res
				}
			}

			// if we haven't returned true somewhere above, return false -- no match
			return false
		},

		string_includes_stop_word(s) {
			if (empty(s)) return false
			for (let sw of this.stop_words) {
				if (s.search(sw) > -1) return true
			}
		},

		execute_search(node) {
			if (empty(node)) return false

			// by default return false (item doesn't meet criteria)
			let rv = false

			// if the node has children, search the children
			if (!empty(node.children) && node.children.length > 0) {
				for (let child of node.children) {
					if (this.execute_search(child)) {
						this.open_items.push(node.tree_key)
						rv = true
					}
				}
			}

			// assuming the node has a cfitem, determine if it should be highlighted as a search result
			if (!empty(node.cfitem)) {
				// if the fullStatement includes a stop word, no
				if (!this.string_includes_stop_word(node.cfitem.fullStatement)) {
					// check fullStatement and humanCodingScheme, and identifier if it looks like it might actually be an identifier (> 16 chars)
					let arr = [node.cfitem.fullStatement, node.cfitem.humanCodingScheme]
					if (this.search_terms.length > 16) arr.push(node.cfitem.identifier)

					if (this.strings_match_search_term_res(arr)) {
						this.search_results.push(node.tree_key)
						rv = true
					}
				}
			}

			return rv
		},

		execute_search_start() {
			// if framework isn't yet loaded (as determined by this.cfo.cfdocument), try again in 100 ms
			if (empty(this.cfo.cfdocument)) {
				if (empty(this.execute_search_start_counter)) this.execute_search_start_counter = 0
				if (this.execute_search_start_counter < 1000) {
					setTimeout(()=>{
						this.execute_search_start()
						++this.execute_search_start_counter
					}, 100)
				}
				return
			}
			U.loading_start()
			setTimeout(()=>U.loading_stop(), 100)
			this.execute_search_clear()
			this.create_search_re()
			this.execute_search(this.cftree)
		},

		execute_search_clear() {
			// uncomment this to close everything when search is cleared
			// this.open_items = []
			this.search_results = []
		},

		search_field_keyup(evt) {
			if (evt.key == 'Enter' || evt.keyCode == 13) {
				this.execute_search_start()
			}
		},

		item_css(tree_key) {
			if (!empty(this.edited_node) && this.edited_node.tree_key == tree_key) {
				return 'k-case-tree-item-being-edited'
			}

			if (this.search_results.find(o=>o==tree_key)) {
				return 'k-case-tree-search-match'
			}

			return ''
		},

		highlight_search_terms(s) {
			// highlight search terms if we have them
			if (!empty(this.search_term_res)) {
				for (let res of this.search_term_res) {
					for (let re of res) {
						s = s.replace(re, '<span class="k-case-tree-searched-term">$1</span>')
					}
				}
			}
			return s
		},

		collapse_all() {
			this.open_items = []
			this.active_items = []
		},

		hide_tree() {
			this.$store.commit('set', ['case_tree_showing', false])
			this.$emit('hide_tree')
		},

		toggle_edit_mode() {
			this.edit_mode = !this.edit_mode
		},

		edit_item(item) {
			this.edited_node = item
		},

		cancel_item_edit() {
			this.edited_node = null
		},

		edit_new_child(new_child_node) {
			this.edited_node = null
			this.$nextTick(()=>{this.edited_node = new_child_node})
		},
	}
}
</script>

<style lang="scss">
.k-case-tree-outer-wrapper {
	position:fixed;
	z-index:200;
	top:60px;
	left:5px;
	// height:60vh;
	// width:620px;
	border:5px solid $v-orange-lighten-1;
	background-color:$v-orange-lighten-5;
	border-radius:10px;
	display:flex;
	flex-direction:column;
	text-align:left;
	resize:both;

	.k-case-tree-top {
		flex:0 1 auto;
		background-color:$v-orange-lighten-1;
		padding-bottom:5px;
		height:90px;

		.k-case-tree-title {
			padding-left:10px;
			font-size:18px;
			font-weight:900;
			display:flex;
			align-items:center;
			cursor:move;
		}
	}

	.k-case-tree-main {
		flex:1 1 auto;
		font-size:14px;
		height:calc(100% - 90px);
		padding:5px 0;

		.k-case-tree-inner-wrapper {
			height:100%;
			overflow:auto;
		}

		.v-treeview-node__root {
			min-height:28px;
			align-items:flex-start;
		}

		.v-treeview-node--leaf > .v-treeview-node__root {
			padding-left:0px;
		}

		.k-case-tree_case-item--active .v-treeview-node__label {
			color:$v-orange-darken-2!important;
			font-weight:bold;
			overflow: visible!important;
			white-space: normal!important;
		}

		.v-treeview-node__content {
			margin-left:0;
		}

		.k-case-tree_case_item {
			margin-right:20px;
			.k-case-tree__choose_item, .k-case-tree__edit_item {
				position: absolute;
				height: 23px;
				width:23px;
				border-radius:12px;
				background-color:#fff;
				right: 3px;
				top: 0px;
				.fa-check-circle {
					font-size:19px;
					margin-left:2px;
					margin-top:0px;
				}
			}
		}

		.k-case-tree-search-match {
			background-color:#f8cdad;
		}

		.k-case-tree-searched-term {
			font-weight:bold;
			text-decoration:underline;
			color:#000;
		}

		.k-case-tree-item-being-edited {
			color:$v-teal;
		}
	}

	.k-resizable-handle-br {
		background-color:#fff;
		border:1px solid #000;
		position:absolute;
		right:-10px;
		bottom:-10px;
		width:16px;
		height:16px;
		cursor: se-resize;
	}
}

</style>
