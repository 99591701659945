<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-cv-unit-wrapper-outer mx-auto">
	<div>
		<div class="k-cv-unit__header">
			<div class="text-center">Learning Progression</div>
			<v-spacer />
		</div>

		<div class="k-lp-unit-wrapper">
			<div v-show="!show_unit_editor" class="mt-3">
				<div class="k-lp-unit-description_plus_standards d-flex" v-if="unit.text!=''"> <!-- ||unit.standards.length>0 -->
					<div v-if="unit.text!=''" class="k-lp-unit-description mb-3 mx-1">
						<div class="k-lp-unit-section-header">Unit Description</div>
						<div class="k-lp-unit-wide-text fr-view">
							<div class="d-flex" v-if="unit_text_toggleable">
								<v-spacer/>
								<v-btn class="mr-2" x-small color="secondary" @click="toggle_all_blocks(true)">Expand All</v-btn>
								<v-btn x-small color="secondary" @click="toggle_all_blocks(false)">Collapse All</v-btn>
							</div>
							<div v-html="unit.text"></div>
						</div>
					</div>
				</div>

				<div class="mb-6 pt-2" v-show="unit_has_resources">
					<div class="k-lp-unit-section-header">Resources</div>

					<LPUnitResourceCollectionTree
						:lp="lp" :unit="unit" :term_mode="term_mode"
						:enable_hc_assignment="true"
						@declare_resource_presence="unit_has_resources=true"
						@assign_resource="$emit('assign_resource', $event)"
					/>
				</div>
			</div>

			<div v-if="show_library_notice" class="text-center my-5"><i>See the <b>Lessons & Activities</b> area for template lessons and/or activities you can use with your students.</i></div>

			<div v-if="is_lp_admin && !show_unit_editor && !small_screen" class="text-center my-5"><v-btn color="secondary" @click="edit_lp_unit"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit Learning Progression Unit</v-btn></div>

			<LearningProgressionUnitEdit ref="unit_editor_component" v-if="show_unit_editor" :lp="lp" :original_unit="unit" @editor_cancel="lp_unit_edit_requested=false" />
		</div>
	</div>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import LPUnitResourceCollectionTree from '../resources/LPUnitResourceCollectionTree'
import LearningProgressionUnitEdit from './LearningProgressionUnitEdit'

export default {
	components: { LPUnitResourceCollectionTree, LearningProgressionUnitEdit, },
	props: {
		lp: { type: Object, required: true },
		unit: { type: Object, required: true },
		term_mode: { type: String, required: true },
		is_sis_course: { type: Boolean, required: true },
		is_lp_admin: { type: Boolean, required: true },
	},
	data() { return {
		unit_text_toggleable: false,
		expanded_assignment: null,
		// this is set to true when the user requests to edit; then we show the editor if the lp isn't locked for editing
		lp_unit_edit_requested: false,
		standard_text_showing: {},
		standard_children_showing: {},
		unit_has_resources: false,	// note that this is determined by the LPUnitResourceCollectionTree component, which will emit an event to that set the value to true
	}},
	computed: {
		...mapState(['user_info', 'lp_edit_locked']),
		...mapGetters(['role', 'small_screen', 'manage_assignments', 'studentish_role']),
		// cv_unit_lessons_showing: {
		// 	get() { return this.$store.state.lst.cv_unit_lessons_showing },
		// 	set(val) { this.$store.commit('lst_set', ['cv_unit_lessons_showing', val]) }
		// },
		unit_standards_tree() {
			let arr = []
			let current_parent = null
			let current_parent_hcs = ''

			for (let standard of this.unit.standards) {
				let hcs = standard.case_item.humanCodingScheme

				if (!empty(current_parent_hcs) && hcs.indexOf(current_parent_hcs) > -1) {
					current_parent.children.push(standard)
					continue
				}

				let o = {
					standard: standard,
					children: [],
				}
				arr.push(o)
				current_parent = o
				current_parent_hcs = hcs
			}

			return arr
		},
		show_standard_search() {
			// for now only show standard search button to test users, and if show_safari is on
			if (vapp.user_info.email == 'teacher@henry.k12.ga.us') return true
			if (vapp.user_info.email == 'admin@henry.k12.ga.us') return true
			if (this.$store.getters.show_safari) return true
			return false
		},
		show_unit_editor() {
			// show the editor if the user has requested to edit and the lp isn't locked for editing (lp_edit_locked[this.course_code] must have been explicitly set to false)
			if (this.lp_unit_edit_requested != true) return false
			if (empty(this.lp_edit_locked[this.lp.course_code]) || this.lp_edit_locked[this.lp.course_code] === true) return false
			return true
		},
		show_library_notice() {
			if (this.studentish_role) return false
			if (!this.manage_assignments) return false
			return this.unit.lessons.length > 0 || this.unit.activities.length > 0
		},
	},
	created() {
	},
	mounted() {
		this.initialize_lp_blocks();
	},
	methods: {
		assign_resource(standard, resource) {
			// console.log('CVUnitLP: assign_resource', resource)
			let case_identifier = ''
			if (!empty(standard)) case_identifier = standard.identifier
			this.$emit('assign_resource', case_identifier, resource)
			this.$emit('show_assignments')
		},

		initialize_lp_blocks() {
			$(this.$el).find('a').attr('target', '_blank')
			$(this.$el).find('.klp-header').on('click', function(){
				$(this).next().slideToggle(50)
			})
			if ($(this.$el).find('.klp-header').length > 0) {
				this.unit_text_toggleable = true
			}
		},

		toggle_all_blocks(on) {
			if (on) $(this.$el).find('.klp-block').slideDown()
			else $(this.$el).find('.klp-block').slideUp()
		},

		toggle_standard_text(identifier) {
			if (!this.standard_text_showing[identifier]) {
				this.$set(this.standard_text_showing, identifier, true)
			} else {
				this.standard_text_showing[identifier] = false
			}
		},

		toggle_standard_children(identifier) {
			if (!this.standard_children_showing[identifier]) {
				this.$set(this.standard_children_showing, identifier, true)
			} else {
				this.standard_children_showing[identifier] = false
			}
		},

		standard_search(case_item) {
			let o = {
				tool: 'safari',
				endpoint: this.$store.state.safari_lti_endpoint,
				search_params: {}
			}
			// send grade low/high
			if (!empty(this.lp.grade_low)) o.search_params.fromgrade = this.lp.grade_low
			if (!empty(this.lp.grade_high)) o.search_params.tograde = this.lp.grade_high

			// send CASE identifier
			o.search_params.standardsparentguid = case_item.identifier
			console.log(o)
			this.$store.dispatch('lti_launch', o)
		},

		show_case_tree(options) {
			this.$emit('show_case_tree', options)
		},

		edit_lp_unit() {
			// we have to request to edit the unit, to make sure no one else already has the lp opened for editing
			this.$store.dispatch('lp_edit_socketmsg', this.lp.course_code)

			// flag that we've requested to edit the LP; once the socket message comes back saying we're OK to edit, the show_lp_editor computed will open it for editing
			this.lp_unit_edit_requested = true
		},
	}
}
</script>

<style lang="scss">

// note that some things here are modified in CVUnit when the vertical layout is used
.k-cv-unit-wrapper-outer {
	max-width:1000px;
	height:auto;
	min-height:350px;

	.k-cv-unit__header {
		display:flex;
		justify-content: center;
		align-items:center;
		font-weight:bold;
		font-size:24px;
		margin:10px 5px 10px 0;
		// border-top:1px solid #999;
		// padding-top:5px;
	}

	.k-lp-unit-wrapper {
		width:auto;
		margin-right:20px;
		margin-top:10px;
		background-color:#fff;
		border-radius:10px;
		padding:1px 0;

		// this makes the unit contents scroll
		// max-height:calc(100vh - 108px);
		// overflow:auto;
		max-height:none;

		.k-lp-unit-header {
			background-color:#c4764e;
			border-radius:10px 10px 0 0;
			padding:10px;
			color:#000;
			font-weight:900;
			// text-align:center;
			// text-transform:uppercase;
		}

		.k-lp-unit-section-header {
			font-weight:bold;
			background-color:$v-blue-darken-2;
			border-radius:5px;
			padding:2px 8px;
			color:#fff;
			margin-left:6px;
			margin-right:6px;
			font-size:16px;
			// text-transform:uppercase;
		}

		.k-lp-unit-description {
			flex:1 0 43%;
			// max-width:800px;
		}

		.k-lp-unit-wide-resources {
			padding:5px 15px;
		}

		.k-lp-unit-template-assignments-wrapper {
			display:flex;
			flex-wrap:wrap;
			justify-content:space-around;
			align-items:flex-start;

			.k-cv-assignment__header {
				white-space: normal!important;
			}
		}
		// this makes it so items in the final "row" of template assignments will have a single item on the left
		// .k-lp-unit-template-assignments-wrapper::after {
		// 	content: '';
		// 	flex-grow: 1000000000;
		// }

		.k-lp-unit-template-assignment {
			border:1px solid #ccc;
			border-radius:10px;
			margin-bottom:8px;
			flex:0 1 calc(50% - 15px);
			// flex:0 1 calc(50% - 15px);
			// min-width:350px;
		}

		// .k-lp-unit-standard {
		// 	border-top:1px solid #ccc;
		// 	// border-radius:5px;
		// 	padding-top:5px;
		// 	margin:10px 15px;
		// 	font-size:14px;
		// 	line-height:20px;
		// }

		// .k-lp-unit-standard-statement {
		// 	cursor:pointer;
		// 	max-width:720px;
		// 	margin-left:auto;
		// 	margin-right:auto;
		// }

		// .k-lp-unit-standard-text {
		// 	font-style:italic;
		// 	color:#666;
		// 	font-weight:bold;
		// 	font-size:0.9em;
		// 	padding-left:20px;
		// }
	}
}

.k-cvlp-unit-lp-wrapper-assignments-showing {
	.k-lp-unit-template-assignment {
		flex: 0 1 auto!important;
	}
}

.k-cv-unit-rc-subhead {
	font-size:12px;
	font-style:italic;
	font-weight:bold;
	color:#999;
}

.k-lp-unit-wide-text {
	max-width:720px;
	margin:6px auto;
	padding:1px 12px;
	font-size:14px;
	line-height:18px;
	background-color:$v-light-blue-lighten-5;
	border-radius:5px;
}

////////////////////////
// Styles for interactive LP teacher-facing resources...
.klp-table {
	border-collapse:collapse;
	margin-top:10px;
	td {
		padding:2px 5px;
		border:1px solid #ccc;
	}
}

.klp-header {
	font-weight:bold;
	background-color:#ddd;
	padding:5px 8px;
	border-radius:8px;
	// border-top:1px solid #ddd;
	// padding-top:5px;
	margin-top:15px;
	cursor:pointer;
	font-size:1.2em;
}

.klp-header:before {
	display:inline;
	font-family:'Font Awesome 5 Free';
	font-weight:900;
	content:"\F105";
	padding-right:10px;
}

.klp-block {
	display:none;
	margin-left:30px;

	.klp-header {
		font-size:1.0em;
	}
}

.klp-l2 {
	margin-left:30px;
	list-style:circle;
}
.klp-l3 {
	margin-left:60px;
	list-style:square;
}
</style>
