<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div v-show="sparkl_embed_component!=null" class="k-sparkl-control-bar">
	<v-spacer/>
	<v-btn v-if="context=='edit'" class="mx-1 k-tight-btn" small color="secondary" @click.stop="close_btn_clicked"><v-icon small class="mr-2">fas fa-check</v-icon>Done Editing</v-btn>
	<v-btn v-if="context!='edit'" class="mx-1 k-tight-btn" small color="secondary" @click.stop="close_btn_clicked"><v-icon small class="mr-2">fas fa-times</v-icon>Close Activity</v-btn>
	<v-btn v-if="openable_in_new_window" class="ml-3 mr-1 k-tight-btn" small color="#ccc" @click.stop="open_in_new_window"><v-icon small class="mr-2">fas fa-arrow-up-right-from-square</v-icon>Open in New Window</v-btn>
	<v-spacer/>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
	},
	data() { return {
		sparkl_embed_component: null,
		context: '',
	}},
	computed: {
		...mapState(['single_item']),
		...mapGetters(['studentish_role']),
		openable_in_new_window() {
			// if we're already open in single_item mode, don't show the button again
			if (this.single_item) return false

			// in edit context, we need to communicate with the HC frame, so don't allow this
			if (this.context == 'edit') return false

			// keep students focused on a single activity; also it's crucial that Sparkl communicate with HC here
			if (this.studentish_role) return false

			// if we get to here allow to be opened in a new tab
			return true
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		show(sparkl_embed_component, context, activity_id) {
			this.context = context
			this.sparkl_embed_component = sparkl_embed_component
		},

		hide() {
			this.context = ''
			this.sparkl_embed_component = null
		},

		close_btn_clicked() {
			this.sparkl_embed_component.close_btn_clicked()
		},

		open_in_new_window() {
			this.sparkl_embed_component.open_in_new_window()
		},
	},
}
</script>

<style lang="scss">
.k-sparkl-control-bar {
	position:fixed;
	width:100%;
	z-index:10;
	top:0;
	height:56px;
	display:flex;
	background-color:rgba(45, 32, 101, 0.6);	// primary - #2d2065
	border-bottom:1px solid $v-amber-accent-4;
	padding-top:12px;
}
</style>