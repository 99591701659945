<template><div class="k-mini-nav">
	<v-btn v-if="role=='parent'" fab x-small :class="current_section=='family_home'?'k-mini-nav-current-btn':''" @click="go_to_home('family_home', $event)"><v-icon small>fas fa-house-user</v-icon></v-btn>
	<v-btn fab x-small :class="current_section=='classes'?'k-mini-nav-current-btn':''" @click="go_to_home('classes', $event)"><v-icon small>fas fa-chalkboard</v-icon></v-btn>
	<v-btn v-if="role=='staff'||role=='admin'" fab x-small :class="current_section=='communities'?'k-mini-nav-current-btn':''" @click="go_to_home('communities', $event)"><v-icon small>fas fa-users</v-icon></v-btn>
	<v-btn v-if="role=='staff'||role=='admin'" fab x-small :class="current_section=='pd'?'k-mini-nav-current-btn':''" @click="go_to_home('pd', $event)"><v-icon small>fas fa-user-graduate</v-icon></v-btn>
	<v-btn fab x-small :class="current_section=='launchers'?'k-mini-nav-current-btn':''" @click="go_to_home('launchers', $event)"><v-icon small>fas fa-circle-nodes</v-icon></v-btn>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		current_section: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters(['role']),
		show_parent_tab() {
			if (this.role == 'parent' || this.role == 'admin') return true

			// if the user has PG editing rights, show the PG tab
			if (vapp.has_admin_right('pg')) return true

			return false
		}
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		go_to_home(new_section, event) {
			if (!empty(event) && !empty(event.target)) $(event.target).closest('button').blur()
			vapp.go_to_home(new_section)
		}
	}
}
</script>

<style lang="scss">
.k-mini-nav {
	margin-right:0px;
	display:flex;
	flex-direction: column;
	justify-content: center;
	background-color:rgba(255, 255, 255, 0.85);
	// height:140px;	// no longer needed because parent is align-stretch
	border-radius:16px 0 0 16px;
	padding:6px 0 6px 12px;

	.v-btn {
		margin:1px 8px 1px 0;
		border:1px solid $secondary-color;
		background-color:$primary-color!important;
		i { color:$secondary-color!important; }
	}

	.k-mini-nav-current-btn {
		border-color:transparent;
		// background-color:rgba(255, 255, 255, 0.85)!important;
		background-color:transparent!important;
		// box-shadow:none!important;
		i { color:$primary-color!important; }
	}
}

// this div should be placed near the MiniNav component
.k-mini-nav-site-section-heading {
	text-align:center;
	font-weight:900;
	font-size:16px;
	line-height:16px;
	color:#333;
	text-transform: uppercase;
	margin:12px 0 -2px 0;
}
</style>
