<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div :class="single_item?'k-mycontent-view-single-item':'k-mycontent-view2-outer'">
	<div v-if="!single_item" class="k-mycontent-view-header pr-2">
		<div v-html="header_text"></div>
		<v-spacer/>

		<v-btn v-if="n_old_items>0" small color="red darken-4" text class="k-tight-btn mr-2" @click="show_older_items=!show_older_items"><v-icon small class="mr-2">fas fa-calendar-days</v-icon>{{show_older_items?'Hide Older Items':'Show Older Items ('+n_old_items+')'}}</v-btn>

		<!-- <v-menu left><template v-slot:activator="{on}"><v-btn v-if="max_context>0" v-on="on" class="k-tight-btn" small color="#fff" @click=""><v-icon small class="mr-1">fas fa-eye</v-icon>{{contexts[context_showing]}}</v-btn></template>
			<v-list dense v-if="max_context>0">
				<v-list-item v-for="(mitem, index) in contexts" v-if="index<=max_context" :key="mitem" @click="context_showing=index"><v-list-item-title><v-icon x-small style="margin-top:-3px" color="#333" class="mr-2" v-visible="index==context_showing">fas fa-circle-check</v-icon>{{mitem}}</v-list-item-title></v-list-item>
			</v-list>
		</v-menu> -->

		<v-btn-toggle v-if="$vuetify.breakpoint.smAndUp&&items.length>0" dense active-class="k-toggle-btn-active-class" color="primary" class="k-toggle-btn" v-model="items_mode" mandatory>
			<v-btn small :value="'list'" @click.stop=""><v-icon small :color="items_mode=='list'?'#fff':'#000'">fas fa-list</v-icon></v-btn>
			<v-btn small :value="'gantt'" @click.stop="" class="k-toggle-btn-active-class-off"><v-icon small :color="items_mode=='gantt'?'#fff':'#000'">fas fa-calendar-week</v-icon></v-btn>
		</v-btn-toggle>


		<v-btn v-show="show_create_placeholder_button" small class="k-tight-btn ml-3" color="primary" @click="create_placeholder"><v-icon class="mr-1" small>fas fa-plus</v-icon>Create</v-btn>
		<v-menu left offset-y>
			<template v-slot:activator="{on}">
				<v-btn v-show="show_create_button" v-on="on" small class="k-tight-btn ml-3" color="primary" :disabled="!viewing_my_activities"><v-icon class="mr-1" small>fas fa-plus</v-icon>Create</v-btn>
			</template>
			<v-list dense>
				<v-list-item @click="create_new_lesson()"><v-list-item-icon><v-icon small>{{activity_type_icon('lesson')}}</v-icon></v-list-item-icon><v-list-item-title>{{max_context>1?'New ':''}}{{activity_type_label('lesson')}}</v-list-item-title></v-list-item>
				<v-list-item @click="create_new_activity('activity')"><v-list-item-icon><v-icon small>{{activity_type_icon('activity')}}</v-icon></v-list-item-icon><v-list-item-title>{{max_context>1?'New ':''}}Student Activity</v-list-item-title></v-list-item>
				<v-list-item @click="create_new_activity('google_assignment')"><v-list-item-icon><img class="k-google-assignment-icon" style="height:24px" src="https://www.gstatic.com/prof/logo_assignments_128dp.png"></v-list-item-icon><v-list-item-title>{{max_context>1?'New ':''}}Google Assignment</v-list-item-title></v-list-item>
				<!-- <v-list-item @click="create_new_activity('discussion')"><v-list-item-icon><v-icon small style="margin-left:3px;">{{activity_type_icon('discussion')}}</v-icon></v-list-item-icon><v-list-item-title>{{max_context>1?'New ':''}}{{activity_type_label('discussion')}}</v-list-item-title></v-list-item> -->
				<!-- <v-list-item @click="create_new_activity('quiz')"><v-list-item-icon><v-icon small style="margin-left:3px;">{{activity_type_icon('quiz')}}</v-icon></v-list-item-icon><v-list-item-title>{{max_context>1?'New ':''}}{{activity_type_label('quiz')}}</v-list-item-title></v-list-item> -->
				<!-- <v-list-item @click=""><v-list-item-icon><v-icon small style="margin-left:3px;">{{activity_type_icon('assessment')}}</v-icon></v-list-item-icon><v-list-item-title>{{max_context>1?'New ':''}}{{activity_type_label('assessment')}}</v-list-item-title></v-list-item> -->
				<v-list-item @click="import_shared_item('hc')"><v-list-item-icon><v-icon small color="purple">fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Import Lesson Plan or Activity from HenryConnects</v-list-item-title></v-list-item>
				<v-list-item @click="import_shared_item('inspire')"><v-list-item-icon><v-icon small color="#D4405F">fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Import Lesson Plan or Activity from GaDOE Inspire</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</div>

	<div v-if="!single_item" class="k-mycontent-view-filters">
		<v-menu nudge-left="-36" nudge-top="12">
			<template v-slot:activator="{on}">
				<v-btn v-show="context_showing>0&&items_mode=='list'" v-on="on" x-small fab :color="checkboxes_showing?'primary':'#999'" dark class="mr-2" :class="checkboxes_showing?'':'elevation-0'" style="margin-left:2px"><v-icon>far fa-square</v-icon></v-btn>
			</template>
			<v-list dense>
				<v-list-item v-if="!checkboxes_showing"><v-btn small text color="primary" class="k-tight-btn k-nocaps-btn" @click.stop="checkboxes_showing=true"><v-icon small class="mr-2">far fa-square</v-icon>Show checkboxes for batch operations</v-btn></v-list-item>

				<v-list-item v-if="checkboxes_showing">
					<v-btn small text color="primary" class="k-tight-btn k-nocaps-btn" @click.stop="$refs.my_content_list.select_all"><v-icon small class="mr-2">fas fa-square-check</v-icon>Select All</v-btn>
					<v-btn small text color="primary" class="k-tight-btn k-nocaps-btn" @click="$refs.my_content_list.select_none"><v-icon small class="mr-2">far fa-square</v-icon>Deselect All</v-btn>
					<v-btn small text color="primary" class="k-tight-btn k-nocaps-btn float-right" @click="checkboxes_showing=false"><v-icon small class="mr-2">fas fa-xmark</v-icon>Hide Checkboxes</v-btn>
				</v-list-item>
				<v-divider v-if="checkboxes_showing" />

				<v-list-item v-if="checkboxes_showing" @click="$refs.my_content_list.archive_selected"><v-list-item-icon><v-icon small style="margin-left:2px;">fas fa-box-archive</v-icon></v-list-item-icon><v-list-item-title><b>Archive</b> selected activities</v-list-item-title></v-list-item>
				<v-list-item v-if="checkboxes_showing" @click="$refs.my_content_list.unassign_selected(my_content_assigned_to_filter)"><v-list-item-icon><v-icon small>fas fa-users-slash</v-icon></v-list-item-icon><v-list-item-title><b>Unassign</b> selected activies for <span v-html="sections_showing"></span></v-list-item-title></v-list-item>
			</v-list>
		</v-menu>

		<v-text-field v-model="search_terms" prepend-inner-icon="fa fa-search" clearable rounded clear-icon="fa fa-times-circle" label="Search lessons and activities" single-line hide-details outlined dense background-color="#ddd" style="flex:0 1 450px"></v-text-field>
		<v-spacer/>
		<div v-if="is_my_sis_course&&!studentish_role" class="mr-2">Show activities for:</div>
		<v-menu bottom left max-height="60vh">
			<template v-slot:activator="{on}"><v-btn v-if="is_my_sis_course&&!studentish_role" v-on="on" small color="secondary" class="k-tight-btn k-nocaps-btn" @click=""><v-icon small class="mr-1">fas fa-eye</v-icon>
				<span v-html="sections_showing"></span>
			</v-btn></template>
			<v-list dense>
				<v-list-item @click="my_content_assigned_to_filter={}"><v-list-item-icon><v-icon v-visible="my_content_assigned_to_filter.empty()" small>fas fa-eye</v-icon></v-list-item-icon><v-list-item-title>All Sections/Students</v-list-item-title></v-list-item>
				<v-divider v-if="assigned_to_options.length>0"/>
				<v-list-item v-for="(assignee, index) in assigned_to_options" :key="assignee.class_sourcedId+assignee.user_sourcedId" @click="my_content_assigned_to_filter=assignee"><v-list-item-icon><v-icon v-visible="my_content_assigned_to_filter.matches(assignee)" small>fas fa-eye</v-icon></v-list-item-icon><v-list-item-title v-html="assignee.toString(null,true)"></v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</div>

	<div v-if="new_lesson" class="mb-4 k-mycontent-new-lesson-component">
		<div class="k-mycontent-item-outer k-mycontent-item-outer-edited elevation-2">
			<div class="k-mycontent-item-title">
				<v-icon class="mr-2" color="primary">{{activity_type_icon('lesson')}}</v-icon>
				<div class="k-lesson-title"><b style="font-weight:900">New {{activity_type_label('lesson')}}</b></div>
				<v-spacer/>
			</div>
			<div class="k-assignment-edit-outer pt-3">
				<LessonEditor :original_lesson="'new'" :course_code="course_code" :lp_unit_id="lp_unit_id" lesson_class="teacher" @edit_lesson_cancel="new_lesson=null" @edit_lesson_saved="new_lesson_saved" />
			</div>
		</div>
	</div>

	<div v-if="new_activity" class="mb-4 k-mycontent-new-lesson-component">
		<div class="k-mycontent-item-outer k-mycontent-item-outer-edited elevation-2">
			<div class="k-mycontent-item-title">
				<v-icon v-if="new_activity.activity_type!='google_assignment'" class="mr-2" color="primary">{{activity_type_icon(new_activity.activity_type)}}</v-icon>
				<img v-if="new_activity.activity_type=='google_assignment'" class="mr-2 k-google-assignment-icon" src="https://www.gstatic.com/prof/logo_assignments_128dp.png">
				<div class="k-lesson-title"><b style="font-weight:900">New {{activity_type_label(new_activity.activity_type)}}</b></div>
				<v-spacer />
			</div>
			<div class="k-assignment-edit-outer pt-3">
				<ActivityEditor ref="activity_editor" :original_activity="new_activity" :initial_content="initial_content" :course_code="course_code"
					:lp_unit_id="lp_unit_id" activity_class="teacher" @edit_activity_cancel="new_activity=null"
					@edit_activity_saved="new_activity_saved" @clear_initial_content="initial_content=''" />
			</div>
		</div>
	</div>


	<div v-if="items.length==0&&!new_lesson&&!new_activity" class="k-mycontent-no-items elevation-2" v-html="no_items_msg"></div>

	<MyContentList ref="my_content_list" v-if="items.length>0&&items_mode=='list'" @duplicate_item="duplicate_item" :items="items" :max_context="max_context" :show_older_items="show_older_items" :items_rendered="items_rendered" :course_code="course_code" :lp_unit_id="lp_unit_id" :n_no_date_items="n_no_date_items" :n_current_items="n_current_items" :filtered_origin_teacher_id="filtered_origin_teacher_id" :checkboxes_showing="checkboxes_showing&&items_mode=='list'" />
	<MyContentGantt v-if="items.length>0&&items_mode=='gantt'" @duplicate_item="duplicate_item" :items="items" :max_context="max_context" :show_older_items="show_older_items" :items_rendered="items_rendered" :course_code="course_code" :lp_unit_id="lp_unit_id" :n_no_date_items="n_no_date_items" :n_current_items="n_current_items" :assigned_to_options="assigned_to_options" :filtered_origin_teacher_id="filtered_origin_teacher_id" />

	<div v-if="more_items_available" class="text-center my-4">Showing {{items_revealed}} items <v-btn small class="ml-2" color="primary" @click="items_revealed+=items_revealed_block_size">Show More</v-btn><v-btn small class="ml-2" color="primary" @click="items_revealed=-1">Show All</v-btn></div>

	<div v-if="max_context==2&&!studentish_role" class="mt-4"><ContentLibrariesView @item_clicked="" :course_code="course_code" :search_terms="search_terms" :lp_unit_id="lp_unit_id" /></div>
	<DuplicateActivity
		v-if="duplicate_activity_data"
		:duplicate_is_template_resource="false"
		:course_code="course_code"
		:lp_unit_id="lp_unit_id"
		:activity_data="duplicate_activity_data"
		@duplicate_activities_created="duplicate_activities_created"
		@dialog_cancel="duplicate_activity_data=null" />
	<DuplicateLesson
		v-if="duplicate_lesson_data"
		:lesson_data="duplicate_lesson_data"
		@duplicate_lesson_created="duplicate_lesson_created"
		@dialog_cancel="duplicate_lesson_data = null" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import LessonEditor from '../lessons/LessonEditor'
import ActivityEditor from '../lessons/ActivityEditor'
import MyContentGantt from './MyContentGantt'
import MyContentList from './MyContentList'
import ContentLibrariesView from './ContentLibrariesView'
import DuplicateActivity from '../lessons/DuplicateActivity'
import DuplicateLesson from '../lessons/DuplicateLesson'

export default {
	components: { LessonEditor, ActivityEditor, MyContentGantt, MyContentList, ContentLibrariesView, DuplicateActivity, DuplicateLesson },
	props: {
		// req: { type: String, required: true },
		course_code: { type: String, required: false, default() { return '' } },
		lp_unit_id: { type: Number, required: false, default() { return 0 } },
		single_activity_id: { type: String, required: false, default() { return '0' } },
		single_lesson_id: { type: String, required: false, default() { return '0' } },
		lp_is_empty: { type: Boolean, required: false, default() { return false } },
		filtered_origin_teacher_id: { required: false, default() { return null } },
	},
	data() { return {
		new_lesson: null,
		new_lesson_is_saved: false,
		new_activity: null,
		new_activity_is_saved: false,
		initial_content: '',
		context_showing: 0,
		contexts: ['All Courses', 'This Course', 'This Unit'],
		items_rendered: false,
		n_old_items: 0,
		n_current_items: 0,
		n_no_date_items: 0,
		duplicate_activity_data: null,
		duplicate_lesson_data: null,

		items_revealed_block_size: 250,
		items_revealed: 250,
		more_items_available: false,

		checkboxes_showing: false,

		search_terms: '',
	}},
	computed: {
		...mapState(['user_info', 'my_lessons', 'my_activities', 'single_item']),
		...mapGetters(['role', 'system_role', 'studentish_role', 'activities_for_student', 'my_sis_courses']),
		is_staff() { return this.role=='staff' || this.role=='admin' },
		lp() { return this.$store.state.all_courses.find(x=>x.course_code==this.course_code) },
		showing_course_materials() { 
			// when viewing a unit, we're always showing unit activities & lessons ('content')
			if (this.context_showing == 2) return false
			// when viewing an lp that is 'empty' we're always showing course_materials (non-unit-specific things -- because we don't have any units!)
			if (this.lp_is_empty) return true
			// else we're showing course_materials if lp_my_content_or_messages_mode is explicitly set to 'course_materials'
			return this.$store.state.lst.lp_my_content_or_messages_mode == 'course_materials'
		},
		show_create_placeholder_button() {
			if (this.is_staff) {
				// if (this.max_context < 2 && !this.lp_is_empty) return true
				if (!this.show_create_button) return true
			}
			return false
		},
		show_create_button() {
			// staff can create...
			if (this.is_staff) {
				// if the lp is "empty" (meaning no units are defined)
				if (this.lp_is_empty) return true
				// or if we're viewing a unit
				if (this.max_context == 2) return true
				// or if we're viewing a course and we're in 'course-wide materials' mode
				if (this.max_context == 1 && this.showing_course_materials) return true
			}
			return false
		},
		unit() {
			if (!this.lp) return null
			return this.lp.units.find(x=>x.lp_unit_id == this.lp_unit_id)
		},
		viewing_my_activities() { return this.filtered_origin_teacher_id == null || this.filtered_origin_teacher_id == this.user_info.user_id },
		is_my_sis_course() {
			// return whether or not this is a course that is in my sis_courses
			// also return false if we're not in any course
			if (this.max_context == 0) return false
			return this.my_sis_courses.find(x=>x.course_code == this.course_code)
		},
		items_mode: {	// list/gantt
			get() { 
				if (this.single_item) return 'list'
				return this.$store.state.lst.my_content_items_mode 
			},
			set(val) { this.$store.commit('lst_set', ['my_content_items_mode', val]) }
		},
		show_older_items: {
			get() { return this.$store.state.lst.show_older_items },
			set(val) { this.$store.commit('lst_set', ['show_older_items', val]) }
		},
		max_context() {
			// if we receive a unit id, we can go down to the unit level; if we receive a course_code we can go to that level
			if (this.lp_unit_id != 0) return 2
			if (this.course_code) return 1
			return 0
		},
		no_items_msg() {
			if (this.single_item) {
				let item_type = window.location.toString().replace(/.*((activity)|(lesson)).*/, '$1')
				if (item_type == window.location.toString()) item_type = 'item'
				item_type = U.capitalize_word(item_type)
				return `<div class="white pa-6 text-center" style="border-radius:20px">You do not have access to this ${item_type} at this time (or the ${item_type} has been removed).</div>`

			} else if (this.is_staff) {
				if (this.context_showing == 2) return 'You have not created lessons or activities for this Unit.' // Click the <i class="fas fa-eye"></i> button above to view lessons and activities for other units in this course or from across the whole site.'
				if (this.context_showing == 1) {
					if (this.showing_course_materials) {
						return 'You have not created any course-wide materials for this Course.'
					} else {
						return 'You have not created any unit lessons or activities for this Course.'
					}
				}
				return 'You have not created any lessons or activities. Open a course to create one!'
			} else if (this.role == 'parent') {
				if (this.context_showing == 2) return 'Your student does not have any assigned activities for this Unit.'
				if (this.context_showing == 1) return 'Your student does not have any assigned activities for this Course.'
				return 'Your student does not have any assigned activities.'
			} else {
				if (this.context_showing == 2) return 'You do not have any assigned activities for this Unit.'
				if (this.context_showing == 1) return 'You do not have any assigned activities for this Course.'
				return 'You do not have any assigned activities.'
			}
		},
		header_text() {
			let s
			if (!this.showing_course_materials) {
				if (this.role == 'parent') s = 'Unit Activities'	// My Child’s 
				else if (this.role == 'student') s = 'Unit Activities'
				else s = 'Unit Lessons & Activities'

				s = `<span style="font-weight:600">${s}</span>: <span style="font-weight:normal">`
				if (this.max_context == 0) s += 'All Courses'
				if (this.max_context == 1) s += 'All Units'	// (this.lp) ? this.lp.title + ' <nobr>(all units)</nobr>' : 'All Units'
				if (this.max_context == 2) s += (this.unit) ? this.unit.title : 'This Unit'
				s += '</span>'

			} else {
				if (this.role == 'parent') s = 'Course-Wide Materials'	// My Child’s 
				else if (this.role == 'student') s = 'Course-Wide Materials'
				else s = 'Course-Wide Materials'
			}

			return s
		},
		items() {
			let n_old = 0
			let n_no_date = 0
			let n_current = 0
			let arr = []

			let search_terms = this.search_terms?.toLowerCase()

			// side-effect: note any lessons/activities tied to this course_code that aren't tied to a valid lp_unit_id -- this will happen when courses get moved from one year to the next
			let items_with_bad_lp_unit_ids = []

			// start with this.more_items_available false; we'll set to true below if we have to skip some
			this.more_items_available = false

			// start with lessons, which should only be shown for teachers (but we have to account for a teacher viewing as a student)
			let lesson_pool = this.my_lessons
			if (!this.studentish_role) {
				// for teachers, only show activities created by them
				lesson_pool = lesson_pool.filter(x=>x.creator_user_id == (this.filtered_origin_teacher_id ?? this.user_info.user_id))
			}
			for (let i = 0; i < lesson_pool.length; ++i) {
				// if we're creating a new lesson and have already saved it, skip the last lesson, because that will be the new lesson, whose editor is still open
				if (this.new_lesson && this.new_lesson_is_saved && i == lesson_pool.length-1) break

				let lesson = lesson_pool[i]

				// don't show any lessons if we're showing a single activity_id
				if (this.single_activity_id != '0') continue
				// show only the designated lesson if we're showing a single lesson_id
				if (this.single_lesson_id != '0' && lesson.lesson_id != this.single_lesson_id*1) continue

				// if student or parent role, only show lessons that have a date and a student_description
				if (this.role == 'student' || this.role == 'parent') {
					if (!lesson.available_to_students()) continue
				}

				if (!this.single_item) {
					if (this.showing_course_materials) {
						if (lesson.lp_unit_id != 0) continue
					} else {
						if (lesson.lp_unit_id == 0) continue
					}
				}

				// limit by course_code / lp_unit_id if we get them
				if (this.context_showing == 1 && this.course_code && lesson.course_code != this.course_code) continue
				if (this.context_showing == 2 && this.lp_unit_id && lesson.lp_unit_id != this.lp_unit_id) continue

				// if we have search_terms, filter based on lesson title
				if (search_terms && !lesson.lesson_title.toLowerCase().includes(search_terms)) continue

				// if we're viewing at the course level, and this item is tied to a unit that no longer exists in the course, note that
				if (this.context_showing == 1 && lesson.lp_unit_id && this.lp.units.findIndex((x)=>x.lp_unit_id==lesson.lp_unit_id) == -1) {
					items_with_bad_lp_unit_ids.push({type:'lesson', item_id:lesson.lesson_id, lp_unit_id: lesson.lp_unit_id})
				}

				let item = {
					type: 'lesson',
					o: lesson,
					title: lesson.lesson_title,
					id: 'L' + lesson.lesson_id,
					icon: lesson.icon(),
					has_date: !empty(lesson.lesson_date),
					is_old: lesson.item_is_old(),
					sort_date: lesson.sort_date(),
				}
				arr.push(item)
				if (item.is_old) ++n_old
				if (!item.has_date) ++n_no_date
			}

			// now activities. for a student/parent, we should only have activities assigned and currently available to the student in my_activities, but account for a teacher viewing as a student
			// If parent, we should only have activities assigned the student in activities_for_student
			let activity_pool = (this.context_showing > 0)
				? this.$store.state.my_activities_by_course[this.course_code]
				: (this.role === 'parent') ? this.activities_for_student : this.my_activities
			if (activity_pool && !this.studentish_role) {
				// for teachers, only show activities created by them
				activity_pool = activity_pool.filter(x=>x.creator_user_id == (this.filtered_origin_teacher_id ?? this.user_info.user_id))
			}

			// note: if activity_pool is null at this point, it means there aren't any my_activities for this course.
			if (activity_pool) for (let i = 0; i < activity_pool.length; ++i) {
				// if we're creating a new activity and have already saved it, skip the last activity, because that will be the new activity, whose editor is still open
				if (this.new_activity && this.new_activity_is_saved && i == activity_pool.length-1) break
				
				let activity = activity_pool[i]
				
				// don't show any activities if we're showing a single lesson_id
				if (this.single_lesson_id != '0') continue
				// show only the designated activity if we're showing a single activity_id
				if (this.single_activity_id != '0' && activity.activity_id != this.single_activity_id*1) continue

				// if student or parent role, only show currently-available activities
				if (this.role == 'student' || this.role == 'parent') {
					if (!activity.available_to_students()) continue
					// TODO: check assigned_to...
				}

				if (!this.single_item) {
					if (this.showing_course_materials) {
						if (activity.lp_unit_id != 0) continue
					} else {
						if (activity.lp_unit_id == 0) continue
					}
				}

				// limit by course_code / lp_unit_id if we get them
				if (this.context_showing == 1 && this.course_code && activity.course_code != this.course_code) continue
				if (this.context_showing == 2 && this.lp_unit_id && activity.lp_unit_id != this.lp_unit_id) continue

				// if my_content_assigned_to_filter is set (and this is a staff member), limit to activities with assignees that match it
				if (!this.studentish_role && !this.my_content_assigned_to_filter.empty()) {
					// if a student is chosen, show assignments for just the student, or the student's class
					if (this.my_content_assigned_to_filter.user_sourcedId) {
						let match = false
						for (let a of activity.assigned_to) {
							if (a.matches(this.my_content_assigned_to_filter) || a.matches_class(this.my_content_assigned_to_filter)) { match = true; break; }
						}
						if (!match) continue

					// else if a section is chosen, assignees must match exactly 
					} else {
						if (!activity.includes_assignee(this.my_content_assigned_to_filter)) continue
					}
				}
				
				// if we have search_terms, filter based on activity title
				if (search_terms && !activity.activity_title.toLowerCase().includes(search_terms)) continue

				// if we're viewing at the course level, and this item is tied to a unit that no longer exists in the course, note that
				if (this.context_showing == 1 && activity.lp_unit_id && this.lp.units.findIndex((x)=>x.lp_unit_id==activity.lp_unit_id) == -1) {
					items_with_bad_lp_unit_ids.push({type:'activity', item_id:activity.activity_id, lp_unit_id: activity.lp_unit_id})
				}

				let item = {
					type: 'activity',
					o: activity,
					title: activity.activity_title,
					id: 'A' + activity.activity_id,
					icon: activity.icon(),
					has_date: !empty(activity.available_date()) || !empty(activity.due_date()),
					is_old: activity.item_is_old(),
					sort_date: activity.sort_date(),
				}
				arr.push(item)
				if (item.is_old) ++n_old
				else if (!item.has_date) ++n_no_date
				else ++n_current

				// if we reach items_revealed items, only show that many; user can choose to see more if they wish
				if (this.items_revealed > -1 && arr.length > this.items_revealed) {
					this.more_items_available = true
					break
				}
			}

			if (this.single_item && arr[0]) {
				// always show a the single item
				this.$store.commit('set', [arr[0].o, 'item_showing', true])
			}

			if (items_with_bad_lp_unit_ids.length > 0) {
				this.offer_to_correct_items_with_bad_lp_unit_ids(items_with_bad_lp_unit_ids)
			}

			// sort by...
			arr.sort((a,b)=>{
				// see Activity.sort_date and Lesson.sort_date -- items without dates will go to the bottom
				if (a.sort_date < b.sort_date) return -1
				if (a.sort_date > b.sort_date) return 1

				// sort others by created date -- more recent first
				return b.o.created_at - a.o.created_at
			})

			// if we have more than items_revealed items, only show that many; user can choose to see more if they wish
			if (this.more_items_available) {
				arr.splice(this.items_revealed, 10000000)
			}

			// have to do this to get the outer_content_item_class method to work properly
			this.$nextTick(()=>this.items_rendered = true)

			// if (activity_pool) console.log(`CALCULATING MY ITEMS for ${this.context_showing}: ${this.course_code}/${this.lp_unit_id} - ${this.my_lessons.length}/${activity_pool.length} -- ${arr.length}`)

			// set n_old_items and return arr
			this.n_old_items = n_old
			this.n_no_date_items = n_no_date
			this.n_current_items = n_current
			return arr
		},
		past_due_items() {

		},
		my_content_assigned_to_filter: {
			get() {
				// make sure we use an Assignee object here
				if (this.max_context == 0) return new Assignee()
				let a = new Assignee(this.$store.state.lst.my_content_assigned_to_filter[this.course_code]) 
				// make sure this assignee is one of my options; if, for example, you were viewing the site earlier and had chosen a section; and now that section no longer exists; then this would cause a problem
				if (!this.assigned_to_options.find(x=>x.matches(a))) return new Assignee()
				return a
			},
			set(val) { 
				if (this.max_context == 0) return

				// make sure val is *not* an object when stored to 
				let o = extobj(this.$store.state.lst.my_content_assigned_to_filter)
				o[this.course_code] = new Assignee(val).copy_for_save()
				this.$store.commit('lst_set', ['my_content_assigned_to_filter', o]) 
			}
		},
		sections_showing() {
			if (this.my_content_assigned_to_filter.empty()) return 'All Sections/Students'
			else return this.my_content_assigned_to_filter.toString(null,true)
		},
		assigned_to_options() {
			let arr = []

			let activity_pool = (this.context_showing > 0) ? this.$store.state.my_activities_by_course[this.course_code] : this.my_activities
			if (empty(activity_pool)) return []

			for (let i = 0; i < activity_pool.length; ++i) {
				let activity = activity_pool[i]
				// limit by course_code / lp_unit_id if we get them
				if (this.context_showing == 1 && this.course_code && activity.course_code != this.course_code) continue
				if (this.context_showing == 2 && this.lp_unit_id && activity.lp_unit_id != this.lp_unit_id) continue

				// add new assignees to our arr
				if (activity.assigned_to.length > 0) {
					for (let assignee of activity.assigned_to) {
						if (!arr.find(x=>(x.class_sourcedId == assignee.class_sourcedId && x.user_sourcedId == assignee.user_sourcedId))) {
							// by using the Assignee class, we can use toString
							arr.push(new Assignee({class_sourcedId:assignee.class_sourcedId, user_sourcedId:assignee.user_sourcedId}))
						}
					}
				}
			}

			// sort with sections first
			arr.sort((a,b)=>{
				// classes come before students (it's a student if it has a user_sourcedId
				if (a.user_sourcedId && !b.user_sourcedId) return 1
				if (b.user_sourcedId && !a.user_sourcedId) return -1

				// either both are students or both are classes; either way, sort by toString
				return (a.toString() < b.toString()) ? -1 : 1
			})

			return arr
		},
	},
	watch: {
		'$vuetify.breakpoint'() { if (!this.$vuetify.breakpoint.smAndUp) this.items_mode = 'list' }
	},
	created() {
		vapp.my_content_view = this

		// set initial context value to max_content
		this.context_showing = this.max_context

		// this component gets loaded directly by router if we're showing a single activity or lesson...
		if (this.single_activity_id*1 > 0 || this.single_lesson_id*1 > 0) {
			// store the single_item 'mode', which might be 'admin' or 'studentpreview'
			this.$store.commit('set', ['single_item_mode', window.location.search.substr(1)])
		
		} else {
			// get lesson_masters if we haven't already gotten done so
			this.$store.dispatch('get_lesson_masters')
		}
	},
	mounted() {
		let payload = {
			course_code: this.course_code,
		}
		this.$store.dispatch("get_messages", payload)
	},
	methods: {
		activity_type_icon(activity_type) { return U.activity_type_icon(activity_type) },
		activity_type_label(activity_type) { return U.activity_type_label(activity_type) },

		create_placeholder() {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			if (this.lp_is_empty) {
				// allow the user to create a new lesson if the learning progression is empty
				this.create_new_lesson()
				return
			}
			this.$alert('To create a lesson or activity, start by navigating to the Learning Progression the lesson or activity should be associated with.')
		},

		create_new_lesson() {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			this.new_lesson = 'new'
			this.new_lesson_is_saved = false
		},

		new_lesson_saved(edited_lesson, flag) {
			// the lesson editor will have saved the lesson to the db; push it to my_lessons, unless we've already done so
			if (this.new_lesson_is_saved) return

			let new_lesson = new Lesson(edited_lesson)
			this.$store.commit('set', [this.my_lessons, 'PUSH', new_lesson])

			// for now we just close the new lesson editor and open it, not in editing mode; later we can work on making the lesson editor work like the activity editor
			this.new_lesson = null
			let new_item = this.items.find(x=>x.id == 'L' + new_lesson.lesson_id)
			if (new_item) this.$store.commit('set', [new_item.o, 'item_showing', true])
			return

			// this.new_lesson_is_saved = true
		},

		create_new_activity(type, starting_resource, link_description) {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			// create a stub of the new activity to be created
			let o = {
				activity_type: type,
			}

			if (type == 'google_assignment') {
				o.tool_id = 'google'
			} else {
				// default: sparkl
				o.tool_id = 'sparkl'
				o.tool_activity_id = '0'
			}
			
			if (starting_resource) {
				// if we got a starting_resource, add it to the activity stub
				let r = new Resource(starting_resource)
				o.resources = [r]

				// set the activity title to the link_description
				o.activity_title = link_description

				// set initial_content to be used for the first Sparkl exercise
				this.initial_content = (sr('<p>$1: <a class="k-host-link-nobr" title="$1" data-resource-link-id="$2" onclick="vapp.open_resource_link(\'$3\',this)"><i class="fas $4 mr-2"></i>LINK</a></p><p></p>', link_description, U.new_uuid(), starting_resource.resource_id, starting_resource.icon()))
				// vapp.open_resource_link(\'$4\',this)

				// after a few ticks, jump to the editor and reveal the resources
				setTimeout(()=>{
					// we can't set this to true right away, because the activity constructor always starts it out as false
					this.$refs.activity_editor.resources_showing = true
					this.$vuetify.goTo($(this.$el).find('.k-mycontent-new-lesson-component')[0], { offset: 96 })
				}, 300)
			} else {
				// if we *don't* have a starting_resource, we have to clear the initial_content parameter (otherwise, we'll use this as the initial content for the next activity if no starting_resource is specified)
				this.initial_content = ''
			}

			// setting new_activity to o will show the editor and start the process of letting the teacher create the activity
			this.new_activity = o
			this.new_activity_is_saved = false
		},

		add_sparkl_activity_from_sparkl_activity_id(tool_activity_id) {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			let o = {
				activity_type: 'activity',
				tool_activity_id: tool_activity_id,
			}
			this.initial_content = ''

			// setting new_activity to o will show the editor and start the process of letting the teacher create the activity
			this.new_activity = o
			this.new_activity_is_saved = false
		},

		new_activity_saved(edited_activity) {
			// the activity editor will have saved the activity to the db
			let new_activity = new Activity(edited_activity)

			if (!this.new_activity_is_saved) {
				// if new activity isn't yet saved, push it to my_activities
				this.$store.commit('add_to_my_activities', new_activity)
				// this.$store.commit('set', [this.my_activities, 'PUSH', new_activity])
				this.new_activity_is_saved = true
			} else {
				// else update (replace) activity
				this.$store.commit('replace_in_my_activities', new_activity)
				// let index = this.my_activities.findIndex(x => x.activity_id == edited_activity.activity_id)
				// this.my_activities.splice(index, 1, new_activity)
			}
		},

		import_shared_item(from = 'hc') {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			let text, title
			if (from == 'hc') {
				title = 'Import Shared Lesson Plan or Activity <nobr>from HenryConnects</nobr>'
				text = 'Enter the shared lesson ID or activity ID from HenryConnects:<div class="mt-2" style="font-size:14px;font-style:italic">This ID should start with an “L” or “A”.</div>'
				// <div style="font-size:14px;font-style:italic"><b>For items shared from Inspire,</b> prefix the ID with an <span style="font-family:monospace">I</span> (e.g. “<span style="font-family:monospace">IL2302</span>”).</div>
			} else {
				title = 'Import Lesson Plan or Activity from Inspire'
				text = 'Enter the shared lesson ID or activity ID from Inspire:<div class="mt-2" style="font-size:14px;font-style:italic">This ID should start with an “L”, “A”, or “S”.</div>'
			}
			this.$prompt({
				title: title,
				text: text,
				dialogMaxWidth: 540,
				acceptText: 'Import',
			}).then(entered_item_id => {
				entered_item_id = $.trim(entered_item_id).toUpperCase()
				if (entered_item_id.search(/^([IALS]+)(\d+)$/) == -1) {
					this.$alert('The ID you entered is not valid.').then(()=>this.import_shared_item())
					return
				}
				let item_id_prefix = RegExp.$1
				let item_id = RegExp.$2

				// back-door functionality: "S203" adds sparkl activity 203 as a new activity
				// when importing from inspire, we may get an 'S' ID
				if (item_id_prefix == 'S' && from != 'inspire') {
					this.add_sparkl_activity_from_sparkl_activity_id(item_id)
					return
				}

				// allow for a backup method where you enter "IL2034" for inspire lesson 2034
				if (item_id_prefix[0] == 'I') {
					from = 'inspire'
					item_id_prefix = item_id_prefix[1]
				}

				let payload = {
					user_id: this.user_info.user_id, 
					item_id_prefix: item_id_prefix,	// this is only used for import from inspire
					item_id: item_id,
					creator_user_id: this.user_info.user_id,
					course_code: this.course_code,
					lp_unit_id: this.lp_unit_id,
				}

				let service
				if (from == 'inspire') service = 'add_from_inspire'
				else if(item_id_prefix == 'L') service = 'add_shared_lesson'
				else service = 'add_shared_activity'
				U.loading_start()
				U.ajax(service, payload, result=>{
					U.loading_stop()
					if (result.status == 'item_not_found') {
						this.$alert(sr('We could not find an item with the ID you entered ($1).', entered_item_id)).then(()=>this.import_shared_item(from))
					} else if (result.status != 'ok') {
						this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
					}

					let new_item_id
					if (item_id_prefix == 'L') {
						let new_lesson = new Lesson(result.lesson)
						this.$store.commit('set', [this.my_lessons, 'PUSH', new_lesson])
						this.$inform('Lesson Plan added')
						new_item_id = 'L' + new_lesson.lesson_id
					} else {
						let new_activity = new Activity(result.activity)
						this.$store.commit('add_to_my_activities', new_activity)
						// this.$store.commit('set', [this.my_activities, 'PUSH', new_activity])
						this.$inform('Activity added')
						new_item_id = 'A' + new_activity.activity_id
					}

					// open the new item??
					// setTimeout(()=>{
					// 	let index = this.items.findIndex(x=>x.id == new_item_id)
					// 	if (index > -1) {	// we should always find the item
					// 		// first we have to show the item, then set item_being_edited
					// 		this.$store.commit('set', [this.items[index].o, 'item_showing', true])
					// 		setTimeout(x=>{
					// 			this.$refs['item'+new_item_id][0].item_being_edited = true
					// 		}, 300)
					// 	}
					// }, 300)
				});

				
			}).catch(n=>{console.log(n)}).finally(f=>{})

		},

		duplicate_item(item) {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			// note that this is almost identical to the fn in LearningProgressionUnitEdit
			// for a lesson, we can just use share_lesson
			if (item.type == 'lesson') {
				// set duplicate_lesson_data to show the DuplicateLesson interface
				this.duplicate_lesson_data = item.o
			} else {
				// Call a service to get a list of the exercises from the activity
				U.loading_start()
				U.ajax('get_activity_exercise_list', {user_id: this.user_info.user_id, tool_activity_id: item.o.tool_activity_id}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
					}

					// set duplicate_activity_data to show the DuplicateActivity interface
					result.activity_data.exercise_records = result.exercises
					this.duplicate_activity_data = result.activity_data
					this.duplicate_activity_data.original_activity_id = item.o.activity_id
				});
			}
		},

		duplicate_activities_created(activities, archive_original_activity_id = 0) {
			// push newly-created activity(s) to my_activities
			for (let activity of activities) {
				let new_activity = new Activity(activity)
				this.$store.commit('add_to_my_activities', new_activity)
			}
			if (archive_original_activity_id !== 0) {
				// TODO: make the user confirm
				let payload = {
					user_id: this.user_info.user_id,
					activity_id: archive_original_activity_id,
				}
				U.loading_start()
				U.ajax('archive_activity', payload, result => {
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert('Error in ajax call: ' + result.status);
						vapp.ping();
						return;
					}
					let new_activity = new Activity({activity_id: archive_original_activity_id, course_code: this.course_code})
					this.$store.commit('remove_from_my_activities', new_activity)
					this.$inform('Duplicated activity/activities added and original archived')
				})
			} else {
				this.$inform('Duplicated activity/activities added')
			}

			// close the dialog
			this.duplicate_activity_data = null
		},

		duplicate_lesson_created(duplicate_lesson, archive_original = false) {
			let payload = {
				user_id: this.user_info.user_id,
				creator_user_id: this.user_info.user_id,
				course_code: this.course_code,
				lp_unit_id: this.lp_unit_id,
				item_id: duplicate_lesson.item_id,
				new_lesson_title: duplicate_lesson.new_lesson_title,
				// note that we *don't* send in a template_id, so the new lesson will take on the original lesson as its template
			}
			U.loading_start()
			U.ajax('add_shared_lesson', payload, result => {
				U.loading_stop()
				if (result.status != 'ok') {
					this.$alert('Error in ajax call: ' + result.status);
					vapp.ping();
					return;
				}
				let new_lesson = new Lesson(result.lesson)
				this.$store.commit('set', [this.my_lessons, 'PUSH', new_lesson])
				if (archive_original) {
					U.loading_start()
					U.ajax('archive_lesson', {user_id: this.user_info.user_id, lesson_id: duplicate_lesson.item_id}, result => {
						U.loading_stop()
						if (result.status != 'ok') {
							this.$alert('Error in ajax call: ' + result.status);
							vapp.ping();
							return;
						}
						let index = this.my_lessons.findIndex(x => x.lesson_id == duplicate_lesson.item_id)
						this.my_lessons.splice(index, 1)
						this.$inform('Duplicate lesson added and original lesson archived')
					});
				} else {
					this.$inform('Duplicate lesson added')
				}
				// clear the duplicated data to close the dialog
				this.duplicate_lesson_data = null
			})
		},

		offer_to_correct_items_with_bad_lp_unit_ids(items_with_bad_lp_unit_ids) {
			// When we move from one academic year to another, the lp_unit_ids for each course change. When we detect this, offer to the user to correct the lp_unit_ids
			let s = `One or more of your lessons and activities appear to have been created in a previous iteration of this course (i.e. in a prior academic year). Click MIGRATE ITEMS below to move these items into their corresponding Units of the current iteration of the course. (Note: if any units have been renamed in the current version of the course, lessons and activities from those units will be placed in Unit 1 of the current course.)`
			this.$confirm({
				title: 'Migrate Lessons and Activities',
				text: s,
				acceptText: 'Migrate Items Now',
				cancelText: 'Don’t Migrate at this time',
				dialogMaxWidth: 620,
			}).then(y => {
				let payload = {
					user_id: this.user_info.user_id,
					course_code: this.course_code,
					items_with_bad_lp_unit_ids: items_with_bad_lp_unit_ids,
				}
				U.loading_start()
				U.ajax('migrate_items_with_bad_lp_unit_ids', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
					}

					this.$alert({
						text: 'Reload to see updated items.',
						acceptText: 'Reload',
						focusBtn: true,		// focus on the accept btn when dialog is rendered
					}).then(y => {
						window.location.reload()
					}).catch(n=>{console.log(n)}).finally(f=>{})

				});
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
	}
}
</script>

<style lang="scss">
.k-mycontent-view2-outer {
	// width:800px;
	// max-width:860px;
	max-width:100vw;
	margin:0 auto;
	text-align:left;
}

.k-mycontent-view2-outer-expanded {
	position: fixed;
	width:1000px;
	left: calc(50vw - 500px);
	// width: calc(100vw - 40px);
	// left: 20px;
	// height: 640px;
	// top: calc(50vh - 320px);
	// height: calc(100vh - 40px);
	// top: 20px;
	top:70px;
	max-height:calc(100vh - 90px);
	overflow:auto;
	z-index: 10000000;
}

.k-mycontent-view2-outer-scrim {
	position:fixed;
	width:100vw;
	height:100vh;
	background-color:rgba(0,0,0,0.8);
	z-index: 9999999;
	display:none;
}

.k-mycontent-view2-outer-expanded .k-mycontent-view2-outer-scrim {
	display:block;
}

.k-mycontent-view-header {
	max-width:860px;
	margin-left:auto;
	margin-right:auto;
	text-align:left;
	font-size:24px;
	line-height:30px;
	font-weight:bold;
	margin-bottom:8px;
	// margin-left:8px;
	display:flex;
	align-items:center;
}

.k-mycontent-view-filters {
	max-width:860px;
	margin-left:auto;
	margin-right:auto;
	text-align:left;
	font-size:14px;
	// line-height:30px;
	// font-weight:bold;
	margin-bottom:8px;
	// margin-left:8px;
	display:flex;
	align-items:center;
}

.k-my-content-view-list-outer {
	max-width:860px;
	margin-left:auto;
	margin-right:auto;
}

.k-mc-gantt-item-showing-outer {
	width:860px;
	max-width:860px;
	margin-left:auto;
	margin-right:auto;
}

.k-mycontent-new-lesson-component {
	max-width:860px;
	margin-left:auto;
	margin-right:auto;
}

.k-mycontent-item-outer {
	background-color:#f5f5f5;
	border-radius:8px;
	padding:6px;
	margin-top:0;
	margin-bottom:4px;
}

.k-mycontent-item-outer-hovered {
	background-color:#fff;
}

.k-mycontent-item-outer-showing {
	background-color:#fff;
	margin-top:12px;
	margin-bottom:12px;
}

.k-mycontent-item-outer-edited {
	background-color:#fff;
	border:2px solid #666;
	position:relative;	// needed for positioning of close/save btns
}

.k-mycontent-item-view-mid {
	border-radius:8px;
	padding:2px;
}

.k-mycontent-item-title {
	display:flex;
	align-items: center;
}

.k-mycontent-no-items {
	max-width:880px;
	margin-left:auto;
	margin-right:auto;
	background-color:#fff;
	border-radius:8px;
	margin-top:12px;
	padding:12px;
	font-size:16px;
	font-style: italic;
}

.k-mycontent-view-single-item {
	margin-top:60px;
}

// note that this needs to be last, so that the background color overwrites others above
.k-mycontent-item-outer-last-viewed {
	background-color:$v-amber-lighten-5;
}
.k-mycontent-item-outer-last-viewed-hovered {
	background-color:$v-amber-lighten-4;
}
</style>
