var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"k-main-division-wrapper k-main-welcome"},[_c('SiteSearch',{on:{"search_lps":_vm.search_lps,"search_case":_vm.search_case}}),_vm._v(" "),_c('div',{staticClass:"k-main-welcome__question k-shadow-text"},[_c('div',[_c('b',{staticClass:"amber--text"},[_vm._v("Good "+_vm._s(_vm.time_of_day)+", "+_vm._s(_vm.user_info.first_name)+"!")])])]),_vm._v(" "),_c('div',{staticClass:"k-main-welcome__time-date k-shadow-text",domProps:{"innerHTML":_vm._s(_vm.time_string)}}),_vm._v(" "),_c('div',{staticClass:"k-main-welcome--nav-btns"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.show_parent_tab)?_c('v-btn',_vm._g({staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='family_home'?'k-main-welcome--nav-btn-current':'',attrs:{"x-large":"","color":"primary"},on:{"click":function($event){return _vm.switch_welcome_section('family_home')}}},on),[_c('v-icon',[_vm._v("fas fa-house-user")])],1):_vm._e()]}}])},[_vm._v("Family Home")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='classes'?'k-main-welcome--nav-btn-current':'',attrs:{"x-large":"","color":"primary"},on:{"click":function($event){return _vm.switch_welcome_section('classes')}}},on),[_c('v-icon',[_vm._v("fas fa-chalkboard")])],1)]}}])},[_vm._v("My Classes")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.role=='staff'||_vm.role=='admin')?_c('v-btn',_vm._g({staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='communities'?'k-main-welcome--nav-btn-current':'',attrs:{"x-large":"","color":"primary"},on:{"click":function($event){return _vm.switch_welcome_section('communities')}}},on),[_c('v-icon',[_vm._v("fas fa-users")])],1):_vm._e()]}}])},[_vm._v("Communities")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.role=='staff'||_vm.role=='admin')?_c('v-btn',_vm._g({staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='pd'?'k-main-welcome--nav-btn-current':'',attrs:{"x-large":"","color":"primary"},on:{"click":function($event){return _vm.switch_welcome_section('pd')}}},on),[_c('v-icon',[_vm._v("fas fa-user-graduate")])],1):_vm._e()]}}])},[_vm._v("Professional Development")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='launchers'?'k-main-welcome--nav-btn-current':'',attrs:{"x-large":"","color":"primary"},on:{"click":function($event){return _vm.switch_welcome_section('launchers')}}},on),[_c('v-icon',[_vm._v("fas fa-circle-nodes")])],1)]}}])},[_vm._v("Connections")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.show_admin_tab&&_vm.$vuetify.breakpoint.smAndUp),expression:"show_admin_tab&&$vuetify.breakpoint.smAndUp"}],staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='admin'?'k-main-welcome--nav-btn-current':'',attrs:{"x-large":"","color":"primary"},on:{"click":function($event){return _vm.switch_welcome_section('admin')}}},on),[_c('v-icon',[_vm._v("fas fa-cog")])],1)]}}])},[_vm._v("Site Admin Tools")])],1),_vm._v(" "),_c('div',{staticClass:"k-main-welcome--pages"},[(_vm.welcome_section_showing=='family_home')?_c('FamilyHome',{ref:"family_home"}):_vm._e(),_vm._v(" "),(_vm.welcome_section_showing=='classes' && _vm.my_classes_view=='lpindex')?_c('LearningProgressionIndex2',{ref:"lp_index_component"}):_vm._e(),_vm._v(" "),(_vm.welcome_section_showing=='classes' && _vm.my_classes_view=='assignments')?_c('CourseList'):_vm._e(),_vm._v(" "),(_vm.welcome_section_showing=='communities')?_c('CommunitiesList'):_vm._e(),_vm._v(" "),(_vm.welcome_section_showing=='admin')?_c('AdminList'):_vm._e(),_vm._v(" "),(_vm.welcome_section_showing=='pd')?_c('PDIndex'):_vm._e(),_vm._v(" "),(_vm.welcome_section_showing=='launchers')?_c('Launchers'):_vm._e()],1),_vm._v(" "),(_vm.case_tree_loaded)?_c('CASETree',{directives:[{name:"show",rawName:"v-show",value:(_vm.case_tree_showing),expression:"case_tree_showing"}],ref:"case_tree",attrs:{"framework_identifier":_vm.case_framework_identifier,"showing":_vm.case_tree_showing,"imported_search_terms":_vm.case_search_terms},on:{"hide_tree":function($event){_vm.case_tree_showing=false}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }