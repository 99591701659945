<template>
	<div class="k-message-editor">
		<v-form ref="message_editor_form">
			<div class="mx-2">

				<!-- Show Send To if Reply (or is student generated (Activity) message) -->
				<div v-if="is_reply || is_student_generated" class="d-flex align-center">
					<div class="k-message-editor-reply-to" style="flex: 1 1 auto; color: black">
						<span class="k-message-editor-reply-to--caption">{{ is_reply ? 'Reply ' : '' }}To:&nbsp;</span>{{ reply_to_string }}
					</div>
				</div>

				<!-- Message Subject -->
				<div v-if="show_teacher_fields" class="d-flex align-center">
					<div style="flex: 1 1 auto">
						<v-text-field background-color="#f8f8f8" :dense="true" hide-details outlined v-model="subject"
							:rules="message_subject_rules" prepend-inner-icon="fas fa-scroll"
							:label="'Message Subject'"></v-text-field>
					</div>
				</div>

				<!-- Message Recipients -->
				<div v-if="show_teacher_fields" class="d-flex align-center mt-3">
					<div class="k-message-editor-recipient-display">
						<div class="d-flex align-center">
							<div>
								<v-icon small class="mr-2" color="#727272">fas fa-users</v-icon>Send to:
								<b>{{ recipient_count }} Recipient{{ recipient_count === 1 ? "" : "s" }}</b>
							</div>
							<v-btn v-show="recipient_count > 0 && !show_recipient_control" x-small color="#ddd"
								class="ml-2 elevation-0" @click="show_all_recipients = !show_all_recipients">Details</v-btn>
							<v-btn x-small color="primary" class="ml-2 elevation-1" @click="activate_recipient_menu"><v-icon
									x-small class="mr-1">fas fa-edit</v-icon>{{ show_recipient_control ? "Done" : "Edit"
									}}</v-btn>
							<v-spacer />
							<v-btn v-show="show_recipient_control" class="k-tight-btn" x-small color="primary"
								@click="section_chooser_showing = true"><v-icon x-small class="mr-1">fas
									fa-gear</v-icon>Filter
								Sections</v-btn>
						</div>
						<div v-show="show_all_recipients" class="mt-2">
							<div class="ma-1" v-for="(recipient, index) in recipients" :key="index">
								{{ recipient.to_string() }}
							</div>
						</div>

						<div style="width: calc(100% - 100px)">
							<v-autocomplete v-show="show_recipient_control" ref="recipient_autocomplete" :menu-props="{
								maxHeight: 400,
								dense: true,
								contentClass: 'k-message-editor-recipient-control',
							}" hide-details label="" background-color="#f8f8f8" chips small-chips deletable-chips multiple
								v-model="recipients" @change="recipients_changed" :items="recipient_options"
								:rules="message_recipient_rules" :filter="recipient_option_filter"
								prepend-inner-icon-x="fas fa-users">
								<template v-slot:item="{ item }">
									<div style="font-size: 14px; height: 45px; align-items: center;"
										class="d-flex flex-grow-1" @click="last_clicked_recipient_option = item">
										<div>
											<v-icon color="primary" class="mr-2">{{
												item.recipient ? "fas fa-square-check" : "far fa-square"
											}}</v-icon>
										</div>
										<div :style="item.type == 'section' || item.type == 'all'
												? 'font-weight:bold'
												: ''
											">
											{{ item.text }}
										</div>
										<v-spacer />
									</div>
								</template>
							</v-autocomplete>
						</div>
						<v-radio-group v-show="recipient_count > 0" background-color="#f8f8f8" dense row v-model="send_to">
							<v-radio :label="pluralize('Send to Student', recipient_count)" value="Student"></v-radio>
							<v-radio :label="pluralize('Send to Guardian', recipient_count)" value="Guardian"></v-radio>
							<v-radio
								:label="recipient_count > 1 ? 'Send to Students and Guardians' : 'Send to Student and Guardian'"
								value="Student & Guardian"></v-radio>
						</v-radio-group>
					</div>
				</div>

				<!-- Message Delivery Option (Now / Later) -->
				<div v-if="show_teacher_fields" class="d-flex align-center mt-3">
					<div style="flex: 1 1 auto">
						<v-select label="Notification Options:" background-color="#f8f8f8" dense hide-details outlined
							v-model="delivery_option" :items="[
								{ text: 'Notify Immediately', value: 1 },
								{ text: 'Schedule for Later', value: 0 },
							]"></v-select>
					</div>

					<!-- Datepicker -->
					<div v-if="delivery_option === 0" class="ml-3">
						<v-menu ref="delivery_date_menu" v-model="delivery_date_menu_showing">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-bind="attrs" v-on="on" background-color="#f8f8f8" dense hide-details
									outlined v-model="delivery_date" :rules="message_delivery_date_rules"
									prepend-inner-icon="fas fa-calendar" :label="'Delivery Date'" readonly></v-text-field>
							</template>
							<v-date-picker v-model="delivery_date" locale="en-us" scrollable
								:min="new Date().toISOString().substring(0, 10)">
								<template v-slot:day="{ date }">
									{{ date.getDate() }}
								</template>
							</v-date-picker>
						</v-menu>
					</div>

					<!-- Timepicker -->
					<div v-if="delivery_option === 0" class="ml-3">
						<v-menu ref="delivery_time_menu" v-model="delivery_time_menu_showing"
							:close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-bind="attrs" v-on="on" background-color="#f8f8f8" dense hide-details
									outlined v-model="delivery_time" :rules="message_delivery_time_rules"
									prepend-inner-icon="fas fa-calendar" :label="'Delivery Time'" readonly></v-text-field>
							</template>
							<v-time-picker v-model="delivery_time" locale="en-us" scrollable format="ampm">
								<div class="d-flex" style="width: 100%">
									<v-btn @click="$refs.delivery_time_menu.save(delivery_time)" style="margin: 0 auto">
										Save
									</v-btn>
								</div>
							</v-time-picker>
						</v-menu>
					</div>
				</div>

				<!-- Froala Editor -->
				<div class="mt-3 mb-4">
					<froala-wrapper :config="editor_config()" v-model="body" />
				</div>

				<!-- Control buttons (Send, Schedule for Later, Cancel) -->
				<div class="d-flex">
					<v-spacer></v-spacer>
					<v-btn small v-if="message.body == ''" color="secondary" @click="delete_message"><v-icon small
							class="mr-1">fas fa-times</v-icon> Cancel</v-btn>
					<v-btn small color="primary" class="ml-2" @click="save_edits" :disabled="!send_enabled"><v-icon small
							class="mr-1">fas fa-check</v-icon>Send Message</v-btn>
				</div>
			</div>
			<MessageSectionChooser v-if="section_chooser_showing" :course_code="course_code"
				@dialog_cancel="section_chooser_showing = false" />
		</v-form>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import MessageSectionChooser from "./MessageSectionChooser"

export default {
	components: { MessageSectionChooser },
	props: {
		message: { type: Object, required: true },
		course_code: { type: String, required: true },
		is_reply: { type: Boolean, required: false, default() { return false } },
		reply_to_string: { type: String, required: false, default() { return "" } },
		// When a message is generated by a student from an Assignment...
		is_student_generated: { type: Boolean, required: false, default() { return false } },
		// When a message is generated by a teacher from an Assignment, we need to pre-fill the recipients field
		activity_recipients: { type: Array, required: false, default() { return [] } },
		activity_id: { type: Number, required: false, default() { return 0 } },
	},
	data() {
		return {
			body: this.message.body,
			subject: this.message.subject,
			recipients: [],
			send_to: this.message.send_to ?? "Student",
			delivery_option: this.message.delivery_option ?? 1,
			show_recipient_control: false,
			show_all_recipients: false,
			last_clicked_recipient_option: null,
			editor_component_id: U.new_uuid(),
			delivery_date_menu_showing: false,
			delivery_time_menu_showing: false,
			delivery_date: null,
			delivery_time: null,
			section_chooser_showing: false,
			// form validation rules
			message_subject_rules: [
				(v) => !!v || "Subject is required",
			],
			message_recipient_rules: [
				(v) => !!(v && v.length) || "At least one recipient is required",
			],
			message_notification_option_rules: [
				(v) => !!v || "Notification option is required",
			],
			message_delivery_time_rules: [
				(v) => !!v || "Delivery time is required",
			],
			message_delivery_date_rules: [
				(v) => !!v || "Delivery date is required",
			],

		}
	},
	computed: {
		...mapState(["user_info", "sis_classes"]),
		...mapGetters([]),
		send_enabled() {
			// Reply and Student Generated Activity messages only require the Body field
			if (this.is_reply && this.has_body_text) return true
			if (this.is_student_generated && this.has_body_text) return true

			// if No subject: Return false
			if (!this.has_subject) return false

			// if No recipients: Return false
			if (!this.has_recipients) return false

			// if No body: Return false	
			if (!this.has_body_text) return false

			// If delayed send but no date or time, return false
			if (this.delivery_option === 0) {
				if (!this.delivery_date) return false
				if (!this.delivery_time) return false
			}

			// If all good, return true
			return true
		},
		has_body_text() {
			return this.body && this.body != ""
		},
		has_recipients() {
			return this.recipients && this.recipients.length > 0
		},
		has_subject() {
			return this.subject && this.subject != ""
		},
		formatted_send_at() {
			// Prep the two date/time fields for the API
			if (!this.delivery_date) return 0
			// if date set but time isn't set, set time to 12:00 AM (as string for now)
			const time_string = !this.delivery_time ? '12:00' : this.delivery_time
			const d = new Date(`${this.delivery_date}T00:00:00Z`);
			const t = new Date(`1970-01-01T${time_string}:00Z`);
			const utc_send_at = new Date(
				d.getUTCFullYear(),
				d.getUTCMonth(),
				d.getUTCDate(),
				t.getUTCHours(),
				t.getUTCMinutes(),
				t.getUTCSeconds()
			);
			return utc_send_at.toISOString().slice(0, 19).replace('T', ' ');
		},
		show_teacher_fields() {
			return !this.is_reply && !this.is_student_generated
		},
		recipient_count() {
			let students = []
			for (let recipient of this.recipients) {
				let astudents = recipient.get_students()
				for (let student of astudents) {
					if (!students.includes(student)) students.push(student)
				}
			}
			return students.length
		},

		// Lifted largely from ActivityEditor.vue->computed->assigned_to_options()
		recipient_options() {
			let arr = []
			let students = []
			let recipient_sections = []
			let all_sections_for_course
			let mcs = this.$store.state.lst.message_chooser_sections[this.course_code]

			for (let my_class of this.sis_classes) {
				let for_this_course = my_class.course_code === this.course_code
				if (!for_this_course) continue
				for (let i = 0; i < my_class.class_sourcedIds.length; ++i) {
					let n_students = 0
					if (!empty(my_class.students[i]))
						n_students = my_class.students[i].length
					if (n_students == 0) continue
					let class_sourcedId = my_class.class_sourcedIds[i]
					// if user has filtered out this section, continue
					if (mcs && !mcs[class_sourcedId]) continue

					let section_title = my_class.section_title(i, {
						student_count: false,
						title: false,
						teacher: false,
						term: 0,
					})
					let r = this.recipients.find(
						(x) =>
							x.class_sourcedId == class_sourcedId && x.user_sourcedId == ""
					)
					if (!r) r = new Recipient({ class_sourcedId: class_sourcedId })
					let section_recipient = this.recipients.findIndex((x) => x == r) > -1
					arr.push({
						value: r,
						text:
							section_title +
							sr(" ($1 $2)", n_students, U.ps("student", n_students)),
						section_title: section_title,
						type: "section",
						recipient: section_recipient,
					})

					if (for_this_course) {
						if (!all_sections_for_course)
							all_sections_for_course = {
								value: new Recipient({
									class_sourcedId: "all",
								}),
								n_students: 0,
								text: my_class.title,
								type: "all",
								recipient: true,
							}
						all_sections_for_course.n_students += n_students
						if (!section_recipient) all_sections_for_course.recipient = false
					}
					if (section_recipient) {
						recipient_sections.push(section_title)
					}
					if (!empty(my_class.students[i]))
						for (let student of my_class.students[i]) {
							let s = students.find((x) => x.sourcedId == student.sourcedId)
							if (empty(s)) {
								let name = student.familyName + ", " + student.givenName
								if (!empty(student.middleName))
									name += " " + student.middleName[0] + "."
								let r = this.recipients.find(
									(x) =>
										x.class_sourcedId == class_sourcedId &&
										x.user_sourcedId == student.sourcedId
								)
								if (!r)
									r = new Recipient({
										class_sourcedId: class_sourcedId,
										user_sourcedId: student.sourcedId,
									})
								s = {
									sourcedId: student.sourcedId,
									value: r,
									text: name,
									type: "student",
									recipient: this.recipients.findIndex((x) => x == r) > -1,
									section_code: my_class.title_codes[i][2],
									sections: [section_title],
									recipient_sections: [],
								}
								students.push(s)
							} else {
								s.sections.push(section_title)
							}
							// if this section is assigned, add it to the student's assigned_to_sections
							if (section_recipient) s.recipient_sections.push(section_title)
						}
				}
			}
			if (all_sections_for_course) {
				all_sections_for_course.text = sr(
					"ALL SECTIONS - $1 $2",
					all_sections_for_course.n_students,
					U.ps("student", all_sections_for_course.n_students)
				)
				arr.push(all_sections_for_course)
			}
			arr.sort((a, b) =>
				a.text.localeCompare(
					b.text,
					navigator.languages[0] || navigator.language,
					{ numeric: true, ignorePunctuation: true }
				)
			)
			students.sort((a, b) => {
				if (a.text < b.text) return -1
				if (b.text < a.text) return 1
				return 0
			})
			for (let s of students) {
				if (
					s.sections.filter((x) => recipient_sections.includes(x)).length > 0
				) {
					s.recipient = true
					s.recipient_by_section = true
				}
				for (let ar of this.activity_recipients) {
					if (ar === s.sourcedId) {
						s.recipient = true
						s.recipient_by_section = false
						let r = this.recipients.find((x) => ar === x.user_sourcedId)
						if (!r) {
							r = new Recipient({ user_sourcedId: ar, class_sourcedId: s.value.class_sourcedId })
							this.recipients.push(r)
						}
					}
				}
				arr.push(s)
			}
			return arr
		},
	},
	watch: {},
	created() { },
	mounted() {
		this.$store.commit("set", [
			"editor_components",
			this.editor_component_id,
			this,
		])
	},
	methods: {
		editor_config() {
			return U.get_froala_config({
				placeholderText: "Message Text",
			})
		},
		activate_recipient_menu() {
			this.show_recipient_control = !this.show_recipient_control
			this.show_all_recipients = false

			if (this.show_recipient_control) {
				setTimeout((x) => {
					this.$refs.recipient_autocomplete.focus()
					this.$refs.recipient_autocomplete.activateMenu()
				})
			}
		},
		delete_message() {
			this.$store.dispatch("delete_message", this.message).then(() => {
				this.$emit("editor_cancel")
			})
		},
		pluralize(input, count) {
			return count > 1 ? `${input}s` : input
		},
		// Lifted largely from ActivityEditor.vue->methods->assigned_to_changed()
		recipients_changed() {
			let close_menu = false
			// Whenever outside of the text is clicked, it thinks that the type is undefined
			// console.log(this.last_clicked_recipient_option?.type)
			// if the user just clicked to assign all...
			if (this.last_clicked_recipient_option?.type == "all") {
				// the "all" class_sourcedId has been selected.
				// check all the "real" sections to see if all of them are currently marked as assigned
				let all_assigned = true
				for (let opt of this.recipient_options) {
					if (opt.type == "section") {
						if (!opt.recipient) all_assigned = false
					}
				}

				// if all the real sections were assigned, clicking "all sections" means to unassign all the sections (and all the students)
				if (all_assigned) {
					this.recipients = []

					// else clicking "all sections" means to assign all the sections (and by extension all the students)
				} else {
					this.recipients = []
					for (let opt of this.recipient_options) {
						if (opt.type == "section") {
							this.recipients.push(opt.value)
						}
					}
					// in this case close the menu
					close_menu = true
				}

				// else if the user clicked on a section, make sure that all students in assigned sections are *not* in assigned_to
			} else if (this.last_clicked_recipient_option?.type === "section") {
				for (let opt of this.recipient_options) {
					if (opt.type == "section" && this.recipients.includes(opt.value)) {
						for (let sopt of this.recipient_options) {
							if (
								sopt.type == "student" &&
								sopt.recipient_sections.length > 0
							) {
								let index = this.recipients.indexOf(sopt.value)
								if (index > -1) {
									this.recipients.splice(index, 1)
								}
							}
						}
					}
				}

				// else the last-clicked option was a student
			} else {
				if (
					this.last_clicked_recipient_option?.recipient_sections?.length > 0
				) {
					// if the user *unchecked* a student that was already part of an assigned section
					// take the student back out of assigned_to; then ask the user to confirm
					let index = this.recipients.findIndex(
						(x) => x == this.last_clicked_recipient_option.value
					)
					this.recipients.splice(index, 1)

					this.$confirm({
						text: sr(
							"The student you unchecked ($1) is currently marked as a message recipient by virtue of being a member of one or more assigned sections ($2). If you “unassign” the message to this student, we will remove the section-level message and replace it with individual messages to all students in the section <i>except</i> this student.<br><br>Is this would you would like to do?",
							this.last_clicked_recipient_option.text,
							this.last_clicked_recipient_option.recipient_sections.join(", ")
						),
						acceptText: "Unassign message for this student",
						cancelText:
							"Keep section-level message assignment",
						dialogMaxWidth: 800,
					})
						.then((y) => {
							// user has confirmed to remove the section assignment(s) and assign other students instead, so go through all this student's assigned sections...
							// (we have to first make a copy of assigned_to_options as it exists now, because every time we edit assigned_to below, it'll change)
							let oato = this.recipient_options.concat([])
							for (let section_title of this.last_clicked_recipient_option
								.recipient_sections) {
								// remove the section from assigned_to
								let sopt = oato.find((x) => x.section_title == section_title)
								let index = this.recipients.findIndex((x) => x == sopt.value)
								if (index > -1) {
									this.recipients.splice(index, 1)
									// then add all other students from this section to assigned_to (if they're not already there)
									for (let oopt of oato) {
										if (
											oopt.type == "student" &&
											oopt.sourcedId !=
											this.last_clicked_recipient_option.sourcedId &&
											oopt.recipient_sections.includes(section_title)
										) {
											let index =
												oopt.recipient_sections.indexOf(section_title)
											oopt.recipient_sections.splice(index, 1)
											// exception: if the student is still in *another* assigned section, we don't need to do this
											if (oopt.recipient_sections.length == 0) {
												this.recipients.push(oopt.value)
											}
										}
									}
								}
							}
							this.last_clicked_recipient_option = null
						})
						.catch((n) => {
							console.log(n)
						})
						.finally((f) => { })
					return
				}
			}
			this.last_clicked_recipient_option = null
			if (close_menu) {
				// blur the autocomplete to close the menu
				this.$refs.recipient_autocomplete.blur()
			}
		},
		save_edits() {
			let message_recipients = []
			if (this.is_reply) {
				message_recipients = [...this.message.recipients]
			} else if (this.is_student_generated) {
				for (let recipient of this.message.recipients) {
					const message_recipient = new Recipient({
						user_sourcedId: recipient,
					})
					message_recipients.push(message_recipient)
				}
			} else {
				for (let recipient of this.recipient_options) {
					if (recipient.type !== 'student') continue
					if (!recipient.recipient) continue
					const message_recipient = new Recipient({
						user_sourcedId: recipient.value.user_sourcedId,
						class_sourcedId: recipient.value.class_sourcedId
					})
					message_recipients.push(message_recipient)
				}
			}
			this.$store.commit("set", [this.message, "subject", this.subject])
			this.$store.commit("set", [this.message, "body", this.body])
			this.$store.commit("set", [this.message, "message_id", 0])
			this.$store.commit("set", [this.message, "author_user_id", this.message.author_user_id])
			this.$store.commit("set", [this.message, "course_code", this.message.course_code])
			this.$store.commit("set", [this.message, "parent_message_id", this.message.parent_message_id])
			this.$store.commit("set", [this.message, "message_level", this.message.message_level])
			this.$store.commit("set", [this.message, "recipients", message_recipients])
			this.$store.commit("set", [this.message, "first_name", this.message.first_name])
			this.$store.commit("set", [this.message, "last_name", this.message.last_name])
			this.$store.commit("set", [this.message, "send_to", this.send_to])
			this.$store.commit("set", [this.message, "send_at", this.formatted_send_at])
			this.$store.commit("set", [this.message, "activity_id", this.activity_id])
			this.$store.dispatch("save_message", this.message).then(() => {
				this.$emit("editor_cancel")
			})
			// this.$emit("editor_cancel")
		},
		recipient_option_filter(item, query_text) {
			query_text = query_text.toLowerCase()
			return item.text.toLowerCase().indexOf(query_text) > -1
		},
	},
}
</script>

<style lang="scss">
.k-message-editor {
	border-top: 1px solid $secondary-color;
	padding: 10px;
	background-color: #cac9d7;

	&-reply-to {
		flex: 1 1 auto;
		font-size: 14px;
		color: #727272;

		&--caption {
			font-weight: bold;
		}
	}
}

.k-message-editor-recipient-display {
	flex: 1 1 auto;
	border: 1px solid #9a9a9a;
	padding: 8px;
	background-color: #f8f8f8;
	border-radius: 4px;
	font-size: 14px;
}
</style>
