<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
<v-app v-show="app_mode!='uninitialized'" :class="app_class">
	<img id="k-bgd-img" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="">
	<v-main>
		<LoginView ref="login_view" v-if="app_mode=='login'"/>
		<MainView ref="main_view" v-if="app_mode=='main'"/>
		<ResourceLinkView ref="reslink_view" v-if="app_mode=='reslink'"/>
		<EditFile v-if="editing_filename" :filename="editing_filename" @cancel="editing_filename=''" />
		<IssueReport v-if="issue_params" :resource="issue_params.resource" :learning_progression="issue_params.learning_progression" :lp_unit="issue_params.lp_unit" @dialog_cancel="issue_params=null" />
		<SatchelInline ref="satchel" />
		<SparklEmbedControlBar ref="sparkl_embed_control_bar" />
	</v-main>
</v-app>
</template>

<script>
import LoginView from './components/login/LoginView'
import MainView from './components/main/MainView'
import ResourceLinkView from './components/resources/ResourceLinkView'
import EditFile from './components/resources/EditFile'
import IssueReport from './components/resources/IssueReport'
import { mapState, mapGetters } from 'vuex'
import ResourceCountMixin from './js/ResourceCountMixin.js'
import SatchelInline from './components/utilities/SatchelInline'
import SparklEmbedControlBar from './components/lessons/SparklEmbedControlBar'

import ela_subject_img from './images/subject-images/ela.jpg'
import math_subject_img from './images/subject-images/math.jpg'
import science_subject_img from './images/subject-images/science.jpg'
import social_studies_subject_img from './images/subject-images/social-studies.jpg'

import pe_subject_img from './images/subject-images/pe.jpg'
import health_subject_img from './images/subject-images/health.jpg'
import compsci_subject_img from './images/subject-images/compsci.jpg'
import languages_subject_img from './images/subject-images/languages.jpg'

import dance_subject_img from './images/subject-images/dance.jpg'
import theatre_subject_img from './images/subject-images/theatre.jpg'
import music_subject_img from './images/subject-images/music.jpg'
import visual_arts_subject_img from './images/subject-images/visual-arts.jpg'
// import media_arts_subject_img from './images/subject-images/media-arts.jpg'

import ctae_subject_img from './images/subject-images/ctae.jpg'
import training_subject_img from './images/subject-images/training.jpg'
import misc_subject_img from './images/subject-images/misc.jpg'

export default {
	name: 'App',
	mixins: [ResourceCountMixin],
	components: { LoginView, MainView, ResourceLinkView, EditFile, IssueReport, SatchelInline, SparklEmbedControlBar },
	data() { return {
		app_mode: 'uninitialized',
		now: 0,
		yesterday: 0,
		reslink_resource_id: '',
		issue_params: null,

		editing_filename: '',

		ping_timeout: null,
		ping_timeout_time: 60 * 1000 * 10,	// every 10 minutes

		// borrow subject images from inspire
		subject_img: {
			'Language Arts': ela_subject_img,
			'Mathematics': math_subject_img,
			'Mathematics 2023': math_subject_img,
			'Science': science_subject_img,
			'Social Studies': social_studies_subject_img,
			'Physical Education': pe_subject_img,
			'Health': health_subject_img,
			'Computer Science': compsci_subject_img,
			'World Languages': languages_subject_img,
			'English Language Development': misc_subject_img,
			'Fine Arts: Dance': dance_subject_img,
			'Fine Arts: Dramatic Arts/Theatre': theatre_subject_img,
			'Fine Arts: Music': music_subject_img,
			'Fine Arts: Visual Art': visual_arts_subject_img,
			'Fine Arts': visual_arts_subject_img,
			'CTAE': ctae_subject_img,
			'other': training_subject_img,

			'misc': misc_subject_img,
		},
	}},
	computed: {
		...mapState(['user_info', 'welcome_section_showing', 'my_classes_view', 'lp_showing', 'single_item']),
		...mapGetters(['role', 'beta_options']),
		app_class() {
			// add a CSS class at top level of application to allow for beta styles
			// TODO: we should really just fold these back into the "normal" styles at this point, since we always use them
			return 'k-beta-2022-04'
		}
	},
	watch:{
		welcome_section_showing() {
			if (empty(this.welcome_section_showing)) return
			U.local_storage_set('district_portal_welcome_section_showing', this.welcome_section_showing)
		},
		// my_classes_view() {
		// 	if (empty(this.my_classes_view)) return
		// 	U.local_storage_set('district_portal_my_classes_view', this.my_classes_view)
		// },
		'$route.fullPath': {
			handler() {
				// Use this watcher to keep track of LP's the user has viewed; this allows us to control edit locking and also refresh any LP that gets updated after being initially viewed
				let course_code_showing = 0
				if (this.$route.fullPath.search(/(lp|pd)\/(\d+)\b/) > -1) {
					course_code_showing = RegExp.$2
				}

				// if lp_showing hasn't changed, we don't have to do anything
				if (course_code_showing === this.lp_showing) return

				// note: if course_code_showing is now 0 and lp_showing is > 0, it means we've left a course.  we could do something here when this happens...

				// else set lp_showing
				this.$store.commit('set', ['lp_showing', course_code_showing])

				// if we're viewing a course and app_mode is main, meaning that the sign-in process is complete, send msg saying that we're viewing the LP.
				// (if sign-in isn't yet complete, we'll send the message after initialization is done; see below)
				if (course_code_showing > 0 && this.app_mode == 'main') {
					this.lp_view_open_socketmsg()
				}

			}, deep:true, immediate:true
		},
		app_mode() {
			// update style of google translate button when in login mode
			if (this.app_mode == 'login') {
				$('#google_translate_element_wrapper').css({
					'background-color': '#999',
					'padding': '4px',
					'border': '1px solid #999',
					'opacity': '1',
				})

			} else {
				$('#google_translate_element_wrapper').css({
					'background-color': 'transparent',
					'padding': '0',
					'border': '0',
					'opacity': '',
				})
			}
		},
	},
	created() {
		window.vapp = this

		// determine right away if we're viewing a single item
		if (window.location.pathname.search(/\/(activity|lesson|resource)\/(\d+)/) > -1) {
			// stores, e.g., ['activity', 98]
			this.$store.commit('set', ['single_item', [RegExp.$1, RegExp.$2]])
		}

		// deal with default settings
		// 8/26/2022 -- now we always start with 'assignments', and don't remember this from session-to-session
		// this.$store.commit('set', ['my_classes_view', U.local_storage_get('district_portal_my_classes_view', 'assignments')])
	},
	mounted() {
		this.show_bgd_image()
		this.initialize_app({})

		// set an interval to kill the spakl_embed_maximized flag just in case it gets stuck
		setInterval(()=>{
			if ($('.k-sparkl-maximized').length == 0 && this.$store.state.activity_embed_maximized) {
				this.$store.commit('set', ['activity_embed_maximized', false])
				$('html').css('overflow', '')
			}
		}, 1000)

	},
	methods: {

		initialize_app(payload) {

			// saml login data may come from remote server, replaces $ad_result data for initiaalize_app
			let params = (new URL(document.location)).searchParams
			
			if (params.get('saml_attributes') != undefined) {
				//console.log('--- saml attributes for initialize_app')
				//console.log(params.get('saml_attributes'))

				payload.saml_login_claims = params.get('saml_attributes')
				payload.use_saml_login = true

				// clear saml login params
				U.clear_location_search()
			}

			U.loading_start()
			this.$store.dispatch('initialize_app', payload).then((mode)=>{
				U.loading_stop()
				// after a tick for everything to get set up, set app_mode to show the app
				this.$nextTick(()=>{
					// update now and yesterday every X seconds
					this.update_now()
					setInterval(()=>{
						// console.log('update now')
						this.update_now()
					}, 10000)

					$('body').removeClass('k-body-uninitialized')

					if (mode == 'login') {
						this.app_mode = mode

					// resource link pass-through (we should probably move this to an app with a smaller footprint)
					} else if (document.location.pathname.search(/\/reslink\/(.*)/) == 0) {
						// store resource_id in this.reslink for the reslink component to access
						this.reslink_resource_id = RegExp.$1
						this.app_mode = 'reslink'

					} else {
						// welcome_section_showing: we have to be careful with this because the user can switch between roles...
						let welcome_section_showing = U.local_storage_get('district_portal_welcome_section_showing', '')
						if (this.role == 'parent') {
							// have parents always start in family_home mode (at least for now)
							welcome_section_showing = 'family_home'
						} else if (!welcome_section_showing) {
							// by default go to classes for everyone else
							welcome_section_showing = 'classes'
						} else if (welcome_section_showing == 'family_home') {
							// if stored value is family_home but we're no longer in the parent role, go to classes
							if (this.role != 'parent') welcome_section_showing = 'classes'
						} else if (welcome_section_showing == 'admin') {
							// if stored value is admin but we're no longer in the admin role, go to classes
							if (this.role != 'admin') welcome_section_showing = 'classes'
						}
						this.$store.commit('set', ['welcome_section_showing', welcome_section_showing])

						// for now, always call get_classes as soon as we log in; if we're viewing a single item, it will load only the single item
						// in the future, when we get rid of the "bridge app", we will want to not call this at all if we're just loading a resource.
						if (true || !this.single_item) {
							this.$store.dispatch('get_classes').then((result)=>{
								// the get_classes service may auto-join the user in one or more communities; if so, inform them
								if (!empty(result, 'communities_joined') && result.communities_joined.length > 0) {
									this.$alert(sr('You have been automatically added to the following community(s): <b>$1</b>', result.communities_joined.join(', ')))
								}
								this.$nextTick(()=>this.app_mode = mode)
							}) 
						} else {
							this.$nextTick(()=>this.app_mode = mode)
						}

						// start pinging to make sure we're signed in
						this.ping()

						// open the HenryChatter websocket connection; once open it will send the sign_in message
						// PW 7/28/2021: temporarily (at least) not openening the socket when the application first launches; we only open when someone tries to edit
						// this.$store.dispatch('socket_open')

						// if we're starting out viewing an LP, send view_open message now
						if (this.lp_showing > 0) {
							this.lp_view_open_socketmsg()
						}
					}
				})
			})
		},

		lp_view_open_socketmsg() {
			// PW 7/28/2021: disabling this, at least for now, since we're not keeping the socket open at all times

			// console.log('lp_view_open_msg')
			// // send status/score update to other students via socket
			// this.$store.dispatch('socket_send', {
			// 	type: 'lp_view_open',
			// 	data: {
			// 		user_email: this.user_info.email
			// 	}
			// })
		},

		update_now() {
			this.now = Math.round(this.$store.state.now_date_obj.getTime() / 1000)
			// this.now = Math.round(new Date().getTime() / 1000)
			this.yesterday = this.now - (24*60*60)
		},

		has_admin_right(right) {
			// central fn for determining if the user has the given admin right

			// if user is viewing in parent or student role, you can't do anything admin-related
			if (this.role == 'student' || this.role == 'parent') return false

			let ar = this.user_info.admin_rights

			// su users have rights to everything
			if (ar.find(x=>x=='su')) return true

			// for lp.x.x, 'lp.level.all' gives rights to edit any LP
			if (right.indexOf('lp') == 0 && ar.find(x=>x=='lp.level.all')) return true

			// for com.mod.x, 'com.mod.all' gives rights to moderate any community_id
			if (right.indexOf('com.mod') == 0 && ar.find(x=>x=='com.mod.all')) return true

			// for pd_rep (pd reports) rights...
			if (right.indexOf('pd_rep') == 0) {
				// 'pd_rep.all' gives rights to view reports for any pd division/school
				if (ar.find(x=>x=='pd_rep.all')) return true

				// principals/assistant principals can see reports for their school(s)
				if (this.$store.getters.user_is_principal_or_ap) {
					// console.log('is principal!')
					if (right == 'pd_rep.any') return true
					// check for a particular school, which will use the guid
					let guid = right.substr(7)
					if (U.is_uuid(guid)) {
						let school = this.$store.state.todo_user_group_schools[guid]
						if (school && this.user_info.district_department.includes(school)) {
							// console.log('found school ' + school)
							return true
						}
					}
				}

				// if 'pd_rep.any' was sent in, return whether the user has rights to view reports for *any* divisions/schools
				if (right == 'pd_rep.any' && ar.find(x=>x.indexOf('pd_rep.') == 0)) return true
			}

			if (ar.find(x=>x==right)) return true

			return false
		},

		// determine if the user has rights to admin an lp; used in a few different places
		is_lp_admin(lp) {
			// note that this logic is repeated in Community.vue
			if (this.initialized < 0) return

			if (vapp.has_admin_right('lp.course.' + lp.course_code)) return true

			// check based on subject
			if (vapp.has_admin_right('lp.subject.' + lp.subject)) return true

			// check based on level
			if (U.grades_include(lp, 'K') && vapp.has_admin_right('lp.level.Elementary')) return true
			if (U.grades_include(lp, '1') && vapp.has_admin_right('lp.level.Elementary')) return true
			if (U.grades_include(lp, '2') && vapp.has_admin_right('lp.level.Elementary')) return true
			if (U.grades_include(lp, '3') && vapp.has_admin_right('lp.level.Elementary')) return true
			if (U.grades_include(lp, '4') && vapp.has_admin_right('lp.level.Elementary')) return true
			if (U.grades_include(lp, '5') && vapp.has_admin_right('lp.level.Elementary')) return true
			if (U.grades_include(lp, '6') && vapp.has_admin_right('lp.level.Middle School')) return true
			if (U.grades_include(lp, '7') && vapp.has_admin_right('lp.level.Middle School')) return true
			if (U.grades_include(lp, '8') && vapp.has_admin_right('lp.level.Middle School')) return true
			if (U.grades_include(lp, '9') && vapp.has_admin_right('lp.level.High School')) return true
			if (U.grades_include(lp, '10') && vapp.has_admin_right('lp.level.High School')) return true
			if (U.grades_include(lp, '11') && vapp.has_admin_right('lp.level.High School')) return true
			if (U.grades_include(lp, '12') && vapp.has_admin_right('lp.level.High School')) return true

			// if we get to here, not an admin
			return false
		},

		// some utilities
		color_from_number(n) {
			n = n * 1
			if (isNaN(n)) return 'k-list-color-0'
			n = (n % 16) + 1
			return 'k-list-color-' + n
		},

		color_from_string(s) {
			if (empty(s)) return 'k-list-color-0'
			s = s + ''
			let n = 2
			for (let i = 0; i < s.length; ++i) {
				n += s.charCodeAt(i)
			}
			return this.color_from_number(n)
		},

		open_resource_link(resource, el) {
			// if we're in the context of a froala editor, `resource` will be the resource_id, and we will have received the dom object for the link in the editor
			if (el) {
				// send a message to the editor's parent_component to show the ResourceEditor dialog
				let fco = U.get_froala_component(el)
				if (fco.parent_component) {
					let froala_resource_link_id = $(el).attr('data-resource-link-id')
					let resource_description = $(el).attr('title')
					fco.parent_component.froala_inserted_resource_clicked(fco, resource, resource_description, froala_resource_link_id)
					return
				}
			}

			// if we received a resource object, we may be able to open without pinging the server
			if (typeof(resource) == 'object') {
				if (resource.has_openable_url) {
					window.open(resource.full_url(), '_blank')
					return
				}

				// else set resource to the resource_id and fall through below to ping the server for the lti form or to find out what to do
				resource = resource.resource_id
			}

			let payload = {resource_id: resource, get_lti_form: 'yes'}
			U.loading_start()
			this.$store.dispatch('get_resource_record', payload).then(result=>{
				U.loading_stop()
				// console.log(result)

				// if we got back an lti_form, launch
				if (!empty(result.lti_form)) {
					// for the lti_form we open a new window and write out the form, which submits itself
					// see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
					let w = window.open()
					w.document.write(result.lti_form)
				} else {
					// else we just open the new window to the resource's url
					resource = new Resource(result.resource_record)
					window.open(resource.full_url(), '_blank')
				}
			})
		},

		show_bgd_image(index) {
			let filenames = [
				'aCIS_2115_LG.jpg',
				'aCIS_5433.jpg',
				'aCIS_6815.jpg',
				'bCIS_0042.jpg',
				'bCIS_4644.jpg',
				'bCIS_8072.jpg',
				'bCIS_8389.jpg',
				'cCIS_2639.jpg',
				'cCIS_2662a.jpg',
				'cCIS_3342.jpg',
			]

			// method for remembering background image
			// if (empty(index)) {
			// 	index = U.local_storage_get('district_portal_bgd_image_index', 0)
			// }
			//
			// if (index == -1) {
			// 	while (index == -1 || index == U.local_storage_get('district_portal_bgd_image_index', 0)) {
			// 		index = U.random_int(filenames.length)
			// 	}
			// }
			//
			// U.local_storage_set('district_portal_bgd_image_index', index)

			if (empty(index)) {
				index = U.random_int(filenames.length)
			}

			// this is another way of setting the bgd image.
			// it's easier, but with this if the window is bigger than the image, you get a gray bar on the sides or top
			// $('html').css('background', sr("url('/bgd-imgs/$1') no-repeat center center fixed", filenames[index]))

			let url = sr('/bgd-imgs/$1', filenames[index])

			// Set the new image
			$("#k-bgd-img").attr('src', url);

			// set resize event if not already set
			if (empty(this.resize_bgd_image_evt)) {
				this.resize_bgd_image_evt = $(window).on('resize', ()=>{ this.resize_bgd_image() })
			}

			// call resize_bgd_image every 100 ms for 15 seconds, to make it resizes properly after the image loads
			this.resize_bgd_image_counter = 0
			clearInterval(this.resize_bgd_image_interval)
			this.resize_bgd_image_interval = setInterval(()=>{
				this.resize_bgd_image()
				if (this.resize_bgd_image_counter > 150) clearInterval(this.resize_bgd_image_interval)
				++this.resize_bgd_image_counter
			}, 100)
		},

		change_academic_year() {
			// academic year options are specified in config.php and passed in initialize_app
			let options = []
			for (let ay of this.$store.state.available_academic_years) {
				options.push({ value:ay+'', text:((ay) + '-' + (ay*1+1)) })
			}

			this.$prompt({
				title: 'Academic Year',
				text: '<div class="mb-1">Select the academic year for which you’d like to view Learning Progressions.</div>',
				promptType: 'select',		// default is 'text'
				selectOptions: options,
				initialValue: this.user_info.academic_year,
				acceptText: 'Select',
			}).then(year => {
				// dispatch change_academic_year, which will change the session data and reload
				this.$store.dispatch('change_academic_year', year)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		resize_bgd_image() {
			let $bg = $("#k-bgd-img")
			let bg_w = $bg.width()
			let bg_h = $bg.height()
			let win_w = $(window).width()
			let win_h = $(window).height()

			// console.log(sr('$1 / $2', bg_w, bg_h))

			// Determine whether width or height should be 100%; shift image left or up to compensate for difference in img/window width/height
			let left, top
			if ((win_w / win_h) < (bg_w / bg_h)) {
				left = '-' + Math.round((bg_w - win_w) / 2) + 'px'
				top = '0'
				$bg.css({height: '100%', width: 'auto', left:left, top:top});
			} else {
				left = '0'
				top = '-' + Math.round((bg_h - win_h) / 2) + 'px'
				$bg.css({width: '100%', height: 'auto', left:left, top:top});
			}
		},

		// call this fn to go to/return to the "home" url (/welcome);
		// if new_section parameter is empty you'll be sent to that section (family_home, classes, communities, or pd);
		// otherwise you'll go back to the last-visited section
		go_to_home(new_section) {
			// set welcome_section_showing to the part we want to move to
			if (new_section && typeof(new_section) == 'string') {
				this.$store.commit('set', ['welcome_section_showing', new_section])
			}

			// then go to the welcome path
			this.$router.push({ path: '/welcome' })
		},

		edit_file(filename) {
			this.editing_filename = filename
		},

		toggle_google_translate_btn(fn) {	// 'show' or 'hide'
			$('#google_translate_element_wrapper')[fn]()
		},

		// central place to show the issue reporting interface; components should call vapp.report_issue(issue_params)
		report_issue(issue_params) {
			console.log('report_issue', issue_params)
			this.issue_params = issue_params
		},

		// determine if the user's session is still active; if not, show a message and call the sign out process
		ping() {
			// we want to automatically call this fn every ping_timeout_time ms. if it's manually called by something else, we reset the timeout
			clearTimeout(this.ping_timeout)

			let sign_user_out = () => {
				let msg = 'You are not signed in. You may have been automatically signed out due to inactivity.'
				this.$alert({
				    text: msg,
				}).finally(f=>{
					this.$store.dispatch('sign_out')
				})
			}

			// use plain-vanilla XMLHttpRequest to call the ping service
			var xhr = new XMLHttpRequest()
			xhr.onreadystatechange = function() {
			    if (xhr.readyState === 4) {		// fetch operation is done
					if (xhr.responseText == 'ok') {
						console.log('ping OK')
						// set the timeout to re-call this fn after ping_timeout_time
						vapp.ping_timeout = setTimeout(()=>{ vapp.ping() }, vapp.ping_timeout_time)

					} else {
						sign_user_out()
					}
				}
			}
			xhr.open('GET', '/src/ping.php')
			xhr.send()
		},

	}
}
</script>

<style lang="scss">
html {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

#k-bgd-img {
	position: fixed;
	z-index:-1;
	left: 0;
	top: 0;
}

body {
	font-size:18px;
}

.k-body-uninitialized {
	background-color:#fff;
	.spinner-wrapper {
		display:none;
	}
}

.v-application {
	font-family: $sans-serif-font;
	background-color:transparent!important;
}

.k-shadow-text {
	color:#fff;
	text-shadow: 2px 2px 8px #000;
}

// Google translate functionality:
// fix the translate menu in the window
#google_translate_element_wrapper { 
	text-align:center;
	z-index:10000000;

	// position:fixed; 
	// top:15px; 
	// left: calc(50vw - 90px);
	left:8px;
	min-width:180px;
	position:absolute;
	bottom:9px;
	border-radius:8px;
	opacity:0.4;

	.goog-te-gadget-simple {
		border-radius:4px;
	}
}

#google_translate_element_wrapper:hover {
	opacity:1;
}

// When another language is selected, Google tries to show a bar at the top of the page; hide it
.skiptranslate iframe.skiptranslate {
	display: none !important;
} 
body {
	// google tries to set the top property of body; this has to be overridden
	top: 0px !important; 
}

</style>
