<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-page-wrapper k-community-view-wrapper" :class="top_css_class">
	<h2 class="k-page-title d-flex align-center mb-8">
		<v-icon color="primary" class="mr-3">fas fa-users</v-icon>
		<b><span v-html="community.title"></span></b>
		<v-spacer/>
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="community.type=='lp'" color="secondary" fab small @click="go_to_course"><v-icon>fas fa-chalkboard</v-icon></v-btn></template>Go to Course</v-tooltip>
		<v-menu bottom right><template v-slot:activator="{on}"><v-btn v-on="on" v-show="is_community_admin||community.type!='lp'" small class="ml-3" fab color="#999" dark><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template>
			<v-list>
				<v-list-item v-if="community.type!='lp'" @click="remove_yourself_from_community"><v-list-item-icon><v-icon small>fas fa-sign-out-alt</v-icon></v-list-item-icon><v-list-item-title>Remove Yourself From This Community</v-list-item-title></v-list-item>
				<v-list-item v-if="is_community_admin" @click="edit_community"><v-list-item-icon><v-icon>fas fa-tools</v-icon></v-list-item-icon><v-list-item-title>Edit Community Title, Description, Image, etc.</v-list-item-title></v-list-item>
				<v-list-item v-if="is_community_admin" @click="manage_community_members"><v-list-item-icon><v-icon>fas fa-tools</v-icon></v-list-item-icon><v-list-item-title>Manage Community Memberships</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</h2>
	<div v-if="community.description!=''" class="k-community-description k-elevated" :class="description_css_class">
		<div class="fr-view" v-html="community.description"></div>
		<v-spacer/>
		<img v-if="community.image!=''" class="k-community-description-img" :src="community.image">
	</div>

	<div v-if="community.role=='pending'" class="k-community-pending-message k-elevated">
		Your request to join this community is pending.
	</div>

	<div v-if="community.role!='pending'">
		<div v-if="initialized" class="k-community-forums-wrapper">
			<Forum v-for="(forum) in forums" :key="forum.forum_id" :forum="forum" :community="community" :community_lp="community_lp" :is_community_admin="is_community_admin"></Forum>
		</div>
		<div v-if="is_community_admin&&community.type!='lp'" class="k-forum-new-forum-btn-wrapper"><v-btn color="#666" dark @click="add_forum"><v-icon class="mr-2">fas fa-plus</v-icon>Add A New Forum</v-btn></div>

		<CommunityEdit v-if="show_community_editor" :community="community" @editor_cancel="show_community_editor=false"></CommunityEdit>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CommunityEdit from './CommunityEdit'
import Forum from './Forum'

export default {
	components: { CommunityEdit, Forum },
	props: {
		community_id: { type: String, required: true },
	},
	data() { return {
		initialized: false,
		show_community_editor: false,
	}},
	computed: {
		...mapState(['user_info']),
		community() {
			let c = this.$store.state.communities.find(o=>o.community_id == this.community_id)
			if (empty(c)) {
				return {}
			}
			return c
		},
		community_lp() {
			// for lp communities, get the lp associated with the community
			if (this.community.type != 'lp') return {}
			// extract the lp_id from the fake community_id (see store.js)
			let lp_id = this.community.community_id - 100000
			let lp = this.$store.state.all_courses.find(x=>x.lp_id==lp_id)
			return (lp) ? lp : {}
		},
		forums() {
			// for lps, we only have one "fake" forum; create it here if necessary
			if (this.community.type == 'lp') {
				let f = this.$store.state.forums.find(x=>x.course_code == this.community_lp.course_code)

				// if not found create one
				if (!f) {
					f = new window.Forum({
						forum_id: 0,
						community_id: 0,	// there is a special community set up for LP forums, with community_id 0
						title: sr('Community: $1', this.community_lp.title),
						type: 'lp',
						course_code: this.community_lp.course_code
					})
					this.$store.commit('set', [this.$store.state.forums, 'PUSH', f])
				}

				return [f]
			}

			// else get forums that match this community_id
			return this.$store.state.forums.filter((forum) => {
				if (forum.community_id == this.community_id) {
					// skip 'Announcements' forum for now
					return forum.type != 'announcements'
				}
			}).sort((a, b)=>{
				// show resources forum last
				if (a.type == 'resources') return 1
				if (b.type == 'resources') return -1
				return 0
			})
		},
		is_community_admin() {
			// for lp communities, you're a community admin if you're an admin for the course
			if (this.community.type == 'lp') {
				if (!this.initialized || !this.community_lp.course_code) return false
				return vapp.is_lp_admin(this.community_lp)
			}

			// else check com.mod
			return vapp.has_admin_right('com.mod.' + this.community_id)
		},
		community_showing() {
			if (this.$route.fullPath.search(/communities\/(\d+)\b/) > -1) {
				return RegExp.$1 == this.community_id
			}
			return false
		},
		fullPath() {
			return this.$route.fullPath
		},
		top_css_class() {
			return vapp.color_from_string(this.community.title) + '-border'
		},
		description_css_class() {
			return vapp.color_from_string(this.community.title) + '-border'
		},
	},
	watch: {
		'$route.fullPath': {
			handler() { this.initialize() }, deep:true, immediate:true
		},
		community_showing() {
			if (this.community_showing && !this.initialized) {
				this.initialize()
			}
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		initialize() {
			if (!this.community_showing || this.initialized) return

			// console.log(sr('community $1 initialization fn', JSON.stringify(this.community)))
			// when loading a LP community, sometimes we won't have the dummy community record set up yet when we get to here, so try again in 100 ms
			if (empty(this.community.title)) {
				setTimeout(x=>this.initialize(), 100)
				return
			}

			// if user's role is pending, set initialized to true to show them the community description and pending message
			if (this.community.role == 'pending') {
				this.initialized = true

			// else user is allowed to view, so get the data for the community
			} else if (!this.initialized) {
				this.get_data_for_community()
			}
		},

		get_data_for_community() {
			let payload

			// for lp communities...
			if (this.community.type == 'lp') {
				// we send an array of forum_id strings based on the course_code and the course's subject/grade band, created in the lp constructor
				payload = { community_id: 0, forum_ids: this.community_lp.forum_ids }

			} else {
				// this isn't an lp community, so get all posts from the given community_id
				payload = { community_id: this.community_id }
			}

			this.$store.dispatch('get_data_for_community', payload).then(()=>{
				this.$nextTick(()=>{
					this.initialized = true
				})
			})
		},

		remove_yourself_from_community() {
			// TODO: if this is a restricted community and you're not a system admin, warn before removing yourself
			this.$confirm({
				title: 'Are you sure?',
			    text: 'Are you sure you want to remove yourself from this community?',
			    acceptText: 'Remove From Community',
				acceptColor: 'red',
			}).then(y => {
				this.$store.dispatch('remove_from_community', {community_id: this.community_id, user_id_to_remove: this.user_info.user_id})
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		edit_community() {
			this.show_community_editor = true
		},

		manage_community_members() {
			// TODO: implement this
			this.$alert('This will show an interface allowing the admin to manage community members.')
		},

		add_forum() {
			this.$prompt({
				title: 'New Community Forum',
				text: 'Enter a title for the new Community Forum:',
				initialValue: '',
				acceptText: 'Create Forum',
				cancelText: 'Cancel',
			}).then(title => {
				if (!empty(title)) {
					let new_forum = new window.Forum({
						forum_id: 0,
						community_id: this.community_id,
						type: 'default',
						title: title
					})
					this.$store.commit('set', [this.$store.state.forums, 'PUSH', new_forum])
					this.$store.dispatch('save_forum', new_forum)
				}
			}).catch(n=>{
			}).finally(f=>{});
		},

		return_to_communities_list() {
			this.$router.push({ path: '/welcome' })
		},

		go_to_course() {
			this.$router.push({ path: sr('/courses/lp/$1/$2/$3', this.community_lp.course_code, 0, 0) })
		},
	}
}
</script>

<style lang="scss">
.k-community-view-wrapper {
	border-top:5px solid transparent;
	border-bottom:5px solid transparent;
}

.k-community-forums-wrapper {
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
	align-items:flex-start;
	margin:10px;
}

.k-community-description {
	clear:both;
	padding:10px;
	margin:-10px 50px 10px 50px;
	font-size:15px;
	background-color:#fff;
	border-radius:8px;
	display:flex;
	border:3px solid transparent;
}

.k-community-description-img {
	flex:0 0 auto;
	margin-left:10px;
	max-width:30%;
	max-height:180px;
	border-radius:10px;
}


.k-forum-new-forum-btn-wrapper {
	text-align:center;
	margin-bottom:15px;
	margin-top:35px;
}

.k-community-pending-message {
	border:6px dashed #ccc;
	border-radius:25px;
	background-color:$secondary-color;
	padding:20px;
	margin:20px 50px;
	font-style:italic;
	font-weight:bold;
	text-align:center;
}

</style>
