<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="mt-8">
	<PDIndex />
	<router-view></router-view>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import PDIndex from './PDIndex'

export default {
	components: { PDIndex, },
	data() { return {
	}},
	computed: {
		...mapState([]),
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
.k-main-collections {
	position:relative;
}
</style>
