<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
<div v-if="filtered_origin_teacher_id" class="k-course-view-wrapper-origin-teacher-header">Viewing Lessons and Activities for <span style="font-weight:900">{{filtered_coteacher_course.origin_teacher_first_name}} {{filtered_coteacher_course.origin_teacher_last_name}}</span> <v-btn class="ml-2 k-tight-btn" x-small color="#fff" @click="filter_coteaching_list(null)"><v-icon small class="mr-1">fas fa-xmark</v-icon>Cancel</v-btn></div>
<div class="k-course-view-wrapper k-page-wrapper" :class="top_css_class">

	<h2 class="k-page-title d-flex align-center">
		<v-icon color="primary" class="mr-3">fas fa-chalkboard</v-icon>
		<span v-if="initialized==0">LOADING…</span>
		<b v-else v-html="lp.title"></b>
		<b v-if="initialized&&lp.active=='no'&&is_lp_admin" class="ml-8 red--text">[Inactive]</b>

		<v-spacer/>

		<div v-if="studentish_role&&teacher_display" class="mr-3 text-center k-course-view-academic-year" style="font-size:12px;" v-html="teacher_display"></div>

		<div v-if="!condensed_controls" class="k-course-view-academic-year" :style="can_change_academic_year?'cursor:pointer':''" @click="change_academic_year">Academic Year<br>{{academic_year_display}}</div>

		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="!course_addable&&user_can_view_teacher_resources&&!condensed_controls" color="secondary" fab small class="mr-3" @click="go_to_community"><v-icon small>fas fa-users</v-icon></v-btn></template>Go to Course Community</v-tooltip>

		<div v-if="!condensed_controls&&block_mode_switch_available" class="k-course-view-term-mode-selector mr-3"><v-btn-toggle dense active-class="k-toggle-btn-active-class" v-model="term_mode" mandatory>
			<v-btn small light value="normal">Quarters</v-btn>
			<v-btn small light value="block">Blocks</v-btn>
		</v-btn-toggle></div>

		<v-menu bottom right>
			<template v-slot:activator="{ on }">
				<v-btn class="mr-3" v-on="on" v-if="user_is_teacher&&manage_assignments" small fab :color="filtered_origin_teacher_id?'pink accent-4':'pink accent-4'" dark><v-icon>fas fa-person-chalkboard</v-icon></v-btn>
			</template>
			<v-list dense>
				<v-list-item v-if="user_is_teacher" @click="manage_coteachers">
					<v-list-item-icon><v-icon small>fas fa-person-chalkboard</v-icon></v-list-item-icon>
					<v-list-item-title>Manage my co-teachers for this course</v-list-item-title>
				</v-list-item>

				<v-list-item v-show="coteaching_filter_list.length>0" style="border-top:1px solid #999">
					<v-list-item-title>Show lessons & activities for origin teacher:</v-list-item-title>
				</v-list-item>
				<v-list-item v-for="coteaching_course in coteaching_filter_list" :key="coteaching_course.origin_teacher_user_id" @click="filter_coteaching_list(coteaching_course)">
					<v-list-item-title>
						<v-icon class="mr-2" small v-visible="coteaching_course.origin_teacher_user_id == filtered_origin_teacher_id">fas fa-circle-check</v-icon>
						{{ coteaching_course.origin_teacher_first_name }} {{ coteaching_course.origin_teacher_last_name }}
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

		<v-menu bottom right><template v-slot:activator="{on}"><v-btn v-on="on" v-if="show_course_function_menu" small fab color="#999" dark><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template>
			<v-list dense>
				<!-- note that this menu changes significantly depending on whether or not we're in a condensed window -->
				<v-list-item v-if="condensed_controls" style="min-height:0px;" class="pt-2 pb-1"><v-list-item-title><div class="text-center"><b>Academic Year {{academic_year_display}}</b></div></v-list-item-title></v-list-item>

				<v-list-item v-if="condensed_controls&&block_mode_switch_available"><v-list-item-title><div class="text-center">
					<v-btn-toggle dense active-class="k-toggle-btn-active-class" v-model="term_mode" mandatory>
						<v-btn x-small light style="width:80px" value="normal">Quarters</v-btn>
						<v-btn x-small light style="width:80px" value="block">Blocks</v-btn>
					</v-btn-toggle>
				</div></v-list-item-title></v-list-item>

				<v-list-item v-if="condensed_controls&&!course_addable&&user_can_view_teacher_resources" @click="go_to_community"><v-list-item-icon><v-icon small>fas fa-users</v-icon></v-list-item-icon><v-list-item-title>Go to Course Community</v-list-item-title></v-list-item>

				<v-list-item v-if="condensed_controls&&case_framework_identifier" @click="show_case_tree"><v-list-item-icon><v-icon small>fas fa-list</v-icon></v-list-item-icon><v-list-item-title>Henry T&amp;L Standards</v-list-item-title></v-list-item>

				<v-list-item v-if="condensed_controls&&(course_in_my_courses||user_can_view_teacher_resources)" v-for="(rc, index) in lp.resource_collections" :key="index" @click="show_resource_collection(rc)"><v-list-item-icon><v-icon small>fas fa-bolt</v-icon></v-list-item-icon><v-list-item-title>{{rc.description}}</v-list-item-title></v-list-item>

				<v-list-item v-if="condensed_controls&&ebook_resource&&(course_in_my_courses||user_can_view_teacher_resources)" @click="open_ebook"><v-list-item-icon><v-icon small>fas fa-book</v-icon></v-list-item-icon><v-list-item-title>Ebook: <span class="ml-1" v-html="ebook_resource.description"></span></v-list-item-title></v-list-item>

				<v-list-item v-if="condensed_controls&&user_can_view_teacher_resources&&has_pd_resources" @click="pd_resources_showing=!pd_resources_showing"><v-list-item-icon><v-icon small>fas fa-user-graduate</v-icon></v-list-item-icon><v-list-item-title>PD Resources</v-list-item-title></v-list-item>

				<v-list-item v-show="condensed_controls&&state_course_code" @click="launch_inspire"><v-list-item-icon><v-icon small>fas fa-box-archive</v-icon></v-list-item-icon><v-list-item-title>Inspire</v-list-item-title></v-list-item>

				<v-list-item v-if="condensed_controls&&show_safari" @click="safari_search" style="padding-left:4px"><v-list-item-title><img style="height:20px;margin-right:6px;" src="./../../images/safari-search-logo.png">Search SAFARI Montage</v-list-item-title></v-list-item>


				<v-list-item v-if="condensed_controls&&course_addable" @click="add_to_my_courses"><v-list-item-icon><v-icon small>fas fa-plus-circle</v-icon></v-list-item-icon><v-list-item-title>Add to “My Courses” Area</v-list-item-title></v-list-item>
				<v-list-item v-if="course_removable" @click="remove_from_my_courses"><v-list-item-icon><v-icon small>fas fa-minus-circle</v-icon></v-list-item-icon><v-list-item-title>Remove from “My Courses” Area</v-list-item-title></v-list-item>

				<v-list-item v-if="is_lp_admin&&!condensed_controls" @click="edit_lp"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit Learning Progression Terms and Units</v-list-item-title></v-list-item>

				<v-list-item v-if="can_view_resource_report&&!condensed_controls" @click="resource_report_showing=true"><v-list-item-icon><v-icon small>fas fa-table</v-icon></v-list-item-icon><v-list-item-title>View Resource Usage Reports</v-list-item-title></v-list-item>

				<v-list-item v-if="(condensed_controls&&role=='parent'&&teacher_display)" style="padding-left:4px;margin-top:-4px" href="https://campus.henry.k12.ga.us/campus/portal/parents/henry.jsp" target="_blank"><v-list-item-title><img src="./../../images/ic_parent_view2.png" style="height:20px;margin-right:6px;margin-bottom:-4px">Infinite Campus Parent Portal</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</h2>

	<!-- <div v-if="studentish_role&&teacher_display" class="my-2 text-center"><b>Teacher:</b> <span v-html="teacher_display"></span></div> -->

	<div v-if="!condensed_controls" class="my-3 d-flex">
		<v-btn v-if="case_framework_identifier" style="letter-spacing:0;text-transform:none;" color="orange lighten-1" class="grey--text text--darken-4" dark @click="show_case_tree"><v-icon small class="mr-2">fas fa-list</v-icon>Henry T&amp;L Standards</v-btn>
		<v-btn v-if="user_can_view_student_resources" v-for="(rc, index) in lp.resource_collections" :key="index" class="ml-3" style="letter-spacing:0;text-transform:none;color:#283593" color="indigo lighten-4" @click="show_resource_collection(rc)"><v-icon small class="mr-2">fas fa-bolt</v-icon>{{rc.description}}</v-btn>
		<v-btn v-if="user_can_view_student_resources&&ebook_resource" class="ml-3 k-tight-btn k-nocaps-btn" color="indigo lighten-4" style="color:#283593" @click="open_ebook">Ebook: <span class="ml-1" v-html="ebook_resource.description"></span></v-btn>
		<v-btn v-if="user_can_view_teacher_resources&&has_pd_resources" style="letter-spacing:0;text-transform:none;" color="lime" class="ml-4 grey--text text--darken-4" @click="pd_resources_showing=!pd_resources_showing"><v-icon small class="mr-2">fas fa-user-graduate</v-icon>PD Resources</v-btn>
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="state_course_code" style="letter-spacing:0;text-transform:none;color:#D4405F!important" color="#F0DECD" class="ml-4 grey--text text--darken-4" @click="launch_inspire"><v-icon small class="mr-2">fas fa-box-archive</v-icon>GaDOE Inspire</v-btn></template>{{state_course_code}}</v-tooltip>
		<v-tooltip bottom><template v-slot:activator="{on}"><div v-if="show_safari" class="k-safari_btn ml-4 elevation-3" v-on="on" @click="safari_search"><img src="./../../images/safari-search-logo.png"></div></template>Search SAFARI Montage<br>for additional resources</v-tooltip>
		<v-spacer />
		<v-btn v-if="course_addable" color="secondary" @click="add_to_my_courses"><v-icon small class="mr-2">fas fa-plus-circle</v-icon>Add to “My Courses”</v-btn>
		<div v-if="role=='parent'&&teacher_display" style="margin-bottom:-24px;margin-top:-8px;"><a href="https://campus.henry.k12.ga.us/campus/portal/parents/henry.jsp" target="_blank"><img src="./../../images/ic_parent_view2.png" style="height:60px"></a></div>
		<v-btn v-if="role=='student'" color="secondary" class="k-tight-btn ml-3" @click="lp_showing_for_student=!lp_showing_for_student"><v-icon small class="mr-2">fas fa-calendar-days</v-icon>{{lp_showing_for_student?'Hide':'Show'}} Learning Progression</v-btn>
	</div>

	<div v-if="role!='student'||lp_showing_for_student">
		<div v-if="initialized" v-show="!show_lp_units&&!show_lp_editor" class="text-center mt-4"><i>The Learning Progression for this course is currently unavailable.</i></div>
		<div v-if="initialized" v-show="show_lp_units&&!lp_is_empty">
			<div class="k-learning-progression-table-wrapper-outer mt-3"><div class="k-learning-progression-table-wrapper"><table v-if="initialized>0" class="k-learning-progression">
				<tr v-if="lp.terms.length>0">
					<td v-for="(term, i) in lp.terms" class="k-learning-progression-term-number" :class="term_css_class[i]" :colspan="term_duration_colspan(i)" :style="'width:'+term_duration_width(i)+'%'">
						<div>{{term_title_display(term.title)}}</div>
					</td>
				</tr>
				<tr v-if="lp.terms.length==0">
					<td class="k-learning-progression-term-number" :class="term_css_class" :colspan="lp.units.length" :style="'width:100%'"></td>
				</tr>
				<tr>
					<td v-for="(x) in shim_array" colspan="1"></td>
				</tr>
				<tr v-if="show_unit_duration_row">
					<td v-for="(unit, i) in lp.units" class="k-learning-progression-unit-td" :class="unit_showing==unit?'k-learning-progression--showing'+unit_css_class[i]:unit_css_class[i]" :colspan="unit_duration_colspan(unit.duration)" :style="'width:'+unit_duration_width(unit.duration)+'%'">
						<div class="k-learning-progression-unit-header" :class="(unit_showing==null||unit_showing==unit)?lp_css_class+'-light k-learning-progression--showing':'k-learning-progression-unit-header--not-selected'">{{unit.title?unit.title:'&nbsp;'}}<div v-if="unit.duration">{{unit_duration_display(unit.duration)}}</div></div>
					</td>
				</tr>
				<tr>
					<td v-for="(unit, i) in lp.units" class="k-learning-progression-unit-td k-learning-progression-unit-title" :class="unit_showing==unit?'k-learning-progression--showing'+unit_css_class[i]:unit_css_class[i]" :colspan="unit_duration_colspan(unit.duration)" :style="lp.terms.length>0?'':'width:'+unit_duration_width(unit.duration)+'%'">
						<div v-html="unit.description"></div>
					</td>
				</tr>
				<tr>
					<td v-for="(unit, i) in lp.units" class="k-learning-progression-unit-td k-learning-progression-unit-buttons k-learning-progression-unit-title" :class="unit_showing==unit?'k-learning-progression--showing'+unit_css_class[i]:unit_css_class[i]" :colspan="unit_duration_colspan(unit.duration)" :style="lp.terms.length>0?'':'width:'+unit_duration_width(unit.duration)+'%'">
						<div class="d-flex align-center justify-center mt-2">
							<div v-if="unit_showing!=unit" class="text-center k-learning-progression-unit-show-btn"><v-btn color="secondary" small @click="show_unit(unit)">View <v-icon class="ml-1">fas fa-angle-down</v-icon></v-btn></div>
							<div v-if="unit_showing==unit" class="text-center"><v-btn color="secondary" small @click="hide_unit(unit)">Hide <v-icon class="ml-1">fas fa-angle-up</v-icon></v-btn></div>
							<div v-if="manage_assignments&&activity_counts[unit.lp_unit_id]" class="k-cv-map-activity-count ml-2">
								<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" class="k-cvlp-unit__status teal darken-2 elevation-4 white--text">{{activity_counts[unit.lp_unit_id]}}</div></template><span v-html="activity_count_tooltips[unit.lp_unit_id]"></span></v-tooltip>
							</div>

						</div>
					</td>
				</tr>
			</table></div></div>

			<div v-show="unit_showing"><div class="d-flex" style="align-items:flex-start;">
				<div class="k-course-view-units-wrapper">
					<CVUnit v-for="(unit) in lp.units" v-show="unit_showing==unit"
						:unit_showing="unit_showing==unit"
						:term_mode="term_mode"
						:lp="lp" :unit="unit"
						:key="unit.lp_unit_id"
						:assignment_id_expanded="assignment_id"
						:is_lp_admin="is_lp_admin"
						:is_sis_course="is_sis_course"
						:filtered_origin_teacher_id="filtered_origin_teacher_id"
						@show_unit="show_unit(unit)"
						@hide_unit="hide_unit"
						@toggle_unit="toggle_unit(unit)"
					></CVUnit>
				</div>
			</div></div>

		</div>
	</div>
	<div v-if="initialized&&show_my_content_or_messages&&!show_lp_editor" class="k-course-view-lp-mode-buttons my-6">
		<div v-if="manage_assignments" class="d-flex mb-5"><v-spacer/><v-btn-toggle v-if="$vuetify.breakpoint.width>800" class="k-course-view-toggle-btn elevation-3" active-class="k-course-view-toggle-btn-active-class" v-model="lp_my_content_or_messages_mode" mandatory>
			<v-btn small :large="$vuetify.breakpoint.mdAndUp" class="k-tight-btn k-nocaps-btn" @click="scroll_to_mode_buttons" :value="'course_materials'">&nbsp;&nbsp;<v-icon class="mr-2" :small="$vuetify.breakpoint.smAndDown" :color="(lp_my_content_or_messages_mode=='course_materials')?'#fff':'secondary'">fas fa-shapes</v-icon>Course-Wide Materials</v-btn>
			<v-btn v-show="!lp_is_empty" small :large="$vuetify.breakpoint.mdAndUp" class="k-tight-btn k-nocaps-btn" @click="scroll_to_mode_buttons" :value="'content'">&nbsp;&nbsp;<v-icon class="mr-2" :small="$vuetify.breakpoint.smAndDown" :color="(lp_my_content_or_messages_mode=='content')?'#fff':'secondary'">fas fa-rectangle-list</v-icon>Unit Lessons & Activities</v-btn>
			<v-btn small :large="$vuetify.breakpoint.mdAndUp" class="k-tight-btn k-nocaps-btn" @click="scroll_to_mode_buttons" :value="'messages'">&nbsp;&nbsp;&nbsp;<v-icon class="mr-2" :small="$vuetify.breakpoint.smAndDown" :color="(lp_my_content_or_messages_mode=='messages')?'#fff':'secondary'">fas fa-comments</v-icon>{{ messages_button_text }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-btn>
		</v-btn-toggle><v-spacer/></div>
		
		<div v-if="lp_my_content_or_messages_mode=='content' || lp_my_content_or_messages_mode=='course_materials'">
			<MyContentView2 v-if="manage_assignments" :course_code="course_code" :lp_is_empty="lp_is_empty" :filtered_origin_teacher_id="filtered_origin_teacher_id" />
			<MyContentView v-if="!manage_assignments" :course_code="course_code" />
		</div>
		<div v-if="lp_my_content_or_messages_mode=='messages' && manage_assignments">
			<MessagesView :course_code="course_code" />
		</div>
	</div>

	<!-- delete this -->
	<CASETree v-if="case_tree_loaded" v-show="case_tree_showing"
		@hide_tree="hide_case_tree"
		@choose_item="standard_chosen"
		@tree_becomes_visible="tree_becomes_visible"
		:framework_identifier="case_framework_identifier"
		:open_item="case_tree_open_item"
		:showing="case_tree_showing"
		:show_chooser="case_tree_show_chooser"
	/>

	<ResourceCollectionTree v-if="resource_collection_showing.description" :resource_collection="resource_collection_showing" @hide_tree="hide_resource_collection" />
	<PDResourceCollection v-if="pd_resources_showing" :lp="lp" @hide="pd_resources_showing=false" />

	<LearningProgressionEdit v-if="show_lp_editor" :learning_progression="lp" @editor_cancel="lp_edit_requested=false"></LearningProgressionEdit>

	<AdminResourceUsageDialog v-if="resource_report_showing" :course_code="course_code" @dialog_cancel="resource_report_showing=false" />
	<CoteacherDialog v-if="show_coteacher_dialog" :course_code="course_code" @dialog_cancel="show_coteacher_dialog=false" />
</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CVUnit from './CVUnit'
import CASETree from '../resources/CASETree'
import ResourceCollectionTree from '../resources/ResourceCollectionTree'
import PDResourceCollection from '../resources/PDResourceCollection'
import LearningProgressionEdit from './LearningProgressionEdit'
import MessagesView from '../messages/MessagesView'
import MyContentView from '../mycontent/MyContentView'
import MyContentView2 from '../mycontent/MyContentView2'
import AdminResourceUsageDialog from '../admin/AdminResourceUsageDialog'
import CoteacherDialog from './CoteacherDialog'
// import './statecoursecodes.js'

export default {
	components: { CVUnit, CASETree, ResourceCollectionTree, PDResourceCollection, LearningProgressionEdit, MessagesView, MyContentView, MyContentView2, AdminResourceUsageDialog, CoteacherDialog },
	props: {
		// note that these three props come from vuerouter
		course_code: { type: String, required: true },
		unit_id: { type: String, required: true },
		assignment_id: { type: String, required: true },
	},
	data() { return {
		initialized: 0,

		unit_showing_val: null,

		case_tree_showing: false,
		case_tree_show_chooser: false,
		case_tree_loaded: false,
		case_tree_open_item: '',
		case_tree_item_to_open: '',

		// this is set to true when the user requests to edit; then we show the editor if the lp isn't locked for editing
		lp_edit_requested: false,

		resource_collection_showing: {},
		pd_resources_showing: false,
		resource_report_showing: false,

		term_mode: 'normal',
		show_coteacher_dialog: false,
		filtered_origin_teacher_id: null,
	}},
	computed: {
		...mapState(['user_info', 'all_courses', 'last_lp_list', 'course_update_trigger', 'lp_edit_locked', 'my_activities', 'my_activity_results', 'my_coteachers', 'my_coteaching_courses']),
		...mapGetters(['academic_year_display', 'role', 'system_role', 'show_safari', 'beta_options', 'studentish_role', 'manage_assignments', 'unread_message_count']),
		messages_button_text() {
			let n = this.unread_message_count(this.course_code)
			if (n == 0) return 'Message Center'
			else return `Message Center (${n})`
		},

		coteaching_filter_list() {
			const arr = this.my_coteaching_courses.filter(x=>x.course_code == this.course_code).reduce((acc, curr) => {
				const existing = acc.find(item => item.origin_teacher_user_id === curr.origin_teacher_user_id);
				if (!existing) {
					acc.push(curr);
				}
				return acc;
			}, []);
			return arr
		},
		filter_on_user_id() {
			// if we are a coteacher and the filtered_origin_teacher_id has been set, filter on that
			// otherwise filter on user id
			if (this.user_is_coteacher_for_course && this.filtered_origin_teacher_id) return this.filtered_origin_teacher_id
			return this.user_info.user_id
		},
		filtered_coteacher_course() {
			if (this.filtered_origin_teacher_id === null) return null
			return this.my_coteaching_courses.find(c => c.origin_teacher_user_id == this.filtered_origin_teacher_id && c.course_code == this.course_code)
		},
		lp_my_content_or_messages_mode: {
			// NOTE: possible values include 'content', 'messages', or 'course_materials' (with the latter value added after the first two were established)
			get() { 
				let val = this.$store.state.lst.lp_my_content_or_messages_mode
				if (val == 'content' && this.lp_is_empty) return 'course_materials'
				return val
			},
			set(val) { 
				console.log('lp_my_content_or_messages_mode (CourseView)', val)
				this.$store.commit('lst_set', ['lp_my_content_or_messages_mode', val]) 
			}
		},
		lp_showing_for_student: {
			get() { return this.$store.state.lst.lp_showing_for_student },
			set(val) { this.$store.commit('lst_set', ['lp_showing_for_student', val]) }
		},
		condensed_controls() {
			return vapp.$vuetify.breakpoint.height < 400 || vapp.$vuetify.breakpoint.width < 960
		},
		course_in_my_courses() {
			// some things should only be shown to family members/students if the course is included in my_courses
			return (this.$store.getters.my_courses.findIndex(x=>x.course_code == this.lp.course_code) > -1)
		},
		user_can_view_student_resources() {
			// if the user is staff or higher, you can view the ebook resources
			if (this.user_can_view_teacher_resources) return true
			// for parents or students, if this is one of their courses, you can view ebook resources
			if (this.course_in_my_courses) return true
			// if user's system role is staff or admin, you're probably viewing as a teacher/student; allow to view ebook resources
			if (this.system_role == 'staff' || this.system_role == 'admin') return true

			return false
		},
		user_can_view_teacher_resources() {
			return this.role == 'staff' || this.role == 'admin'
		},
		user_is_teacher() {
			return this.role == 'staff'
		},
		user_is_parent() {
			return this.role == 'parent'
		},
		user_is_admin() {
			return this.role == 'admin'
		},
		user_is_coteacher_for_course() {
			if (!this.my_coteaching_courses.length) return false
			return (this.my_coteaching_courses.findIndex(x=>x.course_code == this.course_code) > -1)
		},
		lp_is_empty() {
			// return true if the course doesn't have any units or terms
			return (this.lp.terms.length == 0 && this.lp.units.length == 0)
		},
		show_lp_units() {
			// DON'T show the LP unit structure if...
			// the lp editor is showing
			if (this.show_lp_editor) return false

			// or the course is inactive and this user doesn't have edit rights
			if (this.lp.active=='no' && !this.is_lp_admin) return false

			// if we get to here, show the unit structure
			return true
		},
		show_lp_editor() {
			// show the editor if the user has requested to edit and the lp isn't locked for editing (lp_edit_locked[this.course_code] must have been explicitly set to false)
			if (this.lp_edit_requested != true) return false
			if (empty(this.lp_edit_locked[this.course_code]) || this.lp_edit_locked[this.course_code] === true) return false
			if (this.unit_showing) return false
			return true
		},
		sis_course() { 
			// note that this returns the first-listed sis_classes record for this course_code. If this is a teacher that teaches both block and quarter classes, they may have two sis_classes records, but that's OK for our purposes here.
			return this.$store.state.sis_classes.find(x=>x.course_code==this.course_code) 
		},
		teacher_display() {
			if (this.user_info.role == 'staff' || this.user_info.role == 'admin') return ''
			if (!this.sis_course) return ''

			// the "classCodes" value holds the display value for the teacher, which might be, e.g. "Parham, Lindsey VIRTUAL"
			let s = ''
			if (this.sis_course.classCodes && this.sis_course.classCodes.length > 0) {
				s = this.sis_course.classCodes[0]
			}

			// if we have a teacher record, use the email from there (and the teacher name if we didn't get it from classCodes)
			if (this.sis_course.teachers && this.sis_course.teachers.length > 0 && this.sis_course.teachers[0] && this.sis_course.teachers[0].length > 0) {
				// note teachers[0][0] format, which is needed because of the complex course structure in IC
				let teacher = this.sis_course.teachers[0][0]
				// console.log(teacher)
				if (!s) s = teacher.givenName + ' ' + (teacher.middleName ? (teacher.middleName + ' ') : '') + teacher.familyName

				s += sr('<br><a style="font-size:14px; font-weight:bold; margin-left:6px;" href="mailto:$1">$1</a>', teacher.email.toLowerCase())
			}

			s = `<b>Teacher:</b> ${s}`

			return s
		},
		is_sis_course() {
			if (this.sis_course) return true
			return false
		},
		in_added_my_courses() {
			if (this.$store.state.added_my_courses.find(x=>x==this.lp.course_code)) return true
			return false
		},
		in_removed_my_courses() {
			if (this.$store.state.removed_my_courses.find(x=>x==this.lp.course_code)) return true
			return false
		},
		course_addable() {
			// don't let parents add courses (at least for now)
			if (this.role == 'parent') return false
			return (this.in_removed_my_courses || (!this.is_sis_course && !this.in_added_my_courses))
		},
		course_removable() {
			return ((this.in_added_my_courses || this.is_sis_course) && !this.in_removed_my_courses)
		},
		has_pd_resources() {
			return !empty(this.lp.pd_resources) && this.lp.pd_resources.length > 0;
		},
		is_lp_admin() {
			if (this.initialized < 0) return
			return vapp.is_lp_admin(this.lp)
		},
		can_view_resource_report() {
			// editors for this lp (which include system admins), and users that are principals/vice-principals can view resource usage reports from here
			return this.is_lp_admin || this.$store.getters.user_is_principal_or_ap
		},
		can_change_academic_year() {
			return this.is_lp_admin || (this.$store.state.allow_academic_year_change_for_all_staff && this.user_info.system_role == 'staff')
		},
		show_course_function_menu() {
			if (this.condensed_controls) return true
			if (this.can_view_resource_report) return true
			if (this.user_is_teacher || this.user_is_admin) {
				if (this.is_lp_admin) return true
				if (this.course_removable) return true
			}
			return false
		},
		lp() {
			if (this.initialized < 0) return {}

			// we always pull from all_courses here, not the user's individual courses
			let l = this.all_courses.find(o=>o.course_code == this.course_code)
			if (empty(l)) return {}
			return l
		},
		case_framework_identifier() {
			if (this.lp.subject_case_identifier == 'none') return ''
			if (this.lp.subject_case_identifier) return this.lp.subject_case_identifier
			return this.$store.state.subjects[this.lp.subject]	// may return null
		},
		course_case_identifier() {
			return this.lp.course_case_identifier
		},
		top_css_class() {
			let s = U.subject_tile_css(this.lp)+'-border'
			// let s = ''
			if (this.unit_showing) s += ' k-course-view-wrapper--unit-showing'

			if (this.unit_count_overflow) s += ' k-course-view-wrapper--unit_count_overflow'

			if (this.filtered_origin_teacher_id) s += ' k-course-view-wrapper--origin_teacher_activities'

			return s
		},
		lp_css_class() {
			let s = U.subject_tile_css(this.lp)
			return s
		},
		term_css_class() {
			if (empty(this.lp.terms)) return []

			// if no terms specified, we just do one long header
			if (this.lp.terms.length == 0) return this.lp_css_class + ' k-learning-progression-term-number--first k-learning-progression-term-number--last'

			let arr = []
			for (let i = 0; i < this.lp.terms.length; ++i) {
				let s = this.lp_css_class
				if (i == 0) s += ' k-learning-progression-term-number--first'
				if (i == this.lp.terms.length-1) s += ' k-learning-progression-term-number--last'

				arr.push(s)
			}
			return arr
		},
		unit_css_class() {
			if (empty(this.lp.units)) return []
			let arr = []
			for (let i = 0; i < this.lp.units.length; ++i) {
				let s = ''
				if (i == 0) s += ' k-learning-progression-unit--first'
				if (i == this.lp.units.length-1) s += ' k-learning-progression-unit--last'

				arr.push(s)
			}
			return arr
		},
		show_unit_duration_row() {
			// don't show the unit duration rows if none of the units have titles or durations!
			for (let unit of this.lp.units) {
				if (unit.duration || unit.title) return true
			}
			return false
		},
		ebook_resource() {
			if (!this.lp.units) return null
			// if the course's units' resources include at least one ebook link, include the *last*-listed ebook as a link highlighted at the top of the course
			let er
			for (let u of this.lp.units) {
				if (!u.resources) continue
				for (let r of u.resources) {
					if (r.lp_category == 'course_ebook') {
						er = r
					}
				}
			}
			return er
		},
		unit_showing() {
			if (empty(this.lp.units)) return

			// if student is viewing and not showing the lp, obviously a unit is not showing
			if (this.role == 'student' && !this.lp_showing_for_student) return

			// if unit_id (which comes from the url via vuerouter) is something other than 0, get the unit to show;
			// otherwise use the last unit showing (which could be null)
			if (this.unit_id != 0) {
				let u = this.lp.units.find(x=>x.lp_unit_id == this.unit_id)
				if (empty(u)) return null
				this.unit_showing_val = u
			}
			return this.unit_showing_val
		},
		show_my_content_or_messages() {
			// don't show the course-wide my_content area if a unit is showing
			if (this.unit_showing) return false
			// if role is staff or admin, show
			if (this.role=='staff' || this.role=='admin') return true
			// or if manage_assignments is on, show
			if (this.manage_assignments) return true
			// if we get to here, don't show
			return false
		},
		activity_counts() {
			let o = {}
			for (let unit of this.lp.units) {
				o[unit.lp_unit_id] = 0
				if (this.manage_assignments) {
					for (let activity of this.my_activities) {
						if (activity.lp_unit_id == unit.lp_unit_id) {
							// for students, don't count complete activities or activities that aren't available
							if (this.studentish_role) {
								if (this.my_activity_results[activity.activity_id]?.complete() || !activity.available_to_students()) {
									continue
								}
							// for teachers, only count currently due activities
							} else {
								// This is complicated by co-teacher filtering for teachers
								if (this.filter_on_user_id !== activity.creator_user_id) continue
								if (!activity.currently_due()) continue
							}
							++o[unit.lp_unit_id]
						}
					}
				}
			}
			return o
		},
		activity_count_tooltips() {
			let o = {}
			for (let unit of this.lp.units) {
				if (this.studentish_role) {
					o[unit.lp_unit_id] = sr('$1 incomplete $2', this.activity_counts[unit.lp_unit_id], U.ps('activity', this.activity_counts[unit.lp_unit_id], 'activities'))
				} else {
					o[unit.lp_unit_id] = sr('$1 $2 currently available and due', this.activity_counts[unit.lp_unit_id], U.ps('activity', this.activity_counts[unit.lp_unit_id], 'activities'))
				}
			}
			return o
		},
		// lesson_color_counts() {
		// 	let o = {}
		// 	for (let unit of this.lp.units) {
		// 		o[unit.lp_unit_id] = {amber:0, green:0, lime:0, blue:0, grey:0, red:0}

		// 		if (this.course_update_trigger < 0 || empty(unit.assignments)) continue
		// 		if (unit.assignments.length == 0 || empty(unit.assignments[0].component)) continue

		// 		for (let color in o[unit.lp_unit_id]) {
		// 			o[unit.lp_unit_id][color] = unit.assignments.filter(a=>{
		// 				if (empty(a.component)) return false
		// 				return a.component.status_color == color
		// 			}).length
		// 		}
		// 	}
		// 	return o
		// },
		// lesson_tooltips() {
		// 	let o = {}
		// 	for (let unit of this.lp.units) {
		// 		o[unit.lp_unit_id] = {amber:0, green:0, lime:0, blue:0, grey:0, red:0}
		// 		// let o = {amber:0, green:0, lime:0, blue:0, grey:0, red:0}
		// 		for (let color in this.lesson_color_counts[unit.lp_unit_id]) {
		// 			let ct = this.lesson_color_counts[unit.lp_unit_id][color]
		// 			// not started/not assigned: grey
		// 			if (color == 'grey') {
		// 				o[unit.lp_unit_id][color] = sr('$1 Not available to students', ct)

		// 				// past-due: blue
		// 			} else if (color == 'blue') {
		// 				o[unit.lp_unit_id][color] = sr('<b>$1 Past Due</b>', ct)

		// 			} else if (color == 'amber') {
		// 				o[unit.lp_unit_id][color] = sr('<div class="text-center"><b>$1 Open to students</b></div>', ct)
		// 			}
		// 		}
		// 	}
		// 	return o
		// },
		unit_count_overflow() {
			if (empty(this.lp.units)) return false
			return this.lp.units.length >= 10
		},
		block_mode_switch_available() {
			// if the signed-in user is teaching/enrolled in block or term classes *only*, set term_mode appropriately and don't show the switch
			let sis_classes = this.$store.state.sis_classes.filter(o => o.course_code == this.course_code)
			let format = ''
			for (let sis_class of sis_classes) {
				if (!format) {
					format = sis_class.format
				} else if (format != sis_class.format) {
					format = 'both'
					break
				}
			}

			if (format == 'both') {
				// user is teaching both formats; get last-selected format for this course
				this.term_mode = this.$store.state.lst.lp_format_showing[this.course_code+'']
				if (!this.term_mode) this.term_mode = 'normal'

				// return true so they see the switcher
				return true

			} else if (format) {
				this.term_mode = (format == 'B') ? 'block' : 'normal'
				// user is only using one mode, so don't show the switcher
				return false
				
			} else {
				// if the user isn't teaching/enrolled in the course, return true (show the block mode switch) iff grade_high is >= 9 (high school course), and get the last-selected format
				// user is teaching both formats; get last-selected format for this course
				this.term_mode = this.$store.state.lst.lp_format_showing[this.course_code+'']
				if (!this.term_mode) this.term_mode = 'normal'

				return (U.grade_value(this.lp.grade_high) >= 9)
			}
		},
		shim_array() {
			// we need this to overcome an apparent bug in chrome that makes the colspans not work properly
			let arr = [];
			for (let unit of this.lp.units) {
				// account for units with durations > 18 weeks, which are treated as 1 week in the table
				let n = (unit.duration <= 18) ? unit.duration : 1
				for (let i = 0; i < n*2; ++i) {
					arr.push('x')
				}
			}
			return arr
		},
		state_course_code() {
			// *most* of the state codes we need for inspire links are x*.xxxx
			// some of the state codes are, e.g., 23.0040000, which has too many 0's.
			// unfortunately the math codes in inpire are 23.xxxxx
			// but if there are more than 5 numbers after the decimal, it is definitely invalid (I think), so don't use it here
			let scc = this.lp.state_course_code
			if (scc && scc.search(/\.\d\d\d\d\d\d+/) > -1) scc = ''
			return scc
			// 	return this.state_course_code	// could be empty
			// 	// return window.hc_to_state_course_code_mappings[this.course_code]
			// 	if (this.course_code == '6632') return '45.0610'	// PF & Econ
			// 	if (this.course_code == '1545') return '45.0060'	// SS 5
			// 	if (this.course_code == '1335') return '27.01400'	// Math 3
			// 	return ''
		},
	},
	watch: {
		term_mode() {
			console.log('term_mode watcher: ' + this.term_mode)
			// when term_mode is set, change lst value for the course
			let o = this.$store.state.lst.lp_format_showing
			o[this.course_code + ''] = this.term_mode
			this.$store.commit('lst_set', ['lp_format_showing', o])
		},
	},
	created() {
	},
	mounted() {
		// console.log(sr('CourseView mounted; component id: $4, course_code: $1; unit_id: $2; assignment_id: $3', this.course_code, this.unit_id, this.assignment_id, this._uid))
		this.initialize()

		// we have to do this to allow clicking the LP tile to re-show the "map"
		// vapp.assignments_main.lp_components[this.course_code] = this
	},
	methods: {
		initialize() {
			// if we don't already have the full lp data for this course...
			if (empty(this.lp.lp_id) || this.lp.lp_id == 0 || !this.lp.fully_loaded) {
				// if title is empty and the user is an admin, we must be creating it now, so go right to the editor
				if (this.lp.title == '' && this.is_lp_admin) {
					++this.initialized
					this.edit_lp()
					return
				}

				// try to load it now
				this.$store.dispatch('get_learning_progression', this.course_code).then((found)=>{
					if (!found) {
						this.no_learning_progression()
						// this.$alert('The learning progression for this course has not yet been entered in the system.')
						return
					}
					this.get_forum_posts()

					this.$nextTick(()=>{
						++this.initialized
					})

				}).catch(()=>{
					console.log('error in get_learning_progression?')
					vapp.go_to_home()
				})

			} else {
				this.get_forum_posts()
				++this.initialized
			}
		},

		scroll_to_mode_buttons() {
			setTimeout(()=>{ this.$vuetify.goTo($(this.$el).find('.k-course-view-lp-mode-buttons')[0], {offset:72, duration:200}) }, 50)
		},

		get_forum_posts() {
			vapp.update_now()
			if (!this.lp.forum_loaded) {
				this.$store.dispatch('get_data_for_community', {community_id:0, forum_ids:this.lp.forum_ids}).then(()=>{
					this.$store.commit('set', [this.lp, 'forum_loaded', true])
					this.$nextTick(()=>{
						++this.initialized
					})
				})
			}
		},

		term_title_display(term_title) {
			if (this.term_mode == 'block') {
				return term_title.replace(/Quarter/i, 'Block')
			} else {
				return term_title
			}
		},

		term_duration_colspan(term_index) {
			// we have to do some trickiness here if the term includes a unit that spans more than 18 weeks...
			// this loop goes through each unit to find the units included in this term. If this term includes one or more units that span more than 18 weeks,
			// we count it as a "term_extra"; then we add term_extras to the duration of the term (usually 9) to determine the number of colspans to include
			let current_term = 0
			let term_sum = 0
			let term_extras = 0
			for (let ui = 0; ui < this.lp.units.length; ++ui) {
				let unit_duration = this.lp.units[ui].duration
				if (unit_duration <= 18) {
					term_sum += unit_duration
				} else {
					term_extras += 1
				}

				if (term_sum >= this.lp.terms[current_term].duration) {
					if (current_term == term_index) {
						break
					} else {
						term_sum = term_sum - 9
						term_extras = 0
						current_term += 1

						// if the last unit took us through two terms,
						if (term_sum >= this.lp.terms[current_term].duration) {
							// break if necessary, or update current_term again
							if (current_term == term_index) break
							else current_term += 1
						}
					}
				}
			}
			return (this.lp.terms[term_index].duration + term_extras) * 2
		},

		term_duration_width(term_index) {
			return this.term_duration_colspan(term_index) / 72 * 100
		},

		unit_duration_colspan(duration) {
			// units that span two quarters or more arent really part of a single quarter, so use colspan and width of 1 for these
			let n = (duration <= 18) ? duration : 1
			return n * 2
		},

		unit_duration_width(duration) {
			return this.unit_duration_colspan(duration) / 72 * 100
		},

		unit_duration_display(duration) {
			if (this.term_mode == 'block') duration = duration / 2
			return duration + U.ps(' Week', duration)
		},

		hide_unit() {
			if (!empty(this.unit_showing)) {
				// when we hide the unit, we have to set unit_showing_val to null, but after a tick to let unit_id get to 0
				this.$nextTick(()=>this.unit_showing_val = null)
				this.$router.push({ path: sr('/courses/lp/$1/$2/$3', this.course_code, 0, 0) })
				this.scroll_to_mode_buttons()
			}
		},

		show_unit(unit) {
			// TODO: this is code from the original LearningProgression.vue
			/*
			// if we're editing a unit...
			if (!empty(this.unit_showing) && this.$refs.unit_component.show_unit_editor) {
				// then if the unit has changed...
				if (this.$refs.unit_component.$refs.unit_editor_component.unit_has_changed()) {
					// ask the user if they want to save before opening the new unit
					this.$confirm({
					    text: 'Would you like to save the unit you’re currently editing? Click “Cancel” to return to the editor.',
					    acceptText: 'Save',
					    cancelText: 'Cancel'
					}).then(y => {
						// if the user says yes, save edits, then re-call view_unit
						this.$refs.unit_component.$refs.unit_editor_component.save_edits().then(y => {
							this.view_unit(term, unit)
						}).catch(n=>{console.log(n)}).finally(f=>{});

						// if the user cancels, we just return to the editor
					}).catch(n=>{console.log(n)}).finally(f=>{});

					return
				}

				// else the unit hasn't changed, so just close the editor
				this.$refs.unit_component.$refs.unit_editor_component.cancel_editor()
				// recall view_unit on next tick, so that the editor has a tick to shut down
				this.$nextTick(()=>this.view_unit(term, unit))
				return
			}
			*/

			if (empty(this.unit_showing) || unit != this.unit_showing) {
				this.$router.push({ path: sr('/courses/lp/$1/$2/$3', this.course_code, unit.lp_unit_id, 0) })
			}
		},

		toggle_unit(unit) {
			if (!empty(this.unit_showing) && unit == this.unit_showing) this.hide_unit()
			else this.show_unit(unit)
		},

		show_case_tree(options) {
			let data = {framework_identifier:this.case_framework_identifier}
			// if we have a course_case_identifier...
			if (this.course_case_identifier) {
				// have that item open and selected, and (initially) limit to showing just that item and its descendents
				data.item_identifier = this.course_case_identifier
				data.selected_items = [this.course_case_identifier]
				data.limit_to_selected_items = 'children'
				data.no_framework_reset = true
			}

			vapp.$refs.satchel.execute('show').then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
				})
			})

			// // options can include: hide_callback, choose_callback, case_tree_show_chooser
			// if (empty(options)) options = {}

			// this.case_tree_showing = true
			// this.case_tree_loaded = true
			// if (options.case_tree_show_chooser == true) {
			// 	this.case_tree_show_chooser = true
			// } else {
			// 	this.case_tree_show_chooser = false
			// }

			// this.case_tree_show_options = options	// note that case_tree_show_options doesn't need to be reactive
		},

		hide_case_tree() {
			this.case_tree_showing = false
			if (!empty(this.case_tree_show_options, 'hide_callback')) {
				this.case_tree_show_options.hide_callback()
			}
		},

		tree_becomes_visible() {
			// when the tree becomes visible, set case_tree_open_item to case_tree_item_to_open
			this.case_tree_open_item = null
			this.$nextTick(()=>{
				this.case_tree_open_item = this.case_tree_item_to_open
				this.case_tree_item_to_open = null

				// scroll somewhere???
				// goTo($(this.$el).find('.lp_unit_editor_standard_div')[0], {offset:100})
			})
		},

		standard_chosen(identifier, event) {
			if (!empty(this.case_tree_show_options, 'choose_callback')) {
				this.case_tree_show_options.choose_callback(identifier, event)
			}
		},

		show_resource_collection(rc) {
			this.resource_collection_showing = rc
		},

		hide_resource_collection() {
			this.resource_collection_showing = {}
		},

		edit_lp() {
			// if the lp is already saved (i.e. it isn't brand new), we have to request to edit it, to make sure no one else already has it opened for editing
			if (!empty(this.lp.lp_id) && this.lp.lp_id != 0) {
				this.$store.dispatch('lp_edit_socketmsg', this.lp.course_code)
			}

			this.hide_unit()

			// flag that we've requested to edit the LP; once the socket message comes back saying we're OK to edit, the show_lp_editor computed will open it for editing
			this.lp_edit_requested = true
		},

		safari_search() {
			let o = {
				tool: 'safari',
				endpoint: this.$store.state.safari_lti_endpoint,
				search_params: {}
			}

			// for now only do grade/CASE search for test users
			if (vapp.user_info.email == 'teacher@henry.k12.ga.us' || vapp.user_info.email == 'admin@henry.k12.ga.us' || vapp.user_info.email == 'hlong@henry.k12.ga.us') {
				// send grade low/high
				if (!empty(this.lp.grade_low)) o.search_params.fromgrade = this.lp.grade_low
				if (!empty(this.lp.grade_high)) o.search_params.tograde = this.lp.grade_high

				// TODO: send subject CASE identifier?
				// science: 27a08dc6-416e-11e7-ba71-02bd89fdd987 -- returns no resources
				// science/Grade 3: 4fc73034-416e-11e7-8d5e-3765d5cb8bb6 -- 129 web resources, 1 video resource
				// since we don't currently know that the LP goes with science/grade 3, don't send anything for now
				// if (!empty(this.case_framework_identifier)) {
				// 	o.search_params.standardsparentguid = this.case_framework_identifier
				// }
				console.log(o)
			}
			this.$store.dispatch('lti_launch', o)

			// this.$store.dispatch('lti_launch', { tool: 'safari', endpoint: 'https://montage.henry.k12.ga.us/SAFARI/api/imslti.php' })
		},

		add_to_my_courses() {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			this.$store.dispatch('update_my_courses', {course_code:this.course_code, action:'add'}).then(()=>{
				this.$alert(sr('“$1” has been added to your “My Courses” area. If you wish to remove the course in the future, click the “three-dot” button in the upper-right corner of the interface.', this.lp.title))
			})
		},

		go_to_community() {
			this.$router.push({ path: sr('/communities/$1', 100000 + this.lp.lp_id) })
		},

		remove_from_my_courses() {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			this.$store.dispatch('update_my_courses', {course_code:this.course_code, action:'remove'}).then(()=>{
				this.$alert(sr('“$1” has been removed from your “My Courses” area.', this.lp.title))
			})
		},

		change_academic_year() {
			// if ths user is an admin for the LP, allow them to click the Academic Year display to change years (also special case for staff trainings)
			if (!this.can_change_academic_year) return
			vapp.change_academic_year()
		},

		no_learning_progression() {
			// if we don't have a LP for a course, build what we can of the LP from the sis_course if we have it
			if (this.sis_course) {
				console.log('no_learning_progression', this.sis_course)
				let lp = new Learning_Progression({
					course_code: this.course_code,
					title: this.sis_course.title
				})
				this.$store.commit('set', [this.all_courses, 'PUSH', lp])
			}
			++this.initialized
		},

		open_ebook() {
			// open the top ebook link using the same method we use to open resources (ResourceLink.vue)
			let payload = {resource_id: this.ebook_resource.resource_id, get_lti_form: 'yes'}
			this.$store.dispatch('get_resource_record', payload).then(result=>{
				// if we got back an lti_form, launch
				if (!empty(result.lti_form)) {
					// for the lti_form we open a new window and write out the form, which submits itself
					// see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
					let w = window.open()
					w.document.write(result.lti_form)
				} else {
					// else we just open the new window to the resource's url
					window.open(this.ebook_resource.url, '_blank')
				}
			})
		},
		manage_coteachers() {
			this.show_coteacher_dialog = true
		},

		// called when user clicks an origin teacher to view activities for that teacher, or when they click to cancel viewing origin teacher activities
		filter_coteaching_list(coteaching_course) {
			if (coteaching_course == null || coteaching_course.origin_teacher_user_id == this.filtered_origin_teacher_id) {
				this.filtered_origin_teacher_id = this.filtered_origin_teacher_name = null
				this.$store.commit('set', [this.lp, 'viewing_origin_teacher_activities', false])
			} else {
				this.filtered_origin_teacher_id = coteaching_course.origin_teacher_user_id
				this.$store.commit('set', [this.lp, 'viewing_origin_teacher_activities', true])
			}
		},

		launch_inspire() {
			// let url = `http://localhost:7048/course/${this.state_course_code}/0?login`
			let url = `https://inspire.gadoe.org/course/${this.state_course_code}/0?login`
			window.open(url, 'inspire')
		},
	}
}
</script>

<style lang="scss">


.k-course-view-wrapper {
	margin-top:20px!important;
	border-top:5px solid transparent;
	border-bottom:5px solid transparent;

	.k-page-title {
		margin-bottom:0;
	}

	.k-safari_btn {
		height:36px;
		display:inline-block;
	    cursor: pointer;
	    background-color: #fff;
	    border-radius: 10px;
	    padding: 3px 16px;
	    vertical-align: middle;
		img {
			height: 30px;
		}
	}
}
.k-course-view-wrapper--origin_teacher_activities {
	padding-top:42px!important;
	background-color:rgba(252,228,236,0.9)!important;
	.k-cvlp-unit {
		background-color:$v-pink-lighten-5!important;
	}
}

.k-course-view-wrapper-origin-teacher-header {
	position:absolute;
	left:0;
	background-color:$v-pink-accent-4;
	color:#fff;
	width:100vw;
	text-align:center;
	font-weight:bold;
	font-size:18px;
	line-height:18px;
	padding:6px 0;
	// border-top:2px solid #000;
	border-bottom:1px solid #000;
}


.k-course-view-academic-year {
	margin-right:16px;
	color:$v-grey-darken-3;
	text-align:center;
	font-size:12px;
	line-height:16px;
	font-weight:normal;
}

.k-course-view-term-mode-selector {
	margin-left:20px;
	margin-top:-10px;
	.v-btn {
		width:100px;
	}
}

.k-course-view-other-unit-assignments {
	text-align:right;
	font-size:16px;
	font-weight:bold;
	margin-top:-20px;
	cursor:pointer;

	.k-cvlp-unit__status {
		display:inline-block;
		width:28px;
		height:28px;
		line-height:28px;
		border-radius:28px;
		text-align:center;
		font-weight:900;
		margin-left:4px;
	}
}

.k-course-view-units-wrapper {
	flex:1 1 100%;
	margin-top:12px;
}

.k-cv-map-activity-count {
	// position:absolute;
	// right:4px;
	.k-cvlp-unit__status {
		display:inline-block;
		font-size:14px;
		width:24px;
		height:24px;
		line-height:24px;
		border-radius:24px;
		text-align:center;
		font-weight:900;
	}
}

////////////////// from tassle
// .k-learning-progression-table-wrapper-outer {
// 	// padding:0px;
// 	padding:4px;
// 	overflow:auto;
// 	// border-radius: 10px;
// }
//
// .k-learning-progression-table-wrapper {
// 	border-radius: 10px;
// 	max-width:1400px;
// 	min-width:980px;
// 	margin:0 auto 0 auto;
// 	box-shadow:0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
// }

/////////////////

.k-learning-progression-table-wrapper-outer {
	margin:0 -70px;
	padding:10px 4px;
	overflow:auto;
}

.k-learning-progression-table-wrapper {
	border-radius: 10px;
	max-width:1400px;
	min-width:980px;
	margin:0 auto 0 auto;
	box-shadow:0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.k-learning-progression {
	border-collapse:collapse;
	width:100%;
	font-size:16px;

	td {
		vertical-align:top;
	}

	.k-learning-progression-term-number {
		border-left:2px solid #bbb;
		border-right:2px solid #bbb;
		// border-bottom:2px solid #bbb;
		padding:10px;
		div {
			color:#222;
			font-weight:900;
			text-align:center;
			text-transform:uppercase;
		}
	}
	.k-learning-progression-term-number--first {
		border-radius:10px 0 0 0;
		border-left:0;
	}
	.k-learning-progression-term-number--last {
		border-radius:0 10px 0 0;
		border-right:0;
	}

	.k-learning-progression-term-number--first.k-learning-progression-term-number--last {
		border-radius:10px 10px 0 0;
	}

	.k-learning-progression-unit-td {
		border-left:2px solid #bbb;
		border-right:2px solid #bbb;
	}

	.k-learning-progression-unit-header {
		white-space:nowrap;
		padding:8px;
		color:#222;
		font-weight:bold;
		text-align:center;
	}

	.k-learning-progression-unit-header--not-selected {
		background-color:#ccc;
	}

	.k-learning-progression-unit-title {
		position:relative;
		text-align:center;
		font-size:15px;
		padding:10px;
		background-color:#fff;
	}

	.k-learning-progression-unit--first {
		border-left:0;
	}
	.k-learning-progression-unit--last {
		border-right:0;
	}

	.k-learning-progression-unit-buttons.k-learning-progression-unit--first {
		border-radius:0 0 0 10px;
	}
	.k-learning-progression-unit-buttons.k-learning-progression-unit--last {
		border-radius:0 0 10px 0;
	}
}

.k-course-view-wrapper--unit-showing {
	.k-learning-progression-unit-header {
		background-color:rgba(192,192,192,0.3);
		color:rgba(24,24,24,0.3);
	}
	.k-learning-progression-unit-title {
		background-color:rgba(255,255,255,0.3);
		color:rgba(24,24,24,0.3);
		.k-learning-progression-unit-show-btn .v-btn {
			background-color:#ccc!important;
		}
	}

	// note that unit-header is on a div inside the td, whereas unit-title is on the td itself
	.k-learning-progression--showing .k-learning-progression-unit-header, .k-learning-progression--showing.k-learning-progression-unit-title {
		background-color:#fff;
		color:#222;
	}

}

.k-lp-community {
	margin-top:40px;
	margin-bottom:20px;
	.k-forum {
		margin:0 auto;
		width:100%;
		max-width:600px;
	}
}

.k-course-view-wrapper--unit_count_overflow {
	.k-learning-progression .k-learning-progression-unit-title {
		font-size:12px;
		padding:10px 5px;
	}

	.k-learning-progression-unit-header {
		padding:8px 4px;
		font-size:14px;
	}

	.k-learning-progression-unit-buttons {
		.v-btn {
			min-width:40px;
			padding:0 6px;
			font-size:10px;
		}
	}
}

</style>
