// Part of the SPARKL educational activity system, Copyright 2020 by Pepper Williams

// fn for returning the "value" for a grade -- what we use to order the grades
U.grade_value = function(grade) {
	return vapp.$store.state.grades.findIndex(g=>g==grade)
}

// fn for determining if the given object o, which must include a grade_low and grade_high parameter, "includes" the given grade
U.grades_include = function(o, grade) {
	// if object doesn't have a grade_low or grade_high, return false
	if (empty(o.grade_low) || empty(o.grade_high)) return false

	// try to get the index of the given grade, grade_low, and grade_high from the store's grades array; if any not found return false
	let grade_index = U.grade_value(grade)
	let grade_low_index = U.grade_value(o.grade_low)
	let grade_high_index = U.grade_value(o.grade_high)

	if (empty(grade_index)) return false
	if (empty(grade_low_index)) return false
	if (empty(grade_high_index)) return false

	return (grade_index >= grade_low_index && grade_index <= grade_high_index)
}

U.subject_color_mappings = {
	'Language Arts': 'la',
	'Mathematics': 'ma',
	'Mathematics 2023': 'ma',
	'Science': 'sc',
	'Social Studies': 'ss',
	'Fine Arts': 'fa',
	'Physical Education': 'pe',
	'Health': 'pe',
	'Computer Science': 'cs',
	'World Languages': 'wl',
	'CTAE': 'ct',
	'ESOL': 'es',
	'STEM': 'cs',
}

// fn for consistently coloring things that have titles that might include subject words -- e.g. classes or learning progressions
U.subject_tile_css = function(o) {
	if (o.color) {
		return 'k-list-color-' + o.color

	} else if (!empty(o.subject) && U.subject_color_mappings[o.subject]) {
		return 'k-list-color-' + U.subject_color_mappings[o.subject]

	} else if (!empty(o.title)) {
		return vapp.color_from_string(o.title)
	} else {
		return vapp.color_from_string(o + '')
	}

	// if (!empty(o.subject)) {
	// 	if (o.subject.indexOf('Math') > -1) return vapp.color_from_string('a')
	// 	if (o.subject.indexOf('Science') > -1) return vapp.color_from_string('b')
	// 	if (o.subject.indexOf('Social') > -1) return vapp.color_from_string('e')
	// 	if (o.subject.indexOf('Language Arts') > -1) return vapp.color_from_string('d')
	// 	return vapp.color_from_string(o.subject)
	//
	// } else if (!empty(o.title)) {
	// 	if (o.title.indexOf('Math') > -1) return vapp.color_from_string('a')
	// 	if (o.title.indexOf('Science') > -1) return vapp.color_from_string('b')
	// 	if (o.title.indexOf('Social') > -1) return vapp.color_from_string('e')
	// 	if (o.title.indexOf('Language Arts') > -1) return vapp.color_from_string('d')
	// 	return vapp.color_from_string(o.title)
	// }
	// return vapp.color_from_string(o + '')
}



class Learning_Progression {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'fully_loaded', false)

		sdp(this, data, 'lp_id', 0)
		sdp(this, data, 'case_identifier', '')
		sdp(this, data, 'course_code', '')
		sdp(this, data, 'state_course_code', '')
		sdp(this, data, 'title', '')
		sdp(this, data, 'subject_case_identifier', '')
		sdp(this, data, 'course_case_identifier', '')
		sdp(this, data, 'description', '')
		sdp(this, data, 'grade_low', '')
		sdp(this, data, 'grade_high', '')
		sdp(this, data, 'subject', '')
		sdp(this, data, 'color', '')
		sdp(this, data, 'active', 'yes')	// when set to 'no', only admins can see it
		sdp(this, data, 'featured', 0)	// for PD and PG collections: if 0, not featured; if > 0, it's a timestamp, and is shown at the top of the index page with most recently-featured collections first
		sdp(this, data, 'sequence', 0)	// for PD and PG collections: used to order collections

		// normalize subject capitalization, unless the first two letters are both capital (e.g. CTAE, PD, PG, SC, ESOL)
		if (this.subject.search(/^[A-Z][A-Z]/) == -1) {
			this.subject = U.capitalize_words(this.subject)
		}
		// if (this.subject != 'CTAE' && this.subject != 'PD') this.subject = U.capitalize_words(this.subject)

		this.terms = []
		this.units = []
		if (!empty(data.terms)) {
			for (let t of data.terms) {
				// legacy LP's have units saved in terms; deal with those here
				if (!empty(t.units)) {
					// assume these terms are quarters -- 9 weeks
					this.terms.push({
						title: t.title,
						duration: 9,
					})
					for (let u of t.units) {
						this.units.push(new LP_Unit(u))
					}
				} else {
					// "new" terms just have titles and duration; assume 9 weeks if not given
					let term = {}
					sdp(term, t, 'title', '')
					sdp(term, t, 'duration', 9)
					this.terms.push(term)
				}
			}
		}

		// now LP units, which used to be listed under terms
		if (!empty(data.units)) {
			for (let u of data.units) {
				this.units.push(new LP_Unit(u))
			}
		}

		// array of forum_id values for posts directly to this lp's community, and posts to this lp's subject-grade band community
		this.forum_ids = [
			'course_code-' + this.course_code,
			(this.grade_band() + ' ' + this.subject).toLowerCase().replace(/ /g, '-'),
			this.subject.toLowerCase().replace(/ /g, '-')
		]

		// set to true if a teacher has chosen to view activities for an origin teacher; used to determine whether or not to show activity counts on course "tiles"
		this.viewing_origin_teacher_activities = false

		// boolean to tell us whether we've loaded posts for the lp
		this.forum_loaded = false

		// resource_ids for resource collection(s) -- e.g. resources loaded via thin common cartridges
		sdp(this, data, 'resource_collection_ids', [])

		// resource_collections and pd_resources may also be sent to the client; but we don't save these
		sdp(this, data, 'resource_collections', [])
		sdp(this, data, 'pd_resources', [])

		// a resource_collection is a special resource, with:
		// resource_id: a unique id (e.g. the manifest identifier from the common cartridge file)
		// description: title of the collection (e.g. "HMH Science 3rd Grade")
		// type: 'resource_collection'
		// collection_json: a big json object (with property names shortened for loading efficiency) specifying a tree-folder structure and resources.
		// Each item in collection_json may contain:
		//		t: title
		//		c: array of child items
		//		f: for an array of child items, the identiFier (unique within the collection) for the "folder" (from the common cartridge file)
		//		r: resource_id of a resource (the resource will have its own table entry in the resources table)
		//		i: 1 if the resource is teacher-facing only
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)
		delete o.resource_collections
		delete o.pd_resources

		o.units = []
		for (let unit of this.units) {
			o.units.push(unit.copy_for_save())
		}

		delete o.viewing_origin_teacher_activities

		delete o.forum_loaded

		delete o.fully_loaded

		return o
	}

	user_is_lp_admin() {
		if (vapp.has_admin_right('lp.course.' + this.course_code)) return true

		// check based on subject
		if (vapp.has_admin_right('lp.subject.' + this.subject)) return true

		// check based on level
		if (U.grades_include(this, 'K') && vapp.has_admin_right('lp.level.Elementary')) return true
		if (U.grades_include(this, '1') && vapp.has_admin_right('lp.level.Elementary')) return true
		if (U.grades_include(this, '2') && vapp.has_admin_right('lp.level.Elementary')) return true
		if (U.grades_include(this, '3') && vapp.has_admin_right('lp.level.Elementary')) return true
		if (U.grades_include(this, '4') && vapp.has_admin_right('lp.level.Elementary')) return true
		if (U.grades_include(this, '5') && vapp.has_admin_right('lp.level.Elementary')) return true
		if (U.grades_include(this, '6') && vapp.has_admin_right('lp.level.Middle School')) return true
		if (U.grades_include(this, '7') && vapp.has_admin_right('lp.level.Middle School')) return true
		if (U.grades_include(this, '8') && vapp.has_admin_right('lp.level.Middle School')) return true
		if (U.grades_include(this, '9') && vapp.has_admin_right('lp.level.High School')) return true
		if (U.grades_include(this, '10') && vapp.has_admin_right('lp.level.High School')) return true
		if (U.grades_include(this, '11') && vapp.has_admin_right('lp.level.High School')) return true
		if (U.grades_include(this, '12') && vapp.has_admin_right('lp.level.High School')) return true

		// if we get to here, not an admin
		return false
	}

	grade_band() {
		if (U.grades_include(this, 'K')) return 'Elementary'
		if (U.grades_include(this, '1')) return 'Elementary'
		if (U.grades_include(this, '2')) return 'Elementary'
		if (U.grades_include(this, '3')) return 'Elementary'
		if (U.grades_include(this, '4')) return 'Elementary'
		if (U.grades_include(this, '5')) return 'Elementary'
		if (U.grades_include(this, '6')) return 'Middle School'
		if (U.grades_include(this, '7')) return 'Middle School'
		if (U.grades_include(this, '8')) return 'Middle School'
		if (U.grades_include(this, '9')) return 'High School'
		if (U.grades_include(this, '10')) return 'High School'
		if (U.grades_include(this, '11')) return 'High School'
		if (U.grades_include(this, '12')) return 'High School'
	}
}
window.Learning_Progression = Learning_Progression

// Legacy; no longer used
// class LP_Term {
// 	constructor(data) {
// 		if (empty(data)) data = {}
// 		sdp(this, data, 'title', '')
//
// 		this.units = []
// 		if (!empty(data.units)) {
// 			for (let u of data.units) {
// 				this.units.push(new LP_Unit(u))
// 			}
// 		}
// 	}
//
// 	copy_for_save() {
// 		let o = $.extend(true, {}, this)
// 		o.units = []
// 		for (let unit of this.units) {
// 			o.units.push(unit.copy_for_save())
// 		}
// 		return o
// 	}
// }
// window.LP_Term = LP_Term

class LP_Unit {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'lp_unit_id', 0)
		sdp(this, data, 'title', '')	// This will be, e.g., "Unit 1"

		// legacy units had durations in strings instead of #s; parse if necessary
		if (!empty(data.duration) && (data.duration+'').search(/([\d.]+) week(s)?/i) > -1) {
			this.duration = RegExp.$1 * 1
		} else {
			sdp(this, data, 'duration', 0)		// numeric duration expressed in weeks; can have decimal values (e.g. 4.5)
		}

		sdp(this, data, 'description', '')
		sdp(this, data, 'text', '')

		this.standards = []
		if (!empty(data.standards)) {
			for (let standard of data.standards) {
				this.standards.push(new LP_Unit_Standard(standard))
			}
		}

		// this structure marks parts (e.g. "folders") of resource collections that are to be highlighted for this unit
		// the structure is an object, with one member per collection (identified by the collection's resource_id),
		// which includes an array of resource "folder" id's (or potentially resource_ids from actual resources) that are to be included
		this.resource_collection_inclusions = {}
		if (!empty(data.resource_collection_inclusions)) {
			for (let rcid in data.resource_collection_inclusions) {
				this.resource_collection_inclusions[rcid] = data.resource_collection_inclusions[rcid].concat([])
			}
		}

		// this structure is for holding a tree structure for resources; currently used only for PD/PG/SC LPs
		if (!empty(data.resource_tree)) {
			this.resource_tree = $.extend(true, {}, data.resource_tree)
		} else {
			this.resource_tree = {
				folders: [{
					folder_id: 'top',
					title: '',
					parent_folder_id: '',
					seq: 0,
				}],
				folder_assignments: []	// {resource_id: 'xxx', parent_folder_id: 'xxx', seq: 0}
			}
		}

		// individual resources associated with the unit
		this.resources = []
		if (!empty(data.resources)) {
			for (let r of data.resources) {
				r = new Resource(r)
				this.resources.push(r)
			}
		}

		// lessons
		this.lessons = []
		if (!empty(data.lessons)) {
			for (let r of data.lessons) {
				r = new Lesson(r)
				this.lessons.push(r)
			}
		}

		// activities
		this.activities = []
		if (!empty(data.activities)) {
			for (let r of data.activities) {
				r = new Activity(r)
				this.activities.push(r)
			}
		}
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)

		delete o.lpa_component

		// remove full resource objects; attach resource_ids instead
		delete o.resources
		o.resource_ids = []
		for (let r of this.resources) {
			o.resource_ids.push(r.resource_id)
		}

		// remove full lesson objects; attach lesson_ids instead
		delete o.lessons
		o.lesson_ids = []
		for (let r of this.lessons) {
			o.lesson_ids.push(r.lesson_id)
		}

		// remove full activity objects; attach activity_ids instead
		delete o.activities
		o.activity_ids = []
		for (let r of this.activities) {
			o.activity_ids.push(r.activity_id)
		}
		
		for (let i = 0; i < this.standards.length; ++i) {
			o.standards[i] = o.standards[i].copy_for_save()
		}

		// remove resource_tree if there aren't any folder_assignments
		if (o.resource_tree.folder_assignments.length == 0) {
			delete o.resource_tree
		}

		return o
	}
}
window.LP_Unit = LP_Unit

// a LP standard has a case identifier, a case_item, associated text, and any number of associated resources
class LP_Unit_Standard {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'text', '')
		sdp(this, data, 'identifier', '')
		this.case_item = new CASE_Item(data.case_item)
		this.resources = []
		if (!empty(data.resources)) {
			for (let r of data.resources) {
				this.resources.push(new Resource(r))
			}
		}
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)

		// remove full resource objects; attach resource_ids instead
		delete o.resources
		o.resource_ids = []
		for (let r of this.resources) {
			o.resource_ids.push(r.resource_id)
		}

		return o
	}
}
window.LP_Unit_Standard = LP_Unit_Standard

class CASE_Item {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'case_item_id', 0)
		sdp(this, data, 'identifier', '')
		sdp(this, data, 'uri', '')

		sdp(this, data, 'humanCodingScheme', '')
		sdp(this, data, 'fullStatement', '')

		// recode CASE educationLevel to grade_low/grade_high for convenience
		sdp(this, data, 'grade_low', '')
		sdp(this, data, 'grade_high', '')
		if (empty(data.grade_low) && !empty(data.educationLevel)) {
			this.code_grades_from_education_level(data.educationLevel)
		}

		// rather than using separate CASE associations, we'll just use recursive arguments to code is_child_of relationships
		// however, note that for learning progressions, we don't currently "nest" CASE items in any case
		this.children = []
		if (!empty(data.children)) {
			for (let c of data.children) {
				this.children.push(new CASE_Item(c))
			}
		}
	}

	code_grades_from_education_level(el) {
		let gvl = 10000
		let gvh = -10000
		for (let g of el) {
			if (g == 'KG') g = 'K'
			else if (!isNaN(g*1)) g = g*1+''
			else {
				console.log('unknown grade: ' + g)
				continue
			}
			let gv = U.grade_value(g)
			if (gv < gvl) {
				gvl = gv
				this.grade_low = g
			}
			if (gv > gvh) {
				gvh = gv
				this.grade_high = g
			}
		}
	}
}
window.CASE_Item = CASE_Item
