<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<v-dialog v-model="dialog_open" :fullscreen="small_screen" persistent max-width="800px" overlay-opacity="0.9"><v-card class="k-search-results-card">
		<div class="k-search-wrapper-inner"><v-text-field @keyup="search_results_search_keypress" :dense="small_screen" background-color="#eee" outlined rounded hide-details clearable append-icon="fa fa-search" @click:append="do_search" :label="search_type.search_box_label" v-model="search_string" autofocus></v-text-field></div>
		<v-card-title v-show="search_executed"><b>Search Results: {{ search_type.results_title }}</b></v-card-title>
		<v-card-text>
			<div v-if="!search_executed" class="mt-8 text-center" style="font-size:18px" v-html="search_type.start_message"></div>
			<div v-if="search_executed&&search_results.length==0">No results...</div>
			<component v-for="(search_result, index) in search_results" :key="index" :is="'SearchResult_'+search_type.name" :search_result="search_result"></component>
		</v-card-text>
		<v-card-actions class="pl-4 pr-4 pb-4">
			<v-spacer></v-spacer>
			<v-btn color="primary" @click="$emit('dialog_done')">Done</v-btn>
		</v-card-actions>
	</v-card></v-dialog>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import SearchResult_communities_list from './SearchResult_communities_list'
export default {
	components: { SearchResult_communities_list },
	data() { return {
		dialog_open: true,
		search_executed: false,
		search_string: '',
		search_results: [],
	}},
	computed: {
		...mapState(['searchable_links', 'user_info', 'welcome_section_showing']),
		...mapGetters(['small_screen']),
		search_type() {
			if (this.$route.name == 'welcome' && this.welcome_section_showing == 'communities') return {
				name: 'communities_list',
				search_box_label: 'Search Community titles...',
				start_message: 'Enter search terms above to find a new Community to join.',
				results_title: 'Communities',
			}

			if (this.$route.name == 'community') return {
				name: 'community',
				search_box_label: 'Search Community announcements, resources, and posts...',
				placeholder_message: 'Searches for Community content have not yet been implemented.',
				results_title: 'Community Announcements, Resources, and Posts',
			}

			if (this.$route.name == 'learning_progression') return {
				name: 'learning_progression',
				search_box_label: 'Search for resources by keyword...',
				placeholder_message: 'safari_search',
				results_title: '...',
			}

			return {
				name: 'default',
				search_box_label: 'Search...',
				placeholder_message: 'Note that search will be contextual to where you are on the HenryConnects site: for example, if you’re currently viewing the Grade 3 Science Learning Progression, you will search for content related to this topic area.',
				results_title: '...',
			}
		},
	},
	created() {
	},
	mounted() {
	},
	watch: {
	},
	methods: {
		search_results_search_keypress(e) {
			if (e.key == 'Enter') {
				this.do_search()
			}
		},

		do_search() {
			if (empty(this.search_string)) return

			if (empty(this.search_type.placeholder_message)) {
				U.loading_start()
				U.ajax('search_' + this.search_type.name, {user_id:this.$store.state.user_info.user_id, search_string:this.search_string}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert('Error loading search results.')
						return
					}
					console.log('search results', result)
					this.search_results = result.search_results
					this.search_executed = true
				});

			} else {
				U.loading_start()
				setTimeout(()=>{
					U.loading_stop()
					this.$alert({'title': 'Search', text: this.search_type.placeholder_message})
				}, 250)
			}
		},
	}
}
</script>

<style lang="scss">
.k-search-results-card {
	.k-search-wrapper-inner {
		padding:20px 20px 0 20px;

		.theme--light.v-text-field--outlined fieldset {
			border:1px solid $primary-color;
		}
	}

	.v-card__text {
		color:#000!important;
	}

	.k-search-results-search_string {
		border-bottom:1px solid $secondary-color;
		padding:15px 0;
		font-size:1.2em;
	}

	.k-search-result-wrapper {
		border-bottom:1px solid $secondary-color;
		padding:15px;

		.k-search-result-details {
			margin-top:8px;
		}

		.k-search-result-action {
			margin-top:8px;
		}
	}
}

</style>
