U.googleTranslateElementInit = function() {
	// uncomment the below to cancel google translate functionality
	// $('#google_translate_element_wrapper').hide()
	// return

	console.log('GTI2')
	new google.translate.TranslateElement({
			pageLanguage: 'en', 
			includedLanguages: 'en,es,vi,fr,zh-CN,hi',
			// includedLanguages: 'ar,zh-CN,en,es',
			layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
			autoDisplay: false,
		}, 'google_translate_element'
	);
}
