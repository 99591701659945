<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-main-collection k-courseindex2-wrapper">
	<h2 class="k-main-collection-header k-page-title d-flex mb-4">
		<v-icon large color="primary" class="mr-4" style="margin-top:6px">fas fa-chalkboard</v-icon><b>Learning Progression Index</b>
		<v-spacer/>
		<v-btn color="secondary" class="k-tight-btn" @click="back_to_classes"><v-icon small class="mr-1">fas fa-arrow-left</v-icon>My Courses</span></v-btn>
		<v-menu eager bottom right><template v-slot:activator="{on}"><v-btn v-show="can_view_resource_report&&!small_screen" class="ml-4" v-on="on" small fab color="#999" dark><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template>
			<v-list dense>
				<v-list-item v-if="can_view_resource_report" @click="resource_report_showing=true"><v-list-item-icon><v-icon small>fas fa-table</v-icon></v-list-item-icon><v-list-item-title>View Resource Usage Reports</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</h2>

	<div v-show="initialized" class="d-flex align-center justify-center flex-wrap mb-4">
		<div class="k-courseindex2-control-wrapper">Show courses for:</div>
		<div class="k-courseindex2-control-wrapper"><v-select v-model="grade" :items="grades" label="Grade" solo hide-details dense class="k-lp-grade-menu" :menu-props="{maxHeight:700}"></v-select></div>
		<div class="k-courseindex2-control-wrapper"><v-select v-model="subject" :items="subjects" label="Subject" solo hide-details dense class="k-lp-subject-menu" :menu-props="{maxHeight:700}"></v-select></div>
		<div class="k-courseindex2-control-wrapper" style="min-width:300px"><v-text-field
			v-model="search_string"
			prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
			label="Search" single-line hide-details outlined dense background-color="#fff"
		></v-text-field></div>
		<!-- <v-spacer/> -->
	</div>

	<div v-show="initialized" class="k-courseindex2-collections-container" :style="collections_container_style">
		<div v-if="collection_categories.length==0" class="text-center"><b style="color:#666"><i>No available courses match the grade, subject, and/or search terms you entered.</i></b></div>

		<div v-for="(category) in collection_categories" :key="category.title" class="k-courseindex2-category-wrapper" :style="category_wrapper_style(category)" @click.stop="category_clicked(category)">
			<v-hover v-slot:default="{hover}"><div class="k-courseindex2-category-container" :class="category_container_class(category, hover)" :style="category_container_style(category)">
				<div class="k-courseindex2-category-title" :style="category_title_style(category)" v-html="category.title"></div>
				<div class="k-courseindex2-category-icon" :style="category_icon_style(category)"><img :src="subject_img(category.subject)"></div>
				<div class="k-courseindex2-subject-external-systems-btns" :style="subject_framework_btn_style(category)">
					<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" class="k-courseindex2-subject-es-btn k-courseindex2-subject-framework-btn" @click.stop="show_framework(category)"><v-icon :class="opened_subcats[category.title]?'mr-2':'mr-2'" small color="#fff">fas fa-map</v-icon>{{standards_btn_label(category)}}</div></template>Georgia Learning Standards for {{category.subject}}</v-tooltip>
					<!-- <v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" class="k-courseindex2-subject-es-btn k-courseindex2-subject-community-btn" @click.stop="show_community(category)"><v-icon :class="opened_subcats[category.title]?'':'mr-2'" small color="#fff">fas fa-message</v-icon>{{community_btn_label(category)}}</div></template>GaDOE Communities for {{category.subject}}</v-tooltip>
					<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" class="k-courseindex2-subject-es-btn k-courseindex2-subject-pd-btn" @click.stop="show_pd(category)"><v-icon :class="opened_subcats[category.title]?'':'mr-2'" small color="#fff">fas fa-graduation-cap</v-icon>{{pd_btn_label(category)}}</div></template>Georgia Learns - PD Hub</v-tooltip> -->
				</div>
				<div class="k-courseindex2-subcats-container" :style="subcats_container_style(category)" v-show="opened_category==category.title">
					<div v-for="(subcat, subcat_index) in category.subcats" :key="subcat.title" class="k-courseindex2-subcat" :class="subcat_button_class(category, subcat)" :style="subcat_style(category, subcat, subcat_index)" @click.stop="subcat_clicked(category, subcat)">
						<div v-html="subcat.title"></div>
					</div>
				</div>
				<div v-for="(subcat) in category.subcats" :key="subcat.title" class="k-courseindex2-subcat-collections-container" v-show="opened_subcats[category.title]==subcat.title" :class="subcat_collections_container_class(category, subcat)" :style="subcat_collections_container_style(category, subcat)">
					<div v-for="(collection) in subcat.collections" :key="collection.course_code" class="k-courseindex2-collection elevation-4" :class="collection_class(category, subcat, collection)" :style="collection_style(category, subcat, collection)" @click.stop="collection_clicked(category, subcat, collection)">
						<div class="k-courseindex2-shadow-text" v-html="lp_title(collection.title)"></div>
						<div class="k-courseindex2-collection-icons">
							<v-icon color="#000" small class="mr-0">fas fa-person-chalkboard</v-icon>
							<!-- <v-icon color="#000" small>fas fa-long-arrow-right</v-icon> -->
						</div>
						<div class="k-courseindex2-collection-code">{{collection.course_code}}</div>
					</div>
				</div>
			</div></v-hover>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	components: {  },
	data() { return {
		initialized: false,
		grade: '— All Grades —',
		subject: '— All Subjects —',
		search_string:'',
		search_terms: [],

		collection_categories: [],
		collection_category_update_needed: false,

		// debug_uuid: U.new_uuid(),

		// the following are set in reorganize_categories
		tile_width:200,
		grid_margin: 25,
		num_columns: 3,	
		collections_container_style: '',
	}},
	computed: {
		...mapState(['user_info', 'all_courses_loaded', 'all_courses', 'course_update_trigger', 'subjects', 'grades']),
		...mapGetters(['small_screen']),
		can_view_resource_report() {
			// system admins and users that are principals/vice-principals can view resource usage reports from here
			return this.user_info.role == 'admin' || this.$store.getters.user_is_principal_or_ap
		},
		opened_category: {
			get() { return this.$store.state.lst.courseindex_opened_category },
			set(val) { this.$store.commit('lst_set', ['courseindex_opened_category', val]) }
		},
		opened_subcats: {
			// opened_subcats[category.title] = null or subcat.title
			get() { return this.$store.state.lst.courseindex_opened_subcats },
			set(val) { this.$store.commit('lst_set', ['courseindex_opened_subcats', val]) }
		},
		grades() {
			let arr = ['— All Grades —']
			for (let grade of this.$store.state.grades) {
				let text
				if (grade == 'K') text = 'Kindergarten'
				else if (!isNaN(grade*1)) text = 'Grade ' + grade
				else text = grade

				arr.push({ value: grade, text: text})
			}
			return arr
		},
		subjects() {
			let arr = ['— All Subjects —']
			for (let subject in this.$store.state.subjects) {
				arr.push({ value: subject, text: subject})
			}
			return arr
		},

		// see watcher below
		component_showing() {
			return this.$store.state.welcome_section_showing == 'classes' && this.$store.state.my_classes_view == 'lpindex' && this.$store.state.lp_showing == 0
		},

		// note that tile_width, grid_margin, and num_columns are set in reorganize_categories
		tile_height() { return this.tile_width }, 
		tile_width_open() { return this.tile_width * 3 + (2 * this.grid_margin) },
		tile_padding() { return Math.round(this.tile_width / 12.5) },
		subcat_width() { return Math.round(this.tile_width *0.45) },
		cat_icon_large_width() { return this.tile_width / 2.5 },
		cat_icon_small_width() { return this.tile_width / 5 },
		collection_width() { return Math.round(this.tile_width / 1.67) },
	},
	watch: {
		// when the screen is resized or a course gets updated, *note* that we need to update collection categories...
		'$vuetify.breakpoint.width'() { 
			if (this.component_showing) this.set_collection_categories()
			else this.collection_category_update_needed = true
		},
		course_update_trigger() { 
			this.collection_category_update_needed = true
		},
		// then when the component is showing, call set_collection_categories to make sure everything fits right
		component_showing: {
			handler() { 
				if (this.component_showing && this.collection_category_update_needed) {
					this.set_collection_categories()
					this.collection_category_update_needed = false
				}
			}, deep:true, immediate:true
		},

		search_string() {
			if (empty(this.search_string)) {
				this.search_terms = []
			} else {
				// convert string to lower case and split on spaces
				this.search_terms = $.trim(this.search_string).toLowerCase().split(/\s+/)
			}
			this.set_collection_categories()
		},
		grade() { this.set_collection_categories() },
		subject() { this.set_collection_categories() },
	},
	created() {
		// console.log('creating CourseIndex2 ' + this.debug_uuid)
		vapp.courseindex2 = this
		if (!this.all_courses_loaded) {
			this.$store.dispatch('get_all_courses').then(()=>{
				this.$nextTick(()=>{
					this.initialize()
					// console.log('all_courses', this.all_courses)
				})
			}).catch(()=>{
				console.log('error in get_all_courses')
				this.back_to_classes()
			})
		} else {
			this.initialize()
		}
	},
	mounted() {
	},
	methods: {
		initialize() {
			// this will run after all courses are loaded
			setTimeout(x=>{
				this.set_collection_categories()
				this.initialized = true
			})
		},

		external_search(search_string) {
			this.search_string = search_string
		},

		back_to_classes() {
			this.$store.commit('set', ['my_classes_view', 'assignments'])
		},

		set_collection_categories() {
			if (empty(this.grade) && empty(this.subject) && this.search_terms.length == 0) return []

			// filter all courses by grade/subject/search_terms, as well as other criteria
			let arr = this.all_courses.filter(lp => {
				// we never show PD/PG/SC "courses" here
				if (lp.subject == 'PD' || lp.subject == 'PG' || lp.subject == 'SC') return false

				// when we first create a new LP to be entered, its title will be false
				if (lp.title == '') return false

				// for inactive lp's, only show to people explicitly authorized as viewers (this includes admins)
				if (lp.active == 'no') {
					if (!lp.user_is_lp_admin()) return false
				}

				// if all search_terms appear in the title, return true
				let tlc = lp.title.toLowerCase()
				for (let term of this.search_terms) {
					if (tlc.indexOf(term) == -1) return false
				}

				if (!empty(this.grade) && this.grade != this.grades[0] && !U.grades_include(lp, this.grade)) return false
				if (!empty(this.subject) && this.subject != this.subjects[0] && lp.subject != this.subject) return false

				return true
			})

			// sort courses
			arr.sort((a,b)=>{
				// put active courses before inactive
				if (a.active != 'no' && b.active == 'no') return -1 
				if (a.active == 'no' && b.active != 'no') return 1 

				// next sort by grade level
				let gd = U.grade_value(a.grade_low) - U.grade_value(b.grade_low)
				if (gd != 0) return gd

				// then by subject (not relevent here, since we categorize by subject)
				if (a.subject < b.subject) return -1
				if (b.subject < a.subject) return 1

				// then by title, using natural sort algorithm
				return U.natural_sort(a.title, b.title)
			})

			// create preliminary categories and subcats
			let carr = []
			let order = 0
			for (let subject in this.$store.state.subjects) {
				let cat = {
					original_order: order,
					color: this.$store.state.subjects[subject].color,
					title: subject,
					subject: subject,
					framework_identifier: this.$store.state.subjects[subject],
					// for now hard-code subcats
					subcats: [
						{ title: 'Elementary School', grade_low: 'PK', grade_high: '5', collections: []},
						{ title: 'Middle School', grade_low: '6', grade_high: '8', collections: []},
						{ title: 'High School', grade_low: '9', grade_high: '12', collections: []},
					],
				}

				carr.push(cat)
				++order
			}
			carr.push({
				original_order: order,
				color: '33,33,33',
				title: 'Other Courses',
				subject: 'other',
				framework_identifier: null,
				subcats: [
					{ title: 'Elementary School', grade_low: 'PK', grade_high: '5', collections: []},
					{ title: 'Middle School', grade_low: '6', grade_high: '8', collections: []},
					{ title: 'High School', grade_low: '9', grade_high: '12', collections: []},
				],
			})

			// add filtered courses to categories/subcats
			for (let collection of arr) {
				let cat = carr.find(x=>x.subject == collection.subject)
				if (!cat) cat = carr[carr.length-1]	// "Other Courses"
				// find subcat whose grades include the collection's grades
				// TODO: deal with CTAE/Arts where subcats aren't grade-based
				let subcat = cat.subcats.find(x=>{
					return U.grades_include(x, collection.grade_low, collection.grade_high, 'fully_include')
				})

				// If the collection fits in to a single grade band, push it and exit loop...
				if (subcat) {
					subcat.collections.push(collection)
					continue
				}

				// ...otherwise, add collection to all appropriate grade bands
				cat.subcats.forEach(x => {
					if (U.grades_include(x, collection.grade_low, collection.grade_high)) {
						x.collections.push(collection)
					}
				})
			}

			// remove subcats and cats that have no courses, and fill in additional info for each cat
			let fcarr = []
			let opened_subcats = {}
			for (let cat of carr) {
				// create opened_subcats val if necessary; inherit from existing value if there
				opened_subcats[cat.title] = this.opened_subcats[cat.title] ?? ''

				// remove subcats with no courses
				for (let i = cat.subcats.length-1; i >= 0; --i) {
					if (cat.subcats[i].collections.length == 0) {
						// console.log('remove empty subcat: ' + cat.title + ' / ' + cat.subcats[i].title)
						cat.subcats.splice(i, 1)
					}
				}
				// if at least one subcat is left over, include the collection
				if (cat.subcats.length > 0) {
					// TODO: inherit some things from previous incarnation
					cat.top = ''
					cat.left = ''
					cat.column = 0
					cat.row = 0

					fcarr.push(cat)

					// if exactly one subcat is left, open it
					if (cat.subcats.length == 1) opened_subcats[cat.title] = cat.subcats[0].title
				}
			}

			this.collection_categories = fcarr
			this.opened_subcats = opened_subcats

			// if only one category is left here, open it
			if (fcarr.length == 1) {
				this.opened_category = fcarr[0].title
			}

			// whenever we change categories, have to immediately reorganize categories
			this.reorganize_categories()
		},

		subject_img(subject) { 
			let img = vapp.subject_img[subject]
			if (img) return img
			return vapp.subject_img.misc
		},

		category_wrapper_style(category) {
			let s = ''
			s += sr('top:$1;', category.top)
			s += sr('left:$1;', category.left)
			return s
		},

		category_container_class(category, hover) {
			let s = ''
			// s += ` k-framework-color-${category.color}-dark`
			// s += ` k-list-color-${U.subject_color_mappings[category.title]}-lighter`
			s += ` k-list-color-${U.subject_color_mappings[category.title]}`
			if (this.opened_category == category.title) {
				s += ' k-courseindex2-category-open'
				if (this.opened_subcats[category.title]) s += ' k-courseindex2-category-subcategory-selected'
			}
			if (hover) s += ' k-courseindex2-category-hovered'
			return s
		},

		category_container_style(category) {
			let s = ''
			let w, h
			if (this.opened_category == category.title) {
				w = this.tile_width_open
				let rows = this.get_protruding_down_val(category) + 1
				h = this.tile_height * rows + ((rows - 1) * this.grid_margin)
			} else {
				w = this.tile_width
				h = this.tile_height
			}

			let br = Math.round(this.tile_width / 8)

			s += sr('width:$1;height:$2;padding:$3;border-radius:$4;', w+'px', h+'px', this.tile_padding+'px', br+'px')
			return s
		},

		category_icon_style(category) {
			let s = ''
			let l, t, w
			if (this.opened_category != category.title) {
				w = this.cat_icon_large_width
				l = sr('calc(100% - $1)', (w + this.tile_padding - 4)+'px')
				t = l
			} else {
				w = this.cat_icon_small_width
				l = (this.tile_padding - 4) + 'px'
				t = (this.tile_padding + 2) + 'px'
			}
			let br = Math.round(w / 6.67)
			s += sr('width:$1;height:$1;left:$2;top:$3;border-radius:$4;', w+'px', l, t, br+'px')

			return s
		},

		category_title_style(category) {
			let s = ''
			let fs = this.tile_width / 9
			s += sr('font-size:$1;', fs+'px')
			if (this.opened_category == category.title) {
				let pl = this.cat_icon_small_width + (this.tile_padding / 2 - 4)
				let w = this.tile_width_open - (category.subcats.length * this.subcat_width + (category.subcats.length + 1) * Math.round(this.grid_margin / 3)) - this.tile_padding*2
				s += sr('width:$1;padding-left:$2;', w+'px', pl+'px')
			}
			return s
		},

		subcats_container_style(category) {
			let s = ''
			let br = Math.round(this.tile_width / 12.5)
			s += sr('right:$1;top:$2;border-radius:$3', (this.tile_padding-4)+'px', (this.tile_padding-4)+'px', br+'px')
			return s
		},

		subcat_button_class(category, subcat) {
			let s = ''
			// s += U.subject_tile_css(category.subject) + '-medlight'	// this was taken out in nc-cureum
			if (this.opened_category == category.title && this.opened_subcats[category.title] == subcat.title) {
				s += ' k-courseindex2-subcat-selected'
			}
			return s
		},

		subcat_style(category, subcat, subcat_index) {
			let s = ''
			let w = this.subcat_width
			let h = this.subcat_width
			let p = Math.round(this.tile_width / 25)
			let m = Math.round(this.grid_margin / 3)
			let ml = (subcat_index == 0) ? m : m / 2
			let mr = (subcat_index == category.subcats.length-1) ? m : m / 2
			let fs = w / 6.67
			let br = Math.round(this.tile_width / 20)
			s += sr('width:$1;height:$2;margin:$3 $4 $3 $5;font-size:$6;border-radius:$7;padding:$8;', w+'px', h+'px', m+'px', mr+'px', ml+'px', fs+'px', br+'px', p+'px')
			return s
		},

		subcat_collections_container_class(category, subcat) {
			let s = ''
			if (this.opened_category == category.title && this.opened_subcats[category.title] == subcat.title) {
				s += ' k-courseindex2-subcat-collections-container-selected'
			}
			return s
		},

		subcat_collections_container_style(category, subcat) {
			let s = ''
			let t = Math.round(this.tile_width * 0.74)
			s += sr('top:$1;', t+'px')
			return s
		},

		subject_framework_btn_style(category) {
			let s = ''
			if (this.opened_category == category.title) {
				let t
				if (!this.opened_subcats[category.title]) {
					t = this.tile_height * 0.72
				} else {
					t = Math.round(this.tile_width * 0.74) - 40
				}
				s += sr('top:$1;', t+'px')
			}
			return s
		},

		standards_btn_label(category) {
			return 'Henry T&L Standards'
			if (this.opened_subcats[category.title]) return '' //  'ALCOS'
			else return 'Learning Standards'
			// else return 'Georgia Learning Standards'
		},

		community_btn_label(category) {
			if (this.opened_subcats[category.title]) return '' //  'ALCOS'
			else return 'Communities'
		},

		pd_btn_label(category) {
			if (this.opened_subcats[category.title]) return '' //  'ALCOS'
			else return 'Georgia Learns'
		},

		lp_title(s) {
			// add <wbr> tags after slashes
			s = s.replace(/\//g, '/<wbr>')
			return s
		},

		collection_class(category, subcat, collection) {
			let s = ''
			// if (empty(collection.color)) s += ` k-framework-color-${category.color}-darkest`
			// else s += ` k-list-color-${collection.color}-darkest`
			s += U.subject_tile_css(collection)
			// s += ` k-list-color-${U.subject_color_mappings[category.title]}`
			if (collection.active == 'no') {
				s += ' k-lp-inactive'
			}

			// if (!collection.cmap_specified) {
			// 	s += ' k-lp-cmap-unspecified'
			// }

			if (collection.title.length > 50 || U.longest_word_length(this.lp_title(collection.title)) > 18) {
				s += ' k-lp-tile-extra-long-title'
			} else if (collection.title.length > 30 || U.longest_word_length(this.lp_title(collection.title)) > 12) {
				s += ' k-lp-tile-long-title'
			}

			if (this.user_info.role == 'admin') {
				s += ' k-courseindex2-collection-code-visible'
			}

			return s
		},

		collection_style(category, subcat, collection) {
			let s = ''

			let w = this.collection_width
			let h = this.collection_width
			let m = Math.round(this.grid_margin / 6)
			let br = Math.round(this.tile_width / 20)
			let fs = w / 8
			let p = Math.round(this.tile_width / 25)
			s += sr('width:$1;height:$2;margin:$3;font-size:$4;border-radius:$5;padding:$6;', w+'px', h+'px', m+'px', fs+'px', br+'px', p+'px')
			return s
		},

		reorganize_categories() {
			// if (this.$vuetify.breakpoint.width < 0) return 100	// force an update whenever screen is resized

			// whenever this is called, put the categories in their original order
			this.collection_categories.sort((a,b)=>a.original_order-b.original_order)
			
			let container_width = $(this.$el).width()

			let max_tw = 200
			let min_tw = 160

			let tw = max_tw
			if ((container_width / 3) < (max_tw * 1.25)) {
				tw = Math.round((container_width / 3) / 1.25)
				if (tw < min_tw) tw = min_tw
			}

			this.num_columns = Math.floor(container_width / tw)
			this.grid_margin = Math.round((container_width - this.num_columns * tw) / (this.num_columns + 1))

			// if this would give us fewer than 4 columns, see if we can squeeze in 4
			if (this.num_columns < 4 && tw > min_tw && ((min_tw*1.1) * 4) < container_width) {
				tw = Math.round((container_width / 4) / 1.1)
				if (tw < min_tw) tw = min_tw
				this.num_columns = Math.floor(container_width / tw)
				this.grid_margin = Math.round((container_width - this.num_columns * tw) / (this.num_columns + 1))
			}


			if (this.grid_margin < (tw / 10)) {
				// this.num_columns -= 1
				// this.grid_margin = Math.round((container_width - this.num_columns * tw) / (this.num_columns + 1))
				tw = Math.round(tw * 0.9)
				this.grid_margin = Math.round((container_width - this.num_columns * tw) / (this.num_columns + 1))
			}

			this.tile_width = tw

			// console.log('xxx', container_width, this.tile_width, this.num_columns, this.grid_margin)

			let column = 0, row = 0, opened_cat, opened_column_start, opened_row_start, shift_opened_cat, blocked_cells
            for (let category of this.collection_categories) {
				category.column = column
				category.row = row

				// if we find the opened category, set opend_column_start and opened_row_start to the starting position of the opened category
				if (this.opened_category == category.title) {
					opened_cat = category
					opened_column_start = column
					opened_row_start = row
					// find out if if the opened category is going to have to move left; if so, adjust opened_column_start accordingly
					shift_opened_cat = Math.max(0, (column + 3) - this.num_columns)
					if (shift_opened_cat > 0) opened_column_start -= shift_opened_cat
				}

				++column; if (column >= this.num_columns) { column = 0; ++row; }

				// set initial positions, in case we don't have an opened category
				this.set_position(category, category.column, category.row)
			}

			if (opened_cat) {
				// if we have to shift the opened category left, use splices to shift it back in the list, so we make sure to encounter it at the right point in the grid
				if (shift_opened_cat > 0) {
					let index = this.collection_categories.findIndex(x=>x==opened_cat)
					this.collection_categories.splice(index, 1)
					this.collection_categories.splice(index - shift_opened_cat, 0, opened_cat)
				}

				// now position all the categories, taking the opened category's cells into account
				let protruding_down = this.get_protruding_down_val(opened_cat)

				column = 0
				row = 0
				for (let category of this.collection_categories) {
					// when we get to the opened category, set its position based on opened_column_start and opened_row_start
					if (category == opened_cat) {
						category.column = opened_column_start
						category.row = opened_row_start
						this.set_position(category, opened_column_start, opened_row_start)
						column += 3; if (column >= this.num_columns) { column = 0; ++row; }

						// set blocked_cells
						blocked_cells = []
						for (let i = opened_column_start; i < (opened_column_start+3); ++i) {
							blocked_cells[i] = []
							for (let j = opened_row_start; j < (opened_row_start + protruding_down + 1); ++j) {
								blocked_cells[i][j] = true
							}
						}
						continue
					}

					// once we've positioned the opened category, make sure we don't position subsequent categories in blocked_cells
					if (blocked_cells && blocked_cells[column] && blocked_cells[column][row]) {
						// increment column/rows until blocked_cells are cleared
						while (blocked_cells[column] && blocked_cells[column][row]) {
							++column; if (column >= this.num_columns) { column = 0; ++row; }
						}
					}

					// set category column/row and position
					category.column = column
					category.row = row
					this.set_position(category, column, row)
					++column; if (column >= this.num_columns) { column = 0; ++row; }
				}

				// if the category is protruding down...
				if (protruding_down > 0) {
					// then it may be going below the current row value; if so...
					if (row < blocked_cells[blocked_cells.length-1].length) {
						// set row to the bottom row of blocked_cells
						row = blocked_cells[blocked_cells.length-1].length
						// and if column isn't 0, adjust row down so that when we bump it up below it'll be correct
						if (column != 0) row -= 1
					}
				}
			}

			// set height to accommodate the bottom row; row is 0-indexed, so unless column is 0, adjust it up for the calculation below
			if (column != 0) row += 1
			this.collections_container_style = sr('height:$1;', (row * this.tile_width + (row+1) * this.grid_margin)+'px')
        },

		get_protruding_down_val(category) {
			// this returns the number of rows in the grid the given category "protrudes" down
			// if none of the category's subcategories are open, the value is 0
			if (!this.opened_subcats[category.title]) return 0

			// we have space for 5 collections across each row in the category;
			// if we have less than 10 (two rows of collections), we only need to protrude down one
			let opened_subcat = category.subcats.find(x=>x.title == this.opened_subcats[category.title])
			if (empty(opened_subcat)) return 0
			if (opened_subcat.collections.length <= 10) return 1
			// if we need 4 rows of collections, we need to protrude down two
			else if (opened_subcat.collections.length <= 15) return 2
			// if we need 5 rows of collections, we need to protrude down three
			else if (opened_subcat.collections.length <= 25) return 3
			// if we need more than 4 extra rows, the user will be able to scroll
			else return 4
		},

		set_position(category, column, row) {
			category.top = (row + 1) * this.grid_margin + (row * this.tile_height) + "px"
			category.left = (column + 1) * this.grid_margin + (column * this.tile_width) + "px"
		},

		category_clicked(category) {
			if (this.opened_category == category.title) this.opened_category = null
			else this.opened_category = category.title
			this.reorganize_categories()
		},

		subcat_clicked(category, subcat) {
			let val = extobj(this.opened_subcats)
			if (val[category.title] == subcat.title) val[category.title] = null
			else val[category.title] = subcat.title
			this.opened_subcats = val	// make sure we update the value in lst
			this.reorganize_categories()
		},

		collection_clicked(category, subcat, collection) {
			if (this.opened_category != category.title) {
				this.category_clicked(category)
				return
			}
			this.$store.commit('set', ['last_lp_list', 'index'])
			this.$router.push({ path: sr('/courses/lp/$1/0/0', collection.course_code) })
		},

		show_framework(category, collection) {
			// let data = { framework_identifier: this.subject_case_identifier }

			// // set hide_fn to hide the standards chooser if/when the editor is no longer visible
			// // let show_data = { hide_fn: ()=>{ return ($(vapp.course_editor?.$el).is(':visible') == false) } }
			// let show_data = {}

			// vapp.$refs.satchel.execute('show', show_data).then(()=>{
			// 	vapp.$refs.satchel.execute('load_framework', data).then(()=>{
			// 		vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
			// 			// if for some reason the user chose a different framework, save it as the subject_case_identifier
			// 			if (aligned_item.framework_identifier != this.subject_case_identifier) {
			// 				this.subject_case_identifier = aligned_item.framework_identifier
			// 			}
			// 			this.course_case_identifier = aligned_item.cfitem.identifier

			// 			// hide the chooser, because we only choose one item here
			// 			vapp.$refs.satchel.execute('hide')
			// 		})
			// 	})
			// })

			// return
			let item_identifier = category.framework_identifier
			if (collection?.course_case_identifier) item_identifier = collection.course_case_identifier

			let data = { 
				framework_identifier: category.framework_identifier, 
				item_identifier: item_identifier, 
				no_framework_reset: true,
			}

			if (item_identifier != category.framework_identifier) {
				data.selected_items = [item_identifier]
				data.limit_to_selected_items = 'children'
			}

			vapp.$refs.satchel.execute('show').then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
				})
			})
		},

		show_community(category) {
			let s = {
				'Language Arts': '2021-summer-literacy-conference',
				'Mathematics': 'mathematics',
				'Science': 'science',
				'Social Studies': 'social-studies',
				'Physical Education': 'health',
				'Health': 'health',
				'Computer Science': 'computer-science',
				'World Languages': 'world-languages/',
				'English Language Development': 'ela-esol',
				'Fine Arts: Dance': 'dance',
				'Fine Arts: Dramatic Arts/Theatre': 'theatre-1409949552',
				'Fine Arts: Music': 'music-community',
				'Fine Arts: Visual Art': 'visual-art',
				// 'CTAE': '',
			}[category.subject]

			// if (empty(s)) {
			// 	this.$alert('A communities link has not been configured for this subject.')
			// 	return
			// }

			let url = 'https://community.gadoe.org'
			if (!empty(s)) url += `/groups/${s}/`

			window.open(url, 'cureum-community')
		},
		
		show_pd(category) {
			let url = `https://inspire.gadoe.org/src/sso_pdhub.php?session_id=${U.session_id}&subject=${category.subject}`
			window.open(url, 'pd_hub')
			// this.$alert('This Georgia Learns link will be functional soon.')
			return
		},

	}
}
</script>

<style lang="scss">
.k-courseindex2-wrapper {
	justify-content: center;
	.k-lp-grade-menu {
		max-width:180px;
		margin-left:4px;
	}
	.k-lp-subject-menu {
		max-width:240px;
		margin-left:4px;
		margin-right:4px;
	}
	.k-lp-inactive {
		border-width:2px;
		border-style:dotted;
		filter: brightness(0.9);
		// border-color:#999;
	}
	.k-lp-cmap-unspecified {
		opacity:0.5;
	}
}
.k-courseindex2-control-wrapper {
	margin:2px 4px;
}

///////////////////////////////////////////  ADDED FROM CUREUM
@media (max-width: 576px) {
	.k-class-tile {
		width:160px!important;
		height:90px!important;

		img {
			width:22px;
		}

		.k-class-edit-btn {
			right:10px;
			bottom:4px;
		}
	}

	.k-collection-tile {
		width:100px!important;
		height:100px!important;
		border-radius:50px!important;
		font-size:12px!important;
		line-height:15px!important;
		// .k-class-tile-course-code {
		// 	position:absolute;
		// 	left:0px;
		// 	bottom:10px;
		// 	font-size:9px;
		// 	width:100%;
		// 	text-align:center;
		// }
	}
}

.k-resource-one-offs-holder {
	display: flex;
	align-items: flex-start;
	// justify-content: space-around;
	border-bottom:1px solid $secondary-color;
	padding-bottom:12px;
	margin-bottom:12px;
}

.k-resource-one-off {
	max-width:240px;
	border:1px solid #000;
	border-radius:12px;
	// background-color:rgba(255, 250, 240);
	background-color:#fff;
	font-size:16px;
	line-height:20px;
	margin:12px;
	padding:12px;
	cursor:pointer;

	img {
		border-radius:10px;
		width:calc(60% - 12px);
		margin-bottom:4px;
		// margin:12px;
	}
}

///////////////////////////////////////////////////
.k-courseindex2-collections-container-outer {

}

.k-courseindex2-collections-container-header {
	font-size:18px;
	padding-top:12px;
	margin-bottom:-8px;
}

.k-courseindex2-collections-container {	// xx mainContainer
	position: relative;
	// width: 90%;
	height: 1240px;		// set by style
	padding: 0px;
	// background-color:#999;
	// margin:-32px -20px 0 -20px;
}

.k-courseindex2-category-wrapper {	// xx subjectWrapper
	text-align:left;
	position: absolute;
	transition: left .25s, top .25s;
	cursor:pointer;
}

.k-courseindex2-category-container {
	position: relative;

	overflow: auto;

	padding: 16px;	// set by style
	margin: 0px;

	width: 200px;	// set by style
	height: 200px;	// set by style
	
	border-radius: 25px;	// set by style
	background-color: #999;
	color: white;

	box-shadow: 0 0 15px 1px rgba(0, 0, 0, .25);

	transition: width .25s, height .25s, margin .25s;
}

.k-courseindex2-category-title {
	font-size:24px;	// set by style
	line-height:1.2em;
	font-weight:900;
	text-shadow: 0.5px 0.5px 1px #0006;
	color:#fff;
}

.k-courseindex2-category-icon {
	position:absolute;
	top:calc(100% - 92px);	// set by style
	left:calc(100% - 92px);	// set by style
	width:80px;	// set by style
	height:80px;	// set by style
	border-radius:12px;	// set by style
	background-color:#fff;
	opacity:0.85;
	// background-color:rgba(255,255,255,0.85);
	transition: all .25s;
	img {
		width:100%; 
		height:100%;
		padding:6% 1% 1% 6%;
		border-radius:20%;
	}
}

.k-courseindex2-subject-external-systems-btns {
	display:none;
	position:absolute;
	top:24px;	// set by style
	left:12px;
}

.k-courseindex2-subject-es-btn {
	border-radius: 10px;
	background-color: rgba(0, 0, 0, .4);
	font-size:14px;
	padding:4px 12px;
	margin:0 4px;
	transition: all .25s;
}
.k-courseindex2-subject-es-btn:hover {
	background-color: rgba(0, 0, 0, .6);
}

.k-courseindex2-category-hovered { // not implemented originally
}

.k-courseindex2-category-open {
	width: 688px;	// set by style
	// z-index: 100;
	.k-courseindex2-subcats-container, .k-courseindex2-subcat {
		opacity:1;
	}
	.k-courseindex2-category-title {
		width:312px;	// set by style
		padding-left:44px;	// set by style
	}
	.k-courseindex2-category-icon {
		left:12px;	// set by style
		top:18px;	// set by style
		width:40px;	// set by style
		height:40px;	// set by style
		border-radius:6px;	// set by style
	}
	.k-courseindex2-subject-external-systems-btns {
		display:flex;
	}
}
.k-courseindex2-category-subcategory-selected {
	height: 444px;	// set by style
	.k-courseindex2-category-icon {
		// bottom:304px;
	}
	.k-courseindex2-subject-external-systems-btns {
		top:100px;	// set by style
		margin-left:-4px;
	}
}

.k-courseindex2-subcats-container {
	position: absolute;
	right: 12px;	// set by style
	top: 12px;	// set by style
	border-radius: 16px;	// set by style
	background-color: rgba(0, 0, 0, .1);

	opacity: 0.0;
	overflow: hidden;
	transition: opacity .25s;
	
	display: flex;
}


.k-courseindex2-subcat {
	width: 92px;	// set by style
	height: 92px;	// set by style
	border-radius: 10px;	// set by style
	margin: 6px;	// set by style
	padding: 8px;	// set by style
	// color:#000;
	font-weight:bold;

	font-size: 12px;	// set by style
	line-height:1.2em;

	background-color: rgba(0, 0, 0, .4);

	opacity: 0.0;
	transition: background-color .25s, opacity .75s;
}
.k-courseindex2-subcat:hover {
	background-color: rgba(0, 0, 0, .6);
}
.k-courseindex2-subcat-selected {
	background-color: rgba(0, 0, 0, .8);
}


.k-courseindex2-subcat-collections-container {
	position: absolute;
	top: 130px;	// set by style
	left: 4px;
	// margin: 5px;
	// padding: 5px;

	max-height: calc(100% - 150px);
	max-width: calc(100% - 40px);

	background-color: transparent;
	overflow: hidden;

	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 0px;
}

.k-courseindex2-subcat-collections-container-selected {
	overflow: visible;
	.k-courseindex2-subcat-collections-container {
		opacity:1;
	}
	.k-courseindex2-collection {
		opacity:1;
	}
}

.k-courseindex2-collection {
	position:relative;
	width: 120px;	// set by style
	height: 120px;	// set by style
	border-radius: 10px;	// set by style
	margin: 8px;	// set by style
	padding: 8px;	// set by style
	background-color:#222;
	// color:#000;
	font-weight:bold;

	font-size: 15px;	// set by style
	line-height:1.2em;
	border:2px solid rgba(255,255,255,0.5);

	opacity:0;
	transition: background-color .25s, opacity .75s;
}
.k-courseindex2-shadow-text {
	text-shadow: 0.5px 0.5px 2px #6666;
}
.k-courseindex2-collection:hover {
	// opacity:0.8;
	.k-courseindex2-collection-icons {
		opacity:0.8;
	}
}

.k-courseindex2-collection-open {
	// opacity: 1;
}

.k-courseindex2-collection-icons {
	position:absolute;
	right:8px;
	bottom:8px;
	background-color:rgba(255,255,255,1);
	border-radius:8px;
	padding:3px 6px 3px 6px;
	opacity:0.5
}

.k-courseindex2-collection-code {
	position:absolute;
	left:4px;
	bottom:0px;
	color:#fff;
	opacity:1;
	font-size:10px;
	display:none;
}

.k-courseindex2-collection-code-visible {
	.k-courseindex2-collection-code { display:block; }
}
</style>
