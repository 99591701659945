var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"k-page-wrapper"},[_c('h2',{staticClass:"k-page-title"},[_c('div',{staticClass:"float-right"},[_c('v-btn',{on:{"click":_vm.load_admin_user_list}},[_vm._v("Load / Reload Admin User List")])],1),_vm._v(" "),_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"primary"},on:{"click":_vm.return_to_admin_main}},[_vm._v("fas fa-cog")]),_vm._v("\n\t\tAdmin User Privileges\n\t")],1),_vm._v(" "),(_vm.admin_users)?_c('div',{staticStyle:{"clear":"both"}},[_c('div',{staticClass:"py-4 d-flex"},[_c('v-text-field',{staticStyle:{"flex":"0 1 450px"},attrs:{"prepend-inner-icon":"fa fa-search","clearable":"","clear-icon":"fa fa-times-circle","label":"Search","single-line":"","hide-details":"","outlined":"","dense":"","background-color":"#fff"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('v-btn',{staticClass:"ml-8",attrs:{"color":"primary"},on:{"click":_vm.new_user_clicked}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-plus")]),_vm._v(" Add New Admin User")],1)],1),_vm._v(" "),_c('v-data-table',{staticClass:"k-admin-users-table",attrs:{"light":"","headers":_vm.headers,"items":_vm.rows,"sort-by":['su','user'],"sort-desc":[true,false],"must-sort":true,"custom-filter":_vm.table_search_filter,"search":_vm.search,"hide-default-footer":"","footer-props":_vm.footer_options,"items-per-page":-1},scopedSlots:_vm._u([{key:"header.user",fn:function(ref){
var header = ref.header;
return [_vm._v("User ")]}},{key:"header.su",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("SU ")])]}}],null,true)},[_vm._v("Superuser Access")])]}},{key:"header.pd",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("PD ")])]}}],null,true)},[_vm._v("Edit Professional Development resource collections")])]}},{key:"header.pg",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("P/G ")])]}}],null,true)},[_vm._v("Edit Parent/Guardian announcements and resource collections")])]}},{key:"header.qs",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("QS ")])]}}],null,true)},[_vm._v("Update “QuickStart” search results")])]}},{key:"header.mimic_all",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Mimic All ")])]}}],null,true)},[_vm._v("Mimic All Users")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.edit_user(item.identifier)}}},[_c('v-icon',[_vm._v("fas fa-edit")])],1),_c('span',{domProps:{"innerHTML":_vm._s(item.user)}})],1),_vm._v(" "),_c('td',[(item.su)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-check")]):_vm._e()],1),_vm._v(" "),_c('td',[(item.pd)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-check")]):_vm._e()],1),_vm._v(" "),_c('td',[(item.pg)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-check")]):_vm._e()],1),_vm._v(" "),_c('td',[(item.qs)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-check")]):_vm._e()],1),_vm._v(" "),_c('td',[(item.mimic_all)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-check")]):_vm._e()],1),_vm._v(" "),_c('td',_vm._l((item.lps),function(lp){return _c('v-chip',{key:lp.code,staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v(_vm._s(lp.text))])}),1),_vm._v(" "),_c('td',_vm._l((item.coms),function(com){return _c('v-chip',{key:com.code,staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v(_vm._s(com.text))])}),1),_vm._v(" "),_c('td',_vm._l((item.pd_reps),function(com){return _c('v-chip',{key:com.code,staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v(_vm._s(com.text))])}),1)])]}}],null,false,2089078575)})],1):_c('div',{staticClass:"mt-12 mb-3"},[_c('i',[_vm._v("This area allows you to manage which HenryConnects users have administrative access to do such things as moderating community forums and author/edit Learning Progressions and Professional Development resources. To get started, click the “LOAD / RELOAD ADMIN USER LIST” button above.")])]),_vm._v(" "),(_vm.user_being_edited)?_c('AdminUserEdit',{attrs:{"admin_user":_vm.user_being_edited},on:{"editor_cancel":function($event){_vm.user_being_edited=null},"rights_edited":_vm.rights_edited}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }