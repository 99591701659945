import Vue from 'vue'
// Require Froala Editor js file.
import 'froala-editor/js/froala_editor.pkgd.min.js'

// require packages
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/code_beautifier.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/draggable.min.js'
import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/js/plugins/entities.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/help.min.js'
import 'froala-editor/js/plugins/image.min.js'
// import 'froala-editor/js/plugins/image_manager.min.js'	// x?
import 'froala-editor/js/plugins/inline_class.min.js'
import 'froala-editor/js/plugins/inline_style.min.js'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
// import 'froala-editor/js/plugins/quick_insert.min.js'	// x?
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/special_characters.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'	// x?

// Require Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/themes/dark.min.css'

// require package css files
import 'froala-editor/css/plugins/code_view.min.css'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/css/plugins/draggable.min.css'
import 'froala-editor/css/plugins/emoticons.min.css'
import 'froala-editor/css/plugins/file.min.css'
import 'froala-editor/css/plugins/fullscreen.min.css'
import 'froala-editor/css/plugins/help.min.css'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/css/plugins/image_manager.min.css'
import 'froala-editor/css/plugins/line_breaker.min.css'
// import 'froala-editor/css/plugins/quick_insert.min.css'
import 'froala-editor/css/plugins/special_characters.min.css'
import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/css/plugins/video.min.css'

// Import and use Vue Froala lib
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)

// custom buttons
import FroalaEditor from 'froala-editor';
window.FroalaEditor = FroalaEditor

FroalaEditor.DefineIconTemplate('lesson_marker_1_template', '<span style="font-size:15px;color:#5EA666;height:19px;min-width:20px;">⬤</span>');
FroalaEditor.DefineIcon('lesson_marker_1', {NAME: 'lesson_marker_1', template:'lesson_marker_1_template'});
FroalaEditor.RegisterCommand('lesson_marker_1', {
	title: 'Lesson Marker 1', focus: true, undo: true, refreshAfterCallback: true, callback: function() { this.html.insert('<span style="color:#5EA666">⬤</span>:') }
});

FroalaEditor.DefineIconTemplate('lesson_marker_2_template', '<span style="font-size:15px;color:#3B68AB;height:19px;min-width:20px;">⬤</span>');
FroalaEditor.DefineIcon('lesson_marker_2', {NAME: 'lesson_marker_2', template:'lesson_marker_2_template'});
FroalaEditor.RegisterCommand('lesson_marker_2', {
	title: 'Lesson Marker 2', focus: true, undo: true, refreshAfterCallback: true, callback: function() { this.html.insert('<span style="color:#3B68AB">⬤</span>:') }
});

FroalaEditor.DefineIconTemplate('lesson_marker_3_template', '<span style="font-size:15px;color:#513A7E;height:19px;min-width:20px;">⬤</span>');
FroalaEditor.DefineIcon('lesson_marker_3', {NAME: 'lesson_marker_3', template:'lesson_marker_3_template'});
FroalaEditor.RegisterCommand('lesson_marker_3', {
	title: 'Lesson Marker 3', focus: true, undo: true, refreshAfterCallback: true, callback: function() { this.html.insert('<span style="color:#513A7E">⬤</span>:') }
});

FroalaEditor.DefineIconTemplate('lesson_marker_4_template', '<span style="font-size:15px;color:#AA3C36;height:19px;min-width:20px;">⬤</span>');
FroalaEditor.DefineIcon('lesson_marker_4', {NAME: 'lesson_marker_4', template:'lesson_marker_4_template'});
FroalaEditor.RegisterCommand('lesson_marker_4', {
	title: 'Lesson Marker 4', focus: true, undo: true, refreshAfterCallback: true, callback: function() { this.html.insert('<span style="color:#AA3C36">⬤</span>:') }
});

FroalaEditor.DefineIconTemplate('lesson_marker_5_template', '<span style="font-size:15px;color:#E38046;height:19px;min-width:20px;">⬤</span>');
FroalaEditor.DefineIcon('lesson_marker_5', {NAME: 'lesson_marker_5', template:'lesson_marker_5_template'});
FroalaEditor.RegisterCommand('lesson_marker_5', {
	title: 'Lesson Marker 5', focus: true, undo: true, refreshAfterCallback: true, callback: function() { this.html.insert('<span style="color:#E38046">⬤</span>:') }
});

FroalaEditor.DefineIconTemplate('lesson_marker_6_template', '<span style="font-size:15px;color:#F2C749;height:19px;min-width:20px;">⬤</span>');
FroalaEditor.DefineIcon('lesson_marker_6', {NAME: 'lesson_marker_6', template:'lesson_marker_6_template'});
FroalaEditor.RegisterCommand('lesson_marker_6', {
	title: 'Lesson Marker 6', focus: true, undo: true, refreshAfterCallback: true, callback: function() { this.html.insert('<span style="color:#F2C749">⬤</span>:') }
});

FroalaEditor.DefineIconTemplate('insert_resource_template', '<span style="display:flex; line-height: 24px; border-radius: 8px; background-color: #795548; color: #fff; padding: 2px 8px; height: 28px; margin: 0 6px;"><span style="font-size: 24px; line-height: 24px; font-weight:normal; font-family:Arial; min-width: 0; margin-right: 2px; padding: 0; float: none; display: inline-block;">+</span><b style="font-family:Roboto; font-size:12px; margin-left:2px;">RESOURCE</b></span>');
FroalaEditor.DefineIcon('insert_resource', {NAME: 'insert_resource', template:'insert_resource_template'});
FroalaEditor.RegisterCommand('insert_resource', {
	title: 'Insert Resource',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function() {
		let fco = U.get_froala_component(this)
		if (fco.parent_component) fco.parent_component.froala_insert_resource_callback(fco)
	}
});

FroalaEditor.DefineIconTemplate('sparklfontsize', '<i class="fas fa-text-height" style="font-size:14px; color:#fff"></i>');
FroalaEditor.DefineIcon('insertsparklfontsize', {NAME: 'sparklfontsize', template:'sparklfontsize'});
FroalaEditor.RegisterCommand('sparklfontsize', {
	title: 'Font Size',
	type: 'dropdown',
	focus: true,
	undo: true,
	icon: 'insertsparklfontsize',
	refreshAfterCallback: true,
	options: {
		'smallest': 'Smallest',
		'smaller': 'Smaller',
		'normal': 'Normal',
		'larger': 'Larger',
		'largest': 'Largest',
	},
	callback: function (cmd, val) {
		let text = this.selection.text()
		let html = (val == 'normal') ? text : sr('<span class="k-fs-$1">$2</span>', val, text)
		this.html.insert(html)
	},
});

///////////////////////////////////////////////////////
// The following pattern allows us to better manipulate the froala editor functionality. to implement, we use the <froala-wrapper> tag like so:

// <froala-wrapper v-model="description" />
// <froala-wrapper :config="{}" z_index="2501" parameter="description" />
// <froala-wrapper :config="editor_config()" :parameter_object="course" parameter="description" />

// then we can reference the froala component and its surrounding component; one important thing this allows us to do is the following,
// which ensures that the modeled value will be updated after we've manipulated things in the editor
// (note that updateModel is a fn supplied by the official froala vue component)
// let fco = U.get_froala_component(editor)
// if (fco.froala_component) fco.froala_component.updateModel()

// this <froala-wrapper> method MUST be used for the image-insertion and paste "helpers" below to work properly

U.get_froala_component = function(source) {
	// source can either be a) the froala editor dom object (usually retrieved, directly or indirectly, from a froala event callback fn; see below)
	// or b) a selector referencing a dom object, or the dom object itself, that is a part of the froala-edited html (e.g. a link in the edited html)

	// the returned object will include:
	// froala_editor:  the froala editor dom object
	// froala_component: the <froala> vue component that wraps the froala_editor
	// froala_wrapper_component: the FroalaWrapper component (which in turn contains the <froala> component)
	// parent_component: the component that contains the FroalaWrapper component

	let o = {}
	if (typeof(source) == 'object' && !empty(source.$el)) {
		o.froala_wrapper_id = $(source.$el).closest('[data-froala_wrapper_id]').attr('data-froala_wrapper_id')
	} else {
		o.froala_wrapper_id = $(source).closest('[data-froala_wrapper_id]').attr('data-froala_wrapper_id')
	}

	// the FroalaWrapper component registers the froala_wrapper_id in the store (but if we haven't initialized a froala editor at all, froala_wrapper_components may not even exist)
	if (vapp.$store.state.froala_wrapper_components) {
		o.froala_wrapper_component = vapp.$store.state.froala_wrapper_components[o.froala_wrapper_id]
		if (empty(o.froala_wrapper_component)) {
			console.log('couldn’t find editor', o.froala_wrapper_id)
		} else {
			o.froala_component = o.froala_wrapper_component.$refs.froala_component
			o.parent_component = o.froala_wrapper_component.get_parent()
			o.froala_editor = o.froala_component.getEditor()
		}
	}
	// console.log(o)
	return o
}

// pass the froala editor into this fn to ensure that the <froala> vue component immediately updates the modeled parameter to the current value specified by the froala editor
// (it may seem like this shouldn't be necessary, but sometimes it is.)
U.update_froala_model = function(editor) {
	let fco = U.get_froala_component(editor)
	if (fco.froala_component) fco.froala_component.updateModel()
	else console.log('couldn’t update model...')
}
///////////////////////////////////////////////////////

///////////////////////////////////////////////////////////
// the following three fns together form a custom cleanup process we use along with the froala editor
// the first two are separated just in case we need to run them separately in the future, but we can just call clean_froala_pasted_text to do both
window.froala_paste_clear_style_class = function(x) {
	x.find('*').each(function() {
		let el = $(this)

		// add strong tags for font-weight bold and > 400
		let fw = el.css('font-weight')
		if (fw == 'bold' || fw*1 > 400) {
			if (this.tagName != 'STRONG') {
				// console.log('found bold: ' + el.html())
				el.wrapInner('<strong></strong>')
			}
		}

		// add em tags for font-style italic
		let fs = el.css('font-style')
		if (fs == 'italic') {
			if (this.tagName != 'EM') {
				// console.log('found italic: ' + el.html())
				el.wrapInner('<em></em>')
			}
		}

		if (this.tagName == 'B') {
			// exception for QuizEditor
			if (el.hasClass('k-correct-choice')) {
				el.replaceWith(el.html())
			} else {
				el.replaceWith('<strong>' + el.html() + '</strong>')
			}
		}
		if (this.tagName == 'I') {
			el.replaceWith('<em>' + el.html() + '</em>')
		}

		// remove style and class attributes
		el.removeAttr('style')
		el.removeAttr('class')
	})
	return x
}

window.froala_paste_clear_tags = function(x, line_break_tag) {
	// line_break_tag should be 'p' or 'br'

	// remove these tags altogether
	x.find('base,head,link,meta,style,title,area,map,script,canvas,noscript,del,ins').remove()

	// these too
	x.find('option,datalist,fieldset,meter,optgroup,option,output,progress,select,textarea').remove()

	// these too
	x.find('iframe,video,audio,track,embed,object,param,picture,source').remove()

	// remove all inputs
	x.find('input').remove()

	// for these tags, extract everything in them and put them directly in the dom
	x.find('body,address,article,aside,footer,header,main,nav,section').replaceWith(function() {
		return $(this).html()
	})

	// these too
	x.find('button,label,legend').replaceWith(function() {
		return $(this).html()
	})

	let s = x.html()

	// inline tags: preserve just the tags we want to save
	// for these we remove all attributes
	s = s.replace(/<((\/)?(em|strong|sub|sup))\b.*?>/ig, 'ZZZLTZZZ$1ZZZGTZZZ')
	// for these we preserve attributes
	s = s.replace(/<((\/)?(img|a)\b.*?)>/ig, 'ZZZLTZZZ$1ZZZGTZZZ')

	// tables
	s = s.replace(/<((\/)?(table|tr|td|th|thead|tbody))\b.*?>/ig, 'ZZZLTZZZ$1ZZZGTZZZ')

	// insert line_break_tag tags at the ends of block-level things; this results in line breaks where we want them
	s = s.replace(/(\s*<\/(p|div|li|figcaption|figure|pre|blockquote).*?>\s*)+/ig, 'ZZZLTZZZ' + line_break_tag + 'ZZZGTZZZ')

	// strip all other tags
	s = s.replace(/<(\/?)[a-z].*?>/ig, '')

	// put back the <'s we preserved
	s = s.replace(/ZZZLTZZZ/g, '<')
	s = s.replace(/ZZZGTZZZ/g, '>')

	// consolidate multiple p's/br's
	if (line_break_tag == 'p') {
		s = s.replace(/(<p>)+/g, '<p>')
	} else {
		s = s.replace(/(<br>)+/g, '<br>')
	}

	// eliminate line breaks prior to closing <td>/<th> tags
	s = s.replace(/((<(\/)?p>)|<br>)+(<\/(td|th)>)/ig, '$4')

	return s
}

window.clean_froala_pasted_text = function(clipboard_html, line_break_tag, flags) {
	if (empty(line_break_tag)) line_break_tag = 'p'
	if (!flags) flags = {}

	// try to preserve bold and italics
	let x = $(sr('<div>$1</div>', clipboard_html))
	x = window.froala_paste_clear_style_class(x)
	let s = window.froala_paste_clear_tags(x, line_break_tag)

	// if flags.remove_images is set, remove images
	if (flags.remove_images) {
		s = s.replace(/<img.*?>/g, ' ')
	}

	// at some point we were also doing this for "internal" pastes -- where we know we're pasting in html that was previously authored in our froala editor
	// we're currently *not* doing this...
	// // for some reason froala inserts spans in weird places...
	// let s = clipboard_html.replace(/<(\/?)span+.*?>/ig, '')

	return s
}

//////////////////////////////
// call like this:
// window.froala_focus(this.$refs.froala_component_ref)
// window.froala_focus(this.$refs.froala_component_ref[1])
// with a <froala-wrapper>, give the froala-wrapper a $ref of `froala_wrapper` (or something else), then use:
// window.froala_focus(this.$refs.froala_wrapper.$refs.froala_component)
window.froala_focus = function(froala_component) {
	// get editor from component
	let editor = froala_component.getEditor()
	// set focus
	editor.events.focus()
	// move cursor to the end
	editor.selection.setAtEnd(editor.$el.get(0))
	editor.selection.restore()
}

/* usage:
'image.beforeUpload': function (images) {
	return window.froala_image_before_upload_fn(this, images)
},

// note that for this to work we have to use a <froala-wrapper fn...
*/
window.froala_image_before_upload_fn = function(editor, images) {
	// note that images is analogous to the file object returned by the onChange handler of a file input
	let image_file = images[0]

	if (!image_file.type.startsWith('image/')) {
		vapp.$alert('The file you specified does not appear to be an image.')
		return
	}

	// for some reason this seems to get called for already-existing images as well as newly-pasted images
	let ei = editor.image.get()
	if (ei && (ei.attr('data-fr-image-pasted') != 'true' || ei.attr('data-cglt') == 'true')) {
		console.log('skipping already-processed image')
		return
	}
	console.log('processing image')

	// set to last-chosen css classes
	if (ei) ei.attr('data-cglt-fix-css', 'true')
	setTimeout(window.froala_fix_image_css, 10)

	vapp.$prompt({
		title: 'Choose Image Size',
		text: 'Choose an image size for your pasted image. Please note:<ul class="my-2"><li>Smaller images load faster, so please choose the smallest size appropriate for your needs.</li><li class="mt-1">After this step, you can drag-and-drop the image corners to set how big the image should appear on the page.</li></ul>',
		promptType: 'select',
		// selectOptions: [{value:'360', text: 'Small'}, {value:'500', text: 'Medium'}, {value:'640', text: 'Large'}, {value:'800', text: 'X-Large'}, {value:'full', text: 'Full-Size'}],
		// allowing for full-size images is too dangerous -- files could be *huge*
		selectOptions: [{value:'360', text: 'Small'}, {value:'500', text: 'Medium'}, {value:'640', text: 'Large'}, {value:'800', text: 'X-Large'}, {value:'1000', text: 'Max'}],
		initialValue: vapp.$store.state.lst.froala_image_size,
		acceptText: 'Select',
		focusBtn: true,		// focus on the accept btn when dialog is rendered
	}).then(size => {
		vapp.$store.commit('lst_set', ['froala_image_size', size])

		// use the create_image_data_url utility fn to convert the image file to a dataURL
		// typical file sizes for given max_width's: 500 (56815) - 600 (77939) - 668 (93107)
		U.create_image_data_url(image_file, {image_format: 'webp', max_width: size, compression_level:0.9, callback_fn: o=>{
			// use the returned img_url (dataURL) as the src for the img tag
			vapp.$inform('Pasted image size: ' + U.format_bytes(o.img_url.length))

			// console.log('callback', ei, o)
			// if ei is empty, insert a new img tag
			if (empty(ei) || ei.length == 0) {
				// this block is executed when we insert an image using the froala toolbar image btn
				let img_tag = `<img src="${o.img_url}" data-cglt="true" data-cglt-fix-css="true">`		//  style="width:${o.width/2}px"
				editor.html.insert(img_tag)
				setTimeout(window.froala_fix_image_css, 10)

			// else insert the dataURL as the src tag
			} else {
				// this block is executed when we copy/paste an image
				// ei.attr('style', `width:${o.width/2}px`)
				ei.attr('src', o.img_url)
				ei.attr('data-cglt', 'true')
				ei.attr('data-cglt-fix-css', 'true')
				ei.removeAttr('data-fr-image-pasted')
				setTimeout(window.froala_fix_image_css, 10)
			}

			// hide the img popup/uploading popup if open
			editor.popups.hideAll()
			setTimeout(x=>editor.popups.hideAll(), 10)
			setTimeout(x=>editor.popups.hideAll(), 100)

			U.update_froala_model(editor)
		}})
	}).catch(n=>{
		// if user cancels, remove the image
		ei.remove()
	}).finally(f=>{})

	// hide the img popup/uploading popup if open
	editor.popups.hideAll()
	setTimeout(x=>editor.popups.hideAll(), 10)
	setTimeout(x=>editor.popups.hideAll(), 100)

	return false
}

// hackish things to make pasted image appear the way we want
window.froala_fix_image_css = function() {
	// let jq = $('[data-fr-image-pasted],[data-cglt-fix-css]')
	let jq = $('[data-cglt-fix-css]')
	// console.log('froala_fix_image_css', jq.length)

	// hide the image-resizer div (the square that lets you resize the image), since you can't use it until we've finished the paste
	$('.fr-image-resizer.fr-active').remove()

	jq.each(function(index) {
		// Froala image classes:
		// fr-dib == block; fr-dii = inline
		// fr-fil == left-align; fr-fir = right-align; neither for center align
		// add fr-draggable to make it draggable(?)

		// set image width to the last-saved % width, if we have one (see FroalaWrapper)
		if (vapp.$store.state.lst.froala_image_scaled_size) {
			$(this).css('width', vapp.$store.state.lst.froala_image_scaled_size + '%')
		}

		// set classes to the last-chosen values
		let cls = $(this).attr('class')
		// first remove all the froala classes
		cls = cls.replace(/(fr-dib|fr-dii|fr-fic|fr-fil|fr-fir|fr-draggable)/g, '')
		// fr-fic seems to always be added by froala, even though it doesn't do anything
		cls += ' fr-fic'
		// add block or inline
		cls += (vapp.$store.state.lst.froala_image_display == 'block') ? ' fr-dib' : ' fr-dii'
		/// add left or right if align isn't center
		if (vapp.$store.state.lst.froala_image_align != 'center') cls += ` fr-fi${vapp.$store.state.lst.froala_image_align[0]}`
		// add draggable
		cls += ' fr-draggable'
		cls = $.trim(cls)
		// console.log(cls)
		$(this).attr('class', cls)

		// remove the fix-css attribute, so we don't do this again
		$(this).removeAttr('data-cglt-fix-css')
	})
}

/* usage:
'paste.afterCleanup': function (clipboard_html) {
	return window.froala_paste_after_cleanup_fn(this, clipboard_html)
},
*/
window.froala_paste_after_cleanup_fn = function(editor, clipboard_html) {
	// let plain_html = U.html_to_text(clipboard_html)
	// let equivalent = (clipboard_html == plain_html)
	// console.log('froala_before_cleanup_html', window.froala_before_cleanup_html)
	// console.log('after', clipboard_html)
	// console.log('equivalent: ' + equivalent)
	
	// editor.enter should tell us if we're using <br> or <p> for linebreaks
	let line_break_tag = (editor.opts.enter == FroalaEditor.ENTER_BR) ? 'br' : 'p'

	let fn = function(clipboard_html) {
		if (vapp.$store.state.lst.froala_paste_mode == 'normal') {
			clipboard_html = window.clean_froala_pasted_text(clipboard_html, line_break_tag)
		} else if (vapp.$store.state.lst.froala_paste_mode == 'plain') {
			// console.log(1, clipboard_html)
			// make sure there is a \n after all block tags and <br> tags
			clipboard_html = clipboard_html.replace(/(<\/(p|h1|h2|h3|h4|h5|h6|li|pre|blockquote|td|button)>)(\n)?/g, '$1\n')
			clipboard_html = clipboard_html.replace(/(<br>)(\n)?/g, '\n')

			// console.log(2, clipboard_html)
			// replace \n's with a string we can find again below
			clipboard_html = clipboard_html.replace(/\n/g, 'XXXRETURNXXX')

			// console.log(3, clipboard_html)
			// convert to plain text, then replace that string with <br>'s
			clipboard_html = U.html_to_text(clipboard_html).replace(/XXXRETURNXXX/g, '<br>')

			// for spaces immediately following br's, use nbsp's
			clipboard_html = clipboard_html.replace(/(<br>)(\s+)/g, ($0, $1, $2) => {
				let s = '<br>'
				for (let i = 0; i < $2.length; ++i) s += '&nbsp;'
				return s
			})
			// console.log(4, clipboard_html)
		}

		let html = editor.html.get().replace(/<span[^>]*?class="k-pasted-text"[^>]*?>xxx<\/span>/, clipboard_html)
		editor.html.set(html)
	}

	// if text has no formatting, just paste
	if (clipboard_html == U.html_to_text(clipboard_html) || clipboard_html.search(/(\bstyle=)|(\bclass=)/) == -1) {
		return clipboard_html

	// look for tell-tale signature of pasting from froala to froala; if this is found, do our standard cleanup
	} else if (clipboard_html.search(/<span style="[^"]*\bsparkl\b/) > -1) {
		return window.clean_froala_pasted_text(clipboard_html, line_break_tag)

	} else {
		// unless store.show_paste_formatting_options is true, just use  our "normal" version, where we do some cleanup but preserve basic formatting
		// vapp.$store.state.show_paste_formatting_options = true
		if (vapp.$store.state.show_paste_formatting_options !== true) {
			// console.log('1: ' + clipboard_html)
			clipboard_html = window.clean_froala_pasted_text(clipboard_html, line_break_tag)
			// console.log('2: ' + clipboard_html)

			return clipboard_html
			// U.update_froala_model(editor)

		} else {
			vapp.$prompt({
				title: 'Paste Text',
				text: 'Choose an option for formatting the pasted text:',
				promptType: 'select',
				selectOptions: [{value:'asis', text: 'Preserve all html formatting (not recommended)'}, {value:'normal', text: 'Preserve basic formatting (e.g. bold and italics)'}, {value:'plain', text: 'Discard all formatting (paste as plain text)'}],
				initialValue: vapp.$store.state.lst.froala_paste_mode,
				acceptText: 'Paste',
				// hideCancel: true,
				focusBtn: true,
			}).then(mode => {
				// clear the dummy `k-pasted-text` stage out of the undo stack, so that if the user undo-s, they won't go back to having the dummy div in the editor
				editor.undo_stack.pop()

				vapp.$store.commit('lst_set', ['froala_paste_mode', mode])
				fn(clipboard_html)
				U.update_froala_model(editor)
				
			}).catch(n=>{
				editor.undo_stack.pop()

				let html = editor.html.get().replace(/<span[^>]*?class="k-pasted-text"[^>]*?>xxx<\/span>/g, '')
				editor.html.set(html)
				U.update_froala_model(editor)
			}).finally(f=>{})

			return '<span class="k-pasted-text" style="display:none">xxx</span>'
		}
	}
}

///////////////////////////////////////////////////////////////
// this is a "standard" froala_config, used by the FroalaWrapper component if a config isn't supplied
U.get_froala_config = function(params) {
	// params could include, e.g.:
	// placeholderText
	// heightMin
	// heightMax
	// toolbarInline: true,
	if (empty(params)) params = {}

	let config = {
		key: vapp.$store.state.froala_key,
		placeholderText: '',
		// charCounterCount: true,
		attribution: false,
		quickInsertEnabled: false,
		theme: 'light',
		tabSpaces: 4,	// make it so that tapping the tab key actually inserts spaces, rather than moving between input items
		paragraphFormat: {
			N: 'Normal',
		    H1: 'Heading 1',
		    H2: 'Heading 2',
		    H3: 'Heading 3',
			BLOCKQUOTE: 'Block Quote',
		    PRE: 'Code',
		},
		paragraphFormatSelection: true,
		htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allow', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap',
			'onclick',
		],
		toolbarButtons: {
			moreRich: {buttons: ['bold', 'italic', 'insertLink', 'insertImage', 'insertVideo', 'emoticons', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'align', 'formatOL', 'formatUL', 'outdent', 'indent'], buttonsVisible: 5},	// , 'quote', 'outdent', 'indent'
			moreMisc: {buttons: ['paragraphFormat', 'insertTable', 'specialCharacters', 'clearFormatting', 'html', 'fullscreen'], buttonsVisible: 0, align:'right'}
		},
		imageEditButtons: ['imageDisplay', 'imageAlign', 'imageStyle', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageAlt', 'imageSize', 'imageReplace', 'imageRemove'],	// , 'imageCaption' doesn't seem to work consistently
		imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
		linkInsertButtons: ['linkBack'],
		videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
		videoEditButtons: ['videoDisplay', 'videoAlign', 'videoSize', 'videoReplace', 'videoRemove'],

		imageDefaultWidth: 0,			// when first inserted, don't explicitly set the image's size
		imageResizeWithPercent: true,	// when you resize, specify size with percent instead of pixels
		imageRoundPercent: true,		// round to integer when resizing
		// we remember the last-chosen display and align properties the user has chosen
		imageDefaultDisplay: vapp.$store.state.lst.froala_image_display,
		imageDefaultAlign: vapp.$store.state.lst.froala_image_align,

		// this makes it so that if a user enters an image url manually, we just use the image url as the img src, rather than uploading the image
		imageUploadRemoteUrls: false,

		// note that zIndex is set in FroalaWrapper.vue
		// note that commands.after is set in FroalaWrapper.vue
	}

	return $.extend(config, params)
}

///////////////////////////////////////////////////////////
// fn for clearing extra empty paragraphs or breaks at the ends of froala-entered text
window.trim_froala_text = function(html) {
	// console.log('trim_froala_text (start)', html)

	// clear ` id="isPasted"`
	html = html.replace(/\s+id="isPasted"/g, '')

	// clear 'undefined' tags (this shouldn't be necessary once the froala 3.2.7 ENTER_BR bug is fixed)
	html = html.replace(/<\s*(\/?)undefined\s*>/g, '')

	html = html.replace(/^((\s|\&nbsp;)*<br>(\s|\&nbsp;)*)*([\s\S]*?)((\s|\&nbsp;)*<br>(\s|\&nbsp;)*)*$/, '$4')

	// replace singleton p's both before and after replacing empty p's with closing tags
	html = html.replace(/^((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+/, '<p>')
	html = html.replace(/((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+$/, '')

	html = html.replace(/^((\s|\&nbsp;|<br>)*<p>(\s|\&nbsp;|<br>)*<\/p>(\s|\&nbsp;|<br>)*)*([\s\S]*?)((\s|\&nbsp;|<br>)*<p>(\s|\&nbsp;|<br>)*<\/p>(\s|\&nbsp;|<br>)*)*$/, '$5')

	html = html.replace(/^((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+/, '<p>')
	html = html.replace(/((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+$/, '')

	// console.log('trim_froala_text (end)', html)

	return $.trim(html)
}
