<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-page-wrapper">
	<h2 class="k-page-title">
		<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-cog</v-icon>
		Resource Usage Report
	</h2>

	<div>
    <v-container fluid style="clear:both">
		<div class="d-flex align-end mb-6">
			<div>
				<div>Load data for:</div>
				<div style="width:280px" class="mt-1"><v-select v-model="report_interval" :items="interval_list" solo hide-details label="" @input="interval_changed"></v-select></div>
			</div>
			<div v-if="report_interval==='custom'" style="width:300px" class="ml-2"><v-menu v-model="interval_chooser_menu_showing" :close-on-content-click="false" transition="scale-transition" offset-y min-width="300px">
				<template v-slot:activator="{on}"><v-text-field background-color="#f8f8f8" solo hide-details v-model="interval_dates_display" label="Start Date – End Date" prepend-inner-icon="fas fa-calendar-week" readonly v-on="on"></v-text-field></template>
				<v-date-picker v-model="interval_dates" no-title scrollable range>
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="interval_chooser_menu_showing=false">Done</v-btn>
				</v-date-picker>
			</v-menu></div>
			<div class="ml-8">
				<div>Show resource usage by:</div>
				<div style="width:280px" class="mt-1"><v-select v-model="breakdown_by" :items="breakdown_by_options" label="" solo hide-details @input="update_headers" class="k-ru-breakout-menu"></v-select></div>
			</div>
			<v-btn class="ml-8 mb-1" v-if="resource_usage_rows.length==0" color="primary" @click="load_admin_resource_usage">Load Report Data <v-icon small class="ml-2">fas fa-arrow-circle-right</v-icon></v-btn>
		</div>

		<div class="mb-1">Filter results by:</div>
		<v-row>
			<v-col sm="2.5">
				<v-select v-model="grade" :items="filter_grades" @input="filter_changed('grade')" label="Grade" solo outlined dense class="k-ru-filter-grade-menu"></v-select>
			</v-col>
			<v-col sm="2.5">
				<v-select v-model="subject" :items="filter_subjects" @input="filter_changed('subject')" label="Subject" solo outlined dense class="k-ru-filter-subject-menu"></v-select>
			</v-col>
			<v-col sm="2.5">
				<v-autocomplete v-model="lp" :items="filter_lps" @input="filter_changed('lp')" label="Learning Progression" solo outlined dense class="k-ru-filter-lp-menu"></v-autocomplete>
			</v-col>
			<v-col sm="2.5">
				<v-autocomplete v-model="school" :items="filter_schools" @input="filter_changed('school')" label="School" solo outlined dense class="k-ru-filter-school-menu"></v-autocomplete>
			</v-col>
			<v-col sm="2.5">
				<v-autocomplete v-model="staff" :items="filter_staff" @input="filter_changed('staff')" label="Staff" solo outlined dense class="k-ru-filter-staff-menu"></v-autocomplete>
			</v-col>
		</v-row>
    </v-container>
	</div>

	<div v-if="resource_usage_rows.length>0" style="clear:both">
		<div class="py-4 d-flex">
			<v-spacer/>
			<v-text-field
				v-model="raw_search"
				prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
				label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 600px"
				@keyup="search_keyup"
				@click:prepend-inner="search=raw_search"
				@click:clear="search=''"
			></v-text-field>
			<v-spacer/>
		</div>

		<div class="mb-4 d-flex align-center">
			<div class="pa-2 text-center light-blue lighten-4 elevation-2" style="border-radius:8px; flex:1 0 auto;"><b>Totals:</b>
				<span class="pl-4">Staff resource access count: <b>{{this.total_staff_access_cnt.toLocaleString()}}</b></span>
				<span class="pl-8" v-if="show_stu_cnt">Student resource access count: <b>{{this.total_student_access_cnt.toLocaleString()}}</b></span>
				<span class="pl-8">GC adds count: <b>{{this.total_gc_add_cnt.toLocaleString()}}</b></span>
			</div>
			<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-4" small fab color="primary" @click="download_report"><v-icon>fas fa-download</v-icon></v-btn></template><div class="text-center">Download report data<br>to CSV file</div></v-tooltip>
		</div>

		<v-data-table light
			:headers="headers"
			:items="filtered_report_rows"
			:sort-by="['staff_access_cnt']"
			:sort-desc="[true]"
			:must-sort="true"
			:footer-props="footer_options"
			class="k-admin-resource-usage-table"
		>
			<template v-slot:item="{ item }"><tr>
				<td class="text-left" v-html="item.breakout_0"></td>
				<td v-if="num_breakout_columns>1" class="text-left" v-html="item.breakout_1"></td>
				<td v-if="num_breakout_columns>2" class="text-left" v-html="item.breakout_2"></td>
				<td v-if="num_breakout_columns>3" class="text-left" v-html="item.breakout_3"></td>
				<td class="text-center">{{item.staff_access_cnt}}</td>
				<td v-if="show_stu_cnt" class="text-center">{{item.student_access_cnt}}</td>
				<td class="text-center">{{item.gc_add_cnt}}</td>
			</tr></template>
		</v-data-table>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

// principal for testing: yvette.christian
// assistant principal: jamilla.martin

export default {
	components: { },
	props: {
		course_code: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		resource_usage_rows: [], // from ajax service
		report_rows: [],
		data_is_loaded: false,
		report_interval: 'custom',
		interval_list: [
			{value:'custom', text: 'Choose Report Dates'},
			{value:'day', text: 'Today (since 3:00 AM)'},
			{value:'week', text: 'This Week (since Saturday 3:00 AM)'},
			{value:'fortnight', text: 'Last 14 days'},
			// {value:'term', text: 'This Term'},
			// {value:'year', text: 'This Academic Year'},
		],
		interval_dates: ['',''],
		interval_dates_display: '',
		interval_chooser_showing: false,
		interval_chooser_menu_showing: false,

		show_stu_cnt: true,
		num_breakout_columns: 0,
		headers: [],
		breakdown_by_options: [
			{ text: 'School', value: 'school' },
			{ text: 'Staff Member', value: 'user_email' },
			{ text: 'Learning Progression/PD Collection', value: 'lp_title' },
			{ text: 'Resource', value: 'resource_title' },
		],
      	breakdown_by: '',

		lp_list: [], // from ajax service
		school_list: [], // from ajax service
		department_list: [],	// compiled from data
		staff_list: [],
		grade: '- All Grades -',
		subject: '- All Subjects -',
		lp: '- All LPs/Collections -',
		school: '- All Schools/Departments -',
		staff: '- All Staff -',

		footer_options: {
			itemsPerPageOptions: [50,50,100,-1],
		},

		total_staff_access_cnt: 0,
		total_student_access_cnt: 0,
		total_gc_add_cnt: 0,

		// since search filtering can take a while, use raw_search as the auto-updated value; then transfer to search when the user hits the enter key or clicks the search icon
		raw_search: '',
		search: '',

		limit_to_courses: [],
		limit_to_schools: [],
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters(['user_is_principal_or_ap']),
		filter_grades() {
			let arr = ['- All Grades -']
			for (let grade of this.$store.state.grades) {
				let text
				if (grade == 'PK') text = 'Pre-K'
				else if (grade == 'K') text = 'Kindergarten'
				else if (!isNaN(grade*1)) text = 'Grade ' + grade
				else text = grade

				arr.push({ value: grade, text: text})
			}
			return arr
		},
		filter_subjects() {
			let arr = ['- All Subjects -']
			for (let subject in this.$store.state.subjects) {
				arr.push({ value: subject, text: subject})
			}
			return arr
		},
		// update lp list as grades or subject filters changed
		filter_lps() {
			let arr = ['- All LPs/Collections -']
			let lp_arr = this.lp_list

			if (this.subject !== '- All Subjects -') lp_arr = lp_arr.filter(lp => lp.subject == this.subject)
			if (this.grade !== '- All Grades -') {
				let g = (this.grade === 'K') ? 0 : this.grade*1
				lp_arr = lp_arr.filter(lp => g >= lp.grade_low && g <= lp.grade_high)
			}

			lp_arr.sort((a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0))
			for (let lp of lp_arr) {
				// if this user can't edit the lp...
				if (!vapp.is_lp_admin(lp)) {
					// then unless the user is a principal or ap...
					if (!this.user_is_principal_or_ap) {
						// don't let them see this lp's results
						continue
					}
				}
				arr.push({ value: lp.course_code, text: lp.title})
			}

			return arr
		},
		filter_schools() {
			let arr = ['- All Schools/Departments -']
			for (let school of this.school_list) {
				// skip the ones from this list that start with 'Z'
				if (school.school_name.indexOf('Z') == 0) continue

				// if the user is a principal or ap and isn't a site admin...
				if (this.user_is_principal_or_ap && this.user_info.role != 'admin') {
					// only let them see their school(s)
					let scl = school.school_name.toLowerCase()
					if (!this.user_info.district_department.find(x=>x.toLowerCase() == scl)) {
						continue
					}

					// and set the school menu to the first one we find
					if (this.school == '- All Schools/Departments -') {
						this.school = school.school_name
					}
				}

				arr.push({ value: school.school_name, text: school.school_name })
			}

			// add additional values discovered in the data (mostly departments; possibly also things like "Advanced Studies")
			for (let department of this.department_list) {
				if (!arr.find(x=>x.text == department)) {
					// if the user is a principal or ap and isn't a site admin...
					if (this.user_is_principal_or_ap && this.user_info.role != 'admin') {
						// only let them see their school(s)
						let scl = department.toLowerCase()
						if (!this.user_info.district_department.find(x=>x.toLowerCase() == scl)) {
							continue
						}

						// and set the school menu to the first one we find
						if (this.school == '- All Schools/Departments -') {
							this.school = department
						}
					}

					arr.push({ value: department, text: department })
				}
			}

			arr.sort((a, b) => a.text < b.text ? -1 : (a.text > b.text ? 1 : 0))

			return arr
		},
		filter_staff() {
			let arr = ['- All Staff -']

			for (let staff of this.staff_list) {
				if (!staff.user_email) continue
				arr.push({ value: staff.user_email, text: staff.user_email })
			}

			arr.sort((a, b) => a.text < b.text ? -1 : (a.text > b.text ? 1 : 0))

			return arr
		},
		filtered_report_rows() {
			// find rows that match search values; go through the array even if we're not searching to get totals
			let re = new RegExp(this.search, 'i')
			let arr = []
			this.total_staff_access_cnt = 0
			this.total_student_access_cnt = 0
			this.total_gc_add_cnt = 0
			for (let i = 0; i < this.report_rows.length; ++i) {
				let row = this.report_rows[i]
				if (!this.search) {
					arr.push(row)
				} else {
					// search breakout_0, and the rest also if we have them/need to search them
					if (row.breakout_0.search(re) > -1) arr.push(row)
					else if (row.breakout_1 && row.breakout_1.search(re) > -1) arr.push(row)
					else if (row.breakout_2 && row.breakout_2.search(re) > -1) arr.push(row)
					else if (row.breakout_3 && row.breakout_3.search(re) > -1) arr.push(row)
					else continue
				}
				// if we get to here, add to totals
				this.total_staff_access_cnt += row.staff_access_cnt
				this.total_student_access_cnt += row.student_access_cnt
				this.total_gc_add_cnt += row.gc_add_cnt
			}

			return arr
		},
	},
	watch: {
		// if the dates change, do same as if interval selection is changed, set up for "Load Report Data" btn to show
		interval_dates() {	// deep?
			let s = ''
			if (this.interval_dates[0]) s += this.interval_dates[0].replace(/-/g, '/') + ' – '
			if (this.interval_dates[1]) s += this.interval_dates[1].replace(/-/g, '/')
			this.interval_dates_display = s

			this.resource_usage_rows = []
			this.report_rows = []
			this.data_is_loaded = false
		},
	},
	created() {
		vapp.admin_resource_usage_component = this,
		this.load_lp_list(),
		this.load_school_list()

		// if we get a course_code as a prop, use it as the initial lp
		if (this.course_code) {
			this.lp = this.course_code
		}

		// if we received a course_code, set breakdown_by to resource_title
		if (this.course_code) this.breakdown_by = 'resource_title'
		// else if the user is a principle or ap, start with breakdown_by set to user_email
		else if (this.user_is_principal_or_ap) this.breakdown_by = 'user_email'
		// have to call update_headers in case we changed breakdown_by here
		this.update_headers()
	},
	mounted() {
	},
	methods: {
		// this service will need update if type of resource_usage.lp_id is changed to store lp_id rather than course_code
		load_lp_list() {
			let payload = { user_id: this.user_info.user_id }

			U.ajax('get_all_courses', payload, result=>{
				if (result.status != 'ok') {
					console.log('Error in get_all_courses ajax call'); vapp.ping(); return;
				}

				for (let i = 0; i < result.all_courses.length; ++i) {
					let c = result.all_courses[i]
					c.grade_low  = (c.grade_low === 'K') ? 0 : c.grade_low*1
					c.grade_high = (c.grade_high === 'K') ? 0 : c.grade_high*1
					// set PD grades to -1 for now
					if (c.subject === 'PD') {c.grade_low = -1; c.grade_high = -1}
				}

				this.lp_list = result.all_courses
			});
		},

		// gets the whole school table: source_id, school_code, school_name
		load_school_list() {
			let payload = { user_id: this.user_info.user_id }

			U.ajax('get_all_schools', payload, result=>{
				if (result.status != 'ok') {
					console.log('Error in get_all_schools ajax call'); vapp.ping(); return;
				}
				this.school_list = result.all_schools
			});
		},

		interval_changed() {
			// when interval is changed, reset the data (but not the form parameters);
			// then the "Load Report Data" btn will appear; let the user click that to reload
			this.resource_usage_rows = []
			this.report_rows = []
			this.data_is_loaded = false
		},

		// get the usage rows and user data from ajax service
		load_admin_resource_usage() {
			let payload = {
				user_id: this.user_info.user_id,
				report_interval: this.report_interval,
			}

			console.log(this.interval_dates)
			if (this.report_interval === 'custom') {
				if (empty(this.interval_dates[0])) {
					this.$alert('You must select a start date for report.')
					return
				}
				if (empty(this.interval_dates[1])) {
					this.$alert('You must select an end date for report.')
					return
				}
				if (this.interval_dates[1] < this.interval_dates[0]) {
					this.$alert('The report end date is earlier than the start date.')
					return
				}
				payload.start_date = this.interval_dates[0]
				payload.end_date = this.interval_dates[1]

				let date_diff_ms = date.parse(payload.end_date, 'YYYY-MM-DD') - date.parse(payload.start_date, 'YYYY-MM-DD')
				if (date_diff_ms > 31.1*24*60*60*1000) {
					this.$alert('Please choose a shorter date span, as reports are currently limited to 31-day windows. This restriction will be lifted soon.')
					return
				}
			}

			// limit results if the user isn't allowed to see everything
			// if (filter_lps	filter_schools)

			U.loading_start()
			U.ajax('admin_get_resource_usage_data', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin_get_resource_usage_data ajax call'); vapp.ping(); return;
				}
				console.log('resource_usage_rows: ' + result.report_rows.length) //; console.log(result.report_rows)

				this.resource_usage_rows = result.report_rows
				this.staff_list = result.staff

				// if we're auto-loading initially or on report date selection change, set a type, if needed, so compile will work
				if (empty(this.breakdown_by)) {
					this.headers = [
						{ text: 'School', align: 'left', sortable: true, value:'breakout_0' },
						{ text: 'Staff Access Count', align: 'center', sortable: true, value:'staff_access_cnt' },
						{ text: 'Student Access Count', align: 'center', sortable: true, value:'student_access_cnt' },
						{ text: 'GC Add Count', align: 'center', sortable: true, value:'gc_add_cnt' },
					]
					this.breakdown_by = 'school'
					this.show_stu_cnt = true
				}

				this.compile_report()
				// console.log('report_rows', this.report_rows)
			});
		},

		search_keyup(evt) {
			// if/when user hits enter key, set search to raw_search
			if (evt.key === "Enter"){
				this.search = this.raw_search
			}
		},

		// filter selection triggers view change if data has been loaded
		filter_changed(type) {
			if (this.data_is_loaded) {
				this.compile_report()
			}
		},

		correct_school_errors(row, department_list_hash) {
			// note that at the point this fn is called, departments/schools are still stored in `.school`; later this is changed to `.all_schools`
			if (row.school.length == 0) return

			const corrections = [
				['Mount Carmel', 'Mount Carmel Elementary'],
				['Mt Carmel', 'Mount Carmel Elementary'],
				['Mt. Carmel', 'Mount Carmel Elementary'],
				['Pates Creek', 'Pates Creek Elementary'],
				['Pate’s Creek', 'Pates Creek Elementary'],
				['TimberRidge', 'Timber Ridge Elementary'],
				['Timber Ridge', 'Timber Ridge Elementary'],
				['Eagles Landing Middle', 'Eagles Landing Middle'],
				['Eagle’s Landing Middle', 'Eagles Landing Middle'],
				['Eagles Landing High', 'Eagles Landing High'],
				['Eagle’s Landing High', 'Eagles Landing High'],
			]

			for (let i = 0; i < row.school.length; ++i) {
				// if we've already logged the department in department_list_hash, we're good
				if (department_list_hash[row.school[i]]) continue

				let c = corrections.find(x=>row.school[i].indexOf(x[0]) > -1)
				if (c) {
					console.log(sr('correcting `$1` to `$2`', row.school[i], c[1]))
					row.school[i] = c[1]
				}
			}
		},

		// hash on first breakout now, those following are informational
		compile_report() {
			let changing_view_only = (this.data_is_loaded)

			this.report_rows = []
			let report_hash = {}

			let department_list_hash = {}
			if (!changing_view_only) {
				// compile department_list as we go
				this.department_list = []
			}

			for (let row of this.resource_usage_rows) {
				// following new data load, process usage rows prior to hashing/count agg
				if (!changing_view_only) {
					// get the learning progression info from data stored in resource_usage
					// users viewing via a GC link will not have a valid course code in their resource_usage record; the lp_id should be '0' here
					// (note that staff members can click GC links too...)
					if (row.course_code && row.course_code.length >= 4) {
						row.lp_title = ''
						row.grade_low = -1
						row.grade_high = -1
						row.subject = ''

						let usage_lp = this.lp_list.find(x=>x.course_code === row.course_code)
						if (typeof(usage_lp) !== 'undefined') {
							row.lp_title = usage_lp.title
							row.grade_low = usage_lp.grade_low
							row.grade_high = usage_lp.grade_high
							row.subject = usage_lp.subject
							row.active = usage_lp.active
							row.lp_link = this.lp_link(row.course_code, usage_lp.title)
						} else {
							console.log(sr('no lp match', row));
						}
					}

					// show comma delimited list of schools (when schools are shown)
					if ('school' in row) {
						// correct some misspellings...
						this.correct_school_errors(row, department_list_hash)

						// preserve the array of all schools in all_schools, for use when our breakdown is by school
						row.all_schools = row.school

						// then collapse into a string for display purposes when we aren't breaking down by school
						row.school = row.school.join(', ')

						// compile department_list_hash
						for (let s of row.all_schools) {
							if (!department_list_hash[s] && s != '') {
								this.department_list.push(s)
								department_list_hash[s] = true
							}
						}
					}

				} else {
					// clear breakout_X fields from previous view
					delete row.breakout_0
					delete row.breakout_1
					delete row.breakout_2
					delete row.breakout_3
				}

				///////////////////////// filter rows prior to compiling counts
				// drop student records for some view and rows with no school found (if breaking out by school)
				// if requested view breaks out by LP or staff ignore student views
				if (row.system_role === 'student' && ['user_email', 'lp_title'].includes(this.breakdown_by)) continue
				if (this.breakdown_by === 'school' && row.school == '') continue

				// apply user selected filters to rows before hashing/aggregation
				if (this.exclude_row(row)) continue

				// in some scenarios the course code for staff & admin views is not logged
				// exclude these counts from views that show LP
				if (row.system_role !== 'student' && row.lp_id == 0 && ['user_email', 'lp_title'].includes(this.breakdown_by)) continue

				// skip inactive lps
				if (row.system_role != 'student' && row.active != 'yes') {
					continue
				}

				///////////////////////// compile report rows
				// for breakdown by school/department, the row may have more than one school, so we want to do one row_key per school
				let row_keys
				if (this.breakdown_by == 'school') {
					row_keys = row.all_schools	// this is already an array

				} else {
					row_keys = [row[this.breakdown_by]]
				}

				for (let row_key of row_keys) {
					row.breakout_0 = row_key

					////// aggregate resource usage by hash key
					if (row_key in report_hash) {
						if (row.action == 'view' && row.system_role != 'student') ++report_hash[row_key].staff_access_cnt
						else if (row.action == 'view' && row.system_role == 'student') ++report_hash[row_key].student_access_cnt
						else if (row.action == 'add_to_gc') ++report_hash[row_key].gc_add_cnt

					} else {
						// for new hash entries, add common fields and initialize counts
						report_hash[row_key] = {}

						// transfer breakout_0 to report_hash
						report_hash[row_key].breakout_0 = row.breakout_0

						// other fields common to report row
						report_hash[row_key].resource_id = row.resource_id
						report_hash[row_key].course_code = row.course_code

						// initialize counts
						report_hash[row_key].staff_access_cnt = (row.action == 'view' && row.system_role != 'student') ? 1 : 0
						report_hash[row_key].student_access_cnt = (row.action == 'view' && row.system_role == 'student') ? 1 : 0
						report_hash[row_key].gc_add_cnt = (row.action == 'add_to_gc') ? 1 : 0

						// initialize lps, subjects, schools, and grades fields if we need them
						if (['user_email', 'resource_title'].includes(this.breakdown_by)) {
							report_hash[row_key].lp_titles = []
							report_hash[row_key].course_codes = []
						}

						if (['lp_title', 'resource_title'].includes(this.breakdown_by)) {
							report_hash[row_key].subjects  = []
							report_hash[row_key].grades = []
						}

						if (this.breakdown_by == 'user_email') {
							report_hash[row_key].schools = []
						}
					}

					///////////////////// add data to informational rows as report configuration dictates
					// lps
					if (['user_email', 'resource_title'].includes(this.breakdown_by)) {
						if (row['lp_title'] && report_hash[row_key]['lp_titles'].indexOf(row['lp_title']) === -1) {
							report_hash[row_key].lp_titles.push(row['lp_title'])
							report_hash[row_key].course_codes.push(row['course_code'])
						}
					}

					// subjects and grades
					if (['lp_title', 'resource_title'].includes(this.breakdown_by)) {
						if (row['subject'] && report_hash[row_key]['subjects'].indexOf(row['subject']) === -1) {
							report_hash[row_key]['subjects'].push(row['subject'])
						}

						let gl = (row['grade_low'] == 'K') ? 0 : row['grade_low']*1
						let gh = (row['grade_high'] == 'K') ? 0 : row['grade_high']*1
						if (gl == gh && report_hash[row_key].grades.indexOf(gl) === -1) {
							report_hash[row_key].grades.push(gl)
						} else {
							for (let i = gl; i <= gh; ++i) {
								if (report_hash[row_key].grades.indexOf(i) === -1) report_hash[row_key].grades.push(i)
							}
						}
					}

					// schools
					if (this.breakdown_by == 'user_email') {
						let school_array = row['school'].split(',')
						for (let i = 0; i < school_array.length; ++i) {
							let s = school_array[i]
							if (report_hash[row_key]['schools'].indexOf(s) === -1) report_hash[row_key]['schools'].push(s)
						}
					}

					// if (row.resource_id == "RESOURCE_219_SCI_GA19ED_DLS_G07U01L02_002_EN") {
					// 	// "Student View of Digital Lesson: Theory of Evolution by Natural Selection"
					// 	console.log("RESOURCE_219_SCI_GA19ED_DLS_G07U01L02_002_EN: " + row['lp_title'], row)
					// }

				}	// end of for loop on row_keys
			}

			this.report_rows = Object.values(report_hash)

			for (let i = 0; i < this.report_rows.length; ++i) {
				let row = this.report_rows[i]

				// sort grades array before formatting grade ranges
				if (['lp_title', 'resource_title'].includes(this.breakdown_by)) {
					row.grades.sort((a,b) => a - b)
				}

				// create formatted values to show in columns
				if (this.num_breakout_columns > 1) row.breakout_1 = this.format_column_value(row, 1)
				if (this.num_breakout_columns > 2) row.breakout_2 = this.format_column_value(row, 2)
				if (this.num_breakout_columns > 3) row.breakout_3 = this.format_column_value(row, 3)
			}

			console.log('report_rows: ' + this.report_rows.length); console.log(this.report_rows)
			this.data_is_loaded = true
		},

		// we do some different things to format
		format_column_value(row, col_index) {
			let col_name = this.headers[col_index].value

			if (col_name === 'grade') {
				//if (row.breakout_0 != 'HMH activities') return
				return this.grade_range_str(row['grades'])
			} else if (col_name == 'resource_title') {
				return row[col_name + 's'].join(', ')
			} else if (col_name == 'subject' || col_name == 'lp_title' || col_name == 'school') {
				return '<nobr>' + row[col_name + 's'].join(',</nobr><br><nobr>') + '</nobr>'
			} else {
				return 'ERROR'
			}
		},

		// assemble string for grade(s) display
		// there can be disjoint grade ranges
		grade_range_str(grades) {
			if (grades && grades[0] == -1) return ''
			if (grades.length === 1) return (grades[0] === 0) ? 'K' : grades[0]+''

			// snippet to convert array of grades to array of range strings
			// incoming array of grades must be sorted
			// https://stackoverflow.com/questions/2270910/how-to-reduce-consecutive-integers-in-an-array-to-hyphenated-range-expressions
			var ranges = [], rstart, rend
			for (var i = 0; i < grades.length; i++) {
				rstart = grades[i]
				rend = rstart

				while (grades[i + 1] - grades[i] === 1) {
					rend = grades[i + 1] // increment the index if the numbers sequential
					i++
				}
				rstart = (rstart === 0) ? 'K' : rstart
				ranges.push(rstart === rend ? rstart+'' : rstart + '-' + rend);
			}

			return ranges.join(', ')
		},

		// TODO: make resources and LPs links that open the resource/LP in a new window. Considerations:
		// - we can use these patterns to do the link:
		//    sr('<a href="javascript:vapp.admin_resource_usage_component.open_resource(\'$1\')">$2</a>', resource_id, resource_title)
		//    sr('<a href="javascript:vapp.admin_resource_usage_component.open_lp($1)">$2</a>', course_code, resource_title)
		// - open_resource will need to mimic/copy the code in ResourceLink.vue to use LTI if necessary
		//    - for assessments, we should just show the assessment id, which is stored in the URL; there's nothing to open there
		// - for open_lp, if the course_code is > 90000, it's a PD resource, so use url sr('/pd/$1/0/0', course_code)
		//    - else it's a course LP, so use url sr('/courses/lp/$1/0/0', course_code)
		lp_link(course_code, lp_title) {
			return sr('<a href="javascript:vapp.admin_resource_usage_component.open_lp($1)">$2</a>', course_code, lp_title)
		},
		open_lp() {
			if (course_code*1 < 90000) {
				this.$router.push({ path: sr('/courses/lp/$1/0/0', course_code) })
			} else {
				this.$router.push({ path: sr('/pd/$1/0/0', course_code) })
			}
		},
		resource_link(resource_id, resource_title) {
			return sr('<a href="javascript:vapp.admin_resource_usage_component.open_resource(\'$1\')">$2</a>', resource_id, resource_title)
		},
		open_resource() {

		},

		// report user's filter selection(s) on usage rows prior to hashing & counting
		// assuming only one selection per filter
		exclude_row(row) {
			if (this.grade !== '- All Grades -') {
				let gl = (row['grade_low'] == 'K') ? 0 : row['grade_low']
				let gh = (row['grade_high'] == 'K') ? 0 : row['grade_high']
				let filter_grade = (this.grade === 'K') ? 0 : this.grade*1
				if (filter_grade < gl || filter_grade > gh) return true
			}

			if (this.subject !== '- All Subjects -' && row.subject !== this.subject) return true
			if (this.lp !== '- All LPs/Collections -' && this.lp !== row.course_code) return true
			if (this.staff !== '- All Staff -' && this.staff !== row.user_email) return true

			// checking against comma delimited list of schools from user.json
			if (this.school !== '- All Schools/Departments -' && !(row.school).includes(this.school)) return true

			return false
		},

		// update report columns as breakout column is selected
		// if data is loaded, update the report view too as well
		update_headers(value) {
			// { text: 'School', value: ['school'] },
			// { text: 'Staff Member', value: ['user_email', 'school', 'lp_title'] },
			// { text: 'Learning Progression/PD Collection', value: ['lp_title', 'subject', 'grade'] },
			// { text: 'Resource', value: ['resource_title', 'subject', 'grade', 'lp_title'] }

			if (this.breakdown_by == 'school') {
				this.headers = [
					{ text: 'School', align: 'left', sortable: true, value:'breakout_0' },
					{ text: 'Staff Access Count', align: 'center', sortable: true, value:'staff_access_cnt' },
					{ text: 'Student Access Count', align: 'center', sortable: true, value:'student_access_cnt' },
					{ text: 'GC Add Count', align: 'center', sortable: true, value:'gc_add_cnt' },
				]
				this.show_stu_cnt = true
				this.num_breakout_columns = 1

			} else if (this.breakdown_by == 'user_email') {
				this.headers = [
					{ text: 'Staff Member', align: 'left', sortable: true, value:'breakout_0' },
					{ text: 'School', align: 'left', sortable: false, value:'school' },
					{ text: 'Learning Progression/PD Collection', align: 'left', sortable: false, value:'lp_title' },
					{ text: 'Staff Access Count', align: 'center', sortable: true, value:'staff_access_cnt' },
					{ text: 'GC Add Count', align: 'center', sortable: true, value:'gc_add_cnt' },
				]
				this.show_stu_cnt = false
				this.num_breakout_columns = 3

			} else if (this.breakdown_by == 'lp_title') {
				this.headers = [
					{ text: 'Learning Progression/PD Collection', align: 'left', sortable: true, value:'breakout_0' },
					{ text: 'Subject', align: 'left', sortable: false, value:'subject' },
					{ text: 'Grade(s)', align: 'left', sortable: false, value:'grade' },
					{ text: 'Staff Access Count', align: 'center', sortable: true, value:'staff_access_cnt' },
					{ text: 'GC Add Count', align: 'center', sortable: true, value:'gc_add_cnt' },
				]
				this.show_stu_cnt = false
				this.num_breakout_columns = 3

			} else if (this.breakdown_by == 'resource_title') {
				this.headers = [
					{ text: 'Resource', align: 'left', sortable: true, value:'breakout_0' },
					{ text: 'Subject', align: 'left', sortable: false, value:'subject' },
					{ text: 'Grade(s)', align: 'left', sortable: false, value:'grade' },
					{ text: 'Learning Progression/PD Collection', align: 'left', sortable: false, value:'lp_title' },
					{ text: 'Staff Access Count', align: 'center', sortable: true, value:'staff_access_cnt' },
					{ text: 'Student Access Count', align: 'center', sortable: true, value:'student_access_cnt' },
					{ text: 'GC Add Count', align: 'center', sortable: true, value:'gc_add_cnt' },
				]
				this.show_stu_cnt = true
				this.num_breakout_columns = 4
			}
			// that should cover all possibilities

			// if data is loaded, compile requested view change
			if (this.data_is_loaded) {
				this.compile_report()
			}
		},

		download_report() {
			let filename = sr('Resource Usage Report - $1.csv', date.format(new Date(), 'YYYY-MM-DD hh:mm'))

			let table = []

			// create header row
			let header_row = []
			for (let col of this.headers) {
				header_row.push(col.text)
			}

			// create table rows
			for (let item of this.filtered_report_rows) {
				let row = []
				row.push(item.breakout_0)
				if (this.num_breakout_columns > 1) row.push(item.breakout_1)
				if (this.num_breakout_columns > 2) row.push(item.breakout_2)
				if (this.num_breakout_columns > 3) row.push(item.breakout_3)
				row.push(item.staff_access_cnt)
				if (this.show_stu_cnt) row.push(item.student_access_cnt)
				row.push(item.gc_add_cnt)

				table.push(row)
			}

			// sort by column 1 (second column) descending, which is always a number
			table.sort((a,b) => b[1] - a[1])

			// add the header to the top of the file
			table.unshift(header_row)

			U.download_file(CSV.stringify(table), filename)
		},

		return_to_admin_main() {
			this.$router.push({ path: '/welcome' })
		},
	},
}
</script>

<style lang="scss">
	.k-admin-resource-usage-table {
		th {
			white-space:nowrap;
		}
	}
	.k-ru-breakout-menu {
	}
	.k-ru-filter-grade-menu {
	}
	.k-ru-filter-subject-menu {
	}
	.k-ru-filter-lp-menu {
	}
	.k-ru-filter-school-menu {
	}
</style>
