<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-page-wrapper">
	<h2 class="k-page-title">
		<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-cog</v-icon>
		Site Search Administration
	</h2>

	<div class="d-flex pt-3" style="clear:both">
		<div class="k-admin-search-links flex-grow-1">
			<div class="pa-2">
				<v-btn @click="rescrape_for_links" color="secondary">Re-Scrape For Links</v-btn>
				<v-btn v-show="links_changed" class="ml-2" @click="save_links" color="primary">Save Links</v-btn>
			</div>
			<div class="ma-2 pa-2 light-blue lighten-3 text-center elevation-2" style="border-radius:6px; font-size:16px;"><i>Check boxes next to links below to add or remove links to the “Top Hits” list. Top Hits always appear first in the “quick search” list that appears as soon as users start typing in the search box.</i></div>
			<div class="d-flex flex-wrap align-start">
				<AdminSearchLinks :title="'Top Hits'" :links="searchable_links.top_hits" :top_hits="searchable_links.top_hits" @set_top_hit="set_top_hit"/>
				<AdminSearchLinks :title="'School Links'" :links="searchable_links.school_links" :top_hits="searchable_links.top_hits" @set_top_hit="set_top_hit"/>
				<AdminSearchLinks :title="'SchoolWire Nav Links'" :links="searchable_links.schoolwire_nav_links" :top_hits="searchable_links.top_hits" @set_top_hit="set_top_hit"/>
				<AdminSearchLinks :title="'Employee Links'" :links="searchable_links.employee_links" :top_hits="searchable_links.top_hits" @set_top_hit="set_top_hit"/>
				<AdminSearchLinks :title="'Community Links'" :links="searchable_links.community_links" :top_hits="searchable_links.top_hits" @set_top_hit="set_top_hit"/>
				<AdminSearchLinks :title="'Student Links'" :links="searchable_links.student_links" :top_hits="searchable_links.top_hits" @set_top_hit="set_top_hit"/>
				<AdminSearchLinks :title="'Parent Links'" :links="searchable_links.parent_links" :top_hits="searchable_links.top_hits" @set_top_hit="set_top_hit"/>
			</div>
		</div>

		<div class="col-6 flex-grow-0" v-if="link_to_preview!=null">
			<div class="text-center mb-2" style="font-size:14px"><b>Preview:</b> {{link_to_preview.text}} <v-btn color="primary" class="ml-2" style="margin-top:-5px" icon small :href="link_to_preview.href" target="_blank"><v-icon small>fas fa-external-link-alt</v-icon></v-btn></div>
			<iframe id="sidebar_iframe" name="sidebar_iframe" class="k-admin-search-iframe"></iframe>
		</div>
	</div>

	<v-btn class="mt-5" v-show="debug_content!=''" @click="show_debug_content=!show_debug_content">Toggle Debug Block</v-btn>
	<pre v-show="show_debug_content" style="width:100%; padding:10px; background-color:#fff; margin-top:10px; font-size:12px; white-space:pre; overflow:auto;" v-html="debug_content"></pre>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminSearchLinks from './AdminSearchLinks'

export default {
	components: { AdminSearchLinks },
	props: {
	},
	data() { return {
		searchable_links: {
			top_hits: [],
			school_links: [],
			schoolwire_nav_links: [],
			employee_links: [],
			student_links: [],
			parent_links: [],
			community_links: [],
		},

		debug_content: '',
		bad_links: [],

		link_to_preview: null,
		show_debug_content: false,
		links_changed: false,
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
		this.searchable_links = $.extend(true, {}, this.$store.state.searchable_links)
	},
	mounted() {
	},
	methods: {
		// henry county school-based professional learning days site:henry.k12.ga.us
		rescrape_for_links() {
			// start by resetting link arrays, so that the display will show (0)
			for (let prop in this.searchable_links) this.searchable_links[prop] = []

			this.scrape_page('https://schoolwires.henry.k12.ga.us/Domain/1', (html)=>this.process_schoolwires_main(html))
		},

		scrape_page(url, callback) {
			// DEBUG: use this to pull data from static file
			// if (!empty(window.scrapes[url])) {
			// 	console.log('already scraped ' + url)
			// 	callback(window.scrapes[url].text)
			// 	return
			// }

			let payload = {
				user_id: this.user_info.user_id,
				url: url,
			}
			U.loading_start()
			U.ajax('admin_scrape_page', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				callback(result.text)
			});

		},

		process_schoolwires_main(html) {
			// strip newlines (replace with spaces)
			html = html.replace(/\s+/g, ' ')

			// preprocess using jquery/dom
			let jq = $('<div>' + html + '</div>')

			// remove "hidden" links
			jq.find('.hidden-channel').remove()
			jq.find('.hidden-section').remove()

			// extract school links
			let school_html = jq.find('#sw-mystart-outer').remove().html()
			this.searchable_links.school_links = this.process_links(school_html)

			// extract "events" links
			let events_html = jq.find('.upcomingevents').remove().html()
			let event_links = this.process_events(events_html)

			// get the remaining html/links
			let other_html = jq.html()
			this.searchable_links.schoolwire_nav_links = this.process_links(other_html)

			html = ''
			html += sr('$1 School links:<br><br>$2<br><br>', this.searchable_links.school_links.length, this.links_to_html(this.searchable_links.school_links))
			html += sr('$1 SchoolWire Nav links:<br><br>$2<br><br>', this.searchable_links.schoolwire_nav_links.length, this.links_to_html(this.searchable_links.schoolwire_nav_links))
			html += sr('$1 Event links (not used on HenryConnects):<br><br>$2<br><br>', event_links.length, this.links_to_html(event_links))

			html += '<br><br>' + this.bad_links.join('<br>')

			this.debug_content = html

			// now scrape each of the 4 pages where we get links from the description section
			this.process_schoolwires_description_section('https://schoolwires.henry.k12.ga.us/employees', 'employee_links', ()=>{
				this.process_schoolwires_description_section('https://schoolwires.henry.k12.ga.us/community', 'community_links', ()=>{
					this.process_schoolwires_description_section('https://schoolwires.henry.k12.ga.us/students', 'student_links', ()=>{
						this.process_schoolwires_description_section('https://schoolwires.henry.k12.ga.us/parents', 'parent_links', ()=>{
							this.process_schoolwires_main_complete()
						})
					})
				})
			})
		},

		process_schoolwires_main_complete() {
			this.links_changed = true
		},

		process_schoolwires_description_section(url, prop, callback) {
			this.scrape_page(url, (html)=>{
				// extract the links
				html = $('<div>' + html + '</div>').find('.ui-article-description').html()

				this.searchable_links[prop] = this.process_links(html)

				html = sr('$1 $2 links:<br><br>$3<br><br>', this.searchable_links[prop].length, prop, this.links_to_html(this.searchable_links[prop]))

				this.debug_content = html + this.debug_content

				callback()
			})
		},

		process_events(html) {
			let links = []
			// debugger
			// events are in a dom structure (one per “.ui-article”) that includes the dates, which we also want to harvest
			$('<div>' + html + '</div>').find('.ui-article').each((i, el) => {
				let jq = $(el)
				// try to extract the link_tag
				let link_tag
				if (jq.html().search(/(<a .*?>.*?<\/a>)/) > -1) {
					link_tag = RegExp.$1
				} else {
					// if not found return (no event) -- shouldn't happen
					console.log('no event found for ui-article', el)
					return
				}

				if (link_tag.search(/[^>]+href=(["'])(.*?)\1[^>]*>(.*?)<\/a>/) == -1) return

				// create link just like with process_links, but add date
				let link = new Announcement_Link({
					href: RegExp.$2,
					text: RegExp.$3,
					date: jq.find('.sw-calendar-block-date').text()
				})

				link = this.clean_link(link, link_tag)
				if (link == false) return

				// skip duplicates
				if (links.find(o=>o.href == link.href)) return

				links.push(link)
			})

			return links
		},

		process_links(html) {
			// extract link tags from html
			let link_tags = html.match(/<a .*?>.*?<\/a>/g)
			html = ''
			let links = []
			if (!empty(link_tags)) for (let link_tag of link_tags) {
				// parse out href and link text; skip anything that doesn't match
				if (link_tag.search(/[^>]+href=(["'])(.*?)\1[^>]*>(.*?)<\/a>/) == -1) continue

				let link = new Link({
					href: RegExp.$2,
					text: RegExp.$3,
				})

				link = this.clean_link(link, link_tag)
				if (link == false) continue

				// skip duplicates
				if (links.find(o=>o.href == link.href)) continue

				links.push(link)
			}

			return links
		},

		clean_link(link, link_tag) {
			// this is for debugging bad links: start by pushing the link's raw html onto bad_links
			this.bad_links.push(' NO: ' + link_tag.replace(/</g, '&lt;'))

			// CLEAN HREF
			link.href = $.trim(link.href)

			// skip some thing based on link href
			if (empty(link.href)) return false
			if (link.href.search(/['"]/) > -1) return false
			if (link.href.search(/\//) == -1) return false
			if (link.href.search(/www.schoolwires.com/) > -1) return false
			if (link.href.search(/www.blackboard.com/) > -1) return false
			if (link.href.search(/twitter.com/) > -1) return false

			// normalize link urls...
			// links that start with “/”
			if (link.href.search(/\//) == 0) {
				link.href = 'https://schoolwires.henry.k12.ga.us' + link.href
			}

			// https://schoolwires.henry.k12.ga.us/site/default.aspx?PageType=3&DomainID=1&ModuleInstanceID=13807&ViewID=6446EE88-D30C-497E-9316-3F8874B3E108&RenderLoc=0&FlexDataID=199970&PageID=1
			// links that start with “../../site”
			// TODO: get these direct links working
			link.href = link.href.replace(/^\.\.\/\.\.\/site/, 'https://schoolwires.henry.k12.ga.us/site')
			// remove double-“/”s
			link.href = link.href.replace(/\/+/g, '/')
			// make sure we start with http(s)://
			link.href = link.href.replace(/^(http(s)?):\/+/, '$1://')
			if (link.href.search(/^http/) == -1) {
				link.href = 'https://' + link.href
			}

			// CLEAN TEXT -- mostly determine some things to skip
			link.text = $.trim($(sr('<div>$1</div>', link.text)).text())

			// skip some things based on link text
			if (empty(link.text)) return false
			if (link.text.indexOf('Comments (') == 0) return false
			if (link.text == 'District Home') return false
			if (link.text == 'Sign In') return false
			if (link.text == 'Register') return false
			if (link.text == 'update') return false
			if (link.text == 'SITEMAP') return false
			if (link.text == 'Home') return false
			if (link.text.indexOf('Subscribe to RSS Feed') == 0) return false
			if (link.text == 'more') return false	// more headlines

			// for debugging purposes, if the link ends up being good, pop it off the bad_links queue
			this.bad_links.pop()

			// if we get to here return the link, indicating it's "valid" (i.e. a link we want to use)
			return link
		},

		links_to_html(links) {
			let html = ''
			for (let link of links) {
				date = ''
				if (!empty(link.date)) {
					date = sr('($1) ', link.date)
				}
				html += sr('$1 <b>$2</b>: $3<br>', date, link.href, link.text)
			}
			return html
		},

		set_top_hit(link) {
			// find out if link is currently in top_hits; if not add it; if so remove it
			let index = this.searchable_links.top_hits.findIndex(x=>(x.href==link.href && x.text==link.text))
			if (index == -1) {
				let new_link = Object.assign({}, link)
				this.searchable_links.top_hits.push(new_link)

			} else {
				this.searchable_links.top_hits.splice(index, 1)
			}

			this.links_changed = true
		},

		save_links() {
			let payload = {
				user_id: this.user_info.user_id,
				searchable_links: this.searchable_links,
			}
			U.loading_start()
			U.ajax('admin_update_search_data', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				this.$store.commit('set', ['searchable_links', this.searchable_links])
			});
		},

		return_to_admin_main() {
			this.$router.push({ path: '/welcome' })
		}
	}
}
</script>

<style lang="scss">
.k-admin-search-iframe {
	width:100%;
	height:calc(100vh - 323px);
	background-color:#fff;
	border: 0;
	border-radius:5px;
}

.k-admin-search-links {
	// max-height:calc(100vh - 280px);
	// overflow:auto;
	// background-color:#fff;
	// border-radius:5px;
}
</style>
