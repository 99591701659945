<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-resource-collection-item elevation-3" :class="card_class" :style="card_style" @click="card_clicked"><v-hover v-slot:default="{hover}"><div>
	<v-tooltip bottom :disabled="!item.long_description"><template v-slot:activator="{on}"><div v-on="on" class="k-resource-collection-item--title">
		<div class="float-left mr-1" style="height:18px; width:20px;"><v-icon color="rgba(255,255,255,0.8)" small>fas {{icon}}</v-icon></div>

		<div class="float-right ml-1"><v-menu offset-y bottom right><template v-slot:activator="{on}"><v-btn v-visible="hover" v-on="on" icon x-small color="#fff" style="margin-top:-2px"><v-icon small>fas fa-bolt</v-icon></v-btn></template>
			<v-list dense>
				<v-list-item v-if="enable_gc_link" @click.stop="add_to_gc"><v-list-item-icon><img src="./../../images/google_classroom_logo.png" style="height:18px;margin-top:3px;margin-left:-4px;border-radius:3px;"></v-list-item-icon><v-list-item-title>Add to Google Classroom</v-list-item-title></v-list-item>
				<v-list-item v-if="enable_edit_link" @click="$emit('edit_resource', item)"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit {{item_noun}}</v-list-item-title></v-list-item>
				<v-list-item v-if="enable_remove_link" @click="$emit('remove_resource', item)"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Remove {{item_noun}}</v-list-item-title></v-list-item>
				<v-list-item @click.stop="copy_link_to_clipboard"><v-list-item-icon><v-icon small>fas fa-link</v-icon></v-list-item-icon><v-list-item-title>Copy link to clipboard</v-list-item-title></v-list-item>
				<v-list-item v-show="enable_report_issue" @click="$emit('report_issue',{resource:item})"><v-list-item-icon><v-icon small class="ml-1">fas fa-exclamation</v-icon></v-list-item-icon><v-list-item-title>Report an issue with this resource</v-list-item-title></v-list-item>
			</v-list>
		</v-menu></div>

		<a @click.stop="card_clicked" v-html="item_title" class="k-resource-collection-item-title-link"></a>
	</div></template><span v-if="item.long_description" v-html="item.long_description"></span></v-tooltip>

	<v-dialog v-if="item_showing" v-model="item_showing" max-width="800" persistent scrollable content-class="k-resource-collection-item-lesson-card-dialog">
		<v-card class="k-resource-collection-item-lesson-card">
			<LessonView :lesson="item" />
			<v-card-actions class="ma-0 px-1 pt-2 pb-0">
				<v-menu offset-y bottom right><template v-slot:activator="{on}"><v-btn v-on="on" fab small color="primary"><v-icon small>fas fa-bolt</v-icon></v-btn></template>
					<v-list dense>
						<v-list-item v-if="enable_gc_link" @click.stop="add_to_gc"><v-list-item-icon><img src="./../../images/google_classroom_logo.png" style="height:18px;margin-top:3px;margin-left:-4px;border-radius:3px;"></v-list-item-icon><v-list-item-title>Add to Google Classroom</v-list-item-title></v-list-item>
						<v-list-item v-if="enable_edit_link" @click="item_showing=null;$emit('edit_resource', item)"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit {{item_noun}}</v-list-item-title></v-list-item>
						<v-list-item v-if="enable_remove_link" @click="$emit('remove_resource', item)"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Remove {{item_noun}}</v-list-item-title></v-list-item>
						<v-list-item @click.stop="copy_link_to_clipboard"><v-list-item-icon><v-icon small>fas fa-link</v-icon></v-list-item-icon><v-list-item-title>Copy link to clipboard</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
				<v-spacer/>
				<v-btn color="secondary" dark @click="item_showing=false"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<input style="display:none" class="k-copy-link-hidden-input" type="text" :value="reslink">
	<input style="display:none" class="k-copy-title-hidden-input" type="text" :value="copyable_title">

</div></v-hover></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import LessonView from '../lessons/LessonView'

export default {
	name: 'ResourceCollectionItem',
	components: { LessonView, },
	props: {
		item: { type: Object, required: true },
		enable_hc_assignment: { type: Boolean, required: false, default() { return false }},
		enable_gc_link: { type: Boolean, required: false, default() { return false }},
		enable_edit_link: { type: Boolean, required: false, default() { return false }},
		enable_remove_link: { type: Boolean, required: false, default() { return false }},
		enable_report_issue: { type: Boolean, required: false, default() { return false }},
	},
	data() { return {
		card_color: '',
		highlight_color: '#FFA000',	// amber-darken-2
		link_clicked: false,
		item_showing: false,
	}},
	computed: {
		...mapState(['user_is_instructor', 'user_is_student', 'user_info', 'groups', 'teacher_collections', 'demo_collection']),
		...mapGetters(['context_standalone', 'context_gc', 'context_lti', 'signed_in']),
		is_lesson() { return !empty(this.item.lesson_title) },
		is_resource() { return !this.is_lesson },
		item_noun(capitalize) {
			if (capitalize === true) return this.is_lesson ? 'Lesson Plan' : 'Resource'
			return this.is_lesson ? 'lesson plan' : 'resource'
		},
		item_title() {
			if (this.is_lesson) return this.item.lesson_title
			return this.item.description
		},
		item_type() {
			if (this.is_lesson) return 'lesson'
			return this.item.type
		},
		item_id() {
			if (this.is_lesson) return this.item.lesson_id
			return this.item.resource_id
		},
		card_style() {
			return ''
			if (!empty(this.card_color)) {
				return 'background-color:' + this.card_color
			}
		},
		card_class() {
			let c = ''

			if (this.item_type == 'lesson') c += ' k-resource-collection-item--lesson'
			else if (this.item_type == 'sparkl') c += ' k-resource-collection-item--sparkl-activity'
			else if (this.item_type == 'assessment') c += ' k-resource-collection-item--assessment'
			else if (this.item.teacher_facing) c += ' k-resource-collection-item--teacher-resource'
			else if (this.item.target_students == 'ese') c += ' k-resource-collection-item--ese'
			else if (this.item.target_students == 'adv') c += ' k-resource-collection-item--adv'
			else c += ' k-resource-collection-item--student-resource'

			// if (this.search_match == 'yes') c += ' k-resource-collection-item--search_match'
			if (this.$store.state.last_viewed_resource_id == this.item_id) c += ' k-resource-collection-item--last-viewed'

			// add class to reduce font size for long names
			let title_text = U.html_to_text(this.item_title)
			let title_len = title_text.length
			let word_len = title_text.split(/\s+/).sort((a,b)=>b.length-a.length)[0].length
			if (title_len > 45 || word_len > 12) c += ' k-resource-collection-item-really-long-title'
			else if (title_len > 30 || word_len > 9) c += ' k-resource-collection-item-long-title'

			return c
		},
		icon() {
			if (this.item_type == 'lesson') return 'fa-rectangle-list'
			if (this.item_type == 'sparkl') return 'fa-star'
			if (this.item_type == 'assessment') return ' fa-clipboard-list'
			if (this.item_type == 'website') return 'fa-link'
			if (this.item_type == 'video' || this.item_type == 'interactive') return 'fa-video'
			if (this.item_type == 'upload') {
				let ext = this.item.url.replace(/.*?\.(\w+)$/, '$1')
				if (ext == 'pdf') return 'fa-file-pdf'
				if (ext == 'doc') return 'fa-file-word'
				if (ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'pdf') return 'fa-file-image'
			}
			if (this.item_type == 'html') return 'fa-file-alt'
			if (this.item_type == 'folder') return 'fa-folder'
			return 'fa-file'
		},
		reslink() {
			// TODO: link for lesson
			// link/url that we want to be used from the outside
			return 'https://henryconnects.henry.k12.ga.us/reslink/' + this.item.resource_id
		},
		url() {
			if ((this.item.url.indexOf('https:') == -1) && (this.item_type == 'upload' || this.item_type == 'html')) {
				let base = '/user-files/'
				// TODO: for now at least, base url of file upload based on document.location
				// if (document.location.href.indexOf('localhost') > -1) {
				// 	base = 'http://henry.2600.work/user-files/'
				// } else {
				// 	base = 'https://henryconnects.net/user-files/'
				// }
				return base + this.item.url

			} else if (this.item_type == 'sparkl') {
				// for now set sparkl links to use sparkl-ed.com, though we'll open with LTI
				return 'https://sparkl-ed.com/' + this.item.url

			} else {
				return this.item.url
			}
		},
		has_openable_url() {
			// if we don't have a url obviously we can't open
			if (empty(this.item.url)) return false

			// lti, assessment resources, and sparkl activities don't open directly as links
			if (this.item_type == 'lti' || this.item_type == 'assessment' || this.item_type == 'sparkl') return false

			// for assigned videos that have youtube urls, we open in an inline video player
			if (this.item_type == 'video' && this.item.todo && U.youtube_id_from_url(this.item.url)) return false

			// if we get to here, assume the url is openable
			return true
		},
		inline_document_src() {
			if (this.show_inline_document) return this.url
			else return ''
		},
		quick_look_available() {
			// quick look always available for certain types...
			if (this.item_type == 'html') return true
			if (this.item_type == 'document') return true

			// youtube videos might work? but not always unfortunately
			// if (this.item.url.search(/youtu.*?be/i) > -1) return true

			// uploaded pdfs and images should work
			if (this.item_type == 'upload') {
				if (this.item.url.search(/\.pdf/i) > -1) return true
				if (this.icon == 'fa-file-image') return true
			}

			// for other things be safe and don't allow it
			return false
		},
		enable_add_to_current_hc_assignment() {
			// you can add the resource to the currently-edited hc assignment if enable_hc_assignment is true and we have an active assignment editor
			if (this.enable_hc_assignment) {
				if (this.open_assignment_editor && this.open_assignment_editor.editor_active) {
					return true
				}
			}
			return false
		},
		copyable_title() {
			return $(sr('<div>$1</div>', this.item_title)).text()
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		card_clicked() {
			// if a lesson, show it
			if (this.item_type == 'lesson') {
				this.item_showing = true
				return
			}

			// if type is 'collection_item', or type is 'lti' or 'sparkl', call get_resource_record to get the full resource data and retrieve the lti_form if necessary
			if (this.item_type == 'collection_item' || this.item_type == 'lti' || this.item_type == 'sparkl') {
				// (note that lti forms are timestamped, so we need to get a new form every time the link is launched)
				this.get_resource_record(true)
			} else if (this.item_type == 'assessment') {
				// for assessments, we set show_assessment_options to true to show links + instructions
				this.show_assessment_options = true
			} else if (this.is_required_video) {
				// TODO: this won't currently work...
				// for required video, show the video player
				this.show_video_player = true
			} else {
				// else open the url
				window.open(this.url)
			}

			this.$emit('resource_opened')

			// mark link as clicked
			this.link_clicked = true

			// mark as the last-viewed resource
			this.$store.commit('set', ['last_viewed_resource_id', this.item_id])
		},

		get_resource_record(and_launch) {
			this.$store.commit('set', [this.item, 'full_resource_data_loaded', true])

			let payload = {resource_id: this.item.resource_id}
			if (and_launch == true) payload.get_lti_form = 'yes'

			this.$store.dispatch('get_resource_record', payload).then(result=>{
				// fill in parts of the resource that we need but might not have been fully filled in previously, including overwriting the type
				this.$store.commit('set', [this.item, 'type', result.resource_record.type])
				this.$store.commit('set', [this.item, 'url', result.resource_record.url])
				this.$store.commit('set', [this.item, 'long_description', result.resource_record.long_description])

				console.log(this.item)

				// if and_launch is true, launch the link once we get the record data back
				if (and_launch == true) {
					// if we got back an lti_form, launch
					if (!empty(result.lti_form)) {
						// for the lti_form we open a new window and write out the form, which submits itself
						// see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
						let w = window.open()
						w.document.write(result.lti_form)
					} else {
						// else we just open the new window to the resource's url
						window.open(this.item.url, '_blank')
					}
				}
			})
		},

		add_to_gc() {
			// for assessments, can't add to gc directly
			if (this.item_type == 'assessment') {
				this.$alert(sr('To add an Illuminate assessment to Google Classroom, click the <a href="$1" target="_illuminate">resource link</a> to open the assessment in Illuminate, then generate a “Quick Code link” for your class in the Illuminate system, and add this Quick Code link to Google Classroom.', this.url))
				return
			}

			let url = sr('https://classroom.google.com/share?url=$1', this.reslink)
			// add title to url
			url += sr('&title=$1', this.copyable_title)
			window.open(url)
		},

		copy_link_to_clipboard() {
			// temporarily show the input (if we don't do this first, the select/execCommand doesn't work)
			let jq = $(this.$el).find('.k-copy-link-hidden-input')
			jq.show()

			// select the input value and copy to clipboard
			jq[0].select()
			document.execCommand("copy")

			// re-hide the input
			jq.hide()

			this.$inform('Resource link copied to clipboard')
		},

	}
}
</script>

<style lang="scss">
.k-resource-collection-item {
	margin:6px;
	background-color:#333;
	border-radius:10px;
	padding:4px;
	// color:#222;
	border:0px solid transparent;
	position:relative;
	cursor:pointer;
	height:calc(100% - 12px);
}

.k-resource-collection-item--title {
	// display:flex;
	// align-items: flex-start;
	padding:4px;
	font-size:13px;
	line-height:15px;
	text-align: center;
	.k-resource-collection-item-title-link {
		// color:#111!important;
		color:#fff!important;
		font-weight:normal;
		text-decoration:none;
	}
	.k-resource-collection-item-title-link:hover {
		text-decoration:underline;
	}
}
.k-portal-collection-minimized .k-resource-collection-item--title {
	margin:0 12px;
}

.k-resource-collection-item-collapsed-menu-btn {
	// position:absolute;
	// right:2px;
	// top:calc(50% - 10px);
	// top:6px;
}

.k-resource-collection-item-long-title .k-resource-collection-item--title { font-size:12px; line-height:14px; }
.k-resource-collection-item-really-long-title .k-resource-collection-item--title { font-size:10px; line-height:12px; }

.k-resource-collection-item--stats {
	display:flex;
	justify-content: space-between;
	align-items: center;
	margin:4px 2px 2px 2px;
	padding:2px 0px 2px 8px;
	// color:$v-amber-darken-2;
	background-color:rgba(0,0,0,0.05);
	border-radius:4px;
	font-size:12px;
}

.k-resource-collection-item--info-header {
	// color:#666;
	font-size:14px;
	font-weight:bold;
}

.k-resource-collection-item--actions {
	display:flex;
	justify-content: space-between;
}

.k-resource-collection-item-more-info-card {
	border-width:5px;
	border-style:solid;
	// background-color:#eee;
	border-radius:10px!important;
}

.k-resource-collection-item--assessment {
	background-color:$v-pink-accent-4;
	// a, .v-icon.k-resource-type-icon {
	// 	color:$v-teal-darken-2!important;
	// }
}

.k-resource-collection-item--lesson {
	background-color:$v-blue-accent-4;
}

.k-resource-collection-item--sparkl-activity {
	background-color:$v-deep-purple-accent-3;
}

.k-resource-collection-item--teacher-resource {
	background-color:$v-blue-darken-3;
}

.k-resource-collection-item--student-resource {
	background-color:$v-amber-accent-2;
	.k-resource-collection-item--title {
		.fas, .k-resource-collection-item-title-link {color:#000!important; }
	}
}

.k-resource-collection-item--adv {
	background-color:$v-purple-accent-4;
}

.k-resource-collection-item--ese {
	background-color:$v-lime-darken-4;
}

.k-resource-collection-item--unit-planning-guide {
	background-color:$v-green-accent-4;
}


.v-application .k-resource-collection-item--last-viewed {
	border:1px solid #000;
	box-shadow: 0px 0px 8px #000!important;
	// box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 80%), 0px 3px 4px 0px rgb(0 0 0 / 80%), 0px 1px 8px 0px rgb(0 0 0 / 80%) !important
}

.k-resource-collection-item-lesson-card-dialog {
	border-radius:12px!important;
}

.k-resource-collection-item-lesson-card {
	border-radius:12px!important;
	padding:12px;
}

</style>
