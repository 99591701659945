<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<vue-draggable-resizable :drag-handle="'.k-case-tree-title'" :resizable="!small_screen" :draggable="!small_screen" class-name="k-case-tree-outer-wrapper k-pdr-collection-outer-wrapper elevation-3" :h="560" :w="560" :minWidth="350" :minHeight="250" :active="true" :preventDeactivation="true" :handles="['br']" class-name-handle="k-resizable-handle">
		<div class="k-case-tree-top k-pdr-collection-top">
			<div class="k-case-tree-title">
				<v-icon v-if="!small_screen" color="grey darken-4" class="mr-2" style="margin-left:-5px">fas fa-arrows-alt</v-icon>
				<div>Professional Development Resources</div>
				<v-spacer/>
				<v-btn icon color="grey darken-4" @click="$emit('hide')"><v-icon>fas fa-times-circle</v-icon></v-btn>
			</div>
			<v-text-field light background-color="#fff" class="ma-1" solo hide-details clearable dense
				placeholder="Search"
				v-model="search_terms"
				prepend-inner-icon="fas fa-search" @click:prepend-inner="execute_search_start"
				@click:clear="execute_search_clear"
				@keyup="search_field_keyup"
				autocomplete="new-password"
			></v-text-field>
		</div>
		<div class="px-3 pb-2 k-pd-resource-collection-outer">
			<div v-if="course_specific_resources.length>0">
				<div class="pt-2 pb-1"><b>Resources tagged to this course</b></div>
				<div class="k-resources-list">
					<div v-for="(resource) in course_specific_resources" v-if="search_term_res.length==0||search_results[resource.resource_id]" style="margin:3px; flex:1 1 auto;">
						<ResourceLink
							small
							:resource="resource"
							:enable_hc_assignment="false"
							:mark_district_unsanctioned="false"
							:enable_report_issue="true"
							@report_issue="report_issue"
							@assign_in_hc="assign_resource(null, ...arguments)"
						/>
					</div>
				</div>
			</div>
			<div v-if="non_course_specific_resources.length>0">
				<div class="pt-2 pb-1"><b>Resources tagged to this subject/grade</b></div>
				<div class="k-resources-list">
					<div v-for="(resource) in non_course_specific_resources" v-if="search_term_res.length==0||search_results[resource.resource_id]" style="margin:3px; flex:1 1 auto;">
						<ResourceLink
							small
							:resource="resource"
							:enable_hc_assignment="false"
							:mark_district_unsanctioned="false"
							:enable_report_issue="true"
							@report_issue="report_issue"
							@assign_in_hc="assign_resource(null, ...arguments)"
						/>
					</div>
				</div>
			</div>
		</div>
	</vue-draggable-resizable>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ResourceLink from './ResourceLink'
import '../../js/search_fns.js'

export default {
	components: { ResourceLink },
	props: {
		lp: { type: Object, required: true },
	},
	data() { return {
		search_terms: '',
		search_term_res: [],
		stop_words: [],
		search_results: {},
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters(['small_screen']),
		course_specific_resources() {
			let arr = []
			for (let r of this.lp.pd_resources) {
				if (r.mappings.find(x=>x=='course-' + this.lp.course_code)) {
					arr.push(r)
				}
			}
			return arr
		},
		non_course_specific_resources() {
			let arr = []
			for (let r of this.lp.pd_resources) {
				if (!r.mappings.find(x=>x=='course-' + this.lp.course_code)) {
					arr.push(r)
				}
			}
			return arr
		}
	},
	created() {
	},
	mounted() {
		console.log(this.pd_resources)
	},
	watch: {
		// use this hack to make the vue-draggable-resizable component shift more-or-less-properly to full screen when the window is narrow
		'$vuetify.breakpoint.width': { immediate: true, handler(val) {
			this.accommodate_narrow_window()
		}},
	},
	methods: {
		accommodate_narrow_window() {
			setTimeout(x=>{
				if (this.small_screen) {
					$('.k-case-tree-outer-wrapper').css({width:'100vw', height:'100vh', transform:'none'})
				} else {
					$('.k-case-tree-outer-wrapper').css({width:'560px', height:'560px'})
				}
			}, 10)
		},
		// send issue report requests to vapp
		report_issue(issue_params) {
			// issue_params comes in with resource specified; add lp
			issue_params.learning_progression = this.lp
			vapp.report_issue(issue_params)
		},

		execute_search_start() {
			U.loading_start()
			setTimeout(()=>U.loading_stop(), 100)
			this.execute_search_clear()

			let arr = U.create_search_re(this.search_terms)
			this.search_term_res = arr[0]
			this.stop_words = arr[1]

			let sr = {}

			for (let r of this.course_specific_resources) {
				if (!U.string_includes_stop_word(this.stop_words, r.description) && U.strings_match_search_term_res(this.search_term_res, [r.description])) {
					sr[r.resource_id] = true
				}
			}
			for (let r of this.non_course_specific_resources) {
				if (!U.string_includes_stop_word(this.stop_words, r.description) && U.strings_match_search_term_res(this.search_term_res, [r.description])) {
					sr[r.resource_id] = true
				}
			}
			this.search_results = sr
			console.log(this.search_results)
			if (this.search_results.length == 0) {
				this.$inform({text:'No items matched your search terms.', color:'pink darken-4'})
			}
		},

		execute_search_clear() {
			this.search_term_res = []
			this.stop_words = []
			this.search_results = {}
		},

		search_field_keyup(evt) {
			if (evt.key == 'Enter' || evt.keyCode == 13) {
				this.execute_search_start()
			}
		},

		assign_resource(standard, resource) {
			let case_identifier = ''
			if (!empty(standard)) case_identifier = standard.identifier
			this.$emit('assign_resource', case_identifier, resource)
			this.$emit('show_assignments')
		},
	}
}
</script>

<style lang="scss">
.k-pdr-collection-outer-wrapper {
	right:5px;
	border:5px solid $v-lime;
	background-color:#fff;
	z-index:8!important;	// so the resource tree floats above resource btns in the background

	.k-pdr-collection-top {
		background-color: $v-lime;
		color:$v-grey-darken-4;
		border-radius:2px 2px 0 0;
	}

	.k-resizable-handle-br {
		background-color:#fff;
		border:1px solid #000;
		position:absolute;
		right:-10px;
		bottom:-10px;
		width:16px;
		height:16px;
		cursor: se-resize;
	}
}

.k-pd-resource-collection-outer {
	overflow:auto;
}

</style>
