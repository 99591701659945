<!-- Part of the SPARKL educational activity system, Copyright 2020 by Pepper Williams -->
<template><div class="k-lp-editor-wrapper">
	<h3>Course title, grade(s), subject, and description:</h3>
	<div class="k-lp-editor-top-data elevation-3">
		<div class="d-flex mb-4">
			<v-text-field background-color="#fff" style="font-weight:bold" hide-details outlined label="Course Title" v-model="title" @change="title_changed"></v-text-field>
			<v-text-field background-color="#fff" style="font-weight:bold;max-width:150px" class="ml-4" hide-details outlined label="Course Code" v-model="course_code"></v-text-field>
			<v-text-field background-color="#fff" style="font-weight:bold;max-width:150px" class="ml-4" hide-details outlined label="State Course Code" v-model="state_course_code"></v-text-field>
		</div>

		<div class="d-flex mb-4">
			<v-select v-model="grade_low" :items="grades_low" @change="grade_low_changed" label="Grade Low" dense outlined background-color="#fff" hide-details class="k-lp-grade-menu" :menu-props="{maxHeight:700}"></v-select>
			<v-select v-model="grade_high" :items="grades_high" @change="grade_high_changed" label="Grade High" dense outlined background-color="#fff" hide-details class="k-lp-grade-menu" :menu-props="{maxHeight:700}"></v-select>
			<v-select v-model="subject" :items="subjects" label="Subject" dense outlined background-color="#fff" hide-details class="k-lp-subject-menu" :menu-props="{maxHeight:700}" @change="subject_changed"></v-select>
			<v-select v-model="color" :items="color_select" label="Color" dense outlined background-color="#fff" hide-details class="k-lp-color-menu"></v-select>
			<div class="k-lp-color-swatch" :class="color_swatch_class"></div>
			<v-checkbox class="ml-4 pt-0 mt-2" :label="'Active'" v-model="active" hide-details></v-checkbox>
		</div>

		<div class="d-flex mb-4 align-center">
			<div style="flex:1 0 50%">
				<div class="mb-1"><b>Standards Framework:</b></div>
				<div v-if="!changing_subject_case_title" class="d-flex">
					<div v-html="subject_case_title"></div>
					<v-btn small class="ml-2" color="primary" @click="changing_subject_case_title=true">Change</v-btn>
				</div>
				<div v-if="changing_subject_case_title" class="d-flex">
					<v-select v-model="subject_case_identifier" :items="subject_frameworks" label="Subject Framework" dense outlined background-color="#fff" hide-details class="k-lp-subject-menu" :menu-props="{maxHeight:700}" @change="course_case_identifier=''"></v-select>
					<v-btn small class="ml-2" color="primary" @click="changing_subject_case_title=false">Done</v-btn>
				</div>
			</div>
			<div style="flex:1 0 50%" v-show="subject_case_identifier!='none'">
				<div class="mb-1"><b>Course “Branch” in Standards Framework:</b> (optional)</div>
				<div class="d-flex">
					<div v-if="course_case_title" v-html="course_case_title" class="mr-2"></div>
					<v-btn small color="primary" @click="choose_course_case_identifier">{{course_case_identifier?'Change':'Choose'}}</v-btn>
				</div>
			</div>
		</div>

		<v-textarea class="" background-color="#fff" hide-details outlined label="Course Description (optional)" v-model="description" auto-grow rows="2"></v-textarea>

		<div v-if="resource_collection_ids.length > 0" class="mt-3 text-center">
			<v-btn v-for="(resource_collection_id, i) in resource_collection_ids" :key="resource_collection_id" class="ma-1" style="letter-spacing:0; text-transform:none;" color="secondary" small @click="clear_resource_collection(i)">Clear Resource Collection “{{resource_collection_ids[i]}}” from LP</v-btn>
		</div>
	</div>

	<div class="text-center mt-3 mb-6">
		<v-btn small color="secondary" @click="import_terms_btn_clicked">Import Terms and Units from MS Word</v-btn>
		<v-btn small class="ml-2" color="secondary" @click="import_cc_btn_clicked">Import Resources from Thin Common Cartridge</v-btn>
		<v-btn small class="ml-2" color="secondary" @click="clear_all_assessments">Clear All Assessments</v-btn>
	</div>

	<div class="k-lp-editor-terms">
		<h3 class="ml-3">Terms:</h3>
		<div class="d-flex flex-wrap justify-left">
			<div v-for="(term) in terms" class="k-lp-editor-term elevation-3">
				<div class="d-flex align-center">
					<v-text-field background-color="#fff" color="" style="font-weight:bold" hide-details outlined label="Term (e.g. “Quarter 1”)" v-model="term.title" @change="term_title_changed(term)"></v-text-field>
					<v-text-field background-color="#fff" class="ml-1" hide-details outlined label="Weeks" style="max-width:70px" v-model="term.duration"></v-text-field>
					<v-btn icon x-small color="red lighten-2" class="ml-2" style="margin-right:-3px" @click="delete_term(term)"><v-icon>fas fa-times-circle</v-icon></v-btn>
				</div>
			</div>
			<v-spacer/>
			<div class="mt-4 text-center" style="width:100%">
				<v-btn color="#666" width="300" dark @click="create_new_term">Create New Term</v-btn>
			</div>
		</div>
	</div>

	<div class="k-lp-editor-units">
		<h3 class="ml-3">Units:</h3>
		<div class="d-flex flex-wrap justify-left">
			<div v-for="(unit, i) in units" class="k-lp-editor-unit elevation-2">
				<div class="d-flex align-center">
					<v-text-field background-color="#fff" class="mr-1" hide-details outlined style="font-weight:bold" label="Number (e.g. “Unit 1”)" v-model="unit.title" @change="unit_number_changed(unit)"></v-text-field>
					<v-text-field background-color="#fff" class="ml-1" hide-details outlined label="Weeks" style="max-width:80px" v-model="unit.duration"></v-text-field>
					<div class="ml-2" style="margin-right:-3px">
						<v-btn icon x-small color="#666" @click="move_unit(unit,'up')"><v-icon>fas fa-arrow-circle-up</v-icon></v-btn>
						<v-btn icon x-small color="#666" @click="move_unit(unit,'down')"><v-icon>fas fa-arrow-circle-down</v-icon></v-btn><br>
						<v-btn icon x-small color="red lighten-2" @click="delete_unit(unit)"><v-icon>fas fa-times-circle</v-icon></v-btn>
					</div>
				</div>
				<v-text-field background-color="#fff" class="mt-3" hide-details outlined label="Unit Description (e.g. “Matter”)" v-model="unit.description"></v-text-field>
			</div>
			<v-spacer/>

			<div class="mt-4 text-center" style="width:100%">
				<v-btn color="#666" dark width="300" @click="create_new_unit()">Create New Unit</v-btn>
			</div>
		</div>
	</div>

	<v-card-actions class="mt-4">
		<v-spacer></v-spacer>
		<v-btn color="secondary" @click="cancel_editor" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
	</v-card-actions>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
		learning_progression: { type: Object, required: true },
	},
	data() { return {
		import_terms_showing: false,
		title: this.learning_progression.title,
		course_code: this.learning_progression.course_code,
		state_course_code: this.learning_progression.state_course_code,
		subject_case_identifier: this.learning_progression.subject_case_identifier,
		course_case_identifier: this.learning_progression.course_case_identifier,
		description: this.learning_progression.description,
		grade_low: this.learning_progression.grade_low,
		grade_high: this.learning_progression.grade_high,
		subject: this.learning_progression.subject,
		color: this.learning_progression.color,
		active: (this.learning_progression.active == 'yes'),
		terms: [],	// copied below
		units: [],	// copied below
		resource_collection_ids: this.learning_progression.resource_collection_ids.concat([]),

		changing_subject_case_title: false,

		import_warning_shown: false,
		import_terms_data: '',

		unit_deletion_confirmed: false,

		initial_values: JSON.stringify(this.learning_progression.copy_for_save()),
	}},
	computed: {
		...mapState(['user_info', 'frameworks_loaded', 'framework_records']),
		grades() {
			let arr = []
			for (let grade of this.$store.state.grades) {
				let text
				if (grade == 'PK') text = 'Pre-K'
				else if (grade == 'K') text = 'Kindergarten'
				else if (!isNaN(grade*1)) text = 'Grade ' + grade
				else text = grade

				arr.push({ value: grade, text: text})
			}
			return arr
		},
		grades_low() {
			return [{value:'', text:'GRADE RANGE START'}].concat(this.grades)
		},
		grades_high() {
			return [{value:'', text:'GRADE RANGE END'}].concat(this.grades)
		},
		subjects() {
			let arr = []
			for (let subject in this.$store.state.subjects) {
				arr.push({ value: subject, text: subject})
			}
			return arr
		},
		subject_frameworks() {
			let arr = []
			for (let subject in this.$store.state.subjects) {
				arr.push({ value: this.$store.state.subjects[subject], text: subject})
			}
			// arr.push({value: 'none', text: 'None (don’t show course standards for this framework)'})
			return arr
		},
		subject_case_title() {
			if (this.subject_case_identifier == 'none') return 'No course standards'

			let f = this.framework_records.find(x=>x.lsdoc_identifier == this.subject_case_identifier)
			if (f) return f.json.CFDocument.title
			else return this.subject_case_identifier
		},
		course_case_title() {
			if (!this.course_case_identifier || this.subject_case_identifier == 'none') return ''

			let f = this.framework_records.find(x=>x.lsdoc_identifier == this.subject_case_identifier)
			if (f) {
				if (!f.framework_json_loaded) {
					this.$store.dispatch('get_lsdoc', f.lsdoc_identifier)
					return 'Loading…'
				} else if (f.json.CFItems) {
					let i = f.json.CFItems.find(x=>x.identifier == this.course_case_identifier)
					if (i) {
						return U.generate_cfassociation_node_uri_title(i, true)
					}
				}
			}
			// if we get to here, return the identifier
			return this.course_case_identifier
		},
		color_select() { return this.$store.state.color_select },
		color_swatch_class() {
			if (!this.color) return ''
			else return 'k-list-color-' + this.color
		},
	},
	created() {
	},
	mounted() {
		// create a new lp to deep-copy the terms
		// let copy = new Learning_Progression(this.learning_progression)
		// this.terms = copy.terms
		console.log('LearningProgressionEdit', this.learning_progression)
		JSON.stringify(this.learning_progression.copy_for_save())

		// create a copy of the lp to get a copy of of terms and units
		let lp_copy = new Learning_Progression(this.learning_progression)
		this.terms = lp_copy.terms
		this.units = lp_copy.units

		// get frameworks list if not already loaded -- but we don't have to wait for it to load
		if (!this.frameworks_loaded) this.$store.dispatch('get_lsdoc_list')

		// if subject_case_identifier isn't set but subject is, set subject_case_identifier
		if (!this.subject_case_identifier && this.subject) { 
			this.subject_case_identifier = this.$store.state.subjects[this.subject]
		}

		vapp.lp_editor_component = this
	},
	methods: {
		title_changed() {
			if (empty(this.title)) return

			// try to extract grade and subject from title if not already set
			if (empty(this.grade_low)) {
				if (this.title.search(/KK/i) > -1) {
					this.grade_low = 'K'
					this.grade_high = 'K'
				} else if (this.title.search(/(\d+)/i) > -1) {
					let grade = RegExp.$1 * 1
					if (grade >= 1 && grade <= 12) {
						this.grade_low = grade+''
						this.grade_high = grade+''
					}
				}
			}

			if (empty(this.subject)) {
				for (let subject in this.$store.state.subjects) {
					if (this.title.search(new RegExp(subject)) > -1) {
						this.subject = subject
						break
					}
				}
			}
		},

		choose_course_case_identifier() {
			if ((empty(this.subject) || this.subject == '-') && empty(this.course_case_identifier)) {
				this.$alert('You must choose a Subject, or choose a Standards Framework, in order to choose a course CASE identifier.')
				return
			}
			
			let data = { framework_identifier: this.subject_case_identifier }

			// set hide_fn to hide the standards chooser if/when the editor is no longer visible
			// let show_data = { hide_fn: ()=>{ return ($(vapp.course_editor?.$el).is(':visible') == false) } }
			let show_data = {}

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// if for some reason the user chose a different framework, save it as the subject_case_identifier
						if (aligned_item.framework_identifier != this.subject_case_identifier) {
							this.subject_case_identifier = aligned_item.framework_identifier
						}
						this.course_case_identifier = aligned_item.cfitem.identifier

						// hide the chooser, because we only choose one item here
						vapp.$refs.satchel.execute('hide')
					})
				})
			})
		},

		subject_changed() {
			// if subject is chosen/changed...
			if (!empty(this.$store.state.subjects[this.subject])) {
				// if subject_case_identifier wasn't originally entered or is currently empty, or if it equals the value for the original subject,
				if (empty(this.subject_case_identifier) || this.learning_progression.subject_case_identifier == this.$store.state.subjects[this.learning_progression.subject]) {
					// enter the value for the new subject
					this.subject_case_identifier = this.$store.state.subjects[this.subject]
				}
			}
		},

		grade_low_changed() {
			if (empty(this.grade_high) || U.grade_value(this.grade_high) < U.grade_value(this.grade_low)) {
				this.grade_high = this.grade_low
			}
		},

		grade_high_changed() {
			if (empty(this.grade_low) || U.grade_value(this.grade_low) > U.grade_value(this.grade_high)) {
				this.grade_low = this.grade_high
			}
		},

		create_new_term() {
			this.terms.push({
				title: '',
				duration: ''
			})
		},

		delete_term(term) {
			// if we get to here just delete the term after confirming
			this.$confirm({
				text: 'Are you sure you want to delete this term?',
				acceptText: 'Delete Term',
			}).then(y => {
				this.terms.splice(this.terms.findIndex(x=>x==term), 1)
			}).catch(n=>{}).finally(f=>{});
		},

		term_title_changed(term) {
			// if user enters just a number, change to "Quarter #"
			if (!empty(term.title) && !isNaN(term.title*1)) {
				term.title = 'Quarter ' + term.title
			}
		},

		create_new_unit() {
			let u = new LP_Unit()
			// remove duration value so label shows in input
			u.duration = ''
			this.units.push(u)
		},

		delete_unit(unit) {
			if (this.unit_deletion_confirmed) {
				this.units.splice(this.units.findIndex(x=>x==unit), 1)

			} else {
				this.$confirm({
					text: 'Are you sure you want to delete this unit? (Note: you will not be asked to confirm subsequent unit deletions.)',
					acceptText: 'Delete Unit',
				}).then(y => {
					this.units.splice(this.units.findIndex(x=>x==unit), 1)
					// don't ask again
					this.unit_deletion_confirmed = true
				}).catch(n=>{}).finally(f=>{});
			}
		},

		move_unit(unit, direction) {
			let i = this.units.findIndex(x=>x==unit)

			if (direction == 'up') {
				if (i > 0) {
					this.units.splice(i, 1)
					this.units.splice(i-1, 0, unit)
				}
			} else {
				if (i < this.units.length - 1) {
					this.units.splice(i, 1)
					this.units.splice(i+1, 0, unit)
				}
			}
		},

		unit_number_changed(unit) {
			// if user enters just a number, change to "Unit #"
			if (!isNaN(unit.title*1)) {
				unit.title = 'Unit ' + unit.title
			}
		},

		clear_resource_collection(index) {
			// clear the last resource_collection_ids value
			this.resource_collection_ids.splice(index, 1)
		},

		// deletes the resource_id of any resources type=assessment from learning_progression_unit.json.resource_ids
		clear_all_assessments() {
			let payload = {
				lp_id: this.learning_progression.lp_id,
				user_id: this.user_info.user_id
			}

			this.$confirm({
			    title: 'Clearing all assessments',
			    text: 'This action will clear all assessments from a Learning Progression and then refresh the course. If you have other edits in progress, save the edits first and then come back to clear the assessments.',
			    acceptText: 'Clear Assessments',
			}).then(y => {
				U.loading_start()
				U.ajax('delete_lp_assessments', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						alert('An error occurred when attempting to delete the assessments for this course.')
						return
					}
					document.location.pathname = sr('/courses/lp/$1/0/0', this.learning_progression.course_code)
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		save_edits(course_code_confirmed) {
			if (empty(this.title)) {
				this.$alert('The learning progression must have a title.')
				return
			}

			if (empty(this.course_code)) {
				this.$alert('You must enter the course code for the learning progression.')
				return
			}

			// don't allow for course codes that have anything other than a number, and restrict new ones to between 1 and 49999
			if (this.course_code.search(/\D/) > -1 || (this.learning_progression.course_code == '' && (this.course_code*1 < 1 || this.course_code*1 >= 50000))) {
				this.$alert('Course codes must be integers between 1 and 49999.')
				return
			}

			// if this is a new lp, check to make sure the course_code doesn't already exist
			let is_new_lp = false
			if (empty(this.learning_progression.course_code)) {	// note that this is checking the course_code sent in via props, not what the user entered
				is_new_lp = true

				let e = this.$store.state.all_courses.find(o=>o.course_code==this.course_code)
				if (e) {
					this.$alert(sr('A Learning Progression for this course code ($1) already exists (the LP is titled “$2”). You cannot replace Learning Progressions from here. Edit existing Learning Progressions from the “Courses” area of the HenryConnects home page.', e.course_code, e.title))
					return
				}
			}

			if (!empty(this.learning_progression.course_code) && this.course_code != this.learning_progression.course_code && course_code_confirmed !== true) {
				this.$confirm({
				    title: 'Are you sure?',
				    text: 'Are you sure you want to change the Course Code? Only do this if you know what you’re doing!',
				    acceptText: 'I’m Sure',
				}).then(y => {
					this.save_edits(true)
				}).catch(n=>{console.log(n)}).finally(f=>{})

				return
			}

			if (empty(this.grade_low) || empty(this.grade_high)) {
				this.$alert('You must set the start and end of the grade range (the minimum and maximum grade levels can be equal).')
				return
			}

			// we no longer force the entry of a subject
			// if (empty(this.subject)) {
			// 	this.$alert('You must set the subject area.')
			// 	return
			// }

			// validate term/unit week values
			for (let term of this.terms) {
				if (isNaN(term.duration*1)) {
					this.$alert('You must enter a numeric value for “Weeks” for all terms. (Note that “0” <i>is</i> a valid entry.)')
					return
				}
			}
			for (let unit of this.units) {
				if (isNaN(unit.duration*1)) {
					this.$alert('You must enter a numeric value for “Weeks” for all units. (Note that “0” <i>is</i> a valid entry.)')
					return
				}
			}

			this.$store.commit('set', [this.learning_progression, 'title', this.title])
			this.$store.commit('set', [this.learning_progression, 'course_code', this.course_code])
			this.$store.commit('set', [this.learning_progression, 'state_course_code', this.state_course_code])
			this.$store.commit('set', [this.learning_progression, 'description', this.description])
			this.$store.commit('set', [this.learning_progression, 'subject_case_identifier', this.subject_case_identifier])
			this.$store.commit('set', [this.learning_progression, 'course_case_identifier', this.course_case_identifier])
			this.$store.commit('set', [this.learning_progression, 'grade_low', this.grade_low])
			this.$store.commit('set', [this.learning_progression, 'grade_high', this.grade_high])
			this.$store.commit('set', [this.learning_progression, 'subject', this.subject])
			this.$store.commit('set', [this.learning_progression, 'color', this.color])
			this.$store.commit('set', [this.learning_progression, 'terms', this.terms])
			this.$store.commit('set', [this.learning_progression, 'units', this.units])
			this.$store.commit('set', [this.learning_progression, 'resource_collection_ids', this.resource_collection_ids])
			this.$store.commit('set', [this.learning_progression, 'active', this.active ? 'yes' : 'no'])

			if (JSON.stringify(this.learning_progression.copy_for_save()) != this.initial_values) {
				this.$store.dispatch('save_learning_progression', this.learning_progression).then(()=>{
					// if course code changed, have to refresh to the new course
					if (course_code_confirmed === true) {
						let path = sr('/courses/lp/$1/0/0', this.course_code)
						document.location.pathname = path
					} else {
						// if is_new_lp, show alert
						if (is_new_lp) {
							this.$alert('Learning Progression created. You will need to reload your browser window to see the new course appear in the Learning Progression index.')
						}
						let edit_type = (is_new_lp) ? "is_new_lp" : "updated"
						this.cancel_editor(edit_type)
					}
				})
			} else if (course_code_confirmed === true) {
				// if we didn't save and course_code_confirmed is true, we still have to reload (we use this flag for reloading after a resource collection change)
				document.location.reload()
			} else {
				console.log('nothing changed, so not saving')
				this.cancel_editor('cancelled')
			}
		},

		cancel_editor(edit_action) {
			if (typeof(edit_action) !== 'string') edit_action = 'cancelled'
			if (edit_action != 'is_new_lp') {
				this.$store.dispatch('lp_edit_close_socketmsg', {edit_action: edit_action, course_code: this.learning_progression.course_code})
			}

			this.$nextTick(()=>this.$emit('editor_cancel'))
		},

		import_terms_btn_clicked() {
			// prompt for file to import
			this.$prompt({
				text: '<p>Open the Learning Progression document in Microsoft Word, choose “Save As” from the File menu, then save the document in “Web Page, filtered (.htm)” format. Then use the file upload interface below to find and process the .htm file you just saved:</p>',
				promptType: 'file',
				acceptText: 'Process File',
				cancelText: 'Cancel'
			}).then(file => {
				if (file.type != 'text/html') {
					this.$alert('You must save the file in “Web Page, filtered (.htm)” format, which will result in a file that ends in “.htm” or “.html” The file you attempted to process doesn’t have the right extension.').then(()=>this.import_terms_btn_clicked())
					return
				}

				let reader = new FileReader()
				reader.onload = e => {
					this.process_lp_html(e.target.result)
				}
				reader.readAsText(file)

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		process_lp_find_next_unit(units, param) {
			// find the next unit whose param is empty or 0
			for (let unit of units) {
				if (empty(unit[param]) || unit[param] === 0) return unit
			}
			return null
		},

		process_lp_html(html) {
			let report = ''
			let has_errors = false

			// get the html and find the "WordSection1" node
			let nodes = $.parseHTML(html)
			let jq = $(nodes)
			// for (let node of nodes) {
			// 	jq = $(node)
			// 	if (jq.hasClass('WordSection1')) break
			// }

			if (empty(jq)) { this.show_lp_import_error(1, report); return; }

			let terms = []
			let units = []

			function add_to_report(s, level) {
				console.log(s)

				// tags
				s = s.replace(/</g, '&lt;')

				// if line starts with **, mark as possible error
				if (s.indexOf('**') == 0) {
					s = s.replace(/^(\*\*.*)/, '<b style="color:red">$1</b>')
					has_errors = true
				}

				// indentation
				for (let li = 0; li < level; ++li) {
					s = '  ' + s
				}

				report += s + '<br>'
			}

			// document may have multiple tables...
			let tables = jq.find('table')
			add_to_report(sr('Found $1 tables',tables.length), 0)
			for (let ti = 0; ti < tables.length; ++ti) {
				add_to_report('Processing table #' + (ti+1), 0)
				// look for rows in the table
				let rows = $(tables[ti]).find('tr')
				if (empty(rows) || rows.length == 0) {
					add_to_report('*** No rows in table #' + ti, 1)
					continue
				}

				// go through each row of the table...
				let last_row = ''
				let terms_repeat = false
				for (let r = 0; r < rows.length; ++r) {
					let row = $(rows[r])
					let rt = $.trim(row.text()).toLowerCase()

					// if we find a row that starts with 'quarter/semester/term', that row should specify the terms
					if (rt.search(/(quarter|semester|term)/) == 0 && last_row == '') {
						add_to_report('')
						add_to_report('found terms...', 1)
						last_row = 'terms'

						for (let td of row.find('td')) {
							let $td = $(td)
							let term_title = $.trim($td.text().replace(/\s+/g, ' '))

							if (empty(term_title)) {
								add_to_report('*** Empty td in term row', 2)
							} else {
								// if the first term_title we find matches the first term_title we have in terms,
								// assume that this is a "repeat table" that just includes additional text
								if (terms.length > 0 && term_title == terms[0].title) {
									terms_repeat = true
									add_to_report('*** Assuming this table includes repeated terms from previous table')
									break
								}

								terms.push({
									title: term_title,
									duration: 9,	// assume 9 weeks
								})
								add_to_report('term: ' + terms[terms.length-1].title, 2)
							}
						}

						continue	// done with the row
					}

					// if we find a row that starts with 'unit' after the 'terms' row (or before we process any row -- we may have "term-less units"),
					// this row should specify the unit numbers, which we store as the "title"
					if (rt.search(/(unit)/) == 0 && (last_row == 'terms' || last_row == '')) {
						add_to_report('')
						add_to_report('found units...', 1)
						last_row = 'units'

						if (terms_repeat == true) {
							add_to_report('*** Assuming this table includes repeated units from previous table')
							continue
						}

						// go through each unit's td
						for (let td of row.find('td')) {
							let title = $.trim($(td).text().replace(/\s+/g, ' '))
							if (empty(title)) {
								add_to_report('*** Empty td in unit title row', 2)
							} else {
								let unit = new LP_Unit({title: title})
								units.push(unit)
								add_to_report('unit: ' + unit.title, 2)
							}
						}

						continue	// done with the row
					}

					// if we find a row that includes the word 'week' right after the 'units' row, this row should specify the unit durations, which we store as "duration"
					if (rt.search(/(week)/) > -1 && last_row == 'units') {
						add_to_report('')
						add_to_report('found durations...', 1)
						last_row = 'durations'

						if (terms_repeat == true) {
							add_to_report('*** Assuming this table includes repeated units from previous table')
							continue
						}

						// go through each unit's td
						for (let td of row.find('td')) {
							let duration_text = $.trim($(td).text().replace(/\s+/g, ' ')).toLowerCase()
							if (empty(duration_text)) {
								add_to_report('*** Empty td in unit duration row', 2)
							} else {
								let duration = duration_text.replace(/([\d.]+) week(s)?/i, '$1')*1
								if (isNaN(duration)) {
									add_to_report('*** Bad unit duration found: ' + $(td).text(), 2)
									duration = 1
								}

								// find the next unit to fill in
								let unit = this.process_lp_find_next_unit(units, 'duration')
								if (empty(unit)) {
									add_to_report('*** More unit durations found than units -- possible error')
									continue
								}

								unit.duration = duration
								add_to_report(sr('unit “$1” duration: $2', unit.title, unit.duration), 2)
							}
						}

						continue	// done with the row
					}

					// the row right after durations should specify the unit “titles”, which we store as 'description'
					if (last_row == 'durations') {
						add_to_report('')
						add_to_report('found descriptions...', 1)
						last_row = 'descriptions'

						if (terms_repeat == true) {
							add_to_report('*** Assuming this table includes repeated units from previous table')
							continue
						}

						// go through each unit's td
						for (let td of row.find('td')) {
							// TODO: do we need to worry about preserving html here? hopefully not
							let description = $.trim($(td).text().replace(/\s+/g, ' '))
							if (empty(description)) {
								add_to_report('*** Empty td in unit description row', 2)
							} else {
								// find the next unit to fill in
								let unit = this.process_lp_find_next_unit(units, 'description')
								if (empty(unit)) {
									add_to_report('*** More unit titles found than units -- possible error')
									continue
								}

								unit.description = description
								add_to_report(sr('unit “$1” description: $2', unit.title, unit.description), 2)
							}
						}

						continue	// done with the row
					}

					// the row right after descriptions should be the unit text
					if (last_row == 'descriptions') {
						add_to_report('')
						add_to_report('found unit text...', 1)
						last_row = 'text'

						// go through each unit's td
						let ui = -1
						for (let td of row.find('td')) {
							++ui
							let text_text = $.trim($(td).text().replace(/\s+/g, ' '))
							if (empty(text_text)) {
								add_to_report('*** Empty td in unit text row', 2)
							} else {
								// find the next unit to fill in
								let unit
								if (terms_repeat) {
									unit = units[ui]
								} else {
									unit = this.process_lp_find_next_unit(units, 'text')
								}

								if (empty(unit)) {
									add_to_report('*** More unit text columns found than units -- possible error')
									continue
								}

								// clean contents...
								$(td).find('*').each(function(i, el) {
									let tag = $(el).prop('tagName')
									let html = $(el).html()
									html = html.replace(/\&nbsp;/g, ' ')
									html = html.replace(/style=".*?"/g, '')
									html = html.replace(/<(\/)?span\b.*?>/g, '')
									$(el).replaceWith(sr('<$1>$2</$3>', tag, $.trim(html), tag))
								})
								let text = $(td).html().replace(/<p>(\s)*<\/p>/g, '')	// remove blank lines
								text = text.replace(/\s+/g, ' ')	// consolidate spaces
								text = $.trim(text)

								unit.text += text
								add_to_report(sr('unit “$1” text: $2…', unit.title, unit.text.substr(0, 20)), 2)
							}
						}

						break	// once we do this row, we're done processing
					}
				}
				add_to_report('')
			}

			// clear units that have no title, duration, or description
			for (let i = 0; i < units.length; ++i) {
				if (units[i].title == '' && units[i].duration == 0 && units[i].description == '') {
					units.splice(i, 1)
					--i
				}
			}

			// if we have existing unit ids, reuse them
			for (let i = 0; i < this.learning_progression.units.length; ++i) {
				if (this.learning_progression.units[i].lp_unit_id != 0) {
					units[i].lp_unit_id = this.learning_progression.units[i].lp_unit_id
				}
			}

			add_to_report('DONE!')
			console.log(terms, units)

			if (terms.length > 0 || units.length > 0) {
				this.terms = terms
				this.units = units
				let msg = sr('Imported $1 terms and $2 units. After closing this dialog, click “Save” at the bottom of the page to save the imported terms and units, or “Cancel” to cancel the import and revert to any previously-saved terms and units.', terms.length, units.length)
				if (has_errors) {
					msg += '<br><br><b style="color:red">Note:</b> Possible errors were found in the import process; see Import Report.'
				}
				this.$confirm({
				    title: 'File Processed',
				    text: msg,
				    acceptText: 'Done',
				    cancelText: 'View Import Report',
					dialogMaxWidth: 600
				}).then(y => {
				}).catch(n=>{
					report = sr('<pre>$1</pre>', report)
					this.$alert({title: 'Import Processing Report', text: report, dialogMaxWidth:800})
				}).finally(f=>{})

				this.import_terms_showing = false
			} else {
				this.$alert('No terms or units imported. Sorry!!')
			}
		},

		show_lp_import_error(errnum, report) {
			report = sr('<pre>$1</pre>', report)
			this.$alert({title: sr('Error parsing file ($1)', errnum), text: report, dialogMaxWidth:800})
		},

		import_cc_btn_clicked() {
			// prompt for file to import
			this.$prompt({
				text: '<p>Use the file upload interface below to find the Thin Common Cartridge (.imscc) file on your computer, then click the button to process the TCC file.</p>',
				promptType: 'file',
				acceptText: 'Process TCC File',
				cancelText: 'Cancel'
			}).then(file => {
				// console.log(file)
				if (file.type != 'application/zip' && file.name.indexOf('.imscc') == -1) {
					this.$alert('You must upload a valid Thin Common Cartridge file (.imscc). The file you attempted to process doesn’t have the right extension.').then(()=>this.import_cc_btn_clicked())
					return
				}

				const JSZip = require("jszip")
				JSZip.loadAsync(file).then(zip => {
					// console.log('zip: ', zip)
					zip.file("imsmanifest.xml").async("string").then(xml => {
						// console.log('xml: ', xml)
						this.process_cc_xml(xml)
					});
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		process_cc_xml(xml) {
			let $xml = $($.parseXML(xml))

			function get_text_from_child_tags(node, arr, obj, key) {
				let child_node = node
				// search down for the tag_names given in arr
				for (let tag_name of arr) {
					child_node = child_node.getElementsByTagName(tag_name)
					if (child_node.length > 0) {
						child_node = child_node[0]
					} else {
						break
					}
				}
				// if we don't find the child_node, do nothing
				if (empty(child_node) || empty(child_node.textContent)) {
					return ''
				}

				// if obj is 'text', return the trimmed content
				if (obj == 'text') return $.trim(child_node.textContent)

				// if obj is 'node', return the node
				if (obj == 'node') return child_node

				// else we should have received obj/key; set key to the trimmed content
				if (!empty(obj)) {
					obj[key] = $.trim(child_node.textContent)
				}
			}

			let xcount = 0
			function get_toc_item(resource_xml_hash, resources, item_node, level) {
				if (empty(item_node.children)) return null

				let o = {}
				for (let child_node of item_node.children) {
					// extract title
					if (child_node.tagName == 'title') o.t = $.trim(child_node.textContent)

					// get child items
					if (child_node.tagName == 'item') {
						let child = get_toc_item(resource_xml_hash, resources, child_node, level + 1)
						if (!empty(child)) {
							if (empty(o.c)) o.c = []
							o.c.push(child)
						}
					}

					// if the item has metadata,...
					if (child_node.tagName == 'metadata') {
						// then if we have an identifierref, and we find a resource matching it, get/process the resource
						let identifierref = item_node.getAttribute('identifierref')
						if (!empty(identifierref)) {
							// get resource from resource_xml_hash
							let resource = resource_xml_hash[identifierref]
							if (resource) {
								// NEW as of 8/24/2023: add the course_code to the resource_id, because HMH has started making cartridges where they don't consistently use unique identifiers
								let resource_id = `rc-${lthis.course_code}-${identifierref}`

								// store the resource_id in the object
								o.r = resource_id

								// if we already have this resource in the resources array, don't duplicate it
								if (empty(resources.find(x=>x.resource_id == resource_id))) {
									// note that we use the course_code + identifierref from the CC file as the resource_id; if the tcc file is re-uploaded, old resources will be overwritten (which is what we want)
									let resource_json = {
										// we store the title in 'description', and the description in 'long_description'
										description: o.t,
										// description: o.t.normalize('NFC'),
										resource_id: resource_id,
										type: 'lti',
										lti_params: {},
										// assume resources uploaded this way are district_sanctioned and shareable
										district_sanctioned: true,
										shareable: true,
									}

									// look for a long_description, which comes out of the child_node itself
									get_text_from_child_tags(child_node, ['lomr:lom', 'lomr:general', 'lomr:description'], resource_json, 'long_description')

									// TODO: do some error checking here!

									// get other values from the resource...
									// lti endpoint
									get_text_from_child_tags(resource, ['lticc:cartridge_basiclti_link', 'blti:secure_launch_url'], resource_json.lti_params, 'endpoint')

									// role (learner or instructor); need to search through nodes for this...
									let ed_nodes_parent = get_text_from_child_tags(resource, ['metadata', 'lomr:lom', 'lomr:educational'], 'node')
									if (!empty(ed_nodes_parent) && ed_nodes_parent.children.length > 0) {
										let ed_nodes = ed_nodes_parent.getElementsByTagName('lomr:intendedEndUserRole')
										for (let ed_node of ed_nodes) {
											if (get_text_from_child_tags(ed_node, ['lomr:source'], 'text') == 'IMSGLC_CC_Rolesv1p2') {
												// look for role in metadata / lomr:lom / lomr:educational / lomr:intendedenduserrole
												// where lomr:source is 'IMSGLC_CC_Rolesv1p2';
												// if that node's lomr:value is 'Instructor', it's teacher-facing; otherwise assume student-facing
												let role = get_text_from_child_tags(ed_node, ['lomr:value'], 'text')
												resource_json.teacher_facing = ((''+role).toLowerCase() == 'instructor')

												// if teacher-facing, store "i:1" ('i' meaning 'instructor') in o (i.e. in the resource_collection_json)
												if (resource_json.teacher_facing) o.i = 1
											}
										}
									}

									// lti custom tags: there may be multiple of these, so get the node, and if it exists and has children...
									let custom_node = get_text_from_child_tags(resource, ['lticc:cartridge_basiclti_link', 'blti:custom'], 'node')
									if (!empty(custom_node) && custom_node.children.length > 0) {
										for (let custom_prop_node of custom_node.children) {
											// check that the child is called 'lticm:property', and extract the name attribute and text
											if (custom_prop_node.tagName == 'lticm:property') {
												// the key parameters we get for HMH are `resource_url` and `resource_isbn`
												let prop_name = 'custom_' + custom_prop_node.getAttribute('name')
												resource_json.lti_params[prop_name] = $.trim(custom_prop_node.textContent)
											}
										}
									}

									// CASE standards: there may be multiple of these, so get the node, and if it exists and has children...
									let standard_nodes_parent = get_text_from_child_tags(resource, ['metadata', 'csm:curriculumStandardsMetadataSet', 'csm:curriculumStandardsMetadata', 'csm:setOfGUIDs'], 'node')
									if (!empty(standard_nodes_parent) && standard_nodes_parent.children.length > 0) {
										// each child should have structure `<csm:labelledGUID><csm:label>xxx</csm:label><csm:GUID>yyy</csm:GUID></csm:labelledGUID>`
										for (let standard_node of standard_nodes_parent.children) {
											// check that the child is called 'csm:labelledGUID', and extract the text of the csm:GUID tag
											if (standard_node.tagName == 'csm:labelledGUID') {
												// push case_identifier into array in resource_json
												let case_identifier = get_text_from_child_tags(standard_node, ['csm:GUID'], 'text')
												if (empty(resource_json.case_identifiers)) resource_json.case_identifiers = []
												resource_json.case_identifiers.push(case_identifier)
											}
										}
									}

									// use the Resource class/copy_for_save to make sure we get the data in the right format
									resources.push(new Resource(resource_json).copy_for_save())

									++xcount
									console.log(sr('level $1 count $2: $3', level, xcount, resource_id))
								}
							}
						}
					}
					// note that the item will likely have either child items *or* a resource; but handle the case where we have both
				}

				// if we didn't get a title or children, return null (not sure if this would happen...)
				if (empty(o.t) && empty(o.c)) return null

				// if we did get children, store the identifier of the item as 'f'; we use these to mark "folders" of items to include for certain units
				if (!empty(o.c)) {
					o.f = item_node.getAttribute('identifier')
				}

				return o
			}

			// try to find the root toc item in the cc $xml
			let toc_item = $xml.find('organization[structure="rooted-hierarchy"] item[identifier=root]')
			if (toc_item.length != 1) {
				this.$alert(sr('The Thin Common Cartridge file could not be processed (error 483:)', toc_item.length))
				return
			}

			// get a hash of all resources, referenced by identifier. This is much faster than doing $xml.find individually on each identifier
			let resource_xml_hash = {}
			let $resources = $xml.find('resource[identifier]')
			for (let i = 0; i < $resources.length; ++i) {
				let identifier = $resources[i].getAttribute('identifier')
				resource_xml_hash[identifier] = $resources[i]
			}
			console.log(resource_xml_hash)

			let lthis = this
			let resources, resource_collection_json

			// look for an identifier in the manifest, to use as the resource_id of the "collection resource"; if not found use a uuid
			let new_resource_collection_id = $xml.find('manifest').attr('identifier')
			if (empty(new_resource_collection_id)) new_resource_collection_id = U.new_uuid()
			// NEW as of 8/24/2023: add the course_code to the resource_id, because HMH has started making cartridges where they don't consistently use unique identifiers
			new_resource_collection_id = `rc-${this.course_code}-${new_resource_collection_id}`

			// if we have one resource collection already...
			let add_to_existing_resources = false
			if (this.resource_collection_ids.length == 1) {
				let msg, cancelText
				// if the overall resource collection ID is the same as the existing one, ask if they want to add to the current one or replace it
				if (this.resource_collection_ids[0] = new_resource_collection_id) {
					msg = 'Would you like to APPEND resources from the new common cartridge onto the end of the existing resource collection you’ve already uploaded for this course, or REPLACE the existing resource collection with the resources from the new common cartridge?'
					cancelText = 'Replace existing collection'

				} else {
					// else ask if they want to add to the current one or create a new collection
					msg = 'Would you like to APPEND resources from the new common cartridge onto the end of the existing resource collection you’ve already uploaded for this course, or create a NEW (second) resource collection for the course?'
					cancelText = 'Create new collection'
				}

				this.$confirm({
					text: msg,
					acceptText: 'Append to existing collection',
					cancelText: cancelText,
					dialogMaxWidth: 800,
				}).then(y => {
					// add to existing collection -- use the old resource_collection_id as new_resource_collection_id
					new_resource_collection_id = this.resource_collection_ids[0]
					add_to_existing_resources = true
					import_cc_continue()
				}).catch(n=>{
					// create new collection or replace existing -- for either of these options, add_to_existing_resources is false
					import_cc_continue()
				}).finally(f=>{})

			} else {
				// create new collection, or overwrite the existing resource collection if it already exists
				import_cc_continue()
			}

			function import_cc_continue() {
				// extract toc tree and resources. we have to delay starting the process a bit to allow the loading indicator/message to appear
				U.loading_start('Processing Common Cartridge file; this make a take some time...')
				setTimeout(()=>{
					resources = []
					resource_collection_json = get_toc_item(resource_xml_hash, resources, toc_item[0], 0)
					console.log(resource_collection_json)
					console.log(resources)

					// U.download_json_file(resource_collection_json, 'TCC-TREE-' + lthis.learning_progression.title)
					// U.download_json_file(resources, 'TCC-RESOURCES-' + lthis.learning_progression.title)

					U.loading_stop()

					// processing complete. If they're adding to the existing collection, finish; otherwise ask what to call it
					if (add_to_existing_resources) {
						let msg = sr('Found $1 resources (see browser debugger to inspect data). To add the resources to the current resource collection for the Learning Progression click “Save”.', resources.length)
						lthis.$confirm({
							title: 'Processing Successful',
							text: msg,
							acceptText: 'Save',
						}).then(y => {
							// title won't matter, because we won't update it in save_resource_collection
							import_cc_finish('update')
						}).catch(n=>{console.log(n)}).finally(f=>{})

					} else {
						let msg = sr('Found $1 resources (see browser debugger to inspect data). To save the resources in the system and link them to this Learning Progression, enter a title for the resource collection (e.g. “Science 3rd Grade HMH Resources”) and click “Save”.', resources.length)
						lthis.$prompt({
							title: 'Processing Successful',
							text: msg,
							acceptText: 'Save',
						}).then(resource_collection_title => {
							if (empty(resource_collection_title)) return
							import_cc_finish(resource_collection_title)

						}).catch(n=>{console.log(n)}).finally(f=>{})
					}
				}, 50)
			}

			function import_cc_finish(resource_collection_title) {
				// finally save the resources
				let payload = {
					new_resource_collection_id: new_resource_collection_id,
					resource_collection_title: resource_collection_title,
					resource_collection_json: resource_collection_json,
					resources: resources,
					add_to_existing_resources: add_to_existing_resources,
				}
				lthis.$store.dispatch('save_resource_collection', payload).then((new_resource_collection_id)=>{
					// when save is complete...
					// push the resource collection's resource_id onto resource_collection_ids, unless it already exists (in which case we just re-processed the TCC)
					if (!lthis.resource_collection_ids.find(o=>o==new_resource_collection_id)) {
						lthis.resource_collection_ids.push(new_resource_collection_id)
					}
					// then save the LP; by sending true we'll refresh the window after saving
					lthis.save_edits(true)
				})
			}
		},
	}
}
</script>

<style lang="scss">
.k-lp-editor-wrapper {
	clear:both;
	margin:10px;
	padding-top:15px;
	font-size:16px;
	h3 { padding-left:0!important; }

	.k-lp-editor-top-data {
		background-color:$v-amber-lighten-5;	// :#f08c78;
		padding:15px;
		margin:15px 0;
		border-radius:4px;
	}

	.k-lp-grade-menu {
		max-width:150px;
		margin-left:10px;
	}
	.k-lp-subject-menu {
		max-width:250px;
		margin-left:10px;
	}
	.k-lp-color-menu {
		max-width:150px;
		margin-left:10px;
	}

	.k-lp-color-swatch {
		width:30px;
		height:30px;
		background-color:#999;
		align-self: center;
		margin-left:5px;
		border-radius:8px;
	}

	.k-lp-editor-terms {
		background-color:#92c3e9;
		padding:10px 0px 15px 0;
		border-radius:8px;
	}

	.k-lp-editor-term {
		background-color:$v-amber-lighten-5;  // :#92c3e9;
		padding:12px;
		width:calc(25% - 20px);
		margin:10px;
		border-radius:4px;
	}

	.k-lp-editor-units {
		margin-top:20px;
		background-color:#f8cdad;
		padding:10px 0px 15px 0;
		border-radius:8px;
	}

	.k-lp-editor-unit {
		padding:10px;
		border:4px solid transparent;
		width:calc(33% - 20px);
		margin:10px;
		border-radius:4px;
		background-color:$v-amber-lighten-5; 	// :#f8cdad;
	}

	.k-lp-editor-resources-header {
		font-size:14px;
		font-weight:bold;
		color:#666;
	}

	.k-lp-editor-standard-resource {
		padding:10px;
		margin:8px 0;
		border-radius:4px;
		background-color:#eee;
	}

	.k-lp-editor-standard-description {
		flex:1 1 auto;
		cursor:pointer;
		overflow:hidden;
		white-space:nowrap;
	}

	.k-lp-editor-standard-text {
		margin-top:8px;
		padding-top:8px;
		border-top:1px solid #ccc;
	}

	.k-lp-editor-resource-creator {
		background-color:#eee;
		margin-top:10px;
		padding:10px;
		border-radius:4px;
	}

	.k-lp-editor-term-importer {
		background-color:#f8cdad;
		padding:15px;
		margin-top:15px;
		border-radius:4px;
	}

	.k-lp-editor-import-box textarea {
		font-size:12px;
		line-height:16px!important;
	}
}

</style>
