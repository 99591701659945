<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-lesson-edit">
	<div style="font-size:16px" v-if="edited_activity">
		<div class="mx-2">
			<div class="d-flex align-center">
				<div style="flex:1 1 auto">
					<v-text-field background-color="#f8f8f8" :dense="dense_input_fields" hide-details outlined prepend-inner-icon="fas fa-scroll" :label="activity_type_label+' Title'" v-model="edited_activity.activity_title"></v-text-field>
				</div>
				<v-btn v-if="!edited_activity.added_to_gradebook" class="ml-2 k-tight-btn" small color="primary" outlined @click="add_to_gradebook"><v-icon small class="mr-2">fas fa-table-cells</v-icon>Add To Gradebook</v-btn>
				<div v-if="edited_activity.added_to_gradebook" class="ml-2"><b style="font-size:13px;"><v-icon small class="mr-1" style="margin-top:-4px">fas fa-table-cells</v-icon>Added to Gradebook</b><v-btn class="ml-2 k-tight-btn" x-small color="primary" outlined @click="remove_from_gradebook"><v-icon small class="mr-1">fas fa-xmark</v-icon>Remove</v-btn></div>
			</div>

			<div v-if="gradebook_display" class="k-activity-edit-gradebook-display" v-html="gradebook_display"></div>

			<div v-if="activity_class!='template'" class="text-right mt-1 mb-1 mr-16"><v-btn class="k-tight-btn" x-small text color="#444" @click="section_chooser_showing=true"><v-icon x-small class="mr-1">fas fa-gear</v-icon>Filter Sections</v-btn></div>
			<div v-if="activity_class!='template'" class="d-flex align-center mb-2" v-for="(assignment, assignment_index) in edited_activity.assignments" :key="assignment.assignment_id">
				<div style="flex:0 1 288px" class="mr-2" v-show="!assignment.available_date&&!assignment.due_date">
					<v-menu ref="activity_dates_menu" v-model="both_dates_menu_showing[assignment.assignment_id]" :close-on-content-click="false" Xreturn-valueXsync="assignment.available_date" transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{on}"><v-text-field background-color="#f8f8f8" outlined :dense="dense_input_fields" hide-details v-model="activity_dates_display" label="Available Date – Due Date" prepend-inner-icon="fas fa-calendar-week" @click:append="both_dates_menu_showing[assignment.assignment_id]=true" readonly v-on="on"></v-text-field></template>
						<v-date-picker v-model="activity_dates[assignment.assignment_id]" no-title scrollable range>
							<v-btn text color="primary" @click="clear_activity_dates(assignment.assignment_id)">Clear Dates</v-btn>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="both_dates_menu_showing[assignment.assignment_id]=false">Done</v-btn>
						</v-date-picker>
					</v-menu>
				</div>
				<div style="flex:0 1 140px; width:140px; min-width:140px;" class="mr-2" v-show="assignment.available_date||assignment.due_date">
					<v-menu ref="available_date_menu" v-model="available_date_menu_showing[assignment.assignment_id]" :close-on-content-click="false" return-value-sync="assignment.available_date" transition="scale-transition" offset-y min-width="140px">
						<template v-slot:activator="{on}"><v-text-field background-color="#f8f8f8" outlined :dense="dense_input_fields" hide-details v-model="assignment.available_date" label="Available Date" prepend-inner-icon="fas fa-calendar-day" @click:append="available_date_menu_showing[assignment.assignment_id]=true" readonly v-on="on"></v-text-field></template>
						<v-date-picker v-model="assignment.available_date" no-title scrollable>
							<v-btn text color="primary" class="k-tight-btn" @click="clear_available_date(assignment.assignment_id)">Clear Available Date</v-btn>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="available_date_menu_showing[assignment.assignment_id]=false">Done</v-btn>
						</v-date-picker>
					</v-menu>
				</div>
				<div style="flex:0 1 140px; width:140px; min-width:140px;" class="mr-2 k-activity-edit-due-date-editor" v-show="assignment.available_date||assignment.due_date">
					<v-menu ref="due_date_menu" v-model="due_date_menu_showing[assignment.assignment_id]" :close-on-content-click="false" return-value-sync="assignment.due_date" transition="scale-transition" offset-y min-width="140px">
						<template v-slot:activator="{on}"><v-text-field background-color="#f8f8f8" outlined :dense="dense_input_fields" hide-details v-model="assignment.due_date" label="Due Date" prepend-inner-icon="fas fa-calendar-day" @click:append="due_date_menu_showing[assignment.assignment_id]=true" readonly v-on="on"></v-text-field></template>
						<v-date-picker v-model="assignment.due_date" no-title scrollable>
							<v-btn text color="primary" class="k-tight-btn" @click="clear_due_date(assignment.assignment_id)">Clear Due Date</v-btn>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="due_date_menu_showing[assignment.assignment_id]=false">Done</v-btn>
						</v-date-picker>
					</v-menu>
				</div>

				<div style="flex:1 1 auto" class="d-flex align-center">
					<div class="k-activity-editor-assign-to-display">
						<div class="d-flex align-center">
							<div><v-icon small class="mr-2" color="#727272">fas fa-users</v-icon><span v-html="assigned_to_html(assignment)"></span></div>
							<v-btn v-show="assigned_to[assignment.assignment_id].length>1&&!show_assignee_control" x-small color="#ddd" class="ml-2 elevation-0" @click="show_all_assignees[assignment.assignment_id]=!show_all_assignees[assignment.assignment_id]">Details</v-btn>
							<v-btn x-small color="primary" class="ml-2 mr-1 elevation-1" @click.stop="activate_assigned_to_menu(assignment)"><v-icon x-small class="mr-1">fas fa-edit</v-icon>{{show_assignee_control==assignment?'Done':'Edit'}}</v-btn>
							<v-spacer/>
						</div>
						<div v-show="show_all_assignees[assignment.assignment_id]" class="mt-2"><div class="ma-1" v-for="(assignee) in assigned_to[assignment.assignment_id]" v-html="assignee.toString(null,true)"></div></div>
						<div><v-autocomplete v-show="show_assignee_control==assignment" :ref="'assign_to_autocomplete'+assignment.assignment_id" :menu-props="{ maxHeight: 400, dense:true, contentClass:'k-activity-editor-assign-to-control' }" hide-details label="" background-color="#f8f8f8" chips small-chips deletable-chips multiple v-model="assigned_to[assignment.assignment_id]" @change="assigned_to_changed" @blur="deactivate_assignment_menu(assignment)" @click:append.stop="deactivate_assignment_menu(assignment)" :items="assigned_to_options" :filter="assigned_to_option_filter" prepend-inner-icon-x="fas fa-users">
							<template v-slot:item="{ item }">
								<div style="font-size:14px" class="d-flex" @click="last_clicked_assign_to_option=item">
									<div><v-icon color="primary" class="mr-2">{{item.assigned?'fas fa-square-check':'far fa-square'}}</v-icon></div>
									<div :style="(item.type=='section'||item.type=='all')?'font-weight:bold':''">{{item.text}}</div>
									<v-spacer/>
								</div>
							</template>
						</v-autocomplete></div>
					</div>
				</div>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="show_assignment_btns()" v-visible="remove_assignment_btn_visible(assignment)" x-small fab color="red darken-2" dark class="ml-2" @click="remove_assignment(assignment_index)"><v-icon small>fas fa-minus</v-icon></v-btn></template><div class="text-center">Remove the assignment<br>for these students</div></v-tooltip>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="show_assignment_btns()" v-visible="add_assignment_btn_visible(assignment_index)" x-small fab color="green darken-2" dark class="ml-1" style="margin-right:-6px" @click="add_assignment"><v-icon small>fas fa-plus</v-icon></v-btn></template><div class="text-center">Add an assignment<br>for other students</div></v-tooltip>
			</div>
		</div>

		<div v-if="edited_activity.activity_description" class="fr-view mt-4 text-center" style="font-size:16px; font-weight:bold;"><v-icon style="margin-top:-4px" small class="mr-2">fas fa-star</v-icon><span v-html="edited_activity.activity_description"></span></div>

		<div v-show="edited_activity.activity_instructions||edited_activity.standards.length>0||standards_editor_showing||activity_instructions_editor_showing" class="k-activity-instructions mt-4 pb-2 px-1">
			<div v-if="edited_activity.standards.length>0||standards_editor_showing" class="d-flex pt-2" @click="standards_showing=!standards_showing" style="cursor:pointer">
				<v-btn icon x-small color="light-blue darken-2" class="mr-1" @click.stop="standards_showing=!standards_showing"><v-icon>fas {{(standards_showing||standards_editor_showing)?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>
				<div class="k-lesson-title-intro light-blue--text text--darken-2"><b>Learning Standards ({{edited_activity.standards.length}})</b></div>
			</div>
			<v-expand-transition><div v-show="(standards_showing&&edited_activity.standards.length>0)||standards_editor_showing">
				<div class="d-flex flex-wrap my-1 ml-5 mr-2"><draggable v-bind="drag_options" v-model="edited_activity.standards" @end="drag_complete" class="d-flex flex-wrap my-1">
					<CASEItemBtn v-for="(s) in edited_activity.standards" :key="s.identifier" :item="s" @click="align_to_standard(s)" small :show_move_icon="standards_editor_showing" :outer_class="'ma-1'" />
				</draggable></div>
				<div v-if="standards_editor_showing" class="text-center" style="font-size:13px"><i>Select or deselect from the “standards tree”.<span v-if="edited_activity.standards.length>0"> Drag-and-drop to rearrange the standards order.</span></i></div>
			</div></v-expand-transition>

			<div v-if="edited_activity.activity_instructions||activity_instructions_editor_showing" class="d-flex pt-2" @click="activity_instructions_showing=!activity_instructions_showing" style="cursor:pointer">
				<v-btn icon x-small color="light-blue darken-2" class="mr-1" @click.stop="activity_instructions_showing=!activity_instructions_showing"><v-icon>fas {{activity_instructions_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>
				<div class="k-lesson-title-intro light-blue--text text--darken-2"><b>Student Instructions</b></div>
			</div>
			<v-expand-transition><div v-show="(activity_instructions_showing&&edited_activity.activity_instructions)||activity_instructions_editor_showing" class="fr-view mt-2">
				<div class="k-activity-instructions-body" v-show="!activity_instructions_editor_showing">
					<v-icon small color="light-blue darken-1">fas fa-info-circle</v-icon>
					<div v-html="activity_instructions_formatted"></div>
				</div>
				<div v-if="activity_instructions_editor_showing" class="px-2">
					<froala-wrapper :config="editor_config()" v-model="edited_activity.activity_instructions" />
				</div>
			</div></v-expand-transition>
		</div>

		<div class="mt-3 mb-2 text-center">
			<v-btn small color="teal darken-2" class="k-tight-btn my-1 mx-1" dark @click="open_student_activity_editor_tool"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit Student Activity</v-btn>
			<!-- only allow editing of standards if we're in the context of a course -->
			<v-btn v-if="activity_course_code" small :color="standards_editor_showing?'pink darken-4':'light-blue darken-2'" class="k-tight-btn my-1 mx-1" dark @click="toggle_standards_alignment"><v-icon small class="mr-2">fas fa-bullseye</v-icon><span v-if="!standards_editor_showing">{{edited_activity.standards.length>0?'Edit Aligned':'Add Aligned'}}</span><span v-else>Done Editing</span> Standards</v-btn>
			<!-- only show the edit instructions button if the activity has been created -->
			<v-btn v-if="edited_activity.tool_id=='google'||(edited_activity.tool_activity_id&&edited_activity.tool_activity_id!=0)" small :color="activity_instructions_editor_showing?'pink darken-4':'light-blue darken-2'" class="k-tight-btn my-1 mx-1" dark @click="activity_instructions_editor_showing=!activity_instructions_editor_showing"><v-icon small class="mr-2">fas fa-circle-info</v-icon><span v-if="!activity_instructions_editor_showing">{{edited_activity.activity_instructions?'Edit Student':'Add Student'}}</span><span v-else>Done Editing</span><span class="ml-1">Instructions</span></v-btn>
		</div>

		<!-- EMBEDDED SPARKL TOOL -- force-maximizing, rather than showing inline, as of 4/12/2023 -->
		<SparklEmbed v-if="sparkl_showing" ref="sparkl_embed" :key="sparkl_embed_key" :force_maximize="true" :activity="edited_activity" :initial_content="initial_content" @activity_saved="sparkl_activity_saved" @close_sparkl="close_sparkl_from_embed" :context="'edit'" />

		<!-- EMBEDDED GOOGLE ASSIGNMENT TOOL -->
		<GoogleEmbed v-if="google_showing" ref="google_embed" :key="google_embed_key" :force_maximize="true" :activity="edited_activity" :initial_content="initial_content" @close_google="close_google_from_embed" :context="'edit'" />

		<!-- RESOURCES -->
		<!-- <div class="mt-2">
			<div class="d-flex align-center">
				<div class="mb-1 ml-1" @click.stop="resources_showing=!resources_showing" style="cursor:pointer"><v-icon color="indigo" style="margin-top:-2px" class="mr-2">fas {{resources_showing?'fa-caret-down':'fa-caret-right'}}</v-icon><b class="k-lesson-title-intro">{{activity_type_label}} Resources</b><span style="font-size:14px">({{edited_activity.resources.length}})</span></div>
				<v-spacer/>
				<v-btn v-show="resources_showing&&edited_activity.resources.length>1" small class="ml-2" color="#666" dark @click="resource_reorder_open=true"><v-icon x-small class="mr-1">fas fa-arrows-alt-v</v-icon>Reorder</v-btn>
				<v-btn v-show="resources_showing" small class="ml-2" color="brown" dark @click="create_resource_start"><v-icon small class="mr-1">fas fa-plus-circle</v-icon>Add Resource</v-btn>
			</div>
			<v-expand-transition><div v-show="resources_showing&&edited_activity.resources.length>0" class="k-lesson-resource-collection mt-3" style="background-color:#fff; padding:2px 8px; border-radius:6px; margin-top:8px;">
				<div v-for="(resource) in edited_activity.resources" :key="resource.resource_id" class="k-resource-collection-activity-wrapper">
					<ResourceCollectionItem
						:item="resource"
						:enable_gc_link="true"
						:enable_hc_assignment="false"
						:enable_edit_link="true"
						:enable_remove_link="true"
						@edit_resource="edit_resource"
						@remove_resource="remove_resource"
					/>
				</div>
			</div></v-expand-transition>
		</div> -->
	</div>
	<div class="px-3 pt-2 k-activity-editor-buttons">
		<v-btn color="secondary" class="k-tight-btn" @click="edit_activity_cancel"><v-icon small class="mr-2">fas fa-times</v-icon>{{is_new_activity?'Cancel':'Close Assignment Editor'}}&nbsp;</v-btn>
		<v-spacer/>
		<v-btn color="primary" class="ml-3 k-tight-btn" @click="save_activity"><v-icon small class="mr-2">fas fa-save</v-icon>Save&nbsp;</v-btn>
		<v-btn color="primary" class="ml-3 k-tight-btn" @click="save_and_close"><v-icon small class="mr-2">fas fa-check</v-icon>Save and Close</v-btn>
	</div>

	<ResourceEditor v-if="resource_being_edited"
		:unit="unit"
		:course="lp"
		:original_resource="resource_being_edited"
		:show_shareable_control="false"
		:district_sanctioned="true"
		@edit_resource_cancel="create_resource_cancel"
		@edit_resource_saved="edit_resource_saved"
	/>

	<!-- resource reorderer -->
	<v-dialog v-if="resource_reorder_open" v-model="resource_reorder_open" max-width="680" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b style="font-weight:900">Reorder Resources</b></v-card-title>
			<v-card-text class="pt-3" style="background-color:#eee">
				<draggable v-bind="{animation:300}" v-model="edited_activity.resources" @end="save_activity">
					<div v-for="(resource) in edited_activity.resources" :key="resource.resource_id" style="border:1px solid #333; padding:4px; margin:4px 0; background-color:#fff; border-radius:6px; cursor:move" class="d-flex align-center">
						<div class="mx-2 k-lesson-editor-resource-reorderer-handle"><v-icon small>fas fa-arrows-alt-v</v-icon></div>
						<div style="font-size:14px;" v-html="resource.description"></div>
					</div>
				</draggable>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="secondary" @click="resource_reorder_open=false">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<SectionChooser v-if="section_chooser_showing" :lp="lp" @dialog_cancel="section_chooser_showing=false" />
</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import draggable from 'vuedraggable'
import { mapState, mapGetters } from 'vuex'
import ResourceCollectionItem from '../resources/ResourceCollectionItem'
import ResourceEditor from '../resources/ResourceEditor'
// import FroalaResourcesMixin from '../../js/FroalaResourcesMixin'
import SparklEmbed from '../lessons/SparklEmbed'
import GoogleEmbed from '../lessons/GoogleEmbed'
import SectionChooser from './SectionChooser'
import CASEItemBtn from '../resources/CASEItemBtn'

export default {
	components: { ResourceCollectionItem, ResourceEditor, draggable, SparklEmbed, GoogleEmbed, SectionChooser, CASEItemBtn},
	// mixins: [FroalaResourcesMixin],
	props: {
		original_activity: { required: true },
		initial_content: { required: false, default() { return '' } },	// this can be sent in to create an activity with the given initial content
		activity_class: { type: String, required: false, default() { return '' }},	// 'teacher' or 'template'
		course_code: { type: String, required: false, default() { return '' }},
		lp_unit_id: { type: Number, required: false, default() { return 0 }}
	},
	data() { return {
		edited_activity: null,
		is_new_activity: false,
		sparkl_showing: false,
		google_showing: false,
		resource_being_edited: false,
		resources_showing: false,
		standards_showing: false,
		standards_editor_showing: false,
		activity_instructions_showing: false,
		activity_instructions_editor_showing: false,

		section_chooser_showing: false,

		resource_reorder_open: false,

		available_date_menu_showing: {},
		due_date_menu_showing: {},
		both_dates_menu_showing: {},
		activity_dates: {},
		assigned_to: {},
		show_all_assignees: {},
		show_assignee_control: false,	// this will take the value of the assignment being edited

		case_tree_showing: false,
		case_tree_loaded: false,
		aligning_to_activity: false,
		case_tree_active_item: '',
		case_tree_open_item: '',
		case_tree_item_to_open: '',

		editor_component_id: U.new_uuid(),

		stringified_original_activity: '',

		save_activity_after_sparkl_save: false,
		suppress_initial_save: false,
		close_after_save: false,

		dense_input_fields: true,

		last_clicked_assign_to_option: null,

		gb_categories: [
			{ value: 'ungraded', text: 'Ungraded' },
			{ value: 'practice', text: 'Practice Work' },
			{ value: 'assessment', text: 'Assessment Tasks' },
		],

		sparkl_embed_key: 0,	// this allows us to re-render the component when necessary; see https://michaelnthiessen.com/force-re-render/
		google_embed_key: 0,

		drag_options: { animation: 200,	handle: ".k-move-handle" },

		activity_dates_display: '',
	}},
	computed: {
		...mapState(['user_info', 'sis_classes']),
		...mapGetters([]),
		term_for_assignments() { return this.$store.state.lst.term_for_assignments },
		activity_course_code() {
			if (this.original_activity.course_code) return this.original_activity.course_code
			else return this.course_code
		},
		activity_lp_unit_id() {
			if (this.original_activity.lp_unit_id) return this.original_activity.lp_unit_id
			else return this.lp_unit_id
		},
		lp() { return this.$store.state.all_courses.find(x=>x.course_code==this.activity_course_code) },
		unit() {
			if (!this.lp || this.activity_lp_unit_id == 0) return null
			return this.lp.units.find(x=>x.lp_unit_id == this.activity_lp_unit_id)
		},
		subject() {
			if (!this.lp) return null
			return this.lp.subject
		},
		activity_type_label() { return U.activity_type_label(this.original_activity.activity_type) },
		activity_instructions_formatted() {
			let s = this.edited_activity.activity_instructions
			if (empty(s)) return ''
			s = s.replace(/<a /g, '<a target="_blank" ')
			return s
		},
		due_date_formatted() {
			if (empty(this.edited_activity)) return ''
			if (empty(this.edited_activity.due_date)) return ''
			let d = new Date(this.edited_activity.due_date + 'T00:00:00')
			return date.format(d, 'ddd MMM D')	// Tue Jan 9
		},
		available_date_formatted() {
			if (empty(this.edited_activity)) return ''
			if (this.edited_activity.available_date == '') return ''
			let d = new Date(this.edited_activity.available_date + 'T00:00:00')
			return date.format(d, 'ddd MMM D')	// Tue Jan 9
		},
		student_description_word_count() {
			let wc = U.word_count(U.html_to_text(this.edited_activity.student_description))
			return sr('$1 $2', wc, U.ps('word', wc))
		},
		assigned_to_options() {
			// console.log('assigned_to_options updated...')
			if (!this.show_assignee_control) return []

			// we only show options for the assignment we're currently editing
			let assigned_to = this.assigned_to[this.show_assignee_control.assignment_id]

			let arr = []
			let students = []
			let assigned_sections = []
			let all_sections_for_course
			let acs = (this.lp) ? this.$store.state.lst.assignment_chooser_sections[this.lp.course_code] : null

			for (let my_class of this.sis_classes) {
				let for_this_course = this.lp && (my_class.course_code == this.lp.course_code)
				// only show sections for this course; could possibly have an option to show for all courses
				if (!for_this_course) continue

				for (let i = 0; i < my_class.class_sourcedIds.length; ++i) {
					// if no students in this section, continue
					let n_students = 0
					if (!empty(my_class.students[i])) n_students = my_class.students[i].length
					if (n_students == 0) continue

					let class_sourcedId = my_class.class_sourcedIds[i]

					// if user has filtered out this section, continue
					if (acs && !acs[class_sourcedId]) continue

					let section_title = my_class.section_title(i, {student_count:false, title:false, teacher:false, term:0})

					let a = assigned_to.find(x=>x.class_sourcedId == class_sourcedId && x.user_sourcedId == '')
					if (!a) a = new Assignee({ class_sourcedId: class_sourcedId, assignment_id: this.show_assignee_control.assignment_id })
					let section_assigned = assigned_to.findIndex(x=>x==a) > -1
					arr.push({
						value: a,
						text: section_title + sr(' ($1 $2)', n_students, U.ps('student', n_students)),
						section_title: section_title,
						type: 'section',
						assigned: section_assigned,
					})

					if (for_this_course) {
						if (!all_sections_for_course) all_sections_for_course = {
							value: new Assignee({ class_sourcedId: 'all', assignment_id: this.show_assignee_control.assignment_id }),
							n_students: 0,
							text: my_class.title,
							type: 'all',
							// assume all sections are assigned; if we find a section that *isn't*, we'll set to false
							assigned: true,
						}
						all_sections_for_course.n_students += n_students

						// if this section isn't assigned, all sections aren't assigned
						if (!section_assigned) all_sections_for_course.assigned = false
					}

					// let section_title_brief = my_class.title_codes[i][2]
					// if (my_class.title_codes[i][1] != 0) section_title_brief += '.' + my_class.title_codes[i][2]
					// if assigned, add to assigned_sections to deal with students below
					if (section_assigned) {
						assigned_sections.push(section_title)
					}

					if (!empty(my_class.students[i])) for (let student of my_class.students[i]) {
						let s = students.find(x=>x.sourcedId == student.sourcedId)
						if (empty(s)) {
							let name = student.familyName + ', ' + student.givenName
							if (!empty(student.middleName)) name += ' ' + student.middleName[0] + '.'
							
							let a = assigned_to.find(x=>x.class_sourcedId == class_sourcedId && x.user_sourcedId == student.sourcedId)
							if (!a) a = new Assignee({ class_sourcedId: class_sourcedId, user_sourcedId: student.sourcedId, assignment_id: this.show_assignee_control.assignment_id })
							s = {
								sourcedId: student.sourcedId,
								value: a,
								text: name,
								type: 'student',
								assigned: assigned_to.findIndex(x=>x==a) > -1,
								section_code: my_class.title_codes[i][2],
								sections: [section_title],
								assigned_to_sections: [],
							}
							students.push(s)
						} else {
							s.sections.push(section_title)
						}
						// if this section is assigned, add it to the student's assigned_to_sections
						if (section_assigned) s.assigned_to_sections.push(section_title)
					}
				}
			}

			if (all_sections_for_course) {
				all_sections_for_course.text = sr('ALL SECTIONS - $1 $2', all_sections_for_course.n_students, U.ps('student', all_sections_for_course.n_students))
				arr.push(all_sections_for_course)
			}

			arr.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true}))

			students.sort((a,b)=>{
				// first sort by section, then by name
				// if (a.section_code != b.section_code) return a.section_code - b.section_code

				if (a.text < b.text) return -1
				if (b.text < a.text) return 1
				return 0
			})

			for (let s of students) {
				// if the student is in an assigned section, it's assigned to them implicitly...
				if (s.sections.filter(x => assigned_sections.includes(x)).length > 0) {
					s.assigned = true
					s.assigned_by_section = true
				}
				// s.text += sr(' ($1)', s.sections.join(', '))
				arr.push(s)
			}

			return arr
		},
		gradebook_display() {
			if (!this.edited_activity.added_to_gradebook) return ''
			// if added to the gradebook and we have lineitems, show them
			let s = ''
			for (let class_sourcedId in this.original_activity.lineItemSourcedId) {
				// console.log(`${class_sourcedId}: ${this.edited_activity.lineItemSourcedId[class_sourcedId]}`)
				if (empty(s)) s = '<b class="pink--text text--darken-2">Sending grades to SIS for:</b>   '
				else s += ',</nobr> '
				let original_assignee = this.original_activity.assigned_to.find(x=>x.matches({class_sourcedId:class_sourcedId, user_sourcedId:''}))
				let edited_assignee = this.edited_activity.assigned_to.find(x=>x.matches({class_sourcedId:class_sourcedId, user_sourcedId:''}))
				if (!original_assignee) s += '<nobr>UNKNOWN SECTION'
				else if (edited_assignee) s += `<nobr>${original_assignee.toString(null,true,false)}`
				else s += `<nobr><s>${original_assignee.toString(null,true,false)}</s>`
			}

			if (empty(s)) s = 'Previously added to gradebook, but no sections currently assigned'
			else s += '</nobr>'
			return s
		},
	},
	watch: {
		activity_dates: { deep:true, handler(val) {
			// console.log('activity_dates watcher: ', extobj(val))
			for (let assignment_id in val) {
				let arr = val[assignment_id]
				let assignment = this.edited_activity.assignments.find(x=>x.assignment_id==assignment_id)

				// when the activity_dates array is changed, update available/due dates -- but note that either date could be first in the array, or there could be only one val, or both could be empty
				if (arr.length == 0) {	// not sure if this can happen, but handle just in case
					assignment.available_date = ''
					assignment.due_date = ''
				} else if (arr.length == 1 || arr[1] == '') {
					assignment.available_date = arr[0]
					assignment.due_date = ''
				} else if (arr[0] < arr[1]) {
					assignment.available_date = arr[0]
					assignment.due_date = arr[1]
				} else {
					assignment.due_date = arr[0]
					assignment.available_date = arr[1]
				}
			}
		}},
	},
	created() {
	},
	mounted() {
		this.$store.commit('set', ['editor_components', this.editor_component_id, this])

		this.create_edited_activity(this.original_activity)

		vapp.activity_editor_component = this
	},
	methods: {
		editor_config(text, inline) {
			let config = U.get_froala_config({
				placeholderText: text,
				// initOnClick: true,
				toolbarInline: (inline === true),
				paragraphFormat: {
					N: 'Normal',
					BLOCKQUOTE: 'Block Quote',
				    PRE: 'Code',
				},
			})

			// add the insert resource btn at the front of the moreRich buttons
			// config.toolbarButtons.moreRich.buttons.unshift('insert_resource')
			// config.toolbarButtons.moreRich.buttonsVisible += 1

			return config
		},

		open_student_activity_editor_tool() {
			if (this.edited_activity.tool_id == 'google') {
				// for google assignments, the activity needs to be saved before we can launch google, because we need to have an activity established -- and we need an lti_context_id_hc to have been generated
				if (this.is_new_activity) {
					this.save_activity('open_google')
				} else {
					this.google_showing = true
				}

			} else {
				// for sparkl (default), first save changes to the sparkl activity settable here (title, instructions) if necessary...
				this.save_changes_to_sparkl().then(x=>{
					// then set sparkl_showing to true to show the sparkl editor interface
					this.sparkl_showing = true
				})
			}
		},

		create_edited_activity(source_activity) {
			// set is_new_activity to true if the original activity's activity_id is empty or 0
			this.is_new_activity = !source_activity.activity_id

			// the parent must specify at least an activity_type in the original_activity; but if activity_id is 0, it's a newly-created activity
			this.edited_activity = new Activity(source_activity)

			// if the edited_activity doesn't have at least one assignment, add an empty one so the user can fill it in if they wish
			if (this.edited_activity.assignments.length == 0) {
				this.edited_activity.assignments.push(new Assignment())
			}

			if (this.is_new_activity) {
				// fill some things in to edited_activity
				this.edited_activity.creator_user_id = this.user_info.user_id
				this.edited_activity.course_code = this.course_code
				this.edited_activity.lp_unit_id = this.lp_unit_id
				if (empty(this.edited_activity.tool_activity_id)) this.edited_activity.tool_activity_id = 0		// this will cause Sparkl to create a new activity

				// Note: we don't save a stringified_original_activity, because it hasn't yet been saved

				// if we received initial_content, Sparkl will immediately save the newly-created Sparkl activity and trigger a save_activity below,
				// but we *don't* want to save yet, because the user may decide to cancel the new activity. 
				// So set a flag to let save_activity know to suppress this first save
				if (this.initial_content) this.suppress_initial_save = true

			} else {
				this.stringified_original_activity = JSON.stringify(this.edited_activity.copy_for_save())
			}

			// set assignment "helper" objects
			this.set_assignment_helpers()

			// console.log(this.edited_activity)

			// assign to default class???
			// this.edited_activity.assigned_to = this.default_assigned_to
		},

		// we have to maintain "helper" objects with date arrays and assigned_to orecords for each assignment_id, plus some other things
		set_assignment_helpers() {
			let activity_dates = {}, available_date_menu_showing = {}, due_date_menu_showing = {},both_dates_menu_showing = {}, assigned_to = {}, show_all_assignees = {}
			for (let a of this.edited_activity.assignments) {
				available_date_menu_showing[a.assignment_id] = false
				due_date_menu_showing[a.assignment_id] = false
				both_dates_menu_showing[a.assignment_id] = false
				activity_dates[a.assignment_id] = [a.available_date, a.due_date]
				show_all_assignees[a.assignment_id] = false

				// find all assignees for this assignment_id
				assigned_to[a.assignment_id] = []
				for (let assignee of this.edited_activity.assigned_to) {
					if (assignee.assignment_id == a.assignment_id) {
						assigned_to[a.assignment_id].push(assignee)
					}
				}
			}
			this.activity_dates = activity_dates
			this.available_date_menu_showing = available_date_menu_showing
			this.due_date_menu_showing = due_date_menu_showing
			this.both_dates_menu_showing = both_dates_menu_showing
			this.activity_dates = activity_dates
			this.assigned_to = assigned_to
			this.show_all_assignees = show_all_assignees
		},

		clear_activity_dates(assignment_id) {
			// user clicks "no dates": clear available/due dates, and clear activity_dates
			let assignment = this.edited_activity.assignments.find(x=>x.assignment_id==assignment_id)
			assignment.available_date = ''
			assignment.due_date = ''
			this.activity_dates[assignment_id] = ['','']
			this.both_dates_menu_showing = false
		},

		clear_available_date(assignment_id) {
			let assignment = this.edited_activity.assignments.find(x=>x.assignment_id==assignment_id)
			assignment.available_date = ''
			assignment.due_date = ''
			this.activity_dates[assignment_id] = ['','']
			this.available_date_menu_showing = false
		},

		clear_due_date(assignment_id) {
			let assignment = this.edited_activity.assignments.find(x=>x.assignment_id==assignment_id)
			assignment.due_date = ''
			this.activity_dates[assignment_id] = [assignment.assignment_id.available_date]
			this.due_date_menu_showing = false
		},

		activate_assigned_to_menu(assignment) {
			// console.log('activate_assigned_to_menu')
			if (this.show_assignee_control == assignment) {
				this.show_assignee_control = false
			} else {
				this.show_assignee_control = assignment
			}
			this.show_all_assignees[assignment.assignment_id] = false
			if (this.show_assignee_control) {
				setTimeout(x=>{
					this.$refs['assign_to_autocomplete'+assignment.assignment_id][0].focus()
					this.$refs['assign_to_autocomplete'+assignment.assignment_id][0].activateMenu()
				})
			}
		},

		show_assignment_btns() {
			if (this.edited_activity.assignments.length > 1) return true
			if (this.edited_activity.assignments[0] && !this.edited_activity.assignment_is_empty(this.edited_activity.assignments[0])) return true
			return false
		},

		add_assignment_btn_visible(assignment_index) {
			// show the add assignment btn if all assignment entries are not empty
			for (let a of this.edited_activity.assignments) {
				// so return false if any assignment *is* empty
				if (this.edited_activity.assignment_is_empty(a)) return false
			}
			// and only show the btn for the final assignment
			if (assignment_index != this.edited_activity.assignments.length-1) return false
			return true
		},

		remove_assignment_btn_visible(assignment) {
			return !this.edited_activity.assignment_is_empty(assignment) || this.edited_activity.assignments.length > 1
		},

		add_assignment() {
			this.edited_activity.assignments.push(new Assignment())
			this.set_assignment_helpers()
		},

		remove_assignment(assignment_index) {
			// if any of the assignees have lineItemSourcedIds, we will ask if the teacher wants to delete these line items when they click to save changes

			// remove this assignment's assigned_to entries from edited_activity
			let assigned_to_index = this.edited_activity.assigned_to.findIndex(x=>x.matches(this.edited_activity.assignments[assignment_index]))
			if (assigned_to_index > -1) {	// this should always be true!
				this.edited_activity.assigned_to.splice(assigned_to_index, 1)
			}

			this.edited_activity.assignments.splice(assignment_index, 1)
			// if we removed the last "complete" assignment, add a placeholder
			if (this.edited_activity.assignments.length == 0) this.add_assignment()

			// reconstruct overall activity assigned_to from this.assigned_to, which was just changed
			let arr = []
			for (let assignment_id in this.assigned_to) {
				for (let a of this.assigned_to[assignment_id]) {
					if (!arr.includes(a)) arr.push(a)
				}
			}
			this.edited_activity.assigned_to = arr

			this.set_assignment_helpers()
		},

		assigned_student_count(assignment_id) {
			let students = []
			for (let assignee of this.edited_activity.assigned_to) {

				// only consider assignees for this assignment_id
				if (assignee.assignment_id != assignment_id) continue

				let astudents = assignee.get_students()
				for (let student of astudents) {
					if (!students.includes(student)) students.push(student)
				}
			}
			return students.length
		},

		assigned_to_html(assignment) {
			let assigned_to = this.assigned_to[assignment.assignment_id]
			if (this.show_assignee_control == assignment || assigned_to.length != 1) {
				return `Assigned to: <b>${this.assigned_student_count(assignment.assignment_id)} Student${this.assigned_student_count(assignment.assignment_id)==1?'':'s'}</b>`
			} else {
				return assigned_to[0].toString(null, true)
			}
		},

		edit_resource(resource) {
			this.resource_being_edited = resource
		},

		remove_resource(resource, confirmed) {
			// splice the resource out
			let i = this.edited_activity.resources.findIndex(x=>x==resource)
			if (i > -1) this.edited_activity.resources.splice(i, 1)

			// then immediately save
			this.save_activity()
		},

		create_resource_start() {
			this.resource_being_edited = 'new'
		},

		create_resource_cancel() {
			this.resource_being_edited = null
		},

		edit_resource_saved(updated_resource) {
			updated_resource = new Resource(updated_resource)

			// the resource editor will have saved the resource to the db if necessary; splice or push it to the activity resources
			let index = this.edited_activity.resources.findIndex(x=>x.resource_id == updated_resource.resource_id)
			if (index == -1) {
				this.edited_activity.resources.push(updated_resource)

			} else {
				this.edited_activity.resources.splice(index, 1, updated_resource)
				// in the case of an update the activity data won't change, so we have to update the original_unit too
				if (!this.is_new_activity) this.original_activity.resources.splice(index, 1, new Resource(updated_resource))
			}

			// save activity edits right away whenever a new resource is added or updated, unless we don't have a title
			if (this.edited_activity.activity_title) {
				this.save_activity()
			}

			this.resource_being_edited = null
		},

		reorder_resources() {
			this.save_activity()
			this.resource_reorder_open = false
		},

		///////////////////////////////////
		// Standards
		align_to_standard(start_item) {
			let data = { framework_identifier: '', item_identifier: '' }

			if (start_item?.framework_identifier) {
				data.framework_identifier = start_item.framework_identifier
			} else {
				// if the activity has a case_framework_identifier, send it
				if (this.edited_activity.case_framework_identifier) data.framework_identifier = this.edited_activity.case_framework_identifier

				// HACK: trace up through parents looking for a case_framework_identifier value (e.g. in CourseView); if found, use it
				let parent = this.$parent
				while (parent) {
					if (parent.case_framework_identifier) { data.framework_identifier = parent.case_framework_identifier; break; }
					parent = parent.$parent
				}
			}

			if (start_item?.identifier) {
				data.item_identifier = start_item.identifier
			} else {
				// if the activity has a course_case_identifier, send it
				if (this.edited_activity.course_case_identifier) data.item_identifier = this.edited_activity.course_case_identifier

				// HACK: trace up through parents looking for a course_case_identifier value (e.g. in CourseView); if found, use it as item_identifier
				let parent = this.$parent
				while (parent) {
					if (parent.course_case_identifier) { data.item_identifier = parent.course_case_identifier; break; }
					parent = parent.$parent
				}
			}

			// add current activity standards as selected items
			if (this.edited_activity.standards.length > 0) {
				data.selected_items = []
				for (let standard of this.edited_activity.standards) data.selected_items.push(standard.identifier)
			}

			let show_data = { 
				// set embed_hide_callback_fn to toggle standards_editor_showing off when the user closes the chooser
				embed_hide_callback_fn: ()=>{ this.standards_editor_showing = false },
				// set hide_fn to hide the standards chooser if/when the editor is no longer visible
				hide_fn: ()=>{ return ($(vapp.activity_editor_component?.$el).is(':visible') == false) } 
			}

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// if we already have this item aligned, remove the standard
						let i = this.edited_activity.standards.findIndex(o=>o.identifier==aligned_item.cfitem.identifier)
						if (i > -1) {
							this.edited_activity.standards.splice(i, 1)
							// re-initialize the chooser, showing the framework for the item we removed
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})

						} else {
							// Add the standard and the framework_identifier. use the learning progression CASE_Item structure for standards; this is a bit simpler than the full CFItem structure
							let o = new CASE_Item(aligned_item.cfitem)
							o.framework_identifier = aligned_item.framework_identifier
							this.edited_activity.standards.push(o)
							// re-initialize the chooser, showing the framework for the item we added
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})
						}
						
						// save immediately, unless this is a new activity
						if (!this.is_new_activity) this.save_activity('no_spinner')
					})
				})
			})
		},

		toggle_standards_alignment() {
			if (this.standards_editor_showing) {
				this.standards_editor_showing = false
				vapp.$refs.satchel.execute('hide')
			} else {
				this.standards_editor_showing = true
				this.align_to_standard(null)
			}
		},

		drag_complete(evt) {
			// standards will have been reordered; just need to save
			if (!this.is_new_activity) this.save_activity('no_spinner')
		},

		//////////////////////////////////////////
		assigned_to_changed() {
			console.log('assigned_to_changed', this.last_clicked_assign_to_option)
			// note that the computed assigned_to_options param will be updated as soon as we reference it below, and every time we edit assigned_to
			// note that we set this.last_clicked_assign_to_option in an @click event on each assign_to menu item

			// reconstruct overall activity assigned_to from this.assigned_to, which was just changed
			let arr = []
			for (let assignment_id in this.assigned_to) {
				for (let a of this.assigned_to[assignment_id]) {
					if (!arr.includes(a)) arr.push(a)
				}
			}
			this.edited_activity.assigned_to = arr

			let close_menu = false

			if (!this.last_clicked_assign_to_option) {
				// this shouldn't happen
				console.log('Error in assigned_to_changed: last_clicked_assign_to_option not set')
			} else {
				// if the user just clicked to assign all...
				if (this.last_clicked_assign_to_option.type == 'all') {
					// the "all" class_sourcedId has been selected.
					// check all the "real" sections to see if all of them are currently marked as assigned
					let all_assigned = true
					for (let opt of this.assigned_to_options) {
						if (opt.type == 'section') {
							if (!opt.assigned) all_assigned = false
						}
					}

					// if all the real sections were assigned, clicking "all sections" means to unassign all the sections (and all the students)
					if (all_assigned) {
						this.edited_activity.assigned_to = []

					// else clicking "all sections" means to assign all the sections (and by extension all the students)
					} else {
						this.edited_activity.assigned_to = []
						for (let opt of this.assigned_to_options) {
							if (opt.type == 'section') this.edited_activity.assigned_to.push(opt.value)
						}
						// in this case close the menu
						close_menu = true
					}

				// else if the user clicked on a section, make sure that all students in assigned sections are *not* in assigned_to
				} else if (this.last_clicked_assign_to_option.type == 'section') {
					for (let opt of this.assigned_to_options) {
						if (opt.type == 'section' && this.edited_activity.assigned_to.includes(opt.value)) {
							for (let sopt of this.assigned_to_options) {
								if (sopt.type == 'student' && sopt.assigned_to_sections.length > 0) {
									let index = this.edited_activity.assigned_to.indexOf(sopt.value)
									if (index > -1) {
										this.edited_activity.assigned_to.splice(index, 1)
									}
								}
							}
						}
					}
				
				// else the last-clicked option was a student
				} else {
					if (this.last_clicked_assign_to_option.assigned_to_sections.length > 0) {
						// if the user *unchecked* a student that was already part of an assigned section
						// take the student back out of assigned_to; then ask the user to confirm
						let index = this.edited_activity.assigned_to.findIndex(x=>x==this.last_clicked_assign_to_option.value)
						this.edited_activity.assigned_to.splice(index, 1)

						this.$confirm({
							text: sr('The student you unchecked ($1) is currently assigned to complete this activity by virtue of being a member of one or more assigned sections ($2). If you “unassign” the activity to this student, we will remove the section-level assignment and replace it with individual assignments to all students in the section <i>except</i> this student.<br><br>Is this would you would like to do?', this.last_clicked_assign_to_option.text, this.last_clicked_assign_to_option.assigned_to_sections.join(', ')),
							acceptText: 'Unassign this student',
							cancelText: 'Keep section-level assignment',
							dialogMaxWidth: 800,
						}).then(y => {
							// user has confirmed to remove the section assignment(s) and assign other students instead, so go through all this student's assigned sections...
							// (we have to first make a copy of assigned_to_options as it exists now, because every time we edit assigned_to below, it'll change)
							let oato = this.assigned_to_options.concat([])
							for (let section_title of this.last_clicked_assign_to_option.assigned_to_sections) {
								// remove the section from assigned_to
								let sopt = oato.find(x=>x.section_title == section_title)
								let index = this.edited_activity.assigned_to.findIndex(x=>x == sopt.value)
								if (index > -1) {
									this.edited_activity.assigned_to.splice(index, 1)
									// then add all other students from this section to assigned_to (if they're not already there)
									for (let oopt of oato) {
										if (oopt.type == 'student' && oopt.sourcedId != this.last_clicked_assign_to_option.sourcedId && oopt.assigned_to_sections.includes(section_title)) {
											let index = oopt.assigned_to_sections.indexOf(section_title)
											oopt.assigned_to_sections.splice(index, 1)
											// exception: if the student is still in *another* assigned section, we don't need to do this
											if (oopt.assigned_to_sections.length == 0) {
												this.edited_activity.assigned_to.push(oopt.value)
											}
										}
									}
								}
							}
							this.last_clicked_assign_to_option = null
						}).catch(n=>{console.log(n)}).finally(f=>{})
						return
					}
				}
			}

			// sort so that classes are first
			this.edited_activity.sort_assigned_to()
			this.last_clicked_assign_to_option = null

			// call set_assignment_helpers in case something changed
			this.set_assignment_helpers()

			if (close_menu) {
				// blur the autocomplete to close the menu
				this.$refs['assign_to_autocomplete'+this.show_assignee_control.assignment_id][0].blur()
			}
		},

		deactivate_assignment_menu(assignment) {
			if (this.show_assignee_control == assignment) {
				this.activate_assigned_to_menu(assignment)
				this.$refs['assign_to_autocomplete'+assignment.assignment_id][0].blur()
			}
		},

		///////////////////////////////////
		close_sparkl_from_embed() {
			// send the host_activity_saved message TO sparkl, so that Sparkl saves anything that might have been edited there
			this.$refs.sparkl_embed.execute('host_activity_saved', {activity_title: this.edited_activity.activity_title})

			this.save_activity_after_sparkl_save = false
			this.sparkl_closed_from_embed = true
			// ... then once sparkl is done saving, sparkl_activity_saved (below) will be called, and since sparkl_closed_from_embed is true, sparkl_activity_saved will close the SparklEmbed
		},

		close_google_from_embed() {
			this.google_showing = false
		},

		save_and_close() {
			this.close_after_save = true
			this.save_activity()
		},
		
		sparkl_activity_saved(data) {
			// this is called when Sparkl issues a 'sparkl_activity_saved' message; see also analogous fn in ActivityView
			let changed = false
			let reload = false
			// if tool_activity_id is changed, set it
			if (data.sparkl_activity_id && data.sparkl_activity_id != this.edited_activity.tool_activity_id) {
				console.log('sparkl_activity_id updated...')
				// if the tool_activity_id wasn't empty and has been updated, we need to reload the iframe
				if (this.edited_activity.tool_activity_id) reload = true

				this.edited_activity.tool_activity_id = data.sparkl_activity_id
				changed = true
			}

			// build description from the rest of the data; if changed, set it
			if (data.stars_available) {
				let d = Activity.standard_activity_description(data.stars_available)
				
				if (this.edited_activity.activity_description != d) {
					this.edited_activity.activity_description = d
					changed = true
				}
			}

			// update activity_instructions and title if they change
			if (this.edited_activity.activity_instructions != data.activity_instructions) {
				this.edited_activity.activity_instructions = data.activity_instructions
				changed = true
			}
			if (this.edited_activity.activity_title != data.activity_title) {
				this.edited_activity.activity_title = data.activity_title
				changed = true
			}

			// if we just created a new activity with content from HC, clear the initial_content field; otherwise that initial_content would be added again if the activity reloads
			this.$emit('clear_initial_content')

			// if we were explicitly told to save the HC activity after the sparkl activity save is done, or if we changed something here,
			if (this.save_activity_after_sparkl_save || (changed && this.edited_activity.activity_title)) {
				// call save_activity, telling it that we just finished saving sparkl
				this.save_activity('from_sparkl')
			}
			this.save_activity_after_sparkl_save = false

			// if sparkl_closed_from_embed is true, close sparkl now (leaving the assignment interface open)
			if (this.sparkl_closed_from_embed) {
				this.sparkl_closed_from_embed = false
				this.sparkl_showing = false

			// else reload the iframe if necessary, by re-rendering the SparklEmbed component
			} else if (reload) {
				++this.sparkl_embed_key
			}
		},

		save_changes_to_sparkl() {
			return new Promise((resolve, reject)=>{
				// if this is a sparkl activity, sparkl isn't open, and the title or instructions changed, call a separate service to save the title in the sparkl database
				// (if sparkl *is* showing, the user can change the title from Sparkl)
				if (this.edited_activity.tool_id == 'sparkl' && this.edited_activity.tool_activity_id != 0 && !this.sparkl_showing) {
					if (this.edited_activity.activity_title != this.original_activity.activity_title || this.edited_activity.activity_instructions != this.original_activity.activity_instructions) {
						let payload = {
							user_id: this.user_info.user_id,
							editor_email: this.user_info.email,
							activity_data: { activity_id: this.edited_activity.tool_activity_id },
							sparkl_origin: this.$store.state.sparkl_origin,
						}

						if (this.edited_activity.activity_title != this.original_activity.activity_title) payload.activity_data.title = this.edited_activity.activity_title
						if (this.edited_activity.activity_instructions != this.original_activity.activity_instructions) payload.activity_data.activity_instructions = this.edited_activity.activity_instructions
						
						U.ajax('save_sparkl_activity_data', payload, result=>{resolve(result)})
					} else resolve(false)
				} else resolve(false)
			})
		},

		save_activity(flag) {
			if (this.suppress_initial_save) {
				// see above
				this.suppress_initial_save = false
				return
			}

			this.edited_activity.activity_title = $.trim(this.edited_activity.activity_title)
			if (empty(this.edited_activity.activity_title)) {
				this.$alert('You must enter a title for the activity.')
				return
			}

			// if flag isn't 'from_sparkl' and sparkl is open, send a message TO sparkl telling it that the activity has been saved
			if (this.sparkl_showing && flag != 'from_sparkl') {
				this.$refs.sparkl_embed.execute('host_activity_saved', {activity_title: this.edited_activity.activity_title})
				this.save_activity_after_sparkl_save = true
				// ... then return; once sparkl is done saving, sparkl_activity_saved (above) will be called, then save_activity will be called again
				return
			}

			// check to see if we are unassigning (via removing an assignment altogether or editing assigned_to) any classes that previously had lineitems in the gradebook
			let lineitems_to_delete = {}, deleted_lineitem_count = 0
			if (!empty(this.original_activity?.assigned_to)) {
				for (let oa of this.original_activity.assigned_to) {
					if (this.edited_activity.assigned_to.findIndex(x=>x.matches(oa)) == -1) {
						// we're removing oa; if it only has a class_sourcedId, see if it has a lineitem
						if (!empty(oa.class_sourcedId) && empty(oa.user_sourcedId)) {
							let lineItemSourcedId = this.original_activity.lineItemSourcedId[oa.class_sourcedId]
							if (lineItemSourcedId) {
								// if we have a lineitem for the deleted assigned-to-class, add to lineitems_to_delete
								lineitems_to_delete[oa.class_sourcedId] = lineItemSourcedId
								++deleted_lineitem_count
							}
						}
					}
				}
			}
			// if we found any, ask if the teacher wants to delete the lineitems from the SIS gradebook
			if (deleted_lineitem_count > 0) {
				let text
				if (deleted_lineitem_count == 1) text = `You are breaking the link between a class section and this activity. <b>How would you like to handle SIS gradebook entries for the previously assigned section?</b><div class="mt-2"><b>Note:</b> even if you preserve current gradebook scores, HenryConnects will “forget” about the gradebook connection, so that any subsequent student work in HenryConnects will not trigger automatic grade updates in the SIS.</div>`
				else text = `You are breaking the link between ${deleted_lineitem_count} class sections and this activity. <b>How would you like to handle SIS gradebook entries for the previously assigned sections?</b><div class="mt-2"><b>Note:</b> even if you preserve current gradebook scores, HenryConnects will “forget” about the gradebook connections, so that any subsequent student work in HenryConnects will not trigger automatic grade updates in the SIS.</div>`

				let s = (deleted_lineitem_count == 1) ? '' : 's'
				this.$prompt({
					title: `Unlink Section${s} from the Gradebook`,
					text: text,
					promptType: 'radio',
					radioOptions: [
						{value: 'preserve_line_items', label: `<b class="green--text text--darken-3">Preserve</b> SIS grades (but stop sending grade updates to the SIS for ${s?'these':'this'} section${s})`},
						{value: 'delete_line_items', label: `<b class="red--text text--darken-3">Delete</b> any SIS grades for ${s?'these':'this'} section${s}`},
					],
					initialValue: 'preserve_line_items',
					dialogMaxWidth: 760,
					disableForEmptyValue: true,
					acceptText: ' OK ',
				}).then(lineitem_directive => {
					// if user said to delete line items, send lineitems_to_delete through to save_activity_finish; 
					// otherwise the lineitems will not be deleted (although we will "forget" about the lineitems in HC)
					if (lineitem_directive == 'delete_line_items') {
						this.save_activity_finish(flag, lineitems_to_delete)
					} else {
						this.save_activity_finish(flag)
					}
				}).catch(n=>{console.log(n)}).finally(f=>{})
			} else {
				this.save_activity_finish(flag)
			}
		},

		save_activity_finish(flag, lineitems_to_delete) {
			// save changes to sparkl activity (title, instructions) if we have any
			this.save_changes_to_sparkl()

			// if this is a google activity, generate an lti_context_id_hc if this is a new activity
			if (this.edited_activity.tool_id == 'google' && empty(this.edited_activity.lti_context_id_hc) && this.edited_activity.tool_activity_id == 0) {
				// context_id = teacher user_id + course code + academic year + current term
				this.edited_activity.lti_context_id_hc = `henryconnects_${this.edited_activity.creator_user_id}_${this.course_code}_${this.user_info.academic_year}_${this.$store.getters.current_term}`
				// note: we only do this for new activities because if we were to switch the lti_context_id_hc for an already-deployed activity, it might screw up the teacher's ability to see previously-submitted student submissions
			}
			// note: for sparkl, context_id really doesn't matter at this time???

			///////////////////////////////////////////
			let payload = {
				// TODO: deal with activity_class in save_activity; and/or deal with activity_template_id
				activity_class: this.activity_class,
				activity_data: this.edited_activity.copy_for_save(),
			}
			if (lineitems_to_delete) payload.lineitems_to_delete = lineitems_to_delete

			// now add assignees, based on assigned_to
			payload.assignees = []
			for (let assignee of this.edited_activity.assigned_to) {
				payload.assignees.push(assignee.copy_for_save())
			}
			// any assignees in original_activity that *aren't* in edited_activity need to be removed; we send the activity_student_mapping_id for those
			if (!empty(this.original_activity?.assigned_to)) {
				for (let oa of this.original_activity.assigned_to) {
					if (this.edited_activity.assigned_to.findIndex(x=>x.matches(oa)) == -1) {
						payload.assignees.push({
							activity_student_mapping_id: oa.activity_student_mapping_id,
							delete: 'yes',
						})
					}
				}
			}

			// if gradebook settings have changed, show a message indicating that it might take a while
			if (this.edited_activity.added_to_gradebook) {
				payload.loader_msg = 'Saving gradebook updates to Infinite Campus'
			}

			console.log('saving activity in HC  ', payload)
			this.$store.dispatch('save_activity', payload).then((result)=>{
				// reset lineitems_to_delete
				this.lineitems_to_delete = {}

				// updated data will be returned; emit it to the parent component, which will 
				this.$emit('edit_activity_saved', result.activity, flag)

				// if we got back an ic fail message, interpret it
				if (result.ic_status == 'gradebook-add-fail') {
					// this.edited_activity.added_to_gradebook = false
					let msg = 'The activity could not be added to the Infinite Campus gradebook.'
					if (result.ic_error) msg += ` Error description from Infinite Campus:<div class="mt-2"><i>${result.ic_error}</i></div>`
					this.$alert(msg)
					console.log(result)
				} else if (result.ic_status == 'gradebook-delete-fail') {
					// this.edited_activity.added_to_gradebook = false
					let msg = 'The activity could not be removed from the Infinite Campus gradebook.'
					if (result.ic_error) msg += ` Error description from Infinite Campus:<div class="mt-2"><i>${result.ic_error}</i></div>`
					this.$alert(msg)
					console.log(result)
				}

				// recreate edited_activity from result.activity; note that if the activity was new, this will make it so that we now have the activity_id
				this.create_edited_activity(result.activity)

				// save stringified original activity
				this.stringified_original_activity = JSON.stringify(this.edited_activity.copy_for_save())

				// if close_after_save is true, emit edit_activity_cancel
				if (this.close_after_save) {
					this.edit_activity_cancel(true)		// send true flag so we don't make the user confirm
				}
				
				// if flag is 'open_google, call open_student_activity_editor_tool again; google will now be opened, since the activity_id is now established
				if (flag == 'open_google') this.open_student_activity_editor_tool()

			}).catch((result)=>{
				console.log(result)
				this.$alert('An error occurred...')
			})
		},

		edit_activity_cancel(confirmed) {
			if (confirmed !== true && this.stringified_original_activity && this.stringified_original_activity != JSON.stringify(this.edited_activity.copy_for_save())) {
				this.$confirm({
				    text: 'You appear to have made changes. Are you sure you want to close the activity editor without saving these changes?',
					cancelText: 'Keep Editing',
				    acceptText: 'Close and Don’t Save',
				}).then(y => {
					this.edit_activity_cancel(true)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			this.$emit('edit_activity_cancel')
		},

		add_to_gradebook() {
			// title, available date, and due date must be set, and it must be assigned to at least one class, for it to go into the gradebook
			if (this.edited_activity.activity_title && this.edited_activity.available_date() && this.edited_activity.due_date()) {
				for (let assignee of this.edited_activity.assigned_to) {
					if (assignee.class_sourcedId) {
						// if we get to here it's OK for it go into the gradebook
						this.edited_activity.added_to_gradebook = true
						// immediately save, before they can screw up something else...
						this.save_activity()
						return
					}
				}
			}
			// if we get to here this didn't work...
			this.$alert('To add an activity to the gradebook, the activity title, available date, and due date must be set, and the activity must be assigned to at least one full section.')
		},

		remove_from_gradebook() {
			this.$confirm({
				title: 'Are you sure?',
				text: 'Note: if you remove the activity from the gradebook, any existing student grades in the Infinite Campus gradebook column will also be deleted. (However, student results will still persist in HenryConnects.)',
				acceptText: 'Remove Activity from Gradebook',
				acceptColor: 'red darken-2',
				dialogMaxWidth: 600,
				focusBtn: true,		// focus on the accept btn when dialog is rendered
			}).then(y => {
				this.edited_activity.added_to_gradebook = false
				this.save_activity()
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		assigned_to_option_filter(item, query_text, item_text) {
			query_text = query_text.toLowerCase()
			return item.text.toLowerCase().indexOf(query_text) > -1
		},
	}
}
</script>

<style lang="scss">
// note that most styles come from LessonEditor
.k-activity-editor-assign-to-display {
	flex:1 1 auto;
	border:1px solid #9a9a9a;
	padding:8px;
	background-color:#f8f8f8;
	border-radius:4px;
	font-size:14px;
}

.k-activity-editor-assign-to-control {
	.v-list-item {
		min-height:32px!important;
	}
}

.k-activity-editor-due-date-field {
	.fa-calendar-day {transform:scaleX(-1);}	// this makes the due date calendar flipped so the "day" is on the right
}

.k-activity-editor-buttons {
	border-top:1px solid #999;
	display:flex;
	padding-bottom:4px;
}

.k-activity-edit-due-date-editor {
	.fa-calendar-day {
		transform: scaleX(-1);
	}
}

.k-activity-edit-gradebook-display {
	font-size:14px;
	text-align:center;
	line-height:17px;
	margin-top:8px;
	padding:6px;
	background-color:#eee;
	border-radius:8px;
}

</style>
