<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
<div class="k-main-division-wrapper k-main-welcome">
	<SiteSearch @search_lps="search_lps" @search_case="search_case" />

	<div class="k-main-welcome__question k-shadow-text"><div><b class="amber--text">Good {{ time_of_day }}, {{user_info.first_name}}!</b></div></div>
	<div class="k-main-welcome__time-date k-shadow-text" v-html="time_string"></div>

	<div class="k-main-welcome--nav-btns">
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-if="show_parent_tab" class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('family_home')" x-large color="primary" :class="welcome_section_showing=='family_home'?'k-main-welcome--nav-btn-current':''"><v-icon>fas fa-house-user</v-icon></v-btn></template>Family Home</v-tooltip>
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('classes')" x-large color="primary" :class="welcome_section_showing=='classes'?'k-main-welcome--nav-btn-current':''"><v-icon>fas fa-chalkboard</v-icon></v-btn></template>My Classes</v-tooltip>
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-if="role=='staff'||role=='admin'" class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('communities')" x-large color="primary" :class="welcome_section_showing=='communities'?'k-main-welcome--nav-btn-current':''"><v-icon>fas fa-users</v-icon></v-btn></template>Communities</v-tooltip>
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-if="role=='staff'||role=='admin'" class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('pd')" x-large color="primary" :class="welcome_section_showing=='pd'?'k-main-welcome--nav-btn-current':''"><v-icon>fas fa-user-graduate</v-icon></v-btn></template>Professional Development</v-tooltip>
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('launchers')" x-large color="primary" :class="welcome_section_showing=='launchers'?'k-main-welcome--nav-btn-current':''"><v-icon>fas fa-circle-nodes</v-icon></v-btn></template>Connections</v-tooltip>
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-show="show_admin_tab&&$vuetify.breakpoint.smAndUp" class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('admin')" x-large color="primary" :class="welcome_section_showing=='admin'?'k-main-welcome--nav-btn-current':''"><v-icon>fas fa-cog</v-icon></v-btn></template>Site Admin Tools</v-tooltip>
	</div>

	<div class="k-main-welcome--pages">
		<FamilyHome ref="family_home" v-if="welcome_section_showing=='family_home'" />
		<LearningProgressionIndex2 ref="lp_index_component" v-if="welcome_section_showing=='classes' && my_classes_view=='lpindex'" />
		<!-- <LearningProgressionIndex ref="lp_index_component" v-if="welcome_section_showing=='classes' && my_classes_view=='lpindex'" /> -->
		<CourseList v-if="welcome_section_showing=='classes' && my_classes_view=='assignments'" />
		<CommunitiesList v-if="welcome_section_showing=='communities'" />
		<AdminList v-if="welcome_section_showing=='admin'" />
		<PDIndex v-if="welcome_section_showing=='pd'" />
		<Launchers v-if="welcome_section_showing=='launchers'" />
	</div>
	<CASETree v-if="case_tree_loaded" v-show="case_tree_showing" ref="case_tree"
		@hide_tree="case_tree_showing=false"
		:framework_identifier="case_framework_identifier"
		:showing="case_tree_showing"
		:imported_search_terms="case_search_terms"
	/>
</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import FamilyHome from './FamilyHome'
import LearningProgressionIndex from './../courses/LearningProgressionIndex'
import LearningProgressionIndex2 from './../courses/LearningProgressionIndex2'
import CourseList from './../courses/CourseList'
import PDIndex from './../collections/PDIndex'
import CommunitiesList from './../communities/CommunitiesList'
import SiteSearch from '../resources/SiteSearch'
import Launchers from '../collections/Launchers'
import AdminList from './../admin/AdminList'
import CASETree from '../resources/CASETree'

export default {
	components: { FamilyHome, CourseList, LearningProgressionIndex, LearningProgressionIndex2, CommunitiesList, PDIndex, Launchers, SiteSearch, AdminList, CASETree },
	data() { return {
		todo: '',
		time_string: '',
		time_string_colon: true,
		time_of_day: 'day',

		classes_initialized: false,

		case_tree_loaded: false,
		case_tree_showing: false,
		case_framework_identifier: '',
		case_search_terms: '',
	}},
	computed: {
		...mapState(['my_classes_view', 'welcome_section_showing', 'user_info', 'todo_item']),
		...mapGetters(['small_screen']),
		role() { return this.user_info.role },
		filter_on_user_id() {
			// Presumes that we only want to show user's content (not coteacher generated content) in Welcome view
			return this.user_info.user_id
		},
		show_parent_tab() {
			if (this.role == 'parent' || this.role == 'admin') return true

			// if the user has PG editing rights and they're showing with their "default" role, show the PG tab
			// (this prevents edge cases like when a system admin is viewing as staff)
			if (vapp.has_admin_right('pg') && (this.user_info.system_role == this.user_info.role)) return true

			return false
		},
		show_admin_tab() {
			// if user has system admin rights and is viewing as staff, don't show
			if (this.user_info.system_role == 'admin' && this.user_info.role == 'staff') return false
			// otherwise base on su admin right
			return vapp.has_admin_right('su') 
		},
	},
	created() {
		this.calculate_time_string();
		setInterval(()=>{
			this.calculate_time_string();
		}, 60000)
	},
	mounted() {
		// shortcut to allow for vapp.welcome_view.switch_welcome_section('classes')
		vapp.welcome_view = this

		this.check_for_message_redirect()
	},
	watch: {
	},
	methods: {
		switch_welcome_section(s) {
			this.$store.commit('set', ['welcome_section_showing', s])
		},

		calculate_time_string() {
			// changing this screws up the spanish translation
			// this.time_string_colon = !this.time_string_colon
			let d = this.$store.state.now_date_obj

			let hour = d.getHours()

			if (hour > 2 && hour < 12) this.time_of_day = 'morning'
			else if (hour < 18) this.time_of_day = 'afternoon'
			else this.time_of_day = 'evening'

			hour = hour % 12
			if (hour == 0) hour = 12

			let minute = d.getMinutes()
			if (minute < 10) minute = '0' + minute

			// Thu Jan 29&nbsp;&nbsp;10:50
			this.time_string = sr('it’s&nbsp;$4<span style="$5">:</span>$6&nbsp;on&nbsp;$1, $2 $3',
				['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][d.getDay()],
				['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][d.getMonth()],
				// ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()],
				d.getDate(),
				hour,
				this.time_string_colon ? '' : 'visibility:hidden',
				minute,
			)
		},

		todo_keypress(evt) {
            if (evt.key === "Enter") this.create_todo()
		},

		create_todo() {
			this.$store.commit('set', ['todo_item', this.todo])
			U.local_storage_set('district_portal_todo_item', this.todo)
			this.todo = ''
			$('.k-main-welcome__question').find('input').blur()
		},

		search_lps(search_string) {
			this.$store.commit('set', ['my_classes_view', 'lpindex'])
			this.switch_welcome_section('classes')
			this.$nextTick(()=>{
				console.log('search_lps: ' + search_string)
				this.$refs.lp_index_component.external_search(search_string)
			})
		},

		search_case(search_string, case_identifier) {
			this.case_framework_identifier = case_identifier
			this.case_tree_showing = true
			this.case_tree_loaded = true
			this.case_search_terms = search_string
		},

		check_for_message_redirect() {
			// Check get string for a 'message_id' variable
			let params = (new URL(document.location)).searchParams
			// console.log('check_for_message_redirect')
			if (params.get('message_id') != undefined) {
				// console.log('found ' + params.get('message_id'))
				// if found, send to the "my courses" page
				this.switch_welcome_section('classes')
				this.$store.commit('set', ['my_classes_view', 'assignments'])
			}
		},

	}
}
</script>

<style lang="scss">
.k-main-welcome {
	text-align:center;

	.k-main-welcome__question {
		margin:5vh 0 1vh 0;
		font-size: 35px;
		line-height:45px;
		font-weight:bold;

		.v-input {
			max-width:600px;
			margin: 10px auto;

			input {
				text-align:center;
				font-size:24px;
			}
		}
	}

	.k-main-welcome__time-date {
		font-family:$display-font;
		font-weight:900;
		font-size:24px;
		margin-bottom:4vh;
	}

	.k-main-welcome--nav-btns {
		margin-top:2vh;
		// margin-bottom:-5px;
		position:relative;
		z-index:2;

		.k-main-welcome--nav-btn {
			// display:block;
			width:120px;
			height:80px;
			padding-top:5px;
			border-color:$secondary-color!important;
			border-width:3px 3px 0 3px!important;
			border-style:solid;
			border-radius:20px 20px 0 0;

			// -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)!important;
			// box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)!important;
			-webkit-box-shadow: none!important;
			box-shadow: none!important;

			margin:0 12px;

			.v-icon {
				color:$secondary-color;
				font-size:54px;
			}
		}

		.k-main-welcome--nav-btn-current {
			border-color:transparent!important;
			background-color:$page-background!important;
			.v-icon {
				color:$primary-color;
			}
		}
	}

	.k-main-welcome--pages {
		position:relative;
	}
}

</style>
