<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="800" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Duplicate Lesson Plan</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<div>Original lesson plan title: <b>{{ lesson_data.title }}</b></div>
				<div class="mt-4"><v-text-field outlined dense hide-details label="Duplicate lesson plan title"
						v-model="title_start"></v-text-field></div>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-btn color="secondary" @click="$emit('dialog_cancel')">Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-menu top>
					<template v-slot:activator="{ on }">
						<v-btn color="primary" v-on="on">Duplicate Lesson Plan</v-btn>
					</template>
					<v-list min-width="250">
						<v-list-item @click="duplicate_lesson(false)">
							<v-list-item-title>Keep original lesson plan</v-list-item-title>
						</v-list-item>
						<v-list-item @click="duplicate_lesson(true)">
							<v-list-item-title>Archive original lesson plan</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		lesson_data: { type: Object, required: true },
	},
	data() {
		return {
			dialog_open: true,
			title_start: '',
		}
	},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
		this.title_start = this.lesson_data.lesson_title
	},
	mounted() {
	},
	methods: {
		duplicate_lesson(archive_original) {
			const new_title = $.trim(this.title_start)
			const duplicate_lesson = {
				item_id: this.lesson_data.lesson_id,
				new_lesson_title: new_title,
			}
			this.$emit('duplicate_lesson_created', duplicate_lesson, archive_original)
		},

	}
}
</script>

<style lang="scss">
</style>
