<template><div @click="$emit('show_lp',course,$event)" class="k-main-collection-item k-cv-lp-tile" :class="lp_css">
	<div class="k-cv-lp-tile__title" :class="lp_css_color">
		<div class="k-cv-lp-tile__title-title" v-html="title_display"></div>
		<div class="k-cv-lp-tile__title-teacher" v-if="teacher_display&&!course_showing">{{teacher_display}}</div>
	</div>
	<div class="k-cv-lp-tile-message-count" v-if="unread_messages&&(!lp||!lp.viewing_origin_teacher_activities)">
		<v-tooltip bottom>
			<template v-slot:activator="{on}">
				<v-icon v-on="on" color="white">far fa-comment</v-icon>
				<div v-on="on" class="k-cv-lp-tile-message-count-text">{{unread_messages}}</div>
			</template>
			<span v-html="unread_messages_tooltip"></span>
		</v-tooltip>
	</div>
	<div class="k-cv-lp-tile-activity-count" v-if="activity_count&&(!lp||!lp.viewing_origin_teacher_activities)"><v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" class="k-cvlp-unit__status white">{{activity_count}}</div></template><span v-html="activity_count_tooltip"></span></v-tooltip></div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		course: { type: Object, required: true },
		course_showing: { type: String, required: true },
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info', 'all_courses', 'course_update_trigger', 'my_activities', 'my_activity_results', 'messages']),
		...mapGetters(['beta_options', 'studentish_role', 'manage_assignments']),
		lp_css() {
			let s = ''
			if (this.course_showing) s += ' k-cv-lp-tile--course_showing'
			if (this.course_showing == this.course.course_code) s += ' k-cv-lp-tile--this_course_showing'
			return s
		},
		unread_messages() {
			let n = 0
			for (let message of this.messages) {
				if (message.course_code !== this.course.course_code) continue
				if (!message.is_read && message.message_id !== 0) ++n
			}
			return n
		},
 		lp() {
			return this.all_courses.find(c=>c.course_code == this.course.course_code)
		},
		lp_css_color() {
			if (this.lp) return U.subject_tile_css(this.lp)
			else return U.subject_tile_css(this.course)
		},
		title_display() {
			// use the lp title if we have one; otherwise use course.title
			let s
			if (this.lp) s = this.lp.title
			else s = this.course.title

			// add <wbr> tags after slashes
			s = s.replace(/\//g, '/<wbr>')

			return s
		},
		teacher_display() {
			if (this.user_info.role == 'staff' || this.user_info.role == 'admin') return ''
			if (!this.course.classCodes || this.course.classCodes.length == 0) return ''
			// debug
			// if (this.course.classCodes.length > 1) console.log('teacher_display: ' + this.course.classCodes.join('; '))
			return this.course.classCodes[0]
		},
		activity_count() {
			if (!this.manage_assignments) return 0
			let n = 0
			for (let activity of this.my_activities) {
				if (activity.course_code == this.course.course_code) {
					// for students, don't count complete activities or activities that aren't available
					if (this.studentish_role) {
						if (this.my_activity_results[activity.activity_id]?.complete() || !activity.available_to_students()) {
							continue
						}
					// for teachers, only count currently due activities that I've created
					} else {
						if (activity.creator_user_id != this.user_info.user_id) continue
						if (!activity.currently_due()) continue
					}
					++n
				}
			}
			return n
		},
		activity_count_tooltip() {
			if (this.studentish_role) {
				return sr('$1 incomplete $2', this.activity_count, U.ps('activity', this.activity_count, 'activities'))
			} else {
				return sr('$1 $2 currently available and due', this.activity_count, U.ps('activity', this.activity_count, 'activities'))
			}
		},
		unread_messages_tooltip() {
			return sr('$1 unread $2', this.unread_messages, U.ps('message', this.unread_messages, 'messages'))
		}
	},
	created() {
		// console.log(this.course)
	},
	mounted() {
	},
	methods: {
		user_is_message_author(m) {
			console.log('message.author_user_id: ' + m.author_user_id)
			console.log('this.user_info.user_id: ' + this.user_info.user_id)
			return m.author_user_id == this.user_info.user_id
		},
	}
}
</script>

<style lang="scss">

.k-main-wrapper .k-main-collection-item.k-cv-lp-tile {
	width:190px;
	height:190px;
	border-radius:35px;
	text-align:left;
	padding:0;
	// margin:12px;
	flex-direction: row;
	align-items: center;
	// background-color:rgba(255,255,255, 0);

	.k-cv-lp-tile__title {
		flex:1 1 190px;
		display:flex;
		align-items:center;
		flex-wrap:wrap;
		height:190px;
		border-radius:35px;
		font-size:22px;
		line-height:28px;
		font-weight:bold;
		// color:#444;
		padding:15px;

		.k-cv-lp-tile__title-title {
			flex:1 1 100%;
		}

		.k-cv-lp-tile__title-teacher {
			flex:1 1 100%;
			font-size:12px;
			line-height:17px;
			font-weight:normal;
		}
	}

	.k-cv-lp-tile-activity-count {
		position:absolute;
		right:12px;
		bottom:12px;
	}

	.k-cv-lp-tile-message-count {
		position:absolute;
		left:11px;
		bottom:9px;

		> .v-icon {
			font-size:33px;
			transform:scaleX(0.85)	// this makes it so the bubble is a circle
		}

		.k-cv-lp-tile-message-count-text {
			position:absolute;
			display:inline-block;
			font-size:16px;
			left: 0;
			right: 0;
			top: 4px;
			text-align:center;
			font-weight:900;
			color:#fff;
		}
	}

	.k-cv-lp-tile__extra {
		flex:1 1 40px;
		text-align:center;
		height:190px;
		width:40px;
		align-self: center;

		.k-cv-lp-tile__extra-teacher {
			padding-left:5px;
		}

		.k-cv-lp-tile__extra-student {
			transform: rotate(90deg);
			white-space:nowrap;
			padding-left:5px;

			.k-cv-lp-tile__extra-student--inner {
				width:170px;
				overflow:hidden;
				text-overflow: ellipsis;
				padding-left:20px;
			}

			.k-cv-lp-tile-ha .k-cv-lp-tile-hatext:hover {
				text-decoration:underline;
			}

			.k-cv-lp-tile-hatext {
				padding-left:5px;
			}
		}
	}

	.k-cvlp-unit__status {
		display:inline-block;
		font-size:16px;
		width:28px;
		height:28px;
		line-height:28px;
		border-radius:28px;
		text-align:center;
		font-weight:900;
		margin:0 1px;
		// transform:rotate(-90deg);
	}
}

.k-main-wrapper .k-main-collection-item.k-cv-lp-tile--no-activities {
	width:190px;
	.k-cv-lp-tile__extra {
		display:none;
	}
}

.k-main-wrapper .k-main-collection-item.k-cv-lp-tile--course_showing {
	width:100px;
	height:100px;
	border-radius:16px;
	opacity:0.6;
	margin:6px;
	background-color:#f8f8f8;
	-webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;

	.k-cv-lp-tile__title {
		flex:1 1 100px;
		height:100px;
		width:100px;
		border-radius:16px;
		font-weight:normal;
		font-size:12px;
		line-height:14px;
		padding:8px;
	}

	.k-cv-lp-tile-activity-count {
		right:6px;
		bottom:6px;
	}

	.k-cv-lp-tile-message-count {
		position:absolute;
		left:5px;
		bottom:4px;

		> .v-icon {
			font-size:21px;
		}

		.k-cv-lp-tile-message-count-text {
			position:absolute;
			display:inline-block;
			left:0;
			top:1px;
			font-size:10px;
			text-align:center;
			font-weight:900;
		}
	}

	.k-cv-lp-tile__extra {
		flex:1 1 30px;
		height:100px;
		width:30px;

		.k-cv-lp-tile__extra-student {
			.k-cv-lp-tile__extra-student--inner {
				width:90px;
				padding-left:10px;
			}

			.k-cv-lp-tile-hatext {
				display:none;
			}
		}
	}

	.k-cvlp-unit__status {
		font-size:10px;
		width:18px;
		height:18px;
		line-height:18px;
		border-radius:18px;
		margin:0px;
	}

}

.k-main-wrapper .k-main-collection-item.k-cv-lp-tile--course_showing.k-cv-lp-tile--no-activities {
	width:100px;
	height:100px;
	max-height:100px;
}


.k-main-wrapper .k-main-collection-item.k-cv-lp-tile--this_course_showing {
	font-weight:bold;
	background-color:#eee;
	opacity:1.0;
	-webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;

	.k-cv-lp-tile__title {
		color:#000;
		font-weight:bold;
	}
}

.k-cv-lp-tile-caught-up {
	color:#555;
}

</style>
