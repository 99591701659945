<!-- TODO: This needs to be combined with lessons/SectionChooser -->
<template>
	<v-dialog v-model="dialog_open" max-width="1000" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Section Filter</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<div>Select the Infinite Campus sections you would like to choose from when sending messages to students for this course:</div>
				<div class="mt-3 d-flex">
					<div style="width:160px" class="mr-2"><v-select v-model="term_for_messages" :items="terms" label="Term" dense outlined hide-details></v-select></div>
					<div>
						<div v-for="(section, index) in sections" :key="section.value">
							<v-checkbox class="mt-0 mb-1 pt-0" v-model="chosen_sections[section.value]" hide-details><template v-slot:label><span style="font-size:14px; color:#000;" v-html="section.text"></span></template></v-checkbox>
						</div>
						<div class="mt-2">
							<v-btn v-visible="show_select_all" class="mx-1" x-small color="secondary" @click="select_all">Select All</v-btn>
							<v-btn v-visible="show_select_none" class="mx-1" x-small color="secondary" @click="select_none">Select None</v-btn>
						</div>
					</div>
				</div>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="done"><v-icon small class="mr-2">fas fa-check</v-icon>Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<!-- add to includer component, along with import line and components item -->
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		course_code: { type: String, required: false },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		term: null,
		chosen_sections: {},
	}},
	computed: {
		...mapState(['sis_classes', 'term_for_messages']),
		...mapGetters([]),
		term_for_messages: {
			get() { return this.$store.state.lst.term_for_messages },
			set(val) { this.$store.commit('lst_set', ['term_for_messages', val]) }
		},
		terms() {
			return [
				{ value:'0', text:'All Terms' },
				{ value:'1', text:'Term 1' },
				{ value:'2', text:'Term 2' },
				{ value:'3', text:'Term 3' },
				{ value:'4', text:'Term 4' }
			]
		},
		sections() {
			let arr = []
			for (let my_class of this.sis_classes) {
				let for_this_course = (my_class.course_code == this.course_code)
				// only show sections for this course; could possibly have an option to show for all courses
				if (!for_this_course) continue

				for (let i = 0; i < my_class.class_sourcedIds.length; ++i) {
					let n_students = 0
					if (!empty(my_class.students[i])) n_students = my_class.students[i].length
					if (n_students == 0) continue

					// skip if we're filtering by term and this doesn't match the term
					if (this.term_for_messages != 0 && !my_class.period_matches_term(i, this.term_for_messages)) {
						continue
					}

					let text = sr('$1&nbsp <b>$2 $3</b>', my_class.section_title(i, {student_count:false, title:true, teacher:true, term:0}), my_class.students[i].length, U.ps('student', my_class.students[i].length))
					arr.push({value: my_class.class_sourcedIds[i], text:text})
				}
			}

			arr.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true}))

			return arr
		},
		show_select_all() {
			for (let s of this.sections) {
				if (!this.chosen_sections[s.value]) return true
			}
			return false
		},
		show_select_none() {
			for (let s of this.sections) {
				if (this.chosen_sections[s.value]) return true
			}
			return false
		},
	},
	watch: {
		term_for_messages() {
			if (this.term_for_messages != 0) {
				this.select_none()
			}
		}
	},
	created() {
		let mcs = this.$store.state.lst.message_chooser_sections[this.course_code]
		let chosen_sections = {}
		for (let my_class of this.sis_classes) {
			if (my_class.course_code != this.course_code) continue
			for (let i = 0; i < my_class.class_sourcedIds.length; ++i) {
				if (mcs && mcs[my_class.class_sourcedIds[i]]) chosen_sections[my_class.class_sourcedIds[i]] = true
				else chosen_sections[my_class.class_sourcedIds[i]] = false
			}
		}
		this.chosen_sections = chosen_sections
	},
	mounted() {
	},
	methods: {
		select_all() {
			for (let s of this.sections) {
				this.chosen_sections[s.value] = true
			}
		},
		select_none() {
			for (let s in this.chosen_sections) {
				this.chosen_sections[s] = false
			}
		},
		done() {
			// save choices to message_chooser_sections
			let o = extobj(this.$store.state.lst.message_chooser_sections)

			// if nothing is chosen, clear the record for this course
			let nothing_chosen = true
			for (let cc in this.chosen_sections) {
				if (this.chosen_sections[cc]) {
					nothing_chosen = false
					break
				}
			}
			o[this.course_code] = nothing_chosen ? null : this.chosen_sections
			this.$store.commit('lst_set', ['message_chooser_sections', o])
			this.$emit('dialog_cancel')
		},
	}
}
</script>

<style lang="scss">
</style>
