<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<div class="k-search-wrapper" :class="on_home_page?'k-search-wrapper--home':''"><div class="k-search-wrapper-inner">
		<v-autocomplete outlined rounded hide-details clearable
			ref="main_search_box"
			background-color="rgba(255, 255, 255, 0.95)"
			xdense="small_screen"
			:dense="!on_home_page||$vuetify.breakpoint.smAndDown"
			:disabled="false"
			:items="search_items"
			:filter="search_filter"
			prepend-inner-icon="fa fa-search"
			@keypress="search_keypress"
			@change="quicksearch_item_selected"
			@focus="search_focused"
			@blur="search_blurred"
			@click:prepend-inner="do_search"
			:placeholder="search_type.search_box_label"
			v-model="selected_search_item"
			:search-input.sync="search_string"
			autocomplete="new-password"
			item-text="text_display"
			item-value="href"
			:return-object="true"
			:hide-no-data="false"
			:menu-props="{ 'contentClass': 'k-search-quicksearch-menu', 'dense': true }"
		>
			<template v-slot:no-data>
				<v-list-item v-show="false"><v-list-item-title><!-- this keeps the prepend-item slot always showing... --></v-list-item-title></v-list-item>
			</template>
			<template v-slot:prepend-item>
				<div class="k-search-results-top my-1">
					<div class="mr-3 grey--text text--darken-2"><b>Search:</b></div>
					<div v-if="show_safari" class="k-search-results-top-item" @click="outside_search('safari')"><img src="./../../images/search_safari.png"></div>
					<div class="k-search-results-top-item" @click="outside_search('henry')"><img src="./../../images/search_henry.png"></div>
					<div class="k-search-results-top-item" @click="outside_search('google')"><img src="./../../images/search_google.png"></div>
					<div class="k-search-results-top-item k-search-results-top-item-button elevation-2" style="background-color:#92c3e9;" @click="outside_search('courses')"><div class="">Learning<br>Progressions</div></div>
					<v-menu bottom left>
						<template v-slot:activator="{on}"><div v-on="on" class="k-search-results-top-item k-search-results-top-item-button elevation-2" style="background-color:#f9b3a5;">Henry T&amp;L<br>Standards</div></template>
						<v-list>
							<v-list-item v-for="(case_identifier, subject) in subjects" :key="case_identifier" @click="outside_search('standards', case_identifier)"><v-list-item-title>{{subject}}</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>

				</div>
			</template>

		</v-autocomplete>
	</div></div>

	<SearchResults v-if="show_search_results" @dialog_done="show_search_results=false"/>
	<SafariSearch v-if="show_safari_search" @dialog_done="show_safari_search=false"/>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import SearchResults from '../resources/SearchResults'
import SafariSearch from '../resources/SafariSearch'
export default {
	components: { SearchResults, SafariSearch },
	data() { return {
		search_string: '',		// this will mirror the value in the autocomplete input item itself...
		selected_search_item: {},	// ... this is the v-model for the autocomplete; it will be a "Link" object if the user actually clicks on an item
		search_terms: [],
		previous_search_string: '',
		show_search_results: false,
		search_results: [],
		show_safari_search: false,
		search_items: [],
	}},
	computed: {
		...mapState(['searchable_links', 'user_info', 'subjects']),
		...mapGetters(['show_safari', 'small_screen']),
		search_type() {
			if (this.$route.name == 'communities_list') return {
				name: 'communities_list',
				search_box_label: 'Search for a new Community to join...',
				results_title: 'Communities',
			}

			if (this.$route.name == 'community') return {
				name: 'community',
				search_box_label: 'Search Community announcements, resources, and posts...',
				placeholder_message: 'Searches for Community content have not yet been implemented.',
				results_title: 'Community Announcements, Resources, and Posts',
			}

			if (this.$route.name == 'learning_progression') return {
				name: 'learning_progression',
				search_box_label: 'Search for resources by keyword...',
				placeholder_message: 'safari_search',
				results_title: '...',
			}

			return {
				name: 'default',
				search_box_label: 'Search...',
				placeholder_message: 'Note that search will be contextual to where you are on the HenryConnects site: for example, if you’re currently viewing the Grade 3 Science Learning Progression, you will search for content related to this topic area.',
				results_title: '...',
			}
		},

		quicksearch_items() {
			// initialize quicksearch list here
			let all_links = []
			// order quick-search links depending on user...

			// everyone starts with top_hits
			$.merge(all_links, this.searchable_links.top_hits)

			// next do faq for the user's role, and prepare for other relevant roles
			let other_roles = []
			// 'student', 'staff', 'parent', 'admin', 'unknown'
			if (this.user_info.role == 'admin' || this.user_info.role == 'staff') {
				$.merge(all_links, this.searchable_links.employee_links)

				// employees may also want to use parent and student links
				$.merge(other_roles, this.searchable_links.parent_links)
				$.merge(other_roles, this.searchable_links.student_links)

			} else if (this.user_info.role == 'student') {
				$.merge(all_links, this.searchable_links.student_links)

				// students may also want to use parent links
				$.merge(other_roles, this.searchable_links.parent_links)

			} else {	// 'parent' or 'unknown'
				$.merge(all_links, this.searchable_links.parent_links)

				// parents may also want to use student links
				$.merge(other_roles, this.searchable_links.student_links)
			}

			// next do community_links, followed by the other_roles collected above
			$.merge(all_links, this.searchable_links.community_links)
			$.merge(all_links, other_roles)

			// then finally do school_links and schoolwire_nav_links
			$.merge(all_links, this.searchable_links.school_links)
			$.merge(all_links, this.searchable_links.schoolwire_nav_links)

			// create text_lc versions of each link
			for (let link of all_links) {
				this.$store.commit('set', [link, 'text_lc', link.text.toLowerCase()])

				// also add icon to text -- if we use the slot, it doesn't do the text highlighting...
				this.$store.commit('set', [link, 'text_display', link.text + ' →'])	//  »
			}

			// remove duplicates, keeping the first of any duplicates
			for (let i = all_links.length-1; i >= 0; --i) {
				let l = all_links[i]
				if (all_links.findIndex(o=>o.href == l.href) != i || all_links.findIndex(o=>o.text_lc == l.text_lc) != i) {
					// console.log(sr('found duplicate ($1): ', i), l.text)
					all_links.splice(i, 1)
				}
			}

			// and that's our quicksearch array!
			return all_links
		},
		on_home_page() {
			return this.$route.name == 'welcome'
		},
	},
	created() {
		vapp.site_search_component = this
	},
	mounted() {
	},
	watch: {
		search_string() {
			// console.log('search_string watcher:', this.search_string)
			// whenever the search_string is modified (whether the user does so or it's done programatically),
			// a) set search_terms to an array with the search term words
			// b) set search_items to the quicksearch_items array if we're actually searching for something,
			//    or to an empty array if search string is empty (so we won't see an unfiltered list of all items)

			if (empty(this.search_string)) {
				this.search_terms = []
				if (this.search_items.length > 0) this.search_items = []
			} else {
				// convert string to lower case and split on spaces
				this.search_terms = $.trim(this.search_string).toLowerCase().split(/\s+/)
				if (this.search_items.length == 0) this.search_items = this.quicksearch_items
			}
		}
	},
	methods: {
		search_focused() {
			// when the user focuses in the search box, restore the previously-typed search string
			// console.log('search focused')
			if (empty(this.search_string) && !empty(this.previous_search_string)) {
				this.search_string = this.previous_search_string
			}
		},

		search_blurred() {
			// when the user blurs from the search box, set previous_search_string to whatever was in search_string before we left (which might be null)
			// console.log('search_blurred: ' + this.search_string)
			this.previous_search_string = this.search_string
		},

		quicksearch_item_selected(item) {
			// the user selects an item from the "quicksearch" menu of the autocomplete component, so open the item!
			// console.log('quicksearch_item_selected: ' + this.search_string)

			if (empty(item)) return

			// open the item in a new window
			window.open(item.href)

			// stash the current search string (what the user actually typed) so we can get it back later
			let pss = this.search_string

			// reset search_string, selected_search_item, and search_items so that we clear the menu
			this.search_string = ''
			this.selected_search_item = null
			this.search_items = []

			// blur the search box; this will temporarily clear previous_search_string...
			this.$refs.main_search_box.blur()
			setTimeout(()=>{
				// ... then after a few ms (nextTick doesn't work), set previous_search_string to pss
				this.previous_search_string = pss
			}, 10)
		},

		search_keypress(e) {
			// when the user presses a key, check for enter, and if clicked, run do_search
			// console.log('search_keypress', e.key)
			if (e.key == 'Enter') {
				this.do_search()
			}
		},

		search_filter(item, search_string) {
			// this is called when the autocomplete component initiates a search
			// item is the item being tested; search_string is the entered text; return true if the item should be shown
			// search_terms will always be the broken-up list of words, as calculated above, when we get to here

			// if no search_string, show no results
			if (empty(search_string)) return false

			// for quick search, we'll keep it simple (at least for now): just return true if all search_terms strings are in the item
			for (let st of this.search_terms) {
				// console.log(st, item.text_lc)
				if (item.text_lc.indexOf(st) == -1) return false
			}
			return true
		},

		outside_search(where, arg2) {
			// if (empty(this.search_string) && (where == 'google' && where != 'standards' &&)) {
			// 	this.$inform('Enter one or more search terms before searching!')
			// 	return
			// }

			let search_string_for_url = ''
			if (!empty(this.search_string)) search_string_for_url = this.search_string.replace(/\s+/g, '+')

			if (where == 'google') {
				window.open(sr('https://www.google.com/search?q=$1', search_string_for_url))
			} else if (where == 'henry') {
				window.open(sr('https://www.google.com/search?hl=en&as_q=$1&as_sitesearch=henry.k12.ga.us', search_string_for_url))

			} else if (where == 'safari') {
				// for safari send search string as keywords
				let o = { tool: 'safari', endpoint: this.$store.state.safari_lti_endpoint }
				if (!empty(this.search_string)) {
					o.search_params = { keywords: this.search_string }
				}
				this.$store.dispatch('lti_launch', o)

			} else if (where == 'courses') {
				// this fn will to LP index page, then enter search terms in search box; clear search terms here
				this.$emit('search_lps', this.search_string)
				this.search_string = ''
				this.$refs.main_search_box.blur()
			} else if (where == 'standards') {
				this.$emit('search_case', this.search_string, arg2)
				this.search_string = ''
				this.$refs.main_search_box.blur()
			}
		},

		do_search() {
			if (empty(this.search_string)) return
			if (this.search_type.name == 'default') {
				this.outside_search('henry')
				return
			}

			if (empty(this.search_type.placeholder_message)) {
				U.loading_start()
				U.ajax('search_' + this.search_type.name, {user_id:this.$store.state.user_info.user_id, search_string:this.search_string}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert('Error loading search results.')
						return
					}
					console.log('search results', result)
					this.search_results = result.search_results
					this.show_search_results = true
				});

			} else if (this.search_type.placeholder_message == 'safari_search') {
				U.loading_start()
				setTimeout(()=>{
					U.loading_stop()
					this.show_safari_search = true
				}, 250)

			} else {
				U.loading_start()
				setTimeout(()=>{
					U.loading_stop()
					this.$alert({'title': 'Search', text: this.search_type.placeholder_message})
				}, 250)
			}
		},

		close_search() {
			this.search_results = []
			this.show_search_results = false
		},
	}
}
</script>

<style lang="scss">
.k-search-wrapper {
	// margin-left:140px;
	margin-top:2vh;
	.k-search-wrapper-inner {
		max-width:800px;
		margin:0 auto;

		.theme--light.v-text-field--outlined fieldset {
			// border:3px solid $primary-color;
			border:0;
		}
		.v-text-field--outlined .v-label--active {
			transform:translateY(-20px) scale(0.75);
			padding:0 5px;
			// font-weight:bold;
		}

		// hide the "caret" icon in the autocomplete box, because we're using it as a search box only
		.v-input__append-inner:last-of-type {
			display:none;
		}
	}
}

.k-search-wrapper--home {
	margin-top:calc(2vh + 40px);
}

.k-search-quicksearch-menu {
	border-radius:28px!important;
	margin-top:3px;

	.k-search-results-top {
		display:flex;
		width:100%;
		align-items: center;
		justify-content: center;
		font-size:20px;
	}

	.k-search-results-top-item {
		margin:5px 5px;
		img {
			height:40px;
			display:block
		}
		cursor:pointer;
	}

	.k-search-results-top-item-button {
		font-size:13px;
		font-weight:bold;
		line-height:16px;
		text-align:center;
		border-radius:8px;
		padding:1px 5px;
		width:100px;
		height:35px;
	}
}

</style>
