<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-main-collection k-lp-admin-wrapper">
	<h2 class="k-main-collection-header k-page-title d-flex mb-4">
		<v-icon large color="primary" class="mr-4" style="margin-top:6px">fas fa-chalkboard</v-icon><b>Learning Progression Index</b>
		<v-spacer/>
		<v-btn color="secondary" class="k-tight-btn" @click="back_to_classes"><v-icon small class="mr-1">fas fa-arrow-left</v-icon>My Courses</span></v-btn>
		<v-menu eager bottom right><template v-slot:activator="{on}"><v-btn v-show="can_view_resource_report&&!small_screen" class="ml-4" v-on="on" small fab color="#999" dark><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template>
			<v-list dense>
				<v-list-item v-if="can_view_resource_report" @click="resource_report_showing=true"><v-list-item-icon><v-icon small>fas fa-table</v-icon></v-list-item-icon><v-list-item-title>View Resource Usage Reports</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</h2>

	<div v-if="false" class="k-lps-unavailable-note"><i>Note that as of 7/15/2021, HenryConnects is serving Learning Progressions for the 2021-2022 school year. Some courses’ Learning Progressions have not yet been finalized for the new school year and therefore are not yet appearing in the Learning Progression Index. All courses should be ready and available here by 7/23/2021.</i></div>

	<div v-show="initialized" class="d-flex align-center justify-center flex-wrap mb-4">
		<div class="k-course-index-control-wrapper">Show courses for:</div>
		<div class="k-course-index-control-wrapper"><v-select v-model="grade" :items="grades" label="Grade" solo hide-details dense class="k-lp-grade-menu" :menu-props="{maxHeight:700}"></v-select></div>
		<div class="k-course-index-control-wrapper"><v-select v-model="subject" :items="subjects" label="Subject" solo hide-details dense class="k-lp-subject-menu" :menu-props="{maxHeight:700}"></v-select></div>
		<div class="k-course-index-control-wrapper" style="min-width:300px"><v-text-field
			v-model="search_string"
			prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
			label="Search" single-line hide-details outlined dense background-color="#fff"
		></v-text-field></div>
		<!-- <v-spacer/> -->
	</div>

	<div v-show="initialized" class="mb-8">
		<div v-if="search_results==null" class="text-center"><b style="color:#666"><i>Choose a grade and/or subject, or enter search terms, to view Learning Progressions.</i></b></div>
		<div v-else>
			<div v-if="search_results.length==0" class="text-center"><b style="color:#666"><i>There are no Learning Progressions on file that match the grade and/or subject you entered.</i></b></div>
			<div v-else class="k-main-collection-body">
				<div v-for="(lp) in search_results" @click="go_to_lp(lp)" class="k-elevated k-main-collection-item k-lp-tile" :class="lp_css(lp)" v-html="lp_title(lp)"></div>
			</div>
			<div v-if="search_results.length>0" class="text-center"><b style="color:#666"><i>To add to your “My Courses” collection, click to view the course, then click “ADD TO MY COURSES” in the upper-right corner of the window.</i></b></div>
		</div>
	</div>

	<AdminResourceUsageDialog v-if="resource_report_showing" @dialog_cancel="resource_report_showing=false" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminResourceUsageDialog from '../admin/AdminResourceUsageDialog'
export default {
	components: { AdminResourceUsageDialog },
	data() { return {
		initialized: false,
		grade: '— All Grades —',
		subject: '— All Subjects —',
		search_string:'',
		search_terms: [],
		resource_report_showing: false,
	}},
	computed: {
		...mapState(['user_info', 'all_courses_loaded', 'all_courses', 'course_update_trigger']),
		...mapGetters(['small_screen']),
		can_view_resource_report() {
			// system admins and users that are principals/vice-principals can view resource usage reports from here
			return this.user_info.role == 'admin' || this.$store.getters.user_is_principal_or_ap
		},
		grades() {
			let arr = ['— All Grades —']
			for (let grade of this.$store.state.grades) {
				let text
				if (grade == 'PK') text = 'Pre-K'
				else if (grade == 'K') text = 'Kindergarten'
				else if (!isNaN(grade*1)) text = 'Grade ' + grade
				else text = grade

				arr.push({ value: grade, text: text})
			}
			return arr
		},
		subjects() {
			let arr = ['— All Subjects —']
			for (let subject in this.$store.state.subjects) {
				arr.push({ value: subject, text: subject})
			}
			return arr
		},
		search_results() {
			// trigger for updating search_results
			if (this.course_update_trigger < 0) return

			if (empty(this.grade) && empty(this.subject) && this.search_terms.length == 0) return null

			let arr = this.all_courses.filter(lp => {
				// when we first create a new LP to be entered, its title will be false
				if (lp.title == '') return false

				// we never show PD/PG/SC "courses" here
				if (lp.subject == 'PD' || lp.subject == 'PG' || lp.subject == 'SC') return false

				// for inactive lp's, only show to admins
				if (lp.active == 'no') {
					if (!lp.user_is_lp_admin()) return false
				}

				// if all search_terms appear in the title, return true
				let tlc = lp.title.toLowerCase()
				for (let term of this.search_terms) {
					if (tlc.indexOf(term) == -1) return false
				}

				if (!empty(this.grade) && this.grade != this.grades[0] && !U.grades_include(lp, this.grade)) return false
				if (!empty(this.subject) && this.subject != this.subjects[0] && lp.subject != this.subject) return false

				return true
			})

			arr.sort((a,b)=>{
				let gd = U.grade_value(a.grade_low) - U.grade_value(b.grade_low)
				if (gd != 0) return gd

				if (a.subject < b.subject) return -1
				if (b.subject < a.subject) return 1

				if (a.title < b.title) return -1
				if (b.title < a.title) return 1

				return 0
			})

			return arr
		},
		has_sis_classes() {
			// this doesn't currently work consistently, because we may not have loaded sis_classes before we get here...
			this.$store.state.sis_classes.length > 0
		},
	},
	watch: {
		search_string() {
			if (empty(this.search_string)) {
				this.search_terms = []
			} else {
				// convert string to lower case and split on spaces
				this.search_terms = $.trim(this.search_string).toLowerCase().split(/\s+/)
			}
		}
	},
	created() {
		if (!this.all_courses_loaded) {
			this.$store.dispatch('get_all_courses').then(()=>{
				this.$nextTick(()=>{
					this.initialized = true
					// console.log('all_courses', this.all_courses)
				})
			}).catch(()=>{
				console.log('error in get_all_courses')
				this.back_to_classes()
			})
		} else {
			this.initialized = true
		}
	},
	mounted() {
	},
	methods: {
		lp_css(lp) {
			let s = U.subject_tile_css(lp)
			if (lp.active == 'no') {
				s += ' k-lp-no-terms'
			}

			if (lp.title.length > 50) {
				s += ' k-lp-tile-extra-long-title'
			} else if (lp.title.length > 30) {
				s += ' k-lp-tile-long-title'
			}
			return s
		},

		lp_title(lp) {
			// add <wbr> tags after slashes
			let s = lp.title.replace(/\//g, '/<wbr>')

			return s
		},

		go_to_lp(lp) {
			this.$store.commit('set', ['last_lp_list', 'index'])
			this.$router.push({ path: sr('/courses/lp/$1/0/0', lp.course_code) })
		},

		external_search(search_string) {
			this.search_string = search_string
		},

		back_to_classes() {
			this.$store.commit('set', ['my_classes_view', 'assignments'])
		},
	}
}
</script>

<style lang="scss">
.k-lp-admin-wrapper {
	.k-lp-grade-menu {
		max-width:180px;
		margin-left:10px;
	}
	.k-lp-subject-menu {
		max-width:240px;
		margin-left:10px;
		margin-right:10px;
	}
	.k-lp-no-terms {
		border-style:dashed;
		border-color:#999;
	}
}

.k-course-index-control-wrapper {
	margin:2px 4px;
}

.k-lps-unavailable-note {
	background-color:#fff;
	border-radius:6px;
	padding:6px 4px;
	margin:0 auto 16px auto;
	max-width:900px;
	font-size:16px;
}
</style>
