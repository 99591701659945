<template><div class="mt-8">
	<CourseList />
	<router-view></router-view>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CourseList from './CourseList'

export default {
	components: { CourseList, },
	data() { return {
	}},
	computed: {
		...mapState([]),
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
