var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{on:{"click":_vm.toggle_message}},[_c('div',{class:{
				'd-flex': true,
				'k-message-reply-header': true,
				'k-message-reply-header--hover': hover,
				'k-message-reply-header--expanded': _vm.expanded,
			}},[_c('div',{staticClass:"d-flex k-message-reply-sender"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm.expansion_caret))]),_vm._v(" "),_c('p',[(_vm.show_unread_message_icon)?_c('v-icon',{staticClass:"k-unread-message-icon"},[_vm._v("\n\t\t\t\t\t\t\tfa-light fa-comment\n\t\t\t\t\t\t")]):_vm._e(),_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.message.first_name)+" "+_vm._s(_vm.message.last_name)+"\n\t\t\t\t\t\t")],1)],1),_vm._v(" "),(!_vm.expanded)?_c('div',{staticClass:"k-message-reply-body"},[_c('p',[_vm._v(_vm._s(_vm.truncated_message))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex k-message-reply-date"},[_c('p',[_vm._v(_vm._s(_vm.message_date))])])])])]}}])}),_vm._v(" "),_c('v-expand-transition',[(_vm.expanded)?_c('div',{staticClass:"k-message-reply-expanded"},[_c('v-divider'),_vm._v(" "),_c('div',{staticClass:"k-message-body"},[_c('div',{staticClass:"k-message-body-text",domProps:{"innerHTML":_vm._s(_vm.message.body)}})])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }